/* 다국어 분기 */
:lang(en) *,
:lang(en) font,
:lang(en) .tx_en {
	font-family: "Spoqa-Regular", -apple-system, BlinkMacSystemFont, "Apple SD Gothic Neo", Arial, sans-serif !important;
}
:lang(zh) *,
:lang(zh) font,
:lang(zh) .tx_zh {
	font-family: "Spoqa-Regular", "Microsoft Yahei", 微软雅黑, STXihei, 华文细黑, sans-serif !important;
}
:lang(auto) .hide_ko,
:lang(ko) .hide_ko,
:lang(en) .hide_en,
:lang(zh) .hide_zh {
	display: none !important;
}
:lang(en) .hide_gl,
:lang(zh) .hide_gl {
	display: none !important;
}
:lang(auto) .show_ko,
:lang(ko) .show_ko,
:lang(en) .show_en,
:lang(zh) .show_zh {
	display: block !important;
}
:lang(en) .show_gl,
:lang(zh) .show_gl {
	display: block !important;
}
:lang(en) select.show_gl,
:lang(zh) select.show_gl {
	display: inline-block !important;
}
:lang(en) span.show_gl,
:lang(zh) span.show_gl {
	display: inline !important;
}
:lang(en) em.show_gl,
:lang(zh) em.show_gl {
	display: inline !important;
}
.goog-tooltip {
	display: none !important;
}
.goog-text-highlight {
	background-color: initial !important;
	box-shadow: none !important;
	position: static !important;
}
font {
	vertical-align: baseline !important;
}
:lang(ko) .notranslate .tx_ko {
	display: inline;
}
:lang(ko) .notranslate .tx_en {
	display: none;
}
:lang(ko) .notranslate .tx_zh {
	display: none;
}
:lang(ko) .notranslate .tx_gl {
	display: none;
}
:lang(auto) .notranslate .tx_ko {
	display: inline;
}
:lang(auto) .notranslate .tx_en {
	display: none;
}
:lang(auto) .notranslate .tx_zh {
	display: none;
}
:lang(auto) .notranslate .tx_gl {
	display: none;
}
:lang(en) .notranslate .tx_ko {
	display: none;
}
:lang(en) .notranslate .tx_en {
	display: inline;
}
:lang(en) .notranslate .tx_zh {
	display: none;
}
:lang(en) .notranslate .tx_gl {
	display: inline;
}
:lang(zh) .notranslate .tx_ko {
	display: none;
}
:lang(zh) .notranslate .tx_en {
	display: none;
}
:lang(zh) .notranslate .tx_zh {
	display: inline;
}
:lang(zh) .notranslate .tx_gl {
	display: inline;
}
:lang(en) .ssg_tx,
:lang(zh) .ssg_tx {
	font-size: 0 !important;
	color: #fff !important;
}
:lang(en) .ssg_tx .cm_tx_opt,
:lang(zh) .ssg_tx .cm_tx_opt {
	font-size: 12px;
	color: #222;
}
:lang(en) .ssg_price,
:lang(zh) .ssg_price {
	white-space: nowrap;
}
:lang(en) .ssg_price:before {
	content: "\$";
}
:lang(zh) .ssg_price:before {
	content: "\$";
}
:lang(en) .set_currency .ssg_price:before,
:lang(zh) .set_currency .ssg_price:before {
	content: "";
}
:lang(en) .codr_btnarea .codr_tooltip_wrap > .codr_btn {
	line-height: inherit;
}
