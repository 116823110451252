/* 동영상 상품평 */
// .reply_mov {
//   background: #e8e8e8;
//   width: 480px;
//   padding: 9px;
//   box-shadow: inset 0px 0px 5px #afafaf;
//   border-radius: 3px;
//   position: relative;
//   overflow: hidden;
// }
// .reply_mov .pplayer,
// .reply_mov .mplayer {
//   width: 480px;
//   height: 320px;
// }
// .reply_mov .mplayer img {
//   width: 480px;
//   vertical-align: top;
// }

.zoomWrap {
  display: none;
  position: absolute;
  z-index: 100;
}
.zoomWrap .zoomTarget {
  position: relative;
  cursor: crosshair;
  background: url('#{$imgPath}/common/b.gif') 0 0 repeat;
}
.zoomWrap .zoomLens {
  display: none;
  position: absolute;
  cursor: crosshair;
  background: #000;
  opacity: 0.05;
  -moz-opacity: 0.05;
  filter: alpha(opacity=5);
}
.zoomWrap .zoomWindow {
  display: none;
  position: absolute;
  overflow: hidden;
  border: 1px solid #888;
  background: #fff;
}
.zoomWrap .zoomWindow.inner {
  border: 0;
}

/* 상품상세 입고알림신청 */

// .sp_cmdtl {
//   display: inline-block;
//   vertical-align: top;
//   background: url(#{$imgPath}/common/sp_cmdtl.png) no-repeat;
// }
// .request_stock_alert {
//   position: relative;
//   min-height: 25px;
//   padding: 2px 0;
//   text-align: center;
// }
// .request_stock_alert .txt {
//   display: inline-block;
//   vertical-align: top;
//   width: 251px;
//   height: 14px;
//   margin-top: 6px;
//   background-position: 0 -60px;
// }
// .request_stock_alert .btn_stock_alert {
//   display: inline-block;
//   width: 75px;
//   height: 25px;
//   margin-left: 19px;
//   background-position: -74px 0;
// }
/* 상품상세 입고알림신청 - 각몰 수정 */
// .body_sm .request_stock_alert {
//   margin: 0 0 15px;
//   padding: 0 0 0 5px;
//   text-align: left;
// }
// .body_sm .request_stock_alert .txt {
//   width: 127px;
//   height: 12px;
//   margin-top: 7px;
//   background-position: 0 -80px;
// }
// .body_sm .request_stock_alert .btn_stock_alert {
//   position: absolute;
//   right: 11px;
//   top: 0;
//   margin-left: 0;
// }
// .body_sd .request_stock_alert {
//   margin-top: 10px;
//   padding: 0 0 10px 5px;
//   text-align: left;
// }
// .body_sd .request_stock_alert .txt {
//   width: 127px;
//   height: 12px;
//   margin-top: 7px;
//   background-position: 0 -80px;
// }
// .body_sd .request_stock_alert .btn_stock_alert {
//   position: absolute;
//   right: 11px;
//   top: 0;
//   margin-left: 0;
// }
// .body_em .request_stock_alert,
// .body_traders .request_stock_alert,
// .body_boons .request_stock_alert {
//   clear: both;
//   padding: 5px 0 6px;
//   text-align: right;
// }
// .body_em .request_stock_alert .txt,
// .body_traders .request_stock_alert .txt,
// .body_boons .request_stock_alert .txt {
//   width: 231px;
//   height: 12px;
//   background-position: 0 -100px;
// }
// .body_em .request_stock_alert .btn_stock_alert,
// .body_traders .request_stock_alert .btn_stock_alert,
// .body_boons .request_stock_alert .btn_stock_alert {
//   margin-left: 9px;
// }

/* 상품 옵션 */
.cm_option_wrap {
  display: inline-block;
  position: relative;
  z-index: 10;
  height: 13px;
  margin: -3px 0 0 4px;
  vertical-align: middle;
  *display: inline;
  *zoom: 1;
}
.cm_option_wrap .cm_option_bt {
  display: inline-block;
  overflow: hidden;
  width: 13px;
  height: 13px;
  background: url(#{$imgPath}/common/sp_option.png) 0 0 no-repeat;
  line-height: 999px;
  vertical-align: top;
}
.cm_option_wrap.on .cm_option_bt {
  background-position: -20px 0;
}
.cm_option_wrap.on .cm_option {
  display: block;
}
.cm_option {
  display: none;
  position: absolute;
  top: 18px;
  right: -61px;
  width: 348px;
  border: 1px solid #666;
  background: #fff;
  font-size: 12px;
  line-height: 17px;
  text-align: left;
}
.cm_option .sp_opt {
  display: inline-block;
  overflow: hidden;
  background: url(#{$imgPath}/common/sp_option.png) no-repeat;
  line-height: 999px;
  vertical-align: top;
}
.cm_option .opt_bx {
  padding-top: 4px;
}
.cm_option .opt_item {
  margin: 20px 0;
  padding: 0 11px 0 15px;
}
.cm_option .opt_form {
  font-size: 0;
}
.cm_option .opt_form dt {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  width: 95px;
  margin-right: 8px;
  color: #222;
  font-size: 11px;
  letter-spacing: -1px;
  line-height: 1.3em;
  vertical-align: middle;
  word-break: break-all;
  word-wrap: break-word;
}
.cm_option .opt_form dt.a_top {
  padding-top: 1px;
  vertical-align: top;
}
.cm_option .opt_form dt label {
  vertical-align: middle;
}
.cm_option .opt_form dt label strong {
  font-weight: normal;
}
.cm_option .opt_form dd {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  overflow: hidden;
  position: relative;
  width: 219px;
  min-height: 18px;
  color: #666;
  font-size: 11px;
  letter-spacing: -1px;
  vertical-align: middle;
  word-break: break-all;
  word-wrap: break-word;
}
.cm_option .opt_form dd .tx_point {
  color: #73b1da;
}
.cm_option .opt_form dd .inpbx {
  display: block;
  height: 16px;
  padding: 1px 5px;
  border: 1px solid #b2b2b2;
}
.cm_option .opt_form dd .inpbx input {
  color: #666;
  font-size: 11px;
}
.cm_option .opt_form dd select {
  width: 100%;
  height: 20px;
  border: 1px solid #b2b2b2;
  color: #666;
  font-size: 11px;
}
.cm_option .opt_tip {
  position: relative;
  margin: 6px 0 -4px;
  color: #666;
  font-size: 11px;
  letter-spacing: -1px;
  line-height: 16px;
  word-break: break-all;
  word-wrap: break-word;
}
.cm_option .opt_item_addition {
  background: url(#{$imgPath}/common/line_dot.gif) repeat-x;
}
.cm_option .opt_item_addition .opt_form {
  padding-top: 20px;
}
.cm_option .opt_item_result {
  position: relative;
  margin: 0;
  padding: 6px 30px 6px 13px;
  background: #f6f6f6 url(#{$imgPath}/common/line_dot.gif) repeat-x;
  zoom: 1;
}
.cm_option .opt_item_result:after {
  display: block;
  clear: both;
  content: '';
}
.cm_option .opt_item_result .opt_tx {
  float: left;
  position: relative;
  width: 120px;
  padding: 2px 5px 0 21px;
  background: url(#{$imgPath}/common/sp_option.png) -100px 3px no-repeat;
  color: #333;
  word-break: break-all;
  word-wrap: break-word;
}
.cm_option .opt_item_result .opt_tx em {
  color: #e7340b;
}
.cm_option .opt_item_result .opt_tx.sub {
  background: none;
}
.cm_option .opt_item_result .opt_tx.sub .ic_sub {
  position: absolute;
  top: 7px;
  left: 7px;
  width: 7px;
  height: 7px;
  background-position: 0 -20px;
}
.cm_option .opt_item_result .opt_set {
  float: right;
}
.cm_option .opt_item_result .opt_set:after {
  display: block;
  clear: both;
  content: '';
}
.cm_option .opt_item_result .opt_amount {
  position: relative;
  float: left;
  width: 24px;
  padding: 0 12px 0 2px;
  border: 1px solid #cbcbcb;
  background: #fff;
}
.cm_option .opt_item_result .opt_amount .sp_opt {
  position: absolute;
  right: -1px;
  width: 11px;
  height: 11px;
}
.cm_option .opt_item_result .opt_amount .b_plus {
  top: -1px;
  background-position: -20px -20px;
}
.cm_option .opt_item_result .opt_amount .b_minus {
  bottom: -1px;
  background-position: -40px -20px;
}
.cm_option .opt_item_result .opt_amount .opa_area {
  display: block;
}
.cm_option .opt_item_result .opt_amount .opa_area input {
  width: 100%;
  border: 0;
  background: transparent;
  color: #777;
  font-size: 11px;
  text-align: center;
}
.cm_option .opt_item_result .price {
  float: right;
  width: 100px;
  color: #333;
  font-weight: bold;
  line-height: 22px;
  text-align: right;
}
.cm_option .opt_item_result .price em {
  margin-right: 2px;
}
.cm_option .opt_item_result .bt_opt_del {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 13px;
  height: 13px;
  background-position: -40px 0;
}
.cm_option .opt_bt {
  padding: 10px;
  background: url(#{$imgPath}/common/line_dot.gif) repeat-x;
  font-size: 0;
  text-align: center;
}
.cm_option .opt_bt .bt {
  display: inline-block;
  overflow: hidden;
  width: 71px;
  height: 26px;
  margin: 0 2px;
  border-radius: 2px;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: -1px;
  text-align: center;
  vertical-align: top;
}
.cm_option .opt_bt .bt.color1 {
  border: 1px solid #6d6d6d;
  background-color: #7f7f7f;
}
.cm_option .opt_bt .bt.color2 {
  border: 1px solid #f14f4f;
  background-color: #f14f4f;
}
