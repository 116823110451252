/* GMB 몰탭 */
.gnb_mall {
  overflow: hidden;
  width: 1280px;
  height: 51px;
  margin: 0 auto;
  padding-right: 15px;
  box-sizing: border-box;
}
.gnb_mall.gnb_mall_flexible {
  width: auto;
}
.gnb_mall ul {
  display: table;
  width: 100%;
  table-layout: fixed;
  height: 51px;
  background: url(#{$imgPath}/common/bg_layout_header03.gif) 0 100% repeat-x;
}
.gnb_mall li {
  display: table-cell;
  position: relative;
  padding-right: 13px;
  text-align: center;
  vertical-align: bottom;
  animation-name: gnb-mall-show;
  box-sizing: border-box;
}
.gnb_mall li:first-child a:before {
  display: none;
}
.gnb_mall a {
  display: block;
  position: relative;
  line-height: 34px;
  text-decoration: none !important;
}
.gnb_mall a:before {
  content: '';
  z-index: 3;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 14px 0 0;
  background: url(#{$imgPath}/common_layout/bg_malltab_shadow@2x.png) 0 0 no-repeat;
  background-size: 40px auto;
}
.gnb_mall a:after {
  content: '';
  z-index: 4;
  position: absolute;
  bottom: 0;
  right: -29px;
  width: 62px;
  height: 100%;
  background: url(#{$imgPath}/common_layout/bg_malltab_light@2x.png) 0 0 no-repeat;
  background-size: 62px auto;
}
.gnb_mall i {
  display: block;
  border-radius: 14px 0 0;
  background: url(#{$imgPath}/common_layout/sp_malltab_v3@2x.png) 100% 0 repeat-x;
  background-size: 200px auto;
}
.gnb_mall i:after {
  content: '';
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 100%;
  width: 30px;
  height: 100%;
  margin-left: -1px;
  background: url(#{$imgPath}/common_layout/sp_malltab_right_v3@2x.png) 100% 0 no-repeat;
  background-size: 30px auto;
}
.gnb_mall i {
  height: 34px;
}
.gnb_mall i:before {
  display: none;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 14px;
  height: 100%;
  border-left: 1px solid #4d4d4d;
  border-top: 1px solid #4d4d4d;
  border-radius: 14px 0 0;
}
.gnb_mall .gnb_malltx {
  display: block;
  overflow: hidden;
  position: relative;
  z-index: 2;
  margin-top: -34px;
  margin-right: -13px;
  padding: 0 10px;
  color: #222;
  font-size: 13px;
  font-family: $fontM;
  font-weight: 500;
  white-space: nowrap;
}
.gnb_mall .gnb_malltx:after {
  content: '';
  position: absolute;
  top: 50%;
  right: 0;
  width: 16px;
  height: 16px;
  margin-top: -7px;
}
.gnb_mall .gnb_malltx.en {
  font-size: 14px;
}
.gnb_mall .gnb_malltx.light {
  color: #fff;
}
.gnb_mall .ico_tooltip {
  position: absolute;
  top: -6px;
  right: -10px;
  z-index: 10;
  margin: 0;
  width: 30px;
  height: 14px;
  background: url(#{$imgPath}/common_layout/sp_mallnew_ico.png) 0 0 no-repeat;
  background-size: 30px auto;
  opacity: 0;
  opacity: 1\9;
  animation: fadeIn 0.1s forwards;
}
.gnb_mall .ico_tooltip.ad {
  background: url(#{$imgPath}/common_layout/ico_mall_ad.png) 0 0 no-repeat;
}

@media screen and (-webkit-min-device-pixel-ratio: 2) {
  .gnb_mall .ico_tooltip {
    background-image: url(#{$imgPath}/common_layout/sp_mallnew_ico@2x.png);
    background-size: auto 14px;
  }
  .gnb_mall .ico_tooltip.ad {
    background-image: url(#{$imgPath}/common_layout/ico_mall_ad@2x.png);
    background-size: auto 14px;
  }
}

/* GMB 몰탭 - 광고구좌 SVG */
.gnb_mall li.ty_virtual {
  padding: 8px 0 0;
}
.gnb_mall li.ty_virtual a {
  height: 34px;
}
.gnb_mall li.ty_virtual a:after {
  right: -14px;
}
.gnb_mall li.ty_virtual a:focus,
.gnb_mall li.ty_virtual a:hover,
.gnb_mall li.ty_virtual.active a {
  height: 43px;
}
.gnb_mall li.ty_virtual svg {
  display: block;
  overflow: visible;
}
.gnb_mall li.ty_virtual .gnb_mall_svg {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 26px;
}
.gnb_mall li.ty_virtual .gnb_mall_svghover {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 26px;
  opacity: 0;
}
.gnb_mall li.ty_virtual .gnb_malltx {
  margin-right: 0;
}
.gnb_mall li.ty_virtual .ico_tooltip {
  right: 4px;
}
.gnb_mall li.ty_virtual:not(.active) a .gnb_mall_svg {
  bottom: 1px;
}
.gnb_mall li.ty_virtual:not(.active) a:focus .gnb_mall_svg,
.gnb_mall li.ty_virtual:not(.active) a:hover .gnb_mall_svg {
  opacity: 0;
}
.gnb_mall li.ty_virtual:not(.active) a:focus .gnb_mall_svghover,
.gnb_mall li.ty_virtual:not(.active) a:hover .gnb_mall_svghover {
  opacity: 1;
}

/* GMB 몰탭 - animation */
.gnb_mall li:nth-child(1) {
  animation-duration: 0.25s;
  z-index: 20;
}
.gnb_mall li:nth-child(2) {
  animation-duration: 0.35s;
  z-index: 19;
}
.gnb_mall li:nth-child(3) {
  animation-duration: 0.45s;
  z-index: 18;
}
.gnb_mall li:nth-child(4) {
  animation-duration: 0.55s;
  z-index: 17;
}
.gnb_mall li:nth-child(5) {
  animation-duration: 0.65s;
  z-index: 16;
}
.gnb_mall li:nth-child(6) {
  animation-duration: 0.75s;
  z-index: 15;
}
.gnb_mall li:nth-child(7) {
  animation-duration: 0.85s;
  z-index: 14;
}
.gnb_mall li:nth-child(8) {
  animation-duration: 0.95s;
  z-index: 13;
}
.gnb_mall li:nth-child(9) {
  animation-duration: 1.05s;
  z-index: 12;
}
.gnb_mall li:nth-child(10) {
  animation-duration: 1.15s;
  z-index: 11;
}
.gnb_mall li:nth-child(11) {
  animation-duration: 1.25s;
  z-index: 10;
}
.gnb_mall li:nth-child(12) {
  animation-duration: 1.35s;
  z-index: 9;
}
.gnb_mall li:nth-child(13) {
  animation-duration: 1.45s;
  z-index: 8;
}
.gnb_mall li:nth-child(14) {
  animation-duration: 1.55s;
  z-index: 7;
}
.gnb_mall li:nth-child(15) {
  animation-duration: 1.65s;
  z-index: 6;
}
.gnb_mall li:nth-child(16) {
  animation-duration: 1.75s;
  z-index: 5;
}
.gnb_mall li:nth-child(17) {
  animation-duration: 1.85s;
  z-index: 4;
}
.gnb_mall li:nth-child(18) {
  animation-duration: 1.95s;
  z-index: 3;
}
.gnb_mall li:nth-child(19) {
  animation-duration: 2.05s;
  z-index: 2;
}
.gnb_mall li:nth-child(20) {
  animation-duration: 2.15s;
  z-index: 1;
}
@keyframes gnb-mall-show {
  0% {
    opacity: 0;
    transform: translateY(35px);
  }
  70% {
    opacity: 1;
    transform: translateY(35px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.gnb_mall li:nth-child(1) .ico_tooltip {
  animation-delay: 0.25s;
}
.gnb_mall li:nth-child(2) .ico_tooltip {
  animation-delay: 0.35s;
}
.gnb_mall li:nth-child(3) .ico_tooltip {
  animation-delay: 0.45s;
}
.gnb_mall li:nth-child(4) .ico_tooltip {
  animation-delay: 0.55s;
}
.gnb_mall li:nth-child(5) .ico_tooltip {
  animation-delay: 0.65s;
}
.gnb_mall li:nth-child(6) .ico_tooltip {
  animation-delay: 0.75s;
}
.gnb_mall li:nth-child(7) .ico_tooltip {
  animation-delay: 0.85s;
}
.gnb_mall li:nth-child(8) .ico_tooltip {
  animation-delay: 0.95s;
}
.gnb_mall li:nth-child(9) .ico_tooltip {
  animation-delay: 1.05s;
}
.gnb_mall li:nth-child(10) .ico_tooltip {
  animation-delay: 1.15s;
}
.gnb_mall li:nth-child(11) .ico_tooltip {
  animation-delay: 1.25s;
}
.gnb_mall li:nth-child(12) .ico_tooltip {
  animation-delay: 1.35s;
}
.gnb_mall li:nth-child(13) .ico_tooltip {
  animation-delay: 1.45s;
}
.gnb_mall li:nth-child(14) .ico_tooltip {
  animation-delay: 1.55s;
}
.gnb_mall li:nth-child(15) .ico_tooltip {
  animation-delay: 1.65s;
}
.gnb_mall li:nth-child(16) .ico_tooltip {
  animation-delay: 1.75s;
}
.gnb_mall li:nth-child(17) .ico_tooltip {
  animation-delay: 1.85s;
}
.gnb_mall li:nth-child(18) .ico_tooltip {
  animation-delay: 1.95s;
}
.gnb_mall li:nth-child(19) .ico_tooltip {
  animation-delay: 2.05s;
}
.gnb_mall li:nth-child(20) .ico_tooltip {
  animation-delay: 2.15s;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* GMB 몰탭 - focus, hover, active */
.gnb_mall li a:focus,
.gnb_mall li a:hover,
.gnb_mall li.active a {
  line-height: 43px;
}
.gnb_mall li a:focus:before,
.gnb_mall li a:hover:before,
.gnb_mall li.active a:before,
.gnb_mall li a:focus:after,
.gnb_mall li a:hover:after,
.gnb_mall li.active a:after {
  display: none;
}
.gnb_mall li a:focus i,
.gnb_mall li a:hover i,
.gnb_mall li.active i {
  height: 43px;
  background-image: url(#{$imgPath}/common_layout/sp_malltabon_v3@2x.png);
}
.gnb_mall li a:focus i:before,
.gnb_mall li a:hover i:before,
.gnb_mall li.active i:before {
  display: block;
}
.gnb_mall li a:focus i:after,
.gnb_mall li a:hover i:after,
.gnb_mall li.active i:after {
  background-image: url(#{$imgPath}/common_layout/sp_malltabon_right_v3@2x.png);
}
.gnb_mall li a:focus .gnb_malltx,
.gnb_mall li a:hover .gnb_malltx,
.gnb_mall li.active .gnb_malltx {
  margin-top: -43px;
}
.gnb_mall li:not(.active) a:focus .gnb_malltx,
.gnb_mall li:not(.active) a:hover .gnb_malltx {
  color: #222 !important;
}
.gnb_mall li a:focus .gnb_malltx:before,
.gnb_mall li a:hover .gnb_malltx:before,
.gnb_mall li.active .gnb_malltx:before {
  display: none !important;
}
.gnb_mall li:not(.active) a:focus .gnb_malltx:after,
.gnb_mall li:not(.active) a:hover .gnb_malltx:after {
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0), #fff 80%) !important;
}
.gnb_mall li.active .gnb_malltx:after {
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0), #fff 80%);
}

.gnb_mall li:hover {
  z-index: 21 !important;
}
.gnb_mall li:not(.active) a:focus i,
.gnb_mall li:not(.active) a:hover i,
.gnb_mall li:not(.active) a:focus i:after,
.gnb_mall li:not(.active) a:hover i:after {
  background-position-y: 0 !important;
}

.gnb_mall li.active {
  z-index: 22 !important;
  animation: none;
}

/* GMB 몰탭 - 확장 */
.gnb_mall_flexible li.fix {
  width: 128px;
}
.gnb_mall_flexible li:not(.fix) .gnb_malltx:before {
  content: ''; /*display:inline-block;*/
  display: none;
  width: 20px;
  height: 20px;
  margin: -1px 5px 0 0;
  background-image: url(#{$imgPath}/common_layout/sp_malltab_symbol@2x.png);
  background-repeat: no-repeat;
  background-size: 20px 600px;
  vertical-align: middle;
}

/* [D] 미디어쿼리 min-width 계산식 - (min-width: 128 * 몰탭개수 + 45 px)
* 몰탭 11개: (min-width: 1453px)
* 몰탭 12개: (min-width: 1581px)
* 몰탭 13개: (min-width: 1709px)
* 몰탭 14개: (min-width: 1837px)
*/
@media screen and (min-width: 1453px) {
  .gnb_mall_flexible.n11 li {
    width: auto !important;
  }
  .gnb_mall_flexible.n11 li:not(.fix) .gnb_malltx:before,
  .gnb_mall_flexible.n11 li:not(.fix) .gnb_malltx:after {
    display: none;
  }
}
@media screen and (min-width: 1581px) {
  .gnb_mall_flexible.n12 li {
    width: auto !important;
  }
  .gnb_mall_flexible.n12 li:not(.fix) .gnb_malltx:before,
  .gnb_mall_flexible.n12 li:not(.fix) .gnb_malltx:after {
    display: none;
  }
}
@media screen and (min-width: 1709px) {
  .gnb_mall_flexible.n13 li {
    width: auto !important;
  }
  .gnb_mall_flexible.n13 li:not(.fix) .gnb_malltx:before,
  .gnb_mall_flexible.n13 li:not(.fix) .gnb_malltx:after {
    display: none;
  }
}
@media screen and (min-width: 1837px) {
  .gnb_mall_flexible.n14 li {
    width: auto !important;
  }
  .gnb_mall_flexible.n14 li:not(.fix) .gnb_malltx:before,
  .gnb_mall_flexible.n14 li:not(.fix) .gnb_malltx:after {
    display: none;
  }
}
@media screen and (min-width: 1965px) {
  .gnb_mall_flexible.n15 li {
    width: auto !important;
  }
  .gnb_mall_flexible.n15 li:not(.fix) .gnb_malltx:before,
  .gnb_mall_flexible.n15 li:not(.fix) .gnb_malltx:after {
    display: none;
  }
}

/* GMB 몰탭 - 각몰 수정 */
.gnb_mall a i,
.gnb_mall a i:after {
  background-position-y: -400px;
} /* 기본값 siv 스타일 */
.gnb_mall a.sm i,
.gnb_mall a.sm i:after {
  background-position-y: -50px;
}
.gnb_mall a.sd i,
.gnb_mall a.sd i:after {
  background-position-y: -100px;
}
.gnb_mall a.em i,
.gnb_mall a.em i:after {
  background-position-y: -150px;
}
.gnb_mall a.tr i,
.gnb_mall a.tr i:after {
  background-position-y: -200px;
}
.gnb_mall a.mnstarbucks i,
.gnb_mall a.mnstarbucks i:after {
  background-position-y: -250px;
}
.gnb_mall a.tv i,
.gnb_mall a.tv i:after {
  background-position-y: -300px;
}
.gnb_mall a.oul i,
.gnb_mall a.oul i:after {
  background-position-y: -350px;
}
.gnb_mall a.siv i,
.gnb_mall a.siv i:after {
}
.gnb_mall a.sf i,
.gnb_mall a.sf i:after {
  background-position-y: -450px;
}
.gnb_mall a.hwd i,
.gnb_mall a.hwd i:after {
  background-position-y: -500px;
}
.gnb_mall a.mnmorning i,
.gnb_mall a.mnmorning i:after {
  background-position-y: -550px;
}
.gnb_mall a.mncasamia i,
.gnb_mall a.mncasamia i:after {
  background-position-y: -650px;
}
.gnb_mall a.mnchicor i,
.gnb_mall a.mnchicor i:after {
  background-position-y: -700px;
}
.gnb_mall a.mntrip i,
.gnb_mall a.mntrip i:after {
  background-position-y: -800px;
}
/*시코르 일때만 하우디 검정 배경 변경*/
.body_mnchicor .gnb_mall a.hwd i,
.body_mnchicor .gnb_mall a.hwd i:after {
  background-position-y: -750px;
}
.body_mnchicor .gnb_mall a.hwd .gnb_malltx:after {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0), #444 80%);
}
.body_mnchicor .gnb_mall li a:focus .gnb_malltx:after,
.body_mnchicor .gnb_mall li a:hover .gnb_malltx:after {
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0), #fff 80%);
}
.gnb_mall a .gnb_malltx:before {
  background-position-y: -180px;
} /* 기본값 siv 스타일 */
.gnb_mall a.sm .gnb_malltx:before {
  background-position-y: 0;
}
.gnb_mall a.sd .gnb_malltx:before {
  background-position-y: -30px;
}
.gnb_mall a.em .gnb_malltx:before {
  background-position-y: -60px;
}
.gnb_mall a.tr .gnb_malltx:before {
  background-position-y: -90px;
}
.gnb_mall a.mnstarbucks .gnb_malltx:before {
  background-position-y: -120px;
}
.gnb_mall a.tv .gnb_malltx:before {
  background-position-y: -150px;
}
.gnb_mall a.siv .gnb_malltx:before {
}
.gnb_mall a.sf .gnb_malltx:before {
  background-position-y: -210px;
}
.gnb_mall a.hwd .gnb_malltx:before {
  background-position-y: -240px;
}
.gnb_mall a.oul .gnb_malltx:before {
  background-position-y: -270px;
}
.gnb_mall a.mnmorning .gnb_malltx:before {
  background-position-y: -310px;
}
.gnb_mall a.mncasamia .gnb_malltx:before {
  background-position-y: -340px;
}

.gnb_mall a .gnb_malltx:after {
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0), #fff 80%);
} /* 기본값 siv 스타일 */
.gnb_mall a.sm .gnb_malltx:after {
  background-image: linear-gradient(90deg, rgba(252, 24, 51, 0), #fc1833 80%);
}
.gnb_mall a.sd .gnb_malltx:after {
  background-image: linear-gradient(90deg, rgba(110, 111, 111, 0), #6e6f6f 80%);
}
.gnb_mall a.em .gnb_malltx:after {
  background-image: linear-gradient(90deg, rgba(255, 208, 64, 0), #ffd040 80%);
}
.gnb_mall a.tr .gnb_malltx:after {
  background-image: linear-gradient(90deg, rgba(166, 221, 39, 0), #a6dd27 80%);
}
.gnb_mall a.mnstarbucks .gnb_malltx:after {
  background-image: linear-gradient(90deg, rgba(0, 98, 65, 0), #006241 80%);
}
.gnb_mall a.tv .gnb_malltx:after {
  background-image: linear-gradient(90deg, rgba(217, 32, 22, 0), #d92016 80%);
}
.gnb_mall a.siv .gnb_malltx:after {
}
.gnb_mall a.sf .gnb_malltx:after {
  background-image: linear-gradient(90deg, rgba(177, 37, 54, 0), #b12536 80%);
}
.gnb_mall a.hwd .gnb_malltx:after {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0), #000 80%);
}
.gnb_mall a.oul .gnb_malltx:after {
  background-image: linear-gradient(90deg, rgba(159, 0, 92, 0), #d9117c 80%);
}
.gnb_mall a.mnmorning .gnb_malltx:after {
  background-image: linear-gradient(90deg, rgba(163, 183, 205, 0), #a3b7cd 80%);
}
.gnb_mall a.mnchicor .gnb_malltx:after {
  background-image: linear-gradient(90deg, rgba(238, 100, 86, 0), #e31e8f 80%);
}
.gnb_mall a.mntrip .gnb_malltx:after {
  background-image: linear-gradient(90deg, rgba(51, 140, 168, 0), #338ca8 80%);
}

.gnb_mall a.ad:after,
.gnb_mall a.siv:after,
.gnb_mall a.mncasamia:after {
  display: none;
}
.gnb_mall a.ad i:before,
.gnb_mall a.siv i:before,
.gnb_mall a.mncasamia i:before {
  display: block;
}

:lang(en) .gnb_mall .gnb_malltx {
  font-size: 14px;
}
:lang(en) .gnb_mall a.oul .gnb_malltx {
  font-size: 12px;
}

.gnb_mall li.active a.sm .gnb_malltx.light,
.gnb_mall li.active a.tv .gnb_malltx.light,
.gnb_mall li.active a.sf .gnb_malltx.light,
.gnb_mall li.active a.mntrip .gnb_malltx.light {
  color: #222;
}
.gnb_mall li.active a.mnstarbucks .gnb_malltx.light {
  color: #006241;
}
.gnb_mall li.active a.sd .gnb_malltx:after {
  background-image: linear-gradient(90deg, rgba(110, 111, 111, 0), #6e6f6f 80%);
}
.gnb_mall li.active a.em .gnb_malltx:after {
  background-image: linear-gradient(90deg, rgba(255, 208, 64, 0), #ffd040 80%);
}
.gnb_mall li.active a.tr .gnb_malltx:after {
  background-image: linear-gradient(90deg, rgba(166, 221, 39, 0), #a6dd27 80%);
}
.gnb_mall li.active a.sm .gnb_malltx:after,
.gnb_mall li.active a.mnmorning .gnb_malltx:after,
.gnb_mall li.active a.tv .gnb_malltx:after,
.gnb_mall li.active a.mnstarbucks .gnb_malltx:after,
.gnb_mall li.active a.sf .gnb_malltx:after,
.gnb_mall li.active a.mncasamia .gnb_malltx:after,
.gnb_mall li.active a.mntrip .gnb_malltx:after {
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0), #fff 80%);
}
.gnb_mall li.active a.oul .gnb_malltx:after {
  background-image: linear-gradient(90deg, rgba(159, 0, 92, 0), #d9117c 80%);
}
.gnb_mall li.active a.mnchicor .gnb_malltx:after,
.gnb_mall li.active a.hwd .gnb_malltx:after {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0), #000 80%);
}

/* GMB 몰탭 - 각몰 수정 (ie8 이하) */
.gnb_mall a:before,
.gnb_mall a:after,
.gnb_mall i:before,
.gnb_mall i:after,
.gnb_mall li a:focus i:before,
.gnb_mall li a:hover i:before,
.gnb_mall li.active i:before,
.gnb_mall li a:focus i:after,
.gnb_mall li a:hover i:after,
.gnb_mall li.active i:after {
  background: none\0 / IE8;
}
.gnb_mall a .gnb_malltx {
  background: #eee\0 / IE8;
}
.gnb_mall a.sm .gnb_malltx {
  background: #fc1833\0 / IE8;
}
.gnb_mall a.sd .gnb_malltx {
  background: #6e6f6f\0 / IE8;
}
.gnb_mall a.em .gnb_malltx {
  background: #ffd040\0 / IE8;
}
.gnb_mall a.tr .gnb_malltx {
  background: #a6dd27\0 / IE8;
}
.gnb_mall a.mnstarbucks .gnb_malltx {
  background: #006241\0 / IE8;
}
.gnb_mall a.tv .gnb_malltx {
  background: #d92016\0 / IE8;
}
.gnb_mall a.siv .gnb_malltx {
}
.gnb_mall a.sf .gnb_malltx {
  background: #b12536\0 / IE8;
}
.gnb_mall a.hwd .gnb_malltx {
  background: #000\0 / IE8;
}
.gnb_mall a.oul .gnb_malltx {
  background: #d9117c\0 / IE8;
}
.gnb_mall a.mnmorning .gnb_malltx {
  background: #a3b7cd\0 / IE8;
}
.gnb_mall a.mnchicor .gnb_malltx {
  background: #f04c24\0 / IE8;
}
.gnb_mall a.mntrip .gnb_malltx {
  background: #338ca8\0 / IE8;
}
