/* 전문관 클립, 내보내기 */
.special_wrap_wide .special_menu {
  margin-left: 525px;
}
.special_menu {
  position: absolute;
  bottom: 19px;
  left: 50%;
  z-index: 40;
  width: 90px;
  height: 29px;
  margin-left: 420px;
  padding-top: 7px;
  background: url(#{$imgPath}/special/sp_special_menu.png) 0 0 no-repeat;
  text-align: center;
}
.special_menu .sp_menu {
  display: inline-block;
  overflow: hidden;
  background: url(#{$imgPath}/special/sp_special_menu.png) no-repeat;
  line-height: 999px;
  vertical-align: top;
}
.special_menu .cm_clip_special {
  display: inline-block;
  height: 17px;
  margin-top: 2px;
  vertical-align: middle;
}
.special_menu .cm_clip_special a {
  cursor: pointer;
}
.special_menu .cm_clip_special a.sp_ccp {
  background-image: url(#{$imgPath}/special/sp_special_menu.png) !important;
}
.special_menu .cm_clip_special a.sel_clip {
  width: 21px;
  height: 17px;
  margin-top: 0;
  background-position: 0 -40px;
}
.special_menu .cm_clip_special.on a.sel_clip {
  background-position: 0 -60px;
}
.special_menu .special_sns {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}
.special_menu .special_bt_sns {
  display: inline-block;
  margin-left: 9px;
  vertical-align: middle;
  cursor: pointer;
}
.special_menu .special_bt_sns span {
  width: 18px;
  height: 17px;
  background-position: -30px -40px;
}
.special_menu .on .special_bt_sns span {
  background-position: -30px -60px;
}
.special_menu .special_ly_sns {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  width: 260px;
  margin-top: 9px;
  padding: 21px 0 27px;
  border: 1px solid #dedede;
  background: #fff;
}
.special_menu .special_ly_sns h3 {
  margin-bottom: 7px;
  font-size: 14px;
  font-weight: normal;
  font-family: 'Century Gothic';
}
.special_menu .special_ly_sns .bt_close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 13px 19px;
  background: transparent;
}
.special_menu .special_ly_sns .bt_close span {
  width: 10px;
  height: 11px;
  background-position: -80px -40px;
}
.special_menu .special_ly_sns .cm_sns {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  vertical-align: top;
}
.special_menu .special_ly_sns .cm_sns li {
  width: 29px;
  height: 29px;
  margin: 0 2px;
}
.special_menu .special_ly_sns .cm_sns li a {
  display: block;
  width: 29px;
  height: 29px;
  background: url(#{$imgPath}/lifem/sp_sns.png) no-repeat;
}
.special_menu .special_ly_sns .cm_sns li a.kakao {
  background-position: -33px 0;
}
.special_menu .special_ly_sns .cm_sns li a.facebook {
  background-position: 0 0;
}
.special_menu .special_ly_sns .cm_sns li a.twitter {
  background-position: -66px 0;
}
.special_menu .special_ly_sns .cm_sns li a.pinterest {
  background-position: -99px 0;
}
.special_menu {
  .special_like {
    display: inline-block;
    vertical-align: middle;
    .cmlike {
      vertical-align: middle;
    }
  }
}
/* 레티나 대응 */
@media screen and (-webkit-min-device-pixel-ratio: 2) {
  .special_menu,
  .special_menu .sp_menu,
  .special_menu .cm_clip_special a.sp_ccp {
    background-image: url(#{$imgPath}/special/sp_special_menu@2x.png) !important;
    background-size: 90px 90px;
  }
  .special_menu .special_ly_sns .cm_sns li a {
    background-image: url(#{$imgPath}/lifem/sp_sns@2x.png);
    background-size: 130px 30px;
  }
}
