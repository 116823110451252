/* 디자인공통 더보기 버튼 */
.cmtheme_loading {
  height: 60px;
  margin-top: 50px;
  background: url(#{$imgPath}/common/loading.gif) no-repeat 50% 50%;
}
.cmtheme_more {
  margin-top: 50px;
  text-align: center;
}
.cmtheme_more .cmtheme_btnmore {
  width: 500px;
  height: 50px;
  border: 1px solid #222;
  font-family: $fontR;
  font-weight: 400;
  font-size: 15px;
  padding-top: 1px;
  line-height: 49px;
  color: #222;
  letter-spacing: -0.3px;
  box-sizing: border-box;
}
.cmtheme_more .cmtheme_btnmore:after {
  display: inline-block;
  width: 10px;
  height: 6px;
  margin: 21px -17px 0 7px;
  background: url(#{$imgPath}/common/ico_arr.png) no-repeat;
  vertical-align: top;
  content: '';
}
/* 고해상도 대응 */
@media screen and (-webkit-min-device-pixel-ratio: 2) {
  .cmtheme_more .cmtheme_btnmore:after {
    background-size: 10px auto;
    background-position: 0 -10px;
  }
}
