/* web font */
// @font-face {
// 	font-family: "Roboto-Medium";
// 	font-style: normal;
// 	font-weight: normal;
// 	src: url("https://sui.ssgcdn.com/ui/common/font/Roboto-Medium.eot");
// 	src: local(※), url("https://sui.ssgcdn.com/ui/common/font/Roboto-Medium.woff") format("woff"), url("https://sui.ssgcdn.com/ui/common/font/Roboto-Medium.ttf") format("truetype");
// }
// @font-face {
// 	font-family: "Roboto-Regular";
// 	font-style: normal;
// 	font-weight: normal;
// 	src: url("https://sui.ssgcdn.com/ui/common/font/Roboto-Regular.eot");
// 	src: local(※), url("https://sui.ssgcdn.com/ui/common/font/Roboto-Regular.woff") format("woff"), url("https://sui.ssgcdn.com/ui/common/font/Roboto-Regular.ttf") format("truetype");
// }
@font-face {
  font-family: 'Spoqa-Regular';
  font-style: normal;
  font-weight: normal;
  src: url('https://sui.ssgcdn.com/ui/common/font/spoqa/Spoqa-Regular.woff2') format('woff2'),
    url('https://sui.ssgcdn.com/ui/common/font/spoqa/Spoqa-Regular.woff') format('woff');
}
@font-face {
  font-family: 'Spoqa-Medium';
  font-style: normal;
  font-weight: normal;
  src: url('https://sui.ssgcdn.com/ui/common/font/spoqa/Spoqa-Medium.woff2') format('woff2'),
    url('https://sui.ssgcdn.com/ui/common/font/spoqa/Spoqa-Medium.woff') format('woff');
}
@font-face {
  font-family: 'Spoqa-Bold';
  font-style: normal;
  font-weight: normal;
  src: url('https://sui.ssgcdn.com/ui/common/font/spoqa/Spoqa-Bold.woff2') format('woff2'),
    url('https://sui.ssgcdn.com/ui/common/font/spoqa/Spoqa-Bold.woff') format('woff');
}
@font-face {
  font-family: 'NanumMyeongjo';
  font-style: normal;
  font-weight: normal;
  src: url('https://sui.ssgcdn.com/ui/common/font/NanumMyeongjo-Bold.eot');
  src: local(※), url('https://sui.ssgcdn.com/ui/common/font/NanumMyeongjo-Bold.woff') format('woff'),
    url('https://sui.ssgcdn.com/ui/common/font/NanumMyeongjo-Bold.ttf') format('truetype');
}
// @font-face {
// 	font-family: "ssgotham-black";
// 	font-style: normal;
// 	font-weight: normal;
// 	src: url("https://sui.ssgcdn.com/ui/common/font/ssgotham-black.woff2") format("woff2");
// 	src: local(※), url("https://sui.ssgcdn.com/ui/common/font/ssgotham-black.woff") format("woff");
// }
// @font-face {
// 	font-family: "ssgotham-medium";
// 	font-style: normal;
// 	font-weight: normal;
// 	src: url("https://sui.ssgcdn.com/ui/common/font/ssgotham-medium.woff2") format("woff2");
// 	src: local(※), url("https://sui.ssgcdn.com/ui/common/font/ssgotham-medium.woff") format("woff");
// }

/* Pretendard */
@font-face {
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 400;
  src: url('https://sui.ssgcdn.com/ui/common/font/pretendard/Pretendard-Regular.woff2?subset')
    format('woff2');
}
@font-face {
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 500;
  src: url('https://sui.ssgcdn.com/ui/common/font/pretendard/Pretendard-Medium.woff2?subset')
    format('woff2');
}
@font-face {
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 600;
  src: url('https://sui.ssgcdn.com/ui/common/font/pretendard/Pretendard-SemiBold.woff2?subset')
    format('woff2');
}
@font-face {
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 700;
  src: url('https://sui.ssgcdn.com/ui/common/font/pretendard/Pretendard-Bold.woff2?subset')
    format('woff2');
}

/* PlayfairDisplay */
@font-face {
  font-family: 'PlayfairDisplay';
  font-style: normal;
  font-weight: 400;
  src: url('https://sui.ssgcdn.com/ui/common/font/playfairdisplay/PlayfairDisplay-Regular.woff2')
    format('woff2');
}
@font-face {
  font-family: 'PlayfairDisplay';
  font-style: normal;
  font-weight: 500;
  src: url('https://sui.ssgcdn.com/ui/common/font/playfairdisplay/PlayfairDisplay-Medium.woff2')
    format('woff2');
}
@font-face {
  font-family: 'PlayfairDisplay';
  font-style: normal;
  font-weight: 600;
  src: url('https://sui.ssgcdn.com/ui/common/font/playfairdisplay/PlayfairDisplay-SemiBold.woff2')
    format('woff2');
}

/* Icon */
@import '@ssgui/icons/dist/fonts';
