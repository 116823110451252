/* 공식스토어 */
.com_store {
  width: 1020px;
  margin: 30px auto;
}
.com_store.store_wrap:after {
  display: block;
  clear: both;
  content: '';
}
.com_store .store_list {
  margin: 0 -4px 0 -17px;
}
.com_store .store_list a.sel_clip {
  background-image: none !important;
}
.com_store .store_list li {
  display: inline-block;
  width: 240px;
  margin: 20px 0 10px 17px;
  height: auto;
  vertical-align: top;
}
.com_store .store_list li .store_img {
  position: relative;
  display: block;
  height: 145px;
  background-color: #f3f3f5;
}
/* 363218 오픈 후 삭제 */
.com_store .store_list li .store_img .bdg {
  position: absolute;
  top: 0;
  left: 0;
  padding: 3px 15px 1px 15px;
  background-color: #f14f4f;
  color: #fff;
}
/* //363218 오픈 후 삭제 */
.com_store .store_list li .store_img .bdg_open {
  position: absolute;
  top: 0;
  left: 0;
  background: url(#{$imgPath}/common/ic_open.png) no-repeat 0 0 / 64px auto;
  width: 64px;
  height: 25px;
}
.com_store .store_list li .store_img .cm_clip {
  overflow: hidden;
  background: url(#{$imgPath}/common/sp_ccp.png) -590px -85px no-repeat;
  width: 31px;
  height: 31px;
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.com_store .store_list li .store_img .cm_clip.on {
  background-position: -624px -85px;
}
.com_store .store_list li .store_img .cm_clip_item a.sel_clip {
  width: 31px;
  height: 31px;
}
.com_store .store_list .tt_adinfo_n {
  left: 0;
  bottom: 0;
}
.com_store .store_list .tt_adinfo_n .tt_adinfo_layer {
  top: -45px;
  left: 10px;
}
@media screen and (-webkit-min-device-pixel-ratio: 2) {
  .com_store .store_list li .store_img .cm_clip {
    background: url(#{$imgPath}/common/clip_off.svg) 0 0 no-repeat !important;
    width: 30px;
    height: 30px;
  }
  .com_store .store_list li .store_img .cm_clip.on {
    background: url(#{$imgPath}/common/clip_on.svg) 0 0 no-repeat !important;
  }
  .com_store .store_list li .store_img .bdg_open {
    background: url(#{$imgPath}/common/ic_open_2x.png) no-repeat 0 0;
    background-size: 64px auto;
  }
}
.store_wrap .store_big .store_list li .store_img .cm_clip {
  width: 60px;
  height: 60px;
  background-position: -587px -148px;
}
.store_wrap .store_big .store_list li .store_img .cm_clip a {
  width: 60px;
  height: 60px;
}
.store_wrap .store_big .store_list li .store_img .cm_clip.on {
  background-position: -658px -148px;
}

// .com_store .store_list li .store_text {
//     text-decoration: none;
//     color: #8b8b8b;
//     display: block;
//     margin-top: 10px;
// }
// .com_store .store_list li .store_text .brand {
//     display: block;
//     color: #33353d;
//     font-size: 17px;
//     text-align: center;
// }
// .com_store .store_list li .store_text .txt {
//     display: block;
//     font-size: 12px;
//     line-height: 18px;
//     padding-top: 5px;
//     max-height: 38px;
//     overflow: hidden;
//     word-break: break-all;
//     word-wrap: break-word;
// }

.store_wrap {
  position: relative;
}
.store_wrap h2.tit {
  width: 1020px;
  overflow: hidden;
  padding: 30px 0 15px;
  font-family: $fontR;
  font-weight: 400;
  font-size: 50px;
  line-height: 52px;
  color: #231f20;
  text-align: center;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
  font-weight: normal;
}
.store_wrap h2.tit span {
  display: block;
  font-size: 18px;
  font-family: $fontR;
  font-weight: 400;
}
.store_wrap h3.sub_tit {
  position: relative;
  background: url(#{$imgPath}/common/hx_cnt_case1.gif) 0 50% repeat-x;
  margin-top: 30px;
  font-size: 26px;
  color: #222;
  line-height: normal;
  letter-spacing: -2px;
  text-align: center;
}
.store_wrap h3.sub_tit .tt {
  display: inline-block;
  padding: 0 20px;
  background: #fff;
}

// .store_wrap .store_list li {
//     margin-left: 16px;
// }

.store_wrap .store_big .store_list li {
  float: left;
  width: 500px;
  height: 375px;
}
.store_wrap .store_big .store_list li .store_img {
  position: relative;
  display: block;
  height: 300px;
  background-color: #f3f3f5;
}
.store_wrap .store_big .store_list li .store_text .brand {
  text-align: left;
}
.store_wrap .com_store_tab {
  position: relative;
  width: 100%;
  margin-top: 26px;
  display: table;
  margin-left: -1px;
}
.store_wrap .com_store_tab li {
  display: table-cell;
  min-width: 145px;
  border: 1px solid #c4c4c4;
  border-left: 0;
  border-bottom: 2px solid #777;
}
.store_wrap .com_store_tab li:first-child {
  border-left: 1px solid #c4c4c4;
}
.store_wrap .com_store_tab li a {
  color: #747474;
  text-align: center;
  border-bottom: 0;
  display: block;
  font-family: $fontR;
  font-weight: 400;
  text-decoration: none;
  margin: 0 -1px;
  height: 40px;
  line-height: 40px;
}
.store_wrap .com_store_tab li.active {
  position: relative;
  border: 1px solid #777;
  border-bottom: 0;
  z-index: 50;
}
.store_wrap .com_store_tab li.active a {
  overflow: hidden;
  position: relative;
  border: 1px solid #777;
  border-bottom: 0;
  background: #fff;
  color: #222;
  font-weight: bold;
  letter-spacing: -1px;
  z-index: 50;
  margin: 0;
}
.store_wrap .com_store_tab li a:active,
.store_wrap .com_store_tab li a:focus {
  background: none;
  border-right: 1px solid transparent;
  border-left: 1px solid #c4c4c4;
}
.store_wrap .com_store_tab li.active a:active,
.store_wrap .com_store_tab li.active a:focus {
  background: #fff;
  z-index: 20;
  border-right: 1px solid #777;
  border-left: 1px solid #777;
}
.store_wrap .sub_list {
  float: left;
  margin-bottom: 40px;
}
.store_wrap .list_no {
  text-align: center;
  margin: 50px 0 70px 0;
  display: inline-block;
  width: 1020px;
  color: #8b8b8b;
  font-family: $fontR;
  font-weight: 400;
}
.store_wrap .list_no span.txt1 {
  display: block;
  font-size: 26px;
}
.store_wrap .list_no span.txt2 {
  display: block;
  font-size: 12px;
  padding-top: 15px;
}
.body_sm .com_store.store_wrap h2.tit {
  font-family: $fontR;
  font-weight: 400;
}
.body_sm .com_store.store_wrap h3.sub_tit {
  background: url(https://sui.ssgcdn.com/ui/sm/img/display/tit_bg.gif) 0 0 repeat-x;
  font-family: $fontR;
  font-weight: 400;
}
.body_sm .service.store .pr_path {
  margin: 15px 0 0;
  font-size: 13px;
  font-family: $fontR;
  font-weight: 400;
}
.body_sm .service.store .paginate {
  margin-bottom: 30px;
}
.body_sd .com_store.store_wrap h2 {
  border: none;
}
.body_sd .com_store.store_wrap h3.sub_tit {
  background: none;
  border: 1px solid #dbdbdb;
  font-size: 24px;
  color: #151515;
  line-height: 48px;
  text-align: center;
  font-weight: normal;
}
.body_sd .store_wrap .com_store_tab.sd_tab {
  margin-bottom: 0 !important;
  border: none !important;
}
.body_sd .store_wrap .com_store_tab li a {
  width: auto;
  font-size: 12px;
}

/* 공식스토어 1280 대응 */
.body_wide_ctn .com_store {
  width: 1280px;
}
.body_wide_ctn .store_wrap h2.tit {
  width: 1280px;
  font-size: 30px;
  padding: 13px 0 0 0;
  line-height: 32px;
}
.body_wide_ctn .store_wrap .store_big .store_list li {
  width: 634px;
  height: 457px;
}
.body_wide_ctn .store_wrap .store_big .store_list li .store_img {
  height: 382px;
}
.body_wide_ctn .com_store .store_list li {
  width: 305px;
}
.body_wide_ctn .com_store .store_list li .store_img {
  height: 183px;
}
.body_wide_ctn .store_wrap .list_no {
  width: 1280px;
}
