/* myssg layer */

// .layer_ssg_noti {
//   position: absolute;
//   left: 50%;
//   top: 478px;
//   width: 411px;
//   margin-left: -206px;
//   background: #fff;
//   z-index: 1000;
//   border: 1px solid #222223;
// }
// .layer_ssg_noti button {
//   -webkit-appearance: none;
//   background: none;
// }
// .layer_ssg_noti #ly_header {
//   position: relative;
//   padding: 0 50px;
//   border-bottom: 1px solid #202020;
//   text-align: center;
// }
// .layer_ssg_noti #ly_header h1 {
//   display: inline-block;
//   margin: 0 auto;
//   font-size: 22px;
//   color: #515151;
//   line-height: 53px;
//   text-align: center;
//   vertical-align: top;
//   *zoom: 1;
//   *display: inline;
//   font-weight: normal;
// }
// .layer_ssg_noti #ly_header .btn_close {
//   overflow: hidden;
//   position: absolute;
//   right: 0;
//   top: 0;
//   width: 50px;
//   height: 54px;
//   line-height: 99em;
//   vertical-align: top;
// }
// .layer_ssg_noti #ly_header .btn_close span {
//   display: inline-block;
//   width: 19px;
//   height: 19px;
//   margin-top: 17px;
//   *margin-top: 0;
//   background: url(#{$imgPath}/common/layer_closebtn.png) no-repeat;
//   vertical-align: top;
// }
// .layer_ssg_noti #ly_content {
//   position: relative;
//   text-align: center;
// }
// .layer_ssg_noti #ly_footer {
//   position: relative;
//   padding: 12px 15px 11px;
//   background: #585858;
//   font-size: 12px;
// }
// .layer_ssg_noti #ly_footer input {
//   display: inline-block;
//   margin-top: -1px;
//   vertical-align: middle;
// }
// .layer_ssg_noti #ly_footer label {
//   margin-left: 3px;
//   color: #fff;
//   vertical-align: middle;
// }
// .layer_ssg_noti #ly_footer .btn_close_s {
//   position: absolute;
//   right: 15px;
//   top: 13px;
//   font-size: 12px;
//   color: #fff;
//   vertical-align: top;
// }
// .layer_ssg_noti #ly_footer .btn_close_s span {
//   display: inline-block;
//   min-width: 26px;
//   line-height: 16px;
//   vertical-align: middle;
// }
// .layer_ssg_noti #ly_footer .btn_close_s:after {
//   content: '';
//   display: inline-block;
//   width: 10px;
//   height: 10px;
//   margin: 3px 0 0 8px;
//   background: url(#{$imgPath}/common/layer_closebtn.png) no-repeat -23px 0;
//   vertical-align: top;
// }
// .layer_ssg_noti .layer_noti_cont {
//   padding: 25px 0;
//   font-size: 12px;
//   color: #222;
//   line-height: 20px;
// }
// .layer_ssg_noti .layer_noti_cont h2 {
//   margin: 5px 0 22px;
//   font-size: 20px;
//   color: #e24f4f;
//   line-height: 25px;
//   font-weight: normal;
// }
// .layer_ssg_noti .layer_noti_cont .txt strong {
//   display: block;
// }
// .layer_ssg_noti .layer_noti_cont .txt_date {
//   display: block;
//   margin-top: 7px;
//   color: #e55756;
// }
// .layer_ssg_noti .list_type {
//   position: relative;
//   margin: -7px 40px 0;
//   text-align: left;
// }
// .layer_ssg_noti .list_type dt {
//   color: #222;
// }
// .layer_ssg_noti .list_type dd {
//   padding-left: 11px;
//   color: #666;
//   line-height: 18px;
//   text-indent: -11px;
// }
// .layer_ssg_noti .list_type dd .str {
//   color: #e24f4f;
// }

/*퀵뷰개편시 삭제*/
// .ly_cmqv {
//   display: none;
//   position: relative;
//   width: 1018px;
//   border: 1px solid #222;
//   box-shadow: 2px 2px 1px 0 rgba(0, 0, 0, 0.1);
//   outline: none;
// }
// .ly_cmqv .ly_cmqv_ifr {
//   overflow: hidden;
//   overflow-y: auto;
//   width: 1018px;
//   height: 652px;
//   vertical-align: top;
// }
// .ly_cmqv.v2 {
//   display: none;
//   position: relative;
//   width: 1020px;
//   border: 0;
//   box-shadow: none;
//   outline: none;
// }
// .ly_cmqv.v2 .ly_cmqv_in {
//   padding-top: 48px;
// }
// .ly_cmqv.v2 .ly_cmqv_cont {
//   position: relative;
//   width: 1018px;
//   border: 1px solid #222;
//   box-shadow: 2px 2px 1px 0 rgba(0, 0, 0, 0.1);
//   outline: none;
//   background: #fff;
// }
// .ly_cmqv.v2 .ly_cmqv_ifr {
//   overflow: hidden;
//   overflow-y: hidden;
//   width: 1018px;
//   height: 652px;
//   vertical-align: top;
// }
// .ly_cmqv.v2 .cmqv_btn_close {
//   display: inline-block;
//   overflow: hidden;
//   position: absolute;
//   top: -49px;
//   right: -1px;
//   z-index: 10;
//   width: 49px;
//   height: 49px;
//   background: url(#{$imgPath}/product/v2/sp_cmqv.png) no-repeat 0 -83px;
//   font-size: 0;
//   line-height: 0;
//   vertical-align: top;
// }
/*퀵뷰개편시 삭제*/

/* quickview layer */
.ly_cmqv.bly {
  display: none;
  position: relative;
  width: 1280px;
  border: 0;
  box-shadow: none;
  outline: none;
}
.ly_cmqv.bly .ly_cmqv_in {
  padding-top: 0;
}
.ly_cmqv.bly .ly_cmqv_cont {
  position: relative;
  width: 1278px;
  border: 1px solid #222;
  box-shadow: 2px 2px 1px 0 rgba(0, 0, 0, 0.1);
  outline: none;
  background: #fff;
}
.ly_cmqv.bly .ly_cmqv_ifr {
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  height: 750px;
  vertical-align: top;
}
.ly_cmqv.bly .cmqv_btn_close {
  display: inline-block;
  overflow: hidden;
  position: absolute;
  top: -49px;
  right: -1px;
  z-index: 10;
  width: 49px;
  height: 49px;
  background: url(#{$imgPath}/product/v2/sp_cmqv.png) no-repeat 0 -83px;
  font-size: 0;
  line-height: 0;
  vertical-align: top;
}
.ly_cmqv.bly.ty920 {
  width: auto;
  min-width: 922px;
}
.ly_cmqv.bly.ty920 .ly_cmqv_cont {
  width: 937px;
}
.ly_cmqv.bly.ty400 {
  width: auto;
  min-width: 402px;
}
.ly_cmqv.bly.ty400 .ly_cmqv_cont {
  width: 417px;
}

/* 합병공고 */
.mergenotice {
  padding-bottom: 40px;
  background-color: #f1f1f1;
  font-family: $fontR;
  font-weight: 400;
  font-size: 13px;
  color: #222;
  /* 띠배너 */
  .cm_linebn {
    text-align: center;
  }
  .cm_linebn img {
    vertical-align: top;
  }
  .mergenotice_row {
    margin-top: 20px;
  }
  .mergenotice_row:first-child {
    margin: 0;
  }
  .mergenotice_contwrap {
    display: none;
    padding: 1px 0;
    background-color: #fff;
  }
  .mergenotice_row.on .mergenotice_contwrap {
    display: block;
  }
  .mergenotice_cont {
    width: 1030px;
    margin: 0 auto;
  }
  .mergenotice_tit {
    padding: 30px 0 15px;
    font-size: 20px;
  }
  .mergenotice_txt p {
    margin: 0 0 20px;
    line-height: 20px;
    letter-spacing: -0.2px;
  }
  .mergenotice_close {
    padding: 0 0 40px;
    text-align: center;
  }
}

/* 공통헤더 공지 레이어 */
.sp_nl {
  display: inline-block;
  overflow: hidden;
  width: auto;
  height: 10px;
  background: url(#{$imgPath}/common/notice/sp_notice.png) no-repeat;
  line-height: 999px;
  vertical-align: top;
}
.notice_lpop {
  position: absolute;
  right: 0;
  top: 27px;
  z-index: 100;
  border: 2px solid #33353e;
}
.notice_lpop img {
  vertical-align: top;
}
.nl_cont {
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}
.nl_foot {
  height: 40px;
  line-height: 18px;
  background: #33353e;
  zoom: 1;
}
.nl_foot:after {
  display: block;
  clear: both;
  content: '';
}
.nlf_l {
  float: left;
  margin-left: 17px;
  padding-top: 14px;
  font-size: 11px;
  line-height: 12px;
  letter-spacing: -1px;
  color: #a39b97;
}
.nlf_l input {
  width: 13px;
  height: 13px;
  margin-right: 4px;
  vertical-align: top;
}
.nlf_l label {
  display: inline-block;
  margin: 1px 0 -1px;
  vertical-align: top;
  font-family: $fontR;
  font-weight: 400;
}
.nlf_r {
  float: right;
  margin-right: 6px;
  font-size: 0;
  line-height: 0;
}
.nl_close {
  display: inline-block;
  padding: 14px 14px 13px;
  background: transparent;
  line-height: 13px;
  vertical-align: top;
}
.nl_close .sp_nl {
  width: 13px;
  height: 13px;
  background-position: 0 0;
}
.ly_main_wrap {
  width: 1020px;
  margin: 0 auto;
  position: relative;
  z-index: 55;
}
.body_wide_ctn .ly_main_wrap {
  width: 1280px;
}
.nl_main {
  width: 350px;
  height: 440px;
  border: 2px solid #666;
}

// .cm_layer {
//   display: none;
//   border: 1px solid #222;
//   background: #fff;
// }
// .cm_layer .cm_layer_head {
//   position: relative;
//   text-align: center;
// }
// .cm_layer .cm_layer_head h3 {
//   margin: 0;
//   padding: 15px 0 13px;
//   font-size: 22px;
//   height: 26px;
//   line-height: 26px;
//   color: #515151;
//   background-color: #fff;
//   border-bottom: solid 2px #1e1e1e;
//   letter-spacing: -1px;
// }
// .cm_layer .cm_layer_head .btn_close {
//   position: absolute;
//   top: 17px;
//   right: 14px;
//   overflow: hidden;
//   width: 19px;
//   height: 19px;
//   background-position: -203px 0;
// }
// .cm_layer .cm_layer_body img {
//   vertical-align: top;
// }

/* 대체상품 레이어 */
.ly_chg_w {
  position: relative;
  width: 1018px;
  outline: none;
  border: 1px solid #000;
  border-top-width: 2px;
  display: none;
}
.ly_chg_cont {
  text-align: left;
  padding: 0;
  width: 800px;
  margin: 0 auto;
  padding-bottom: 40px;
}
.ly_chg_cont .table_itemlist td {
  background: none;
}
.ly_chg_cont .ly_chg_tit {
  height: 46px;
  background: url(#{$imgPath}/common/sp_change.png) 0 0 no-repeat;
  margin-top: 40px;
  border-bottom: 1px solid #e2e2e1;
}
.ly_chg_cont .ly_chg_tit.notranslate {
  font-family: $fontM;
  font-weight: 500;
  font-size: 22px;
  background: none;
  line-height: 38px;
}
.ly_chg_cont .ly_chg_close {
  position: absolute;
  top: 17px;
  right: 17px;
  height: 21px;
  width: 21px;
  background: url(#{$imgPath}/common/sp_change.png) -400px -100px no-repeat;
}
.ly_chg_cont .ly_chg_prev {
  position: absolute;
  top: 50%;
  left: 39px;
  height: 50px;
  width: 40px;
  background: url(#{$imgPath}/common/sp_change.png) -200px -100px no-repeat;
  margin-top: -25px;
}
.ly_chg_cont .ly_chg_next {
  position: absolute;
  top: 50%;
  right: 39px;
  height: 50px;
  width: 40px;
  background: url(#{$imgPath}/common/sp_change.png) -300px -100px no-repeat;
  margin-top: -25px;
}
.ly_chg_cont .ly_chg_btn {
  border-top: 1px dashed #d8d8d8;
  clear: both;
  margin: 0 26px 0 27px;
  padding-top: 20px;
  line-height: 0;
}
.ly_chg_cont .ly_chg_cart {
  height: 40px;
  width: 150px;
  background: url(#{$imgPath}/common/sp_change.png) 0 -100px no-repeat;
}
.ly_chg_goods {
  min-height: 450px;
}
.ly_chg_goods.loading {
  background: url(#{$imgPath}/common/loading.gif) 50% 50% no-repeat;
}
.ly_chg_goods .table_itemlist,
.ly_chg_goods .lst_item {
  display: none;
}
.ly_chg_goods .table_itemlist.on {
  display: block;
}
.ly_chg_goods .lst_item.on {
  display: table;
}
.ly_chg_goods .cunit_lst_v {
  display: none;
}
.ly_chg_goods .cunit_lst_v.on {
  display: block;
}

.body_wide_ctn {
  .ly_chg_goods .cunit_thmb_lst {
    width: 800px;
    padding: 0;
    > li {
      width: 232px;
      margin-right: 52px;
      &:nth-child(3n + 3) {
        margin-right: 0;
      }
    }
  }
}

.ly_chg_goods {
  .cmitem_grid,
  .mnemitem_grid {
    display: none;
    &.on {
      display: block;
    }
    .cmitem_grid_lst,
    .mnemitem_grid_lst {
      width: 800px;
      gap: 0 52px;
      > li {
        width: 232px;
      }
    }
  }
}

.body_em .ly_chg_cont .lst_item,
.body_traders .ly_chg_cont .lst_item {
  border: 0;
  margin-top: 20px;
}
.body_em .ly_chg_cont .lst_item td,
.body_traders .ly_chg_cont .lst_item td {
  border: 0;
  padding: 0;
}
.body_em .ly_chg_cont .ly_chg_btn,
.body_traders .ly_chg_cont .ly_chg_btn {
  border-top: 0;
  padding-top: 3px;
  margin: 0 0 0 31px;
}
.body_em .ly_chg_cont .ly_chg_close,
.body_traders .ly_chg_cont .ly_chg_close {
  position: absolute;
  top: 17px;
  right: 17px;
  height: 25px;
  width: 25px;
  background: url(#{$imgPath}/common/sp_change_em.png) -400px -100px no-repeat;
}
.body_em .ly_chg_cont .ly_chg_prev,
.body_traders .ly_chg_cont .ly_chg_prev {
  position: absolute;
  top: 50%;
  left: 39px;
  height: 50px;
  width: 40px;
  background: url(#{$imgPath}/common/sp_change_em.png) -200px -100px no-repeat;
  margin-top: -25px;
}
.body_em .ly_chg_cont .ly_chg_next,
.body_traders .ly_chg_cont .ly_chg_next {
  position: absolute;
  top: 50%;
  right: 39px;
  height: 50px;
  width: 40px;
  background: url(#{$imgPath}/common/sp_change_em.png) -300px -100px no-repeat;
  margin-top: -25px;
}
.body_em .ly_chg_cont .ly_chg_cart {
  background: url(#{$imgPath}/common/sp_change_em.png) 0 -100px no-repeat;
}
.body_traders .ly_chg_cont .ly_chg_cart {
  background: url(#{$imgPath}/common/sp_change_em.png) 0 -150px no-repeat;
}
