@import '../utils/variable';
@import '../utils/mixins';
@import '../utils/functions'; // 공통 변수 사용


#container {
  &.overlay {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 100;
      background-color: rgba(0, 0, 0, 0.45);
    }
  }
}
.cmgnb_navbar {
  position: relative;
  z-index: 200;
  border-bottom: 1px solid #e5e5e5;
  box-shadow: 0 4px 4px 0 rgba(108, 108, 108, 0.06);

  // &::after {
  // 	content: "";
  // 	position: absolute;
  // 	top: 100%;
  // 	z-index: 10;
  // 	width: 100%;
  // 	height: 20px;
  // 	border-top: 1px solid #e5e5e5;
  // 	box-shadow: inset 0px 4px 4px 0 rgba(108, 108, 108, 0.06);
  // }

  .cmgnb_inner {
    width: 1280px;
    height: 58px;
    margin: 0 auto;
    display: flex;
    align-items: center;
  }
}

.cmgnb_ship {
  margin-left: 23px;
  display: flex;
  .cmgnb_ship_lnk {
    display: block;
    margin-right: 8px;
  }
  .cmgnb_ship_ico {
    overflow: hidden;
    display: block;
    min-width: 84px;
    height: 30px;
    border-radius: 16px;
    background-color: rgba(0, 0, 0, 0.03);
    img {
      height: 100%;
      vertical-align: top;
    }
  }
}

.cmgnb_menu {
  flex: 1;
  text-align: right;
  ul {
    display: inline-flex;
    align-items: center;
    li {
      position: relative;
      display: inline-block;
      padding-left: 15px;
      &:first-child {
        padding-left: 0;
      }
    }
  }

  .menu_lnk {
    position: relative;
    display: block;
    color: #222;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: -0.3px;
    text-align: center;
    line-height: 27px;
    &.ty_open {
      &:after {
        content: '';
        position: absolute;
        top: -3px;
        right: -6px;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: #ff6c6b;
      }
    }
  }
  .menu_ico {
    display: inline-block;
    vertical-align: top;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.03);
    img {
      vertical-align: top;
      width: 100%;
      image-rendering: -webkit-optimize-contrast;
    }
  }
}

.cmgnb_ctg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 58px;
  height: 58px;
  margin-left: -7px;
}

.cmgnb_ctg_open {
  display: block;
  width: 44px;
  height: 44px;
  border: 2px solid #000;
  border-radius: 100%;
  box-sizing: border-box;
  background-color: #fff;
  &:focus:not(:focus-visible) {
    outline: 0;
    //box-shadow: 0 0 0 1px #e0e0e0;
  }
  &.on {
    border: 0;
    background-color: #000;
    .ico_menu {
      background-color: #fff;
      &::before,
      &::after {
        background-color: #fff;
      }
    }
  }
  .ico_menu {
    position: relative;
    display: block;
    width: 22px;
    height: 2px;
    margin: 0 auto;
    background-color: #000;
    &::before,
    &::after {
      content: '';
      position: absolute;
      z-index: 1;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: #000;
    }
    &::before {
      top: -6px;
    }
    &::after {
      bottom: -6px;
    }
  }
}

.cmctg_total {
  display: none;
  position: absolute;
  top: 58px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  &.on {
    display: block;
  }
  // &::before {
  // 	content: "";
  // 	position: fixed;
  // 	z-index: -1;
  // 	width: 100%;
  // 	height: 100%;
  // 	background-color: rgba(0, 0, 0, 0.45);
  // }
}

.cmctg_dimmed {
  position: fixed;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  user-select: none;
}
.cmctg_cont {
  border-top: 1px solid #e5e5e5;
  box-shadow: inset 0px 4px 4px 0 rgba(108, 108, 108, 0.06);
  background-color: #fff;
}
.cmctg_list {
  position: relative;
  width: 1280px;
  margin: 0 auto;
  padding: 15px 0 30px;
}
.cmctg_top_mn {
  width: 154px;
  &:first-child {
    margin-top: -2px;
  }
  &.on {
    .cmctg_top_lnk {
      color: get-color('primary');
    }
    .cmctg_sub_area {
      display: block;
      display: flex;
    }
  }
}
.cmctg_top_lnk {
  display: flex;
  align-items: center;
  height: 30px;
  line-height: 30px;
  padding: 0 2px 0 1px;
  font-size: 14px;
  font-weight: bold;
  color: #222;
  word-break: break-all;
  word-wrap: break-word;
}
.cmctg_icon_open {
  background: url(#{$imgPath}/common/ico_cmtg_open@2x.png) no-repeat;
  background-size: 100%;
  width: 40px;
  height: 17px;
  display: inline-block;
  margin: -3px 0 0 5px;
}
.cmctg_badge_open {
  width: 40px;
  height: 17px;
  display: inline-block;
  margin: -3px 0 0 5px;
  img {
    vertical-align: top;
    width: 100%;
  }
}
.cmctg_sub_area {
  display: none;
  position: absolute;
  top: 0;
  left: 154px;
  right: 0;
  // bottom: 0;
  padding: 20px 0 40px;
  min-height: 100%;
  box-sizing: border-box;
}
.cmctg_sub_inner {
  width: 1280px;
  margin: 0 auto;
  @include clearfix;
}
.cmctg_sub_menu {
  width: 100%;
  // display: table;
  // table-layout: fixed;
  // height: 100%;
}
.cmctg_sub_colgroup {
  display: flex;
}
.cmctg_sub_col {
  display: table-cell;
  vertical-align: top;
  width: 186px;
  height: 100%;
  border-left: 1px solid #f0f0f0;
}

.cmctg_sub_lst {
  max-width: 186px;
  padding: 25px 0 0 18px;
  &:first-child {
    padding-top: 0;
  }
}

.cmctg_sub_tit {
  margin-top: -2px;
  .cmctg_sub_lnk {
    font-weight: bold;
    &:hover {
      color: get-color('primary');
    }
  }
}
.cmctg_sub_mn {
  padding-top: 6px;
  &:first-child {
    padding-top: 0;
  }
  .cmctg_sub_lnk:hover {
    color: get-color('primary');
  }
}
.cmctg_sub_lnk {
  font-size: 13px;
  letter-spacing: -0.3px;
  color: #222;
}

.cmctg_sub_side {
  float: right;
  min-width: 170px;
  width: 170px;
  padding-left: 18px;
  &.has_kilr {
    border-left: 1px solid #f0f0f0;
  }
}

.cmctg_kilr {
  .cmctg_kilr_bn {
    .cmctg_kilr_bnlnk {
      display: block;
      text-decoration: none;
    }
    .cmctg_kilr_bnimg {
      position: relative;
      img {
        width: 100%;
        object-fit: cover;
      }
    }
  }
  .cmctg_kilr_lst {
    margin: 20px 0 10px 0;
  }
  .cmctg_kilr_mn {
    padding-top: 7px;
    &:first-child {
      padding-top: 0;
    }
  }
  .cmctg_kilr_lnk {
    display: inline-block;
    line-height: 20px;
    font-size: 13px;
    font-weight: bold;
    color: #222;
    letter-spacing: -0.3px;
  }
  .cmctg_kilr_badge {
    display: inline-block;
    vertical-align: top;
    margin-left: 4px;
    img {
      height: 18px;
      vertical-align: top;
    }
  }
}

.cmctg_bn {
  .cmctg_bn_lnk {
    display: block;
    text-decoration: none;
  }
  .cmctg_bn_img {
    overflow: hidden;
    position: relative;
    padding-top: 100%;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      object-fit: cover;
    }
  }

  .cmctg_bn_tit {
    padding: 7px 20px 0 0;
    color: #222;
    letter-spacing: -0.3px;
    .cmctg_bn_main {
      display: block;
      overflow: hidden;
      max-height: 34px;
      font-size: 15px;
      font-weight: bold;
    }
    .cmctg_bn_sub {
      display: block;
      overflow: hidden;
      max-height: 34px;
      font-size: 13px;
    }
  }
}
