/* 로딩 overlay */
.cmloading_overlay {
  display: none;
  z-index: 3011;
  position: absolute;
  background: url(#{$imgPath}/common/bg_opct60.png);
  background: rgba(255, 255, 255, 0.6);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.cmloading_overlay .cmloading_overlay_ico {
  height: 100%;
  background: url(#{$imgPath}/common/loading.gif) no-repeat center 400px;
}
