.help_tooltip {
  z-index: 1010;
  display: inline-block;
  height: 15px;
  padding-right: 16px;
  color: #666;
  text-decoration: none;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: -1px;
  background: url(#{$imgPath}/common/ico_helptooltip.png) 100% 0 no-repeat;
}
.tooltip_box {
  display: none;
  position: absolute;
  z-index: 1000;
  padding: 8px 10px;
  border: 1px solid #424242;
  background-color: #fff;
}
.tooltip_box.active {
  display: block;
}
.tooltip_box.small {
  width: 182px;
}
.tooltip_box.x_small {
  width: 126px;
}
.tooltip_box.medium {
  min-width: 226px;
}
.tooltip_box.small_02,
.tooltip_box.medium_02,
.tooltip_box.medium_03 {
  border-top: 2px solid #222;
}
.detail_product .tooltip_box.small_02,
.detail_product .tooltip_box.medium_02,
.detail_product .tooltip_box.medium_03 {
  border-top: 2px solid #222;
  position: absolute;
  right: 204px;
  top: 30px;
}
.detail_product .tooltip_box.small {
  position: absolute;
  right: 0;
  top: 19px;
  left: auto;
}
.detail_product.detail_2dan .tooltip_box.small_02,
.detail_product.detail_2dan .tooltip_box.medium_02,
.detail_product.detail_2dan .tooltip_box.medium_03,
.detail_product.detail_2dan .tooltip_box.small_03,
.detail_product.detail_2dan .tooltip_box.small_04 {
  border-top: 2px solid #222;
}
.tooltip_box.small .title {
  display: block;
  margin-bottom: 7px;
  padding-bottom: 5px;
  font-size: 12px;
  color: #272727;
  border-bottom: 1px dotted #666;
  line-height: 16px;
}
.tooltip_box.small .title em {
  font-size: 11px;
  font-weight: normal;
  color: #666;
}
.tooltip_box.medium .title {
  display: block;
  margin-bottom: 7px;
  padding-bottom: 5px;
  font-size: 12px;
  color: #272727;
  border-bottom: 1px dotted #666;
  line-height: 16px;
}
.tooltip_box.medium_03 .title {
  border-bottom: 1px dashed #d8d8d8;
}
.tooltip_box .dashed_line {
  margin-bottom: 5px;
  padding-bottom: 5px;
  border-bottom: 1px dashed #d8d8d8;
}
.tooltip_box .title.dashed_line {
  margin: 7px 0;
  padding-top: 5px;
  border-top: 1px dashed #d8d8d8;
}
.tooltip_box.medium .title.dotted_line {
  margin: 0 0 0;
  padding-top: 7px;
  border-top: 1px dotted #666;
  border-bottom: 0;
}
.tooltip_box.medium .title.dashed_line {
  margin: 0 0 0;
  padding-top: 7px;
  border-top: 1px dashed #d8d8d8;
  border-bottom: 0;
}
.tooltip_box p {
  font-size: 11px;
  line-height: 16px;
  color: #666;
}
.tooltip_box p.desc {
  margin-top: 3px;
  padding-top: 8px;
  border-top: 1px dashed #d8d8d8;
  font-size: 11px;
  line-height: 16px;
  color: #999;
  letter-spacing: -0.1em;
}
.tooltip_box p.desc02 {
  border-top: 1px dotted #666;
  padding: 15px 0 0 7px;
  text-indent: -7px;
}
.tooltip_box p.desc03 {
  margin-top: -10px;
  padding: 15px 0 0 7px;
  text-indent: -7px;
}
.tooltip_box p.strong {
  font-weight: bold;
  font-size: 12px;
  text-align: center;
}
.tooltip_box p.strong .em {
  color: #ef4c44;
}
.tooltip_box.x_small p {
  text-align: center;
}
.tooltip_box.x_small p.txt {
  letter-spacing: 0;
}
.tooltip_box span {
  display: inline;
  line-height: 16px;
}
.tooltip_box ul {
  padding-top: 10px;
}
.tooltip_box .data_list.small {
  padding: 10px;
  border-top: solid 1px #ddd;
  background-color: #f9f9f9;
}
.tooltip_box .data_list.small li {
  line-height: 16px;
  color: #666;
  margin-top: 5px;
}
.tooltip_box .data_list.small li:first-child {
  margin-top: 0;
}
.tooltip_box.small table {
  width: 100%;
  border-collapse: collapse;
  line-height: 16px;
  letter-spacing: -0.05em;
}
.tooltip_box.small table th {
  font-weight: normal;
  color: #666;
  font-size: 11px;
  text-align: left;
}
.tooltip_box.small table td {
  font-size: 11px;
  text-align: right;
}
.tooltip_box p.btn_wrap {
  padding: 5px 0;
  text-align: center;
}
.tooltip_box p.btn_wrap .btn {
  float: none;
}
.tooltip_box .sale_txt {
  display: block;
  margin-top: 5px;
  padding-top: 8px;
  border-top: 1px dashed #d8d8d8;
  color: #666;
  letter-spacing: -1px;
}
.tooltip_box .tooltip_close {
  display: block;
  position: absolute;
  top: 7px;
  right: 15px;
  width: 9px;
  height: 9px;
}
.tooltip_box.medium table {
  width: 100%;
  margin: -7px 0 0;
  border-collapse: collapse;
  line-height: 16px;
  letter-spacing: -0.05em;
}
.tooltip_box.medium table th {
  font-weight: normal;
  color: #666;
  font-size: 11px;
  text-align: left;
  border-bottom: 1px solid #e6e6e6;
}
.tooltip_box.medium table td {
  font-size: 11px;
  padding: 9px 0 5px 10px;
  border-bottom: 1px solid #e6e6e6;
  border-left: 1px solid #e6e6e6;
  letter-spacing: -1px;
}
.tooltip_box.medium table tr.last-child th,
.tooltip_box.medium table tr.last-child td {
  border-bottom: 0;
}
.tooltip_box.medium table td .pay_txt {
  float: right;
  *margin-top: -17px;
}
.tooltip_box.medium_03 table {
  width: 100%;
  margin: 0 0 5px;
  border-collapse: collapse;
  line-height: 16px;
  letter-spacing: -0.05em;
}
.tooltip_box.medium_03 table th {
  font-weight: normal;
  color: #666;
  font-size: 11px;
  text-align: left;
  border: 0;
}
.tooltip_box.medium_03 table td {
  font-size: 11px;
  padding: 3px 0 1px;
  border: 0;
  color: #666;
  letter-spacing: -0.1em;
}
.tooltip_box.small_03 {
  top: -98px;
  left: -26px;
}
.tooltip_box.small_03.cliping {
  width: 226px;
  top: -98px;
  left: -229px;
}
.tooltip_box.small_03.wish {
  width: 226px;
  top: -98px;
  left: 72px;
}
.tooltip_box.small_03.buy {
  top: -108px;
  left: -70px;
}
.mg_list {
  font-size: 11px;
  line-height: 16px;
  color: #666;
}
.mg_list li {
  position: relative;
  margin: 0 !important;
  padding: 0;
  padding-left: 8px;
  zoom: 1;
}
.mg_list li .bul {
  display: inline-block;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 5px;
  width: 2px;
  height: 2px;
  background: #666;
  line-height: 999px;
  vertical-align: top;
}
.detail_2dan .tooltip_box.small_03 {
  top: -97px;
  left: 5px;
}
.detail_2dan .tooltip_box.small_03.cliping {
  width: 226px;
  top: -97px;
  left: 355px;
}
.detail_2dan .tooltip_box.small_03.wish {
  width: 226px;
  top: -35px;
  left: 136px;
}
.detail_2dan .tooltip_box.small_03.buy {
  top: -108px;
  left: -70px;
}
.tooltip_box.small_03 p {
  text-align: center;
}
.tooltip_box.small_03 span.after {
  display: block;
  position: absolute;
  left: 50%;
  bottom: -10px;
  width: 11px;
  height: 10px;
  margin-left: -6px;
  background: url(#{$imgPath}/product/bg_arrow_03.png) no-repeat 0 0;
  color: #999;
  font-size: 11px;
  font-family: $fontR;
  font-weight: 400;
}
