/* 딱일 */
.cm_bdg {
  position: absolute;
  top: -15px;
  left: -8px;
  width: 53px;
  z-index: 2;
}
.cm_bdg_r {
  position: absolute;
  top: 0;
  right: 3px;
  width: 50px;
  z-index: 2;
}
.box .cm_bdg_r {
  padding-top: 16px;
}
.cm_bdg_r span {
  display: inline-block;
  width: 50px;
  height: 50px;
  background-image: url(#{$imgPath}/common/sp_bdg.png);
  background-repeat: no-repeat;
}
.cm_bdg span {
  display: inline-block;
  width: 53px;
  height: 59px;
  margin-bottom: -9px;
  background-image: url(#{$imgPath}/common/sp_bdg.png);
  background-repeat: no-repeat;
  font-size: 25px;
  color: #fff;
  text-align: center;
  letter-spacing: -1px;
  font-family: $fontR;
  font-weight: 400;
  line-height: 53px;
  overflow: hidden;
  vertical-align: top;
}
.cm_bdg em.per {
  display: inline-block;
  background: url(#{$imgPath}/common/sp_bdg.png) 0 -1200px no-repeat;
  width: 9px;
  height: 10px;
  text-indent: -1000em;
  overflow: hidden;
  margin-left: 2px;
}
.cm_bdg span.di_sale {
  background-position: 0 0;
  padding-top: 3px;
  height: 56px;
}
.cm_bdg span.di_coupon {
  background-position: 0 -150px;
  line-height: 50px;
}
.cm_bdg span.di_enuri {
  background-position: 0 -300px;
  line-height: 50px;
}
.cm_bdg span.di_plus {
  background-position: 0 -600px;
  line-height: 58px;
  font-size: 18px;
}
.cm_bdg span.di_plus em {
  color: #f9f051;
  font-size: 20px;
  vertical-align: top;
  margin-left: 1px;
}
.cm_bdg span.di_plus2 {
  background-position: 0 -450px;
}
.cm_bdg span.di_gift {
  background-position: 0 -750px;
}
.cm_bdg span.di_better {
  background-position: 0 -900px;
}
.cm_bdg span.di_sale2 {
  background-position: 0 -1050px;
}
.cm_bdg span.di_holiday,
.cm_bdg_r span.di_holiday {
  background-position: 0 -1350px;
  width: 50px;
  height: 50px;
}
.cm_bdg span.di_onefree {
  background-position: 0 -1500px;
  line-height: 58px;
  font-size: 18px;
}
.cm_bdg span.di_onefree em {
  font-size: 14px;
  margin: 0 3px;
}
.cm_bdg_r span.sprshp {
  width: 54px;
  height: 55px;
  background: url(#{$imgPath}/common/sp_sprshp.png) 0 -100px no-repeat;
  margin: 8px 0 0 -11px;
}
.cm_bdg_r span.ssgdeliv_sd {
  display: block;
  width: 60px;
  height: 63px;
  background: url(#{$imgPath}/common/sp_label.png) -150px -2340px no-repeat;
  margin: 0 0 0 -11px;
}
.table_itemlist2 .cm_bdg {
  top: -10px;
}
.cm_bdg.bdg_m {
  width: 74px;
  line-height: 1;
}
.cm_bdg.bdg_m span {
  margin-bottom: -10px;
  width: 74px;
  height: 82px;
  font-size: 34px;
  line-height: 72px;
}
.cm_bdg_r.bdg_m span {
  display: inline-block;
  width: 70px;
  height: 70px;
  background-image: url(#{$imgPath}/common/sp_bdg.png);
  background-repeat: no-repeat;
}
.cm_bdg.bdg_m em.per {
  display: inline-block;
  background: url(#{$imgPath}/common/sp_bdg.png) -100px -1200px no-repeat;
  width: 12px;
  height: 14px;
  text-indent: -1000em;
  overflow: hidden;
  margin-left: 2px;
}
.cm_bdg.bdg_m span.di_sale {
  background-position: -100px 0;
  padding-top: 3px;
  height: 79px;
}
.cm_bdg.bdg_m span.di_coupon {
  background-position: -100px -150px;
}
.cm_bdg.bdg_m span.di_enuri {
  background-position: -100px -300px;
}
.cm_bdg.bdg_m span.di_plus {
  background-position: -100px -600px;
  line-height: 80px;
  font-size: 24px;
}
.cm_bdg.bdg_m span.di_plus em {
  color: #f9f051;
  font-size: 26px;
  vertical-align: top;
  margin-left: 1px;
}
.cm_bdg.bdg_m span.di_plus2 {
  background-position: -100px -450px;
}
.cm_bdg.bdg_m span.di_gift {
  background-position: -100px -750px;
}
.cm_bdg.bdg_m span.di_better {
  background-position: -100px -900px;
}
.cm_bdg.bdg_m span.di_sale2 {
  background-position: -100px -1050px;
}
.cm_bdg.bdg_m span.di_holiday,
.cm_bdg_r.bdg_m span.di_holiday {
  background-position: -98px -1350px;
  width: 70px;
  height: 70px;
}
.cm_bdg.bdg_m span.di_onefree {
  background-position: -100px -1500px;
  line-height: 80px;
  font-size: 24px;
}
.cm_bdg.bdg_m span.di_onefree em {
  font-size: 20px;
  margin: 0 5px;
}
.cm_bdg.bdg_m span.sprshp {
  width: 75px;
  height: 76px;
  background: url(#{$imgPath}/common/sp_sprshp.png) 0 -0 no-repeat;
}
.cm_bdg.bdg_b {
  width: 114px;
}
.cm_bdg.bdg_b span {
  margin-bottom: -20px;
  width: 114px;
  height: 126px;
  font-size: 54px;
  line-height: 112px;
  font-weight: bold;
}
.cm_bdg_r.bdg_m span {
  display: inline-block;
  width: 107px;
  height: 107px;
  background-image: url(#{$imgPath}/common/sp_bdg.png);
  background-repeat: no-repeat;
}
.cm_bdg_r.bdg_m span.sprshp {
  width: 75px;
  height: 76px;
  background: url(#{$imgPath}/common/sp_sprshp.png) 0 0 no-repeat;
  margin: -2px 0 0 -26px;
}
.cm_bdg_r.bdg_m span.ssgdeliv_sd {
  width: 90px;
  height: 94px;
  background: url(#{$imgPath}/common/sp_label.png) -300px -2340px no-repeat;
  margin: -2px 0 0 -26px;
}
.cm_bdg.bdg_b em.per {
  display: inline-block;
  background: url(#{$imgPath}/common/sp_bdg.png) -200px -1200px no-repeat;
  width: 18px;
  height: 26px;
  text-indent: -1000em;
  overflow: hidden;
  margin-left: 2px;
}
.cm_bdg.bdg_b span.di_sale {
  background-position: -200px 0;
  padding-top: 10px;
  height: 116px;
}
.cm_bdg.bdg_b span.di_coupon {
  background-position: -200px -150px;
}
.cm_bdg.bdg_b span.di_enuri {
  background-position: -200px -300px;
}
.cm_bdg.bdg_b span.di_plus {
  background-position: -200px -600px;
  line-height: 132px;
  font-size: 38px;
}
.cm_bdg.bdg_b span.di_plus em {
  color: #f9f051;
  font-size: 44px;
  vertical-align: middle;
  margin-left: 1px;
  margin-top: -11px;
  display: inline-block;
}
.cm_bdg.bdg_b span.di_plus2 {
  background-position: -200px -450px;
}
.cm_bdg.bdg_b span.di_gift {
  background-position: -200px -750px;
}
.cm_bdg.bdg_b span.di_better {
  background-position: -200px -900px;
}
.cm_bdg.bdg_b span.di_sale2 {
  background-position: -200px -1050px;
}
.cm_bdg.bdg_b span.di_holiday,
.cm_bdg_r.bdg_b span.di_holiday {
  background-position: -200px -1350px;
  width: 107px;
  height: 107px;
}
.cm_bdg.bdg_b span.di_onefree {
  background-position: -200px -1500px;
  line-height: 132px;
  font-size: 38px;
}
.cm_bdg.bdg_b span.di_onefree em {
  font-size: 30px;
  margin: 0 5px;
}

.cm_best {
  text-align: center;
  padding: 11px 8px 3px;
  height: 28px;
}
.cm_best span {
  font-size: 20px;
  color: #181818;
  border-bottom: 3px solid #000;
  font-family: $fontM;
  font-weight: 500;
  line-height: 28px;
  padding: 0 9px 0 7px;
  letter-spacing: -1px;
  background-color: #fff;
}
.cm_delivery {
  position: absolute;
  top: 0;
  right: 0;
}
.cm_delivery span.sprshp {
  display: inline-block;
  width: 75px;
  height: 76px;
  background: url(#{$imgPath}/common/sp_sprshp.png) 0 -0 no-repeat;
}
.cm_delivery span.ssgdeliv_sd {
  display: inline-block;
  width: 90px;
  height: 90px;
  background: url(#{$imgPath}/common/sp_label.png) -300px -2340px no-repeat;
}
