.clip_product_bx {
  display: none;
  position: absolute;
  left: -5px;
  top: 35px;
  border: 1px solid #666;
  z-index: 100;
  background: url(#{$imgPath}/common/bg_clip_line_02.gif) no-repeat 0 0 #fff;
}
.clip_product_bx.recommend_setup {
  display: block;
  position: absolute;
  left: 50%;
  top: 100px;
  margin-left: -362px;
  z-index: 999999;
}
.clip_product_bx.clip_wcase3.property {
  position: absolute;
  left: 56px;
  top: 49px;
  border: 1px solid #222;
}
.clip_product_bx.clip_wcase5.property {
  position: absolute;
  left: 56px;
  top: 49px;
  border: 1px solid #222;
}
.clip_product_bx.clip_wcase6.property {
  position: absolute;
  left: 0;
  top: 105px;
  border: 1px solid #222;
}
.clip_product_bx .clip_product_sec {
  margin: 0 20px 10px 20px;
}
.clip_product_bx .restructure {
  margin: 15px 10px;
  text-align: center;
}
.clip_product_bx .btn_close {
  position: absolute;
  right: 15px;
  top: 13px;
  z-index: 30;
}
.clip_product_bx .mark_arrow {
  position: absolute;
  top: -8px;
  left: 72px;
  width: 13px;
  height: 8px;
  background: url(#{$imgPath}/product/bg_arrow_02.gif) no-repeat;
}
.clip_product_bx.clip_type {
  width: 322px;
  background: #fff;
  left: -27px;
  padding: 0;
}
.clip_product_bx.clip_type .restructure {
  margin: 13px 0 0 22px;
  text-align: left;
}
.clip_product_bx.clip_type .clip_title {
  display: inline-block;
  padding-bottom: 12px;
  border-bottom: 1px dotted #a7a7a7;
}
.clip_title p {
  padding-top: 9px;
  color: #999;
  font-size: 12px;
  text-align: center;
}
.clip_product_bx.clip_type .clip_area {
  position: relative;
  margin: 18px 0 0 11px;
  zoom: 1;
}
.clip_product_bx.clip_type .clip_r {
  margin-bottom: 10px;
  zoom: 1;
}
.clip_product_bx.clip_type .clip_area strong {
  width: 94px;
  display: block;
  float: left;
  color: #f52052;
}
.clip_product_bx.clip_type .my_size {
  float: left;
  width: 81px;
  padding-top: 0;
}
.clip_product_bx.clip_type .my_size li {
  float: left;
  margin: 0 5px 0 0;
}
.clip_area .btn_resize {
  position: absolute;
  right: 38px;
  top: 0;
  display: inline-block;
  width: 73px;
  height: 19px;
  background: url(#{$imgPath}/common/clip_resize.png) no-repeat 0 0;
}
.clip_area .btn_size {
  display: inline-block;
  width: 83px;
  height: 19px;
  background: url(#{$imgPath}/common/clip_btn_size.png) no-repeat;
}
.clip_type .my_brand {
  float: left;
  text-align: left;
  padding-top: 0;
}
.clip_type .my_brand span {
  display: inline-block;
  color: #777;
}
.clip_type .my_brand_etc {
  display: inline-block;
  margin-left: 5px;
  font-weight: bold;
  color: #222;
}
.clip_product_bx.clip_type_v .restructure {
  margin: 13px 0 23px 22px;
  margin-top: 20px;
}
.clip_type_v .my_brand {
  width: 173px;
}
.clip_type_v .my_brand span {
  color: #232323;
}
.clip_type_v .clip_best_area {
  margin-top: 13px;
  padding: 11px 0 7px 9px;
  background: #f9f9f9;
}
.clip_type_v .clip_best_area p {
  padding-bottom: 3px;
  font-weight: bold;
  color: #353535;
}
.clip_type_v .clip_best_lst {
  padding-left: 3px;
}
.clip_type_v .clip_best_lst li {
  padding-bottom: 3px;
}
.clip_type_v .clip_best_lst li a {
  color: #666;
  text-decoration: none;
  float: none;
  margin: 0;
}
.clip_type_v .clip_best_lst li a:hover,
.clip_type_v .clip_best_lst li a:active,
.clip_type_v .clip_best_lst li a:focus {
  font-weight: bold;
  color: #ff4873;
}
.clip_type_v .clip_type .clip_best_area strong {
  width: 100%;
  color: #353535;
}
.clip_product_bx.clip_type .clip_r:after {
  clear: both;
  display: block;
  content: '';
}
.clip_product_bx.clip_type .clip_area:after {
  clear: both;
  display: block;
  content: '';
}
.search_section .clip_product_bx.clip_wcase3.property .mark_arrow {
  left: 428px;
}
.category_section .clip_product_bx.clip_wcase3.property .mark_arrow {
  left: 413px;
}
.clip_product_sec .clip_top_tit {
  position: relative;
  height: 37px;
  padding-top: 10px;
  *zoom: 1;
  text-align: center;
}
.clip_wcase2 .clip_product_sec .clip_top_tit {
  text-align: left;
}
.clip_product_sec .clip_top_tit:after {
  clear: both;
  display: block;
  content: '';
}
.clip_product_sec .clip_top_tit h2 {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  padding-left: 27px;
  font-size: 19px;
  font-weight: bold;
  color: #222;
  line-height: 28px;
  background: url(#{$imgPath}/common/ico_heart.jpg) no-repeat 0 3px;
}
.clip_product_sec .clip_top_tit h2 a {
  color: #222;
  text-decoration: none;
}
.clip_product_sec .clip_top_tit .bar {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  width: 1px;
  height: 9px;
  margin: 4px 10px 0;
  background: #ccc;
}
.clip_product_sec .clip_top_tit .txt {
  font-size: 11px;
}
.clip_product_bx.clip_wcase5.property .clip_product_sec .clip_top_tit .ico_heart {
  position: absolute;
  left: 310px;
  top: 11px;
  width: 24px;
  height: 20px;
  background: url(#{$imgPath}/common/ico_heart.jpg) no-repeat 0 0;
}
.clip_product_bx.clip_wcase3.property .clip_product_sec .clip_top_tit .ico_heart {
  position: absolute;
  left: 168px;
  top: 11px;
  width: 24px;
  height: 20px;
  background: url(#{$imgPath}/common/ico_heart.jpg) no-repeat 0 0;
}
.clip_product_bx.clip_wcase2 .bn_ar {
  padding-bottom: 30px;
}
.clip_product_sec .clip_top_type2 h2 {
  font-size: 22px;
}
.clip_product_sec .clip_top_type3 {
  padding-left: 0;
  background: none;
  border-bottom: 1px dotted #666;
  color: #666;
}
.clip_product_sec .clip_top_type3 h2 {
  font-size: 22px;
}
.clip_product_sec .clipping_sec {
  height: 48px;
  border-bottom: 1px dashed #d8d8d8;
  line-height: 48px;
  *zoom: 1;
}
.clip_product_sec .clipping_sec:after {
  clear: both;
  display: block;
  content: '';
}
.clip_product_sec .clipping_sec p {
  float: left;
  font-size: 12px;
  color: #666;
  font-weight: bold;
}
.clip_product_sec .clipping_sec .btn_setting {
  float: right;
  width: 56px;
  height: 28px;
  margin-top: 10px;
  background: url(#{$imgPath}/common/bg_btn_setting.gif) no-repeat;
  text-indent: -1000em;
  overflow: hidden;
}
.clip_product_sec .content_field {
  position: relative;
  margin: 0 -20px 0 -16px;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 615px;
}
.clip_product_sec .content_field .clip_recomlist {
  overflow: hidden;
  width: 475px;
  padding-left: 20px;
}
.clip_product_sec .content_field .clip_recomlist .itembx .thumbnail {
  height: auto;
  margin-top: 0 !important;
  position: relative !important;
}
.clip_product_sec .content_field .clip_recomlist .itembx .thumbnail a {
  width: 140px;
}
.clip_product_sec .content_field .btn_viewmore {
  display: block;
  height: 30px;
  margin-top: 20px;
  padding-top: 13px;
  border: 1px solid #f5f5f5;
  border-bottom: 1px solid #d3d3d3;
  background: #fdfdfd;
  text-align: center;
}
.clip_product_sec .new_itembx {
  padding: 17px 0 0 20px;
}
.clip_product_sec .new_itembx .tit {
  padding-bottom: 17px;
  color: #666;
}
.clip_product_sec .new_itembx .tit strong {
  margin-right: 5px;
  color: #00aac4;
}
.clip_product_sec .new_itembx .tit em {
  color: #ff6865;
  padding-left: 5px;
}
.clip_product_sec .new_itembx .tit em a {
  color: #ff6865;
  text-decoration: none;
}
.clip_product_sec .new_itembx ul {
  width: auto;
  *zoom: 1;
}
.clip_product_sec .new_itembx ul:after {
  display: block;
  clear: both;
  content: '';
}
.clip_product_sec .new_itembx ul li {
  float: left;
  margin: 0 1px 10px 0;
}
.clip_product_sec .new_itembx ul li a {
  display: block;
  width: 108px;
  height: 108px;
  border: 1px solid #d8d8d8;
}
.clip_product_sec .new_itembx ul li a img {
  width: 108px;
  height: 108px;
}
.clip_product_sec .new_itembx .itembx {
  width: 110px;
  height: 110px;
}
.clip_product_sec .new_itembx .itembx .thumbnail {
  width: 110px;
  height: 110px;
  position: relative !important;
}
.clip_product_sec .new_itembx .itembx .thumbnail .ico_left {
  top: 4px;
  left: -4px;
}
.clip_product_sec .new_itembx .itembx .thumbnail .coupon_sale span,
.clip_product_sec .new_itembx .itembx .thumbnail .sale span {
  letter-spacing: -1px;
  font-size: 18px;
  padding-top: 2px;
}
.clip_product_sec .new_itembx .itembx .thumbnail .coupon_sale span em,
.clip_product_sec .new_itembx .itembx .thumbnail .sale span em {
  font-size: 12px;
  vertical-align: 3px;
}
.clip_product_sec .new_itembx .itembx .thumbnail .coupon_sale .persent,
.clip_product_sec .new_itembx .itembx .thumbnail .sale .persent {
  top: 7px;
}
.clip_product_sec .new_itembx .itembx .thumbnail .thmb_over_bg {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url(#{$imgPath}/common/bg_clip_thmb_over.png);
}
.clip_product_sec .new_itembx .itembx .thumbnail .thmb_over {
  display: none;
  position: absolute;
  left: 10px;
  bottom: 10px;
  width: 90px;
  color: #fff;
  line-height: 14px;
  font-size: 11px;
  text-align: center;
  cursor: pointer;
}
.clip_product_sec .new_itembx .itembx .thumbnail .thmb_over .clip_tx_tit {
  display: block;
  overflow: hidden;
  max-height: 28px;
  margin-bottom: 5px;
}
.clip_product_sec .new_itembx .itembx .thumbnail .thmb_over del,
.clip_product_sec .new_itembx .itembx .thumbnail .thmb_over .price_tt,
.clip_product_sec .new_itembx .itembx .thumbnail a:hover .thmb_over_bg,
.clip_product_sec .new_itembx .itembx .thumbnail a:hover .thmb_over {
  display: block;
}
.clip_product_sec .catelst_box {
  margin: 20px -10px 0;
  *zoom: 1;
}
.clip_product_bx.clip_wcase2.recommend_setup .clip_product_sec .bn_ar {
  clear: both;
}
.clip_product_sec .catelst_box:after {
  clear: both;
  display: block;
  content: '';
}
.clip_product_sec .catelst_box .lst_box {
  float: left;
  width: 328px;
  margin: 0 10px;
  border-top: 1px solid #666;
}
.clip_product_sec .catelst_box .lst_box input {
  display: inline-block;
  width: 13px;
  height: 13px;
  margin: 1px 6px 0 0;
  padding: 0;
  vertical-align: top;
}
.clip_product_sec .catelst_box .lst_box .tit {
  padding-left: 10px;
  height: 38px;
  background: #eee;
  font-weight: bold;
  font-size: 12px;
  color: #222;
  line-height: 38px;
}
.clip_product_sec .catelst_box .lst_box .tit em {
  color: #00aac4;
}
.clip_product_sec .catelst_box .lst_box .lst {
  overflow-x: hidden;
  position: relative;
  overflow-y: auto;
  height: 195px;
  border-top: 1px solid #d1d1d1;
}
.clip_product_sec .catelst_box .lst_box .lst ul li {
  display: block;
  height: 18px;
  padding: 10px 20px 10px 12px;
  border-bottom: 1px solid #e5e5e5;
  white-space: nowrap;
}
.clip_product_sec .catelst_box .lst_box .lst ul li label {
  display: inline-block;
  overflow: hidden;
  width: auto;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #666;
}
.clip_product_sec .catelst_box .lst_box .lst_del {
  position: absolute;
  margin: 0 0 0 10px;
  vertical-align: top;
}
.clip_product_sec .capture_sec {
  padding: 40px 0 5px;
  text-align: center;
}
.clip_product_sec .capture_choice .bn_cl,
.clip_product_sec .capture_sec .bn_cl {
  overflow: hidden;
  width: 295px;
  padding-left: 10px;
  float: left;
}
.clip_product_sec .capture_choice .btn_cl_start,
.clip_product_sec .capture_sec .btn_cl_start {
  float: right;
  display: inline-block;
  padding-right: 22px;
  width: 164px;
  height: 54px;
  margin: 0 auto;
  background: url(#{$imgPath}/common/clip_guide.png) -500px -61px no-repeat;
}
.clip_product_sec .capture_sec h3 {
  width: 275px;
  height: 26px;
  margin: 0 auto;
  background: url(#{$imgPath}/common/clip_guide.png) no-repeat;
}
.clip_product_sec .capture_sec p.advice {
  width: 386px;
  height: 16px;
  margin: 12px auto 38px;
  color: #666;
  font-size: 12px;
  background: url(#{$imgPath}/common/clip_guide.png) 0 -50px no-repeat;
}
.clip_product_sec .capture_choice .capture_img {
  width: 458px;
  height: 364px;
  margin: 0 auto 20px;
  padding-right: 7px;
  padding-bottom: 35px;
  border-bottom: 1px solid #d8d8d8;
  background: url(#{$imgPath}/common/clip_guide_02.png) 0 0px no-repeat;
}
.clip_product_sec .capture_sec .capture_img {
  width: 454px;
  height: 406px;
  margin: 0 auto 20px;
  padding-right: 7px;
  padding-bottom: 35px;
  border-bottom: 1px solid #d8d8d8;
  background: url(#{$imgPath}/common/clip_guide.png) 0 -200px no-repeat;
}
.clip_product_sec .capture_sec2 {
  position: relative;
  padding: 0;
  text-align: center;
  background: url(#{$imgPath}/common/bg_clip_guide.jpg) 0 0 no-repeat;
  width: 512px;
  height: 620px;
  margin: -2px 0 0 -20px;
}
.clip_product_sec .capture_sec2 .btn_clip_info {
  position: absolute;
  width: 162px;
  height: 36px;
  top: 565px;
  left: 333px;
}
.clip_product_sec .capture_choice .bn_ar .btn_cl_go,
.clip_product_sec .capture_sec .bn_ar .btn_cl_go {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  width: 137px;
  height: 23px;
  margin: 0 auto;
  background: url(#{$imgPath}/common/clip_guide.png) -500px 0 no-repeat;
}
.clip_product_sec .cllipping_check {
  padding: 17px 0;
}
.clip_product_sec .cllipping_check .check_data {
  background: url(#{$imgPath}/common/bg_underline.gif) left bottom repeat-x;
}
.clip_product_sec .cllipping_check .size_sort {
  position: relative;
  padding: 20px 0;
}
.clip_product_sec .cllipping_check .brand_sort {
  padding-bottom: 20px;
}
.clip_product_sec .cllipping_check .check_data .check_inner {
  overflow: hidden;
}
.clip_product_sec .cllipping_check .check_data .check_inner .no_info {
  text-indent: -100px;
  text-align: center;
  color: #ff6865;
}
.clip_product_sec .cllipping_check .check_data .brand_lst {
  overflow: hidden;
  width: 712px;
  *zoom: 1;
}
.clip_product_sec .cllipping_check .check_data .brand_lst li {
  float: left;
  width: 177px;
  color: #666;
}
.clip_product_sec .cllipping_check .check_data .brand_lst li p {
  padding: 0;
}
.clip_product_sec .cllipping_check .check_data .brand_lst li .tit {
  padding-bottom: 2px;
  color: #747474;
}
.clip_product_sec .cllipping_check .size_sort .size_lst li {
  width: 100%;
  padding-bottom: 15px;
  color: #666;
  *zoom: 1;
}
.clip_product_sec .cllipping_check .size_sort .size_lst li:after {
  clear: both;
  display: block;
  content: '';
}
.clip_product_sec .cllipping_check .size_sort .size_lst li strong {
  display: block;
  float: left;
  width: 119px;
  padding-left: 9px;
  background: url(#{$imgPath}/common/bg_bullet02.gif) no-repeat 0 50%;
}
.clip_product_sec .cllipping_check .size_sort .size_lst li div {
  float: left;
}
.clip_product_sec .cllipping_check .size_sort .size_lst .size_group {
  width: 400px;
  margin-top: 2px;
  padding: 0 0 0 1px;
  border: 0;
  *zoom: 1;
}
.clip_product_sec .cllipping_check .size_sort .size_lst .size_group span {
  width: 98px;
  margin-bottom: 5px;
}
.clip_product_sec .cllipping_check .size_sort .size_lst span {
  display: block;
  float: left;
  margin-left: -1px;
  border-left: 1px dotted #d8d8d8;
  font-size: 12px;
  line-height: 12px;
}
.clip_product_sec .cllipping_nbox {
  overflow: hidden;
  padding: 20px 0 30px;
}
.clip_product_sec .cllipping_nbox .noti {
  float: left;
  height: 22px;
  padding-left: 30px;
  background: url(#{$imgPath}/common/ico_notice.gif) no-repeat;
  font-weight: bold;
  line-height: 22px;
  color: #666;
}
.clip_product_sec .cllipping_nbox .btn_size_setting {
  float: right;
  height: 26px;
  margin-top: -5px;
  padding: 0 11px;
  border: 1px solid #ccc;
  background: #fcfcfc;
  font-weight: bold;
  line-height: 26px;
  text-decoration: none;
  color: #666;
}
.clip_product_sec .cllipping_type2 {
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  height: 888px;
  padding: 32px 0 0;
}
.clip_product_sec .cllipping_type2 .btn_viewmore {
  display: block;
  height: 30px;
  padding-top: 13px;
  border: 1px solid #f5f5f5;
  border-bottom: 1px solid #d3d3d3;
  background: #fdfdfd;
  text-align: center;
}
.clip_product_sec .cllipping_type2:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}
.clip_product_sec .cllipping_type2 .clip_itemlist {
  clear: both;
}
.clip_product_sec .cllipping_type2 .cllipping_box {
  position: relative;
  *zoom: 1;
}
.clip_product_sec .cllipping_type2 .cllipping_sec {
  position: relative;
}
.clip_product_sec .cllipping_type2 .cllipping_sec.open {
  width: 835px;
  margin-bottom: 46px;
  padding: 20px 0;
  text-align: center;
  border: 1px solid #e5e5e5;
}
.clip_product_sec .cllipping_type2 .cllipping_sec.open p {
  width: 800px;
  margin: 0 20px;
  color: #999;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
}
.clip_product_sec .cllipping_type2 .cllipping_sec a.clip {
  position: absolute;
  left: 370px;
  bottom: -14px;
}
.clip_product_sec .cllipping_type2 .cllipping_sec.close {
  width: 785px;
  margin-bottom: 46px;
  padding: 25px 25px 30px;
  border: 1px solid #e5e5e5;
}
.clip_product_sec .cllipping_type2 .btn_area {
  margin-top: 25px;
  text-align: center;
}
.clip_product_sec .cllipping_type2 .btn_area a {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  float: none;
  min-width: 78px;
  margin-right: 4px;
  border: 1px solid #3b3b3b;
  border-radius: 3px;
  background: #3b3b3b;
  font-weight: bold;
  line-height: 26px;
  color: #fff;
  vertical-align: top;
  text-decoration: none;
}
.clip_product_sec .cllipping_type2 .btn_area a.cancel {
  background: #fcfcfc url(#{$imgPath}/common/bg_border02.gif) left top repeat-x;
  color: #666;
  border: 1px solid #ccc;
}
.clip_product_sec .cllipping_type2 .itemlst {
  padding: 0 30px;
}
.clip_product_sec .cllipping_type2 .check_tit {
  float: left;
  width: 97px;
  padding-left: 11px;
  background: url(#{$imgPath}/common/bu_circle.gif) no-repeat 0 50%;
}
.clip_product_sec .cllipping_type2 .check_data .brand_lst li input {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  width: 13px;
  height: 13px;
  margin: 1px 6px 0 0;
  padding: 0;
  vertical-align: top;
}
.clip_product_sec .cllipping_type2 .size_sort .size_lst li:after {
  display: block;
  clear: both;
  content: '';
}
.clip_product_sec .cllipping_type2 .size_sort .size_lst li input {
  display: inline-block;
  width: 13px;
  height: 13px;
  margin: 1px 6px 0 0;
  padding: 0;
  vertical-align: top;
}
.clip_product_sec .cllipping_type2 .size_sort .size_lst li span {
  border: 0;
}
.clip_product_sec .cllipping_type2 .size_sort .size_lst li label {
  display: inline-block;
  margin-top: 2px;
}
.clip_product_sec .cllipping_type2 .size_sort a {
  position: absolute;
  right: 0;
  top: 20px;
  display: inline-block;
  width: 138px;
  height: 26px;
  line-height: 26px;
  font-weight: bold;
  color: #666;
  text-align: center;
  text-decoration: none;
  background: #fcfcfc;
  border: 1px solid #ccc;
  border-radius: 3px;
}
.clip_product_sec .cllipping_type2 .clip_btn_sec {
  margin-bottom: 20px;
  border-bottom: 1px dashed #d8d8d8;
}
.clip_product_sec .cllipping_type2 .btn_size_setting {
  position: absolute;
  right: 10px;
  top: -10px;
  height: 26px;
  padding: 0 11px;
  border: 1px solid #ccc;
  background: #fcfcfc;
  font-weight: bold;
  line-height: 26px;
  text-decoration: none;
  color: #666;
}
.clip_product_sec .cllipping_type2 .itemlst li {
  height: 440px;
}
.clip_product_sec .clip_mysize_setting {
  padding: 26px 0 22px;
  color: #666;
}
.clip_product_sec .clip_mysize_setting .tit {
  padding-bottom: 8px;
  font-size: 16px;
  color: #000;
}
.clip_product_sec .size_select_sec {
  position: relative;
  min-height: 242px;
  margin-top: 22px;
  padding: 30px 246px 0 0;
  border-top: 1px solid #ececec;
  border-bottom: 1px dashed #d8d8d8;
}
.clip_product_sec .size_select_sec .choice_size li {
  width: 100%;
  margin-bottom: 28px;
  *zoom: 1;
}
.clip_product_sec .size_select_sec .choice_size li:after {
  clear: both;
  display: block;
  content: '';
}
.clip_product_sec .size_select_sec .choice_size li strong {
  float: left;
  width: 116px;
}
.clip_product_sec .size_select_sec .choice_size li ul {
  float: left;
  width: 390px;
  margin: -4px 0 0 0;
}
.clip_product_sec .size_select_sec .choice_size li ul li {
  position: relative;
  float: left;
  width: auto;
  height: 23px;
  margin: 3px 2px 2px 3px;
}
.clip_product_sec .size_select_sec .choice_size li ul li label {
  display: block;
  width: 58px;
  height: 21px;
  border: 1px solid #e5e5e5;
  background: #fff;
  font-size: 12px;
  text-align: center;
  line-height: 21px;
  color: #000;
  text-decoration: none;
}
.clip_product_sec .size_select_sec .choice_size li ul li .scheck {
  overflow: hidden;
  top: 1px;
  left: 1px;
  width: 1px;
  height: 1px;
  opacity: 0;
  filter: alpha(opacity= '1');
  z-index: -1;
}
.clip_product_sec .size_select_sec .choice_size li ul li .checked {
  display: none;
  position: absolute;
  top: -7px;
  right: -5px;
  width: 19px;
  height: 19px;
  background: url(#{$imgPath}/product/ico_checked.png) center center no-repeat;
}
.clip_product_sec .size_select_sec .choice_size li ul li.checked .checked {
  display: block;
}
.clip_product_sec .size_select_sec .choice_size li ul li.checked label {
  border: 1px solid #000;
}
.clip_product_sec .capture_choice {
  padding: 40px 0 10px;
  text-align: center;
}
.clip_product_sec .capture_choice h3 {
  width: 370px;
  height: 59px;
  margin: 0 auto 20px auto;
  background: url(#{$imgPath}/common/clip_guide.png) no-repeat 0 -100px;
}
.clip_product_sec .capture_choice p {
  padding: 0 0 27px;
  color: #000;
  font-size: 12px;
}
.clip_product_sec .capture_choice p a {
  color: #0000cc;
  font-weight: bold;
}
.clip_product_sec .sorting_area {
  float: none;
  width: 779px;
  height: 51px;
  padding: 0 30px;
  border-top: 1px solid #bfbfbf;
  border-bottom: 1px dotted #d9d9d9;
}
.clip_product_sec .sorting_area a {
  height: 51px;
  line-height: 51px;
}
.clip_product_sec .sorting_area a.active {
  text-decoration: none;
  background: url(#{$imgPath}/common/bg_underline02.gif) left 40px repeat-x;
}
.clip_product_sec .btn_clip_more {
  border: 1px solid #dfdfdf;
  display: block;
  text-align: center;
  line-height: 0;
  background: #f8f8f8;
  height: 23px;
  padding-top: 6px;
  margin: 20px 20px 13px;
}
.clip_product_sec .btn_clip_more img {
  vertical-align: top;
}
.clip_wcase {
  width: 512px;
  background: url(#{$imgPath}/common/bg_clip_line_new.gif) no-repeat 0 0 #fff;
}
.clip_wcase2 {
  width: 716px;
  border-top: 2px solid #222;
}
.clip_wcase3 {
  width: 532px;
}
.clip_wcase4 {
  width: 710px;
}
.clip_wcase5 {
  overflow: hidden;
  width: 876px;
  border-top: 2px solid #222;
  border-right: 1px solid #222;
  border-bottom: 1px solid #222;
  border-left: 1px solid #222;
}
.clip_wcase5 .clip_product_sec {
  margin: 0 0 20px 20px;
  overflow: hidden;
}
.clip_wcase6 {
  width: 800px;
  border: 1px solid #222;
}
.clip_wcase7 {
  width: 169px;
  background: #fff !important;
}
.clip_wcase8 {
  width: 278px;
  background: #fff !important;
}
.clip_wcase7 strong,
.clip_wcase8 strong {
  line-height: 16px;
  color: #666;
  font-size: 11px;
  letter-spacing: -1px;
}
.clip_wcase8 p {
  padding-top: 9px;
  text-align: center;
  color: #999;
  font-size: 11px;
}
.clip_wcase9 {
  width: 512px;
}
.clp_ly_bx {
  display: none;
  position: absolute;
  width: 452px;
  height: 635px;
  background: #fff;
  border: 1px solid #666;
  box-shadow: 0px 2px 10px #595959;
  -webkit-box-shadow: 0px 2px 10px #595959;
  margin-top: 13px;
}
.clp_ly_bx .sp_bx_clip {
  background-image: url(#{$imgPath}/common/sp_bx_clip.png);
  background-repeat: no-repeat;
}
.clp_ly_bx .arr {
  position: absolute;
  width: 23px;
  height: 12px;
  background-position: -200px -100px;
  left: 23px;
  top: -12px;
}
.clp_ly_bx .clp_ly_tit {
  background-position: 0 0;
  width: 452px;
  height: 41px;
}
.clp_ly_bx .btn_close {
  background: url(#{$imgPath}/common/b.gif);
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
}
.clp_ly_logout {
  margin: 20px auto 0 auto;
  width: 412px;
  height: 518px;
  background: url(#{$imgPath}/common/sp_bx_clip.png) 0 -200px no-repeat;
}
.clp_ly_logout .btn_login {
  background: url(#{$imgPath}/common/b.gif);
  float: right;
  width: 90px;
  height: 50px;
}
.clp_ly_btn {
  border-top: 1px solid #d8d8d8;
  padding-top: 10px;
  text-align: center;
  width: 412px;
  margin: 0 auto;
}
.clp_ly_btn a {
  display: inline-block;
  margin: 0 1px 0 0;
}
.clp_ly_btn .btn1 {
  background-position: 0 -50px;
  width: 88px;
  height: 35px;
}
.clp_ly_btn .btn2 {
  background-position: -100px -50px;
  width: 90px;
  height: 35px;
}
.clp_ly_btn .btn3 {
  background-position: -200px -50px;
  width: 90px;
  height: 35px;
}
.clp_ly_cont {
  padding: 20px 20px 0 20px;
}
.clp_ly_cont .clp_ly_tab:after {
  content: '';
  display: block;
  clear: both;
}
.clp_ly_cont .clp_ly_tab li {
  float: left;
  width: 50%;
}
.clp_ly_cont .clp_ly_tab li a {
  display: block;
  text-align: center;
  height: 40px;
  line-height: 40px;
  border-bottom: 2px solid #747474;
  background-color: #f8f8f8;
  border-top: 1px solid #bfbfbf;
  color: #747474;
  text-decoration: none;
}
.clp_ly_cont .clp_ly_tab li.active a {
  background-color: #fff;
  border: 2px solid #777;
  color: #777;
  font-weight: bold;
  border-bottom: none;
  height: 41px;
}
.clp_ly_cont .clp_ly_scr {
  height: 455px;
  width: 412px;
  overflow-x: hidden;
  overflow-y: auto;
  margin-top: 20px;
  padding-right: 20px;
}
.clp_ly_cont .clp_ly_scr2 {
  height: 371px;
  width: 412px;
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 20px;
}
.clp_ly_cont .clp_ly_scr3 {
  height: 433px;
  width: 412px;
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 20px;
}
.clp_ly_cont .clp_ly_co {
  width: 412px;
  font-family: $fontR;
  font-weight: 400;
}
.clp_ly_cont .clp_ly_link {
  display: inline-block;
  text-decoration: none;
  color: #747474;
  line-height: 19px;
  border: 1px solid #d8d8d8;
  padding: 1px 14px 0 10px;
  height: 18px;
  overflow: hidden;
  background: url(#{$imgPath}/common/bu_arr.png) 100% 7px no-repeat;
}
.clp_ly_cont .clp_ly_lst dt {
  border-top: 1px dashed #d8d8d8;
  border-bottom: 1px dashed #d8d8d8;
  line-height: 21px;
  padding: 4px 0 5px 9px;
}
.clp_ly_cont .clp_ly_lst dt:after {
  content: '';
  display: block;
  clear: both;
}
.clp_ly_cont .clp_ly_lst dt strong {
  float: left;
  color: #404040;
  padding-top: 2px;
  line-height: 19px;
}
.clp_ly_cont .clp_ly_lst dt strong em {
  color: #00c8e6;
}
.clp_ly_cont .clp_ly_lst dt .clp_ly_link {
  float: right;
}
.clp_ly_cont .clp_ly_lst dd {
  padding: 11px 0 19px 0;
}
.clp_ly_cont .clp_ly_lst li {
  vertical-align: top;
  line-height: 20px;
  padding-left: 15px;
  background: url(#{$imgPath}/common/bu_dot.png) 10px 8px no-repeat;
}
.clp_ly_cont .clp_ly_lst li span.txt_clp,
.clp_ly_cont .clp_ly_lst li a {
  vertical-align: middle;
  color: #747474;
  text-decoration: none;
}
.clp_ly_cont .clp_ly_lst li a:hover {
  text-decoration: underline;
}
.clp_ly_cont .clp_ly_lst li a.link_my_clip {
  color: #f8566b;
}
.clp_ly_cont .clp_ly_lst li a.clp_ly_link {
  margin-left: 8px;
}
.clp_ly_cont .clp_ly_lst li a.clp_ly_link:hover {
  text-decoration: none;
}
.clp_ly_cont .clp_ly_lst li span.gt {
  vertical-align: middle;
  color: #747474;
  margin: 0 3px 0 4px;
}
.clp_ly_cont .clp_ly_msg {
  padding-top: 28px;
  height: 76px;
  border-bottom: 1px dashed #d8d8d8;
}
.clp_ly_cont .clp_ly_msg p {
  background-image: url(#{$imgPath}/common/sp_bx_clip.png);
  background-repeat: no-repeat;
  height: 50px;
  width: 412px;
}
.clp_ly_cont .clp_ly_msg p.tx_no_brand {
  background-position: 0 -750px;
}
.clp_ly_cont .clp_ly_msg p.tx_no_goods {
  background-position: 0 -850px;
}
.clp_ly_cont .clp_ly_lst2 li {
  border-bottom: 1px dashed #d8d8d8;
  padding: 14px 5px 14px 5px;
}
.clp_ly_cont .clp_ly_lst2 li:after {
  content: '';
  display: block;
  clear: both;
}
.clp_ly_cont .clp_ly_lst2 li .tit {
  float: left;
  font-size: 14px;
  color: #747474;
  line-height: 32px;
  width: 255px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  letter-spacing: -1px;
}
.clp_ly_cont .clp_ly_lst2 li .clp_btn_sec {
  float: right;
  width: 147px;
}
.clp_ly_cont .clip_wrap {
  float: left;
  width: 27px;
  height: 26px;
  overflow: hidden;
  margin-top: 3px;
}
.clp_ly_cont .clip_wrap .clip_btn {
  background: url(#{$imgPath}/common/sp_bx_clip.png) 0 -100px no-repeat;
  width: 27px;
  height: 26px;
  float: left;
}
.clp_ly_cont .clip_wrap.clip_red .clip_btn {
  background-position: -40px -100px;
}
.clp_ly_cont .clip_wrap.clip_yellow .clip_btn {
  background-position: -80px -100px;
}
.clp_ly_cont .clp_ly_lst2 .brand_go {
  background: url(#{$imgPath}/common/sp_bx_clip.png) -300px -50px no-repeat;
  width: 110px;
  height: 32px;
  float: left;
  margin-left: 10px;
}
.clp_ly_cont .clp_tit_gs {
  font-weight: bold;
  color: #404040;
  height: 24px;
  padding-top: 18px;
  border-bottom: 1px dashed #d8d8d8;
}
.clp_ly_cont .clp_tit_gs em {
  color: #00c8e6;
}
.clp_ly_cont .clp_ly_lst3 {
  width: 414px;
  margin-left: -1px;
  padding-top: 10px;
}
.clp_ly_cont .clp_ly_lst3:after {
  content: '';
  display: block;
  clear: both;
}
.clp_ly_cont .clp_ly_lst3 li {
  float: left;
  width: 168px;
  padding: 10px 19px;
  border-left: 1px dotted #bcbcbc;
  border-bottom: 1px dotted #bcbcbc;
}
.clp_ly_cont .clp_ly_lst3 li .itembx .thumbnail a {
  width: 168px;
  height: 168px;
}
.clp_ly_cont .clp_ly_lst3 li .itembx .thumbnail .ico_left {
  top: 2px;
}
.clp_ly_cont .clp_ly_lst3 li .itembx .thumbnail .thmb_over_bg {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000;
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.clp_ly_cont .clp_ly_lst3 li .itembx .thumbnail .thmb_over {
  display: none;
  position: absolute;
  top: 52px;
  left: 9px;
  width: 150px;
  text-align: center;
  color: #fff;
  line-height: 17px;
}
.clp_ly_cont .clp_ly_lst3 li .itembx .thumbnail .thmb_over .clip_tx_tit {
  display: block;
  margin-bottom: 11px;
  max-height: 34px;
  overflow: hidden;
}
.clp_ly_cont .clp_ly_lst3 li .itembx .thumbnail .thmb_over del {
  display: block;
}
.clp_ly_cont .clp_ly_lst3 li .itembx .thumbnail .thmb_over .price_tt {
  font-weight: bold;
}
.clp_ly_cont .clp_ly_lst3 li .itembx .thumbnail a:hover .thmb_over {
  display: block;
}
.clp_ly_cont .clp_ly_lst3 li .itembx .thumbnail a:hover .thmb_over_bg {
  display: block;
}
.clp_ly_cont .clp_ly_more {
  background: #fff;
  margin-top: -1px;
  padding: 10px 0;
  text-align: center;
  position: relative;
}
.clp_ly_cont .btn_gs_more {
  display: inline-block;
  vertical-align: top;
  width: 172px;
  height: 42px;
  background-position: 0 -150px;
}
.clp_ly_cont .clp_gs_btm {
  padding: 10px 0 0 0;
}
.clp_ly_cont .clp_gs_btm:after {
  content: '';
  display: block;
  clear: both;
}
.clp_ly_cont .clp_gs_btm .clip_wrap {
  float: right;
  margin: 0;
}

/* 클립개선 */
.sp_ccp {
  display: inline-block;
  overflow: hidden;
  background-image: url(#{$imgPath}/common/sp_ccp.png) !important;
  background-repeat: no-repeat;
  vertical-align: top;
  line-height: 999px;
}
.cm_clip_dtl a.sel_clip {
  width: 85px;
  height: 55px;
  background-position: 0 0;
}
.cm_clip_dtl.on a.sel_clip {
  background-position: -89px 0;
}
.cm_clip_item a.sel_clip {
  width: 32px;
  height: 32px;
  margin-right: -1px;
  background-position: -86px -60px;
}
.cm_clip_item.on a.sel_clip {
  background-position: -124px -60px;
}
.cm_clip_layer {
  position: absolute;
  top: 0 !important;
  left: 0 !important;
  z-index: 999999;
}
.cm_clip_layer .dimmed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 600;
  opacity: 0.5;
  filter: alpha(opacity=50);
  cursor: pointer;
}
.cm_clip_layercancel .cm_layer_area .lp_close {
  top: 14px;
  right: 14px;
}
.cm_clip_layer .cm_layer_area {
  position: relative;
  z-index: 700;
  padding: 33px 0 27px;
  border: 1px solid #222;
  background: #fff;
  text-align: center;
}
.cm_clip_layer .cm_layer_area .cm_lp_tit {
  font-family: $fontR;
  font-weight: 400;
  font-size: 15px;
  color: #222;
  line-height: 20px;
}
.cm_clip_layer .cm_layer_area .cm_lp_tit .point_txt {
  color: #fb016c;
}
.cm_clip_layer .cm_layer_area .btn_area {
  margin-top: 12px;
}
.cm_clip_layer .cm_layer_area .btn_area a {
  width: 76px;
  height: 32px;
}
.cm_clip_layer .cm_layer_area .btn_area a.cm_btn {
  margin-right: 3px;
  background-position: -174px -90px;
}
.cm_clip_layer .cm_layer_area .btn_area a.cm_btn2 {
  background-position: -254px -90px;
}
.cm_clip_layer .lp_close {
  position: absolute;
  overflow: hidden;
  right: 20px;
  top: 16px;
  width: 19px;
  height: 19px;
  background-position: -203px 0;
  vertical-align: baseline;
}
.cm_clip_layer .ico_clip {
  margin-right: 5px;
  background-position: -174px -126px;
  width: 23px;
  height: 20px;
}
.cm_clip_layer .cm_tit {
  margin-left: -2px;
  font-family: $fontR;
  font-weight: 400;
  font-size: 19px;
  color: #222;
  text-align: left;
  line-height: 21px;
}
.cm_clip_layer .cm_clip_bx {
  padding: 12px 0 11px;
  margin-top: 12px;
  min-height: 85px;
  font-family: $fontR;
  font-weight: 400;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  -webkit-border-radius: 5px;
}
.cm_clip_info_lst {
  zoom: 1;
}
.cm_clip_info_lst li {
  float: left;
  width: 134px;
  border-left: 1px solid #e5e5e5;
}
.cm_clip_info_lst li:first-child {
  border-left: 0;
}
.cm_clip_info_lst li .cm_info {
  width: 57px;
  height: 54px;
  background-position: -334px -80px;
}
.cm_clip_info_lst li .cm_info2 {
  width: 56px;
  height: 54px;
  background-position: -395px -82px;
}
.cm_clip_info_lst li .cm_info_txt {
  display: block;
  margin-top: 5px;
  font-family: $fontR;
  font-weight: 400;
  font-size: 14px;
  color: #7a7a7a;
  line-height: 18px;
}
.cm_clip_info_lst li .cm_info_txt em {
  color: #343434;
}
.cm_clip_info_lst:after {
  content: '';
  display: block;
  clear: both;
}
.cm_clip_layer .cm_clip_bx .cm_clip_info {
  display: inline-block;
  font-size: 14px;
  color: #444;
  text-align: left;
  line-height: 16px;
  vertical-align: top;
}
.cm_clip_layer .cm_clip_bx2 {
  padding: 17px 0 12px 0;
  text-align: center;
}
.cm_clip_layer .cm_clip_bx2 .btn_myclip {
  width: 86px;
  height: 23px;
  background-position: 0 -581px;
}
.cm_clip_layer .cm_clip_bx2 .btn_recomclip {
  width: 86px;
  height: 23px;
  background-position: 0 -608px;
}
.cm_clip_layercheck .cm_layer_area {
  position: fixed;
  width: 271px;
  top: 50%;
  left: 50%;
  margin: -115px 0 0 -136px;
  padding: 16px 23px 8px;
  z-index: 700;
}
.cm_clip_layercancel .cm_layer_area {
  position: fixed;
  width: 248px;
  top: 50%;
  left: 50%;
  margin: -73px 0 0 -124px;
  z-index: 700;
}
.body_sfc .cm_clip {
  display: none !important;
}
.body_sfc .goods_item .item .pop_area li.wish_list {
  display: none;
}
.body_sfc .goods_item .item .pop_area {
  width: 105px;
  margin-left: -52px;
  border-right: 1px solid #d9d9d9;
}
.body_sfc .csm_lst_h .pop_area {
  overflow: hidden;
  height: 105px;
  border-bottom: 1px solid #d9d9d9;
}
.body_sfc .item_tbl .item .pop_area {
  overflow: hidden;
  height: 105px;
  border-bottom: 1px solid #d9d9d9;
}
.body_sfc .goods_img_type .btn_detail {
  width: 105px;
  left: 50%;
  margin-left: -52px;
  border-right: 1px solid #d9d9d9;
}
.body_sfc .goods_list_type .btn_detail {
  overflow: hidden;
  height: 105px;
  border-bottom: 1px solid #d9d9d9;
}
