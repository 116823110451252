/* 신규몰 홍보 구좌 */
.cmgrand_open_sec .cmgrand_open_imgbx {
  position: relative;
  height: 392px;
}
.cmgrand_open_sec .cmgrand_open_img {
  width: 100%;
  vertical-align: top;
}
.cmgrand_open_sec .cmgrand_open_listbx {
  margin: -26px 0 14px;
  text-align: center;
}
.cmgrand_open_sec .cmgrand_open_list {
  position: relative;
  display: inline-block;
  padding: 0 14px;
  border: 2px solid #fff;
  border-radius: 27px;
  background: #fff;
  box-sizing: border-box;
  font-size: 0;
}
.cmgrand_open_sec .cmgrand_open_list li {
  display: inline-block;
  position: relative;
  vertical-align: top;
  text-align: center;
}
.cmgrand_open_sec .cmgrand_open_list li a {
  overflow: hidden;
  position: relative;
  z-index: 1;
  display: block;
  width: 90px;
  height: 52px;
  padding: 0 14px;
  font-size: 16px;
  font-family: $fontR;
  font-weight: 400;
  color: #222;
  word-break: break-word;
  word-wrap: break-word;
  letter-spacing: -0.3px;
  line-height: 52px;
}
.cmgrand_open_sec .cmgrand_open_list li:hover a {
  color: #fff;
  text-decoration: none;
}
.cmgrand_open_sec .cmgrand_open_list li:hover:after {
  position: absolute;
  top: 0;
  left: -14px;
  bottom: 0;
  right: -14px;
  border-radius: 26px;
  box-sizing: border-box;
  border: 1px solid #222;
  background: #222;
  content: '';
}
/* 몰별 키컬러값 */
.cmgrand_open_sec.sm .cmgrand_open_list li:hover:after {
  border: 1px solid #f12e24;
  background: #f12e24;
}
.cmgrand_open_sec.sd .cmgrand_open_list li:hover:after {
  border: 1px solid #535455;
  background: #535455;
}
.cmgrand_open_sec.em .cmgrand_open_list li:hover:after {
  border: 1px solid #ffd040;
  background: #ffd040;
}
.cmgrand_open_sec.traders .cmgrand_open_list li:hover:after {
  border: 1px solid #a6dd27;
  background: #a6dd27;
}
.cmgrand_open_sec.boots .cmgrand_open_list li:hover:after {
  border: 1px solid #1b448e;
  background: #1b448e;
}
.cmgrand_open_sec.tv .cmgrand_open_list li:hover:after {
  border: 1px solid #e2231a;
  background: #e2231a;
}
.cmgrand_open_sec.siv .cmgrand_open_list li:hover:after {
  border: 1px solid #222;
  background: #222;
}
.cmgrand_open_sec.outlet .cmgrand_open_list li:hover:after {
  border: 1px solid #d9117c;
  background: #d9117c;
}
.cmgrand_open_sec.sf .cmgrand_open_list li:hover:after {
  border: 1px solid #b12536;
  background: #b12536;
}
.cmgrand_open_sec.howdy .cmgrand_open_list li:hover:after {
  border: 1px solid #000;
  background: #000;
}
.cmgrand_open_sec.mnmorning .cmgrand_open_list li:hover:after {
  border: 1px solid #a3b7cd;
  background: #a3b7cd;
}
// 신규몰 수정(06.11)
.cmgrand_open_sec {
  &.v2,
  &.v3 {
    .cmgrand_open_imgbx {
      height: 180px;
      .cmgrand_open_imglink {
        overflow: hidden;
        display: block;
        width: inherit;
        height: inherit;
      }
    }
    .cunit_thmb_lst {
      height: 608px;
    }
  }
  &.v3 {
    .cmgrand_open_linkbx {
      bottom: 31px;
      margin-bottom: -31px;
      .cmgrand_open_link {
        padding: 0 10px;
        bottom: 28px;
        border: 0;
        li {
          margin: 0 4px;
          padding: 7px 11px 7px 15px;
          background-color: #fff;
          border-radius: 15.5px;
          border: solid 1px rgba(34, 34, 34, 0.1);
          &.active:after {
            bottom: -29px;
          }
          a {
            &:before {
              content: none;
            }
            &:after {
              display: inline-block;
              vertical-align: middle;
              width: 4px;
              height: 4px;
              border-top: 1px solid #222;
              border-right: 1px solid #222;
              margin: 2px;
              transform: rotate(45deg);
              content: '';
            }
          }
        }
      }
    }
  }
  .ico_grand_open {
    position: absolute;
    left: -18px;
    top: 8px;
    width: 135px;
    height: 46px;
  }
  .cmgrand_open_btn {
    margin: 0 auto;
    text-align: center;
    font-size: 0;
    .cmgrand_open_btnprev,
    .cmgrand_open_btnnext {
      width: 40px;
      height: 40px;
      background: url(#{$imgPath}/common/sp_main_slide.png) no-repeat;
    }
    .cmgrand_open_btnprev {
      background-position: 0 0;
    }
    .cmgrand_open_btnprev:hover {
      background-position: 0 -100px;
    }
    .cmgrand_open_btnprev.disabled {
      background-position: 0 -50px;
    }
    .cmgrand_open_btnnext {
      background-position: -50px 0;
    }
    .cmgrand_open_btnnext:hover {
      background-position: -50px -100px;
    }
    .cmgrand_open_btnnext.disabled {
      background-position: -50px -50px;
    }
  }
  .cmgrand_open_quick {
    position: absolute;
    right: 17px;
    bottom: 15px;
    width: 113px;
    height: 32px;
    border: 1px solid #fff;
    background: #222;
    text-align: center;
    font-family: $fontR;
    font-weight: 400;
    z-index: 10;
    span {
      line-height: 33px;
      font-size: 12px;
      color: #fff;
      &:after {
        display: inline-block;
        margin-left: 5px;
        background: url(#{$imgPath}/main/ico_arrow.png) no-repeat;
        background-size: 17px;
        vertical-align: middle;
        width: 17px;
        height: 9px;
        content: '';
      }
    }
  }
  .cmgrand_open_linkbx {
    position: relative;
    text-align: center;
    bottom: 30px;
    margin-bottom: -30px;
    //타몰대응
    line-height: 1.5em;
    font-size: 0.75em;
    .cmgrand_open_link {
      display: inline-block;
      position: relative;
      padding: 0 10px;
      background: none;
      border-top: 1px solid rgba(34, 34, 34, 0.1);
      border-bottom: 1px solid rgba(34, 34, 34, 0.1);
      bottom: 30px;
      box-sizing: border-box;
      font-size: 0;
      li {
        display: inline-block;
        position: relative;
        vertical-align: middle;
        text-align: center;
        padding: 4px 10px;
        &.active {
          font-weight: bold;
          &:after {
            position: absolute;
            left: 50%;
            bottom: -29px;
            border-bottom: 9px solid currentColor;
            border-left: 8px solid transparent !important;
            border-right: 8px solid transparent !important;
            transform: translateX(-50%);
            content: '';
          }
        }
        a {
          display: block;
          overflow: hidden;
          position: relative;
          color: #222;
          font-size: 13px;
          line-height: 15px;
          letter-spacing: -0.26px;
          font-family: $fontR;
          font-weight: 400;
          word-break: break-word;
          word-wrap: break-word;
          z-index: 1;
          &:before {
            content: '#';
          }
          //타몰대응
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
  .cmgrand_open_tipbx {
    position: relative;
    font-family: $fontR;
    font-weight: 400;
    background-color: currentColor;
    .cmgrand_open_tip {
      display: block;
      padding: 12px 0;
      font-size: 14px;
      text-align: center;
      color: #fff;
    }
  }
  /* 고해상도 대응 */
  @media screen and (-webkit-min-device-pixel-ratio: 2) {
    .cmgrand_open_quick span:after {
      background-image: url(#{$imgPath}/main/ico_arrow@2x.png);
    }
  }
}
