// =============================================================================
// PC 전몰 공통 믹스인
// =============================================================================

// Common
@mixin clearfix {
  &:after {
    clear: both;
    content: '';
    display: block;
  }
}

@mixin ellipsis($width: 100%) {
  display: block;
  max-width: $width;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin breakword {
  word-break: break-all;
  word-wrap: break-word;
}

// Font Face
@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
  $src: null;

  $extmods: (
    eot: '?',
    svg: '#' + str-replace($name, ' ', '_')
  );

  $formats: (
    otf: 'opentype',
    ttf: 'truetype'
  );

  @each $ext in $exts {
    $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append($src, url(quote($path + '.' + $extmod)) format(quote($format)), comma);
  }

  @font-face {
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight;
    src: $src;
  }
}
// ellipsis multiline
@mixin ellipsis-multiline($line) {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
  word-break: break-all;
}

// default : @include filter('변수값');
// 투명도 있을 때 : @include filter('변수값', 투명도));
@mixin filter($color: 'black', $opacity: 1) {
  $color-value: map-get($colors, $color);

  $r: calc(red($color-value) / 255);
  $g: calc(green($color-value) / 255);
  $b: calc(blue($color-value) / 255);
  $a: $opacity;

  // grayscale fallback if SVG from data url is not supported
  $lightness: lightness($color-value);
  filter: saturate(0%) brightness(0%) invert($lightness) opacity($opacity);

  // color filter
  $svg-filter-id: 'recolor';
  filter: url('data:image/svg+xml;utf8,\
    <svg xmlns="http://www.w3.org/2000/svg">\
      <filter id="#{$svg-filter-id}" color-interpolation-filters="sRGB">\
        <feColorMatrix type="matrix" values="\
          0 0 0 0 #{$r}\
          0 0 0 0 #{$g}\
          0 0 0 0 #{$b}\
          0 0 0 #{$a} 0\
        "/>\
      </filter>\
    </svg>\
    ##{$svg-filter-id}');
}
