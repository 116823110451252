/* 몰 TAB */
.cmall_tabarea {
  position: relative;
  height: 43px;
  margin: 40px 0 20px;
  border-bottom: 2px solid #222;
  font-size: 14px;
  font-family: $fontR;
  font-weight: 400;
  z-index: 99;
}
.cmall_tab {
  display: table;
  width: 100%;
  table-layout: fixed;
}
.cmall_tab li {
  display: table-cell;
  color: #bbb;
  text-align: center;
  vertical-align: middle;
  border-top: 1px solid #dcdcdc;
  border-left: 1px solid #dcdcdc;
  background: #fafafa;
  height: 42px;
}
.cmall_tab li:first-child {
}
.cmall_tab li:last-child {
  border-right: 1px solid #dcdcdc;
}
.cmall_tab li a,
.cmall_tab li span,
.cmall_tab li span.notranslate {
  display: inline-block;
  padding: 0;
  line-height: 15px;
}
.cmall_tab li a {
  color: #222;
  text-decoration: none;
}
.cmall_tab li:first-child {
  border-left: 1px solid #dcdcdc;
}
.cmall_tab li:first-child a {
  margin-left: 0;
}
.cmall_tab li.active {
  background-color: #fff;
  border-right: 2px solid #222;
  border-color: #222;
  border-width: 2px;
  position: relative;
  z-index: 10;
  font-weight: bold;
  height: 41px;
}
.cmall_tab li.active:before {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px;
  right: 0;
  height: 2px;
  background-color: #fff;
}
.cmall_tab li:first-child.active {
  right: 0;
}
.cmall_tab li:last-child.active {
  right: 0;
}

/* 카테고리 몰 TAB */
.category_section .cmall_tabarea {
  margin: 10px 0 0;
}
/* 상품평, 이벤트 몰 TAB */
.content_myssg .cmall_tabarea,
.content_primary .cmall_tabarea,
.content_csc .cmall_tabarea {
  height: auto;
  margin: 21px 0 0;
  border-bottom-color: #777;
  font-size: 12px;
}
.content_myssg .cmall_tab li,
.content_primary .cmall_tab li,
.content_csc .cmall_tab li {
  color: #747474;
}
.content_myssg .cmall_tab li a,
.content_myssg .cmall_tab li span,
.content_primary .cmall_tab li a,
.content_primary .cmall_tab li span {
  min-width: 86px;
  border-color: #c4c4c4;
  background: transparent;
}
.content_csc .cmall_tab li a,
.content_csc .cmall_tab li span {
  min-width: 77px;
  border-color: #c4c4c4;
  background: #fff;
}
.content_myssg .cmall_tab li.active a,
.content_primary .cmall_tab li.active a,
.content_csc .cmall_tab li.active a {
  border-color: #777;
  letter-spacing: -1px;
}
