/* 공통 몰메인 팝업 */
.cmmain_notipop {
  position: relative;
  z-index: 310;
  width: 1020px;
  margin: 0 auto;
}
.cmmain_notipop .cmmain_notipop_inr {
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  font-size: 0;
  white-space: nowrap;
}
.cmmain_notipop .cmnotipop {
  display: inline-block;
  margin: 10px 0 0 10px;
  font-size: 12px;
  vertical-align: top;
}
.cmmain_notipop .nl_main {
  display: inline-block;
  position: relative;
  right: auto;
  top: auto;
  z-index: auto;
  margin: 10px 0 0 10px;
  font-size: 12px;
  vertical-align: top;
}
.cmnotipop {
  width: 365px;
  height: 396px;
  border: 2px solid #222;
  font-family: $fontR;
  font-weight: 400;
  white-space: normal;
  word-break: break-all;
  background: #fff;
}
.cmnotipop .cmnotipop_cont {
  position: relative;
  background: #fff;
  overflow-y: auto;
  max-height: 357px;
  height: 357px;
  > a:first-child {
    position: relative;
    z-index: 1;
  }
}
.cmnotipop .cmnotipop_img img {
  width: 100%;
  height: 238px;
  vertical-align: top;
}
.cmnotipop .cmnotipop_detail {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 50px 40px 0;
  color: #222;
}
.cmnotipop .cmnotipop_tit {
  display: block;
  margin-bottom: 23px;
  font-weight: bold;
  font-size: 24px;
  line-height: normal;
}
.cmnotipop .cmnotipop_tx {
  margin-top: 25px;
  font-size: 16px;
  line-height: 1.25;
  color: #666;
  letter-spacing: 0.2px;
}
.cmnotipop .cmnotipop_subtx {
  margin-top: 10px;
  font-size: 16px;
  line-height: 1.25;
  color: #888;
  // text-align: center;
}
.cmnotipop .cmnotipop_subtx_inr {
  display: inline-block;
  text-align: left;
  vertical-align: top;
}
.cmnotipop .cmnotipop_link_inr {
  margin-top: 10px;
  padding-bottom: 20px;
  &.rgt {
    text-align: right;
  }
}
.cmnotipop .cmnotipop_link {
  display: inline-block;
  font-size: 14px;
  text-decoration: underline;
  color: #000;
}
.cmnotipop .cmnotipop_banner {
  img {
    vertical-align: top;
    width: 100%;
  }
}
.cmnotipop .cmnotipop_foot {
  height: 40px;
  background: #222;
}
.cmnotipop .cmnotipop_foot:after {
  display: block;
  clear: both;
  content: '';
}
.cmnotipop .cmnotipop_l {
  float: left;
  margin: 11px 0 0 12px;
}
.cmnotipop .cmnotipop_r {
  float: right;
  margin-right: 5px;
}
.cmnotipop .cmnotipop_chk {
  width: 18px;
  height: 18px;
  margin-right: 10px;
  vertical-align: top;
}
.cmnotipop .cmnotipop_lbl {
  font-size: 13px;
  line-height: 19px;
  color: #d1d1d1;
  vertical-align: top;
}
.cmnotipop .cmnotipop_close {
  padding: 10px;
  background: transparent;
  line-height: normal;
}
.cmnotipop .cmnotipop_close:before {
  display: block;
  width: 20px;
  height: 20px;
  background: url(#{$imgPath}/common/notice/ico_cmnotipop_close.png) 0 0;
  content: '';
}
.cmnotipop.v2 {
  height: 440px;
  width: 350px;
  border: 2px solid #666;
  .cmnotipop_cont {
    height: 400px;
    max-height: 400px;
  }
  .cmnotipop_img img {
    height: auto;
  }
  .cmnotipop_foot {
    padding-top: 2px;
    margin-left: -2px;
    height: 40px;
    width: 354px;
    line-height: 18px;
    background: #33353e;
    zoom: 1;
  }
  .cmnotipop_foot:after {
    display: block;
    clear: both;
    content: '';
  }
  .cmnotipop_l {
    float: left;
    margin: 0 0 0 17px;
    padding-top: 14px;
  }
  .cmnotipop_l .cmnotipop_chk {
    width: 13px;
    height: 13px;
    margin-right: 4px;
    vertical-align: top;
  }
  .cmnotipop_l .cmnotipop_lbl {
    display: inline-block;
    margin: 1px 0 -1px;
    vertical-align: top;
    font-family: $fontR;
    font-weight: 400;
    font-size: 11px;
    line-height: 12px;
    letter-spacing: -1px;
    color: #a39b97;
  }
  .cmnotipop_r {
    float: right;
    margin-right: 6px;
    font-size: 0;
    line-height: 0;
  }
  .cmnotipop_close {
    display: inline-block;
    padding: 14px 14px 13px;
    background: transparent;
    line-height: 13px;
    vertical-align: top;
  }
  .cmnotipop_close:before {
    display: inline-block;
    overflow: hidden;
    width: auto;
    height: 10px;
    background: url(#{$imgPath}/common/notice/sp_notice.png) no-repeat;
    line-height: 999px;
    vertical-align: top;
    width: 13px;
    height: 13px;
    background-position: 0 0;
  }
}
/* 공통 몰메인 팝업 - 몰별 분기 */
.body_wide_ctn .cmmain_notipop {
  width: 1280px;
}
.body_ssg .cmmain_notipop {
  top: 20px;
  z-index: 210;
  width: 1240px;
}
.body_sm .top_banner + .cmmain_notipop {
  top: -100px;
}
.body_em .top_bn_tti + .cmmain_notipop {
  top: -100px;
}
.body_boots .cmmain_notipop {
  z-index: 190;
}
.body_siv .cmmain_notipop {
  z-index: 190;
}
.body_sf .cmmain_notipop {
  z-index: 300;
}
.body_howdy .cmmain_notipop {
  top: 145px;
  z-index: 3100;
  width: 1280px;
}
/* 고해상도 대응 */
@media screen and (-webkit-min-device-pixel-ratio: 2) {
  .cmnotipop .cmnotipop_close:before {
    background-position: -15px 0;
    background-size: 35px auto;
  }
}

/* 온라인 포인트카드 발급 팝업 */
.cmmain_notipop .ptcd_main {
  display: inline-block;
  position: relative;
  font-family: $fontR;
  font-weight: 400;
  margin: 10px 0 0 10px;
  font-size: 12px;
  vertical-align: top;
  width: 650px;
  height: 569px;
  border: 1px solid #eee;
  background: #fff;
}
.cmmain_notipop .ptcd_main .pop_header {
  position: relative;
  text-align: center;
  height: 54px;
}
.cmmain_notipop .ptcd_main .pop_header h1 {
  margin: 0;
  padding: 14px 0 13px;
  font-weight: bold;
  font-size: 22px;
  height: 26px;
  line-height: 28px;
  color: #222;
  background-color: #fff;
  border-bottom: 2px solid #1e1e1e;
  letter-spacing: -1px;
}
.cmmain_notipop .ptcd_main .pop_content .ptcd_intro {
  height: 211px;
  background: url(#{$imgPath}/common/img_ptcd_barcode.png) 8px 13px no-repeat;
}
.cmmain_notipop .ptcd_main .pop_content .ptcd_intro .ptcd_intro_txt {
  padding: 93px 0 0 315px;
}
.cmmain_notipop .ptcd_main .pop_content .ptcd_intro .ptcd_intro_txt h2 {
  font-size: 16px;
  letter-spacing: -0.4px;
  font-weight: bold;
  line-height: 23px;
}
.cmmain_notipop .ptcd_main .pop_content .ptcd_intro .ptcd_intro_txt p {
  font-size: 13px;
  color: #9b9b9b;
  letter-spacing: -0.4px;
  line-height: 19px;
  margin-top: 7px;
}
.cmmain_notipop .ptcd_main .pop_content .ptcd_agr_lst {
  border-top: 1px solid #222;
  border-bottom: 1px solid #222;
}
.cmmain_notipop .ptcd_main .pop_content .ptcd_agr_lst li {
  position: relative;
  border-bottom: 1px solid #eee;
  height: 42px;
  line-height: 42px;
  padding-left: 11px;
}
.cmmain_notipop .ptcd_main .pop_content .ptcd_agr_lst li:last-child {
  border-bottom: none;
}
.cmmain_notipop .ptcd_main .custom_chk {
  position: relative;
  min-width: 18px;
  display: inline-block;
  min-height: 18px;
  word-wrap: normal;
  word-break: normal;
  vertical-align: top;
  line-height: 42px;
}
.cmmain_notipop .ptcd_main .custom_chk input[type='checkbox'] {
  position: absolute;
  top: 11px;
  width: 18px;
  height: 18px;
  outline: 0 none;
  opacity: 0;
  filter: alpha(opacity=0);
}
.cmmain_notipop .ptcd_main .custom_chk:after {
  position: absolute;
  top: 11px;
  left: 0;
  width: 18px;
  height: 18px;
  background: url(#{$imgPath}/common/omni_sp_chk.png) 0 0 no-repeat;
  content: '';
}
.cmmain_notipop .ptcd_main .custom_chk label {
  display: inline-block;
  position: relative;
  z-index: 10;
  padding-left: 22px;
  font-size: 14px;
  color: #222;
  cursor: pointer;
}
.cmmain_notipop .ptcd_main .custom_chk input:focus + label {
  outline: 1px dotted #808080;
  outline: auto -webkit-focus-ring-color;
}
.cmmain_notipop .ptcd_main .custom_chk.on:after {
  background-position: 0 -30px;
}
.cmmain_notipop .ptcd_main .pop_content .ptcd_agr_lst li .agr_btn_dtl {
  position: absolute;
  top: 1px;
  right: 0;
  font-size: 12px;
  color: #888;
  line-height: 42px;
  letter-spacing: -1px;
}
.cmmain_notipop .ptcd_main .pop_content .ptcd_agr_lst li .agr_btn_dtl:hover {
  text-decoration: underline;
}
.cmmain_notipop .ptcd_main .pop_content .ptcd_agr_lst li .agr_btn_dtl .ico_arr {
  display: inline-block;
  width: 6px;
  height: 10px;
  margin: -1px 0 0 3px;
  background: url(#{$imgPath}/common/omni_ico_btn.png) no-repeat 0 0;
  vertical-align: middle;
}
.cmmain_notipop .ptcd_main .btn_cnf {
  width: 100%;
  height: 50px;
  background: get-color('primary');
  color: #fff;
  text-align: center;
  line-height: 50px;
  font-weight: bold;
  font-size: 15px;
  margin-top: 30px;
}
.cmmain_notipop .ptcd_main .pop_footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 34px;
  background: #eee;
  text-align: center;
  z-index: 99;
}
.cmmain_notipop .ptcd_main .pop_footer .custom_chk {
  line-height: 35px;
}
.cmmain_notipop .ptcd_main .pop_footer .custom_chk input[type='checkbox'] {
  top: 8px;
}
.cmmain_notipop .ptcd_main .pop_footer .custom_chk:after {
  top: 8px;
}
.cmmain_notipop .ptcd_main .pop_footer .custom_chk label {
  font-size: 13px;
  color: #777;
  padding-left: 26px;
}

.cmmain_notipop .ptcd_main .button_close {
  top: -54px !important;
  width: 53px !important;
  height: 53px !important;
}
.cmmain_notipop .ptcd_main .button_close .ir {
  width: 53px !important;
  height: 53px !important;
}

/* 배송지 등록 안내 팝업 */
.cmmain_notipop .regi_ad {
  .cmnotipop_cont {
    padding: 25px;
    box-sizing: border-box;
  }
  .cmnotipop_img {
    padding: 32px 0 31px;
    text-align: center;
    img {
      width: auto;
    }
  }
  .cmnotipop_tit {
    margin-bottom: 0;
    font-size: 28px;
    letter-spacing: -0.035em;
    text-align: center;
    color: #222;
  }
  .cmnotipop_tx {
    margin-top: 3px;
    text-align: center;
  }
  .cmnotipop_subtit {
    font-size: 13px;
    letter-spacing: -0.02em;
  }
  .tx_emart {
    color: #ffa516;
  }
  .tx_earlymorning {
    color: #8097af;
  }
  .tx_traders {
    color: #99cc00;
  }
  .cmnotipop_dsc {
    margin: 20px 0 35px;
    font-size: 16px;
    color: #222;
    line-height: 1.38;
    letter-spacing: -0.05em;
  }
  .btn_cnf {
    display: block;
    width: 100%;
    height: 52px;
    background: get-color('primary');
    font-size: 16px;
    color: #fff;
    text-align: center;
    line-height: 52px;
    letter-spacing: -0.3px;
  }
  .cmnotipop_foot {
    padding-top: 0;
  }
}
/* 배송지 등록 안내 팝업 */
.cmmain_notipop .ty_affiliate {
  .cmnotipop_cont {
    padding: 40px 25px 38px;
    box-sizing: border-box;
  }
  .cmnotipop_img {
    width: 90px;
    height: 70px;
    margin: 0 auto 32px;
    img {
      width: 100%;
    }
  }
  .cmnotipop_tit {
    margin-bottom: 0;
    font-size: 22px;
    text-align: center;
    color: #222;
  }
  .cmnotipop_tx {
    margin: 9px 0 36px;
    font-size: 14px;
    text-align: center;
  }
  .btn_cnf {
    display: block;
    width: 100%;
    height: 52px;
    background: #ff3e33;
    font-size: 16px;
    color: #fff;
    text-align: center;
    line-height: 52px;
    letter-spacing: -0.3px;
  }
  .cmnotipop_foot {
    padding-top: 0;
  }
}

/* 명절 대량 주문 안내 팝업 */
.cmmain_notipop .ty_holiday {
  width: 350px;
  height: 250px;
  box-sizing: border-box;
  .cmnotipop_cont {
    height: auto;
    max-height: 100%;
    padding: 40px 25px 38px;
    box-sizing: border-box;
  }
  .cmnotipop_tit {
    margin-bottom: 0;
    font-size: 22px;
    text-align: center;
    color: #222;
    letter-spacing: 0.5px;
  }
  .cmnotipop_tx {
    margin: 11px 0 0;
    font-size: 14px;
    text-align: center;
    color: #666;
    letter-spacing: -0.5px;
  }
  .cmnotipop_dsc {
    &:not(:first-child) {
      margin-top: 12px;
    }
    &.tx_point {
      color: get-color('primary');
    }
  }
  .cmnotipop_foot {
    background-color: #000;
  }
}

/* 비밀번호 변경 캠페인 팝업 */
.cm_lypop {
  display: none;
  position: relative;
  background-color: #fff;
  font-family: $fontR;
  font-weight: 400;
  line-height: normal;
  box-sizing: border-box;

  &_btnclose {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 20px;
    height: 20px;
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 10px;
      height: 21px;
      width: 1px;
      background-color: #222;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
}
.cm_lypop_pwcampaign {
  border: solid 2px #222;
  .cm_lypop_content {
    padding: 52px 85px 70px;
  }
  .password_campaign {
    &_intro {
      text-align: center;
      h2 {
        font-size: 30px;
        font-weight: normal;
      }
      p {
        margin-top: 15px;
        font-size: 15px;
        line-height: 1.33;
      }
      em {
        color: get-color('primary');
      }
    }
    &_tip {
      margin-top: 40px;
      padding: 30px;
      border-radius: 13px;
      border: solid 1px #eee;
      text-align: center;
      strong {
        font-size: 20px;
        font-weight: normal;
        letter-spacing: -0.46px;
      }
    }
    &_list {
      margin-top: 30px;
      li {
        display: inline-block;
        margin: 15px 35px;
        font-size: 15px;
        line-height: 1.33;
        letter-spacing: -0.3px;
        color: #777;
        text-align: center;
        i {
          display: block;
          margin-bottom: 20px;
          &:before {
            content: '';
            display: inline-block;
            width: 100px;
            height: 100px;
            background-repeat: no-repeat;
            background-size: 100%;
          }
        }
        i.ico_othernumber:before {
          background-image: url('#{$imgPath}/common/ico_othernumber.svg');
        }
        i.ico_combination:before {
          background-image: url('#{$imgPath}/common/ico_combination.svg');
        }
        i.ico_memo:before {
          background-image: url('#{$imgPath}/common/ico_memo.svg');
        }
      }
    }
    &_btnarea {
      margin: 50px auto 0;
      text-align: center;
    }
  }
  .btn_change_after,
  .btn_change_password {
    display: inline-block;
    width: 263px;
    height: 52px;
    line-height: 50px;
    border: 1px solid #222;
    font-size: 16px;
    font-family: $fontR;
    font-weight: 400;
    color: #222;
    vertical-align: top;
    letter-spacing: -0.3px;
    box-sizing: border-box;
    &:not(:first-child) {
      margin-left: 15px;
    }
  }
  .btn_change_password {
    background: #222;
    color: #fff;
  }
}

/* 넷퍼넬 팝업 */
.cm_lypop_netfunnel {
  width: 360px;
  .cm_lypop_content {
    padding: 50px 30px;
    text-align: center;
  }
  .netfunnel_tit {
    display: block;
    font-size: 24px;
    color: #222;
    line-height: 32px;
    letter-spacing: -0.3px;
    font-weight: bold;
  }
  .netfunnel_tx {
    margin-top: 15px;
  }
  .netfunnel_dsc {
    font-size: 13px;
    letter-spacing: -0.3px;
  }
  .netfunnel_img {
    width: 100%;
    margin: 8px auto 0;
    line-height: 1em;
    img {
      width: 100%;
      vertical-align: top;
    }
  }
  .netfunnel_subtx {
    margin: 30px 0 15px;
  }
  .netfunnel_subdsc {
    font-size: 13px;
    color: get-color('primary');
    letter-spacing: -0.3px;
  }
  .netfunnel_subdsc_time {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.3px;
    color: get-color('primary');
    font-weight: bold;
  }
}

/* 로그인 보안 설정 팝업 */
.cmmain_notipop .logsecurity {
  .cmnotipop_cont {
    padding: 25px;
    box-sizing: border-box;
    text-align: center;
  }
  .cmnotipop_img {
    padding: 38px 0 34px;
    text-align: center;
    img {
      width: auto;
    }
  }
  .cmnotipop_tit {
    display: block;
    margin-bottom: 10px;
    font-size: 25px;
    line-height: 22px;
    color: #222;
    letter-spacing: -0.5px;
    font-family: $fontR;
    font-weight: 400;
  }
  .cmnotipop_tx {
    margin-top: 3px;
  }
  .cmnotipop_dsc {
    font-size: 16px;
    color: #222;
    letter-spacing: -0.25px;
    line-height: 22px;
  }
  .cmnotipop_lst {
    display: inline-block;
    margin: 10px auto 38px;
    li {
      position: relative;
      padding-left: 18px;
      margin-top: 10px;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: -0.2px;
      text-align: left;
      &:before {
        position: absolute;
        display: block;
        left: 1px;
        top: 2px;
        width: 9px;
        height: 5px;
        border-left: 2px solid #000;
        border-bottom: 2px solid #000;
        transform: rotate(-50deg);
        content: '';
      }
    }
  }
  .btn_cnf {
    display: block;
    width: 100%;
    height: 52px;
    background: get-color('primary');
    font-size: 16px;
    color: #fff;
    text-align: center;
    line-height: 52px;
    letter-spacing: -0.3px;
  }
  .cmnotipop_foot {
    padding-top: 0;
  }
}

/* 고해상도 대응 */
@media screen and (-webkit-min-device-pixel-ratio: 2) {
  .cmmain_notipop .ptcd_main .pop_content .ptcd_intro {
    background: url(#{$imgPath}/common/img_ptcd_barcode@2x.png) 8px 13px no-repeat;
    background-size: 282px auto;
  }
}

/* 해외 ip접근시 다국어 변환 안내 */
.ssgmain_oversea_pop {
  display: none;
  position: absolute;
  width: 650px;
  height: 410px;
  border: 1px solid #222;
  background: #fff;
  font-size: 0;
  line-height: normal;
  text-align: center;
}
.ssgmain_oversea_pop:after {
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
  content: '';
}
.ssgmain_oversea_pop .ssgmain_ospop_en {
  font-family: $fontR;
  font-weight: 400;
}
.ssgmain_oversea_pop .ssgmain_ospop_kr {
  font-family: $fontR;
  font-weight: 400;
}
.ssgmain_oversea_pop .ssgmain_ospop_cont {
  display: inline-block;
  font-size: 12px;
  vertical-align: middle;
}
.ssgmain_oversea_pop .ssgmain_ospop_msg {
  font-size: 25px;
  line-height: 30px;
  color: #222;
  letter-spacing: -0.5px;
}
.ssgmain_oversea_pop .ssgmain_ospop_btnarea {
  margin-top: 50px;
}
.ssgmain_oversea_pop .ssgmain_ospop_btn {
  display: block;
  width: 240px;
  height: 50px;
  margin: 10px auto 0;
  padding-bottom: 2px;
  background: #222;
  color: #fff;
  box-sizing: border-box;
}
.ssgmain_oversea_pop button.ssgmain_ospop_btn {
  border: 1px solid #222;
  background: #fff;
  color: #222;
}
.ssgmain_oversea_pop .ssgmain_ospop_btn:after {
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
  content: '';
}
.ssgmain_oversea_pop .ssgmain_ospop_btn .ssgmain_ospop_en {
  font-size: 15px;
  vertical-align: middle;
}
.ssgmain_oversea_pop .ssgmain_ospop_btn .ssgmain_ospop_cn {
  font-size: 13px;
  vertical-align: middle;
}
.ssgmain_oversea_pop .ssgmain_ospop_btn .ssgmain_ospop_kr {
  font-size: 14px;
  vertical-align: -2px;
}
.ssgmain_oversea_pop .ssgmain_ospop_close {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 29px;
  height: 29px;
  background: url(#{$imgPath}/common/btn_cmpop_close.png) no-repeat;
  font-size: 12px;
}
/* 고해상도 대응 */
@media screen and (-webkit-min-device-pixel-ratio: 2) {
  .ssgmain_oversea_pop .ssgmain_ospop_close {
    background-image: url(#{$imgPath}/common/btn_cmpop_close@2x.png);
    background-size: 29px auto;
  }
}
