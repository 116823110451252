/* 공통 클립하기 버튼 */
.clipbx.v2 {
  position: relative;
}
.clipbx.v2 a.clipbtn {
  display: block;
  width: 122px;
  height: 24px;
  background-image: url(#{$imgPath}/common_layout/sp_clipbtn.png) !important;
  background-repeat: no-repeat;
}
.clipbx.v2 a.clipbtn01 {
  background-position: 0 0;
} /* 카테고리 클립하기 */
.clipbx.v2 a.clipbtn02 {
  background-position: 0 -30px;
} /* 이벤트 클립하기 */
.clipbx.v2 a.clipbtn03 {
  background-position: 0 -60px;
} /* 브랜드 클립하기 */
.clipbx.v2 a.clipbtn04 {
  background-position: 0 -90px;
} /* 기획전 클립하기 */
.clipbx.v2.clip_red a.clipbtn {
  background-position: 0 -120px;
}
.clipbx.v2 .tooltip_box.small.cliping {
  display: none;
  position: absolute;
  left: -280px;
  top: -17px;
  z-index: 2000;
  width: 247px;
  height: 34px;
  padding: 10px;
  border: solid 1px #000;
  background-color: #fff;
  line-height: 18px !important;
  font-size: 12px;
  font-family: $fontR;
  font-weight: 400;
  color: #666;
  letter-spacing: 0;
  text-align: left;
}
.clipbx.v2 .tooltip_box.small.active {
  display: block;
}
.clipbx.v2 .tooltip_box.small.cliping span {
  color: #fc3d72;
}
.clipbx.v2 .tooltip_box .btn {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 2000;
  width: 13px;
  height: 13px;
  background: url(#{$imgPath}/common_layout/sp_clipbtn.png) 0 -150px no-repeat;
}
.clipbx.v2 .tooltip_box .icon_arow {
  position: absolute;
  right: -6px;
  top: 40%;
  z-index: 2000;
  width: 6px;
  height: 11px;
  background: url(#{$imgPath}/common_layout/sp_clipbtn.png) -20px -150px no-repeat;
}

.common_location .clipbx.v2 {
  float: right;
} /* 카테고리 */
.exhibition_box .clipbx.v2 {
  float: left;
} /* 기획전 */
.benfit_list .clipbx.v2 {
  float: left;
  margin-top: 2px;
} /* 이벤트 */

#container_sm .brand_cate_box.brandshop .clipbx.v2 {
  position: absolute;
  right: 0;
  top: 5px;
} /* 신몰 브랜드 */
#container_sd .clipbx.v2 {
  position: absolute;
  top: 54px;
  right: 50%;
  margin-right: -510px;
} /* 신백 브랜드 */
#container_sd .exhibition_box .clipbx.v2 {
  position: relative;
  top: auto;
  right: auto;
  margin-right: 0;
} /* 신백 기획전 */

/* 2입기획 */
.thumbnail .plus.p2i,
.thumbnail2 .plus.p2i {
  background-image: url(#{$imgPath}/common/sp_p2i.png);
  background-position: -150px 0;
  text-indent: -1000em;
}
.item_size140 .plus.p2i,
.item_size168 .plus.p2i {
  background-position: 0 0;
}
.btm_lst_area .ico_area .plus.p2i {
  background-image: url(#{$imgPath}/common/sp_p2i.png);
  background-position: -150px 0;
  text-indent: -1000em;
  background-position: -150px 0;
}
.item_size450 .plus.p2i {
  background-position: -300px 0;
}
.item_benefits .plus.p2i {
  background: url(#{$imgPath}/common/sp_p2i.png) -300px 0 no-repeat !important;
}
.ico_item.normal.p2i {
  width: 64px;
  height: 24px;
  vertical-align: middle;
}
.ico_item.normal.p2i .ir {
  background: url(#{$imgPath}/common/sp_p2i.png) -450px 0 no-repeat !important;
}
.csm_lst_v .bdg_l p.i_plus.p2i,
.csm_lst_h .bdg_l p.i_plus.p2i {
  background: url(#{$imgPath}/common/sp_p2i.png) 0 -150px no-repeat !important;
  text-indent: -1000em;
}
#container_sm .pr_bfsv .p2i {
  display: inline-block;
  width: 76px;
  height: 76px;
  overflow: hidden;
  background: url(#{$imgPath}/common/sp_p2i.png) -150px -150px no-repeat !important;
  vertical-align: top;
}
#container_sm .pr_bnfli .pr_h5 .p2i {
  display: inline-block;
  width: 66px;
  height: 17px;
  overflow: hidden;
  text-indent: -1000em;
  background: url(#{$imgPath}/common/sp_p2i.png) -450px -150px no-repeat !important;
  vertical-align: top;
}
#container_sm .hb_benefit_b .n_plus.p2i {
  display: inline-block;
  width: 130px;
  height: 130px;
  overflow: hidden;
  text-indent: -1000em;
  background: url(#{$imgPath}/common/sp_p2i.png) -300px -150px no-repeat !important;
  vertical-align: top;
}
#container_sm .event_ico .event01.p2i {
  background: url(#{$imgPath}/common/sp_p2i.png) -150px -150px no-repeat !important;
  width: 76px;
  height: 76px;
  padding: 0;
}
#qv .event_ico .event01.p2i {
  background: url(#{$imgPath}/common/sp_p2i.png) -150px -150px no-repeat !important;
  width: 76px;
  height: 76px;
  padding: 0;
}
.emall .item_thm .ico_plus.p2i,
.s_emall .item_thm .ico_plus.p2i {
  background: url(#{$imgPath}/common/sp_p2i.png) 0 -300px no-repeat !important;
}
.emall .detail_wrap .bdg_item span.ico_plus1.p2i,
.s_emall .detail_wrap .bdg_item span.ico_plus1.p2i {
  background: url(#{$imgPath}/common/sp_p2i.png) -450px -300px no-repeat !important;
}
.emall .item_wrap .left_item span.ico_plus.p2i,
.s_emall .item_wrap .left_item span.ico_plus.p2i {
  background: url(#{$imgPath}/common/sp_p2i.png) -300px -300px no-repeat !important;
}
.benefit .ico_plus1.p2i,
.desc .ico_plus1.p2i {
  background: url(#{$imgPath}/common/sp_p2i.png) -450px -450px no-repeat !important;
  width: 41px !important;
}
.traders .item_thm .ico_plus.p2i,
.s_traders .item_thm .ico_plus.p2i {
  background: url(#{$imgPath}/common/sp_p2i.png) -150px -450px no-repeat !important;
  overflow: hidden;
  text-indent: -1000em;
}
.traders .w110 .item_thm .ico_plus.p2i,
.s_traders .w110 .item_thm .ico_plus.p2i {
  background: url(#{$imgPath}/common/sp_p2i.png) 0 -450px no-repeat !important;
  overflow: hidden;
  text-indent: -1000em;
}
.traders .bdg_item span.ico_plus1.p2i,
.s_traders .bdg_item span.ico_plus1.p2i {
  background: url(#{$imgPath}/common/sp_p2i.png) 0 -450px no-repeat !important;
  overflow: hidden;
  text-indent: -1000em;
}
.s_traders .item_emall .item.item_h.w110 .item_thm .ico_plus {
  background: url(#{$imgPath}/common/sp_p2i.png) 0 -300px no-repeat !important;
}
.s_traders .item_emall .item.w253 .item_thm .ico_plus.p2i {
  background: url(#{$imgPath}/common/sp_p2i.png) 0 -300px no-repeat !important;
  margin: 5px 0 0 0;
}
.boons .item_thm .ico_plus.p2i,
.s_boons .item_thm .ico_plus.p2i {
  background: url(#{$imgPath}/common/sp_p2i.png) -150px -600px no-repeat !important;
  overflow: hidden;
  text-indent: -1000em;
}
.boons .bdg_item span.ico_plus1.p2i,
.s_boons .bdg_item span.ico_plus1.p2i {
  background: url(#{$imgPath}/common/sp_p2i.png) 0 -600px no-repeat !important;
  overflow: hidden;
  text-indent: -1000em;
}
.detail_wrap .info_wrap .ico_plus1.p2i {
  background: url(#{$imgPath}/common/sp_p2i.png) -450px -450px no-repeat !important;
  width: 41px !important;
}

/* 상품상세 카드혜택 */
.cm_card_info {
  font-family: $fontR;
  font-weight: 400;
}
.cm_card_info .cm_card_lst {
  /*background:url(#{$imgPath}/common/ic_ssgpay.png) 100% 100% no-repeat;*/
  padding: 10px 0 10px !important;
  margin-bottom: 5px;
}
.cm_card_info .cm_card_lst li,
.info_wrap .data .link_block li .cm_card_info .cm_card_lst li {
  height: auto;
  width: auto;
  line-height: 18px;
  margin: 0;
  border: 0;
  float: none;
  font-size: 11px;
  color: #999 !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
.cm_card_info .cm_card_tb {
  border-top: 1px solid #dfdfdf;
}
.cm_card_info .cm_card_tb table {
  margin: 0;
  width: 100%;
}
.cm_card_info .cm_card_tb table th {
  width: 40%;
  letter-spacing: -1px;
  font-size: 11px;
  padding: 8px 0 6px;
  border-bottom: 1px solid #ececec;
  font-family: $fontR;
  font-weight: 400;
  color: #666;
  line-height: 18px !important;
}
.cm_card_info .cm_card_tb table td {
  color: #666;
  vertical-align: middle;
  border-left: 1px solid #ececec;
  border-bottom: 1px solid #ececec;
  padding: 8px 0 6px 9px !important;
  font-family: $fontR;
  font-weight: 400;
  font-size: 11px;
  line-height: 18px !important;
}
.cm_card_info .cm_card_tb table td.cmonth {
  width: 38%;
}
.cm_card_info .cm_card_tb table td.cprice {
  width: 22%;
}
.cm_card_info .cm_card_tb .ic_ssgpay {
  background: url(#{$imgPath}/common/ic_ssgpay2.png) 100% 0 no-repeat;
  padding-right: 20px;
  display: inline-block;
  line-height: 17px;
}
.cm_card_info .cm_card_img img {
  vertical-align: top;
}
.info_wrap dd.card .lay_box.ly_cardinfo {
  left: auto;
  right: 4px;
}
.content_ty2 .cm_card_info .cm_card_tb table {
  width: 290px;
  margin-bottom: 15px;
}
.content_ty2 .cm_card_info .cm_card_lst {
  width: 280px;
  padding-top: 0 !important;
}
.content_ty2 .cm_card_info .cm_card_lst li em,
.boons .cm_card_info .cm_card_lst li em {
  display: none;
}
.step_contwrap .cm_card_info {
  padding-top: 8px;
}
.step_contwrap .cm_card_info .cm_card_img {
  width: 254px;
  overflow: hidden;
}
.benefit_layer2 .cm_card_info {
  padding-top: 8px;
}
.benefit_layer2 .cm_card_info .cm_card_img {
  width: 254px;
  overflow: hidden;
}
.benefit_layer2 .cm_card_info .cm_card_tb table {
  margin-bottom: 0 !important;
}
.benefit_layer2 .cm_card_info .cm_card_tb th {
  width: 42%;
}
.benefit_layer2 .cm_card_info .cm_card_tb td {
  background: 0 !important;
  text-align: left !important;
  padding: 8px 0 6px 9px !important;
}
.benefit_layer2 .cm_card_info .cm_card_tb td.cmonth {
  width: 36%;
}
.bn_thankcard {
  margin-bottom: 10px;
  float: left;
  width: 100%;
}
.bn_thankcard a {
  display: block;
  line-height: 0;
}
.bn_holiday {
  float: left;
  width: 100%;
}
#container_sd .bn_thankcard,
#container_sm .bn_thankcard {
  margin-top: 10px;
}
.detail_product.detail_2dan .bn_thankcard {
  padding-top: 10px;
  margin: 10px 0 0;
  border-top: 1px dotted #707070;
}

/* s-club */
.bn_sclub {
  display: none;
  background: url(#{$imgPath}/common/bg_sclub.png) 0 0 no-repeat;
  width: 300px;
  height: 75px;
  margin: 0 0 13px 122px;
}
.bn_sclub ul {
  padding: 11px 0 0 91px;
}
.bn_sclub li {
  font-size: 11px;
  color: #777;
  line-height: 16px;
  background: url(#{$imgPath}/common/bu_dot2.png) 0 5px no-repeat;
  padding-left: 6px;
  margin-bottom: 6px;
  font-family: $fontR;
  font-weight: 400;
}
.bn_sclub li strong {
  color: #0a7ec7;
}
.emall .info_wrap .bn_sclub,
.traders .info_wrap .bn_sclub,
.boons .info_wrap .bn_sclub {
  margin: 0 0 10px 0;
}
#container_sm .bn_sclub {
  margin: 7px 0 0 0;
  width: 285px;
}
#container_sd .bn_sclub {
  margin: 15px 0 0 0;
  width: 285px;
}
#container_sm .bn_sclub li,
#container_sd .bn_sclub li {
  padding-bottom: 0;
}
#container_sm .bn_sclub li strong,
#container_sd .bn_sclub li strong {
  float: none;
}
