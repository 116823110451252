/* 태그 */
.item_tag {
  float: left;
  margin-top: 15px;
  width: 100%;
  display: table;
}
.item_tag:after {
  content: '';
  display: block;
  clear: both;
}
.item_tag .tag_tit {
  position: relative;
  cursor: pointer;
  display: table-cell;
  width: 35px;
}
.item_tag .tag_tit a {
  display: inline-block;
  background: url(#{$imgPath}/common/ic_tag.png) 0 1px no-repeat;
  height: 14px;
  width: 35px;
}
.item_tag .tag_tit .tt_tag_layer {
  display: none;
  position: absolute;
  left: -1px;
  top: -60px;
  z-index: 100;
  padding: 8px 10px 7px;
  background-color: #fff;
  font-family: $fontR;
  font-weight: 400;
  font-size: 12px;
  color: #222;
  line-height: 16px;
  text-align: left;
  white-space: nowrap;
  -webkit-box-shadow: 4px 5px 17px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 4px 5px 17px 0 rgba(0, 0, 0, 0.3);
  border: 1px solid #eee;
}
.item_tag .tag_tit .tt_tag_layer .bg_arr {
  position: absolute;
  left: 5px;
  top: 100%;
  width: 12px;
  height: 9px;
  background: url(#{$imgPath}/common/bg_adinfo_n.png) 0 0 no-repeat;
}
.item_tag .tag_lst {
  line-height: 19px;
  display: table-cell;
  vertical-align: top;
}
.item_tag .tag_lst a {
  color: #888;
  margin-right: 7px;
}
.item_tag .tag_lst a:hover {
  color: #000;
}
