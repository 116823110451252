/* 각몰검색추가 - 수정이후삭제예정 */
.srch_ssg_result {
  position: relative;
}
.srch_ssg_result .txt_area {
  background: url(#{$imgPath}/common/search_bar.jpg) left top no-repeat;
  width: 1020px;
  height: 100px;
  margin-bottom: 50px;
  cursor: pointer;
}
.srch_ssg_result .txt_area .result_txt {
  float: left;
  width: 728px;
  padding: 22px 0 0 70px;
}
.srch_ssg_result .txt_area .result_txt span {
  width: 720px;
  float: left;
  color: #464646;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: -1px;
  line-height: 26px;
}
.srch_ssg_result .txt_area .result_txt span strong {
  font-size: 25px;
}
.srch_ssg_result .txt_area .result_txt span:first-child {
  color: #7a8184;
  font-size: 14px;
}
.srch_ssg_result .txt_area .result_btn {
  float: left;
  position: relative;
  padding-top: 30px;
}
.srch_ssg_result .txt_area .result_btn a {
  float: left;
  background: url(#{$imgPath}/common/search_btn.gif) left top no-repeat;
  width: 167px;
  height: 40px;
  font-size: 0;
  line-height: 0;
}
.srch_ssg_result .srch_pro {
  position: relative;
}
.srch_ssg_result .hx_cnt {
  position: relative;
  background-repeat: repeat-x;
  background-position: 0 50%;
  font-size: 26px;
  color: #222;
  line-height: normal;
  letter-spacing: -2px;
  text-align: center;
}
.srch_ssg_result .hx_case3 {
  background-image: url(#{$imgPath}/common/hx_cnt_case3.gif);
}
.srch_ssg_result .hx_cnt .tt {
  display: inline-block;
  padding: 0 20px;
  background: #fff;
}
.srch_ssg_result .hx_cnt a {
  color: #222;
}
.srch_ssg_result .hx_cnt .bn {
  position: absolute;
  margin-top: -9px;
  top: 50%;
  right: 0;
  font-size: 0;
  line-height: 0;
}

.srch_ssg_result_v1 {
  position: relative;
  padding-top: 10px;
}
.srch_ssg_result_v1 .txt_area {
  position: relative;
  width: 1020px;
  border-bottom: 1px solid #747474;
  padding-bottom: 12px;
  margin: 0 auto;
  text-align: center;
}
.srch_ssg_result_v1 .txt_area .result_txt {
  display: inline-block;
}
.srch_ssg_result_v1 .txt_area .result_txt span {
  float: left;
  *float: none;
  display: inline-block;
  color: #999;
  font-size: 24px;
  font-weight: bold;
  line-height: 28px;
  letter-spacing: -1px;
  margin-top: 15px;
}
.srch_ssg_result_v1 .txt_area .result_txt span:first-child {
  color: #222;
}
.srch_ssg_result_v1 .txt_area .result_txt span strong {
  color: #e84348;
  display: inline-block;
}
.srch_ssg_result_v1 .txt_area.ty2 {
  position: relative;
  width: 1020px;
  border-bottom: 0;
  padding-bottom: 2px;
  margin: 0 auto;
  text-align: center;
}
.srch_ssg_result_v1 .txt_area a.result_btn {
  position: relative;
  margin: 0 0 -5px 10px;
  display: inline-block;
  background: url(#{$imgPath}/common/search_btn_v1.gif) left top no-repeat;
  width: 160px;
  height: 30px;
  font-size: 0;
  line-height: 0;
}
.srch_ssg_result_v1 .srch_pro {
  position: relative;
}
/* 검색결과 교차사이트 상품추천 */
.srch_cross_pd {
  position: relative;
  margin: 54px 0 70px;
}
.srch_cross_pd .txt_area {
  position: relative;
  width: 1020px;
  margin: 0 auto;
  padding-bottom: 12px;
  border-bottom: 1px solid #747474;
  font-family: $fontR;
  font-weight: 400;
  text-align: center;
}
.srch_cross_pd .txt_area .result_txt {
  display: inline-block;
}
.srch_cross_pd .txt_area .result_txt span {
  display: inline-block;
  font-size: 26px;
  line-height: 30px;
  letter-spacing: -1px;
  color: #222;
}
.srch_cross_pd .txt_area .result_txt span strong {
  font-weight: normal;
}
.srch_cross_pd .txt_area .result_txt .result_btn {
  display: inline-block;
  position: relative;
  top: -1px;
  width: 130px;
  height: 30px;
  margin-left: 12px;
  background: url(#{$imgPath}/common/search_btn_cross.gif);
  font-size: 0;
  line-height: 0;
  vertical-align: top;
}
.srch_cross_pd .srch_pro {
  position: relative;
}
.body_sm .srch_cross_pd {
  margin-top: -24px;
}
.body_sm .srch_cross_pd .txt_area .result_txt em,
.body_sd .srch_cross_pd .txt_area .result_txt em {
  color: #ff7200;
}
.body_em .srch_cross_pd .txt_area .result_txt em,
.body_traders .srch_cross_pd .txt_area .result_txt em,
.body_boons .srch_cross_pd .txt_area .result_txt em {
  color: #d91800;
}
.ico_bottom {
  position: absolute;
  right: 0;
  bottom: 0;
}
.ico_mov {
  display: block;
  width: 66px;
  height: 24px;
  margin: 0 8px 8px 0;
  background: url(#{$imgPath}/common/sp_mov.png) no-repeat;
}
.item_size140 .thumbnail .ico_mov {
  margin: 0 4px 4px 0;
}
