/* 이벤트 이미지 게시판  */
.inpbx {
  display: inline-block;
  height: 22px;
  padding: 5px 10px 0;
  background: #fff;
  input {
    width: 100%;
    height: 16px;
    border: 0;
    vertical-align: top;
    line-height: 16px;
  }
}
.img_board_wrap {
  width: 1020px;
  margin: 0 auto;
}
.img_board_wrap .h_evt_reply {
  height: 22px;
  margin: 59px 0 0;
  background: url(#{$imgPath}/common/tit_evt_reply.png) no-repeat;
}
.reply_register_wrap {
  position: relative;
  margin-top: 10px;
  padding: 10px 135px 10px 0;
  border: 1px solid #e5e5e5;
  background: #fafafa;
}
.reply_register_wrap .inp_wrap {
  margin-top: 5px;
}
.reply_register_wrap .inp_wrap:first-child {
  margin-top: 0;
}
.reply_register_wrap label {
  float: left;
  width: 26px;
  height: 13px;
  margin: 19px 11px 0 17px;
}
.reply_register_wrap .lb_txt {
  background: url(#{$imgPath}/common/txt_board_title.gif) no-repeat;
}
.reply_register_wrap .lb_photo {
  background: url(#{$imgPath}/common/txt_board_photo.gif) no-repeat;
}
.reply_register_wrap .inp {
  overflow: hidden;
  display: block;
  height: 48px;
  padding: 0 19px;
  border: 1px solid #e5e5e5;
  background: #fff;
}
.reply_register_wrap .inp input {
  width: 100%;
  height: 23px;
  margin-top: 14px;
  border: 0;
  background: #fff;
  color: #959595;
  line-height: 23px\9;
}
.reply_register_wrap .inp input::-ms-value {
  border: 0;
  background: #fff;
  color: #959595;
}
.reply_register_wrap .inp_txt {
  position: relative;
  padding-right: 92px;
}
.reply_register_wrap .inp_txt input {
  height: 42px;
  margin-top: 3px;
  border: 0;
  font-size: 16px;
  color: #3d3d3d;
  line-height: 42px;
  letter-spacing: -1px;
}
.reply_register_wrap .inp_txt .tx_desc {
  position: absolute;
  right: 0;
  top: 17px;
  width: 88px;
  height: 14px;
  border-left: 1px solid #e5e5e5;
  color: #888;
  text-align: center;
  line-height: 14px;
}
.reply_register_wrap .btn_register_reply {
  position: absolute;
  right: -1px;
  top: -1px;
}
.img_board_wrap .lst_noti {
  margin-top: 9px;
  text-align: left;
}
.img_board_wrap .lst_noti li {
  margin-top: 4px;
  font-family: $fontR;
  font-weight: 400;
  font-size: 12px;
  color: #737272;
  letter-spacing: -1px;
  line-height: 14px;
}
.img_board_top {
  position: relative;
  margin-top: 57px;
}
.img_board_top .btn_my_writing {
  position: absolute;
  top: 0;
  right: 0;
  width: 126px;
  height: 30px;
  background: url(#{$imgPath}/common/btn_my_writing.png) no-repeat;
}
.img_board_top .sort_lst_wrap {
  zoom: 1;
}
.img_board_top .sort_lst_wrap:after {
  display: block;
  clear: both;
  content: '';
}
.img_board_top .sort_lst_wrap li {
  float: left;
  width: 74px;
  height: 30px;
  margin-left: -1px;
  background: url(#{$imgPath}/common/sp_board_sort.png) no-repeat;
}
.img_board_top .sort_lst_wrap li a {
  display: block;
  height: 100%;
}
.img_board_top .sort_lst_wrap .lst {
  margin-left: 0;
}
.img_board_top .sort_lst_wrap .lst2 {
  background-position: -73px 0;
}
.img_board_top .sort_lst_wrap .lst3 {
  width: 75px;
  background-position: -146px 0;
}
.img_board_top .sort_lst_wrap .lst.active {
  background-position: 0 -32px;
}
.img_board_top .sort_lst_wrap .lst2.active {
  background-position: -73px -32px;
}
.img_board_top .sort_lst_wrap .lst3.active {
  background-position: -146px -32px;
}
.img_board_list {
  overflow: hidden;
  margin: 10px 0 20px;
  padding: 5px 0 10px;
  border-top: 4px solid #444;
  border-bottom: 1px solid #ccc;
}
.img_board_list ul {
  margin-left: -20px;
  zoom: 1;
}
.img_board_list ul:after {
  display: block;
  clear: both;
  content: '';
}
.img_board_list li {
  float: left;
  width: 240px;
  margin: 10px 0 0 20px;
  padding-bottom: 20px;
  height: 224px;
}
.img_board_list li .tit {
  overflow: hidden;
  display: block;
  margin-top: 8px;
  font-size: 14px;
  color: #222;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.img_board_list .writing_info {
  margin: 7px 0 0;
  padding: 0;
}
.img_board_list .writing_info li {
  width: auto;
  height: 11px;
  margin: 0;
  padding: 0 10px;
  border-left: 1px solid #d6d6d6;
  font-size: 11px;
  color: #777;
  line-height: 11px;
}
.img_board_list .writing_info li:first-child {
  border-left: 0;
}
.img_board_list .writing_info li.tx_userid {
  padding-left: 16px;
  background: url(#{$imgPath}/common/ico_userid.gif) no-repeat;
}
.img_board_list li .pic {
  position: relative;
  width: 240px;
  height: 180px;
}
.img_board_list li .pic a {
  display: block;
  background: #000;
  text-align: center;
}
.img_board_list li .pic a img {
  width: auto;
  max-width: 240px;
  vertical-align: top;
}
.img_board_list li .pic .btn_rcmd {
  overflow: hidden;
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 35px;
  height: 32px;
  padding-left: 40px;
  background: url(#{$imgPath}/common/sp_board.png) no-repeat;
  font-size: 15px;
  font-weight: bold;
  color: #dedede;
  text-decoration: none;
  z-index: 10;
}
.img_board_list li .pic .btn_rcmd em {
  display: block;
  padding-right: 21px;
  background: url(#{$imgPath}/common/sp_board.png) no-repeat 100% -68px;
  line-height: 33px;
  text-align: right;
}
.img_board_list li .pic .btn_rcmd_on {
  background-position: 0 -34px;
}
.img_board_list li .pic .btn_rcmd_on em {
  background-position: -105px -68px;
}
.img_board_list li .pic .bg_rcmd_succ {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: url(#{$imgPath}/common/bg_rcmd_succ.png) no-repeat;
  z-index: 10;
}
.img_board_list li .pic .bg_rcmd_fail {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: url(#{$imgPath}/common/bg_rcmd_fail.png) no-repeat;
  z-index: 10;
}
.img_board_list li .pic .bg_rcmd_succ.on,
.img_board_list li .pic .bg_rcmd_fail.on {
  display: block;
}
