@use '@ssgui/icons/dist/ssgui-icons' as *;

#gnbBanrDiv {
  z-index: 1001;
  position: relative;
}

.gnb_bnr_ie {
  display: flex;
  align-items: center;
  height: 50px;
  background: #f0f0f0;
  .gnb_bnr_inner {
    position: relative;
    width: 1280px;
    margin: 0 auto;
  }
  .gnb_bnr_desc {
    display: flex;
    align-items: center;
    &::before {
      content: '';
      display: inline-block;
      vertical-align: top;
      margin-right: 5px;
      width: 20px;
      height: 20px;
      background: url(#{$imgPath}/common_layout/icon_triangle_error.png) 0 0 no-repeat;
    }
    p {
      padding-top: 2px;
      font-size: 13px;
      letter-spacing: -0.3px;
      color: #222;
    }
    .gnb_bnr_btnopen {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-left: 20px;
      font-size: 12px;
      line-height: 13px;
      color: #fff;
      padding: 0 6px 0 14px;
      height: 24px;
      background: #222;
      border-radius: 15px;
      .icon {
        &::after {
          filter: invert(86%) sepia(89%) saturate(2%) hue-rotate(271deg) brightness(110%)
            contrast(97%);
        }
      }
    }
  }
  .gnb_bnr_btntoday {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    font-size: 11px;
    letter-spacing: -0.3px;
    color: #222;
    .icon {
      margin-left: 10px;
      width: 12px;
      height: 12px;
    }
  }
}

.gnb_bnr_wrap {
  display: none;
  width: 100%;
  position: relative;
  z-index: 600;
  top: 0;
  left: 0;
  right: 0;
  min-width: 1280px;
}
.gnb_bnr_wrap .ie_dimed {
  position: fixed;
  background: url(#{$imgPath}/common_layout/bn/bg_opacity.png);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 599;
}
.gnb_bnr_s {
  // height: 100px;
  text-align: center;
  position: relative;
  z-index: 600;
}
.gnb_bnr_s a {
  display: block;
  text-align: center;
}
.gnb_bnr_s img {
  margin: 0 auto;
  width: 1280px;
  // height: 100px;
  vertical-align: top;
}
.gnb_bnr_s .gnb_btn_today {
  position: absolute;
  top: 0;
  right: 0;
  // height: 100px;
  width: 50px;
}
.gnb_bnr_b {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.gnb_bnr_b .gnb_bnr_cont {
  position: relative;
  width: 1280px;
  margin: 0 auto;
}
.gnb_bnr_b .gnb_bnr_cont img {
  width: 1280px;
  height: 800px;
}
.gnb_bnr_b .gnb_bnr_cont .gnb_bnr_clse {
  position: absolute;
  top: 0;
  right: 0;
  width: 100px;
  height: 100px;
}

/* 탑 배너 영역 추가*/
.tv_promo_wrap {
  width: 100%;
  position: absolute;
  z-index: 600;
  top: 0;
  left: 0;
  right: 0;
  display: none;
  min-width: 1020px;
}
.tv_promo_s {
  height: 100px;
  text-align: center;
}
.tv_promo_s a {
  display: block;
  text-align: center;
}
.tv_promo_s img {
  margin: 0 auto;
  width: 1020px;
  height: 100px;
  vertical-align: top;
  display: block;
}
.tv_promo_cont {
  width: 1020px;
  height: 800px;
  margin: 0 auto;
  position: relative;
}
.tv_promo_mov {
  position: absolute;
  top: 35px;
  left: 186px;
}
.tv_promo_bn {
  position: absolute;
  top: 438px;
  left: -18px;
}
.tv_promo_link {
  position: absolute;
  top: 706px;
  left: 379px;
  width: 263px;
  height: 63px;
  opacity: 0;
  filter: alpha(opacity=0);
  background: #fff;
}
.tv_promo_close {
  position: absolute;
  top: 11px;
  right: 0;
  width: 71px;
  height: 89px;
  background: url(#{$imgPath}/common_layout/bn/btn_cf.png) 0 0 no-repeat;
}

.gnb_bnr_belt {
  max-height: 32px;
  transform-origin: 50% 0;
  transition: 700ms max-height cubic-bezier(0, 0.01, 0.2, 1);
  overflow: hidden;

  > a {
    width: 100%;
    height: 32px;
    display: block;
    position: relative;

    color: currentColor;

    > img {
      min-width: 1280px;
      display: block;
      margin: 0 auto;
    }
  }

  .gnb_bnr_desc {
    max-width: 600px;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 20;
    transform: translate(-50%, -50%);
    font-family: $fontM;
    font-weight: 500;
    font-size: 12px;
    color: #222;
    line-height: calc(14 / 12);
    letter-spacing: -0.3px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .gnb_bnr_belt_safety {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10;
    transform: translate(-50%, -50%);
    margin-left: 630px;
  }

  .js_gnb_bnr_belt_close {
    display: block;
    line-height: 0;

    .icon_ssg_close {
      &::after {
        content: '';
        @include ssgui-svg-icon($name: $IconSsgClose, $size: 'sm');
      }
    }
  }

  // FIXME 삭제 필요
  > .js_gnb_bnr_belt_close {
    position: absolute;
    top: 50%;
    right: 320px;
    z-index: 10000;
    transform: translateY(-50%);
  }

  &.color_white {
    .gnb_bnr_desc {
      color: #fff;
    }
    .js_gnb_bnr_belt_close {
      .icon_ssg_close {
        &::after {
          content: '';
          @include ssgui-svg-icon($name: $IconSsgClose, $size: 'sm', $color: #fff);
        }
      }
    }
  }

  &.is-active {
    max-height: 0;

    .gnb_bnr_desc {
      transition: 200ms opacity ease;
      opacity: 0;
    }

    .js_gnb_bnr_belt_close {
      transition: 200ms opacity ease;
      opacity: 0;
    }
  }
}
