.ly_ssgpay .dimmed {
  background: #000;
  opacity: 0.2;
  filter: alpha(opacity=20);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 600;
  width: 100%;
  height: 100%;
}
.ly_ssgpay .layer_pop {
  left: 50%;
  width: 540px;
  margin-left: -270px;
  border: 0;
  position: absolute;
  z-index: 700;
  border: 0;
  background: #fff;
}
.ly_ssgpay .ssgpay_top {
  width: 540px;
  height: 126px;
  position: relative;
  background: url(#{$imgPath}/common/img_ssgpay1.png);
}
.ly_ssgpay .ssgpay_top .btn_close {
  position: absolute;
  top: 20px;
  right: 20px;
  height: 34px;
  width: 34px;
  background: url(#{$imgPath}/common/b.gif);
}
.ly_ssgpay .ssgpay_btm {
  width: 540px;
  height: 430px;
  position: relative;
  background: url(#{$imgPath}/common/img_ssgpay2.png);
}
.ly_ssgpay .ssgpay_btm .btn_ssgpay {
  display: none;
  width: 97px;
  height: 35px;
  position: absolute;
  top: 325px;
  left: 38px;
  background: url(#{$imgPath}/common/btn_ssgpay.png) 0 0 no-repeat;
}
.ly_ssgpay .ssgpay_btm_bn {
  width: 540px;
}
.ly_ssgpay .ssgpay_btm_bn img {
  vertical-align: top;
  width: 540px;
}
.ly_ssgpay .ssgpay_price {
  line-height: 1.5;
}
.ly_ssgpay .ssgpay_price .ssgpay_benefit {
  border-bottom: 1px solid #c9c9c9;
  font-family: $fontR;
  font-weight: 400;
  color: #666;
  padding: 26px 0 5px;
}
.ly_ssgpay .ssgpay_price .ssgpay_benefit:after {
  content: '';
  display: block;
  clear: both;
}
.ly_ssgpay .ssgpay_price .ssgpay_benefit dt {
  float: left;
  width: 160px;
  background-position: 29px 4px;
  background-repeat: no-repeat;
  height: 44px;
}
.ly_ssgpay .ssgpay_price .ssgpay_benefit dt.stit7 {
  background-image: url(#{$imgPath}/common/tx_ssgpay3.png);
}
.ly_ssgpay .ssgpay_price .ssgpay_benefit dt.stit8 {
  background-image: url(#{$imgPath}/common/tx_ssgpay2.png);
}
.ly_ssgpay .ssgpay_price .ssgpay_benefit dt.stit9 {
  background-image: url(#{$imgPath}/common/tx_ssgpay1.png);
}
.ly_ssgpay .ssgpay_price .ssgpay_benefit dt span {
  visibility: hidden;
  overflow: hidden;
  position: absolute;
  top: -1px;
  left: -1px;
  width: 1px;
  height: 1px;
  font-size: 0;
  line-height: 0;
}
.ly_ssgpay .ssgpay_price .ssgpay_benefit dd {
  float: right;
  width: 360px;
  color: #666;
  text-align: left;
  margin-bottom: 12px;
  padding-right: 20px;
}
.ly_ssgpay .ssgpay_price .ssgpay_benefit dd strong.price {
  font-size: 20px;
  vertical-align: -2px;
  color: #000;
  letter-spacing: -1px;
  margin-right: 2px;
  display: inline-block;
  margin-top: -1px;
}
.ly_ssgpay .ssgpay_price .ssgpay_benefit dd .card_area {
  margin-bottom: 12px;
}
.emall .ly_ssgpay .ssgpay_price .ssgpay_benefit dd strong.price,
.traders .ly_ssgpay .ssgpay_price .ssgpay_benefit dd strong.price,
.boons .ly_ssgpay .ssgpay_price .ssgpay_benefit dd strong.price {
  font-size: 24px;
  margin-top: -3px;
}
.ly_samsungpay .ssgpay_top {
  background: url(#{$imgPath}/common/img_samsungpay1.png);
}
.ly_samsungpay .ssgpay_price .ssgpay_benefit dt.stit7 {
  background-image: url(#{$imgPath}/common/tx_samsungpay3.png);
}
.ly_samsungpay .ssgpay_price .ssgpay_benefit dt.stit8 {
  background-image: url(#{$imgPath}/common/tx_samsungpay2.png);
}
.ly_samsungpay .ssgpay_price .ssgpay_benefit dt.stit9 {
  background-image: url(#{$imgPath}/common/tx_samsungpay1.png);
}

/* ssgpay */
.info_wrap .bn_ssgpay {
  text-align: right;
  padding: 19px 0 10px 0;
}
.info_wrap .bn_ssgpay a {
  display: inline-block;
  width: 358px;
  height: 35px;
  background: url(#{$imgPath}/common/bn_ssgpay.png) 0 0 no-repeat;
  vertical-align: top;
}
.emall .info_wrap .bn_ssgpay,
.traders .info_wrap .bn_ssgpay,
.boons .info_wrap .bn_ssgpay {
  padding: 10px 0 16px 0;
  text-align: left;
  background: #fff;
}
#container_sm .bn_ssgpay {
  text-align: center;
  padding: 15px 0 5px 0;
}
#container_sd .bn_ssgpay {
  text-align: center;
  padding: 0 0 5px 0;
}
#container_sm .bn_ssgpay a,
#container_sd .bn_ssgpay a {
  display: inline-block;
  width: 285px;
  height: 35px;
  background: url(#{$imgPath}/common/bn_ssgpay_sm.png) 0 0 no-repeat;
  vertical-align: top;
}
.bn_ssgpay_v {
  padding: 19px 0 10px 0;
}
.bn_ssgpay_v a {
  display: inline-block;
  overflow: hidden;
  width: 181px;
  height: 35px;
  background: url(#{$imgPath}/common/bg_ssgpay.png) 0 0 no-repeat;
  vertical-align: top;
  text-align: center;
  line-height: 35px;
  font-family: $fontR;
  font-weight: 400;
  color: #33353d;
  font-size: 13px;
  text-decoration: none;
  word-break: break-all;
  word-wrap: break-word;
  padding: 0 17px 0 87px;
}
.info_wrap .bn_ssgpay_v {
  padding-left: 122px;
}
.emall .info_wrap .bn_ssgpay_v,
.traders .info_wrap .bn_ssgpay_v,
.boons .info_wrap .bn_ssgpay_v {
  padding: 10px 0 16px 0;
}
#container_sm .bn_ssgpay_v {
  padding: 15px 0 5px 0;
}
#container_sd .bn_ssgpay_v {
  padding: 0 0 5px 0;
}
.btn_ssgpay_cnfrm {
  display: inline-block;
  vertical-align: top;
  width: 79px;
  height: 20px;
  background: url(#{$imgPath}/common/bg_ssgpay.png) 0 -40px no-repeat;
}
