// =============================================================================
// PC 전몰 공통 variable
// =============================================================================
// 디자인 정책 컨플 (24.03.07 버전 적용)
// https://projectwiki.ssgadm.com/pages/viewpage.action?pageId=160914553#160914553f3d3c23676884185bc9d4bba55ff7a87
// =============================================================================
$common-colors: (
  'transparent': transparent,
  'current': currentColor,
  'white': #ffffff,
  'black': #000000,
  'black_alpha3': rgba(0, 0, 0, 0.03),
  'black_alpha4': rgba(0, 0, 0, 0.04),
  'black_alpha20': rgba(0, 0, 0, 0.2),
  'black_alpha45': rgba(0, 0, 0, 0.45),
  'black_alpha60': rgba(0, 0, 0, 0.6),
  'black_alpha80': rgba(0, 0, 0, 0.8),
  'black_alpha95': rgba(0, 0, 0, 0.95),
  'white_alpha20': rgba(255, 255, 255, 0.2),
  'white_alpha60': rgba(255, 255, 255, 0.6),
  'white_alpha80': rgba(255, 255, 255, 0.8),
  'white_alpha95': rgba(255, 255, 255, 0.95),
  'warning': #ff5f04,
  'warning_loss': #ff3e3e,
  'positive': #178aff,
  'success': #07a740,
  'primary_light': #fff2f2,
  'primary': #ff5452,
  'primary_dark': #ff0014,
  'secondary_light': #f5edff,
  'secondary': #222222,
  'gray100': #fafafa,
  'gray150': #f5f5f5,
  'gray200': #f0f0f0,
  'gray300': #e5e5e5,
  'gray350': #cfcfcf,
  'gray400': #969696,
  'gray500': #888888,
  'gray600': #777777,
  'gray700': #666666,
  'gray800': #444444,
  'gray900': #222222,

  'ssg_brand': linear-gradient(270deg, #be3ffa 0%, #f43479 40.65%, #f43479 58.41%, #ff5452 100%),
  'emart_brand': #ffd040,

  // site
  'site.shinsegaemall_primary': #f12e24,
  'site.department_primary': #a59357,
  'site.department_secondary_01': #222222,
  'site.ssg_secondary3_horizontal':
    linear-gradient(to right, #fecc1f, #fe3a6b 39.58%, #a33ffa 59.9%, #3798fb 80.21%, #26d866),
  'site.triip_primary': #328ca8,
  'site.triip_secondary_01': #2e3342,

  // delivery
  'delivery.emart_light': #ffe98b,
  'delivery.emart': #ffd040,
  'delivery.emart_dark': #ff6200,
  'delivery.earlymorning_light': #d5dee8,
  'delivery.earlymorning': #a3b7cd,
  'delivery.earlymorning_dark': #44637e,
  'delivery.traders_light': #d6ef9c,
  'delivery.traders': #a6dd27,
  'delivery.traders_dark': #50a800,
  'delivery.oneday': #dcaa78,
  'delivery.oneday_dark': #a57c58,
  'delivery.post_light': #eceae4,
  'delivery.post': #d6d2c4,
  'delivery.depart_today_light': #f5edff,
  'delivery.depart_today': #8807f3,
  'delivery.pickup': #e1d2c8,
  'delivery.mobilecoupon': #9ee5d2,

  // service
  'service.univers_primary1_horizontal': linear-gradient(90deg, #002041 41.02%, #497cff 102.54%),
  'service.univers_primary2': #002041,
  'service.univers_secondary1': #497cff,
  'service.univers_secondary2': #dbe5ff,
  'service.happylounge_primary': #ff4c3c,
  'service.present_primary': #ab8f59,
  'service.present_secondary_01': #d6d2c4,
  'service.present_secondary_02': #5d533f,
  'service.ssgtalk_primary': #6677c0,
  'service.obanjang_primary': #ffd040,
  'service.mondaymoon_primary': #3e584b,
  'service.luxury_primary': #0b503d,
  'service.esg_primary': #0b923b,
  'service.esg_primary_light': #e6f6ec,
  'service.ssgfood_primary': #ebf08f,
  'service.ssgfood_primary_light': #fdf7e5,
  'service.farmersmarket': #0038f0,
  'service.biz_primary': #353dff,
  'service.freshgreen_primary': #0b9b5b,

  // promotion
  'promotion.ssgday_primary': #e60096,
  'promotion.ssgday_secondary1': #ff7373,
  'promotion.ssgday_secondary2': #ff4137
) !default;

// =============================================================================
// PC SSG 공통 variable
// =============================================================================
$ssg-colors: (
  // 'brand': linear-gradient(270deg, #be3ffa 0%, #f43479 40.65%, #f43479 58.41%, #ff5452 100%)
);

$colors: map-merge($common-colors, $ssg-colors);

$fontR: 'Pretendard', -apple-system, BlinkMacSystemFont, sans-serif;
$fontM: 'Pretendard', -apple-system, BlinkMacSystemFont, sans-serif;
$fontB: 'Pretendard', -apple-system, BlinkMacSystemFont, sans-serif;

$pretendard: 'Pretendard', -apple-system, BlinkMacSystemFont, sans-serif;
$playfair: 'PlayfairDisplay', -apple-system, BlinkMacSystemFont, serif;

$imgPath: '../img' !default;

$spacer: 4px !default;
$border-radius-root: 4px !default;

$fonts: (
  'gothic': $pretendard,
  'serif': $playfair
);

$fontSizes: (
  '3xs': 10px,
  '2xs': 11px,
  'xs': 12px,
  'sm': 13px,
  'md': 14px,
  'lg': 15px,
  'xl': 16px,
  '2xl': 18px,
  '3xl': 20px,
  '4xl': 22px,
  '5xl': 24px,
  '6xl': 26px,
  '7xl': 30px,
  '8xl': 34px,
  '9xl': 40px
);

$fontWeights: (
  'hairline': 100,
  'thin': 200,
  'light': 300,
  'normal': 400,
  'regular': 400,
  'medium': 500,
  'semibold': 600,
  'bold': 700,
  'extrabold': 800,
  'black': 900
);

$common-fonts: () !default;
$common-fonts: map-merge(
  (
    'fonts': $fonts,
    'fontSizes': $fontSizes,
    'fontWeights': $fontWeights
  ),
  $common-fonts
);

$device: 'pc' !default;
