/* 플로팅배너 */
#floatWrap {
  display: none;
  position: fixed;
  bottom: 5px;
  left: 50%;
  z-index: 500;
  margin-left: 645px;
}
.body_wide #floatWrap {
  margin-left: 775px;
}
#floatTy1 img {
  position: absolute;
  right: 0;
  bottom: 0;
}
#floatTy1 .bn_fix_s {
  position: absolute;
  right: 0;
  bottom: 0;
}
#floatTy1 .bn_fix_b {
  right: -11px;
}
#floatWrap2 {
  display: none;
  position: fixed;
  bottom: 5px;
  left: 50%;
  z-index: 500;
  margin-left: 645px;
}
.body_wide #floatWrap2 {
  margin-left: 775px;
}
#floatTy2 .bn_fix_s {
  position: absolute;
  right: 0;
  bottom: 0;
}
#floatTy2 .bn_fix_s img {
  position: absolute;
  right: 0;
  bottom: 0;
}
#floatTy2 .jump_big {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
}
#floatTy2 .bn_fix_b {
  width: 1020px;
  text-align: right;
  display: block;
  margin: 0 auto;
}
#floatTy2 .bn_fix_b img {
  vertical-align: top;
  width: 1020px;
  height: 185px;
}
#floatWrap3 {
  display: none;
  position: fixed;
  bottom: 5px;
  right: 50%;
  z-index: 500;
  margin-right: 612px;
}
.body_wide #floatWrap3 {
  margin-right: 742px;
}
#floatTy3 {
  position: relative;
}
#floatTy3 img {
  position: absolute;
  left: 0;
  bottom: 0;
}
#floatTy3 .bn_fix_s {
  position: absolute;
  left: 0;
  bottom: 0;
}
#floatTy3 .bn_fix_b img {
  vertical-align: top;
}
#floatWrap4 {
  display: none;
  position: fixed;
  bottom: 5px;
  right: 50%;
  z-index: 500;
  margin-right: 612px;
}
.body_wide #floatWrap4 {
  margin-right: 742px;
}
#floatTy4 {
  position: relative;
}
#floatTy4 img {
  position: absolute;
  left: 0;
  bottom: 0;
}
#floatTy4 .bn_fix_s {
  position: absolute;
  left: 0;
  bottom: 0;
}

@media only screen and (max-width: 1500px) {
  .body_wide #floatWrap,
  .body_wide #floatWrap2,
  .body_wide #floatWrap3,
  .body_wide #floatWrap4 {
    margin-right: 0;
    right: auto;
    left: 0;
    bottom: 0;
  }
  .body_wide #floatTy3 .bn_fix_s img {
    width: 50px !important;
  }
}

// #miracleWrap {
//   display: none;
//   position: fixed;
//   bottom: 5px;
//   left: 50%;
//   z-index: 500;
//   margin-left: 645px;
// }
// #miracle img {
//   position: absolute;
//   right: 0;
//   bottom: 0;
// }
// #miracle .bn_fix_s {
//   position: absolute;
//   right: 0;
//   bottom: 0;
// }
// #miracle .bn_fix_b {
//   right: -11px;
// }
// #jumpUpWrap {
//   display: none;
//   position: fixed;
//   bottom: 5px;
//   left: 50%;
//   z-index: 500;
//   margin-left: 645px;
// }
// #jumpUp .bn_fix_s {
//   position: absolute;
//   right: 0;
//   bottom: 0;
// }
// #jumpUp .bn_fix_s img {
//   position: absolute;
//   right: 0;
//   bottom: 0;
// }
// #jumpUp .jump_big {
//   position: fixed;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   text-align: center;
// }
// #jumpUp .bn_fix_b {
//   width: 1020px;
//   text-align: right;
//   display: block;
//   margin: 0 auto;
// }
// #jumpUp .bn_fix_b img {
//   vertical-align: top;
//   width: 1020px;
//   height: 185px;
// }
