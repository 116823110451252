@charset "UTF-8";
/* web font */
@font-face {
  font-family: "Spoqa-Regular";
  font-style: normal;
  font-weight: normal;
  src: url("https://sui.ssgcdn.com/ui/common/font/spoqa/Spoqa-Regular.woff2") format("woff2"), url("https://sui.ssgcdn.com/ui/common/font/spoqa/Spoqa-Regular.woff") format("woff");
}
@font-face {
  font-family: "Spoqa-Medium";
  font-style: normal;
  font-weight: normal;
  src: url("https://sui.ssgcdn.com/ui/common/font/spoqa/Spoqa-Medium.woff2") format("woff2"), url("https://sui.ssgcdn.com/ui/common/font/spoqa/Spoqa-Medium.woff") format("woff");
}
@font-face {
  font-family: "Spoqa-Bold";
  font-style: normal;
  font-weight: normal;
  src: url("https://sui.ssgcdn.com/ui/common/font/spoqa/Spoqa-Bold.woff2") format("woff2"), url("https://sui.ssgcdn.com/ui/common/font/spoqa/Spoqa-Bold.woff") format("woff");
}
@font-face {
  font-family: "NanumMyeongjo";
  font-style: normal;
  font-weight: normal;
  src: url("https://sui.ssgcdn.com/ui/common/font/NanumMyeongjo-Bold.eot");
  src: local(※), url("https://sui.ssgcdn.com/ui/common/font/NanumMyeongjo-Bold.woff") format("woff"), url("https://sui.ssgcdn.com/ui/common/font/NanumMyeongjo-Bold.ttf") format("truetype");
}
/* Pretendard */
@font-face {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  src: url("https://sui.ssgcdn.com/ui/common/font/pretendard/Pretendard-Regular.woff2?subset") format("woff2");
}
@font-face {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 500;
  src: url("https://sui.ssgcdn.com/ui/common/font/pretendard/Pretendard-Medium.woff2?subset") format("woff2");
}
@font-face {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 600;
  src: url("https://sui.ssgcdn.com/ui/common/font/pretendard/Pretendard-SemiBold.woff2?subset") format("woff2");
}
@font-face {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  src: url("https://sui.ssgcdn.com/ui/common/font/pretendard/Pretendard-Bold.woff2?subset") format("woff2");
}
/* PlayfairDisplay */
@font-face {
  font-family: "PlayfairDisplay";
  font-style: normal;
  font-weight: 400;
  src: url("https://sui.ssgcdn.com/ui/common/font/playfairdisplay/PlayfairDisplay-Regular.woff2") format("woff2");
}
@font-face {
  font-family: "PlayfairDisplay";
  font-style: normal;
  font-weight: 500;
  src: url("https://sui.ssgcdn.com/ui/common/font/playfairdisplay/PlayfairDisplay-Medium.woff2") format("woff2");
}
@font-face {
  font-family: "PlayfairDisplay";
  font-style: normal;
  font-weight: 600;
  src: url("https://sui.ssgcdn.com/ui/common/font/playfairdisplay/PlayfairDisplay-SemiBold.woff2") format("woff2");
}
/* 다국어 분기 */
:lang(en) *,
:lang(en) font,
:lang(en) .tx_en {
  font-family: "Spoqa-Regular", -apple-system, BlinkMacSystemFont, "Apple SD Gothic Neo", Arial, sans-serif !important;
}

:lang(zh) *,
:lang(zh) font,
:lang(zh) .tx_zh {
  font-family: "Spoqa-Regular", "Microsoft Yahei", 微软雅黑, STXihei, 华文细黑, sans-serif !important;
}

:lang(auto) .hide_ko,
:lang(ko) .hide_ko,
:lang(en) .hide_en,
:lang(zh) .hide_zh {
  display: none !important;
}

:lang(en) .hide_gl,
:lang(zh) .hide_gl {
  display: none !important;
}

:lang(auto) .show_ko,
:lang(ko) .show_ko,
:lang(en) .show_en,
:lang(zh) .show_zh {
  display: block !important;
}

:lang(en) .show_gl,
:lang(zh) .show_gl {
  display: block !important;
}

:lang(en) select.show_gl,
:lang(zh) select.show_gl {
  display: inline-block !important;
}

:lang(en) span.show_gl,
:lang(zh) span.show_gl {
  display: inline !important;
}

:lang(en) em.show_gl,
:lang(zh) em.show_gl {
  display: inline !important;
}

.goog-tooltip {
  display: none !important;
}

.goog-text-highlight {
  background-color: initial !important;
  box-shadow: none !important;
  position: static !important;
}

font {
  vertical-align: baseline !important;
}

:lang(ko) .notranslate .tx_ko {
  display: inline;
}

:lang(ko) .notranslate .tx_en {
  display: none;
}

:lang(ko) .notranslate .tx_zh {
  display: none;
}

:lang(ko) .notranslate .tx_gl {
  display: none;
}

:lang(auto) .notranslate .tx_ko {
  display: inline;
}

:lang(auto) .notranslate .tx_en {
  display: none;
}

:lang(auto) .notranslate .tx_zh {
  display: none;
}

:lang(auto) .notranslate .tx_gl {
  display: none;
}

:lang(en) .notranslate .tx_ko {
  display: none;
}

:lang(en) .notranslate .tx_en {
  display: inline;
}

:lang(en) .notranslate .tx_zh {
  display: none;
}

:lang(en) .notranslate .tx_gl {
  display: inline;
}

:lang(zh) .notranslate .tx_ko {
  display: none;
}

:lang(zh) .notranslate .tx_en {
  display: none;
}

:lang(zh) .notranslate .tx_zh {
  display: inline;
}

:lang(zh) .notranslate .tx_gl {
  display: inline;
}

:lang(en) .ssg_tx,
:lang(zh) .ssg_tx {
  font-size: 0 !important;
  color: #fff !important;
}

:lang(en) .ssg_tx .cm_tx_opt,
:lang(zh) .ssg_tx .cm_tx_opt {
  font-size: 12px;
  color: #222;
}

:lang(en) .ssg_price,
:lang(zh) .ssg_price {
  white-space: nowrap;
}

:lang(en) .ssg_price:before {
  content: "$";
}

:lang(zh) .ssg_price:before {
  content: "$";
}

:lang(en) .set_currency .ssg_price:before,
:lang(zh) .set_currency .ssg_price:before {
  content: "";
}

:lang(en) .codr_btnarea .codr_tooltip_wrap > .codr_btn {
  line-height: inherit;
}

/* skip */
#skip {
  position: relative;
  z-index: 1002;
}

#skip ul li {
  display: inline;
}

#skip h2 {
  display: block;
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
  font-size: 1px;
  line-height: 0;
  text-indent: -999em;
  white-space: nowrap;
}

#skip a {
  position: absolute;
  top: -100px;
  left: -999px;
  display: inline-block;
  border: 1px solid #24242c;
  background: #31313b;
  color: white;
}

#skip a:focus,
#skip a:active {
  top: 0;
  left: 0;
  cursor: pointer;
  zoom: 1;
  display: inline-block;
  margin-right: 9px;
  padding: 5px 8px 4px;
  background: #31313b;
  font-weight: normal;
  font-size: 11px;
  line-height: 1.4em;
}

.body_wide,
.body_wide #wrap,
.body_wide .gnb_bnr_wrap {
  min-width: 1280px;
}

/* 1280px container */
.body_wide_ctn #container,
.body_wide_ctn #container_sm,
.body_wide_ctn #container_sd {
  width: 100%;
  min-width: 1280px;
}

.body_wide_ctn #content {
  width: 1280px;
  margin-left: auto;
  margin-right: auto;
}

.body_wide_ctn .sub_content {
  width: 1280px;
}

.body_wide_ctn #sub_contents {
  width: 1280px;
}

.body_wide_ctn.body_em .content_ty2 {
  width: 1280px;
}

.body_wide_ctn.body_traders #container .sub_wrap {
  width: 1280px;
}

.body_wide_ctn.body_traders #container {
  background-image: none;
}

/* webkit 버그 */
/*.bx-wrapper .bx-viewport{-webkit-backface-visibility:hidden;-webkit-transform:scale(1)}*/
/* 크롬 bxslider blur 버그 fix */
.bx-wrapper .bx-viewport > ul > li,
.bx-wrapper .bx-viewport > div > div {
  backface-visibility: hidden;
}

/* infinite grid */
.grid_container {
  min-height: 1000px;
}

.grid_loader {
  opacity: 0;
  padding: 40px 0;
}

.grid_loader.on {
  opacity: 1;
}

.grid_loading {
  height: 60px;
  background: url(../../img/common/loading.gif) no-repeat 50% 50%;
}

.grid_item .tmpl_noresult {
  width: 1280px;
  margin-left: auto;
  margin-right: auto;
}

#content {
  position: relative;
  z-index: 1;
}

.btn {
  display: inline-block;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
  line-height: 0.95em;
  text-align: center;
  white-space: nowrap;
  vertical-align: top;
  appearance: none;
  text-decoration: none;
}

.btn span {
  display: inline-block;
  vertical-align: top;
}

button {
  overflow: visible;
  border: 0 none;
  border-radius: 0;
  cursor: pointer;
}

button::-moz-focus-inner,
input[type=button]::-moz-focus-inner,
input[type=submit]::-moz-focus-inner,
input[type=reset]::-moz-focus-inner {
  padding: 0;
  border: 0 none;
}

input.btn,
button.btn {
  overflow: visible;
  border: 0 none;
  background-color: transparent;
  cursor: pointer;
}

/* 상품상세 IFRAME */
/* 버튼 */
.bn_ar {
  overflow: hidden;
  text-align: center;
  zoom: 1;
  margin-top: 20px;
}

.bn_ar.mobile {
  padding: 0 0 80px 0;
}

.bn_ar .bn {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  min-width: 140px;
  border: 1px solid #3b3b3b;
  background-color: #3b3b3b;
  color: #fff;
  line-height: 38px;
  height: 40px;
  border-radius: 4px;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
  font-size: 15px;
  text-align: center;
  vertical-align: top;
  text-decoration: none;
  margin-left: 5px;
}

.bn_ar .bn:first-child {
  margin-left: 0;
}

.bn_ar .bn.btn_login {
  width: 110px;
  min-width: 110px;
  height: 90px;
  line-height: 90px;
}

.bn_ar .bn.btn_login_large {
  width: 110px;
  min-width: 110px;
  height: 140px;
  line-height: 140px;
}

.bn_ar .bn.xsmall {
  width: 150px;
  height: 36px;
  line-height: 36px;
  font-size: 15px;
  letter-spacing: -1px;
}

.bn_ar .bn.small {
  width: 160px;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  letter-spacing: -1px;
}

.bn_ar .bn.medium {
  width: 190px;
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  letter-spacing: -1px;
}

.bn_ar .bn.large {
  width: 260px;
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  letter-spacing: -1px;
}

.bn_ar button.bn {
  line-height: 38px;
  cursor: pointer;
}

.bn_ar button.bn.xsmall {
  line-height: 31px;
}

.bn_ar button.bn.small {
  line-height: 35px;
}

.bn_ar button.bn.medium,
.bn_ar button.bn.large {
  line-height: 45px;
}

.bn_ar .bn.color1 {
  background-color: #3b3b3b;
  border-color: #3b3b3b;
  color: #fff;
}

.bn_ar .bn.color2 {
  background-color: #f9f9f9;
  border-color: #ccc;
  color: #222;
}

.bn_ar .bn.color3 {
  background-color: #f14f4f;
  border-color: #f14f4f;
}

.bn_ar .bn.txt_ty2 {
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
}

.login .bn_ar {
  margin-top: 13px;
}

.login .bn_ar .btn_login {
  position: absolute;
  top: 0;
  right: 21px;
}

.nonmember .bn_ar {
  text-align: center;
}

.nonmember .section_wrap .bn_ar {
  margin: 10px 0 0 92px;
  text-align: left;
}

.login .bn_ar .bn.xsmall {
  width: 123px;
  margin-left: 0;
}

.member_certification .bn_ar .btn_login {
  position: absolute;
  top: 0;
  right: 0px;
}

.b2e_member .bn_ar .btn_login_large {
  position: absolute;
  top: 20px;
  right: 0px;
}

.adult_certify .bn_ar .btn_login {
  position: absolute;
  top: 0;
  right: 0px;
}

.layer_pop .bn_ar {
  margin-top: 0;
  padding-bottom: 30px;
  text-align: center;
  zoom: 1;
}

.login .content_support .bn_ar {
  margin: 0;
  text-align: right;
  padding-top: 0;
}

.login .content_support .bn_ar .xsmall {
  color: #666;
}

.login .content_support .bn_ar .xsmall.color1 {
  color: #fff;
}

.please_comment .bn_ar .bn.large,
.content_main .section .clip_data_nbox .bn_ar .bn.large {
  height: 40px;
  font-size: 17px;
  line-height: 40px;
}

.mobile_version {
  width: 1280px;
  margin: 10px auto 0;
}

.mobile_version a {
  display: block;
  height: 110px;
  padding-top: 2px;
  background: #fff;
  border: 1px solid #c3c3c3;
  border-radius: 3px;
  box-shadow: inset 0 -8px 0 0 #f0f0f0;
  font-weight: bold;
  font-size: 36px;
  color: #666;
  line-height: 110px;
  text-align: center;
  text-decoration: none;
}

/* cmfooter 1280 */
#footer.common_footer {
  background-color: #fff;
}

.common_footer {
  position: static;
  width: auto;
  margin: 0 auto;
  padding-top: 30px;
}

.common_footer .service_support {
  width: auto;
  height: 51px;
  margin-bottom: 0;
  border-top: 1px solid #e4e4e4;
  border-bottom: 1px solid #e4e4e4;
}

.common_footer .service_support .f_inner {
  position: relative;
  width: 1280px;
  margin: 0 auto;
  zoom: 1;
}

.common_footer .service_support .f_inner:after {
  display: block;
  clear: both;
  content: "";
}

.common_footer .support_txt {
  display: inline;
  float: left;
  padding-top: 19px;
}

.common_footer .support_txt:after {
  content: "";
  display: block;
  clear: both;
}

.common_footer .support_txt li {
  float: left;
  margin-left: 19px;
  padding-left: 20px;
  background: url(../../img/footer/bu_bar.png) no-repeat 0 2px;
}
.common_footer .support_txt li:first-child {
  padding-left: 0;
  margin-left: 0;
}

.common_footer .support_txt li a {
  display: block;
  height: 15px;
  text-decoration: none;
  color: #777;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
}

.common_footer .support_txt li a:hover {
  text-decoration: underline;
}

.common_footer .support_txt li a strong {
  color: var(--m-colors-primary, #ff5452);
  font-weight: bold;
}

.common_footer .support_txt li:first-child {
  background: none;
}

.common_footer .support_site {
  float: right;
}
.common_footer .support_site .family_link {
  float: left;
  margin-left: -1px;
}

.common_footer .family_link {
  position: relative;
  z-index: 300;
  float: right;
  right: 0;
  top: 10px;
  width: 182px;
  height: 30px;
}

.common_footer .family_link h2 {
  right: 0;
  top: 13px;
  width: 182px;
  height: 30px;
}

.common_footer .family_link h2 a {
  position: relative;
  width: 182px !important;
  height: 30px !important;
  padding: 0 10px 0 15px;
  *padding: 0;
  line-height: 29px;
  text-align: left;
  color: #444;
  font-size: 12px;
  font-weight: normal;
  text-decoration: none;
  border: solid 1px #cecece;
  box-sizing: border-box;
}

.common_footer .family_link h2 a .sel_arrow {
  position: absolute;
  top: 50%;
  right: 10px;
  width: 15px;
  height: 9px;
  margin-top: -5px;
  background: url(../../img/footer/bg_sel_arrow_close.png);
}

.common_footer .family_link h2 a.active .sel_arrow {
  background: url(../../img/footer/bg_sel_arrow_open.png);
}

.common_footer .family_link h2 a.active {
  background-position: -300px -50px;
}

.common_footer .family_link dl {
  display: none;
  position: absolute;
  bottom: 29px;
  left: 0;
  width: 156px;
  border: 1px solid #b2b2b2;
  padding: 3px 12px 13px 12px;
  background-color: #fff;
}

.common_footer .family_link dl.active {
  display: block;
}

.common_footer .family_link dl dt {
  position: relative;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 1px;
  color: #969696;
  padding: 8px 0 2px 0;
  display: block;
}

.common_footer .family_link dl dt span.txt {
  position: relative;
  padding-right: 5px;
  background-color: #fff;
  z-index: 99;
}

.common_footer .family_link dl dt:after {
  position: absolute;
  top: 16px;
  left: 10px;
  height: 1px;
  background-color: #cecece;
  width: 143px;
  content: "";
  z-index: 9;
}

.common_footer .family_link dl dt.no_tit:after {
  display: none;
}

.common_footer .family_link dl dd a {
  font-size: 12px;
  color: #222;
  text-decoration: none;
  padding: 2px 0;
  display: block;
  line-height: 15px;
}

.common_footer .family_link dl dd a:hover {
  text-decoration: underline;
  color: var(--m-colors-primary, #ff5452);
}

.common_footer .sp_cmfooter,
.common_footer .cmfooter_corp_wrap .cmfooter_corp_cs .cmfooter_cs_tel:before {
  display: inline-block;
  background: url(../../img/footer/sp_cmfooter.png) no-repeat 0 0;
  vertical-align: top;
}

.common_footer .cmfooter_corp_wrap {
  min-height: 338px;
  padding: 20px 0 70px;
  font-size: 12px;
  line-height: 23px;
  letter-spacing: -0.5px;
  color: #777;
}

.common_footer .cmfooter_corp_wrap .cmfooter_corp_row {
  margin: 0 0 24px;
}

.common_footer .cmfooter_corp_wrap .cmfooter_corp_in {
  position: relative;
  width: 1280px;
  margin: 0 auto;
  zoom: 1;
}

.common_footer .cmfooter_corp_wrap .cmfooter_corp_in:after {
  display: block;
  clear: both;
  content: "";
}

.common_footer .cmfooter_corp_wrap .cmfooter_corp_info {
  float: left;
}

.common_footer .cmfooter_corp_wrap .cmfooter_corp_mall {
  float: left;
  margin-left: 70px;
  zoom: 1;
}

:lang(en) .common_footer .cmfooter_corp_wrap .cmfooter_corp_mall,
:lang(zh) .common_footer .cmfooter_corp_wrap .cmfooter_corp_mall {
  display: block;
  float: none;
  margin-left: 0;
  margin-bottom: 20px;
}

.common_footer .cmfooter_corp_wrap .cmfooter_corp_mall:after {
  display: block;
  clear: both;
  content: "";
}

.common_footer .cmfooter_corp_wrap .cmfooter_corp_mall:first-child {
  margin-left: 0;
}

.common_footer .cmfooter_corp_wrap .cmfooter_corp_mall dt {
  font-weight: bold;
  color: #222;
}

.common_footer .cmfooter_corp_wrap .cmfooter_corp_txarea .cmfooter_corp_txwrap {
  margin: 11px 0 0;
}

.common_footer .cmfooter_corp_wrap .cmfooter_corp_txarea .cmfooter_corp_txwrap:first-child {
  margin-top: 0;
}

.common_footer .cmfooter_corp_wrap .cmfooter_corp_txarea .cmfooter_corp_tx {
  display: inline-block;
  margin-left: 7px;
}

.common_footer .cmfooter_corp_wrap .cmfooter_corp_txarea .cmfooter_corp_tx:first-child {
  margin-left: 0;
}

.common_footer .cmfooter_corp_wrap .cmfooter_corp_btnarea {
  margin-top: 4px;
}

.common_footer .cmfooter_corp_wrap .cmfooter_corp_btn {
  display: inline-block;
  margin: 0 0 0 3px;
  padding: 1px 8px 1px 6px;
  border: 1px solid #dcdcdc;
  border-radius: 2px;
  line-height: 19px;
  font-size: 12px;
  color: #555;
  text-decoration: none;
  vertical-align: top;
}

.common_footer .cmfooter_corp_wrap .cmfooter_corp_btn:after {
  display: inline-block;
  margin: 6px 0 0 6px;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-left: 4px solid var(--m-colors-primary, #ff5452);
  vertical-align: top;
  content: "";
}

.common_footer .cmfooter_corp_wrap .cmfooter_corp_btn:first-child {
  margin-left: 0;
}

.common_footer .cmfooter_corp_wrap .cmfooter_corp_cs {
  margin: 2px 0 8px;
  line-height: 21px;
  zoom: 1;
}

.common_footer .cmfooter_corp_wrap .cmfooter_corp_cs:after {
  display: block;
  clear: both;
  content: "";
}

.common_footer .cmfooter_corp_wrap .cmfooter_corp_cs .cmfooter_cs_tel {
  display: inline-block;
  margin-right: 6px;
  font-size: 24px;
  letter-spacing: 0;
  color: #222;
  vertical-align: top;
}

.common_footer .cmfooter_corp_wrap .cmfooter_corp_cs .cmfooter_cs_tel:before {
  width: 22px;
  height: 21px;
  background-position: 0 0;
  content: "";
}

.common_footer .cmfooter_corp_wrap .cmfooter_corp_cs strong.cmfooter_corp_tx {
  color: #222;
  font-weight: normal;
  line-height: 23px;
  padding-left: 2px;
}

.common_footer .cmfooter_corp_wrap .cmfooter_corp_rgt {
  position: absolute;
  top: 3px;
  right: 0;
  text-align: right;
}

.common_footer .sp_cmfooter_sns {
  display: inline-block;
  background: url(../../img/footer/sp_cmfooter_sns.png) no-repeat 0 0;
  vertical-align: top;
}

.common_footer .cmfooter_corp_wrap .cmfooter_sns {
  margin-bottom: 30px;
}

.common_footer .cmfooter_corp_wrap .cmfooter_sns strong {
  display: inline-block;
  position: relative;
  margin: 11px 14px 0 28px;
  font-weight: 500;
  font-weight: normal;
  vertical-align: top;
}

.common_footer .cmfooter_corp_wrap .cmfooter_sns a {
  width: 45px;
  height: 45px;
  margin-left: 5px;
}

.common_footer .cmfooter_corp_wrap .cmfooter_sns .cmfooter_sns_fb {
  background-position: 0 0;
}

.common_footer .cmfooter_corp_wrap .cmfooter_sns .cmfooter_sns_ig {
  background-position: 0 -50px;
}

.common_footer .cmfooter_corp_wrap .cmfooter_sns .cmfooter_sns_kakao {
  background-position: 0 -100px;
}

.common_footer .cmfooter_corp_wrap .cmfooter_sns .cmfooter_sns_blog {
  background-position: 0 -150px;
}

.common_footer .cmfooter_corp_wrap .cmfooter_app {
  text-align: right;
}

.common_footer .cmfooter_corp_wrap .cmfooter_app_lft {
  display: inline-block;
  margin: 0 14px 0 0;
  text-align: left;
  vertical-align: top;
}

.common_footer .cmfooter_corp_wrap .cmfooter_app_tit {
  display: block;
  font-weight: bold;
}

.common_footer .cmfooter_corp_wrap .cmfooter_app_ssg .cmfooter_app_qrcode {
  width: 70px;
  height: 70px;
  background-position: -200px -50px;
}

.common_footer .cmfooter_corp_wrap .cmfooter_app_sm .cmfooter_app_qrcode {
  width: 70px;
  height: 70px;
  background-position: -200px -150px;
}

.common_footer .cmfooter_corp_wrap .cmfooter_app_em .cmfooter_app_qrcode {
  width: 70px;
  height: 70px;
  background-position: -200px -250px;
}

.common_footer .cmfooter_corp_wrap .cmfooter_app_traders .cmfooter_app_qrcode {
  width: 71px;
  height: 74px;
  background-position: -200px -350px;
}

.common_footer .cmfooter_corp_wrap .cmfooter_corp_row2 {
  margin: 0 0 34px;
}

.common_footer .cmfooter_corp_wrap .cmfooter_corp_hosting .cmfooter_corp_tx {
  display: inline-block;
  margin-left: 45px;
  letter-spacing: 0;
  color: #222;
}

.common_footer .cmfooter_corp_wrap .cmfooter_corp_hosting .cmfooter_corp_tx:first-child {
  margin-left: 0;
}

.common_footer .cmfooter_corp_wrap .cmfooter_corp_notice {
  font-size: 10px;
  line-height: 1.6;
  letter-spacing: -0.42px;
  color: #777;
}

.common_footer .cmfooter_corp_wrap .cmfooter_corp_arr_link {
  color: #222;
  margin: 0 5px;
}
.common_footer .cmfooter_corp_wrap .cmfooter_corp_arr_link:after {
  content: "";
  display: inline-block;
  width: 4px;
  height: 4px;
  margin: 6px 0 0 4px;
  border: solid #222;
  border-width: 1px 1px 0 0;
  transform: rotate(45deg);
  vertical-align: top;
}

.common_footer .cmfooter_corp_wrap .cmfooter_mark {
  position: relative;
}

.common_footer .cmfooter_corp_wrap .cmfooter_mark ul li {
  display: inline-block;
  margin-right: 18px;
  padding: 6px 0;
  font-size: 10px;
  line-height: 13px;
  color: #777;
  vertical-align: top;
}

.common_footer .cmfooter_corp_wrap .cmfooter_mark ul li.cmfooter_mark_sgi {
  padding: 0;
}

.common_footer .cmfooter_corp_wrap .cmfooter_mark ul li.cmfooter_mark_sgi2 {
  padding: 6px 0;
  line-height: 15px;
}

.common_footer_sm .cmfooter_corp_wrap .cmfooter_mark ul li.cmfooter_mark_sgi {
  display: block;
  margin-top: 5px;
}

.common_footer .cmfooter_corp_wrap .cmfooter_mark ul li a {
  color: #777;
  text-decoration: none;
}

.common_footer .cmfooter_corp_wrap .cmfooter_mark ul li .cmfooter_corp_btn {
  color: #555;
  vertical-align: text-bottom;
}
.common_footer .cmfooter_corp_wrap .cmfooter_mark ul li .cmfooter_corp_btn:not(:first-child) {
  margin-left: 5px;
}

.common_footer .cmfooter_corp_wrap .cmfooter_mark_ico {
  width: 30px;
  height: 30px;
}

.common_footer .cmfooter_corp_wrap .cmfooter_mark_risk .cmfooter_mark_ico {
  width: 97px;
  height: 30px;
  background-position: 0 -50px;
}

.common_footer .cmfooter_corp_wrap .cmfooter_mark_kisa .cmfooter_mark_ico {
  width: 30px;
  height: 30px;
  background-position: 0 -100px;
}

.common_footer .cmfooter_corp_wrap .cmfooter_mark_kolsa .cmfooter_mark_ico {
  width: 48px;
  height: 30px;
  background-position: 0 -150px;
}

.common_footer .cmfooter_corp_wrap .cmfooter_mark_ea .cmfooter_mark_ico {
  width: 25px;
  height: 30px;
  background-position: 0 -200px;
}

.common_footer .cmfooter_corp_wrap .cmfooter_mark_wa .cmfooter_mark_ico {
  width: 26px;
  height: 30px;
  background-position: 0 -250px;
}

.common_footer .cmfooter_corp_wrap .cmfooter_mark_aa .cmfooter_mark_ico {
  width: 26px;
  height: 30px;
  background-position: 0 -300px;
}

.common_footer .cmfooter_corp_wrap .cmfooter_mark_norton .cmfooter_mark_ico {
  width: 60px;
  height: 30px;
  background-position: 0 -350px;
}

.common_footer .cmfooter_corp_wrap .cmfooter_mark_ico + .cmfooter_mark_txt {
  margin-left: 4px;
}

.common_footer .cmfooter_corp_wrap .cmfooter_mark_txt {
  display: inline-block;
  padding: 2px 0;
}

.common_footer .cmfooter_corp_wrap .cmfooter_mark_tit {
  font-weight: bold;
  font-size: 11px;
  color: #222;
}

.common_footer .cmfooter_corp_wrap .cmfooter_corp_copy {
  display: block;
  width: 164px;
  height: 41px;
  margin: 0 auto;
  background-position: -200px 0;
}

@media screen and (-webkit-min-device-pixel-ratio: 2) {
  .common_footer .sp_cmfooter,
.common_footer .cmfooter_corp_wrap .cmfooter_corp_cs .cmfooter_cs_tel:before {
    background-image: url(../../img/footer/sp_cmfooter@2x.png);
    background-size: 640px auto;
  }
  .common_footer .sp_cmfooter_sns {
    background-image: url(../../img/footer/sp_cmfooter_sns@2x.png);
    background-size: 45px auto;
  }
}
.clip_product_bx {
  display: none;
  position: absolute;
  left: -5px;
  top: 35px;
  border: 1px solid #666;
  z-index: 100;
  background: url(../../img/common/bg_clip_line_02.gif) no-repeat 0 0 #fff;
}

.clip_product_bx.recommend_setup {
  display: block;
  position: absolute;
  left: 50%;
  top: 100px;
  margin-left: -362px;
  z-index: 999999;
}

.clip_product_bx.clip_wcase3.property {
  position: absolute;
  left: 56px;
  top: 49px;
  border: 1px solid #222;
}

.clip_product_bx.clip_wcase5.property {
  position: absolute;
  left: 56px;
  top: 49px;
  border: 1px solid #222;
}

.clip_product_bx.clip_wcase6.property {
  position: absolute;
  left: 0;
  top: 105px;
  border: 1px solid #222;
}

.clip_product_bx .clip_product_sec {
  margin: 0 20px 10px 20px;
}

.clip_product_bx .restructure {
  margin: 15px 10px;
  text-align: center;
}

.clip_product_bx .btn_close {
  position: absolute;
  right: 15px;
  top: 13px;
  z-index: 30;
}

.clip_product_bx .mark_arrow {
  position: absolute;
  top: -8px;
  left: 72px;
  width: 13px;
  height: 8px;
  background: url(../../img/product/bg_arrow_02.gif) no-repeat;
}

.clip_product_bx.clip_type {
  width: 322px;
  background: #fff;
  left: -27px;
  padding: 0;
}

.clip_product_bx.clip_type .restructure {
  margin: 13px 0 0 22px;
  text-align: left;
}

.clip_product_bx.clip_type .clip_title {
  display: inline-block;
  padding-bottom: 12px;
  border-bottom: 1px dotted #a7a7a7;
}

.clip_title p {
  padding-top: 9px;
  color: #999;
  font-size: 12px;
  text-align: center;
}

.clip_product_bx.clip_type .clip_area {
  position: relative;
  margin: 18px 0 0 11px;
  zoom: 1;
}

.clip_product_bx.clip_type .clip_r {
  margin-bottom: 10px;
  zoom: 1;
}

.clip_product_bx.clip_type .clip_area strong {
  width: 94px;
  display: block;
  float: left;
  color: #f52052;
}

.clip_product_bx.clip_type .my_size {
  float: left;
  width: 81px;
  padding-top: 0;
}

.clip_product_bx.clip_type .my_size li {
  float: left;
  margin: 0 5px 0 0;
}

.clip_area .btn_resize {
  position: absolute;
  right: 38px;
  top: 0;
  display: inline-block;
  width: 73px;
  height: 19px;
  background: url(../../img/common/clip_resize.png) no-repeat 0 0;
}

.clip_area .btn_size {
  display: inline-block;
  width: 83px;
  height: 19px;
  background: url(../../img/common/clip_btn_size.png) no-repeat;
}

.clip_type .my_brand {
  float: left;
  text-align: left;
  padding-top: 0;
}

.clip_type .my_brand span {
  display: inline-block;
  color: #777;
}

.clip_type .my_brand_etc {
  display: inline-block;
  margin-left: 5px;
  font-weight: bold;
  color: #222;
}

.clip_product_bx.clip_type_v .restructure {
  margin: 13px 0 23px 22px;
  margin-top: 20px;
}

.clip_type_v .my_brand {
  width: 173px;
}

.clip_type_v .my_brand span {
  color: #232323;
}

.clip_type_v .clip_best_area {
  margin-top: 13px;
  padding: 11px 0 7px 9px;
  background: #f9f9f9;
}

.clip_type_v .clip_best_area p {
  padding-bottom: 3px;
  font-weight: bold;
  color: #353535;
}

.clip_type_v .clip_best_lst {
  padding-left: 3px;
}

.clip_type_v .clip_best_lst li {
  padding-bottom: 3px;
}

.clip_type_v .clip_best_lst li a {
  color: #666;
  text-decoration: none;
  float: none;
  margin: 0;
}

.clip_type_v .clip_best_lst li a:hover,
.clip_type_v .clip_best_lst li a:active,
.clip_type_v .clip_best_lst li a:focus {
  font-weight: bold;
  color: #ff4873;
}

.clip_type_v .clip_type .clip_best_area strong {
  width: 100%;
  color: #353535;
}

.clip_product_bx.clip_type .clip_r:after {
  clear: both;
  display: block;
  content: "";
}

.clip_product_bx.clip_type .clip_area:after {
  clear: both;
  display: block;
  content: "";
}

.search_section .clip_product_bx.clip_wcase3.property .mark_arrow {
  left: 428px;
}

.category_section .clip_product_bx.clip_wcase3.property .mark_arrow {
  left: 413px;
}

.clip_product_sec .clip_top_tit {
  position: relative;
  height: 37px;
  padding-top: 10px;
  *zoom: 1;
  text-align: center;
}

.clip_wcase2 .clip_product_sec .clip_top_tit {
  text-align: left;
}

.clip_product_sec .clip_top_tit:after {
  clear: both;
  display: block;
  content: "";
}

.clip_product_sec .clip_top_tit h2 {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  padding-left: 27px;
  font-size: 19px;
  font-weight: bold;
  color: #222;
  line-height: 28px;
  background: url(../../img/common/ico_heart.jpg) no-repeat 0 3px;
}

.clip_product_sec .clip_top_tit h2 a {
  color: #222;
  text-decoration: none;
}

.clip_product_sec .clip_top_tit .bar {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  width: 1px;
  height: 9px;
  margin: 4px 10px 0;
  background: #ccc;
}

.clip_product_sec .clip_top_tit .txt {
  font-size: 11px;
}

.clip_product_bx.clip_wcase5.property .clip_product_sec .clip_top_tit .ico_heart {
  position: absolute;
  left: 310px;
  top: 11px;
  width: 24px;
  height: 20px;
  background: url(../../img/common/ico_heart.jpg) no-repeat 0 0;
}

.clip_product_bx.clip_wcase3.property .clip_product_sec .clip_top_tit .ico_heart {
  position: absolute;
  left: 168px;
  top: 11px;
  width: 24px;
  height: 20px;
  background: url(../../img/common/ico_heart.jpg) no-repeat 0 0;
}

.clip_product_bx.clip_wcase2 .bn_ar {
  padding-bottom: 30px;
}

.clip_product_sec .clip_top_type2 h2 {
  font-size: 22px;
}

.clip_product_sec .clip_top_type3 {
  padding-left: 0;
  background: none;
  border-bottom: 1px dotted #666;
  color: #666;
}

.clip_product_sec .clip_top_type3 h2 {
  font-size: 22px;
}

.clip_product_sec .clipping_sec {
  height: 48px;
  border-bottom: 1px dashed #d8d8d8;
  line-height: 48px;
  *zoom: 1;
}

.clip_product_sec .clipping_sec:after {
  clear: both;
  display: block;
  content: "";
}

.clip_product_sec .clipping_sec p {
  float: left;
  font-size: 12px;
  color: #666;
  font-weight: bold;
}

.clip_product_sec .clipping_sec .btn_setting {
  float: right;
  width: 56px;
  height: 28px;
  margin-top: 10px;
  background: url(../../img/common/bg_btn_setting.gif) no-repeat;
  text-indent: -1000em;
  overflow: hidden;
}

.clip_product_sec .content_field {
  position: relative;
  margin: 0 -20px 0 -16px;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 615px;
}

.clip_product_sec .content_field .clip_recomlist {
  overflow: hidden;
  width: 475px;
  padding-left: 20px;
}

.clip_product_sec .content_field .clip_recomlist .itembx .thumbnail {
  height: auto;
  margin-top: 0 !important;
  position: relative !important;
}

.clip_product_sec .content_field .clip_recomlist .itembx .thumbnail a {
  width: 140px;
}

.clip_product_sec .content_field .btn_viewmore {
  display: block;
  height: 30px;
  margin-top: 20px;
  padding-top: 13px;
  border: 1px solid #f5f5f5;
  border-bottom: 1px solid #d3d3d3;
  background: #fdfdfd;
  text-align: center;
}

.clip_product_sec .new_itembx {
  padding: 17px 0 0 20px;
}

.clip_product_sec .new_itembx .tit {
  padding-bottom: 17px;
  color: #666;
}

.clip_product_sec .new_itembx .tit strong {
  margin-right: 5px;
  color: #00aac4;
}

.clip_product_sec .new_itembx .tit em {
  color: #ff6865;
  padding-left: 5px;
}

.clip_product_sec .new_itembx .tit em a {
  color: #ff6865;
  text-decoration: none;
}

.clip_product_sec .new_itembx ul {
  width: auto;
  *zoom: 1;
}

.clip_product_sec .new_itembx ul:after {
  display: block;
  clear: both;
  content: "";
}

.clip_product_sec .new_itembx ul li {
  float: left;
  margin: 0 1px 10px 0;
}

.clip_product_sec .new_itembx ul li a {
  display: block;
  width: 108px;
  height: 108px;
  border: 1px solid #d8d8d8;
}

.clip_product_sec .new_itembx ul li a img {
  width: 108px;
  height: 108px;
}

.clip_product_sec .new_itembx .itembx {
  width: 110px;
  height: 110px;
}

.clip_product_sec .new_itembx .itembx .thumbnail {
  width: 110px;
  height: 110px;
  position: relative !important;
}

.clip_product_sec .new_itembx .itembx .thumbnail .ico_left {
  top: 4px;
  left: -4px;
}

.clip_product_sec .new_itembx .itembx .thumbnail .coupon_sale span,
.clip_product_sec .new_itembx .itembx .thumbnail .sale span {
  letter-spacing: -1px;
  font-size: 18px;
  padding-top: 2px;
}

.clip_product_sec .new_itembx .itembx .thumbnail .coupon_sale span em,
.clip_product_sec .new_itembx .itembx .thumbnail .sale span em {
  font-size: 12px;
  vertical-align: 3px;
}

.clip_product_sec .new_itembx .itembx .thumbnail .coupon_sale .persent,
.clip_product_sec .new_itembx .itembx .thumbnail .sale .persent {
  top: 7px;
}

.clip_product_sec .new_itembx .itembx .thumbnail .thmb_over_bg {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url(../../img/common/bg_clip_thmb_over.png);
}

.clip_product_sec .new_itembx .itembx .thumbnail .thmb_over {
  display: none;
  position: absolute;
  left: 10px;
  bottom: 10px;
  width: 90px;
  color: #fff;
  line-height: 14px;
  font-size: 11px;
  text-align: center;
  cursor: pointer;
}

.clip_product_sec .new_itembx .itembx .thumbnail .thmb_over .clip_tx_tit {
  display: block;
  overflow: hidden;
  max-height: 28px;
  margin-bottom: 5px;
}

.clip_product_sec .new_itembx .itembx .thumbnail .thmb_over del,
.clip_product_sec .new_itembx .itembx .thumbnail .thmb_over .price_tt,
.clip_product_sec .new_itembx .itembx .thumbnail a:hover .thmb_over_bg,
.clip_product_sec .new_itembx .itembx .thumbnail a:hover .thmb_over {
  display: block;
}

.clip_product_sec .catelst_box {
  margin: 20px -10px 0;
  *zoom: 1;
}

.clip_product_bx.clip_wcase2.recommend_setup .clip_product_sec .bn_ar {
  clear: both;
}

.clip_product_sec .catelst_box:after {
  clear: both;
  display: block;
  content: "";
}

.clip_product_sec .catelst_box .lst_box {
  float: left;
  width: 328px;
  margin: 0 10px;
  border-top: 1px solid #666;
}

.clip_product_sec .catelst_box .lst_box input {
  display: inline-block;
  width: 13px;
  height: 13px;
  margin: 1px 6px 0 0;
  padding: 0;
  vertical-align: top;
}

.clip_product_sec .catelst_box .lst_box .tit {
  padding-left: 10px;
  height: 38px;
  background: #eee;
  font-weight: bold;
  font-size: 12px;
  color: #222;
  line-height: 38px;
}

.clip_product_sec .catelst_box .lst_box .tit em {
  color: #00aac4;
}

.clip_product_sec .catelst_box .lst_box .lst {
  overflow-x: hidden;
  position: relative;
  overflow-y: auto;
  height: 195px;
  border-top: 1px solid #d1d1d1;
}

.clip_product_sec .catelst_box .lst_box .lst ul li {
  display: block;
  height: 18px;
  padding: 10px 20px 10px 12px;
  border-bottom: 1px solid #e5e5e5;
  white-space: nowrap;
}

.clip_product_sec .catelst_box .lst_box .lst ul li label {
  display: inline-block;
  overflow: hidden;
  width: auto;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #666;
}

.clip_product_sec .catelst_box .lst_box .lst_del {
  position: absolute;
  margin: 0 0 0 10px;
  vertical-align: top;
}

.clip_product_sec .capture_sec {
  padding: 40px 0 5px;
  text-align: center;
}

.clip_product_sec .capture_choice .bn_cl,
.clip_product_sec .capture_sec .bn_cl {
  overflow: hidden;
  width: 295px;
  padding-left: 10px;
  float: left;
}

.clip_product_sec .capture_choice .btn_cl_start,
.clip_product_sec .capture_sec .btn_cl_start {
  float: right;
  display: inline-block;
  padding-right: 22px;
  width: 164px;
  height: 54px;
  margin: 0 auto;
  background: url(../../img/common/clip_guide.png) -500px -61px no-repeat;
}

.clip_product_sec .capture_sec h3 {
  width: 275px;
  height: 26px;
  margin: 0 auto;
  background: url(../../img/common/clip_guide.png) no-repeat;
}

.clip_product_sec .capture_sec p.advice {
  width: 386px;
  height: 16px;
  margin: 12px auto 38px;
  color: #666;
  font-size: 12px;
  background: url(../../img/common/clip_guide.png) 0 -50px no-repeat;
}

.clip_product_sec .capture_choice .capture_img {
  width: 458px;
  height: 364px;
  margin: 0 auto 20px;
  padding-right: 7px;
  padding-bottom: 35px;
  border-bottom: 1px solid #d8d8d8;
  background: url(../../img/common/clip_guide_02.png) 0 0px no-repeat;
}

.clip_product_sec .capture_sec .capture_img {
  width: 454px;
  height: 406px;
  margin: 0 auto 20px;
  padding-right: 7px;
  padding-bottom: 35px;
  border-bottom: 1px solid #d8d8d8;
  background: url(../../img/common/clip_guide.png) 0 -200px no-repeat;
}

.clip_product_sec .capture_sec2 {
  position: relative;
  padding: 0;
  text-align: center;
  background: url(../../img/common/bg_clip_guide.jpg) 0 0 no-repeat;
  width: 512px;
  height: 620px;
  margin: -2px 0 0 -20px;
}

.clip_product_sec .capture_sec2 .btn_clip_info {
  position: absolute;
  width: 162px;
  height: 36px;
  top: 565px;
  left: 333px;
}

.clip_product_sec .capture_choice .bn_ar .btn_cl_go,
.clip_product_sec .capture_sec .bn_ar .btn_cl_go {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  width: 137px;
  height: 23px;
  margin: 0 auto;
  background: url(../../img/common/clip_guide.png) -500px 0 no-repeat;
}

.clip_product_sec .cllipping_check {
  padding: 17px 0;
}

.clip_product_sec .cllipping_check .check_data {
  background: url(../../img/common/bg_underline.gif) left bottom repeat-x;
}

.clip_product_sec .cllipping_check .size_sort {
  position: relative;
  padding: 20px 0;
}

.clip_product_sec .cllipping_check .brand_sort {
  padding-bottom: 20px;
}

.clip_product_sec .cllipping_check .check_data .check_inner {
  overflow: hidden;
}

.clip_product_sec .cllipping_check .check_data .check_inner .no_info {
  text-indent: -100px;
  text-align: center;
  color: #ff6865;
}

.clip_product_sec .cllipping_check .check_data .brand_lst {
  overflow: hidden;
  width: 712px;
  *zoom: 1;
}

.clip_product_sec .cllipping_check .check_data .brand_lst li {
  float: left;
  width: 177px;
  color: #666;
}

.clip_product_sec .cllipping_check .check_data .brand_lst li p {
  padding: 0;
}

.clip_product_sec .cllipping_check .check_data .brand_lst li .tit {
  padding-bottom: 2px;
  color: #747474;
}

.clip_product_sec .cllipping_check .size_sort .size_lst li {
  width: 100%;
  padding-bottom: 15px;
  color: #666;
  *zoom: 1;
}

.clip_product_sec .cllipping_check .size_sort .size_lst li:after {
  clear: both;
  display: block;
  content: "";
}

.clip_product_sec .cllipping_check .size_sort .size_lst li strong {
  display: block;
  float: left;
  width: 119px;
  padding-left: 9px;
  background: url(../../img/common/bg_bullet02.gif) no-repeat 0 50%;
}

.clip_product_sec .cllipping_check .size_sort .size_lst li div {
  float: left;
}

.clip_product_sec .cllipping_check .size_sort .size_lst .size_group {
  width: 400px;
  margin-top: 2px;
  padding: 0 0 0 1px;
  border: 0;
  *zoom: 1;
}

.clip_product_sec .cllipping_check .size_sort .size_lst .size_group span {
  width: 98px;
  margin-bottom: 5px;
}

.clip_product_sec .cllipping_check .size_sort .size_lst span {
  display: block;
  float: left;
  margin-left: -1px;
  border-left: 1px dotted #d8d8d8;
  font-size: 12px;
  line-height: 12px;
}

.clip_product_sec .cllipping_nbox {
  overflow: hidden;
  padding: 20px 0 30px;
}

.clip_product_sec .cllipping_nbox .noti {
  float: left;
  height: 22px;
  padding-left: 30px;
  background: url(../../img/common/ico_notice.gif) no-repeat;
  font-weight: bold;
  line-height: 22px;
  color: #666;
}

.clip_product_sec .cllipping_nbox .btn_size_setting {
  float: right;
  height: 26px;
  margin-top: -5px;
  padding: 0 11px;
  border: 1px solid #ccc;
  background: #fcfcfc;
  font-weight: bold;
  line-height: 26px;
  text-decoration: none;
  color: #666;
}

.clip_product_sec .cllipping_type2 {
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  height: 888px;
  padding: 32px 0 0;
}

.clip_product_sec .cllipping_type2 .btn_viewmore {
  display: block;
  height: 30px;
  padding-top: 13px;
  border: 1px solid #f5f5f5;
  border-bottom: 1px solid #d3d3d3;
  background: #fdfdfd;
  text-align: center;
}

.clip_product_sec .cllipping_type2:after {
  content: "";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}

.clip_product_sec .cllipping_type2 .clip_itemlist {
  clear: both;
}

.clip_product_sec .cllipping_type2 .cllipping_box {
  position: relative;
  *zoom: 1;
}

.clip_product_sec .cllipping_type2 .cllipping_sec {
  position: relative;
}

.clip_product_sec .cllipping_type2 .cllipping_sec.open {
  width: 835px;
  margin-bottom: 46px;
  padding: 20px 0;
  text-align: center;
  border: 1px solid #e5e5e5;
}

.clip_product_sec .cllipping_type2 .cllipping_sec.open p {
  width: 800px;
  margin: 0 20px;
  color: #999;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
}

.clip_product_sec .cllipping_type2 .cllipping_sec a.clip {
  position: absolute;
  left: 370px;
  bottom: -14px;
}

.clip_product_sec .cllipping_type2 .cllipping_sec.close {
  width: 785px;
  margin-bottom: 46px;
  padding: 25px 25px 30px;
  border: 1px solid #e5e5e5;
}

.clip_product_sec .cllipping_type2 .btn_area {
  margin-top: 25px;
  text-align: center;
}

.clip_product_sec .cllipping_type2 .btn_area a {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  float: none;
  min-width: 78px;
  margin-right: 4px;
  border: 1px solid #3b3b3b;
  border-radius: 3px;
  background: #3b3b3b;
  font-weight: bold;
  line-height: 26px;
  color: #fff;
  vertical-align: top;
  text-decoration: none;
}

.clip_product_sec .cllipping_type2 .btn_area a.cancel {
  background: #fcfcfc url(../../img/common/bg_border02.gif) left top repeat-x;
  color: #666;
  border: 1px solid #ccc;
}

.clip_product_sec .cllipping_type2 .itemlst {
  padding: 0 30px;
}

.clip_product_sec .cllipping_type2 .check_tit {
  float: left;
  width: 97px;
  padding-left: 11px;
  background: url(../../img/common/bu_circle.gif) no-repeat 0 50%;
}

.clip_product_sec .cllipping_type2 .check_data .brand_lst li input {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  width: 13px;
  height: 13px;
  margin: 1px 6px 0 0;
  padding: 0;
  vertical-align: top;
}

.clip_product_sec .cllipping_type2 .size_sort .size_lst li:after {
  display: block;
  clear: both;
  content: "";
}

.clip_product_sec .cllipping_type2 .size_sort .size_lst li input {
  display: inline-block;
  width: 13px;
  height: 13px;
  margin: 1px 6px 0 0;
  padding: 0;
  vertical-align: top;
}

.clip_product_sec .cllipping_type2 .size_sort .size_lst li span {
  border: 0;
}

.clip_product_sec .cllipping_type2 .size_sort .size_lst li label {
  display: inline-block;
  margin-top: 2px;
}

.clip_product_sec .cllipping_type2 .size_sort a {
  position: absolute;
  right: 0;
  top: 20px;
  display: inline-block;
  width: 138px;
  height: 26px;
  line-height: 26px;
  font-weight: bold;
  color: #666;
  text-align: center;
  text-decoration: none;
  background: #fcfcfc;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.clip_product_sec .cllipping_type2 .clip_btn_sec {
  margin-bottom: 20px;
  border-bottom: 1px dashed #d8d8d8;
}

.clip_product_sec .cllipping_type2 .btn_size_setting {
  position: absolute;
  right: 10px;
  top: -10px;
  height: 26px;
  padding: 0 11px;
  border: 1px solid #ccc;
  background: #fcfcfc;
  font-weight: bold;
  line-height: 26px;
  text-decoration: none;
  color: #666;
}

.clip_product_sec .cllipping_type2 .itemlst li {
  height: 440px;
}

.clip_product_sec .clip_mysize_setting {
  padding: 26px 0 22px;
  color: #666;
}

.clip_product_sec .clip_mysize_setting .tit {
  padding-bottom: 8px;
  font-size: 16px;
  color: #000;
}

.clip_product_sec .size_select_sec {
  position: relative;
  min-height: 242px;
  margin-top: 22px;
  padding: 30px 246px 0 0;
  border-top: 1px solid #ececec;
  border-bottom: 1px dashed #d8d8d8;
}

.clip_product_sec .size_select_sec .choice_size li {
  width: 100%;
  margin-bottom: 28px;
  *zoom: 1;
}

.clip_product_sec .size_select_sec .choice_size li:after {
  clear: both;
  display: block;
  content: "";
}

.clip_product_sec .size_select_sec .choice_size li strong {
  float: left;
  width: 116px;
}

.clip_product_sec .size_select_sec .choice_size li ul {
  float: left;
  width: 390px;
  margin: -4px 0 0 0;
}

.clip_product_sec .size_select_sec .choice_size li ul li {
  position: relative;
  float: left;
  width: auto;
  height: 23px;
  margin: 3px 2px 2px 3px;
}

.clip_product_sec .size_select_sec .choice_size li ul li label {
  display: block;
  width: 58px;
  height: 21px;
  border: 1px solid #e5e5e5;
  background: #fff;
  font-size: 12px;
  text-align: center;
  line-height: 21px;
  color: #000;
  text-decoration: none;
}

.clip_product_sec .size_select_sec .choice_size li ul li .scheck {
  overflow: hidden;
  top: 1px;
  left: 1px;
  width: 1px;
  height: 1px;
  opacity: 0;
  filter: alpha(opacity="1");
  z-index: -1;
}

.clip_product_sec .size_select_sec .choice_size li ul li .checked {
  display: none;
  position: absolute;
  top: -7px;
  right: -5px;
  width: 19px;
  height: 19px;
  background: url(../../img/product/ico_checked.png) center center no-repeat;
}

.clip_product_sec .size_select_sec .choice_size li ul li.checked .checked {
  display: block;
}

.clip_product_sec .size_select_sec .choice_size li ul li.checked label {
  border: 1px solid #000;
}

.clip_product_sec .capture_choice {
  padding: 40px 0 10px;
  text-align: center;
}

.clip_product_sec .capture_choice h3 {
  width: 370px;
  height: 59px;
  margin: 0 auto 20px auto;
  background: url(../../img/common/clip_guide.png) no-repeat 0 -100px;
}

.clip_product_sec .capture_choice p {
  padding: 0 0 27px;
  color: #000;
  font-size: 12px;
}

.clip_product_sec .capture_choice p a {
  color: #0000cc;
  font-weight: bold;
}

.clip_product_sec .sorting_area {
  float: none;
  width: 779px;
  height: 51px;
  padding: 0 30px;
  border-top: 1px solid #bfbfbf;
  border-bottom: 1px dotted #d9d9d9;
}

.clip_product_sec .sorting_area a {
  height: 51px;
  line-height: 51px;
}

.clip_product_sec .sorting_area a.active {
  text-decoration: none;
  background: url(../../img/common/bg_underline02.gif) left 40px repeat-x;
}

.clip_product_sec .btn_clip_more {
  border: 1px solid #dfdfdf;
  display: block;
  text-align: center;
  line-height: 0;
  background: #f8f8f8;
  height: 23px;
  padding-top: 6px;
  margin: 20px 20px 13px;
}

.clip_product_sec .btn_clip_more img {
  vertical-align: top;
}

.clip_wcase {
  width: 512px;
  background: url(../../img/common/bg_clip_line_new.gif) no-repeat 0 0 #fff;
}

.clip_wcase2 {
  width: 716px;
  border-top: 2px solid #222;
}

.clip_wcase3 {
  width: 532px;
}

.clip_wcase4 {
  width: 710px;
}

.clip_wcase5 {
  overflow: hidden;
  width: 876px;
  border-top: 2px solid #222;
  border-right: 1px solid #222;
  border-bottom: 1px solid #222;
  border-left: 1px solid #222;
}

.clip_wcase5 .clip_product_sec {
  margin: 0 0 20px 20px;
  overflow: hidden;
}

.clip_wcase6 {
  width: 800px;
  border: 1px solid #222;
}

.clip_wcase7 {
  width: 169px;
  background: #fff !important;
}

.clip_wcase8 {
  width: 278px;
  background: #fff !important;
}

.clip_wcase7 strong,
.clip_wcase8 strong {
  line-height: 16px;
  color: #666;
  font-size: 11px;
  letter-spacing: -1px;
}

.clip_wcase8 p {
  padding-top: 9px;
  text-align: center;
  color: #999;
  font-size: 11px;
}

.clip_wcase9 {
  width: 512px;
}

.clp_ly_bx {
  display: none;
  position: absolute;
  width: 452px;
  height: 635px;
  background: #fff;
  border: 1px solid #666;
  box-shadow: 0px 2px 10px #595959;
  -webkit-box-shadow: 0px 2px 10px #595959;
  margin-top: 13px;
}

.clp_ly_bx .sp_bx_clip {
  background-image: url(../../img/common/sp_bx_clip.png);
  background-repeat: no-repeat;
}

.clp_ly_bx .arr {
  position: absolute;
  width: 23px;
  height: 12px;
  background-position: -200px -100px;
  left: 23px;
  top: -12px;
}

.clp_ly_bx .clp_ly_tit {
  background-position: 0 0;
  width: 452px;
  height: 41px;
}

.clp_ly_bx .btn_close {
  background: url(../../img/common/b.gif);
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
}

.clp_ly_logout {
  margin: 20px auto 0 auto;
  width: 412px;
  height: 518px;
  background: url(../../img/common/sp_bx_clip.png) 0 -200px no-repeat;
}

.clp_ly_logout .btn_login {
  background: url(../../img/common/b.gif);
  float: right;
  width: 90px;
  height: 50px;
}

.clp_ly_btn {
  border-top: 1px solid #d8d8d8;
  padding-top: 10px;
  text-align: center;
  width: 412px;
  margin: 0 auto;
}

.clp_ly_btn a {
  display: inline-block;
  margin: 0 1px 0 0;
}

.clp_ly_btn .btn1 {
  background-position: 0 -50px;
  width: 88px;
  height: 35px;
}

.clp_ly_btn .btn2 {
  background-position: -100px -50px;
  width: 90px;
  height: 35px;
}

.clp_ly_btn .btn3 {
  background-position: -200px -50px;
  width: 90px;
  height: 35px;
}

.clp_ly_cont {
  padding: 20px 20px 0 20px;
}

.clp_ly_cont .clp_ly_tab:after {
  content: "";
  display: block;
  clear: both;
}

.clp_ly_cont .clp_ly_tab li {
  float: left;
  width: 50%;
}

.clp_ly_cont .clp_ly_tab li a {
  display: block;
  text-align: center;
  height: 40px;
  line-height: 40px;
  border-bottom: 2px solid #747474;
  background-color: #f8f8f8;
  border-top: 1px solid #bfbfbf;
  color: #747474;
  text-decoration: none;
}

.clp_ly_cont .clp_ly_tab li.active a {
  background-color: #fff;
  border: 2px solid #777;
  color: #777;
  font-weight: bold;
  border-bottom: none;
  height: 41px;
}

.clp_ly_cont .clp_ly_scr {
  height: 455px;
  width: 412px;
  overflow-x: hidden;
  overflow-y: auto;
  margin-top: 20px;
  padding-right: 20px;
}

.clp_ly_cont .clp_ly_scr2 {
  height: 371px;
  width: 412px;
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 20px;
}

.clp_ly_cont .clp_ly_scr3 {
  height: 433px;
  width: 412px;
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 20px;
}

.clp_ly_cont .clp_ly_co {
  width: 412px;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
}

.clp_ly_cont .clp_ly_link {
  display: inline-block;
  text-decoration: none;
  color: #747474;
  line-height: 19px;
  border: 1px solid #d8d8d8;
  padding: 1px 14px 0 10px;
  height: 18px;
  overflow: hidden;
  background: url(../../img/common/bu_arr.png) 100% 7px no-repeat;
}

.clp_ly_cont .clp_ly_lst dt {
  border-top: 1px dashed #d8d8d8;
  border-bottom: 1px dashed #d8d8d8;
  line-height: 21px;
  padding: 4px 0 5px 9px;
}

.clp_ly_cont .clp_ly_lst dt:after {
  content: "";
  display: block;
  clear: both;
}

.clp_ly_cont .clp_ly_lst dt strong {
  float: left;
  color: #404040;
  padding-top: 2px;
  line-height: 19px;
}

.clp_ly_cont .clp_ly_lst dt strong em {
  color: #00c8e6;
}

.clp_ly_cont .clp_ly_lst dt .clp_ly_link {
  float: right;
}

.clp_ly_cont .clp_ly_lst dd {
  padding: 11px 0 19px 0;
}

.clp_ly_cont .clp_ly_lst li {
  vertical-align: top;
  line-height: 20px;
  padding-left: 15px;
  background: url(../../img/common/bu_dot.png) 10px 8px no-repeat;
}

.clp_ly_cont .clp_ly_lst li span.txt_clp,
.clp_ly_cont .clp_ly_lst li a {
  vertical-align: middle;
  color: #747474;
  text-decoration: none;
}

.clp_ly_cont .clp_ly_lst li a:hover {
  text-decoration: underline;
}

.clp_ly_cont .clp_ly_lst li a.link_my_clip {
  color: #f8566b;
}

.clp_ly_cont .clp_ly_lst li a.clp_ly_link {
  margin-left: 8px;
}

.clp_ly_cont .clp_ly_lst li a.clp_ly_link:hover {
  text-decoration: none;
}

.clp_ly_cont .clp_ly_lst li span.gt {
  vertical-align: middle;
  color: #747474;
  margin: 0 3px 0 4px;
}

.clp_ly_cont .clp_ly_msg {
  padding-top: 28px;
  height: 76px;
  border-bottom: 1px dashed #d8d8d8;
}

.clp_ly_cont .clp_ly_msg p {
  background-image: url(../../img/common/sp_bx_clip.png);
  background-repeat: no-repeat;
  height: 50px;
  width: 412px;
}

.clp_ly_cont .clp_ly_msg p.tx_no_brand {
  background-position: 0 -750px;
}

.clp_ly_cont .clp_ly_msg p.tx_no_goods {
  background-position: 0 -850px;
}

.clp_ly_cont .clp_ly_lst2 li {
  border-bottom: 1px dashed #d8d8d8;
  padding: 14px 5px 14px 5px;
}

.clp_ly_cont .clp_ly_lst2 li:after {
  content: "";
  display: block;
  clear: both;
}

.clp_ly_cont .clp_ly_lst2 li .tit {
  float: left;
  font-size: 14px;
  color: #747474;
  line-height: 32px;
  width: 255px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  letter-spacing: -1px;
}

.clp_ly_cont .clp_ly_lst2 li .clp_btn_sec {
  float: right;
  width: 147px;
}

.clp_ly_cont .clip_wrap {
  float: left;
  width: 27px;
  height: 26px;
  overflow: hidden;
  margin-top: 3px;
}

.clp_ly_cont .clip_wrap .clip_btn {
  background: url(../../img/common/sp_bx_clip.png) 0 -100px no-repeat;
  width: 27px;
  height: 26px;
  float: left;
}

.clp_ly_cont .clip_wrap.clip_red .clip_btn {
  background-position: -40px -100px;
}

.clp_ly_cont .clip_wrap.clip_yellow .clip_btn {
  background-position: -80px -100px;
}

.clp_ly_cont .clp_ly_lst2 .brand_go {
  background: url(../../img/common/sp_bx_clip.png) -300px -50px no-repeat;
  width: 110px;
  height: 32px;
  float: left;
  margin-left: 10px;
}

.clp_ly_cont .clp_tit_gs {
  font-weight: bold;
  color: #404040;
  height: 24px;
  padding-top: 18px;
  border-bottom: 1px dashed #d8d8d8;
}

.clp_ly_cont .clp_tit_gs em {
  color: #00c8e6;
}

.clp_ly_cont .clp_ly_lst3 {
  width: 414px;
  margin-left: -1px;
  padding-top: 10px;
}

.clp_ly_cont .clp_ly_lst3:after {
  content: "";
  display: block;
  clear: both;
}

.clp_ly_cont .clp_ly_lst3 li {
  float: left;
  width: 168px;
  padding: 10px 19px;
  border-left: 1px dotted #bcbcbc;
  border-bottom: 1px dotted #bcbcbc;
}

.clp_ly_cont .clp_ly_lst3 li .itembx .thumbnail a {
  width: 168px;
  height: 168px;
}

.clp_ly_cont .clp_ly_lst3 li .itembx .thumbnail .ico_left {
  top: 2px;
}

.clp_ly_cont .clp_ly_lst3 li .itembx .thumbnail .thmb_over_bg {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000;
  opacity: 0.5;
  filter: alpha(opacity=50);
}

.clp_ly_cont .clp_ly_lst3 li .itembx .thumbnail .thmb_over {
  display: none;
  position: absolute;
  top: 52px;
  left: 9px;
  width: 150px;
  text-align: center;
  color: #fff;
  line-height: 17px;
}

.clp_ly_cont .clp_ly_lst3 li .itembx .thumbnail .thmb_over .clip_tx_tit {
  display: block;
  margin-bottom: 11px;
  max-height: 34px;
  overflow: hidden;
}

.clp_ly_cont .clp_ly_lst3 li .itembx .thumbnail .thmb_over del {
  display: block;
}

.clp_ly_cont .clp_ly_lst3 li .itembx .thumbnail .thmb_over .price_tt {
  font-weight: bold;
}

.clp_ly_cont .clp_ly_lst3 li .itembx .thumbnail a:hover .thmb_over {
  display: block;
}

.clp_ly_cont .clp_ly_lst3 li .itembx .thumbnail a:hover .thmb_over_bg {
  display: block;
}

.clp_ly_cont .clp_ly_more {
  background: #fff;
  margin-top: -1px;
  padding: 10px 0;
  text-align: center;
  position: relative;
}

.clp_ly_cont .btn_gs_more {
  display: inline-block;
  vertical-align: top;
  width: 172px;
  height: 42px;
  background-position: 0 -150px;
}

.clp_ly_cont .clp_gs_btm {
  padding: 10px 0 0 0;
}

.clp_ly_cont .clp_gs_btm:after {
  content: "";
  display: block;
  clear: both;
}

.clp_ly_cont .clp_gs_btm .clip_wrap {
  float: right;
  margin: 0;
}

/* 클립개선 */
.sp_ccp {
  display: inline-block;
  overflow: hidden;
  background-image: url(../../img/common/sp_ccp.png) !important;
  background-repeat: no-repeat;
  vertical-align: top;
  line-height: 999px;
}

.cm_clip_dtl a.sel_clip {
  width: 85px;
  height: 55px;
  background-position: 0 0;
}

.cm_clip_dtl.on a.sel_clip {
  background-position: -89px 0;
}

.cm_clip_item a.sel_clip {
  width: 32px;
  height: 32px;
  margin-right: -1px;
  background-position: -86px -60px;
}

.cm_clip_item.on a.sel_clip {
  background-position: -124px -60px;
}

.cm_clip_layer {
  position: absolute;
  top: 0 !important;
  left: 0 !important;
  z-index: 999999;
}

.cm_clip_layer .dimmed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 600;
  opacity: 0.5;
  filter: alpha(opacity=50);
  cursor: pointer;
}

.cm_clip_layercancel .cm_layer_area .lp_close {
  top: 14px;
  right: 14px;
}

.cm_clip_layer .cm_layer_area {
  position: relative;
  z-index: 700;
  padding: 33px 0 27px;
  border: 1px solid #222;
  background: #fff;
  text-align: center;
}

.cm_clip_layer .cm_layer_area .cm_lp_tit {
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
  font-size: 15px;
  color: #222;
  line-height: 20px;
}

.cm_clip_layer .cm_layer_area .cm_lp_tit .point_txt {
  color: #fb016c;
}

.cm_clip_layer .cm_layer_area .btn_area {
  margin-top: 12px;
}

.cm_clip_layer .cm_layer_area .btn_area a {
  width: 76px;
  height: 32px;
}

.cm_clip_layer .cm_layer_area .btn_area a.cm_btn {
  margin-right: 3px;
  background-position: -174px -90px;
}

.cm_clip_layer .cm_layer_area .btn_area a.cm_btn2 {
  background-position: -254px -90px;
}

.cm_clip_layer .lp_close {
  position: absolute;
  overflow: hidden;
  right: 20px;
  top: 16px;
  width: 19px;
  height: 19px;
  background-position: -203px 0;
  vertical-align: baseline;
}

.cm_clip_layer .ico_clip {
  margin-right: 5px;
  background-position: -174px -126px;
  width: 23px;
  height: 20px;
}

.cm_clip_layer .cm_tit {
  margin-left: -2px;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
  font-size: 19px;
  color: #222;
  text-align: left;
  line-height: 21px;
}

.cm_clip_layer .cm_clip_bx {
  padding: 12px 0 11px;
  margin-top: 12px;
  min-height: 85px;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  -webkit-border-radius: 5px;
}

.cm_clip_info_lst {
  zoom: 1;
}

.cm_clip_info_lst li {
  float: left;
  width: 134px;
  border-left: 1px solid #e5e5e5;
}

.cm_clip_info_lst li:first-child {
  border-left: 0;
}

.cm_clip_info_lst li .cm_info {
  width: 57px;
  height: 54px;
  background-position: -334px -80px;
}

.cm_clip_info_lst li .cm_info2 {
  width: 56px;
  height: 54px;
  background-position: -395px -82px;
}

.cm_clip_info_lst li .cm_info_txt {
  display: block;
  margin-top: 5px;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #7a7a7a;
  line-height: 18px;
}

.cm_clip_info_lst li .cm_info_txt em {
  color: #343434;
}

.cm_clip_info_lst:after {
  content: "";
  display: block;
  clear: both;
}

.cm_clip_layer .cm_clip_bx .cm_clip_info {
  display: inline-block;
  font-size: 14px;
  color: #444;
  text-align: left;
  line-height: 16px;
  vertical-align: top;
}

.cm_clip_layer .cm_clip_bx2 {
  padding: 17px 0 12px 0;
  text-align: center;
}

.cm_clip_layer .cm_clip_bx2 .btn_myclip {
  width: 86px;
  height: 23px;
  background-position: 0 -581px;
}

.cm_clip_layer .cm_clip_bx2 .btn_recomclip {
  width: 86px;
  height: 23px;
  background-position: 0 -608px;
}

.cm_clip_layercheck .cm_layer_area {
  position: fixed;
  width: 271px;
  top: 50%;
  left: 50%;
  margin: -115px 0 0 -136px;
  padding: 16px 23px 8px;
  z-index: 700;
}

.cm_clip_layercancel .cm_layer_area {
  position: fixed;
  width: 248px;
  top: 50%;
  left: 50%;
  margin: -73px 0 0 -124px;
  z-index: 700;
}

.body_sfc .cm_clip {
  display: none !important;
}

.body_sfc .goods_item .item .pop_area li.wish_list {
  display: none;
}

.body_sfc .goods_item .item .pop_area {
  width: 105px;
  margin-left: -52px;
  border-right: 1px solid #d9d9d9;
}

.body_sfc .csm_lst_h .pop_area {
  overflow: hidden;
  height: 105px;
  border-bottom: 1px solid #d9d9d9;
}

.body_sfc .item_tbl .item .pop_area {
  overflow: hidden;
  height: 105px;
  border-bottom: 1px solid #d9d9d9;
}

.body_sfc .goods_img_type .btn_detail {
  width: 105px;
  left: 50%;
  margin-left: -52px;
  border-right: 1px solid #d9d9d9;
}

.body_sfc .goods_list_type .btn_detail {
  overflow: hidden;
  height: 105px;
  border-bottom: 1px solid #d9d9d9;
}

/* 1280px container */
.body_wide .left_wing_bx {
  top: 260px;
  margin-left: -739px;
}

.body_wide.body_ssg .left_wing_bx {
  top: 200px;
}

.body_wide .leftbnr_wing {
  top: 260px;
  margin-left: -739px;
}

.body_wide .sky_banner_left {
  top: 154px;
  left: 50%;
  margin-left: -739px;
}

.body_wide .main .sky_banner_left {
  top: 154px;
}

.body_wide .main .sky_banner_left.top_banner_visible {
  top: 154px;
}

/* 좌측 날개배너 */
.left_wing_bx {
  position: absolute;
  left: 50%;
  top: 190px;
  margin-left: -609px;
}

.left_wing_bx .promotion_bnr_area {
  position: relative;
  margin-top: 6px;
}

.left_wing_bx .promotion_bnr_area .bn_blist {
  overflow: hidden;
}

.left_wing_bx .promotion_bnr_area .bn_blist li:first-child {
  display: block;
}

.left_wing_bx .promotion_bnr_area .bn_blist li {
  display: none;
}

.left_wing_bx .promotion_bnr_area .bx-wrapper .bn_blist li {
  display: block;
}

.left_wing_bx .bn_cf_left {
  position: relative;
  margin-top: 10px;
}

.left_wing_bx .bn_blist_benefit,
.left_wing_bx .bn_cf_left {
  position: relative;
  top: 0;
  left: 0;
}

.cmsky_card {
  position: absolute;
  top: 260px;
  left: 50%;
  z-index: 400;
  width: 87px;
  margin-left: -739px;
}

.body_ssg .cmsky_card {
  top: 200px;
}

.cmsky_card .cmsky_card_btnview {
  display: none;
}

.cmsky_card .cmsky_card_bnlst {
  margin-top: 6px;
}
.cmsky_card .cmsky_card_bnlst a {
  display: block;
  width: 77px;
  height: 109px;
  margin-bottom: 6px;
}
.cmsky_card .cmsky_card_bnlst a img {
  width: 100%;
  height: 100%;
  vertical-align: top;
}

.body_wide .cmsky_card .left_wing_bx {
  position: relative;
  top: 0;
  left: 0;
  margin-left: 0;
}

.body_wide.body_ssg .cmsky_card .left_wing_bx {
  top: 0;
}

.body_wide .cmsky_card .leftbnr_wing {
  position: relative;
  top: 0;
  left: 0;
  margin-left: 0;
}

.body_wide .cmsky_card .sky_banner_left {
  position: relative;
  top: 0;
  left: 0;
  margin-left: 0;
}

.body_wide .main .cmsky_card .sky_banner_left {
  top: 0;
}

.body_wide .main .cmsky_card .sky_banner_left.top_banner_visible {
  top: 0;
}

@media only screen and (max-width: 1500px) {
  .cmsky_card {
    left: -87px;
    margin-left: 0;
  }
  .cmsky_card.on {
    left: 0;
  }
  .cmsky_card .cmsky_card_sec {
    position: relative;
    width: 84px;
    padding: 4px 0 4px 2px;
    border: 1px solid #222;
    border-left: 0;
    border-radius: 0 0 9px 0;
    background: #fff;
  }
  .cmsky_card .cmsky_card_btnview {
    display: block;
    position: absolute;
    top: -1px;
    left: 100%;
    width: 50px;
    border: 1px solid #222;
    border-left: 0;
    border-radius: 0 9px 9px 0;
    background: #fff;
    text-align: left;
  }
  .cmsky_card .cmsky_card_btnview:after {
    display: inline-block;
    overflow: hidden;
    position: absolute;
    right: 13px;
    bottom: 11px;
    width: 17px;
    height: 17px;
    background: url(../../img/common_layout/sp_cmsky_card_btnview.png) no-repeat;
    vertical-align: top;
    content: "";
  }
  .cmsky_card.on .cmsky_card_btnview:after {
    right: 10px;
    background-position: 0 -50px;
  }
  .cmsky_card .cmsky_card_tx {
    display: inline-block;
    padding: 13px 0 40px 11px;
    font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #666;
    vertical-align: top;
  }
  .cmsky_card.on .cmsky_card_tx {
    padding-left: 14px;
  }
  .cmsky_card .cmsky_card_cont {
    visibility: hidden;
  }
  .cmsky_card.on .cmsky_card_cont {
    visibility: visible;
  }
  .cmsky_card.off {
    visibility: hidden;
    z-index: -1;
  }
  .body_wide #wrap .sky_scraper.off {
    visibility: hidden;
    z-index: -1;
  }
  :lang(en) .cmsky_card .cmsky_card_tx,
:lang(zh) .cmsky_card .cmsky_card_tx {
    padding-left: 8px;
  }
  :lang(en) .cmsky_card.on .cmsky_card_tx,
:lang(zh) .cmsky_card.on .cmsky_card_tx {
    padding-left: 8px;
  }
}
/* 고해상도 대응 */
@media screen and (-webkit-min-device-pixel-ratio: 2) {
  .cmsky_card .cmsky_card_btnview:after {
    background-image: url(../../img/common_layout/sp_cmsky_card_btnview@2x.png);
    background-size: 17px auto;
  }
}
/* 우측 날개배너 */
#skyScraperWrap {
  z-index: 11;
}

.sky_scraper {
  position: absolute;
  top: 290px;
  right: auto;
  left: 50%;
  z-index: 500;
  width: 101px;
  margin-left: 544px;
  background: url(../../img/common_layout/bg_common_sky.png) no-repeat -240px -100px;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
  line-height: 1.5em;
}

.body_wide .sky_scraper {
  position: absolute;
  top: 288px;
  right: auto;
  left: 50%;
  z-index: 500;
  width: 101px;
  margin-left: 674px;
  background: url(../../img/common_layout/bg_common_sky.png) no-repeat -240px -100px;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
  line-height: 1.5em;
}

#wrap .sky_scraper {
  top: 190px;
}

.body_wide #wrap .sky_scraper {
  top: 288px;
}

.body_wide.body_ssg #wrap .sky_scraper {
  top: 228px;
}

:lang(en) .body_wide #wrap .sky_scraper,
:lang(zh) .body_wide #wrap .sky_scraper {
  margin-top: 40px;
}

:lang(en) .body_wide #wrap .sky_scraper.active,
:lang(zh) .body_wide #wrap .sky_scraper.active {
  margin-top: 10px;
}

.sky_scraper .btn_sky_mini {
  position: absolute;
  top: -28px;
  right: 0;
  width: 25px;
  height: 25px;
  background: url(../../img/common_layout/bg_common_sky.png) no-repeat 0 -575px;
}

.sky_scraper.active {
  width: 395px;
  height: 596px;
  margin-top: -28px;
  margin-left: 244px;
  padding: 0 2px 2px 5px;
  background-position: -350px 0;
  background-color: transparent;
}

.body_wide .sky_scraper.active {
  width: 395px;
  height: 596px;
  margin-top: -28px;
  margin-left: 374px;
  padding: 0 2px 2px 5px;
  background-position: -350px 0;
  background-color: transparent;
}

.sky_scraper.active .btn_sky_mini {
  top: -26px;
  right: -4px;
}

.sky_scraper img {
  vertical-align: middle;
}

.sky_scraper.active .sky_direction,
.sky_scraper.active .sky_top,
.sky_scraper.active .sky_clip,
.sky_scraper.active .sky_delivery,
.sky_scraper .sky_haeder select,
.sky_scraper .history_list li.type_product .txt {
  display: none;
}

.sky_scraper.active .sky_haeder select,
.sky_scraper.active .history_list li.commodity .txt,
.sky_scraper.active .history_list li.brand .txt {
  display: block;
}

.sky_scraper .sky_haeder {
  min-width: 101px;
  height: 18px;
  padding: 12px 0 2px;
  text-align: left;
  *zoom: 1;
}

.sky_scraper .sky_haeder h3 {
  padding: 3px 8px 0 8px;
  font-weight: normal;
}

.sky_scraper .sky_haeder h3:after {
  display: block;
  clear: both;
  content: "";
}

.sky_scraper .sky_haeder h3 .sky_tit {
  float: left;
  font-size: 15px;
  font-weight: normal;
  letter-spacing: 0.5px;
  color: #222;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
}

.sky_scraper .sky_haeder h3 strong {
  float: right;
  margin-top: -3px;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 19px;
  font-weight: 400;
  line-height: 23px;
  color: #e74848;
  vertical-align: top;
}

.sky_scraper .sky_content {
  position: relative;
  height: 186px;
  margin-top: 23px;
  background: url(../../img/common_layout/bg_history.gif) repeat-y 12px 0;
}

.sky_scraper .history_list {
  overflow: hidden;
  position: relative;
  z-index: 1000;
  height: 186px;
  margin: 0 0 0 3px;
}

.sky_scraper .history_list li {
  overflow: hidden;
  margin-top: 15px;
  width: 97px;
}

.sky_scraper .history_list li .icon_mall.cm_mall_ic,
.sky_scraper.active .history_list li .icon_mall.cm_mall_ic {
  float: left;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  background: none;
  font-size: 0;
  line-height: 0;
}

.sky_scraper .history_cont {
  float: left;
  width: 72px;
  position: relative;
}

.sky_scraper .history_cont .btn_history_del {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
  background: url(../../img/common_layout/btn_history_del.png) 0 0 no-repeat;
}

.sky_scraper .hishover .history_cont .btn_history_del {
  display: block;
}

.sky_scraper .sky_btn_del {
  display: none;
  text-align: right;
  border-top: 1px solid #7d7d7d;
  padding: 9px 15px 0 0;
}

.sky_scraper .sky_btn_del .btn_history_alldel {
  width: 72px;
  height: 25px;
  background: url(../../img/common_layout/btn_history_alldel.png) 0 0 no-repeat;
}

:lang(en) .sky_scraper .sky_btn_del .btn_history_alldel {
  background-image: url(../../img/common_layout/btn_history_alldel_en.png);
}

:lang(zh) .sky_scraper .sky_btn_del .btn_history_alldel {
  background-image: url(../../img/common_layout/btn_history_alldel_zh.png);
}

.history_cont a {
  color: #666;
  text-decoration: none;
}

.history_cont .txt {
  line-height: 18px;
  max-height: 36px;
  overflow: hidden;
}

.history_cont .txt a {
  color: #222;
}

.history_cont .txt .blind_txt {
  display: none;
}

.history_cont .txt .blind_txt a {
  color: #848484;
  text-decoration: none;
}

.history_cont a:hover {
  text-decoration: underline;
}

.history_cont .sky_thumbnail {
  position: relative;
  float: left;
  margin-right: 9px;
}

.history_cont .sky_thumbnail a,
.active .history_cont .sky_thumbnail a {
  display: block;
  position: relative;
  width: 48px;
  height: 48px;
  background: url(../../img/common_layout/bg_common_sky.png) no-repeat;
  border: 1px solid #ececec;
  cursor: pointer;
}

.history_cont .sky_thumbnail a img {
  width: 48px;
  height: 48px;
}

.history_cont .sky_thumbnail.medium a img {
  width: 68px;
  height: 48px;
}

.history_cont .sky_thumbnail.adult_only a {
  background-position: -100px -100px;
}

.history_cont .sky_thumbnail.sold_out a {
  background-position: 0 -100px;
}

.history_cont .sky_thumbnail.medium a {
  width: 68px;
  height: 48px;
  border: 1px solid #ececec;
}

.history_cont .sky_thumbnail a .img_hover,
.history_cont .sky_thumbnail a .img_hover02 {
  display: block;
  position: absolute;
  left: -1px;
  top: -1px;
  right: -1px;
  bottom: -1px;
}

.history_cont .sky_thumbnail a:hover .img_hover {
  background: url(../../img/common_layout/bg_common_sky.png) no-repeat 0 -400px;
}

.history_cont .sky_thumbnail a:hover .img_hover02 {
  background: url(../../img/common_layout/bg_common_sky.png) no-repeat 0 -500px;
}

.history_cont .sky_thumbnail .soldout {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: url(../../img/common/bg_itemlst_soldout.png);
  color: #222;
  line-height: normal;
  text-align: center;
}

.history_cont .sky_thumbnail .soldout .bx {
  display: inline-block;
  position: relative;
  width: 100%;
  vertical-align: middle;
}

.history_cont .sky_thumbnail .soldout .tt {
  display: block;
  width: 100%;
  height: 16px;
  background: url(../../img/common/bg_soldout_txt_50.png) no-repeat 50% 50%;
  font-size: 0;
  line-height: 0;
  overflow: hidden;
}

.history_cont .sky_thumbnail .soldout .valign {
  display: none;
}

.history_cont .event_end {
  color: #e74848;
  font-size: 12px;
}

.sky_scraper .history_list li.history_txt {
  background: #fff;
  margin: 0;
}

.sky_scraper .history_list li.history_txt p.history_txt {
  height: 113px;
  width: 100px;
  margin: 28px 0 0 0;
  padding-top: 45px;
  text-align: center;
  font-size: 11px;
  line-height: 14px;
  color: #747474;
}

.sky_scraper .history_list li.history_txt p.history_txt span {
  color: #00aac4;
}

.sky_scraper .sky_direction {
  position: relative;
  position: absolute;
  top: 0;
  height: 261px;
}

.sky_scraper .sky_direction button {
  position: absolute;
  width: 101px;
  height: 20px;
  margin-top: 0;
  background: url(../../img/common_layout/bg_common_sky.png) no-repeat;
  cursor: pointer;
  z-index: 9999;
}

.sky_scraper .sky_direction .prev {
  left: 0;
  top: 35px;
  background-position: -100px -200px;
}

.sky_scraper .sky_direction .next {
  left: 0;
  bottom: 0;
  background-position: -100px -225px;
}

.sky_scraper .sky_clip {
  border: 1px solid #ff625e;
  position: absolute;
  bottom: -86px;
  width: 99px;
  height: 58px;
}

.sky_scraper .sky_clip a {
  background-image: url(../../img/common_layout/bg_common_sky.png);
  display: block;
}

.sky_scraper .sky_clip a.sky_myclip {
  width: 99px;
  height: 25px;
  background-position: -1px -621px;
  position: absolute;
  top: 0;
  left: 0;
}

.sky_scraper .sky_clip a.sky_myclip:hover {
  background-position: -131px -621px;
}

.sky_scraper .sky_clip a.sky_clip_red {
  width: 50px;
  height: 33px;
  background-position: -1px -646px;
  position: absolute;
  top: 25px;
  left: 0;
}

.sky_scraper .sky_clip a.sky_clip_red:hover {
  background-position: -131px -646px;
}

.sky_scraper .sky_clip a.sky_clip_yellow {
  width: 50px;
  height: 33px;
  background-position: -51px -646px;
  position: absolute;
  top: 25px;
  left: 50px;
}

.sky_scraper .sky_clip a.sky_clip_yellow:hover {
  background-position: -181px -646px;
}

.sky_scraper .sky_delivery a {
  display: none;
  position: absolute;
  bottom: -114px;
  width: 101px;
  height: 24px;
  background-image: url(../../img/common_layout/bg_common_sky.png);
  background-position: 0 -684px;
  background-repeat: no-repeat;
}

.sky_scraper .sky_delivery a:hover {
  background-position: -130px -684px;
}

.sky_scraper .sky_top a {
  position: absolute;
  bottom: -118px;
  width: 101px;
  height: 28px;
  background: url(../../img/common_layout/bg_common_sky.png) no-repeat 0 -712px;
}

.sky_scraper .sky_btn_area {
  position: absolute;
  left: -21px;
  top: 13px;
  font-size: 0;
  line-height: 0;
  z-index: 100000;
}

.sky_scraper .sky_btn_area .btn {
  width: 22px;
  height: 22px;
  background: url(../../img/common_layout/bg_common_sky.png) -150px 0;
  font-size: 0;
  line-height: 0;
  cursor: pointer;
}

.sky_scraper .sky_btm {
  padding-top: 20px;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
}

.sky_scraper .sky_btm .sky_bx {
  padding: 1px 0;
  background-color: #f7f7f7;
  text-align: center;
}

.sky_scraper .sky_btm .ico_reserve.bojung2 {
  display: block;
  margin: 0 auto 8px;
  background-image: url(../../img/common/sp_label.png);
  background-repeat: no-repeat;
}

.sky_scraper .sky_btm .ico_reserve.bojung2 {
  width: 62px;
  height: 47px;
  background: url(../../img/common/sp_delivery.png) 0 -100px no-repeat;
}

.sky_scraper .sky_btm .sky_tx {
  margin: 15px 0;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -1px;
  color: #232323;
}

.sky_scraper .sky_btm .sky_tx a {
  font-weight: bold;
  color: #232323;
  text-decoration: underline;
}

.sky_scraper .sky_btm .sky_tx a:hover {
  text-decoration: underline;
}

.sky_scraper .sky_btm .sky_tx strong {
  display: block;
  color: #232323;
}

.sky_scraper .sky_btm .sky_deliv_btnarea {
  margin: 13px 0;
  text-align: center;
}

.sky_scraper .sky_btm .sky_deliv_btn {
  display: inline-block;
  overflow: hidden;
  width: 87px;
  height: 20px;
  margin: 3px 0 0 0;
  border: solid 1px #dbdbdb;
  border-radius: 3px;
  background-color: #fff;
  font-size: 11px;
  line-height: 22px;
  letter-spacing: -1px;
  color: #777;
  text-decoration: none;
  vertical-align: top;
}

.sky_scraper .sky_btm .sky_deliv_btn:first-child {
  margin-top: 0;
}

.sky_scraper .sky_btm .sky_deliv_btnarea.ty_image {
  position: relative;
  margin: 13px 0 0;
  padding-bottom: 13px;
  padding-top: 62%;
}

.sky_scraper .sky_btm .sky_deliv_btnarea.ty_image .sky_deliv_img {
  position: absolute;
  bottom: -1px;
  right: 0;
}

.sky_scraper .sky_btm .sky_deliv_btnarea.ty_image .sky_deliv_img img {
  vertical-align: top;
}

.sky_scraper .sky_btm .sky_deliv_btnarea.ty_image .sky_deliv_btn {
  position: relative;
  color: #232323;
  font-weight: bold;
}

.sky_scraper .sky_btm .sky_deliv_tit {
  margin: 7px 8px -3px;
  background: url(../../img/common_layout/bg_sky_delivtit.png) repeat-x 0 50%;
  font-size: 12px;
  font-weight: bold;
  color: #232323;
}

.sky_scraper .sky_btm .sky_deliv_tit span {
  display: inline-block;
  padding: 0 5px;
  background: #f7f7f7;
}

.sky_scraper .sky_btm .sky_deliv_lst {
  margin: 0 7px 13px;
}

.sky_scraper .sky_btm .sky_deliv_item {
  position: relative;
  margin: 10px 0 0;
  font-size: 11px;
  line-height: 15px;
  letter-spacing: -1px;
  color: #232323;
}

.sky_scraper .sky_btm .sky_deliv_item:first-child {
  margin-top: 13px;
}

.sky_scraper .sky_btm .sky_deliv_item .icon_mall {
  display: inline-block;
  float: none;
  width: 17px;
  height: 17px;
  margin: -3px 1px 0 -1px;
  background: url(../../img/common_layout/bg_common_sky.png) no-repeat 0 -190px;
  font-size: 0;
  line-height: 0;
  vertical-align: top;
}

.sky_scraper .sky_btm .sky_deliv_item .icon_mall.ssgmall {
  background-position: 0 -219px;
}

.sky_scraper .sky_btm .sky_deliv_item .icon_mall.depart {
  background-position: 0 -248px;
}

.sky_scraper .sky_btm .sky_deliv_item .icon_mall.emart {
  background-position: 0 -277px;
}

.sky_scraper .sky_btm .sky_deliv_item .icon_mall.traders {
  background-position: 0 -306px;
}

.sky_scraper .sky_btm .sky_deliv_item .icon_mall.boons {
  background-position: 0 -335px;
}

.sky_scraper .sky_btm .sky_deliv_item .icon_mall.howdy {
  background-position: 0 -364px;
}

.sky_scraper .sky_btm .sky_deliv_item .icon_mall.boots {
  background-position: -38px -161px;
}

.sky_scraper .sky_btm .sky_deliv_item .icon_mall.tv {
  background-position: 0 -161px;
}

.sky_scraper .sky_btm .sky_deliv_item .icon_mall.si {
  background-position: -38px -364px;
}

.sky_scraper .sky_btm .sky_deliv_item .sky_deliv_place {
  display: inline-block;
}

.sky_scraper .sky_btm .sky_deliv_itemlft {
  padding: 0 0 0 20px;
  text-align: left;
}

.sky_scraper .sky_btm .sky_deliv_itemlft .icon_mall {
  position: absolute;
  top: 0;
  left: 0;
}

.sky_scraper .sky_btm .sky_deliv_msg a {
  color: #232323;
  text-decoration: none;
}

.sky_scraper .sky_btm .sky_deliv_msg .ico_arr {
  display: inline-block;
  width: 5px;
  height: 7px;
  margin: 2px 0 0 3px;
  background: url(../../img/common_layout/bg_common_sky.png) -100px -350px no-repeat;
  vertical-align: top;
}

.sky_scraper .sky_btm .sky_btn_myclip {
  display: block;
  width: 101px;
  height: 26px;
  background: url(../../img/common_layout/bg_common_sky.png) -130px -750px no-repeat;
}

.sky_scraper .sky_btm .sky_btn_like {
  display: block;
  width: 99px;
  border: 1px solid #222;
}
.sky_scraper .sky_btm .sky_btn_like .cmlike_ico {
  margin-right: 2px;
}
.sky_scraper .sky_btm .sky_btn_like .cmlike_btn {
  color: #222;
  background-color: #fff;
  height: 24px;
  line-height: 23px;
}
.sky_scraper .sky_btm .sky_btn_like .cmlike_btn:after {
  content: "";
  display: inline-block;
  width: 6px;
  height: 6px;
  border-bottom: 1px solid #222;
  border-right: 1px solid #222;
  margin-top: -1px;
  transform: perspective(1px) rotate(-45deg);
}

.sky_scraper .sky_btm .sky_btn_top {
  display: block;
  width: 101px;
  height: 26px;
  margin-top: 4px;
  background: url(../../img/common_layout/bg_common_sky.png) -130px -712px no-repeat;
}

:lang(en) .sky_scraper .sky_btm .sky_btn_myclip {
  background-image: url(../../img/common_layout/bg_common_sky_en.png);
}

:lang(zh) .sky_scraper .sky_btm .sky_btn_myclip {
  background-image: url(../../img/common_layout/bg_common_sky_zh.png);
}

/* sky_scraper_v2 */
.sky_scraper.sky_scraper_v2.sky_mini .sky_btm .sky_bx {
  display: block;
  width: 100px;
  position: absolute;
  top: -1000px;
  left: -2000px;
  visibility: hidden;
}

.sky_scraper.sky_scraper_v2 .sky_content {
  position: relative;
  height: 166px;
  margin-top: 23px;
  background: url(../../img/common_layout/bg_history.gif) repeat-y 12px 0;
}

.sky_scraper.sky_scraper_v2 .sky_direction {
  position: relative;
  position: absolute;
  top: 0;
  height: 241px;
}

.sky_scraper.sky_scraper_v2 .sky_btm .deiv_bdg {
  position: relative;
  top: auto;
  right: auto;
  width: 100%;
}

.sky_scraper.sky_scraper_v2 .sky_btm .deiv_bdg > span {
  display: block;
  width: 45px;
  height: 45px;
  margin: 0 auto 4px;
}

.sky_scraper.sky_scraper_v2 .sky_btm .ico_reserve.bojung2 {
  display: block;
  margin: 0 auto 4px;
  width: 62px;
  height: 47px;
  background: url(../../img/common/sp_delivery.png) 0 -100px no-repeat;
}

.sky_scraper.sky_scraper_v2 .sky_btm .sky_deliv_lst {
  margin: 0;
  overflow: hidden;
  max-height: 98px;
}

.sky_scraper.sky_scraper_v2 .sky_btm .sky_deliv_item {
  width: 101px;
  margin: 0;
  padding-top: 10px;
}

.sky_scraper.sky_scraper_v2 .sky_btm .sky_deliv_msg {
  line-height: 12px;
  letter-spacing: -1.5px;
}

.sky_scraper.sky_scraper_v2 .sky_btm .sky_deliv_msg a {
  color: #232323;
  text-decoration: none;
}

.sky_scraper.sky_scraper_v2 .sky_btm .sky_deliv_btn {
  display: inline-block;
  overflow: hidden;
  width: 87px;
  height: 20px;
  margin: 0;
  border: solid 1px #dbdbdb;
  border-radius: 0;
  background-color: #fff;
  font-size: 11px;
  line-height: 22px;
  letter-spacing: -1px;
  color: #222;
  text-decoration: none;
  vertical-align: top;
}

.sky_scraper.sky_scraper_v2 .sky_btm .sky_deliv_btn:first-child {
  margin-top: 0;
  margin-bottom: -1px;
}

.sky_scraper .bx-wrapper {
  height: 122px;
}

.sky_scraper .bx-controls {
  position: relative;
  width: 47px;
  height: 16px;
  margin: 5px auto 0;
}

.sky_scraper .bx-controls a {
  position: absolute;
  background-image: url(../../img/common_layout/sp_deiv_page.png);
  background-repeat: no-repeat;
  height: 16px;
  top: 0;
  overflow: hidden;
  text-indent: -1000px;
}

.sky_scraper .bx-controls a.bx-prev {
  left: 0;
  background-position: 0 -22px;
  width: 16px;
}

.sky_scraper .bx-controls a.bx-prev:hover {
  background-position: 0 0;
}

.sky_scraper .bx-controls a.bx-next {
  right: 0;
  background-position: -32px -22px;
  width: 16px;
}

.sky_scraper .bx-controls a.bx-next:hover {
  background-position: -32px 0;
}

.sky_scraper .bx-controls-auto a {
  display: block;
}

.sky_scraper .bx-controls-auto a.active {
  display: none;
}

.sky_scraper .bx-controls-auto a.bx-start {
  left: 16px;
  width: 17px;
  background-position: -16px 0;
}

.sky_scraper .bx-controls-auto a.bx-stop {
  left: 16px;
  width: 17px;
  background-position: -16px -22px;
}

.sky_scraper.sky_scraper_v2 .sky_btm .sky_deliv_btnarea {
  margin: 8px 0 13px;
  text-align: center;
}

@media screen and (-webkit-min-device-pixel-ratio: 2) {
  .sky_scraper .bx-controls a {
    background-image: url(../../img/common_layout/sp_deiv_page@2x.png);
    background-size: 47px auto;
  }
}
@media screen and (max-width: 1340px) {
  .body_wide {
    overflow: overlay;
  }
  .body_wide::-webkit-scrollbar {
    width: 5px;
    height: 5px; /* 가로스크롤 없애려면 값을 0으로 지정 */
  }
  .body_wide::-webkit-scrollbar-thumb {
    background: rgba(50, 50, 50, 0.3);
    border-radius: 100px;
  }
  .body_wide::-webkit-scrollbar-thumb:hover {
    background: rgba(50, 50, 50, 0.5);
  }
  .body_wide::-webkit-scrollbar-track {
    display: none;
  }
}
/* sky_ssgtalk */
.sky_scraper .sky_ssgtalk {
  margin: 20px 2px 0 1px;
  padding: 0 0 7px;
  border-radius: 5px;
  background: #fff;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
}

.sky_scraper.active .sky_ssgtalk {
  display: none;
}

.sky_scraper.sky_mini .sky_ssgtalk {
  margin: 16px auto 0;
  padding: 3px 0 3px;
}

.sky_scraper .sky_ssgtalk .sky_ssgtalk_tittxt,
.sky_scraper .cmfloating_btnarea .cmfloating_ico,
.sky_scraper .cmfloating_btnarea .cmfloating_msg:before,
.sky_scraper .cmfloating_btnarea .cmfloating_tip:before {
  display: inline-block;
  background: url(../../img/common_layout/sp_cmfloating_ssgtalk_v2.png) no-repeat;
  vertical-align: top;
}

.sky_scraper .sky_ssgtalk .sky_ssgtalk_tit {
  display: block;
  position: relative;
}

.sky_scraper .sky_ssgtalk .sky_ssgtalk_tittxt {
  width: 42px;
  height: 23px;
  margin-left: 15px;
  background-position: 0 0;
}

.sky_scraper .sky_ssgtalk .sky_ssgtalk_tittxt.v2 {
  width: 40px;
  height: 23px;
  margin-left: 15px;
  background-position: 0 -74px;
}

.sky_scraper .sky_ssgtalk .sky_ssgtalk_titico {
  display: inline-block;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 58px;
  width: 32px;
  height: 18px;
  background: url(../../img/common_layout/sky_ssgtalk_titico.gif) no-repeat;
  vertical-align: top;
}

.sky_scraper .sky_ssgtalk .on .sky_ssgtalk_tittxt {
  background-position: -50px 0;
}

.sky_scraper .sky_ssgtalk .on .sky_ssgtalk_tittxt.v2 {
  background-position: -51px -74px;
}

.sky_scraper .sky_ssgtalk .push .sky_ssgtalk_tittxt {
  margin-left: 5px;
}

.sky_scraper .sky_ssgtalk .push .sky_ssgtalk_titico {
  left: 47px;
  width: 46px;
  height: 26px;
  background: url(../../img/common_layout/sky_ssgtalk_titico_on.gif) no-repeat;
}

.sky_scraper.sky_mini .sky_ssgtalk .sky_ssgtalk_tit {
  text-align: center;
}

.sky_scraper.sky_mini .sky_ssgtalk .sky_ssgtalk_tittxt {
  width: 38px;
  height: 21px;
  margin-left: 0;
  background-position: 0 -250px;
}

.sky_scraper.sky_mini .sky_ssgtalk .sky_ssgtalk_tittxt.v2 {
  width: 36px;
  height: 21px;
  margin-left: 0;
  background-position: 0 -103px;
}

.sky_scraper.sky_mini .sky_ssgtalk .on .sky_ssgtalk_tittxt {
  background-position: -50px -250px;
}

.sky_scraper.sky_mini .sky_ssgtalk .on .sky_ssgtalk_tittxt.v2 {
  background-position: -51px -103px;
}

.sky_scraper.sky_mini .sky_ssgtalk .sky_ssgtalk_titico {
  display: none;
}

.sky_scraper .cmfloating_btnarea {
  display: block;
  position: relative;
  height: 38px;
  margin: 3px 0;
}

.sky_scraper .cmfloating_btnarea .cmfloating_btn {
  display: block;
  position: absolute;
  right: 0;
  width: 96px;
  height: 36px;
  border: solid 1px #d1d1d1;
  border-radius: 19px;
  background-color: #fff;
}

.sky_scraper .cmfloating_btnseller {
  height: 32px;
  padding-top: 12px;
}

.sky_scraper .cmfloating_btnseller:after {
  position: absolute;
  top: 0;
  right: 5px;
  left: 5px;
  height: 1px;
  background: #e5e5e5;
  content: "";
}

.sky_scraper .cmfloating_btnseller.on:after {
  background: #7989c7;
}

.sky_scraper .cmfloating_btnseller .cmfloating_btn {
  display: block;
  position: absolute;
  right: 0;
  width: 98px;
  height: 24px;
  padding: 4px 0 4px;
  border: 0;
  border-radius: 0;
  background: none;
}

.sky_scraper.sky_mini .cmfloating_btnarea {
  height: 57px;
}

.sky_scraper.sky_mini .cmfloating_btnarea .cmfloating_btn {
  right: 6px;
  width: 36px;
}

.sky_scraper.sky_mini .cmfloating_btnseller {
  height: 29px;
  padding-top: 0;
}

.sky_scraper.sky_mini .cmfloating_btnseller .cmfloating_btn {
  width: 38px;
}

.sky_scraper.sky_mini .cmfloating_btnseller:after {
  display: none;
}

.sky_scraper .cmfloating_btnarea.on .cmfloating_btn {
  border-color: #6677c0;
}

.sky_scraper .cmfloating_btnarea .cmfloating_ico {
  position: absolute;
  top: 9px;
  left: 9px;
  width: 18px;
  height: 18px;
}

.sky_scraper .cmfloating_btnarea.cmfloating_btncsbot .cmfloating_ico {
  background-position: 1px -50px;
}

.sky_scraper .cmfloating_btnarea.cmfloating_btncsbot.on .cmfloating_ico {
  background-position: -49px -50px;
}

.sky_scraper .cmfloating_btnarea .cmfloating_txt {
  position: absolute;
  top: 10px;
  right: 0;
  width: 73px;
  margin-right: 0;
  font-size: 12px;
  letter-spacing: -0.3px;
  color: #6c7080;
  text-align: center;
  white-space: nowrap;
}

.sky_scraper.sky_mini .cmfloating_btnarea .cmfloating_txt {
  top: 100%;
  right: -6px;
  width: 49px;
  font-size: 11px;
}

.sky_scraper .cmfloating_btnarea.on .cmfloating_txt {
  color: #6677c0;
}

.sky_scraper .cmfloating_btnarea .cmfloating_push {
  display: none;
  position: absolute;
  top: -5px;
  right: -2px;
  margin-left: -5px;
  padding: 0 0.45em;
  border-radius: 10px;
  background: var(--m-colors-primary, #ff5452);
  font-size: 11px;
  color: #fff;
  text-align: center;
}

.sky_scraper .cmfloating_btnarea.push .cmfloating_push {
  display: block;
}

.sky_scraper.sky_mini .cmfloating_btnarea .cmfloating_push {
  top: -1px;
  right: -6px;
}

.sky_scraper.sky_mini .cmfloating_btnseller .cmfloating_push {
  top: -11px;
}

.sky_scraper .cmfloating_btnarea .cmfloating_thmb_img {
  position: absolute;
  top: 0;
  left: 0;
  width: 36px;
  height: 36px;
  border-radius: 19px;
}

.sky_scraper .cmfloating_btnarea .cmfloating_thmb_img img {
  width: 100%;
  height: 100%;
  border-radius: 19px;
}

.sky_scraper .cmfloating_btnarea .cmfloating_msg {
  visibility: hidden;
  position: absolute;
  top: -14px;
  right: 100%;
  z-index: -1;
  width: 156px;
  height: 38px;
  margin-right: -11px;
  border: solid 1px #6677c0;
  border-radius: 19px 15px 0 19px;
  box-shadow: 3px 5px 5px 0 rgba(0, 0, 0, 0.15);
  background: #fff;
  font-size: 12px;
  letter-spacing: -0.3px;
  color: #6677c0;
  white-space: nowrap;
  word-wrap: break-word;
  word-break: break-all;
  opacity: 0;
  -webkit-transition: visibility 0.25s ease-out, z-index 0.25s ease-out, margin-right 0.25s ease-out, opacity 0.25s ease-out;
  transition: visibility 0.25s ease-out, z-index 0.25s ease-out, margin-right 0.25s ease-out, opacity 0.25s ease-out;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.sky_scraper .cmfloating_btnarea.push.on .cmfloating_msg {
  visibility: visible;
  z-index: 1;
  margin-right: 0;
  opacity: 1;
}

.sky_scraper .cmfloating_btnarea .cmfloating_msg:before {
  position: absolute;
  right: -9px;
  bottom: -1px;
  width: 9px;
  height: 8px;
  background-position: 0 -200px;
  content: "";
}

.sky_scraper .cmfloating_btnarea .cmfloating_msg:after {
  display: inline-block;
  overflow: hidden;
  width: 1px;
  height: 100%;
  margin-right: -1px;
  vertical-align: middle;
  content: "";
}

.sky_scraper .cmfloating_btnarea .cmfloating_msg .cmfloating_thmb_img {
  width: 38px;
  height: 38px;
}

.sky_scraper .cmfloating_btnarea .cmfloating_msg_txt {
  display: inline-block;
  overflow: hidden;
  padding: 0 10px 0 20px;
  line-height: 15px;
  white-space: normal;
  vertical-align: middle;
}

.sky_scraper .cmfloating_btnarea .cmfloating_msg .cmfloating_thmb_img + .cmfloating_msg_txt {
  padding-left: 45px;
}

.sky_scraper .cmfloating_btnseller .cmfloating_msg {
  top: -8px;
}

.sky_scraper.sky_mini .cmfloating_btnseller .cmfloating_msg {
  top: -23px;
}

.sky_scraper .cmfloating_btnarea .cmfloating_tip {
  visibility: hidden;
  position: absolute;
  top: -14px;
  right: 100%;
  z-index: -1;
  width: 156px;
  height: 38px;
  margin-right: -11px;
  border: solid 1px #6677c0;
  border-radius: 19px 15px 0 19px;
  box-shadow: 3px 5px 5px 0 rgba(0, 0, 0, 0.15);
  background: #6677c0;
  font-size: 11px;
  letter-spacing: -0.3px;
  color: #fff;
  white-space: nowrap;
  word-wrap: break-word;
  word-break: break-all;
  opacity: 0;
  -webkit-transition: visibility 0.25s ease-out, z-index 0.25s ease-out, margin-right 0.25s ease-out, opacity 0.25s ease-out;
  transition: visibility 0.25s ease-out, z-index 0.25s ease-out, margin-right 0.25s ease-out, opacity 0.25s ease-out;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.sky_scraper .cmfloating_btnarea.on .cmfloating_tip {
  visibility: visible;
  z-index: 1;
  margin-right: -1px;
  opacity: 1;
}

.sky_scraper .cmfloating_btnarea .cmfloating_tip:before {
  position: absolute;
  right: -9px;
  bottom: -1px;
  width: 9px;
  height: 8px;
  background-position: -50px -200px;
  content: "";
}

.sky_scraper .cmfloating_btnarea .cmfloating_tip:after {
  display: inline-block;
  overflow: hidden;
  width: 1px;
  height: 100%;
  margin-right: -1px;
  vertical-align: middle;
  content: "";
}

.sky_scraper .cmfloating_btnarea .cmfloating_tip .cmfloating_thmb_img {
  width: 38px;
  height: 38px;
}

.sky_scraper .cmfloating_btnarea .cmfloating_tip_txt {
  display: inline-block;
  overflow: hidden;
  padding: 0 10px 0 20px;
  line-height: 15px;
  white-space: normal;
  vertical-align: middle;
}

.sky_scraper .cmfloating_btnarea .cmfloating_tip .cmfloating_thmb_img + .cmfloating_tip_txt {
  padding-left: 45px;
}

@media screen and (-webkit-min-device-pixel-ratio: 2) {
  .sky_scraper .sky_ssgtalk .sky_ssgtalk_tittxt,
.sky_scraper .cmfloating_btnarea .cmfloating_ico,
.sky_scraper .cmfloating_btnarea .cmfloating_msg:before,
.sky_scraper .cmfloating_btnarea .cmfloating_tip:before {
    background-image: url(../../img/common_layout/sp_cmfloating_ssgtalk_v2@2x.png);
    background-size: 116px auto;
  }
  .sky_scraper .sky_ssgtalk .sky_ssgtalk_titico {
    background-image: url(../../img/common_layout/sky_ssgtalk_titico@2x.gif);
    background-size: 32px auto;
  }
  .sky_scraper .sky_ssgtalk .push .sky_ssgtalk_titico {
    background-image: url(../../img/common_layout/sky_ssgtalk_titico_on@2x.gif);
    background-size: 46px auto;
  }
  .sky_scraper .history_list.v2 li .icon_mall,
.sky_scraper.active .history_list.v2 li .icon_mall {
    background-image: url(../../img/common_layout/sp_ico_dotmall@2x.png);
    background-size: 150px auto;
  }
}
.sky_scraper.sky_afterlogin .sky_delivery a {
  display: block;
}

.sky_scraper.sky_afterlogin .sky_top a {
  bottom: -146px;
}

.sky_scraper.sky_afterlogin .sky_content {
  height: 186px;
}

.sky_scraper.sky_afterlogin .history_list {
  height: 186px;
}

.sky_scraper.sky_afterlogin .sky_direction {
  height: 261px;
}

.sky_scraper.sky_mini {
  width: 49px;
  right: 0;
  left: auto;
  margin-left: 0;
  background: none;
}

.sky_scraper.sky_mini .sky_haeder,
.sky_scraper.sky_mini .sky_content,
.sky_scraper.sky_mini .sky_direction,
.sky_scraper.sky_mini .sky_clip,
.sky_scraper.sky_mini .sky_delivery {
  display: none;
}

.sky_scraper.sky_mini .sky_btn_area {
  display: none;
}

.sky_scraper.sky_mini .btn_sky_mini {
  width: 49px;
  height: 43px;
  background: url(../../img/common_layout/bg_common_sky.png) no-repeat -139px -500px;
  right: 0;
  top: -28px;
}

:lang(en) .sky_scraper.sky_mini .btn_sky_mini,
:lang(zh) .sky_scraper.sky_mini .btn_sky_mini {
  background-position: -80px -500px;
}

.sky_scraper.sky_mini .sky_top a {
  width: 49px;
  height: 26px;
  background-position: -146px -52px;
  bottom: -44px;
  right: 0;
}

.sky_scraper.sky_mini .sky_btm {
  padding-top: 14px;
}

.sky_scraper.sky_mini .sky_btm .sky_bx,
.sky_scraper.sky_mini .sky_btm .sky_btn_myclip,
.sky_scraper.sky_mini .sky_btm .sky_btn_like {
  display: none;
}

.sky_scraper.sky_mini .sky_btm .sky_btn_top {
  width: 49px;
  height: 26px;
  background-position: -200px -52px;
}

.sky_scraper.low_width {
  right: 0;
  left: auto;
  margin-left: 0;
}

.sky_scraper.active .sky_btm,
.sky_scraper.active .btn_sky_mini {
  display: none;
}

.sky_scraper.active .sky_content {
  position: static;
  background: 0 none;
  margin-top: 0;
}

.sky_scraper.active .sky_btn_area {
  position: absolute;
  left: -7px;
  top: 13px;
}

.sky_scraper.active .sky_btn_area .btn {
  width: 22px;
  height: 22px;
  background: url(../../img/common_layout/bg_common_sky.png) no-repeat -100px -300px;
  cursor: pointer;
}

.sky_scraper.active .sky_haeder {
  overflow: hidden;
  width: auto;
  height: 47px;
  padding: 3px 0 0;
}

.sky_scraper.active .sky_haeder h3 {
  float: left;
  padding: 1px 0 0 20px;
  font-size: 13px;
  line-height: 40px;
  *padding-top: 0;
}

.sky_scraper.active .sky_haeder h3 strong {
  float: left;
  margin: 0 0 0 4px;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 19px;
  font-weight: 400;
  line-height: 40px;
  color: #e74848;
  vertical-align: top;
}

.sky_scraper.active .sky_haeder h3 img {
  padding-right: 4px;
  padding-top: 3px;
}

.sky_scraper.active .sky_haeder select {
  float: right;
  width: 113px;
  padding: 4px;
  height: 26px\9 ;
  *height: auto;
  margin: 8px 15px 0 0;
  *margin: 12px 15px 0 0;
  vertical-align: -3px;
  font-size: 12px;
  color: #222;
}

:lang(en) .sky_scraper.active .sky_haeder select,
:lang(zh) .sky_scraper.active .sky_haeder select {
  width: auto;
}

.sky_scraper.active .history_list {
  position: relative;
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: auto;
  height: 503px;
  margin: -1px 0 0 10px;
  background: url(../../img/common_layout/bg_history.gif) repeat-y 9px 0;
}

.sky_scraper.active .history_list li {
  overflow: hidden;
  margin-top: 8px;
  width: 364px;
}

.sky_scraper.active .history_list li:first-child {
  margin-top: 15px;
}

.sky_scraper.active .history_list li.category {
  margin-left: 0;
}

.sky_scraper.active .history_list li .blind_txt {
  display: inline;
  color: #848484;
}

.sky_scraper.active .history_list li .icon_mall {
  margin-right: 7px;
}

.sky_scraper.active .history_list li .history_cont .txt {
  max-height: 54px;
}

.sky_scraper.active .history_list li .history_cont {
  overflow: hidden;
  float: left;
  width: 330px;
  padding: 2px 0 10px;
  border-bottom: 1px dotted #909090;
}

.sky_scraper.active .history_list li .history_cont .sky_thumbnail.adult_only a {
  background-position: -100px -100px;
}

.sky_scraper.active .history_list li .history_cont .sky_thumbnail.sold_out a {
  background-position: 0 -100px;
}

.sky_scraper.active .history_list li .history_cont .txt {
  float: left;
  width: 330px;
}

.sky_scraper.active .history_list li .history_cont .txt.blind_txt {
  width: 270px;
}

.sky_scraper.active .history_list li .history_cont .txt.blind_txt a {
  color: #848484;
  text-decoration: none;
  word-break: break-all;
  word-wrap: break-word;
}

.sky_scraper.active .history_list li .history_cont .txt a:hover {
  text-decoration: underline;
}

.sky_scraper.active .history_list li.type_category .txt {
  color: #848484;
}

.sky_scraper.active .history_list li.type_brand .history_cont .txt .brand_txt {
  color: #222;
  padding-right: 3px;
}

.sky_scraper.active .history_list li.type_brand .history_cont .txt .brand_txt .brand {
  color: #848484;
  padding-right: 3px;
}

.sky_scraper.active .history_list li.type_event .txt a .em,
.sky_scraper.active .history_list li.type_exhibition .txt a .em {
  color: #848484;
}

.sky_scraper.active .history_list li.type_search .txt a {
  color: #222;
}

.sky_scraper.active .history_list li.type_brand .history_cont .txt a,
.sky_scraper.active .history_list li.type_search .txt {
  color: #848484;
}

.sky_scraper.active .history_list li.type_product .txt a {
  max-height: 36px;
  overflow: hidden;
  display: block;
  color: #222;
}

.sky_scraper.active .history_list li.type_product .txt a .brand {
  color: #848484;
}

.sky_scraper.active .history_list li.type_product.adult .txt a {
  display: block;
  overflow: hidden;
  color: #222;
  line-height: 18px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.sky_scraper.active .history_list li.type_product .txt del {
  display: inline-block;
  margin-right: 8px;
  color: #aaaaaa;
}

.sky_scraper.active .history_list li.type_product .txt .applied {
  font-weight: bold;
  color: #e74848;
}

.sky_scraper.active .history_list li.type_product:hover .btn_history_del {
  display: block;
}

.sky_scraper.active .history_list li.type_brand .history_cont .txt {
  float: left;
  width: 250px;
  color: #848484;
}

.sky_scraper.active .history_list li.type_event .txt .date,
.sky_scraper.active .history_list li.type_exhibition .txt .date {
  color: #848484;
  display: block;
}

.sky_scraper.active .history_list li.type_event .txt a,
.sky_scraper.active .history_list li.type_exhibition .txt a {
  display: block;
  overflow: hidden;
  color: #222;
  line-height: 18px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.sky_scraper.active .history_list li.history_txt {
  background: #fff;
  margin: 0;
}

.sky_scraper.active .history_list li.history_txt p.history_txt {
  height: 265px;
  width: 383px;
  margin: 28px 0 0 3px;
  padding-top: 210px;
  text-align: center;
  font-size: 20px;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
  line-height: 26px;
  color: #666;
}

.sky_scraper.active .history_list li.history_txt p.history_txt span {
  color: #00aac4;
}

.sky_scraper.active .sky_btn_del {
  display: block;
}

.sky_scraper.active .history_list li.cmsky_ty_trip .txt a {
  max-height: 54px;
}
.sky_scraper.active .history_list li.cmsky_ty_trip .txt .cmsky_trip_cities {
  display: block;
  max-width: 260px;
  white-space: nowrap;
  overflow: hidden;
}
.sky_scraper.active .history_list li.cmsky_ty_trip .txt .cmsky_trip_cities i {
  display: inline-block;
  font-size: 0;
  width: 16px;
  height: 12px;
  margin-top: 2px;
  background: url(../../img/common_layout/bg_common_sky.png) no-repeat 0 0;
  background-position: -100px -372px;
  vertical-align: top;
}
.sky_scraper.active .history_list li.cmsky_ty_trip .txt .cmsky_trip_date {
  color: #338ca8;
  letter-spacing: -0.3px;
}
.sky_scraper.active .history_list li.cmsky_ty_trip .txt .cmsky_trip_stops {
  display: block;
  color: #848484;
}
.sky_scraper.active .history_list li.cmsky_ty_trip .txt .cmsky_trip_hotels {
  display: block;
  max-width: 260px;
  white-space: nowrap;
  overflow: hidden;
}
.sky_scraper.active .history_list li.cmsky_ty_trip .txt .cmsky_trip_countries {
  display: block;
  color: #848484;
}

#sr_ly {
  position: fixed;
  z-index: 1000;
  left: 50%;
  right: auto;
  margin-left: 395px;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
}

.body_wide #sr_ly {
  position: fixed;
  z-index: 1000;
  left: 50%;
  right: auto;
  margin-left: 526px;
}

#sr_ly.low_width {
  left: auto;
  right: 20px;
  margin-left: 0;
}

#sr_ly .history_cont {
  position: relative;
  width: 158px;
  height: 44px;
  padding: 9px 62px 9px 11px;
  border: 1px solid #000;
  background: #fff;
}

:lang(en) #sr_ly .history_cont,
:lang(zh) #sr_ly .history_cont {
  margin-top: -40px;
}

#sr_ly .history_cont:after {
  content: "";
  display: block;
  clear: both;
}

#sr_ly .history_cont .btn_history_del {
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  background: url(../../img/common_layout/btn_history_del.png) 0 0 no-repeat;
  right: -21px;
  top: -1px;
}

#sr_ly .history_cont .sky_thumbnail {
  position: absolute;
  right: 6px;
  top: 6px;
  float: none;
  margin: 0;
}

#sr_ly .history_cont .sky_thumbnail a {
  display: inline-block;
  border: 1px solid #ececec;
  vertical-align: top;
}

#sr_ly .history_cont .sky_thumbnail a img {
  vertical-align: top;
}

#sr_ly .history_cont .sky_thumbnail .img_hover {
  display: none;
}

#sr_ly .history_cont .txt {
  overflow: visible;
  max-height: 100%;
  height: 62px;
  color: #555;
}

#sr_ly .history_cont .txt a {
  display: block;
  overflow: hidden;
  max-height: 26px;
  margin-bottom: 2px;
  font-size: 11px;
  line-height: 13px;
  color: #777;
}

#sr_ly .history_cont.adult .txt a {
  max-height: 13px;
}

#sr_ly .history_cont .txt .adult_notice {
  display: block;
  font-size: 11px;
  line-height: 13px;
  color: #777;
}

#sr_ly .history_cont .txt del {
  display: inline-block;
  color: #aaa;
  vertical-align: top;
  display: none;
}

#sr_ly .history_cont .txt .applied {
  display: inline-block;
  vertical-align: top;
  line-height: 22px;
}

#sr_ly .history_cont .txt .applied .ssg_price {
  display: inline-block;
  margin: 0;
  font-size: 15px;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
  line-height: 22px;
  color: #f14f4f;
  vertical-align: top;
}

#sr_ly .history_cont .txt .applied .ssg_tx {
  display: inline-block;
  vertical-align: top;
}

#sr_ly .history_cont .txt .applied em {
  display: inline-block;
  font-size: 15px;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
  line-height: 22px;
  color: #f14f4f;
  vertical-align: -1px;
  margin-right: 1px;
}

#sr_ly .history_cont.cmsky_ty_trip {
  padding: 10px 61px 10px 11px;
  width: 160px;
}
#sr_ly .history_cont.cmsky_ty_trip .txt a {
  max-height: 46px;
}
#sr_ly .history_cont.cmsky_ty_trip .txt .cmsky_trip_cities {
  display: block;
  height: 13px;
  max-width: 160px;
  overflow: hidden;
}
#sr_ly .history_cont.cmsky_ty_trip .txt .cmsky_trip_cities i {
  display: inline-block;
  font-size: 0;
  width: 16px;
  height: 12px;
  background: url(../../img/common_layout/bg_common_sky.png) no-repeat 0 0;
  background-position: -100px -372px;
  vertical-align: top;
}
#sr_ly .history_cont.cmsky_ty_trip .txt .cmsky_trip_date {
  display: block;
  margin-top: 2px;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
  color: #338ca8;
  font-size: 12px;
  letter-spacing: -0.3px;
}
#sr_ly .history_cont.cmsky_ty_trip .txt .cmsky_trip_stops {
  display: block;
  color: #848484;
  margin-top: 3px;
}
#sr_ly .history_cont.cmsky_ty_trip .txt .cmsky_trip_hotels {
  display: block;
  height: 13px;
  max-width: 160px;
  overflow: hidden;
}
#sr_ly .history_cont.cmsky_ty_trip .txt .cmsky_trip_countries {
  display: block;
  margin-top: 3px;
  color: #848484;
}

/* 공통헤더 */
.common_header {
  position: relative;
  z-index: 400;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
  line-height: 1.5em;
}

.common_header:after {
  display: block;
  clear: both;
  content: "";
}

.common_header .common_header_inner {
  position: relative;
  z-index: 300;
  width: 1280px;
  margin: 0 auto;
  height: 59px;
  padding-top: 13px;
}

.common_header img {
  vertical-align: middle;
}

.common_header .sp_rban {
  display: inline-block;
  overflow: hidden;
  width: auto;
  height: 10px;
  background: url(../../img/common_layout/sp_rban.png) no-repeat;
  line-height: 999px;
  vertical-align: top;
}

.common_header .sp_cmh,
.common_header .util_menu .cmh_ico_myssg:after,
.common_header .util_myssg li.util_myssg_02 a:after {
  display: inline-block;
  overflow: hidden;
  background: url(../../img/common_layout/sp_cmh.png) no-repeat;
  vertical-align: top;
}

:lang(en) .common_header .sp_cmh,
.common_header .util_menu .cmh_ico_myssg:after,
.common_header .util_myssg li.util_myssg_02 a:after {
  background-image: url(../../img/common_layout/sp_cmh_en.png);
}

:lang(zh) .common_header .sp_cmh,
.common_header .util_menu .cmh_ico_myssg:after,
.common_header .util_myssg li.util_myssg_02 a:after {
  background-image: url(../../img/common_layout/sp_cmh_zh.png);
}

.common_header.other_site .common_header_inner {
  height: 42px;
}

@media screen and (-webkit-min-device-pixel-ratio: 2) {
  .common_header .sp_cmh,
.common_header .util_menu .cmh_ico_myssg:after,
.common_header .util_myssg li.util_myssg_02 a:after {
    background-image: url(../../img/common_layout/sp_cmh_2x.png);
    background-size: 150px auto;
  }
  :lang(en) .common_header .sp_cmh,
.common_header .util_menu .cmh_ico_myssg:after,
.common_header .util_myssg li.util_myssg_02 a:after {
    background-image: url(../../img/common_layout/sp_cmh_en_2x.png);
  }
  :lang(zh) .common_header .sp_cmh,
.common_header .util_menu .cmh_ico_myssg:after,
.common_header .util_myssg li.util_myssg_02 a:after {
    background-image: url(../../img/common_layout/sp_cmh_zh_2x.png);
  }
}
/* 공통헤더 좌측 날개배너 */
.left_wing_bx .bn_blist_benefit {
  overflow: hidden;
  position: relative;
  width: 77px;
  padding: 3px 0;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  background: #fff;
}

.left_wing_bx .card_bnr_area .bn_blist {
  overflow: hidden;
  height: 109px;
}

.left_wing_bx .bn_blist li a {
  display: inline-block;
  vertical-align: top;
}

.left_wing_bx .bn_blist li img {
  vertical-align: top;
}

.left_wing_bx .bn_blist_benefit .bx-controls-direction {
  padding: 3px 0;
  line-height: 0;
  text-align: center;
}

.left_wing_bx .bn_blist_benefit .bx-controls-direction a {
  display: inline-block;
  overflow: hidden;
  background: url(../../img/sprites/sp_leftwing.png) 0 0 no-repeat;
  line-height: 999px;
  vertical-align: top;
  width: 18px;
  height: 18px;
  margin: 0 1px;
}

.left_wing_bx .bn_blist_benefit .bx-controls-direction .bx-next {
  background-position: -20px 0;
}

/* 공통헤더 유틸 */
.common_header .header_util {
  position: absolute;
  top: 10px;
  left: 0;
  z-index: 20;
  width: 100%;
}

.common_header .header_util:after {
  display: block;
  clear: both;
  content: "";
}

.common_header .util_left {
  display: none;
  float: left;
}

.common_header .util_left li {
  float: left;
  margin-left: 10px;
  padding-left: 11px;
  background: url(../../img/common_layout/bg_vertical_util.gif) 0 4px no-repeat;
}

.common_header .util_left li:first-child {
  margin-left: 0;
  padding-left: 0;
  background: none;
}

.common_header .util_left li a {
  font-weight: bold;
  font-size: 11px;
  line-height: 19px;
  color: #666;
  letter-spacing: -0.5px;
  text-decoration: none;
}

.common_header .util_left li.util_lang {
  min-width: 97px;
}

.common_header .util_left li.util_lang.on {
  position: relative;
  z-index: 2;
  margin-right: -18px;
}

:lang(en) .common_header .util_left li.util_lang.on {
  margin-right: -13px;
}

:lang(zh) .common_header .util_left li.util_lang.on {
  margin-right: -4px;
}

.common_header .util_left li.util_lang .util_lang_btnsel {
  display: block;
  padding: 0 0 0 10px;
  background-color: #fff;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 500;
  font-size: 11px;
  line-height: 33px;
  color: #666;
}

.common_header .util_left li.util_lang.on .util_lang_btnsel:hover {
  background-color: #222;
  color: #fff;
}

.common_header .util_left li.util_lang .util_lang_sel {
  display: block;
  margin: -10px 0 0 -10px;
  border: 1px solid #fff;
  border-bottom: 0;
}

.common_header .util_left li.util_lang.on .util_lang_sel {
  width: 123px;
  border-color: #d1d1d1;
}

.common_header .util_left li.util_lang .util_lang_sel .util_lang_btnsel {
  padding-top: 1px;
}

.common_header .util_left li.util_lang .util_lang_sel .util_lang_btnsel:after {
  display: inline-block;
  margin: 13px 0 0 4px;
  border-top: 4px solid #222;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  vertical-align: top;
  content: "";
}

.common_header .util_left li.util_lang.on .util_lang_sel .util_lang_btnsel:after {
  display: none;
}

.common_header .util_left li.util_lang .util_lang_layer {
  display: none;
  position: absolute;
  top: 100%;
  left: -10px;
  width: 123px;
  border: 1px solid #d1d1d1;
  border-top: 0;
  background: #fff;
}

.common_header .util_left li.util_lang.on .util_lang_layer {
  display: block;
}

.common_header .util_left li.util_lang_cur {
  display: none;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 500;
  font-size: 11px;
  color: #666;
}

.util_lang .util_lang_ico {
  display: inline-block;
  width: 25px;
  height: 25px;
  margin: 4px 4px 0 0;
  background: url(../../img/common_layout/sp_util_lang.png) no-repeat 0 0;
  vertical-align: top;
}

:lang(ko) .util_lang .util_lang_btnsel1 .util_lang_ico {
  background-position: 0 0;
}

:lang(ko) .util_lang .util_lang_btnsel2 .util_lang_ico {
  background-position: 0 -50px;
}

:lang(ko) .util_lang .util_lang_btnsel3 .util_lang_ico {
  background-position: 0 -100px;
}

:lang(auto) .util_lang .util_lang_btnsel1 .util_lang_ico {
  background-position: 0 0;
}

:lang(auto) .util_lang .util_lang_btnsel2 .util_lang_ico {
  background-position: 0 -50px;
}

:lang(auto) .util_lang .util_lang_btnsel3 .util_lang_ico {
  background-position: 0 -100px;
}

:lang(en) .util_lang .util_lang_btnsel1 .util_lang_ico {
  background-position: 0 -50px;
}

:lang(en) .util_lang .util_lang_btnsel2 .util_lang_ico {
  background-position: 0 0;
}

:lang(en) .util_lang .util_lang_btnsel3 .util_lang_ico {
  background-position: 0 -100px;
}

:lang(zh) .util_lang .util_lang_btnsel1 .util_lang_ico {
  background-position: 0 -100px;
}

:lang(zh) .util_lang .util_lang_btnsel2 .util_lang_ico {
  background-position: 0 0;
}

:lang(zh) .util_lang .util_lang_btnsel3 .util_lang_ico {
  background-position: 0 -50px;
}

.util_lang .util_lang_tx em {
  display: none;
}

:lang(ko) .util_lang .util_lang_btnsel1 .util_lang_tx_ko {
  display: inline;
}

:lang(ko) .util_lang .util_lang_btnsel2 .util_lang_tx_en {
  display: inline;
}

:lang(ko) .util_lang .util_lang_btnsel3 .util_lang_tx_zh {
  display: inline;
}

:lang(auto) .util_lang .util_lang_btnsel1 .util_lang_tx_ko {
  display: inline;
}

:lang(auto) .util_lang .util_lang_btnsel2 .util_lang_tx_en {
  display: inline;
}

:lang(auto) .util_lang .util_lang_btnsel3 .util_lang_tx_zh {
  display: inline;
}

:lang(en) .util_lang .util_lang_btnsel1 .util_lang_tx_en {
  display: inline;
}

:lang(en) .util_lang .util_lang_btnsel2 .util_lang_tx_ko {
  display: inline;
}

:lang(en) .util_lang .util_lang_btnsel3 .util_lang_tx_zh {
  display: inline;
}

:lang(zh) .util_lang .util_lang_btnsel1 .util_lang_tx_zh {
  display: inline;
}

:lang(zh) .util_lang .util_lang_btnsel2 .util_lang_tx_ko {
  display: inline;
}

:lang(zh) .util_lang .util_lang_btnsel3 .util_lang_tx_en {
  display: inline;
}

:lang(en) .auto_word,
:lang(zh) .auto_word {
  border-top: 1px solid #d1d1d1;
  top: 2px;
}

@media screen and (-webkit-min-device-pixel-ratio: 2) {
  .util_lang .util_lang_ico {
    background-image: url(../../img/common_layout/sp_util_lang@2x.png);
    background-size: 25px auto;
  }
}
.common_header .util_left li.util_go_global a {
  position: relative;
  display: inline-block;
  vertical-align: top;
  padding-right: 37px;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
}

.common_header .util_left li.util_go_global .go_global_ico {
  position: absolute;
  right: 0;
  top: -2px;
}

/* util_go_global 배포 후, util_intl_guide 제거 예정(s) */
.common_header .util_left li.util_intl_guide {
  margin-top: -3px;
}

.common_header .util_left li.util_intl_guide img {
  width: 85px;
  height: 21px;
  vertical-align: top;
}

/* util_go_global 배포 후, util_intl_guide 제거 예정(e) */
/* #394955 [PC GNB] 바로가기 ON 버튼 비노출 처리 */
.common_header .util_left li.util_go_s {
  display: none;
}

/* //#394955 [PC GNB] 바로가기 ON 버튼 비노출 처리 */
.common_header .util_left li.util_go_s .ico {
  display: inline-block;
  margin-left: 2px;
  vertical-align: top;
}

.common_header .util_left li.util_go_s .util_s_bx {
  display: none;
  position: absolute;
  top: 19px;
  left: 0;
  padding-top: 3px;
  cursor: pointer;
}

.common_header .util_left li.util_go_s .util_s_bx img {
  vertical-align: top;
}

.common_header .util_left li.util_go_s.hover .util_s_bx {
  display: block;
}

.common_header .util_right {
  float: right;
}

.common_header .util_right li {
  float: left;
  margin-right: 8px;
  padding-right: 9px;
  background: url(../../img/common_layout/bg_vertical_util.gif) 100% 4px no-repeat;
}

.common_header .util_right li:last-child {
  margin-right: 0;
  padding-right: 0;
  background: none;
}

.common_header .util_right li a {
  font-weight: bold;
  font-size: 11px;
  line-height: 19px;
  color: #666;
  letter-spacing: -0.5px;
  text-decoration: none;
}

.common_header .util_menu {
  position: absolute;
  top: 22px;
  left: 824px;
  z-index: 21;
}

.common_header.rank .util_menu {
  position: absolute;
  top: 26px;
  left: 875px;
  z-index: 41;
}

.common_header .util_menu > li {
  float: left;
  padding-left: 1px;
  background: url(../../img/common_layout/bg_vertical_util2.gif) 0 50% no-repeat;
}

.common_header .util_menu > li:first-child {
  padding-left: 0;
  background: none;
}

.common_header .util_menu > li > a,
.common_header .util_menu > li > button {
  display: block;
  position: relative;
  padding: 2px 13px 0 12px;
}

.common_header .util_menu .cmh_ico_cart {
  width: 31px;
  height: 38px;
  background-position: 0 0;
}

.common_header .util_menu .cmh_ico_order {
  width: 46px;
  height: 38px;
  background-position: -33px 0;
}

.common_header .util_menu .cmh_ico_myssg {
  width: 33px;
  height: 38px;
  background-position: -81px 0;
}

.common_header .util_menu .num_nc {
  position: absolute;
  top: -4px;
  left: 50%;
  min-width: 12px;
  margin-left: 3px;
  padding: 0 3px;
  background: var(--m-colors-primary, #ff5452);
  border-radius: 9px;
  font-size: 11px;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
  line-height: 18px;
  color: #fff;
  text-align: center;
}

.common_header .util_cart {
  position: relative;
  word-break: break-all;
  letter-spacing: -0.5px;
}

.common_header .util_cart .sr_off {
  display: block;
}

.common_header .util_cart .sr_on {
  display: none;
}

.common_header .util_cart button.util_cart_btn {
  display: none;
  background: transparent;
}

.common_header .util_cart .util_cart_layer {
  display: none;
  position: absolute;
  top: 46px;
  left: -13px;
  min-width: 318px;
  border: 1px solid #d1d1d1;
  background: #fff;
}

.common_header .util_cart .util_cart_layer:before {
  position: absolute;
  top: -1px;
  left: 0;
  width: 78px;
  height: 1px;
  background: #fff;
  content: "";
}

.common_header .util_cart .util_cart_layer.ty_empty {
  min-width: 198px;
}

.common_header .util_cart .util_cart_link {
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 78px;
  height: 55px;
  background: url(../../img/common/b.gif);
}

.common_header .util_cart .util_cart_tit {
  display: block;
  padding: 14px 19px 0;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: #222;
}

.common_header .util_cart .util_cart_loading {
  height: 40px;
  margin: 15px 20px;
  background: url(../../img/common/loading.gif) no-repeat 50% 0;
}

.common_header .util_cart .util_cart_more {
  display: block;
  padding: 13px 0;
  font-size: 13px;
  line-height: 15px;
  color: var(--m-colors-primary, #ff5452);
  text-align: center;
  text-decoration: none;
}

.common_header .util_cart .util_cart_more:hover {
  text-decoration: underline;
}

.common_header .util_cart .util_cart_more:after {
  display: inline-block;
  width: 9px;
  height: 15px;
  margin: -1px 0 0 6px;
  background: url(../../img/common_layout/bu_arr2.png);
  vertical-align: top;
  content: "";
}

@media screen and (-webkit-min-device-pixel-ratio: 2) {
  .common_header .util_cart .util_cart_more:after {
    background-image: url(../../img/common_layout/bu_arr2_2x.png);
    background-size: 9px auto;
  }
}
.common_header .util_cart .util_cart_total {
  padding-bottom: 8px;
  background: #fafafa;
  color: #222;
  line-height: 18px;
}

.common_header .util_cart .util_cart_total:before {
  display: block;
  height: 4px;
  margin-bottom: 10px;
  border-top: 1px solid #d7d7d7;
  background: #efefef;
  content: "";
}

.common_header .util_cart .util_cart_total:after {
  display: block;
  clear: both;
  content: "";
}

.common_header .util_cart .util_cart_totaltit {
  float: left;
  clear: both;
  padding: 3px 0 3px 19px;
}

.common_header .util_cart .util_cart_totaldsc {
  float: right;
  clear: right;
  padding: 3px 19px 3px 0;
  font-weight: bold;
  text-align: right;
}

.common_header .util_cart .util_cart_price .ssg_price {
  display: inline-block;
  margin-top: -2px;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0;
  vertical-align: top;
}

.common_header .util_cart .util_cart_pointx {
  color: var(--m-colors-primary, #ff5452);
}

.common_header .util_cart .util_cart_pointx2 {
  color: #969696;
  letter-spacing: -0.3px;
}

.common_header .util_cart .util_cart_empty {
  display: block;
  padding: 18px 20px 19px;
  color: #888;
}

.common_header .util_cartlst {
  padding: 0 19px;
}

.common_header .util_cartlst .util_cartlst_item {
  padding: 10px 0;
  border-bottom: 1px solid #efefef;
}

.common_header .util_cartlst .util_cartlst_item:after {
  display: block;
  clear: both;
  content: "";
}

.common_header .util_cartlst .util_cartlst_thmb {
  float: left;
  position: relative;
  width: 50px;
  height: 50px;
}

.common_header .util_cartlst .util_cartlst_thmb a:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.02);
  z-index: 3;
}

.common_header .util_cartlst .util_cartlst_img {
  width: 100%;
  height: 100%;
}

.common_header .util_cartlst .util_cartlst_soldout {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #222;
  background-color: rgba(34, 34, 34, 0.95);
  font-weight: bold;
  font-size: 11px;
  line-height: 18px;
  color: #fff;
  letter-spacing: -0.4px;
  text-align: center;
}

.common_header .util_cartlst .util_cartlst_infoarea {
  margin-left: 60px;
  font-size: 0;
  white-space: nowrap;
}

.common_header .util_cartlst .util_cartlst_name {
  display: block;
  overflow: hidden;
  max-width: 220px;
  padding-top: 2px;
  font-size: 12px;
  line-height: 23px;
  color: #888;
  letter-spacing: -0.3px;
  text-overflow: ellipsis;
  text-decoration: none;
}

.common_header .util_cartlst .util_cartlst_name:hover {
  text-decoration: underline;
}

/*  #364373 [공통] 몰아이콘 마크업 통합 관리 배포후 삭제*/
.common_header .util_cartlst .util_cartlst_mall {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 3px 0 2px;
  background-position-x: 0;
  background-image: url(../../img/common_layout/sp_mall_cate_ico_v2.png);
  background-repeat: no-repeat;
  font-size: 12px;
  vertical-align: top;
}

.common_header .util_cartlst .util_cartlst_mall.ssg {
  background-position-y: 0;
}

.common_header .util_cartlst .util_cartlst_mall.sm {
  background-position-y: -20px;
}

.common_header .util_cartlst .util_cartlst_mall.sd {
  background-position-y: -40px;
}

.common_header .util_cartlst .util_cartlst_mall.em {
  background-position-y: -60px;
}

.common_header .util_cartlst .util_cartlst_mall.tr {
  background-position-y: -80px;
}

.common_header .util_cartlst .util_cartlst_mall.bt {
  background-position-y: -100px;
}

.common_header .util_cartlst .util_cartlst_mall.tv {
  background-position-y: -120px;
}

.common_header .util_cartlst .util_cartlst_mall.si {
  background-position-y: -140px;
}

.common_header .util_cartlst .util_cartlst_mall.hwd {
  background-position-y: -160px;
}

.common_header .util_cartlst .util_cartlst_mall.outlet {
  background-position-y: -180px;
}

.common_header .util_cartlst .util_cartlst_mall.mnmorning {
  background-position-y: -200px;
}

@media screen and (-webkit-min-device-pixel-ratio: 2) {
  .common_header .util_cartlst .util_cartlst_mall {
    background-position-x: -20px;
    background-size: 40px 300px;
  }
}
.common_header .util_cartlst .util_cartlst_mall {
  margin: 0 3px 0 2px;
}

.common_header .util_cartlst .util_cartlst_mall + .util_cartlst_info {
  margin-left: 0;
  padding-left: 0;
}

.common_header .util_cartlst .util_cartlst_mall + .util_cartlst_info:before {
  display: none;
}

/*  #364373 [공통] 몰아이콘 마크업 통합 관리 배포후 삭제*/
.common_header .util_cartlst .util_cartlst_icon {
  display: inline-block;
  vertical-align: top;
  width: 20px;
  height: 20px;
  margin: 0 3px 0 2px;
}

.common_header .util_cartlst .util_cartlst_icon + .util_cartlst_info {
  margin-left: 0;
  padding-left: 0;
}

.common_header .util_cartlst .util_cartlst_icon + .util_cartlst_info:before {
  display: none;
}

.common_header .util_cartlst .util_cartlst_info {
  display: inline-block;
  position: relative;
  margin-left: 10px;
  padding: 2px 0 0 11px;
  font-size: 13px;
  line-height: 18px;
  color: #888;
  vertical-align: top;
}

.common_header .util_cartlst .util_cartlst_info:before {
  position: absolute;
  top: 4px;
  left: 0;
  width: 1px;
  height: 13px;
  background: #c7c7c7 url(../../img/common_layout/bg_dotline_trans_white.gif) repeat-y;
  content: "";
}

.common_header .util_cartlst .util_cartlst_price {
  color: #222;
}

.common_header .util_cartlst .util_cartlst_price .ssg_price {
  display: inline-block;
  margin-top: -2px;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0;
  vertical-align: top;
}

.common_header .util_cart.active_preview a.util_cart_btn {
  display: none;
}

.common_header .util_cart.active_preview button.util_cart_btn {
  display: block;
}

.common_header .util_cart .util_cart_btnview {
  display: none;
}

.common_header .util_cart.active_preview .util_cart_btnpreview {
  display: none;
}

.common_header .util_cart.active_preview .util_cart_btnview {
  display: block;
}

.common_header .util_cartlst .util_cartlst_item.ty_bundle .util_cartlst_name {
  line-height: 18px;
}

.common_header .util_cartlst .util_cartlst_item.ty_bundle .util_cartlst_name:before {
  content: "·";
}

.common_header .util_cartlst .util_cartlst_item.ty_bundle dt {
  margin-bottom: 6px;
}

.common_header .util_cartlst .util_cartlst_item.ty_bundle .util_cartlst_thmb {
  height: auto;
  width: 46px;
  padding: 0 0 15px 4px;
}

.common_header .util_cartlst .util_cartlst_item.ty_bundle .util_cartlst_thmb a:before {
  display: none;
}

.common_header .util_cartlst .util_cartlst_item.ty_bundle .util_cartlst_thmb img.util_cartlst_img {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  border: 1px solid #eee;
  margin-bottom: -15px;
}

.common_header .util_cart.on {
  z-index: 10;
}

.common_header .util_cart.on:before {
  position: absolute;
  top: -9px;
  left: -13px;
  width: 78px;
  height: 54px;
  border: 1px solid #d1d1d1;
  border-bottom-color: #fff;
  background: #fff;
  content: "";
}

.common_header .util_cart.on .sr_off {
  display: none;
}

.common_header .util_cart.on .sr_on {
  display: block;
}

.common_header .util_cart.on .util_cart_layer {
  display: block;
}

.common_header .util_myssg {
  position: relative;
  z-index: 1;
}

.common_header .util_myssg ul {
  display: none;
  position: absolute;
  top: -9px;
  left: 0;
  width: 98px;
  height: 151px;
  border: 1px solid #d1d1d1;
  background: #fff;
}

:lang(en) .common_header .util_myssg ul {
  width: 130px;
  height: 157px;
}

:lang(zh) .common_header .util_myssg ul {
  width: 110px;
  height: 157px;
}

.common_header .util_myssg.on {
  z-index: 11;
}

.common_header .util_myssg.on ul {
  display: block;
}

.common_header .util_myssg li {
  margin: 0 -1px;
}

.common_header .util_myssg li:first-child {
  margin-top: -1px;
}

.common_header .util_myssg li a {
  display: block;
  position: relative;
  padding: 0 13px;
  font-size: 12px;
  line-height: 30px;
  color: #222;
  letter-spacing: -0.5px;
  text-decoration: none;
}

.common_header .util_myssg li a:hover {
  background: #222;
  color: #fff;
}

.common_header .util_myssg li.util_myssg_01:after {
  display: block;
  margin: -1px 1px 0;
  border-top: 1px solid #d7d7d7;
  height: 4px;
  background: #efefef;
  content: "";
}

.common_header .util_myssg li.util_myssg_01 a {
  padding: 11px 13px 10px;
}

.common_header .util_myssg li.util_myssg_01 a .cmh_ico_myssg:after {
  overflow: hidden;
  position: absolute;
  top: 50%;
  right: 9px;
  width: 9px;
  height: 15px;
  margin: -8px 0 0 14px;
  background-position: -14px -40px;
  content: "";
}

.common_header .util_myssg li.util_myssg_01 a:hover .cmh_ico_myssg {
  background-position: -116px 0;
}

.common_header .util_myssg li.util_myssg_01 a:hover .cmh_ico_myssg:after {
  background-position: -25px -40px;
}

.common_header .util_myssg li.util_myssg_02 a:after {
  width: 15px;
  height: 13px;
  margin: 9px 0 0 4px;
  background-position: -36px -40px;
  content: "";
}

.common_header .util_myssg li .util_myssg_like {
  display: block;
}
.common_header .util_myssg li .util_myssg_like a .cmlike_ico {
  margin: 6px 3px 0 -3px;
}
.common_header .util_myssg li .util_myssg_like a:hover .cmlike_ico .cmlike_primary_xs {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M0 0h16v16H0z'/%3E%3Cpath fill='%23fff' stroke-width='0' stroke='%23fff' fill-rule='nonzero' d='M10.8 2.5a3.5 3.5 0 00-2.73 1.58L8 4.2l-.07-.12A3.5 3.5 0 001.5 6a4.08 4.08 0 001.14 2.75L8 14.1l5.35-5.36a4.08 4.08 0 001.15-2.74A3.5 3.5 0 0011 2.5h-.2zM7.5 5.83V6h1a2.5 2.5 0 015 0l-.01.2a3.07 3.07 0 01-.85 1.85L8 12.7 3.35 8.05a3.06 3.06 0 01-.85-2.06 2.5 2.5 0 015-.15z'/%3E%3C/g%3E%3C/svg%3E");
}
.common_header .util_myssg li .util_myssg_like a:after {
  display: none;
}

.common_header .direct_link {
  position: absolute;
  top: 42px;
  right: 0;
  z-index: 11;
}

.common_header .direct_link li {
  float: left;
  margin-left: 8px;
  padding-left: 9px;
  background: url(../../img/common_layout/bg_vertical_util.gif) no-repeat 0 5px;
}

.common_header .direct_link li:first-child {
  margin-left: 0;
  padding-left: 0;
  background: none;
}

.common_header .direct_link li img {
  vertical-align: top;
}

.common_header.other_site .util_menu {
  top: 13px;
  left: 799px;
}

.common_header.rank.other_site .util_menu {
  top: 17px;
  left: 825px;
}

.common_header.other_site .direct_link {
  top: 38px;
}

/* 공통헤더 로고 */
.common_header .logo_section {
  position: absolute;
  top: 0;
  left: 186px;
  width: 270px;
  height: 80px;
  text-align: right;
  overflow: hidden;
}

.common_header.rank .logo_section {
  left: 130px;
  top: 0;
}

.common_header .logo_section a {
  display: inline-block;
  color: #000;
  text-decoration: none;
  width: 270px;
  height: 83px;
  font-size: 21px;
  cursor: pointer;
  text-align: right;
}

.common_header .logo_section span {
  display: block;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 500;
}

.common_header .logo_section .name_x {
  font-size: 26px;
  display: inline-block;
  width: 200px;
  text-align: center;
  line-height: 26px;
  padding-top: 16px;
}

.common_header .logo_section .name_x .name {
  display: none;
}

.common_header .logo_section .name_o {
  font-size: 17px;
  display: inline-block;
  width: 200px;
  text-align: center;
  font-weight: bold;
  line-height: 24px;
  padding-top: 20px;
}

.common_header .logo_section .name_o .msg {
  font-size: 18px;
}

.common_header .logo_section .name_o .msg br {
  display: none;
}

.common_header .logo_section .name_o .name {
  font-size: 21px;
}

.common_header .logo_section .bg_birthday {
  position: absolute;
  top: 0;
  right: 0;
  height: 83px;
  width: 270px;
  background-image: url(../../img/vvip/ty_birthday.png);
  background-position: 0 0;
  background-repeat: no-repeat;
  animation: logo_bg2 3s steps(49) infinite;
  -webkit-animation: bg_birthday 3s steps(49) infinite;
  -ms-animation: bg_birthday 3s steps(49) infinite;
}

.common_header .logo_section.ie .bg_birthday {
  background: url(../../img/vvip/ty_birthday.gif) 0 0 no-repeat;
  animation: none;
  -webkit-animation: none;
  z-index: -1;
}

.common_header .logo_section h1.logo img {
  margin-left: -4px;
  vertical-align: top;
}

.common_header.other_site .logo_section {
  top: 24px;
  left: 296px;
  width: auto;
  height: auto;
}

.common_header.rank.other_site .logo_section {
  top: 28px;
  left: 210px;
}

.common_header.other_site .logo_section a {
  width: auto;
  height: auto;
}

.common_header.other_site .logo_section h1.logo img {
  margin-left: 0;
}

@keyframes bg_birthday {
  from {
    background-position: 0 0;
    width: 270px;
    height: 83px;
  }
  to {
    background-position: 0 -4067px;
    width: 270px;
    height: 83px;
  }
}
@-webkit-keyframes bg_birthday {
  from {
    background-position: 0 0;
    width: 270px;
    height: 83px;
  }
  to {
    background-position: 0 -4067px;
    width: 270px;
    height: 83px;
  }
}
@-ms-keyframes bg_birthday {
  from {
    background-position: 0 0;
    width: 270px;
    height: 83px;
  }
  to {
    background-position: 0 -4067px;
    width: 270px;
    height: 83px;
  }
}
/* 공통헤더 급상승검색어 */
.cmjump_rank {
  position: absolute;
  top: 26px;
  left: 720px;
  z-index: 31;
}

:lang(en) .cmjump_rank,
:lang(zh) .cmjump_rank {
  visibility: hidden;
  left: -100%;
}

.cmjump_rank .cmjump_totalrank {
  overflow: hidden;
  width: 145px;
  height: 36px;
  border: 2px solid #3a3a3a;
  border-left: 0 none;
  background-color: #fff;
}

.cmjump_rank .cmjump_totalrank:before {
  position: absolute;
  top: 2px;
  left: 0;
  width: 1px;
  height: 36px;
  background: #e8e8e8;
  content: "";
}

.cmjump_rank .cmjump_totalrank_tit {
  display: block;
  padding-left: 10px;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 14px;
  color: #222;
}

.cmjump_rank .cmjump_keyword_info {
  display: block;
  padding: 5px 0 8px 8px;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: -0.5px;
  color: #b1b1b1;
}

.cmjump_rank .cmjump_totalrank .cmjump_totalrank_cont {
  overflow: hidden;
}

.cmjump_rank .cmjump_rank_ico {
  display: inline-block;
  background: url(../../img/common_layout/sp_cmjump_rank_ico.png) no-repeat;
  vertical-align: top;
}

.cmjump_rank .cmjump_rank_ico {
  width: 11px;
  height: 10px;
  margin-top: 7px;
}

.cmjump_rank .cmjump_rank_up {
  color: var(--m-colors-primary, #ff5452);
}

.cmjump_rank .cmjump_rank_up .cmjump_rank_ico {
  width: 11px;
  height: 10px;
  background-position: 0 0;
}

.cmjump_rank .cmjump_rank_down {
  color: #343aa3;
}

.cmjump_rank .cmjump_rank_down .cmjump_rank_ico {
  background-position: 0 -50px;
}

.cmjump_rank .cmjump_rank_same .cmjump_rank_ico {
  background-position: 0 -100px;
  margin: 12px 10px 0 0;
}

.cmjump_rank .cmjump_rank_new {
  color: #000;
}

.cmjump_rank .cmjump_rank_new .cmjump_rank_ico {
  width: 23px;
  vertical-align: -2px;
  background-position: 0 -150px;
}

.cmjump_rank .cmjump_rank_link {
  display: block;
  overflow: hidden;
  position: relative;
  padding: 6px 45px 6px 8px;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 25px;
  color: #222;
  text-decoration: none;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.cmjump_rank .cmjump_rank_num {
  font-size: 14px;
  letter-spacing: -0.5px;
  vertical-align: top;
  color: #222;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
}

.cmjump_rank .cmjump_rank_state {
  position: absolute;
  top: 6px;
  right: 10px;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 500;
  font-size: 13px;
  line-height: 25px;
  letter-spacing: -0.5px;
  text-align: right;
}

.cmjump_rank .cmjump_lyr {
  display: none;
  position: absolute;
  top: -5px;
  left: -82px;
  z-index: 11;
  width: 230px;
  height: 370px;
  margin-top: 5px;
  border: solid 1px #d1d1d1;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.03);
  background-color: #fff;
}

.cmjump_rank .cmjump_lyr.on,
.cmjump_rank .cmjump_lyr_cont .cmjump_lyr_panel.on {
  display: block;
}

.cmjump_rank .cmjump_lyr_cont {
  padding: 15px 0 15px 15px;
}

.cmjump_rank .cmjump_lyr_cont .cmjump_rank_lst {
  padding-top: 5px;
}

.cmjump_rank .cmjump_lyr_cont .cmjump_rank_link {
  padding: 0 48px 0 0;
}

.cmjump_rank .cmjump_lyr_cont .cmjump_rank_item {
  display: block;
  width: 200px;
}

.cmjump_rank .cmjump_lyr_cont .cmjump_rank_num {
  font-size: 12px;
  letter-spacing: -0.3px;
  vertical-align: top;
  color: #888;
  padding-right: 5px;
}

.cmjump_rank .cmjump_lyr_cont .cmjump_rank_same .cmjump_rank_ico {
  margin-right: 0;
}

.cmjump_rank .cmjump_lyr_cont .cmjump_lyr_tab li {
  display: inline-block;
  width: 100px;
  height: 30px;
  margin-right: -3px;
}

.cmjump_rank .cmjump_lyr_cont .cmjump_lyr_tab li button {
  float: left;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
  color: #969696;
  width: 100px;
  height: 30px;
  line-height: 14px;
  text-align: center;
  vertical-align: middle;
  font-size: 12px;
  border: 1px solid #d8d8d8;
  background-color: #f4f4f4;
}

.cmjump_rank .cmjump_lyr_cont .cmjump_lyr_tab li.on button {
  color: #222;
  background-color: #fff;
}

.cmjump_rank .cmjump_lyr_cont .cmjump_rank_state {
  top: 0;
}

.cmjump_rank .cmjump_lyr_cont .cmjump_lyr_panel {
  display: none;
}

.cmjump_rank .cmjump_lyr_cont .cmjump_rank_item {
  display: block;
  width: 200px;
}

.cmjump_rank .cmjump_lyr_cont .cmjump_lyr_panel.on .cmjump_rank_item.bold .cmjump_rank_tx {
  font-weight: bold;
}

.other_site .cmjump_rank {
  top: 21px;
  left: 675px;
}

.other_site .cmjump_rank .cmjump_totalrank_tit {
  margin-top: 8px;
}

.other_site .cmjump_rank .cmjump_totalrank:before {
  position: absolute;
  top: 2px;
  left: 0;
  width: 1px;
  height: 30px;
  background: #e8e8e8;
  content: "";
}

.other_site .cmjump_rank .cmjump_totalrank {
  height: 30px;
}

.other_site .cmjump_rank .cmjump_totalrank .cmjump_rank_link {
  padding: 3px 45px 0 8px;
}

.other_site .cmjump_rank .cmjump_totalrank .cmjump_rank_state {
  top: 3px;
}

@media screen and (-webkit-min-device-pixel-ratio: 2) {
  .cmjump_rank .cmjump_rank_ico {
    background: url(../../img/common_layout/sp_cmjump_rank_ico_retina.png) no-repeat;
    background-size: 320px 240px;
  }
  .cmjump_rank .cmjump_rank_up .cmjump_rank_ico {
    background-position: 0 0;
  }
  .cmjump_rank .cmjump_rank_down .cmjump_rank_ico {
    background-position: 0 -27px;
  }
  .cmjump_rank .cmjump_rank_same .cmjump_rank_ico {
    background-position: 0 -52px;
  }
  .cmjump_rank .cmjump_rank_new .cmjump_rank_ico {
    background-position: 0 -77px;
  }
  .cmjump_rank .cmjump_lyr .cmjump_lyr_close:after {
    background-image: url(../../img/common_layout/sp_cm_jump_ico_set_retina.png);
    background-position: -72px 0px;
    width: 20px;
    height: 20px;
    background-size: 92px 65px;
  }
}
/* 몰탭 + 검색창 개편 (s)*/
.cmicon {
  display: inline-block;
  vertical-align: middle;
  font-size: 0;
  line-height: normal;
}
.cmicon .icon,
.cmicon .logo {
  display: inline-block\9 ;
}

.key_word_renew,
.auto_word.renew {
  border: solid 1px rgba(0, 0, 0, 0.07);
  border-top: 0;
}

.key_word_renew {
  overflow: hidden;
  padding-bottom: 15px;
  text-align: left;
  box-sizing: border-box;
  background: #fff;
  font-size: 13px;
}
.key_word_renew .tit_area {
  padding-top: 15px;
  color: #000;
  margin: 0 15px;
}
.key_word_renew .tit_area strong {
  font-weight: bold;
}
.key_word_renew .result_area {
  padding-top: 5px;
}
.key_word_renew .result_area li {
  position: relative;
  padding: 0 15px;
  line-height: 16px;
  margin-right: 1px;
}
.key_word_renew .result_area li.on, .key_word_renew .result_area li:hover {
  background: #fafafa;
}
.key_word_renew .result_area li a {
  display: block;
  overflow: hidden;
  padding: 5px 36px 5px 0;
  color: #777;
  letter-spacing: -0.03em;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}
.key_word_renew .result_area .btn_del {
  overflow: hidden;
  position: absolute;
  top: 50%;
  right: 15px;
  width: 19px;
  height: 19px;
  margin-top: -10px;
  line-height: 19px;
  background: none;
}
.key_word_renew .result_area .btn_del .icon_close {
  opacity: 0.4;
}
.key_word_renew .result_area .result_clear {
  padding-top: 10px;
  margin: 5px 15px 0;
  border-top: 1px solid #f0f0f0;
  text-align: right;
}
.key_word_renew .result_area .result_clear .btn_clear {
  font-size: 13px;
  color: #969696;
  background: none;
}
.key_word_renew .nodata {
  padding: 40px 0 45px;
  text-align: center;
  color: #777;
}

.auto_word.renew {
  position: relative;
  width: 100%;
  padding-top: 3px;
  top: 0;
  left: 0;
  text-align: left;
}
.auto_word.renew .word_shortcut {
  position: relative;
  margin: 0 15px;
  padding: 12px 15px 0;
  font-size: 13px;
  color: #000;
  background: #fff;
  white-space: nowrap;
  text-overflow: ellipsis;
  border: 0;
}
.auto_word.renew .word_shortcut a {
  padding: 12px 35px 12px 15px;
  border-radius: 4px;
  border: 1px solid #e5e5e5;
}
.auto_word.renew .word_shortcut a:after {
  content: none;
}
.auto_word.renew .word_shortcut a .icon_market {
  margin-right: 5px;
}
.auto_word.renew .word_shortcut:after {
  right: 15px;
}
.auto_word.renew .word_shortcut .word_shortcut_arrow {
  position: absolute;
  right: 15px;
  top: 50%;
  margin-top: -8px;
  width: 16px;
  height: 16px;
}
.auto_word.renew .word_list {
  padding: 12px 0;
  min-height: auto;
}
.auto_word.renew .word_list li {
  line-height: 26px;
}
.auto_word.renew .word_list li > a {
  padding: 0 72px 0 15px;
  color: #777;
}
.auto_word.renew .word_list li > a.match:after {
  content: none;
}
.auto_word.renew .word_list li > a .word_date {
  font-size: 13px;
}
.auto_word.renew .word_list li:hover {
  background: #fafafa;
}
.auto_word.renew .word_list li .ad_thmb {
  margin-top: 15px;
}
.auto_word.renew .word_list li .ad_detail .link {
  text-align: left;
  padding-top: 10px;
}
.auto_word.renew .word_list li .ad_detail .tx {
  color: #777;
}
.auto_word.renew .word_list li .ad {
  top: 1px;
  bottom: 2px;
  border-bottom-right-radius: 20px;
}
.auto_word.renew .word_list li .ad .link {
  border-radius: 4px;
  overflow: hidden;
}
.auto_word.renew .word_list li .ad:before {
  background: #f0f0f0;
  margin: 15px 0;
}
.auto_word.renew .word_list .word_lnk {
  display: inline-block;
  width: 16px;
  height: 16px;
  line-height: 16px;
  opacity: 0.6;
  margin: -3px 0 0 -5px;
}
.auto_word.renew.ad_showing .word_list li > a {
  padding-right: 240px;
}

.common_header.renew.v2 {
  position: relative;
  z-index: 400;
}
.common_header.renew.v2:after {
  display: block;
  clear: both;
  content: "";
}
.common_header.renew.v2 .common_header_inner {
  position: relative;
  z-index: 300;
  width: 1280px;
  margin: 0 auto;
  padding-top: 13px;
  height: 89px;
}
.common_header.renew.v2.sub_site {
  width: 100%;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.05);
}
.common_header.renew.v2.other_site {
  width: 100%;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.05);
}
.common_header.renew.v2.other_site .common_header_inner {
  height: 47px;
}
.common_header.renew.v2.other_site .gnb_renew_logo {
  top: 22px;
  margin: 0 15px 0 0;
}
.common_header.renew.v2.other_site .gnb_renew_mall_gate .btn_label {
  padding-top: 1px;
  width: 18px;
  height: 17px;
}
.common_header.renew.v2.other_site .gnb_renew_search {
  margin: 0 0 0 140px;
  text-align: left;
}
.common_header.renew.v2.other_site .gnb_renew_util {
  top: 22px;
  right: 178px;
}
.common_header.renew.v2.other_site .gnb_renew_util:before {
  position: absolute;
  right: -16px;
  top: -3px;
  width: 1px;
  height: 25px;
  background: #f0f0f0;
  content: "";
}
.common_header.renew.v2.other_site .gnb_renew_menu {
  top: 16px;
}
.common_header.renew.v2.other_site .gnb_searching_box .inp_txt {
  height: 26px;
}
.common_header.renew.v2.other_site .gnb_renew_mall_layer {
  top: -10px;
}
.common_header.renew.v2.other_site .cmjump_rank.renew {
  top: 6px;
}
.common_header.renew.v2.other_site .cmjump_rank.renew .cmjump_lyr {
  top: 2px;
  left: -1px;
}
.common_header.renew.v2.other_site .cmjump_rank.renew .cmjump_lyr .cmjump_totalrank .cmjump_rank_state {
  top: 7px;
}
.common_header.renew.v2 .auto_word.renew {
  position: static;
}
.common_header.renew.v2 .key_word_renew,
.common_header.renew.v2 .auto_word.renew {
  padding-top: 45px;
  border-radius: 4px;
  border: 0;
}
@media \0 screen {
  .common_header.renew.v2 .key_word_renew,
.common_header.renew.v2 .auto_word.renew {
    border: 1px solid #000;
  }
}
.common_header.renew.v2 .key_word_renew:before,
.common_header.renew.v2 .auto_word.renew:before {
  position: absolute;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  top: -1px;
  left: -1px;
  right: 0;
  bottom: 0;
  z-index: -1;
  box-sizing: border-box;
  padding: 1px;
  border-radius: 4px;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1 1' preserveAspectRatio='none'%3E%3Cdefs%3E%3ClinearGradient id='fh8cypk2ya' x1='5.138%25' x2='99.194%25' y1='50.219%25' y2='49.719%25'%3E%3Cstop offset='0%25' stop-color='%23FECC1F'/%3E%3Cstop offset='33.288%25' stop-color='%23FE3A6B'/%3E%3Cstop offset='55.717%25' stop-color='%23A33FFA'/%3E%3Cstop offset='77.639%25' stop-color='%233798FB'/%3E%3Cstop offset='100%25' stop-color='%2326D866'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect fill='url(%23fh8cypk2ya)' x='0' y='0' width='1' height='1'/%3E%3C/svg%3E%0A");
  background: var(--m-colors-ssg_brand, linear-gradient(270deg, #be3ffa 0%, #f43479 40.65%, #f43479 58.41%, #ff5452 100%));
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask-composite: exclude;
  content: "";
}
.common_header.renew.v2 .gnb_search_wrap.ty_mall .key_word_renew {
  padding-top: 28px;
}
.common_header.renew.v2 .gnb_search_wrap.ty_mall .auto_word.renew {
  padding-top: 28px;
}
.common_header.renew.v2 .gnb_search_wrap.ty_mall .auto_word.renew .icon_shortcut_lnk {
  right: 12px;
}

.cmjump_rank.renew {
  display: inline-block;
  top: 38px;
  left: auto;
  margin-left: 20px;
  text-align: left;
}
.cmjump_rank.renew .cmjump_rank_ico {
  width: 16px;
  height: 16px;
  margin-top: -2px;
  vertical-align: middle;
  background-image: none;
}
.cmjump_rank.renew .cmjump_totalrank {
  width: 155px;
  padding: 6px 0;
  border: none;
  background-color: transparent;
}
.cmjump_rank.renew .cmjump_totalrank:before {
  content: none;
}
.cmjump_rank.renew .cmjump_totalrank_cont .cmjump_rank_num {
  font-size: 15px;
  font-weight: bold;
  margin-right: 4px;
  color: #000;
}
.cmjump_rank.renew .cmjump_totalrank_cont .cmjump_rank_link {
  text-overflow: clip;
  padding: 6px 0;
  font-weight: bold;
  color: #000;
}
.cmjump_rank.renew .cmjump_totalrank_cont .cmjump_rank_link .cmjump_rank_tx {
  display: inline-block;
  white-space: nowrap;
  max-width: 125px;
  text-overflow: ellipsis;
  overflow: hidden;
  vertical-align: top;
  max-width: 125px;
}
.cmjump_rank.renew .cmjump_totalrank_cont .cmjump_rank_state {
  position: relative;
  right: 2px;
  top: 0;
  padding-right: 2px;
}
.cmjump_rank.renew .cmjump_totalrank_cont .cmjump_rank_state.cmjump_rank_new, .cmjump_rank.renew .cmjump_totalrank_cont .cmjump_rank_state.cmjump_rank_same {
  padding-left: 3px;
}
.cmjump_rank.renew .cmjump_lyr {
  width: 247px;
  height: 462px;
  top: -8px;
  left: 0px;
  border-radius: 8px;
  box-shadow: 6px 6px 8px 0 rgba(0, 0, 0, 0.1);
  border-color: rgba(0, 0, 0, 0.07);
}
.cmjump_rank.renew .cmjump_lyr_cont {
  padding: 20px;
}
.cmjump_rank.renew .cmjump_lyr_cont .cmjump_lyr_tab {
  position: relative;
  border-radius: 4px;
  border: solid 1px rgba(0, 0, 0, 0.07);
  box-sizing: border-box;
  height: 35px;
}
.cmjump_rank.renew .cmjump_lyr_cont .cmjump_lyr_tab:before {
  position: absolute;
  left: 50%;
  top: 11px;
  height: 13px;
  width: 1px;
  background-color: #f0f0f0;
  z-index: -1;
  content: "";
}
.cmjump_rank.renew .cmjump_lyr_cont .cmjump_lyr_tab li {
  width: 50%;
  height: 35px;
  font-size: 0;
}
.cmjump_rank.renew .cmjump_lyr_cont .cmjump_lyr_tab li button {
  width: 100%;
  height: 35px;
  border: 0;
  font-size: 13px;
  color: #777;
  background-color: transparent;
}
.cmjump_rank.renew .cmjump_lyr_cont .cmjump_lyr_tab li.on button {
  color: #222;
  font-weight: bold;
}
.cmjump_rank.renew .cmjump_lyr_cont .cmjump_rank_lst {
  padding-top: 15px;
}
.cmjump_rank.renew .cmjump_lyr_cont .cmjump_rank_state {
  top: -3px;
  right: 0;
  text-align: left;
  width: 40px;
}
.cmjump_rank.renew .cmjump_lyr_cont .cmjump_rank_item {
  width: 100%;
}
.cmjump_rank.renew .cmjump_totalrank_tit {
  padding: 0 0 15px 0;
  font-weight: bold;
}
.cmjump_rank.renew .cmjump_keyword_info {
  padding: 15px 0 0 0;
  border-top: 1px solid #f0f0f0;
  color: #969696;
  font-size: 13px;
  text-align: right;
}
.cmjump_rank.renew .cmjump_lyr_scont .cmjump_rank_item {
  padding-bottom: 15px;
}
.cmjump_rank.renew .cmjump_lyr_scont .cmjump_rank_link {
  line-height: 16px;
  color: #777;
  padding: 0 60px 0 0;
}
.cmjump_rank.renew .cmjump_lyr_scont .cmjump_rank_num {
  font-size: 13px;
  color: #000;
  font-weight: bold;
}
.cmjump_rank.renew .cmjump_rank_down {
  color: #22009c;
}
.cmjump_rank.renew .cmjump_rank_up {
  color: var(--m-colors-primary, #ff5452);
}

.gnb_renew_logo {
  position: absolute;
  top: 46px;
}
.gnb_renew_logo .gnb_logo_wrap {
  position: relative;
  display: inline-block;
}
.gnb_renew_logo .gnb_logo_wrap > h1 {
  display: inline-block;
  vertical-align: top;
}
.gnb_renew_logo .gnb_logo_wrap .gnb_logo_ssg .gnb_logo_lnk {
  display: block;
  vertical-align: middle;
}
.gnb_renew_logo .gnb_logo_wrap.is_open .gnb_renew_mall_gate .btn_label:before {
  transform: rotate(180deg);
}
.gnb_renew_logo .gnb_logo_wrap.is_open .gnb_renew_mall_gate .btn_label.ty_on {
  display: block;
}
.gnb_renew_logo .gnb_logo_wrap.is_open .gnb_renew_mall_gate .btn_label.ty_off {
  display: none;
}
.gnb_renew_logo .gnb_logo_wrap.is_open .gnb_renew_mall_layer {
  visibility: visible;
  animation: gnb-mall-layer 0.25s ease-out forwards;
}
.gnb_renew_logo .gnb_logo_wrap.is_close .gnb_renew_mall_layer {
  visibility: hidden;
  animation: gnb-mall-layer-close 0.2s ease-out forwards;
}
.gnb_renew_logo .gnb_renew_mall_gate {
  display: inline-block;
  box-sizing: border-box;
  text-align: center;
  background: #fff;
}
.gnb_renew_logo .gnb_renew_mall_gate .btn_label {
  display: inline-block;
  border-radius: 50%;
  border: solid 1px rgba(34, 34, 34, 0.1);
  padding-top: 5px;
  width: 26px;
  height: 21px;
}
.gnb_renew_logo .gnb_renew_mall_gate .btn_label.ty_on {
  display: none;
}
.gnb_renew_logo .gnb_renew_mall_gate .btn_label .cmicon {
  vertical-align: top;
}
.gnb_renew_logo .gnb_renew_mall_layer {
  position: absolute;
  overflow: hidden;
  top: -13px;
  left: -6px;
  z-index: 2100;
  width: 324px;
  padding: 20px 12px 12px;
  border-radius: 20px;
  border: solid 1px rgba(0, 0, 0, 0.03);
  box-sizing: border-box;
  box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  visibility: hidden;
}
.gnb_renew_logo .gnb_renew_mall_layer .gnb_mall_ssg {
  position: relative;
  padding-bottom: 12px;
  margin: 0 6px;
  border-bottom: 1px solid #f0f0f0;
}
.gnb_renew_logo .gnb_renew_mall_layer .gnb_mall_ssg .gnb_mall_ssglnk {
  position: relative;
  display: inline-block;
  line-height: 28px;
  height: 28px;
  margin: 0 16px 0 30px;
}
.gnb_renew_logo .gnb_renew_mall_layer .gnb_mall_ssg .gnb_mall_ssglnk:before {
  content: "";
  position: absolute;
  top: 50%;
  left: -30px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  overflow: hidden;
  transform: translateY(-50%);
  background: url(../../img/common/gnb_badge_ssg.jpg) center no-repeat;
  background-size: cover;
}
.gnb_renew_logo .gnb_renew_mall_layer .gnb_mall_ssg .gnb_renew_mall_gate {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin-top: -6px;
}
.gnb_renew_logo .gnb_renew_mall_layer .gnb_mall_lst:after {
  clear: both;
  display: block;
  content: "";
}
.gnb_renew_logo .gnb_renew_mall_layer .gnb_mall_lst > li {
  float: left;
  width: 50%;
  padding-top: 7px;
}
.gnb_renew_logo .gnb_renew_mall_layer .gnb_mall_lnk {
  display: inline-block;
  padding: 5px 6px;
  border-radius: 16px;
  text-decoration: none;
  white-space: nowrap;
}
.gnb_renew_logo .gnb_renew_mall_layer .gnb_mall_lnk.on {
  padding-right: 12px;
}
.gnb_renew_logo .gnb_renew_mall_layer .gnb_mall_lnk.on .gnb_mall_name {
  font-weight: bold;
}
.gnb_renew_logo .gnb_renew_mall_layer .gnb_mall_lnk.on .gnb_mall_bedge {
  display: none;
}
.gnb_renew_logo .gnb_renew_mall_layer .gnb_mall_name {
  overflow: hidden;
  display: inline-block;
  vertical-align: top;
  max-width: 108px;
  height: 20px;
  line-height: 20px;
  box-sizing: border-box;
  padding: 1px 0 0 3px;
  color: #222;
  font-size: 13px;
  letter-spacing: -0.43px;
}
.gnb_renew_logo .gnb_renew_mall_layer .gnb_mall_thumb {
  overflow: hidden;
  display: inline-block;
  vertical-align: top;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
.gnb_renew_logo .gnb_renew_mall_layer .gnb_mall_thumb img {
  width: 100%;
  vertical-align: middle;
}
.gnb_renew_logo .gnb_renew_mall_layer .gnb_mall_bedge {
  display: inline-block;
  vertical-align: top;
  height: 15px;
  line-height: 15px;
  padding: 0 3px 0 3px;
  margin: 3px 0 0 2px;
  border: solid 1px transparent;
  border-radius: 3px;
  box-sizing: border-box;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 500;
  font-size: 9px;
  text-align: center;
  /* 백화점 개편 : 오픈 딱지 임시 수정(s) */
}
.gnb_renew_logo .gnb_renew_mall_layer .gnb_mall_bedge.ty_ad {
  color: #969696;
  border-color: #e5e5e5;
}
.gnb_renew_logo .gnb_renew_mall_layer .gnb_mall_bedge.ty_open {
  overflow: hidden;
  color: transparent;
  max-width: 34px;
  color: #fff;
  border-color: var(--m-colors-primary, #ff5452);
  background-color: var(--m-colors-primary, #ff5452);
}
.gnb_renew_logo .gnb_renew_mall_layer .gnb_mall_bedge.ty_open:before {
  content: "NEW";
  display: block;
  color: #fff;
}
.gnb_renew_logo .gnb_renew_mall_layer .gnb_mall_bedge.ty_icon_txt {
  max-width: 34px;
  color: #fff;
  border-color: var(--m-colors-primary, #ff5452);
  background-color: var(--m-colors-primary, #ff5452);
}

.gnb_renew_search {
  margin: 23px auto 0;
  text-align: center;
}

.gnb_search_wrap {
  position: relative;
  display: inline-block;
  text-align: left;
  width: 500px;
  height: 48px;
}
@media \0 screen {
  .gnb_search_wrap {
    border: 1px solid #000;
  }
}
.gnb_search_wrap:before {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 1px;
  border-radius: 4px;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1 1' preserveAspectRatio='none'%3E%3Cdefs%3E%3ClinearGradient id='fh8cypk2ya' x1='5.138%25' x2='99.194%25' y1='50.219%25' y2='49.719%25'%3E%3Cstop offset='0%25' stop-color='%23FECC1F'/%3E%3Cstop offset='33.288%25' stop-color='%23FE3A6B'/%3E%3Cstop offset='55.717%25' stop-color='%23A33FFA'/%3E%3Cstop offset='77.639%25' stop-color='%233798FB'/%3E%3Cstop offset='100%25' stop-color='%2326D866'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect fill='url(%23fh8cypk2ya)' x='0' y='0' width='1' height='1'/%3E%3C/svg%3E%0A");
  background: var(--m-colors-ssg_brand, linear-gradient(270deg, #be3ffa 0%, #f43479 40.65%, #f43479 58.41%, #ff5452 100%));
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask-composite: exclude;
  background-size: 300%, 300%;
  background-size: contain\9 ;
  animation: animatedgradient 5s ease alternate infinite;
  content: "";
}
.gnb_search_wrap.ty_mall {
  width: 218px;
  height: 30px;
  top: 2px;
}
.gnb_search_wrap.ty_mall .gnb_search_btn {
  width: 20px;
  height: 20px;
  right: 3px;
  top: 3px;
}
.gnb_search_wrap.ty_mall .gnb_searching_inp .inp_txt {
  padding-left: 11px;
  width: calc(100% - 31px);
}
.gnb_search_wrap.ty_mall .gnb_searching_inp .inp_txt::placeholder {
  font-size: 12px;
}
.gnb_search_wrap .key_word_renew .tit_area {
  border-top: 1px solid #f0f0f0;
}

.gnb_searching_box {
  position: relative;
  overflow: hidden;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  margin-top: 1px;
  margin-left: 1px;
  border-radius: 4px;
  font-size: 0;
  background: #fff;
  z-index: 300;
}
.gnb_searching_box .inp_txt {
  padding-left: 16px;
  margin-top: 1px;
  width: calc(100% - 16px);
  height: 45px;
  min-height: 26px;
  border: 0;
  font-size: 13px;
  letter-spacing: -1px;
  line-height: 45px\9 ;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
}
.gnb_searching_box .inp_txt::-ms-clear {
  display: none;
}
.gnb_searching_box .inp_txt:focus {
  outline: none;
}
.gnb_searching_box .gnb_searching_inp .inp_txt {
  padding-left: 16px;
  /* IE */
  /* Firefox */
}
.gnb_searching_box .gnb_searching_inp .inp_txt::placeholder {
  color: #777;
  font-size: 14px;
  opacity: 1; /* 파이어폭스에서 뿌옇게 나오는 현상을 방지하기 위한 css */
}
.gnb_searching_box .gnb_searching_inp .inp_txt:-webkit-input-placeholder {
  color: #777;
  font-size: 14px;
}
.gnb_searching_box .gnb_searching_inp .inp_txt:-ms-input-placeholder {
  color: #777;
  font-size: 14px;
}
.gnb_searching_box .gnb_searching_inp .inp_txt:-mos-input-placeholder {
  color: #777;
  font-size: 14px;
}
.gnb_searching_box .gnb_search_btn {
  position: absolute;
  width: 28px;
  height: 28px;
  right: 8px;
  top: 8px;
  background: #fff;
}

.gnb_search_word {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  background: #fff;
  box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.1);
  z-index: 200;
}

.gnb_renew_util {
  position: absolute;
  right: 0;
  z-index: 20;
  width: auto;
  top: 12px;
  display: flex;
  align-items: center;
}
.gnb_renew_util:after {
  display: block;
  clear: both;
  content: "";
}
.gnb_renew_util .gnb_util {
  float: right;
}
.gnb_renew_util .gnb_util:after {
  display: block;
  clear: both;
  content: "";
}
.gnb_renew_util .gnb_util li {
  float: left;
  margin-right: 3px;
}
.gnb_renew_util .gnb_util li:not(:first-child):before {
  display: inline-block;
  margin-right: 3px;
  color: #969696;
  content: "·";
}
.gnb_renew_util .gnb_util li a {
  line-height: 19px;
  letter-spacing: -0.5px;
  text-decoration: none;
  font-size: 12px;
  color: #777;
}
.gnb_renew_util .gnb_util li.gnb_tx_user {
  margin-right: 16px;
}
.gnb_renew_util .gnb_util li.gnb_tx_user + li:before {
  content: none;
}
.gnb_renew_util .gnb_util li.gnb_tx_user a {
  font-weight: bold;
}
.gnb_renew_util .gnb_mmbrs.ty_smileclub .gnb_mmbrs_txt {
  color: #966e46;
}
.gnb_renew_util .gnb_mmbrs .gnb_mmbrs_link {
  display: flex;
  align-items: center;
}
.gnb_renew_util .gnb_mmbrs .gnb_mmbrs_link::after {
  content: "";
  display: block;
  width: 1px;
  height: 10px;
  margin: 0 8px;
  background-color: #e5e5e5;
}
.gnb_renew_util .gnb_mmbrs .gnb_mmbrs_new {
  font-family: "Helvetica", "Arial", sans-serif;
  font-weight: 700;
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px 1px 0 1px;
  width: 12px;
  height: 13px;
  line-height: 13px;
  border-radius: 16px;
  color: #fff;
  background: linear-gradient(57.38deg, #fecc1f 6.24%, #fe3a6b 40.38%, #a33ffa 57.32%, #3798fb 76.49%, #26d866 92.2%);
}
.gnb_renew_util .gnb_mmbrs .gnb_mmbrs_txt {
  padding: 0 4px;
  font-weight: bold;
  font-size: 12px;
  height: 20px;
  line-height: 22px;
  color: #222;
}

.gnb_renew_menu {
  position: absolute;
  top: 52px;
  right: 0;
}
.gnb_renew_menu .gnb_menu {
  z-index: 41;
}
.gnb_renew_menu .gnb_menu:after {
  display: block;
  clear: both;
  content: "";
}
.gnb_renew_menu .gnb_menu > li {
  float: left;
  position: relative;
  margin-left: 12px;
}
.gnb_renew_menu .gnb_menu .gnb_util_num {
  position: absolute;
  top: -4px;
  left: 50%;
  min-width: 12px;
  margin-left: 3px;
  padding: 0 3px;
  background: var(--m-colors-primary, #ff5452);
  border-radius: 9px;
  font-size: 11px;
  line-height: 19px;
  height: 18px;
  color: #fff;
  text-align: center;
}

@keyframes animatedgradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-webkit-keyframes gnb-mall-layer {
  0% {
    opacity: 1;
    height: 0;
  }
  100% {
    visibility: visible;
    height: inherit;
  }
}
@keyframes gnb-mall-layer {
  0% {
    opacity: 1;
    height: 0;
  }
  100% {
    visibility: visible;
    height: inherit;
  }
}
@-webkit-keyframes gnb-mall-layer-close {
  0% {
    opacity: 1;
    height: inherit;
    visibility: visible;
  }
  80% {
    opacity: 1;
  }
  100% {
    visibility: hidden;
    height: 0;
    opacity: 0;
  }
}
@keyframes gnb-mall-layer-close {
  0% {
    opacity: 1;
    height: inherit;
    visibility: visible;
  }
  80% {
    opacity: 1;
  }
  100% {
    visibility: hidden;
    height: 0;
    opacity: 0;
  }
}
.body_wide .gnb_mall_lnk.on {
  background-color: #f5f5f5;
}

.body_sm .gnb_mall_lnk.on {
  background-color: #f12e24;
}
.body_sm .gnb_mall_lnk.on .gnb_mall_name {
  color: #fff;
}

.body_sd .gnb_mall_lnk.on {
  background-color: #6a6b6d;
}
.body_sd .gnb_mall_lnk.on .gnb_mall_name {
  color: #fff;
}

.body_em .gnb_mall_lnk.on {
  background-color: #ffd040;
}

.body_traders .gnb_mall_lnk.on {
  background-color: #a6dd27;
}

.body_morning .gnb_mall_lnk.on {
  background-color: #8097af;
}

.body_mnstarbucks .gnb_mall_lnk.on {
  background-color: #037042;
}
.body_mnstarbucks .gnb_mall_lnk.on .gnb_mall_name {
  color: #fff;
}

.body_mncasamia .gnb_mall_lnk.on {
  background-color: #f5f5f5;
}

.body_mnchicor .gnb_mall_lnk.on {
  background-color: #000;
}
.body_mnchicor .gnb_mall_lnk.on .gnb_mall_name {
  color: #fff;
}

.body_howdy .gnb_mall_lnk.on {
  background-color: #000;
}
.body_howdy .gnb_mall_lnk.on .gnb_mall_name {
  color: #fff;
}

.body_tv .gnb_mall_lnk.on {
  background: linear-gradient(90deg, #ff329b 0%, #fc5653 59.9%, #ff9100 100%);
}
.body_tv .gnb_mall_lnk.on .cm_mall_ic > i {
  background-image: none;
}
.body_tv .gnb_mall_lnk.on .cm_mall_ic > i::after {
  background-image: url('data:image/svg+xml;utf-8,<svg width="26" height="16" viewBox="0 0 26 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M25.9999 14.9004H15.3276V0.5H17.9056V12.6199H25.9999V14.9004Z" fill="white"/><path d="M4.60391 6.34812C3.47165 6.01242 2.94529 5.37807 2.94529 4.54229C2.94529 3.4611 4.06819 2.6068 5.89525 2.6068C7.69892 2.6068 9.00196 3.55834 9.47217 5.04468L11.6946 4.46126C10.8992 1.78029 8.57853 0.5 5.76659 0.5C2.37915 0.5 0.472553 2.50494 0.472553 4.59554C0.472553 6.36665 1.35684 7.53581 2.7558 8.14701C4.16177 8.76053 5.84846 8.85777 7.2942 9.21662C8.6838 9.56158 9.48153 10.1612 9.48153 11.1753C9.48153 12.4417 8.19955 13.3376 6.32336 13.3376C4.50332 13.3376 2.90551 12.319 2.31131 10.5548L0 11.1822C0.624616 13.9951 3.20028 15.5 6.314 15.5C9.8956 15.5 11.9449 13.4325 11.9449 11.0965C11.9449 9.20273 10.8197 7.91781 9.10956 7.32513C7.47901 6.76022 5.89057 6.73013 4.60391 6.34812Z" fill="white"/></svg>');
  background-position: center;
  background-size: 16px;
}
.body_tv .gnb_mall_lnk.on .gnb_mall_name {
  color: #fff;
}

.body_siv .gnb_mall_lnk.on {
  background-color: #f5f5f5;
}

.body_outlet .gnb_mall_lnk.on {
  background-color: #d9117c;
}
.body_outlet .gnb_mall_lnk.on .gnb_mall_name {
  color: #fff;
}

.body_mntrip .gnb_mall_lnk.on {
  background-color: #328ca8;
}
.body_mntrip .gnb_mall_lnk.on .gnb_mall_name {
  color: #fff;
}

.body_sf .gnb_mall_lnk.on {
  background-color: #b12536;
}
.body_sf .gnb_mall_lnk.on .gnb_mall_name {
  color: #fff;
}

/* 몰탭 + 검색창 개편 (e) */
/* 검색박스 */
.common_header .main_title {
  position: absolute;
  top: 22px;
  left: 0;
  z-index: 30;
}

.common_header .searching_box {
  position: absolute;
  top: 0;
  left: 456px;
  width: 350px;
  font-size: 0;
  background: #fff;
}

.common_header.rank .main_title .searching_box {
  left: 392px;
  width: 340px;
  top: 4px;
}

.common_header .searching_box div {
  float: left;
  width: 249px;
  height: 36px;
  border: 2px solid #3a3a3a;
  border-left: none;
}

.common_header.rank .main_title .searching_box div {
  width: 239px;
}

.common_header.rank .main_title .searching_box .searching_select {
  width: 97px;
}

.common_header .searching_box input {
  float: left;
}

.common_header .searching_box .inp_txt {
  margin-top: 1px;
  padding-left: 8px;
  height: 34px;
  border: 0;
  font-size: 13px;
  letter-spacing: -1px;
  line-height: 34px\9 ;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
}

.common_header .searching_box .inp_txt::-ms-clear {
  display: none;
}

.common_header .searching_box .search_hash {
  float: left;
  width: 18px;
  height: 100%;
  background: url(../../img/common_layout/btn_hash.png) no-repeat 100% 50%;
}

.common_header .searching_box .search_btn {
  float: left;
  width: 30px;
  height: 100%;
  background: url(../../img/common_layout/btn_search.png) no-repeat 50% 50%;
}

.common_header .searching_box.search_inp_tx .search_btn {
  background: url(../../img/common_layout/btn_search_white.png) no-repeat 50% 50% #3a3a3a;
}

.common_header .searching_box .searching_select {
  width: 97px;
  height: 36px;
  background: url(../../img/common_layout/bg_search_new.png) no-repeat 100% 50%;
  border: 2px solid #3a3a3a;
  border-right: none;
  font-size: 12px;
}

.common_header .searching_box .searching_select .ss_default {
  display: block;
  padding-left: 10px;
  color: #2b2b2b;
  line-height: 36px;
  text-decoration: none;
}

.common_header .searching_box .searching_select .ss_option {
  display: none;
}

.common_header .searching_box .searching_select .ss_option.active {
  display: block;
  position: absolute;
  z-index: 10;
  top: 100%;
  left: 1px;
  width: 96px;
  margin-top: -1px;
  padding-top: 7px;
  padding-bottom: 3px;
  border: 1px solid #3a3a3a;
  background: #fff;
}

.common_header .searching_box .searching_select .ss_option.active li a {
  display: block;
  padding-left: 9px;
  line-height: 23px;
  color: #2b2b2b;
  text-decoration: none;
}

.common_header .searching_box .searching_select .ss_option.active li a:hover {
  font-weight: bold;
}

.common_header .searching_box.hash_tag div {
  width: 346px;
  border-left: 2px solid #3a3a3a;
}

.common_header.rank .searching_box.hash_tag div {
  width: 326px;
  border-left: 2px solid #3a3a3a;
}

.common_header .searching_box.hash_tag .search_btn {
  float: right;
}

.common_header .searching_box.hash_tag .inp_txt {
  font-size: 12px;
  letter-spacing: 0;
}

.common_header .searching_box.hash_tag .inp_txt:-ms-input-placeholder {
  color: #888;
  letter-spacing: -0.05em;
}

.common_header .searching_box.hash_tag .inp_txt::-webkit-input-placeholder {
  color: #888;
  letter-spacing: -0.05em;
}

.common_header .searching_box.hash_tag .inp_txt:focus:-moz-placeholder {
  color: transparent;
}

.common_header .searching_box.hash_tag .inp_txt:focus::-moz-placeholder {
  color: transparent;
}

.common_header .searching_box.hash_tag .inp_txt:focus::-webkit-input-placeholder {
  color: transparent;
}

.common_header .searching_box.hash_tag .search_hash {
  width: 22px;
}

.common_header.other_site .main_title {
  top: 17px;
}

.common_header.other_site .searching_box {
  left: 431px;
}

.common_header.other_site.rank .main_title .searching_box {
  left: 347px;
}

.common_header.other_site .searching_box div {
  height: 30px;
}

.common_header.other_site .searching_box .inp_txt {
  height: 28px;
  line-height: 28px\9 ;
}

.common_header.other_site .searching_box .searching_select .ss_default {
  line-height: 30px;
}

.common_header.other_site .searching_box.hash_tag .search_hash {
  width: 22px;
}

/* 검색박스(이몰) */
.emall_header_wide .emall_sch .ip_search .search_hash {
  float: left;
  width: 20px;
  height: 100%;
  background: url(../../img/common_layout/btn_hash.png) no-repeat 50% 50%;
}

.emall_header_wide .emall_sch .btn_search {
  float: right;
  width: 35px;
  height: 31px;
  background: url(../../img/common_layout/btn_search_white.png) 50% 50% no-repeat #3a3a3a;
}

.emall_header_wide .emall_sch.hash_tag .btn_search {
  background-image: url(../../img/common_layout/btn_search_gray.png);
}

.emall_header_wide .emall_sch.hash_tag.search_inp_tx .btn_search {
  background-image: url(../../img/common_layout/btn_search_white.png);
}

/* 검색박스(신몰) */
#header_sm .search .search_hash {
  width: 20px;
  height: 100%;
  background: url(../../img/common_layout/btn_hash.png) no-repeat 50% 50%;
}

#header_sm .search .search_btn {
  width: 38px;
  height: 36px;
  border: 2px solid #f12e24;
  border-left: 0;
  border-radius: 0 50% 50% 0;
  background: url(../../img/common_layout/btn_search.png) 5px 50% no-repeat;
}

/* 검색박스(레티나 대응) */
@media screen and (-webkit-min-device-pixel-ratio: 2) {
  /* 검색박스(레티나 대응 - ssg) */
  .common_header .searching_box .search_hash {
    background-image: url(../../img/common_layout/btn_hash@2x.png);
    background-size: 13px 16px;
  }
  .common_header .searching_box .search_btn {
    background-image: url(../../img/common_layout/btn_search@2x.png);
    background-size: 16px 16px;
  }
  .common_header .searching_box.search_inp_tx .search_btn {
    background-image: url(../../img/common_layout/btn_search_white@2x.png);
    background-size: 16px 16px;
  }
  /* 검색박스(레티나 대응 - 이몰) */
  .emall_header_wide .emall_sch .ip_search .search_hash {
    background: url(../../img/common_layout/btn_hash@2x.png) no-repeat 50% 50%;
    background-size: 13px 16px;
  }
  .emall_header_wide .emall_sch .btn_search {
    background-image: url(../../img/common_layout/btn_search_white@2x.png);
    background-size: 16px 16px;
  }
  .emall_header_wide .emall_sch.hash_tag .btn_search {
    background-image: url(../../img/common_layout/btn_search_gray@2x.png);
    background-size: 16px 16px;
  }
  .emall_header_wide .emall_sch.hash_tag.search_inp_tx .btn_search {
    background-image: url(../../img/common_layout/btn_search_white@2x.png);
    background-size: 16px 16px;
  }
  /* 검색박스(레티나 대응 - 신몰) */
  #header_sm .search .search_hash {
    background-image: url(../../img/common_layout/btn_hash@2x.png);
    background-size: 13px 16px;
  }
  #header_sm .search .search_btn {
    background-image: url(../../img/common_layout/btn_search@2x.png);
    background-size: 16px 16px;
  }
}
/* 자동완성 */
.com_search_word {
  position: absolute;
  top: 32px;
  left: 430px;
  width: 346px;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
  font-size: 13px;
  color: #222;
}

.auto_word {
  display: none;
  position: absolute;
  top: 1px;
  left: -29px;
  right: 0;
  width: 440px;
  border: 1px solid #d1d1d1;
  background: #fff;
  box-sizing: border-box;
  z-index: 1;
}

.other_site .auto_word {
  top: 0;
  left: -49px;
}

.auto_word .word_shortcut {
  margin: 0 14px;
  border-bottom: 1px solid #d8d8d8;
}

.auto_word .word_shortcut a {
  display: block;
  position: relative;
  overflow: hidden;
  margin: 0 -14px;
  padding: 12px 24px 10px 14px;
  color: #222;
  font-weight: bold;
  letter-spacing: -0.5px;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.auto_word .word_shortcut a:after {
  position: absolute;
  top: 50%;
  right: 10px;
  margin-top: -7px;
  color: #4c4c4c;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 15px;
  content: ">";
}

.auto_word .word_list {
  position: relative;
  min-height: 276px;
  padding: 10px 0;
}

.auto_word .word_list li {
  line-height: 25px;
}

.auto_word .word_list li > a {
  display: block;
  overflow: hidden;
  position: relative;
  padding: 0 170px 0 14px;
  color: #222;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.auto_word .word_list li > a font {
  font-size: 13px !important;
}

.auto_word .word_list li > a.match:after {
  margin-left: 3px;
  color: #969696;
  font-weight: bold;
  font-size: 15px;
  vertical-align: middle;
  content: ">";
}

.auto_word .word_list li > a .word_date,
.auto_word .tag_list li > a .word_date {
  position: absolute;
  top: 0;
  right: 19px;
  color: #969696;
  line-height: inherit;
}

.auto_word.ad_showing .word_list li > a,
.auto_word.ad_showing .tag_list li > a {
  padding-right: 240px;
}

.auto_word.ad_showing .word_list li > a .word_date,
.auto_word.ad_showing .tag_list li > a .word_date {
  right: 200px;
}

.auto_word .word_list li strong {
  font-weight: normal;
  color: #fc4248;
}

.auto_word .word_list li .ad {
  display: none;
  position: absolute;
  top: 1px;
  bottom: 0;
  right: 0;
  width: 180px;
  background: #fff;
  z-index: 1;
}

.auto_word .word_list li .ad:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  width: 1px;
  background: #e5e5e5;
  content: "";
}

.auto_word .word_list li .ad .link {
  display: block;
  color: #222;
  text-decoration: none;
}

.auto_word .word_list li .ad_thmb {
  position: relative;
  margin: 34px 20px 0;
  *zoom: 1;
}

.auto_word .word_list li .ad_thmb img {
  width: 100%;
  vertical-align: top;
}

.auto_word .word_list li .ad_thmb .tt_adinfo_n {
  left: 0;
  bottom: 0;
  width: 45px;
  height: 20px;
}

.auto_word .word_list li .ad_thmb .tt_adinfo_n .btn_tt_adinfo {
  width: 45px;
  height: 20px;
  background: #fbfcfc url(../../img/common/ico_adinfo_s.png) no-repeat -3px 1px;
}

.auto_word .word_list li .ad_detail {
  margin: 0 20px;
}

.auto_word .word_list li .ad_detail .link {
  padding: 5px 0;
  font-size: 12px;
  line-height: 17px;
  text-align: center;
}

.auto_word .word_list li .ad_detail .tx {
  display: block;
  display: -webkit-box;
  overflow: hidden;
  max-height: 34px;
  word-break: break-all;
  word-wrap: break-word;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  *zoom: 1;
}

.auto_word .word_list li .ad_detail .price {
  display: block;
  margin-top: 5px;
}

.auto_word .word_list li .ad_detail .price em {
  font-weight: bold;
  font-size: 13px;
  vertical-align: -1px;
}

.auto_word .word_list li.on,
.auto_word .word_list li:hover {
  background: #f4f5f8;
}

.auto_word .word_list li.on .ad,
.auto_word .word_list li:hover .ad {
  display: block;
}

.auto_word .word_plan {
  margin: 0 14px;
  padding: 15px 0;
  border-top: 1px solid #d8d8d8;
}

.auto_word .word_plan li {
  position: relative;
  margin-top: 6px;
}

.auto_word .word_plan li:first-child {
  margin-top: 0;
}

.auto_word .word_plan li a {
  display: block;
  line-height: 22px;
  color: #222;
  text-decoration: none;
  cursor: pointer;
  zoom: 1;
}

.auto_word .word_plan li a:after {
  display: block;
  clear: both;
  content: "";
}

.auto_word .word_plan li .plan_tit {
  position: absolute;
  top: 50%;
  left: 0;
  width: 49px;
  height: 20px;
  margin-top: -11px;
  border: 1px solid #222;
  color: #222;
  font-size: 11px;
  line-height: 22px;
  text-align: center;
}

.auto_word .word_plan li .plan_txt {
  display: block;
  overflow: hidden;
  padding-left: 61px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.auto_word .tag_list {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px 0 15px;
  border-top: 1px solid #e5e5e5;
  background: #fff;
}

.auto_word .tag_list:before {
  position: absolute;
  top: -11px;
  left: 0;
  width: 100%;
  height: 10px;
  background: #fff;
  content: "";
}

.auto_word .tag_list li {
  line-height: 25px;
}

.auto_word .tag_list li a {
  display: block;
  overflow: hidden;
  position: relative;
  padding: 0 14px;
  color: #222;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
}

.auto_word .tag_list li strong {
  font-weight: normal;
  color: #fc4248;
}

.auto_word .tag_list li.on a,
.auto_word .tag_list li a:hover {
  background: #f4f5f8;
}

.key_word {
  display: none;
  position: absolute;
  top: 1px;
  left: 0;
  right: 0;
  min-height: 206px;
  border: 1px solid #d1d1d1;
  background: #fff;
  z-index: 1;
  zoom: 1;
}

.common_header.other_site .key_word {
  top: 0;
}

.key_word:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  z-index: 10;
  width: 1px;
  background: #d1d1d1;
  content: "";
}

.key_word:after {
  display: block;
  clear: both;
  content: "";
}

.key_word .recent_list {
  float: left;
  width: 50%;
  z-index: 20;
}

.key_word .often_list {
  float: left;
  width: 50%;
  z-index: 10;
}

.key_word .nodata {
  position: absolute;
  top: 34px;
  bottom: 0;
  width: 50%;
  text-align: center;
}

.key_word .recent_list .nodata {
  left: 0;
}

.key_word .often_list .nodata {
  left: 50%;
}

.key_word .nodata p {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  margin-top: -18px;
  font-size: 13px;
  line-height: 18px;
  vertical-align: middle;
}

.key_word .tit_area {
  position: relative;
  height: 35px;
  border-bottom: 1px solid #e5e5e5;
  line-height: 35px;
  text-align: center;
  z-index: 10;
}

.key_word .tit_area strong {
  display: inline-block;
  overflow: hidden;
  max-width: 90%;
  color: #000;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
}

.key_word .result_area {
  position: relative;
  padding: 0 0 31px;
}

.key_word .result_area ul {
  overflow: hidden;
  padding: 8px 0 18px;
  height: 264px;
}

.key_word .result_area li {
  position: relative;
  padding: 0 29px 0 10px;
  line-height: 25px;
}

.key_word .often_list .result_area li {
  padding-right: 45px;
}

.key_word .result_area li a {
  display: block;
  overflow: hidden;
  padding-left: 5px;
  color: #000;
  letter-spacing: -0.03em;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}

.key_word .result_area li.on,
.key_word .result_area li:hover {
  background: #f4f5f8;
}

.key_word .result_area li.on a,
.key_word .result_area li:hover a {
  color: #fc4248;
}

.key_word .result_area .num {
  position: absolute;
  top: 50%;
  right: 15px;
  width: 30px;
  height: 26px;
  margin-top: -13px;
  font-size: 12px;
  color: #9e9e9e;
  text-align: right;
  white-space: nowrap;
}

.key_word .result_area .btn_del {
  overflow: hidden;
  position: absolute;
  top: 50%;
  right: 10px;
  width: 19px;
  height: 19px;
  margin-top: -10px;
  background: url(../../img/display/search/ico_del.gif) 50% 50% no-repeat;
  line-height: 999px;
}

.key_word .btn_clear {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  width: 100%;
  height: 30px;
  border-top: 1px solid #f5f5f5;
  background: #fbfbfb;
  color: #888;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
  font-size: 11px;
  line-height: 31px;
}

.key_word .btn_clear span {
  position: relative;
}

.key_word .ssg-tooltip-wrap {
  display: inline-block;
  *display: inline;
  zoom: 1;
  position: relative;
  height: 17px;
  line-height: 17px;
  text-align: left;
  vertical-align: middle;
}

.key_word .ssg-tooltip {
  display: inline-block;
  overflow: hidden;
  width: 17px;
  height: 17px;
  background: url(../../img/display/search/ico_qmark.png) no-repeat;
  line-height: 999px;
  vertical-align: top;
}

.key_word .ssg-tooltip-layer {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 185px;
  padding: 18px 20px 15px 15px;
  border: 1px solid #3a3a3a;
  background: #fff;
  line-height: 20px;
  word-break: break-all;
  word-wrap: break-word;
}

.key_word .recomm_list {
  position: absolute;
  top: -1px;
  right: 100%;
  width: 344px;
  border: 1px solid #d1d1d1;
  background: #fff;
  z-index: 30;
}

.key_word .item_area {
  position: relative;
  height: 307px;
  padding: 4px 10px 10px;
}

.key_word .cmitem_list {
  margin-left: -26px;
  zoom: 1;
}

.key_word .cmitem_list:after {
  display: block;
  clear: both;
  content: "";
}

.key_word .cmitem_bx {
  float: left;
  width: 90px;
  height: 139px;
  margin: 13px 0 0 26px;
}

.key_word .cmitem_link {
  display: block;
  padding: 5px 0 8px;
  color: #222;
  text-decoration: none;
  cursor: pointer;
  zoom: 1;
}

.key_word .cmitem_thmb {
  display: block;
  width: 70px;
  margin: 0 auto;
}

.key_word .cmitem_thmb img {
  vertical-align: top;
}

.key_word .cmitem_tx {
  display: block;
  margin-top: 8px;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.03em;
  text-align: center;
}

.key_word .cmitem_tx .tx {
  display: block;
  overflow: hidden;
  max-height: 30px;
}

.key_word .cmitem_tx .price {
  display: block;
  margin-top: 3px;
}

/* 자동완성(SSG) */
.common_header.other_site .com_search_word {
  top: 28px;
  left: 402px;
}

.common_header .com_search_word {
  top: 38px;
  left: 456px;
  width: 350px;
}

.common_header.rank .com_search_word {
  top: 43px;
  left: 456px;
  width: 350px;
}

.common_header.other_site .com_search_word {
  top: 32px;
  left: 431px;
}

.common_header.rank.other_site .com_search_word {
  top: 38px;
  left: 431px;
}

/* 자동완성(신몰) */
#header_sm .com_search_word {
  top: 59px;
  left: 263px;
  width: 294px;
}

#header_sm .auto_word {
  top: 2px;
}

#header_sm .auto_word:before {
  width: 100%;
}

#header_sm .key_word .recomm_list {
  width: 317px;
}

#header_sm .key_word .cmitem_list {
  margin-left: -13px;
}

#header_sm .key_word .cmitem_bx {
  margin-left: 13px;
}

/* 자동완성(신백) */
#header_sd .com_search_word {
  top: 59px;
  left: 205px;
  width: 350px;
  z-index: 50;
}

#header_sd .auto_word {
  top: 1px;
  left: 0;
}

#header_sd .auto_word:before {
  display: none;
}

#header_sd .key_word {
  top: 1px;
  border-top: 1px solid #d1d1d1;
}

#header_sd .key_word .cmitem_list {
  margin-left: -27px;
}

#header_sd .key_word .cmitem_bx {
  margin-left: 27px;
}

/* 자동완성(이/트) */
.emall_header_wide .com_search_word {
  top: 58px;
  left: 415px;
  width: 350px;
}

.emall_header_wide .auto_word {
  width: inherit;
  left: 0;
}

.emall_header_wide .key_word .item_area {
  height: 278px;
  padding-top: 8px;
  padding-bottom: 35px;
}

.emall_header_wide .key_word .cmitem_bx {
  margin-top: 0;
}

.traders_header_wide .com_search_word {
  top: 59px;
  left: 456px;
  width: 350px;
}

.traders_header_wide .auto_word {
  border-top: 1px solid #d1d1d1;
  left: 0;
}

.traders_header_wide .auto_word {
  width: inherit;
}

.traders_header_wide .auto_word:before {
  display: none;
}

.traders_header_wide .key_word {
  border-top: 1px solid #d1d1d1;
}

.traders_header_wide .key_word .item_area {
  height: 278px;
  padding-top: 8px;
  padding-bottom: 35px;
}

.traders_header_wide .key_word .cmitem_list {
  margin-left: -25px;
}

.traders_header_wide .key_word .cmitem_bx {
  margin: 0 0 0 25px;
}

/* 자동완성(tv) */
.tv_header .com_search_word {
  top: 59px;
  left: 345px;
  width: 332px;
}

.tv_header .auto_word {
  width: inherit;
  left: 0;
}

.tv_header .auto_word:before {
  display: none;
}

.tv_header .key_word .cmitem_bx {
  margin-top: 0;
}

/* 자동완성(Boots) */
.boots_header .com_search_word {
  top: 59px;
  left: 50%;
  width: 376px;
  margin-left: -362px;
  z-index: 20;
}

.boots_header .auto_word {
  top: 1px;
  left: 0;
  width: inherit;
}

.boots_header .auto_word:before {
  display: none;
}

.boots_header .auto_word .word_list li strong,
.boots_header .auto_word .tag_list li strong {
  color: #00498e;
}

.boots_header .key_word {
  top: 1px;
}

.boots_header .key_word .result_area li.on a,
.boots_header .key_word .result_area li:hover a {
  color: #00498e;
}

.boots_header .key_word .cmitem_list {
  margin-left: -27px;
}

.boots_header .key_word .cmitem_bx {
  margin-left: 27px;
}

/* 자동완성(siv) */
.siv_header .com_search_word {
  top: 53px;
  left: 335px;
  width: 350px;
}

.siv_header .auto_word {
  top: 1px;
  width: inherit;
  left: 0;
}

.siv_header .auto_word:before {
  display: none;
}

.siv_header .key_word {
  top: 1px;
}

.siv_header .key_word .recomm_list {
  width: 317px;
}

.siv_header .key_word .cmitem_list {
  margin-left: -13px;
}

.siv_header .key_word .cmitem_bx {
  margin-left: 13px;
}

/* 자동완성(starfield) */
.sf_header .com_search_word {
  top: 71px;
  right: 30px;
  left: auto;
  z-index: 20;
  width: 339px;
  margin-right: 0;
}

.sf_header .auto_word {
  top: 1px;
  width: inherit;
  left: 0;
}

.sf_header .auto_word:before {
  display: none;
}

.sf_header .key_word {
  top: 1px;
}

.sf_header .key_word .ssg-tooltip-layer {
  right: 0;
  left: auto;
}

/* 자동완성(outlet) */
.oul_header .com_search_word {
  top: 51px;
  right: 0;
  left: auto;
  width: 260px;
  margin-right: 0;
  z-index: 300;
}

.oul_header .auto_word {
  top: 0;
  width: inherit;
  border: 1px solid #d1d1d1;
  left: 0;
}

.oul_header .auto_word:before {
  display: none;
}

.oul_header .auto_word .word_list li > a {
  padding-right: 100px;
}

.oul_header .key_word {
  top: 0;
  border: 1px solid #d1d1d1;
}

.oul_header .key_word .ssg-tooltip-layer {
  right: 0;
  left: auto;
}

/* 자동완성(mnmorning) */
.mnmorning_header .com_search_word {
  top: 73px;
  right: 0;
  left: auto;
  z-index: 20;
  width: 350px;
  margin-right: 0;
}

.mnmorning_header .auto_word {
  top: 1px;
  width: inherit;
  left: 0;
}

.mnmorning_header .auto_word:before {
  display: none;
}

.mnmorning_header .key_word {
  top: 1px;
}

.mnmorning_header .key_word .ssg-tooltip-layer {
  right: 0;
  left: auto;
}

/* 자동완성(mnseller) */
.mnseller_header .com_search_word {
  top: 71px;
  left: 50%;
  width: 390px;
  z-index: 20;
  transform: translateX(-50%);
}

.mnseller_header .auto_word {
  top: 0;
  width: inherit;
  left: 0;
  border-top: 0;
}

.mnseller_header .auto_word:before {
  display: none;
}

.mnseller_header .key_word {
  top: 0;
  border-top: 0;
}

.mnseller_header .key_word .ssg-tooltip-layer {
  right: 0;
  left: auto;
}

/* 자동완성(mndeptfood) */
.mndeptfood_header .com_search_word {
  top: 67px;
  right: 0;
  left: auto;
  z-index: 20;
  width: 350px;
  margin-right: 0;
}

.mndeptfood_header .auto_word {
  top: 0;
  width: inherit;
  left: 0;
}

.mndeptfood_header .auto_word:before {
  display: none;
}

.mndeptfood_header .key_word {
  top: 0;
}

.mndeptfood_header .key_word .ssg-tooltip-layer {
  right: 0;
  left: auto;
}

/* 자동완성(mncasamia) */
.mncasamia_header .com_search_word {
  top: 74px;
  right: 0;
  left: auto;
  z-index: 20;
  width: 350px;
  margin-right: 0;
}

.mncasamia_header .auto_word {
  top: 0;
  width: inherit;
  left: 0;
}

.mncasamia_header .auto_word:before {
  display: none;
}

.mncasamia_header .key_word {
  top: 0;
}

.mncasamia_header .key_word .ssg-tooltip-layer {
  right: 0;
  left: auto;
}

/* 자동완성(mnchicor) */
.mnchicor_header .com_search_word {
  top: 67px;
  left: 50%;
  z-index: 20;
  width: 360px;
  margin-right: 0;
  margin-left: -180px;
}

.mnchicor_header .auto_word {
  top: 0;
  width: inherit;
  left: 0;
}

.mnchicor_header .auto_word:before {
  display: none;
}

.mnchicor_header .key_word {
  top: 0;
}

.mnchicor_header .key_word .ssg-tooltip-layer {
  right: 0;
  left: auto;
}

/* 자동완성(mntrip) */
.mntrip_header .com_search_word {
  top: 80px;
  left: 207px;
  width: 220px;
  margin-right: 0;
  z-index: 300;
}

.mntrip_header .auto_word {
  top: 0;
  width: inherit;
  border: 1px solid #d1d1d1;
  left: 0;
}

.mntrip_header .auto_word:before {
  display: none;
}

.mntrip_header .auto_word .word_list li > a {
  padding-right: 100px;
}

.mntrip_header .key_word {
  top: 0;
  left: -5px;
  width: 270px;
  border: 1px solid #d1d1d1;
}

.mntrip_header .key_word .ssg-tooltip-layer {
  right: 0;
  left: auto;
}

/* 자동완성(mnstarbucks) */
.mnstarbucks_header .com_search_word {
  top: 74px;
  right: 0;
  left: auto;
  z-index: 20;
  width: 320px;
  margin-right: 0;
}

.mnstarbucks_header .auto_word {
  top: 0;
  width: inherit;
  left: 0;
}

.mnstarbucks_header .auto_word:before {
  display: none;
}

.mnstarbucks_header .key_word {
  top: 0;
}

.mnstarbucks_header .key_word .ssg-tooltip-layer {
  right: 0;
  left: auto;
}

/* 해시태그 */
.com_search_hash {
  position: absolute;
  top: 32px;
  left: 160px;
  width: 680px;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
  font-size: 13px;
  color: #222;
  background-color: #fff;
  border: 1px solid #d1d1d1;
}

.com_search_hash .key_hash {
  position: relative;
}

.com_search_hash .key_hash button.btn_close {
  position: absolute;
  right: 0;
  top: 0;
  width: 45px;
  height: 45px;
  background: url(../../img/common_layout/hash_close.png) no-repeat;
  z-index: 99;
}

.com_search_hash a {
  font-size: 13px;
  text-decoration: none;
  color: #3e3e3e;
}

.com_search_hash .real_time {
  position: relative;
  padding: 30px 0 27px 0;
  float: left;
  border-right: 1px solid #ececec;
  width: 225px;
  min-height: 357px;
}

.com_search_hash .real_time .tit {
  padding-bottom: 10px;
  border-bottom: 1px solid #e5e5e5;
  font-size: 14px;
  color: #000;
  margin: 0 25px;
}

.com_search_hash .real_time .real_lst {
  padding-top: 11px;
}

.com_search_hash .real_time .real_lst li {
  display: block;
  padding: 7px 25px 0 25px;
}

.com_search_hash .real_time .real_lst li span.arr_num {
  display: inline-block;
  width: 18px;
  color: #888;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
}

.com_search_hash .real_time .real_lst li span.text {
  display: inline-block;
  overflow: hidden;
  max-width: 88%;
  color: #666;
  vertical-align: middle;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.com_search_hash .real_time .real_lst p.date {
  text-align: right;
  padding-top: 8px;
  border-top: 1px solid #e5e5e5;
  font-size: 12px;
  color: #888;
  margin: 18px 25px 0 25px;
}

.com_search_hash .real_time .real_lst li:hover {
  background-color: #f4f5f8;
  padding-bottom: 5px;
  margin-bottom: -5px;
}

.com_search_hash .real_time .real_lst li:hover span.text {
  color: #222;
  font-weight: bold;
}

.com_search_hash .recomm_hash {
  position: relative;
  padding: 30px 25px 25px 25px;
  float: left;
  width: 404px;
  min-height: 357px;
}

.com_search_hash .recomm_hash .tit {
  font-size: 16px;
  padding-bottom: 10px;
}

.com_search_hash .recomm_hash .tit strong {
  position: relative;
}

.com_search_hash .recomm_hash .tit strong:after {
  position: absolute;
  right: -8px;
  top: 0;
  border-radius: 50%;
  width: 6px;
  height: 6px;
  background-color: var(--m-colors-primary, #ff5452);
  content: "";
}

.com_search_hash .recomm_hash .tit em {
  color: var(--m-colors-primary, #ff5452);
}

.com_search_hash .recomm_hash .recomm_lst {
  margin-bottom: 27px;
  max-height: 135px;
  overflow: hidden;
  font-size: 0;
}

.com_search_hash .recomm_hash .recomm_lst:last-child {
  margin-bottom: 0;
}

.com_search_hash .recomm_hash .recomm_lst li {
  display: inline-block;
  vertical-align: top;
  *display: inline;
}

.com_search_hash .recomm_hash .recomm_lst li a {
  display: inline-block;
  margin: 0 5px 5px 0;
  border: 1px solid #ececec;
  border-radius: 30px;
  padding: 6px 10px 3px 13px;
  line-height: 18px;
}

.com_search_hash .recomm_hash .recomm_lst li a:hover {
  color: var(--m-colors-primary, #ff5452);
  border: 1px solid var(--m-colors-primary, #ff5452);
  text-decoration: none;
}

.com_search_hash .com_search_txarea {
  clear: both;
  height: 34px;
  padding: 0 25px;
  background: #f4f5f8;
  line-height: 34px;
}

.com_search_hash .com_search_tx {
  font-size: 12px;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
  color: #888;
  letter-spacing: -1px;
}

.com_search_hash .com_search_tx em {
  color: #666;
}

.common_header.other_site .com_search_hash {
  top: 28px;
}

.common_header .com_search_hash {
  top: 38px;
}

.common_header.rank .com_search_hash {
  top: 44px;
  left: 186px;
}

.common_header.rank.other_site .com_search_hash {
  top: 38px;
  left: 141px;
}

.common_header.other_site .com_search_hash {
  top: 32px;
}

/* 해시태그(신몰) */
#header_sm .com_search_hash {
  top: 61px;
  z-index: 40;
}

/* 해시태그(이몰) */
.emall_header_wide .emall_sch.hash_tag .ip_search .inp_txt {
  padding: 0 2px;
  font-size: 12px;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
}

.emall_header_wide .com_search_hash {
  top: 58px;
  left: 249px;
  z-index: 1;
}

.emall_header .com_search_hash a {
  text-decoration: none;
}

#container.overlay::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.45);
}

.cmgnb_navbar {
  position: relative;
  z-index: 200;
  border-bottom: 1px solid #e5e5e5;
  box-shadow: 0 4px 4px 0 rgba(108, 108, 108, 0.06);
}
.cmgnb_navbar .cmgnb_inner {
  width: 1280px;
  height: 58px;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.cmgnb_ship {
  margin-left: 23px;
  display: flex;
}
.cmgnb_ship .cmgnb_ship_lnk {
  display: block;
  margin-right: 8px;
}
.cmgnb_ship .cmgnb_ship_ico {
  overflow: hidden;
  display: block;
  min-width: 84px;
  height: 30px;
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0.03);
}
.cmgnb_ship .cmgnb_ship_ico img {
  height: 100%;
  vertical-align: top;
}

.cmgnb_menu {
  flex: 1;
  text-align: right;
}
.cmgnb_menu ul {
  display: inline-flex;
  align-items: center;
}
.cmgnb_menu ul li {
  position: relative;
  display: inline-block;
  padding-left: 15px;
}
.cmgnb_menu ul li:first-child {
  padding-left: 0;
}
.cmgnb_menu .menu_lnk {
  position: relative;
  display: block;
  color: #222;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.3px;
  text-align: center;
  line-height: 27px;
}
.cmgnb_menu .menu_lnk.ty_open:after {
  content: "";
  position: absolute;
  top: -3px;
  right: -6px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #ff6c6b;
}
.cmgnb_menu .menu_ico {
  display: inline-block;
  vertical-align: top;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.03);
}
.cmgnb_menu .menu_ico img {
  vertical-align: top;
  width: 100%;
  image-rendering: -webkit-optimize-contrast;
}

.cmgnb_ctg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 58px;
  height: 58px;
  margin-left: -7px;
}

.cmgnb_ctg_open {
  display: block;
  width: 44px;
  height: 44px;
  border: 2px solid #000;
  border-radius: 100%;
  box-sizing: border-box;
  background-color: #fff;
}
.cmgnb_ctg_open:focus:not(:focus-visible) {
  outline: 0;
}
.cmgnb_ctg_open.on {
  border: 0;
  background-color: #000;
}
.cmgnb_ctg_open.on .ico_menu {
  background-color: #fff;
}
.cmgnb_ctg_open.on .ico_menu::before, .cmgnb_ctg_open.on .ico_menu::after {
  background-color: #fff;
}
.cmgnb_ctg_open .ico_menu {
  position: relative;
  display: block;
  width: 22px;
  height: 2px;
  margin: 0 auto;
  background-color: #000;
}
.cmgnb_ctg_open .ico_menu::before, .cmgnb_ctg_open .ico_menu::after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #000;
}
.cmgnb_ctg_open .ico_menu::before {
  top: -6px;
}
.cmgnb_ctg_open .ico_menu::after {
  bottom: -6px;
}

.cmctg_total {
  display: none;
  position: absolute;
  top: 58px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
}
.cmctg_total.on {
  display: block;
}

.cmctg_dimmed {
  position: fixed;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  user-select: none;
}

.cmctg_cont {
  border-top: 1px solid #e5e5e5;
  box-shadow: inset 0px 4px 4px 0 rgba(108, 108, 108, 0.06);
  background-color: #fff;
}

.cmctg_list {
  position: relative;
  width: 1280px;
  margin: 0 auto;
  padding: 15px 0 30px;
}

.cmctg_top_mn {
  width: 154px;
}
.cmctg_top_mn:first-child {
  margin-top: -2px;
}
.cmctg_top_mn.on .cmctg_top_lnk {
  color: var(--m-colors-primary, #ff5452);
}
.cmctg_top_mn.on .cmctg_sub_area {
  display: block;
  display: flex;
}

.cmctg_top_lnk {
  display: flex;
  align-items: center;
  height: 30px;
  line-height: 30px;
  padding: 0 2px 0 1px;
  font-size: 14px;
  font-weight: bold;
  color: #222;
  word-break: break-all;
  word-wrap: break-word;
}

.cmctg_icon_open {
  background: url(../../img/common/ico_cmtg_open@2x.png) no-repeat;
  background-size: 100%;
  width: 40px;
  height: 17px;
  display: inline-block;
  margin: -3px 0 0 5px;
}

.cmctg_badge_open {
  width: 40px;
  height: 17px;
  display: inline-block;
  margin: -3px 0 0 5px;
}
.cmctg_badge_open img {
  vertical-align: top;
  width: 100%;
}

.cmctg_sub_area {
  display: none;
  position: absolute;
  top: 0;
  left: 154px;
  right: 0;
  padding: 20px 0 40px;
  min-height: 100%;
  box-sizing: border-box;
}

.cmctg_sub_inner {
  width: 1280px;
  margin: 0 auto;
}
.cmctg_sub_inner:after {
  clear: both;
  content: "";
  display: block;
}

.cmctg_sub_menu {
  width: 100%;
}

.cmctg_sub_colgroup {
  display: flex;
}

.cmctg_sub_col {
  display: table-cell;
  vertical-align: top;
  width: 186px;
  height: 100%;
  border-left: 1px solid #f0f0f0;
}

.cmctg_sub_lst {
  max-width: 186px;
  padding: 25px 0 0 18px;
}
.cmctg_sub_lst:first-child {
  padding-top: 0;
}

.cmctg_sub_tit {
  margin-top: -2px;
}
.cmctg_sub_tit .cmctg_sub_lnk {
  font-weight: bold;
}
.cmctg_sub_tit .cmctg_sub_lnk:hover {
  color: var(--m-colors-primary, #ff5452);
}

.cmctg_sub_mn {
  padding-top: 6px;
}
.cmctg_sub_mn:first-child {
  padding-top: 0;
}
.cmctg_sub_mn .cmctg_sub_lnk:hover {
  color: var(--m-colors-primary, #ff5452);
}

.cmctg_sub_lnk {
  font-size: 13px;
  letter-spacing: -0.3px;
  color: #222;
}

.cmctg_sub_side {
  float: right;
  min-width: 170px;
  width: 170px;
  padding-left: 18px;
}
.cmctg_sub_side.has_kilr {
  border-left: 1px solid #f0f0f0;
}

.cmctg_kilr .cmctg_kilr_bn .cmctg_kilr_bnlnk {
  display: block;
  text-decoration: none;
}
.cmctg_kilr .cmctg_kilr_bn .cmctg_kilr_bnimg {
  position: relative;
}
.cmctg_kilr .cmctg_kilr_bn .cmctg_kilr_bnimg img {
  width: 100%;
  object-fit: cover;
}
.cmctg_kilr .cmctg_kilr_lst {
  margin: 20px 0 10px 0;
}
.cmctg_kilr .cmctg_kilr_mn {
  padding-top: 7px;
}
.cmctg_kilr .cmctg_kilr_mn:first-child {
  padding-top: 0;
}
.cmctg_kilr .cmctg_kilr_lnk {
  display: inline-block;
  line-height: 20px;
  font-size: 13px;
  font-weight: bold;
  color: #222;
  letter-spacing: -0.3px;
}
.cmctg_kilr .cmctg_kilr_badge {
  display: inline-block;
  vertical-align: top;
  margin-left: 4px;
}
.cmctg_kilr .cmctg_kilr_badge img {
  height: 18px;
  vertical-align: top;
}

.cmctg_bn .cmctg_bn_lnk {
  display: block;
  text-decoration: none;
}
.cmctg_bn .cmctg_bn_img {
  overflow: hidden;
  position: relative;
  padding-top: 100%;
}
.cmctg_bn .cmctg_bn_img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  object-fit: cover;
}
.cmctg_bn .cmctg_bn_tit {
  padding: 7px 20px 0 0;
  color: #222;
  letter-spacing: -0.3px;
}
.cmctg_bn .cmctg_bn_tit .cmctg_bn_main {
  display: block;
  overflow: hidden;
  max-height: 34px;
  font-size: 15px;
  font-weight: bold;
}
.cmctg_bn .cmctg_bn_tit .cmctg_bn_sub {
  display: block;
  overflow: hidden;
  max-height: 34px;
  font-size: 13px;
}

/* GMB 몰탭 */
.gnb_mall {
  overflow: hidden;
  width: 1280px;
  height: 51px;
  margin: 0 auto;
  padding-right: 15px;
  box-sizing: border-box;
}

.gnb_mall.gnb_mall_flexible {
  width: auto;
}

.gnb_mall ul {
  display: table;
  width: 100%;
  table-layout: fixed;
  height: 51px;
  background: url(../../img/common/bg_layout_header03.gif) 0 100% repeat-x;
}

.gnb_mall li {
  display: table-cell;
  position: relative;
  padding-right: 13px;
  text-align: center;
  vertical-align: bottom;
  animation-name: gnb-mall-show;
  box-sizing: border-box;
}

.gnb_mall li:first-child a:before {
  display: none;
}

.gnb_mall a {
  display: block;
  position: relative;
  line-height: 34px;
  text-decoration: none !important;
}

.gnb_mall a:before {
  content: "";
  z-index: 3;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 14px 0 0;
  background: url(../../img/common_layout/bg_malltab_shadow@2x.png) 0 0 no-repeat;
  background-size: 40px auto;
}

.gnb_mall a:after {
  content: "";
  z-index: 4;
  position: absolute;
  bottom: 0;
  right: -29px;
  width: 62px;
  height: 100%;
  background: url(../../img/common_layout/bg_malltab_light@2x.png) 0 0 no-repeat;
  background-size: 62px auto;
}

.gnb_mall i {
  display: block;
  border-radius: 14px 0 0;
  background: url(../../img/common_layout/sp_malltab_v3@2x.png) 100% 0 repeat-x;
  background-size: 200px auto;
}

.gnb_mall i:after {
  content: "";
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 100%;
  width: 30px;
  height: 100%;
  margin-left: -1px;
  background: url(../../img/common_layout/sp_malltab_right_v3@2x.png) 100% 0 no-repeat;
  background-size: 30px auto;
}

.gnb_mall i {
  height: 34px;
}

.gnb_mall i:before {
  display: none;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 14px;
  height: 100%;
  border-left: 1px solid #4d4d4d;
  border-top: 1px solid #4d4d4d;
  border-radius: 14px 0 0;
}

.gnb_mall .gnb_malltx {
  display: block;
  overflow: hidden;
  position: relative;
  z-index: 2;
  margin-top: -34px;
  margin-right: -13px;
  padding: 0 10px;
  color: #222;
  font-size: 13px;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 500;
  white-space: nowrap;
}

.gnb_mall .gnb_malltx:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  width: 16px;
  height: 16px;
  margin-top: -7px;
}

.gnb_mall .gnb_malltx.en {
  font-size: 14px;
}

.gnb_mall .gnb_malltx.light {
  color: #fff;
}

.gnb_mall .ico_tooltip {
  position: absolute;
  top: -6px;
  right: -10px;
  z-index: 10;
  margin: 0;
  width: 30px;
  height: 14px;
  background: url(../../img/common_layout/sp_mallnew_ico.png) 0 0 no-repeat;
  background-size: 30px auto;
  opacity: 0;
  opacity: 1\9 ;
  animation: fadeIn 0.1s forwards;
}

.gnb_mall .ico_tooltip.ad {
  background: url(../../img/common_layout/ico_mall_ad.png) 0 0 no-repeat;
}

@media screen and (-webkit-min-device-pixel-ratio: 2) {
  .gnb_mall .ico_tooltip {
    background-image: url(../../img/common_layout/sp_mallnew_ico@2x.png);
    background-size: auto 14px;
  }
  .gnb_mall .ico_tooltip.ad {
    background-image: url(../../img/common_layout/ico_mall_ad@2x.png);
    background-size: auto 14px;
  }
}
/* GMB 몰탭 - 광고구좌 SVG */
.gnb_mall li.ty_virtual {
  padding: 8px 0 0;
}

.gnb_mall li.ty_virtual a {
  height: 34px;
}

.gnb_mall li.ty_virtual a:after {
  right: -14px;
}

.gnb_mall li.ty_virtual a:focus,
.gnb_mall li.ty_virtual a:hover,
.gnb_mall li.ty_virtual.active a {
  height: 43px;
}

.gnb_mall li.ty_virtual svg {
  display: block;
  overflow: visible;
}

.gnb_mall li.ty_virtual .gnb_mall_svg {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 26px;
}

.gnb_mall li.ty_virtual .gnb_mall_svghover {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 26px;
  opacity: 0;
}

.gnb_mall li.ty_virtual .gnb_malltx {
  margin-right: 0;
}

.gnb_mall li.ty_virtual .ico_tooltip {
  right: 4px;
}

.gnb_mall li.ty_virtual:not(.active) a .gnb_mall_svg {
  bottom: 1px;
}

.gnb_mall li.ty_virtual:not(.active) a:focus .gnb_mall_svg,
.gnb_mall li.ty_virtual:not(.active) a:hover .gnb_mall_svg {
  opacity: 0;
}

.gnb_mall li.ty_virtual:not(.active) a:focus .gnb_mall_svghover,
.gnb_mall li.ty_virtual:not(.active) a:hover .gnb_mall_svghover {
  opacity: 1;
}

/* GMB 몰탭 - animation */
.gnb_mall li:nth-child(1) {
  animation-duration: 0.25s;
  z-index: 20;
}

.gnb_mall li:nth-child(2) {
  animation-duration: 0.35s;
  z-index: 19;
}

.gnb_mall li:nth-child(3) {
  animation-duration: 0.45s;
  z-index: 18;
}

.gnb_mall li:nth-child(4) {
  animation-duration: 0.55s;
  z-index: 17;
}

.gnb_mall li:nth-child(5) {
  animation-duration: 0.65s;
  z-index: 16;
}

.gnb_mall li:nth-child(6) {
  animation-duration: 0.75s;
  z-index: 15;
}

.gnb_mall li:nth-child(7) {
  animation-duration: 0.85s;
  z-index: 14;
}

.gnb_mall li:nth-child(8) {
  animation-duration: 0.95s;
  z-index: 13;
}

.gnb_mall li:nth-child(9) {
  animation-duration: 1.05s;
  z-index: 12;
}

.gnb_mall li:nth-child(10) {
  animation-duration: 1.15s;
  z-index: 11;
}

.gnb_mall li:nth-child(11) {
  animation-duration: 1.25s;
  z-index: 10;
}

.gnb_mall li:nth-child(12) {
  animation-duration: 1.35s;
  z-index: 9;
}

.gnb_mall li:nth-child(13) {
  animation-duration: 1.45s;
  z-index: 8;
}

.gnb_mall li:nth-child(14) {
  animation-duration: 1.55s;
  z-index: 7;
}

.gnb_mall li:nth-child(15) {
  animation-duration: 1.65s;
  z-index: 6;
}

.gnb_mall li:nth-child(16) {
  animation-duration: 1.75s;
  z-index: 5;
}

.gnb_mall li:nth-child(17) {
  animation-duration: 1.85s;
  z-index: 4;
}

.gnb_mall li:nth-child(18) {
  animation-duration: 1.95s;
  z-index: 3;
}

.gnb_mall li:nth-child(19) {
  animation-duration: 2.05s;
  z-index: 2;
}

.gnb_mall li:nth-child(20) {
  animation-duration: 2.15s;
  z-index: 1;
}

@keyframes gnb-mall-show {
  0% {
    opacity: 0;
    transform: translateY(35px);
  }
  70% {
    opacity: 1;
    transform: translateY(35px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.gnb_mall li:nth-child(1) .ico_tooltip {
  animation-delay: 0.25s;
}

.gnb_mall li:nth-child(2) .ico_tooltip {
  animation-delay: 0.35s;
}

.gnb_mall li:nth-child(3) .ico_tooltip {
  animation-delay: 0.45s;
}

.gnb_mall li:nth-child(4) .ico_tooltip {
  animation-delay: 0.55s;
}

.gnb_mall li:nth-child(5) .ico_tooltip {
  animation-delay: 0.65s;
}

.gnb_mall li:nth-child(6) .ico_tooltip {
  animation-delay: 0.75s;
}

.gnb_mall li:nth-child(7) .ico_tooltip {
  animation-delay: 0.85s;
}

.gnb_mall li:nth-child(8) .ico_tooltip {
  animation-delay: 0.95s;
}

.gnb_mall li:nth-child(9) .ico_tooltip {
  animation-delay: 1.05s;
}

.gnb_mall li:nth-child(10) .ico_tooltip {
  animation-delay: 1.15s;
}

.gnb_mall li:nth-child(11) .ico_tooltip {
  animation-delay: 1.25s;
}

.gnb_mall li:nth-child(12) .ico_tooltip {
  animation-delay: 1.35s;
}

.gnb_mall li:nth-child(13) .ico_tooltip {
  animation-delay: 1.45s;
}

.gnb_mall li:nth-child(14) .ico_tooltip {
  animation-delay: 1.55s;
}

.gnb_mall li:nth-child(15) .ico_tooltip {
  animation-delay: 1.65s;
}

.gnb_mall li:nth-child(16) .ico_tooltip {
  animation-delay: 1.75s;
}

.gnb_mall li:nth-child(17) .ico_tooltip {
  animation-delay: 1.85s;
}

.gnb_mall li:nth-child(18) .ico_tooltip {
  animation-delay: 1.95s;
}

.gnb_mall li:nth-child(19) .ico_tooltip {
  animation-delay: 2.05s;
}

.gnb_mall li:nth-child(20) .ico_tooltip {
  animation-delay: 2.15s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* GMB 몰탭 - focus, hover, active */
.gnb_mall li a:focus,
.gnb_mall li a:hover,
.gnb_mall li.active a {
  line-height: 43px;
}

.gnb_mall li a:focus:before,
.gnb_mall li a:hover:before,
.gnb_mall li.active a:before,
.gnb_mall li a:focus:after,
.gnb_mall li a:hover:after,
.gnb_mall li.active a:after {
  display: none;
}

.gnb_mall li a:focus i,
.gnb_mall li a:hover i,
.gnb_mall li.active i {
  height: 43px;
  background-image: url(../../img/common_layout/sp_malltabon_v3@2x.png);
}

.gnb_mall li a:focus i:before,
.gnb_mall li a:hover i:before,
.gnb_mall li.active i:before {
  display: block;
}

.gnb_mall li a:focus i:after,
.gnb_mall li a:hover i:after,
.gnb_mall li.active i:after {
  background-image: url(../../img/common_layout/sp_malltabon_right_v3@2x.png);
}

.gnb_mall li a:focus .gnb_malltx,
.gnb_mall li a:hover .gnb_malltx,
.gnb_mall li.active .gnb_malltx {
  margin-top: -43px;
}

.gnb_mall li:not(.active) a:focus .gnb_malltx,
.gnb_mall li:not(.active) a:hover .gnb_malltx {
  color: #222 !important;
}

.gnb_mall li a:focus .gnb_malltx:before,
.gnb_mall li a:hover .gnb_malltx:before,
.gnb_mall li.active .gnb_malltx:before {
  display: none !important;
}

.gnb_mall li:not(.active) a:focus .gnb_malltx:after,
.gnb_mall li:not(.active) a:hover .gnb_malltx:after {
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0), #fff 80%) !important;
}

.gnb_mall li.active .gnb_malltx:after {
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0), #fff 80%);
}

.gnb_mall li:hover {
  z-index: 21 !important;
}

.gnb_mall li:not(.active) a:focus i,
.gnb_mall li:not(.active) a:hover i,
.gnb_mall li:not(.active) a:focus i:after,
.gnb_mall li:not(.active) a:hover i:after {
  background-position-y: 0 !important;
}

.gnb_mall li.active {
  z-index: 22 !important;
  animation: none;
}

/* GMB 몰탭 - 확장 */
.gnb_mall_flexible li.fix {
  width: 128px;
}

.gnb_mall_flexible li:not(.fix) .gnb_malltx:before {
  content: ""; /*display:inline-block;*/
  display: none;
  width: 20px;
  height: 20px;
  margin: -1px 5px 0 0;
  background-image: url(../../img/common_layout/sp_malltab_symbol@2x.png);
  background-repeat: no-repeat;
  background-size: 20px 600px;
  vertical-align: middle;
}

/* [D] 미디어쿼리 min-width 계산식 - (min-width: 128 * 몰탭개수 + 45 px)
* 몰탭 11개: (min-width: 1453px)
* 몰탭 12개: (min-width: 1581px)
* 몰탭 13개: (min-width: 1709px)
* 몰탭 14개: (min-width: 1837px)
*/
@media screen and (min-width: 1453px) {
  .gnb_mall_flexible.n11 li {
    width: auto !important;
  }
  .gnb_mall_flexible.n11 li:not(.fix) .gnb_malltx:before,
.gnb_mall_flexible.n11 li:not(.fix) .gnb_malltx:after {
    display: none;
  }
}
@media screen and (min-width: 1581px) {
  .gnb_mall_flexible.n12 li {
    width: auto !important;
  }
  .gnb_mall_flexible.n12 li:not(.fix) .gnb_malltx:before,
.gnb_mall_flexible.n12 li:not(.fix) .gnb_malltx:after {
    display: none;
  }
}
@media screen and (min-width: 1709px) {
  .gnb_mall_flexible.n13 li {
    width: auto !important;
  }
  .gnb_mall_flexible.n13 li:not(.fix) .gnb_malltx:before,
.gnb_mall_flexible.n13 li:not(.fix) .gnb_malltx:after {
    display: none;
  }
}
@media screen and (min-width: 1837px) {
  .gnb_mall_flexible.n14 li {
    width: auto !important;
  }
  .gnb_mall_flexible.n14 li:not(.fix) .gnb_malltx:before,
.gnb_mall_flexible.n14 li:not(.fix) .gnb_malltx:after {
    display: none;
  }
}
@media screen and (min-width: 1965px) {
  .gnb_mall_flexible.n15 li {
    width: auto !important;
  }
  .gnb_mall_flexible.n15 li:not(.fix) .gnb_malltx:before,
.gnb_mall_flexible.n15 li:not(.fix) .gnb_malltx:after {
    display: none;
  }
}
/* GMB 몰탭 - 각몰 수정 */
.gnb_mall a i,
.gnb_mall a i:after {
  background-position-y: -400px;
} /* 기본값 siv 스타일 */
.gnb_mall a.sm i,
.gnb_mall a.sm i:after {
  background-position-y: -50px;
}

.gnb_mall a.sd i,
.gnb_mall a.sd i:after {
  background-position-y: -100px;
}

.gnb_mall a.em i,
.gnb_mall a.em i:after {
  background-position-y: -150px;
}

.gnb_mall a.tr i,
.gnb_mall a.tr i:after {
  background-position-y: -200px;
}

.gnb_mall a.mnstarbucks i,
.gnb_mall a.mnstarbucks i:after {
  background-position-y: -250px;
}

.gnb_mall a.tv i,
.gnb_mall a.tv i:after {
  background-position-y: -300px;
}

.gnb_mall a.oul i,
.gnb_mall a.oul i:after {
  background-position-y: -350px;
}

.gnb_mall a.sf i,
.gnb_mall a.sf i:after {
  background-position-y: -450px;
}

.gnb_mall a.hwd i,
.gnb_mall a.hwd i:after {
  background-position-y: -500px;
}

.gnb_mall a.mnmorning i,
.gnb_mall a.mnmorning i:after {
  background-position-y: -550px;
}

.gnb_mall a.mncasamia i,
.gnb_mall a.mncasamia i:after {
  background-position-y: -650px;
}

.gnb_mall a.mnchicor i,
.gnb_mall a.mnchicor i:after {
  background-position-y: -700px;
}

.gnb_mall a.mntrip i,
.gnb_mall a.mntrip i:after {
  background-position-y: -800px;
}

/*시코르 일때만 하우디 검정 배경 변경*/
.body_mnchicor .gnb_mall a.hwd i,
.body_mnchicor .gnb_mall a.hwd i:after {
  background-position-y: -750px;
}

.body_mnchicor .gnb_mall a.hwd .gnb_malltx:after {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0), #444 80%);
}

.body_mnchicor .gnb_mall li a:focus .gnb_malltx:after,
.body_mnchicor .gnb_mall li a:hover .gnb_malltx:after {
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0), #fff 80%);
}

.gnb_mall a .gnb_malltx:before {
  background-position-y: -180px;
} /* 기본값 siv 스타일 */
.gnb_mall a.sm .gnb_malltx:before {
  background-position-y: 0;
}

.gnb_mall a.sd .gnb_malltx:before {
  background-position-y: -30px;
}

.gnb_mall a.em .gnb_malltx:before {
  background-position-y: -60px;
}

.gnb_mall a.tr .gnb_malltx:before {
  background-position-y: -90px;
}

.gnb_mall a.mnstarbucks .gnb_malltx:before {
  background-position-y: -120px;
}

.gnb_mall a.tv .gnb_malltx:before {
  background-position-y: -150px;
}

.gnb_mall a.sf .gnb_malltx:before {
  background-position-y: -210px;
}

.gnb_mall a.hwd .gnb_malltx:before {
  background-position-y: -240px;
}

.gnb_mall a.oul .gnb_malltx:before {
  background-position-y: -270px;
}

.gnb_mall a.mnmorning .gnb_malltx:before {
  background-position-y: -310px;
}

.gnb_mall a.mncasamia .gnb_malltx:before {
  background-position-y: -340px;
}

.gnb_mall a .gnb_malltx:after {
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0), #fff 80%);
} /* 기본값 siv 스타일 */
.gnb_mall a.sm .gnb_malltx:after {
  background-image: linear-gradient(90deg, rgba(252, 24, 51, 0), #fc1833 80%);
}

.gnb_mall a.sd .gnb_malltx:after {
  background-image: linear-gradient(90deg, rgba(110, 111, 111, 0), #6e6f6f 80%);
}

.gnb_mall a.em .gnb_malltx:after {
  background-image: linear-gradient(90deg, rgba(255, 208, 64, 0), #ffd040 80%);
}

.gnb_mall a.tr .gnb_malltx:after {
  background-image: linear-gradient(90deg, rgba(166, 221, 39, 0), #a6dd27 80%);
}

.gnb_mall a.mnstarbucks .gnb_malltx:after {
  background-image: linear-gradient(90deg, rgba(0, 98, 65, 0), #006241 80%);
}

.gnb_mall a.tv .gnb_malltx:after {
  background-image: linear-gradient(90deg, rgba(217, 32, 22, 0), #d92016 80%);
}

.gnb_mall a.sf .gnb_malltx:after {
  background-image: linear-gradient(90deg, rgba(177, 37, 54, 0), #b12536 80%);
}

.gnb_mall a.hwd .gnb_malltx:after {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0), #000 80%);
}

.gnb_mall a.oul .gnb_malltx:after {
  background-image: linear-gradient(90deg, rgba(159, 0, 92, 0), #d9117c 80%);
}

.gnb_mall a.mnmorning .gnb_malltx:after {
  background-image: linear-gradient(90deg, rgba(163, 183, 205, 0), #a3b7cd 80%);
}

.gnb_mall a.mnchicor .gnb_malltx:after {
  background-image: linear-gradient(90deg, rgba(238, 100, 86, 0), #e31e8f 80%);
}

.gnb_mall a.mntrip .gnb_malltx:after {
  background-image: linear-gradient(90deg, rgba(51, 140, 168, 0), #338ca8 80%);
}

.gnb_mall a.ad:after,
.gnb_mall a.siv:after,
.gnb_mall a.mncasamia:after {
  display: none;
}

.gnb_mall a.ad i:before,
.gnb_mall a.siv i:before,
.gnb_mall a.mncasamia i:before {
  display: block;
}

:lang(en) .gnb_mall .gnb_malltx {
  font-size: 14px;
}

:lang(en) .gnb_mall a.oul .gnb_malltx {
  font-size: 12px;
}

.gnb_mall li.active a.sm .gnb_malltx.light,
.gnb_mall li.active a.tv .gnb_malltx.light,
.gnb_mall li.active a.sf .gnb_malltx.light,
.gnb_mall li.active a.mntrip .gnb_malltx.light {
  color: #222;
}

.gnb_mall li.active a.mnstarbucks .gnb_malltx.light {
  color: #006241;
}

.gnb_mall li.active a.sd .gnb_malltx:after {
  background-image: linear-gradient(90deg, rgba(110, 111, 111, 0), #6e6f6f 80%);
}

.gnb_mall li.active a.em .gnb_malltx:after {
  background-image: linear-gradient(90deg, rgba(255, 208, 64, 0), #ffd040 80%);
}

.gnb_mall li.active a.tr .gnb_malltx:after {
  background-image: linear-gradient(90deg, rgba(166, 221, 39, 0), #a6dd27 80%);
}

.gnb_mall li.active a.sm .gnb_malltx:after,
.gnb_mall li.active a.mnmorning .gnb_malltx:after,
.gnb_mall li.active a.tv .gnb_malltx:after,
.gnb_mall li.active a.mnstarbucks .gnb_malltx:after,
.gnb_mall li.active a.sf .gnb_malltx:after,
.gnb_mall li.active a.mncasamia .gnb_malltx:after,
.gnb_mall li.active a.mntrip .gnb_malltx:after {
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0), #fff 80%);
}

.gnb_mall li.active a.oul .gnb_malltx:after {
  background-image: linear-gradient(90deg, rgba(159, 0, 92, 0), #d9117c 80%);
}

.gnb_mall li.active a.mnchicor .gnb_malltx:after,
.gnb_mall li.active a.hwd .gnb_malltx:after {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0), #000 80%);
}

/* GMB 몰탭 - 각몰 수정 (ie8 이하) */
.gnb_mall a:before,
.gnb_mall a:after,
.gnb_mall i:before,
.gnb_mall i:after,
.gnb_mall li a:focus i:before,
.gnb_mall li a:hover i:before,
.gnb_mall li.active i:before,
.gnb_mall li a:focus i:after,
.gnb_mall li a:hover i:after,
.gnb_mall li.active i:after {
  background: none\0 /IE8;
}

.gnb_mall a .gnb_malltx {
  background: #eee\0 /IE8;
}

.gnb_mall a.sm .gnb_malltx {
  background: #fc1833\0 /IE8;
}

.gnb_mall a.sd .gnb_malltx {
  background: #6e6f6f \0 /IE8;
}

.gnb_mall a.em .gnb_malltx {
  background: #ffd040\0 /IE8;
}

.gnb_mall a.tr .gnb_malltx {
  background: #a6dd27\0 /IE8;
}

.gnb_mall a.mnstarbucks .gnb_malltx {
  background: #006241 \0 /IE8;
}

.gnb_mall a.tv .gnb_malltx {
  background: #d92016\0 /IE8;
}

.gnb_mall a.sf .gnb_malltx {
  background: #b12536\0 /IE8;
}

.gnb_mall a.hwd .gnb_malltx {
  background: #000 \0 /IE8;
}

.gnb_mall a.oul .gnb_malltx {
  background: #d9117c\0 /IE8;
}

.gnb_mall a.mnmorning .gnb_malltx {
  background: #a3b7cd\0 /IE8;
}

.gnb_mall a.mnchicor .gnb_malltx {
  background: #f04c24\0 /IE8;
}

.gnb_mall a.mntrip .gnb_malltx {
  background: #338ca8 \0 /IE8;
}

.item_size140 .thumbnail {
  width: 140px;
  height: 140px;
}

.item_size140 .thumbnail2 {
  position: relative;
  line-height: 0;
  height: 140px;
}

.item_size140 .sale,
.item_size140 .gift,
.item_size140 .plus,
.item_size140 .reserve,
.item_size140 .traders_reserve,
.item_size140 .ssgdeliv,
.item_size140 .ssgdeliv_tr,
.item_size140 .ssgdeliv_sd,
.item_size140 .ssgpick,
.item_size140 .coupon_sale,
.item_size140 .ico_better,
.item_size140 .holiday_delivery {
  width: 42px;
  height: 48px;
  margin-top: -8px;
  background-image: url(../../img/common/sp_label.png);
  line-height: 48px;
}

.item_size140 .sale {
  height: 25px;
  padding-top: 18px;
  padding-bottom: 5px;
  font-size: 20px;
  line-height: normal;
  background-position: 0 -150px;
}

.item_size140 .sale .persent {
  top: 5px;
}

.item_size140 .coupon_sale {
  height: 25px;
  padding-top: 18px;
  padding-bottom: 5px;
  font-size: 20px;
  line-height: normal;
  background-position: 0 -300px;
}

.item_size140 .coupon_sale .persent {
  top: 5px;
}

.item_size140 .gift {
  background-position: 0 -600px;
  font-size: 12px;
  color: #fff;
  text-align: center;
}

.item_size140 .plus {
  background-position: 0 -450px;
  font-size: 19px;
  line-height: 50px;
}

.item_size140 .plus .plus1 {
  display: inline-block;
  width: 15px;
  height: 17px;
  background: url(../../img/common/sp_label.png) 0 -1650px no-repeat;
  vertical-align: -1px;
  margin-left: 3px;
}

.item_size140 .ico_better {
  background-position: 0 -1500px;
}

.item_size140 .reserve {
  width: 62px;
  height: 38px;
  margin: 0 0 0 -24px;
  background-position: 0 -750px;
}

.item_size140 .reserve.bojung,
.item_size140 .reserve.center {
  background-position: 0 -900px;
}

.item_size140 .reserve.bojung2 {
  width: 54px;
  height: 41px;
  background: url(../../img/common/sp_delivery.png) 0 -200px no-repeat;
  margin: 0 0 0 -16px;
}

.item_size140 .traders_reserve {
  display: block;
  width: 62px;
  height: 38px;
  margin: 0 0 0 -24px;
  background-position: 0 -1050px;
}

.item_size140 .holiday_delivery,
.item_size140 .holiday_delivery02 {
  display: block;
  width: 50px;
  height: 50px;
  margin: 0 0 0 -12px;
  background: url(../../img/common/sp_bdg.png) 0 -1350px no-repeat;
}

.item_size140 .reserve.ico_pickup {
  width: 65px;
  height: 45px;
  background-position: 0 -1950px;
  margin-right: -3px;
}

.item_size140 .traders_reserve.ico_pickup {
  width: 65px;
  height: 45px;
  background-position: 0 -1860px;
  margin-right: -3px;
}

.item_size140 .ssgdeliv {
  display: block;
  width: 48px;
  height: 50px;
  margin: 0 0 0 -10px;
  background-position: 0 -2040px;
}

.item_size140 .ssgdeliv_tr {
  display: block;
  width: 48px;
  height: 50px;
  margin: 0 0 0 -10px;
  background-position: 0 -2140px;
}

.item_size140 .ssgdeliv_sd {
  display: block;
  width: 48px;
  height: 50px;
  margin: 0 0 0 -10px;
  background-position: 0 -2340px;
}

.item_size140 .ssgpick {
  display: block;
  width: 48px;
  height: 48px;
  margin: 0 0 0 -10px;
  background-position: 0 -2240px;
}

.item_size140 .soldout {
  background-image: url(../../img/common/bg_soldout_140x140.png);
}

.item_size140 .soldout .date {
  color: #999;
}

.item_size140 .soldout.adultbx {
  background-image: url(../../img/common/bg_adult_140x140.png);
}

.item_size140 .holiday_delivery_em,
.item_size140 .holiday_delivery02_em {
  display: block;
  width: 62px;
  height: 47px;
  background: url(../../img/common/sp_delivery.png) -100px -100px no-repeat !important;
  margin-left: -24px;
}

.item_size140 .sprshp {
  width: 54px;
  height: 55px;
  margin: -2px 0 0 -16px;
}

.table_itemlist td .list_content .item_size140 .item_info .summary {
  min-height: 34px;
  height: auto;
}

.item_size140 .item_ord .item_promotion span {
  width: 50%;
}

.item_size168 .thumbnail {
  width: 168px;
  height: 168px;
}

.item_size168 .thumbnail2 {
  position: relative;
  line-height: 0;
  height: 168px;
}

.item_size168 .sale,
.item_size168 .gift,
.item_size168 .plus,
.item_size168 .reserve,
.item_size168 .traders_reserve,
.item_size168 .ssgdeliv,
.item_size168 .ssgdeliv_tr,
.item_size168 .coupon_sale,
.item_size168 .ico_better {
  width: 42px;
  height: 48px;
  margin-top: -8px;
  background-image: url(../../img/common/sp_label.png);
  line-height: 48px;
}

.item_size168 .sale {
  height: 25px;
  padding-top: 18px;
  padding-bottom: 5px;
  font-size: 20px;
  line-height: normal;
  background-position: 0 -150px;
}

.item_size168 .sale .persent {
  top: 5px;
}

.item_size168 .coupon_sale {
  height: 25px;
  padding-top: 18px;
  padding-bottom: 5px;
  font-size: 20px;
  line-height: normal;
  background-position: 0 -300px;
}

.item_size168 .coupon_sale .persent {
  top: 6px;
}

.item_size168 .gift {
  background-position: 0 -600px;
  font-size: 12px;
  color: #fff;
  text-align: center;
}

.item_size168 .plus {
  background-position: 0 -450px;
  font-size: 19px;
  line-height: 52px;
}

.item_size168 .plus .plus1 {
  display: inline-block;
  width: 15px;
  height: 17px;
  background: url(../../img/common/sp_label.png) 0 -1650px no-repeat;
  vertical-align: -1px;
  margin-left: 3px;
}

.item_size168 .ico_better {
  background-position: 0 -1500px;
}

.item_size168 .reserve {
  width: 62px;
  height: 38px;
  margin: 0 0 0 -24px;
  background-position: 0 -750px;
}

.item_size168 .reserve.bojung,
.item_size168 .reserve.center {
  width: 62px;
  height: 38px;
  margin: 0 0 0 -24px;
  background-position: 0 -900px;
}

.item_size168 .reserve.bojung2 {
  width: 54px;
  height: 41px;
  background: url(../../img/common/sp_delivery.png) 0 -200px no-repeat;
  margin: 0 0 0 -16px;
}

.item_size168 .traders_reserve {
  width: 62px;
  height: 38px;
  margin: 0 0 0 -24px;
  background-position: 0 -1050px;
}

.item_size168 .reserve.ico_pickup {
  width: 65px;
  height: 45px;
  background-position: 0 -1950px;
}

.item_size168 .traders_reserve.ico_pickup {
  width: 65px;
  height: 45px;
  background-position: 0 -1860px;
}

.item_size168 .holiday_delivery {
  width: 50px;
  height: 50px;
  margin: 0 0 0 -9px;
  background-position: 0 -1350px;
}

.item_size168 .holiday_delivery_em {
  margin-left: -21px;
}

.item_size168 .ssgdeliv {
  width: 48px;
  height: 50px;
  margin: 0 0 0 -7px;
  background-position: 0 -2040px;
}

.item_size168 .ssgdeliv_tr {
  width: 48px;
  height: 50px;
  margin: 0 0 0 -7px;
  background-position: 0 -2140px;
}

.item_size168 .soldout {
  background-image: url(../../img/common/bg_soldout_168x168.png);
}

.item_size168 .soldout .date {
  margin-top: 34px;
  color: #999;
}

.item_size168 .soldout.adultbx {
  background-image: url(../../img/common/bg_adult_168x168.png);
}

.table_itemlist td .list_content .item_size168 .item_info .summary {
  min-height: 34px;
  height: auto;
}

.item_size168 .item_ord .item_promotion span {
  width: 50%;
}

.item_size168 .sprshp {
  margin: 0 0 0 -14px;
}

.item_size202 .thumbnail {
  width: 202px;
  height: 202px;
}

.item_size202 .item_info {
  padding: 0 2px 0 1px;
}

.item_size202 .item_ord {
  padding: 0 0 5px;
  width: 100%;
}

.itembx.item_size202 .item_promotion span {
  width: 50%;
  overflow: hidden;
}

.item_size290 .soldout .date {
  margin-top: 63px;
}

.item_size290 .soldout .tx,
.item_size350 .soldout .tx {
  font-size: 18px;
}

.item_size290 .adultbx .adult,
.item_size350 .adultbx .adult {
  width: 63px;
  height: 63px;
  background-position: -97px 0;
}

.itembx.item_size290 .item_promotion span,
.itembx.item_size350 .item_promotion span {
  width: 33%;
  overflow: hidden;
}

.item_size290 .thumbnail {
  width: 290px;
  height: 290px;
}

.item_size290 .soldout {
  background-image: url(../../img/common/bg_soldout_290x290.png);
}

.item_size290 .soldout.adultbx {
  background-image: url(../../img/common/bg_adult_290x290.png);
}

.item_size350 .thumbnail {
  width: 350px;
  height: 350px;
}

.item_size350 .summary {
  height: 34px;
}

.item_size350 .soldout {
  background-image: url(../../img/common/bg_soldout_350x350.png);
}

.item_size350 .soldout.adultbx {
  background-image: url(../../img/common/bg_adult_350x350.png);
}

.item_size350 .soldout .date {
  margin-top: 68px;
  font-size: 12px;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
  color: #999;
}

.item_size420 .thumbnail {
  width: 420px;
  height: 420px;
}

.item_size420 .thumbnail .soldout {
  background: url(../../img/common/bg_soldout_420x420.png);
}

.item_size420 .thumbnail .soldout .date {
  margin-top: 67px;
  font-size: 16px;
}

.item_size420 .thumbnail .soldout.adultbx {
  background: url(../../img/common/bg_adult_420x420.png);
}

.itembx.item_size420 .item_promotion span {
  width: 33%;
}

.item_size420 .sale,
.item_size420 .gift,
.item_size420 .plus,
.item_size420 .s_price {
  width: 73px;
  height: 83px;
  margin-top: -17px;
  background-image: url(../../img/common/label_01_xxlarge.png);
  line-height: 165px;
}

.item_size420 .department {
  display: block;
  width: 67px;
  height: 67px;
  background-image: url(../../img/common/label_02_xxlarge.png);
  line-height: 83px;
  background-position: 0 -76px;
}

.item_size420 .holiday_delivery02 {
  display: block;
  width: 67px;
  height: 67px;
  background-image: url(../../img/common/sp_label.png);
  line-height: 83px;
  background-position: -300px -1350px;
}

.item_size420 .reserve {
  width: 108px;
  height: 67px;
  background-image: url(../../img/common/sp_label.png);
  background-position: -300px -750px;
  margin-left: -41px;
}

.item_size420 .reserve.bojung,
.item_size420 .reserve.center {
  background: url(../../img/common/sp_label.png) -300px -900px no-repeat !important;
}

.item_size420 .reserve.bojung2 {
  background: url(../../img/common/sp_label.png) -300px -1200px no-repeat !important;
}

.item_size450.special .thumbnail {
  width: 450px;
  height: 450px;
}

.item_size450.special .sale,
.item_size450.special .gift,
.item_size450.special .plus,
.item_size450.special .s_price {
  width: 73px;
  height: 83px;
  margin-top: -17px;
  background-image: url(../../img/common/sp_label.png);
  line-height: 165px;
}

.item_size450.special .reserve,
.item_size450.special .department,
.item_size450.special .holiday_delivery02 {
  width: 67px;
  height: 66px;
  background-image: url(../../img/common/sp_label.png);
  line-height: 83px;
}

.item_size450.special .sale {
  height: 52px;
  padding-top: 31px;
  font-size: 33px;
  line-height: normal;
  background-position: -300px -150px;
}

.item_size450.special .sale .persent {
  width: 15px;
  height: 14px;
  background-position: 0 -13px;
}

.item_size450.special .gift {
  background-position: -300px -600px;
  font-size: 20px;
}

.item_size450.special .plus {
  background-position: -300px -450px;
  font-size: 33px;
}

.item_size450.special .plus .plus1 {
  display: inline-block;
  width: 25px;
  height: 30px;
  background: url(../../img/common/sp_label.png) -300px -1650px no-repeat;
  vertical-align: -2px;
  margin-left: 5px;
}

.item_size450.special .s_price {
  background-position: 0 -706px;
  font-size: 33px;
}

.item_size450.special .soldout {
  background-image: url(../../img/common/bg_soldout_450x450.png);
}

.item_size450.special .soldout .bx {
  width: 320px;
  margin: 0 auto;
}

.item_size450.special .soldout .tt {
  font-size: 32px;
}

.item_size450.special .soldout .tx {
  font-size: 28px;
}

.item_size450 .thumbnail {
  width: 450px;
  height: 450px;
}

.itembx.item_size450 .item_promotion span {
  width: 33%;
}

.item_size450 .sale,
.item_size450 .gift,
.item_size450 .plus,
.item_size450 .reserve,
.item_size450 .traders_reserve,
.item_size450 .ssgdeliv,
.item_size450 .ssgdeliv_tr,
.item_size450 .ssgpick,
.item_size450 .ico_better,
.item_size450 .coupon_sale,
.item_size450 .holiday_delivery {
  width: 73px;
  height: 84px;
  margin-top: -17px;
  background-image: url(../../img/common/sp_label.png);
  line-height: 83px;
}

.item_size450 .sale {
  height: 47px;
  padding-top: 32px;
  font-size: 33px;
  line-height: normal;
  padding-bottom: 6px;
  background-position: -300px -150px;
}

.item_size450 .sale .persent {
  width: 15px;
  height: 14px;
  background-position: 0 -13px;
}

.item_size450 .coupon_sale {
  height: 49px;
  padding-top: 30px;
  font-size: 33px;
  line-height: normal;
  background-position: -300px -300px;
  padding-bottom: 6px;
}

.item_size450 .coupon_sale .persent {
  width: 15px;
  height: 14px;
  background-position: 0 -13px;
}

.item_size450 .gift {
  background-position: -300px -600px;
  font-size: 20px;
}

.item_size450 .plus {
  background-position: -300px -450px;
  font-size: 33px;
  line-height: 86px;
}

.item_size450 .plus .plus1 {
  display: inline-block;
  width: 25px;
  height: 30px;
  background: url(../../img/common/sp_label.png) -300px -1650px no-repeat;
  vertical-align: -2px;
  margin-left: 5px;
}

.item_size450 .ico_better {
  background-position: -300px -1500px;
}

.item_size450 .reserve {
  width: 108px;
  height: 66px;
  margin: 0 0 0 -35px;
  background-position: -300px -750px;
}

.item_size450 .reserve.bojung,
.item_size450 .reserve.center {
  background-position: -300px -900px;
}

.item_size450 .reserve.bojung2 {
  width: 90px;
  height: 68px;
  background: url(../../img/common/sp_delivery.png) 0 0 no-repeat;
  margin: 0 0 0 -17px;
}

.item_size450 .traders_reserve {
  width: 108px;
  height: 66px;
  margin: 0 0 0 -35px;
  background-position: -300px -1050px;
}

.item_size450 .reserve.ico_pickup {
  width: 108px;
  height: 76px;
  background-position: -296px -1950px;
}

.item_size450 .traders_reserve.ico_pickup {
  width: 108px;
  height: 76px;
  background-position: -296px -1860px;
}

.item_size450 .holiday_delivery {
  width: 70px;
  height: 70px;
  margin: 0 0 0 -3px;
  background: url(../../img/common/sp_bdg.png) -100px -1350px no-repeat;
}

.item_size450 .ssgdeliv {
  width: 90px;
  height: 94px;
  margin: 0 0 0 -17px;
  background-position: -300px -2040px;
}

.item_size450 .ssgdeliv_tr {
  width: 90px;
  height: 94px;
  margin: 0 0 0 -17px;
  background-position: -300px -2140px;
}

.item_size450 .ssgdeliv_sd {
  width: 90px;
  height: 94px;
  margin: 0 0 0 -17px;
  background-position: -300px -2340px;
}

.item_size450 .ssgpick {
  width: 90px;
  height: 90px;
  margin: 0 0 0 -17px;
  background-position: -300px -2240px;
}

.item_size450 .soldout {
  background-image: url(../../img/common/bg_soldout_450x450.png);
}

.item_size450 .soldout.adultbx {
  background-image: url(../../img/common/bg_adult_450x450.png);
}

.item_size450 .soldout .tx {
  font-size: 26px;
}

.item_size450 .adultbx .adult {
  width: 94px;
  height: 94px;
  background-position: 0 0;
}

.item_size450 .item_info,
.item_size450 .item_ord {
  padding: 0 20px;
}

.item_size450 .holiday_delivery_em {
  width: 90px;
  height: 68px;
  background: url(../../img/common/sp_delivery.png) -100px 0 no-repeat !important;
  margin-left: -17px;
}

.item_size450 .sprshp {
  width: 75px;
  height: 76px;
  background: url(../../img/common/sp_sprshp.png) 0 0 no-repeat;
  margin: 0 0 0 -2px;
}

.item_size500.special .ico_right .thumbnail {
  width: 500px;
  height: 500px;
}

.item_size500.special .ico_right .holiday_delivery,
.item_size500.special .ico_right .reserve,
.item_size500.special .ico_right .traders_reserve,
.item_size500.special .ico_right .ssgdeliv,
.item_size500.special .ico_right .ssgdeliv_tr {
  width: 73px;
  height: 83px;
  margin-top: -17px;
  background-image: url(../../img/common/sp_label.png);
  line-height: 165px;
  background-position: 0 0;
}

.item_size500.special .ico_right .holiday_delivery02 {
  display: block;
  width: 70px;
  height: 70px;
  margin: 0 0 0 -3px;
  background: url(../../img/common/sp_bdg.png) -100px -1350px no-repeat;
}

.item_size500.special .ico_right .holiday_delivery02_em {
  display: block;
  width: 90px;
  height: 68px;
  background: url(../../img/common/sp_delivery.png) -100px 0 no-repeat !important;
  margin-left: -17px;
}

.item_size500.special .ico_right .holiday_delivery {
  display: block;
  width: 70px;
  height: 70px;
  margin: 0 0 0 -3px;
  background: url(../../img/common/sp_bdg.png) -100px -1350px no-repeat;
}

.item_size500.special .ico_right .reserve {
  width: 108px;
  height: 66px;
  margin-top: 0;
  margin-left: -41px;
  background-position: -300px -750px;
}

.item_size500.special .ico_right .reserve.bojung,
.item_size500.special .ico_right .reserve.center {
  background-position: -300px -900px;
}

.item_size500.special .ico_right .reserve.bojung2 {
  width: 90px;
  height: 68px;
  background: url(../../img/common/sp_delivery.png) 0 0 no-repeat;
}

.item_size500.special .ico_right .traders_reserve {
  width: 108px;
  height: 66px;
  margin-top: 0;
  margin-left: -41px;
  background-position: -300px -1050px;
}

.item_size500.special .ico_right .ssgdeliv {
  width: 90px;
  height: 94px;
  margin: 0 0 0 -23px;
  background-position: -300px -2040px;
}

.item_size500.special .ico_right .ssgdeliv_tr {
  width: 90px;
  height: 94px;
  margin: 0 0 0 -23px;
  background-position: -300px -2140px;
}

.item_size500.special .ico_right .ssgdeliv_sd {
  width: 90px;
  height: 94px;
  margin: 0 0 0 -23px;
  background-position: -300px -2340px;
}

.search_section .item_size500.special .soldout {
  background-image: url(../../img/common/bg_soldout_420x420.png);
}

.item_size500 .thumbnail {
  width: 450px;
  height: 450px;
}

.item_size500 .thumbnail .soldout .tx {
  content: "";
  display: block;
  width: 0;
  height: 0;
  font-size: 0;
  line-height: 0;
  visibility: hidden;
}

.itembx.item_size500 .item_promotion span {
  width: 33%;
}

.item_size500 .ico_left .sale,
.item_size500 .ico_left .gift,
.item_size500 .ico_left .plus,
.item_size500 .reserve,
.item_size500 .traders_reserve,
.item_size500 .ssgdeliv,
.item_size500 .ssgdeliv_tr,
.item_size500 .ico_left .coupon_sale,
.item_size500 .ico_left .ico_better,
.item_size500 .ico_left .holiday_delivery {
  width: 73px;
  height: 83px;
  margin-top: -17px;
  background-image: url(../../img/common/sp_label.png);
  line-height: 83px;
}

.item_size500 .ico_left .sale {
  height: 52px;
  padding-top: 31px;
  font-size: 33px;
  line-height: normal;
  background-position: -300px -150px;
}

.item_size500 .ico_left .sale .persent {
  width: 15px;
  height: 14px;
  background-position: 0 -13px;
}

.item_size500 .ico_left .coupon_sale {
  height: 52px;
  padding-top: 31px;
  font-size: 33px;
  line-height: normal;
  background-position: -300px -300px;
}

.item_size500 .ico_left .coupon_sale .persent {
  width: 15px;
  height: 14px;
  background-position: 0 -13px;
}

.item_size500 .ico_left .gift {
  background-position: -300px -600px;
  font-size: 20px;
}

.item_size500 .ico_left .plus {
  background-position: -300px -450px;
  font-size: 33px;
}

.item_size500 .ico_left .plus .plus1 {
  display: inline-block;
  width: 25px;
  height: 30px;
  background: url(../../img/common/sp_label.png) -300px -1650px no-repeat;
  vertical-align: -2px;
  margin-left: 5px;
}

.item_size500 .ico_better {
  background-position: -300px -1500px;
}

.item_size500 .reserve {
  width: 66px;
  height: 66px;
  margin: 0;
  background-position: -300px -750px;
}

.item_size500 .reserve.bojung,
.item_size500 .reserve.center {
  background-position: -300px -900px;
}

.item_size500 .reserve.bojung2 {
  background-position: -300px -1200px;
}

.item_size500 .traders_reserve {
  width: 66px;
  height: 66px;
  margin: 0;
  background-position: -300px -1050px;
}

.item_size500 .reserve.ico_pickup {
  width: 108px;
  height: 76px;
  background-position: -296px -1950px;
}

.item_size500 .traders_reserve.ico_pickup {
  width: 108px;
  height: 76px;
  background-position: -296px -1860px;
}

.item_size500 .ico_left .holiday_delivery {
  width: 66px;
  height: 66px;
  margin: 0;
  background-position: -300px -1350px;
}

.item_size500 .ssgdeliv {
  width: 90px;
  height: 94px;
  margin: 0;
  background-position: -300px -2040px;
}

.item_size500 .ssgdeliv_tr {
  width: 90px;
  height: 94px;
  margin: 0;
  background-position: -300px -2140px;
}

.item_size500 .soldout {
  background-image: url(../../img/common/bg_soldout_450x450.png);
}

.item_size500 .soldout .date {
  position: absolute;
  left: 179px;
  top: 276px;
  font-size: 16px;
  margin-top: 0;
  color: #999;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
}

.item_size500 .adultbx .adult {
  width: 94px;
  height: 94px;
  background-position: 0 0;
}

.item_size500 .sprshp {
  width: 75px;
  height: 76px;
  background: url(../../img/common/sp_sprshp.png) 0 0 no-repeat;
  margin-left: -8px;
}

/* 백화점 세일 딱지 */
.item_size168 .sd_sale,
.item_size140 .sd_sale {
  display: block;
  width: 52px;
  height: 52px;
  background: url(../../img/common/sp_sale.png) 100% -200px no-repeat;
  margin: 3px 0 0 -17px;
}

.item_size202 .sd_sale,
.item_size290 .sd_sale,
.item_size350 .sd_sale {
  display: block;
  width: 72px;
  height: 72px;
  background: url(../../img/common/sp_sale.png) 100% -100px no-repeat;
  margin: 0 0 0 -17px;
}

.item_size420 .sd_sale,
.item_size450 .sd_sale,
.item_size500 .sd_sale {
  display: block;
  width: 90px;
  height: 90px;
  background: url(../../img/common/sp_sale.png) 100% 0 no-repeat;
  margin: 0 0 0 -17px;
}

.item_size140 .bdg_sale,
.item_size168 .bdg_sale {
  display: block;
  width: 70px;
  height: 71px;
  background: url(../../img/common/spr_sale2.png) -639px -720px no-repeat;
  margin: 4px 0 0 -32px;
}

.item_size140 .bdg_sale.sl_01,
.item_size168 .bdg_sale.sl_01 {
  background-position: 0 0;
}

.item_size140 .bdg_sale.sl_02,
.item_size168 .bdg_sale.sl_02 {
  background-position: -71px 0;
}

.item_size140 .bdg_sale.sl_03,
.item_size168 .bdg_sale.sl_03 {
  background-position: -142px 0;
}

.item_size140 .bdg_sale.sl_04,
.item_size168 .bdg_sale.sl_04 {
  background-position: -213px 0;
}

.item_size140 .bdg_sale.sl_05,
.item_size168 .bdg_sale.sl_05 {
  background-position: -284px 0;
}

.item_size140 .bdg_sale.sl_06,
.item_size168 .bdg_sale.sl_06 {
  background-position: -355px 0;
}

.item_size140 .bdg_sale.sl_07,
.item_size168 .bdg_sale.sl_07 {
  background-position: -426px 0;
}

.item_size140 .bdg_sale.sl_08,
.item_size168 .bdg_sale.sl_08 {
  background-position: -497px 0;
}

.item_size140 .bdg_sale.sl_09,
.item_size168 .bdg_sale.sl_09 {
  background-position: -568px 0;
}

.item_size140 .bdg_sale.sl_10,
.item_size168 .bdg_sale.sl_10 {
  background-position: -639px 0;
}

.item_size140 .bdg_sale.sl_11,
.item_size168 .bdg_sale.sl_11 {
  background-position: 0 -80px;
}

.item_size140 .bdg_sale.sl_12,
.item_size168 .bdg_sale.sl_12 {
  background-position: -71px -80px;
}

.item_size140 .bdg_sale.sl_13,
.item_size168 .bdg_sale.sl_13 {
  background-position: -142px -80px;
}

.item_size140 .bdg_sale.sl_14,
.item_size168 .bdg_sale.sl_14 {
  background-position: -213px -80px;
}

.item_size140 .bdg_sale.sl_15,
.item_size168 .bdg_sale.sl_15 {
  background-position: -284px -80px;
}

.item_size140 .bdg_sale.sl_16,
.item_size168 .bdg_sale.sl_16 {
  background-position: -355px -80px;
}

.item_size140 .bdg_sale.sl_17,
.item_size168 .bdg_sale.sl_17 {
  background-position: -426px -80px;
}

.item_size140 .bdg_sale.sl_18,
.item_size168 .bdg_sale.sl_18 {
  background-position: -497px -80px;
}

.item_size140 .bdg_sale.sl_19,
.item_size168 .bdg_sale.sl_19 {
  background-position: -568px -80px;
}

.item_size140 .bdg_sale.sl_20,
.item_size168 .bdg_sale.sl_20 {
  background-position: -639px -80px;
}

.item_size140 .bdg_sale.sl_21,
.item_size168 .bdg_sale.sl_21 {
  background-position: 0 -160px;
}

.item_size140 .bdg_sale.sl_22,
.item_size168 .bdg_sale.sl_22 {
  background-position: -71px -160px;
}

.item_size140 .bdg_sale.sl_23,
.item_size168 .bdg_sale.sl_23 {
  background-position: -142px -160px;
}

.item_size140 .bdg_sale.sl_24,
.item_size168 .bdg_sale.sl_24 {
  background-position: -213px -160px;
}

.item_size140 .bdg_sale.sl_25,
.item_size168 .bdg_sale.sl_25 {
  background-position: -284px -160px;
}

.item_size140 .bdg_sale.sl_26,
.item_size168 .bdg_sale.sl_26 {
  background-position: -355px -160px;
}

.item_size140 .bdg_sale.sl_27,
.item_size168 .bdg_sale.sl_27 {
  background-position: -426px -160px;
}

.item_size140 .bdg_sale.sl_28,
.item_size168 .bdg_sale.sl_28 {
  background-position: -497px -160px;
}

.item_size140 .bdg_sale.sl_29,
.item_size168 .bdg_sale.sl_29 {
  background-position: -568px -160px;
}

.item_size140 .bdg_sale.sl_30,
.item_size168 .bdg_sale.sl_30 {
  background-position: -639px -160px;
}

.item_size140 .bdg_sale.sl_31,
.item_size168 .bdg_sale.sl_31 {
  background-position: 0 -240px;
}

.item_size140 .bdg_sale.sl_32,
.item_size168 .bdg_sale.sl_32 {
  background-position: -71px -240px;
}

.item_size140 .bdg_sale.sl_33,
.item_size168 .bdg_sale.sl_33 {
  background-position: -142px -240px;
}

.item_size140 .bdg_sale.sl_34,
.item_size168 .bdg_sale.sl_34 {
  background-position: -213px -240px;
}

.item_size140 .bdg_sale.sl_35,
.item_size168 .bdg_sale.sl_35 {
  background-position: -284px -240px;
}

.item_size140 .bdg_sale.sl_36,
.item_size168 .bdg_sale.sl_36 {
  background-position: -355px -240px;
}

.item_size140 .bdg_sale.sl_37,
.item_size168 .bdg_sale.sl_37 {
  background-position: -426px -240px;
}

.item_size140 .bdg_sale.sl_38,
.item_size168 .bdg_sale.sl_38 {
  background-position: -497px -240px;
}

.item_size140 .bdg_sale.sl_39,
.item_size168 .bdg_sale.sl_39 {
  background-position: -568px -240px;
}

.item_size140 .bdg_sale.sl_40,
.item_size168 .bdg_sale.sl_40 {
  background-position: -639px -240px;
}

.item_size140 .bdg_sale.sl_41,
.item_size168 .bdg_sale.sl_41 {
  background-position: 0 -320px;
}

.item_size140 .bdg_sale.sl_42,
.item_size168 .bdg_sale.sl_42 {
  background-position: -71px -320px;
}

.item_size140 .bdg_sale.sl_43,
.item_size168 .bdg_sale.sl_43 {
  background-position: -142px -320px;
}

.item_size140 .bdg_sale.sl_44,
.item_size168 .bdg_sale.sl_44 {
  background-position: -213px -320px;
}

.item_size140 .bdg_sale.sl_45,
.item_size168 .bdg_sale.sl_45 {
  background-position: -284px -320px;
}

.item_size140 .bdg_sale.sl_46,
.item_size168 .bdg_sale.sl_46 {
  background-position: -355px -320px;
}

.item_size140 .bdg_sale.sl_47,
.item_size168 .bdg_sale.sl_47 {
  background-position: -426px -320px;
}

.item_size140 .bdg_sale.sl_48,
.item_size168 .bdg_sale.sl_48 {
  background-position: -497px -320px;
}

.item_size140 .bdg_sale.sl_49,
.item_size168 .bdg_sale.sl_49 {
  background-position: -568px -320px;
}

.item_size140 .bdg_sale.sl_50,
.item_size168 .bdg_sale.sl_50 {
  background-position: -639px -320px;
}

.item_size140 .bdg_sale.sl_51,
.item_size168 .bdg_sale.sl_51 {
  background-position: 0 -400px;
}

.item_size140 .bdg_sale.sl_52,
.item_size168 .bdg_sale.sl_52 {
  background-position: -71px -400px;
}

.item_size140 .bdg_sale.sl_53,
.item_size168 .bdg_sale.sl_53 {
  background-position: -142px -400px;
}

.item_size140 .bdg_sale.sl_54,
.item_size168 .bdg_sale.sl_54 {
  background-position: -213px -400px;
}

.item_size140 .bdg_sale.sl_55,
.item_size168 .bdg_sale.sl_55 {
  background-position: -284px -400px;
}

.item_size140 .bdg_sale.sl_56,
.item_size168 .bdg_sale.sl_56 {
  background-position: -355px -400px;
}

.item_size140 .bdg_sale.sl_57,
.item_size168 .bdg_sale.sl_57 {
  background-position: -426px -400px;
}

.item_size140 .bdg_sale.sl_58,
.item_size168 .bdg_sale.sl_58 {
  background-position: -497px -400px;
}

.item_size140 .bdg_sale.sl_59,
.item_size168 .bdg_sale.sl_59 {
  background-position: -568px -400px;
}

.item_size140 .bdg_sale.sl_60,
.item_size168 .bdg_sale.sl_60 {
  background-position: -639px -400px;
}

.item_size140 .bdg_sale.sl_61,
.item_size168 .bdg_sale.sl_61 {
  background-position: 0 -480px;
}

.item_size140 .bdg_sale.sl_62,
.item_size168 .bdg_sale.sl_62 {
  background-position: -71px -480px;
}

.item_size140 .bdg_sale.sl_63,
.item_size168 .bdg_sale.sl_63 {
  background-position: -142px -480px;
}

.item_size140 .bdg_sale.sl_64,
.item_size168 .bdg_sale.sl_64 {
  background-position: -213px -480px;
}

.item_size140 .bdg_sale.sl_65,
.item_size168 .bdg_sale.sl_65 {
  background-position: -284px -480px;
}

.item_size140 .bdg_sale.sl_66,
.item_size168 .bdg_sale.sl_66 {
  background-position: -355px -480px;
}

.item_size140 .bdg_sale.sl_67,
.item_size168 .bdg_sale.sl_67 {
  background-position: -426px -480px;
}

.item_size140 .bdg_sale.sl_68,
.item_size168 .bdg_sale.sl_68 {
  background-position: -497px -480px;
}

.item_size140 .bdg_sale.sl_69,
.item_size168 .bdg_sale.sl_69 {
  background-position: -568px -480px;
}

.item_size140 .bdg_sale.sl_70,
.item_size168 .bdg_sale.sl_70 {
  background-position: -639px -480px;
}

.item_size140 .bdg_sale.sl_71,
.item_size168 .bdg_sale.sl_71 {
  background-position: 0 -560px;
}

.item_size140 .bdg_sale.sl_72,
.item_size168 .bdg_sale.sl_72 {
  background-position: -71px -560px;
}

.item_size140 .bdg_sale.sl_73,
.item_size168 .bdg_sale.sl_73 {
  background-position: -142px -560px;
}

.item_size140 .bdg_sale.sl_74,
.item_size168 .bdg_sale.sl_74 {
  background-position: -213px -560px;
}

.item_size140 .bdg_sale.sl_75,
.item_size168 .bdg_sale.sl_75 {
  background-position: -284px -560px;
}

.item_size140 .bdg_sale.sl_76,
.item_size168 .bdg_sale.sl_76 {
  background-position: -355px -560px;
}

.item_size140 .bdg_sale.sl_77,
.item_size168 .bdg_sale.sl_77 {
  background-position: -426px -560px;
}

.item_size140 .bdg_sale.sl_78,
.item_size168 .bdg_sale.sl_78 {
  background-position: -497px -560px;
}

.item_size140 .bdg_sale.sl_79,
.item_size168 .bdg_sale.sl_79 {
  background-position: -568px -560px;
}

.item_size140 .bdg_sale.sl_80,
.item_size168 .bdg_sale.sl_80 {
  background-position: -639px -560px;
}

.item_size140 .bdg_sale.sl_81,
.item_size168 .bdg_sale.sl_81 {
  background-position: 0 -640px;
}

.item_size140 .bdg_sale.sl_82,
.item_size168 .bdg_sale.sl_82 {
  background-position: -71px -640px;
}

.item_size140 .bdg_sale.sl_83,
.item_size168 .bdg_sale.sl_83 {
  background-position: -142px -640px;
}

.item_size140 .bdg_sale.sl_84,
.item_size168 .bdg_sale.sl_84 {
  background-position: -213px -640px;
}

.item_size140 .bdg_sale.sl_85,
.item_size168 .bdg_sale.sl_85 {
  background-position: -284px -640px;
}

.item_size140 .bdg_sale.sl_86,
.item_size168 .bdg_sale.sl_86 {
  background-position: -355px -640px;
}

.item_size140 .bdg_sale.sl_87,
.item_size168 .bdg_sale.sl_87 {
  background-position: -426px -640px;
}

.item_size140 .bdg_sale.sl_88,
.item_size168 .bdg_sale.sl_88 {
  background-position: -497px -640px;
}

.item_size140 .bdg_sale.sl_89,
.item_size168 .bdg_sale.sl_89 {
  background-position: -568px -640px;
}

.item_size140 .bdg_sale.sl_90,
.item_size168 .bdg_sale.sl_90 {
  background-position: -639px -640px;
}

.item_size140 .bdg_sale.sl_91,
.item_size168 .bdg_sale.sl_91 {
  background-position: 0 -720px;
}

.item_size140 .bdg_sale.sl_92,
.item_size168 .bdg_sale.sl_92 {
  background-position: -71px -720px;
}

.item_size140 .bdg_sale.sl_93,
.item_size168 .bdg_sale.sl_93 {
  background-position: -142px -720px;
}

.item_size140 .bdg_sale.sl_94,
.item_size168 .bdg_sale.sl_94 {
  background-position: -213px -720px;
}

.item_size140 .bdg_sale.sl_95,
.item_size168 .bdg_sale.sl_95 {
  background-position: -284px -720px;
}

.item_size140 .bdg_sale.sl_96,
.item_size168 .bdg_sale.sl_96 {
  background-position: -355px -720px;
}

.item_size140 .bdg_sale.sl_97,
.item_size168 .bdg_sale.sl_97 {
  background-position: -426px -720px;
}

.item_size140 .bdg_sale.sl_98,
.item_size168 .bdg_sale.sl_98 {
  background-position: -497px -720px;
}

.item_size140 .bdg_sale.sl_99,
.item_size168 .bdg_sale.sl_99 {
  background-position: -568px -720px;
}

.item_size202 .bdg_sale,
.item_size290 .bdg_sale,
.item_size350 .bdg_sale {
  display: block;
  width: 88px;
  height: 88px;
  background: url(../../img/common/spr_sale.png) -828px -900px no-repeat;
  margin: 0 0 0 -36px;
}

.item_size202 .bdg_sale.sl_01,
.item_size290 .bdg_sale.sl_01,
.item_size350 .bdg_sale.sl_01 {
  background-position: 0 0;
}

.item_size202 .bdg_sale.sl_02,
.item_size290 .bdg_sale.sl_02,
.item_size350 .bdg_sale.sl_02 {
  background-position: -92px 0;
}

.item_size202 .bdg_sale.sl_03,
.item_size290 .bdg_sale.sl_03,
.item_size350 .bdg_sale.sl_03 {
  background-position: -184px 0;
}

.item_size202 .bdg_sale.sl_04,
.item_size290 .bdg_sale.sl_04,
.item_size350 .bdg_sale.sl_04 {
  background-position: -276px 0;
}

.item_size202 .bdg_sale.sl_05,
.item_size290 .bdg_sale.sl_05,
.item_size350 .bdg_sale.sl_05 {
  background-position: -368px 0;
}

.item_size202 .bdg_sale.sl_06,
.item_size290 .bdg_sale.sl_06,
.item_size350 .bdg_sale.sl_06 {
  background-position: -460px 0;
}

.item_size202 .bdg_sale.sl_07,
.item_size290 .bdg_sale.sl_07,
.item_size350 .bdg_sale.sl_07 {
  background-position: -552px 0;
}

.item_size202 .bdg_sale.sl_08,
.item_size290 .bdg_sale.sl_08,
.item_size350 .bdg_sale.sl_08 {
  background-position: -644px 0;
}

.item_size202 .bdg_sale.sl_09,
.item_size290 .bdg_sale.sl_09,
.item_size350 .bdg_sale.sl_09 {
  background-position: -736px 0;
}

.item_size202 .bdg_sale.sl_10,
.item_size290 .bdg_sale.sl_10,
.item_size350 .bdg_sale.sl_10 {
  background-position: -828px 0;
}

.item_size202 .bdg_sale.sl_11,
.item_size290 .bdg_sale.sl_11,
.item_size350 .bdg_sale.sl_11 {
  background-position: 0 -100px;
}

.item_size202 .bdg_sale.sl_12,
.item_size290 .bdg_sale.sl_12,
.item_size350 .bdg_sale.sl_12 {
  background-position: -92px -100px;
}

.item_size202 .bdg_sale.sl_13,
.item_size290 .bdg_sale.sl_13,
.item_size350 .bdg_sale.sl_13 {
  background-position: -184px -100px;
}

.item_size202 .bdg_sale.sl_14,
.item_size290 .bdg_sale.sl_14,
.item_size350 .bdg_sale.sl_14 {
  background-position: -276px -100px;
}

.item_size202 .bdg_sale.sl_15,
.item_size290 .bdg_sale.sl_15,
.item_size350 .bdg_sale.sl_15 {
  background-position: -368px -100px;
}

.item_size202 .bdg_sale.sl_16,
.item_size290 .bdg_sale.sl_16,
.item_size350 .bdg_sale.sl_16 {
  background-position: -460px -100px;
}

.item_size202 .bdg_sale.sl_17,
.item_size290 .bdg_sale.sl_17,
.item_size350 .bdg_sale.sl_17 {
  background-position: -552px -100px;
}

.item_size202 .bdg_sale.sl_18,
.item_size290 .bdg_sale.sl_18,
.item_size350 .bdg_sale.sl_18 {
  background-position: -644px -100px;
}

.item_size202 .bdg_sale.sl_19,
.item_size290 .bdg_sale.sl_19,
.item_size350 .bdg_sale.sl_19 {
  background-position: -736px -100px;
}

.item_size202 .bdg_sale.sl_20,
.item_size290 .bdg_sale.sl_20,
.item_size350 .bdg_sale.sl_20 {
  background-position: -828px -100px;
}

.item_size202 .bdg_sale.sl_21,
.item_size290 .bdg_sale.sl_21,
.item_size350 .bdg_sale.sl_21 {
  background-position: 0 -200px;
}

.item_size202 .bdg_sale.sl_22,
.item_size290 .bdg_sale.sl_22,
.item_size350 .bdg_sale.sl_22 {
  background-position: -92px -200px;
}

.item_size202 .bdg_sale.sl_23,
.item_size290 .bdg_sale.sl_23,
.item_size350 .bdg_sale.sl_23 {
  background-position: -184px -200px;
}

.item_size202 .bdg_sale.sl_24,
.item_size290 .bdg_sale.sl_24,
.item_size350 .bdg_sale.sl_24 {
  background-position: -276px -200px;
}

.item_size202 .bdg_sale.sl_25,
.item_size290 .bdg_sale.sl_25,
.item_size350 .bdg_sale.sl_25 {
  background-position: -368px -200px;
}

.item_size202 .bdg_sale.sl_26,
.item_size290 .bdg_sale.sl_26,
.item_size350 .bdg_sale.sl_26 {
  background-position: -460px -200px;
}

.item_size202 .bdg_sale.sl_27,
.item_size290 .bdg_sale.sl_27,
.item_size350 .bdg_sale.sl_27 {
  background-position: -552px -200px;
}

.item_size202 .bdg_sale.sl_28,
.item_size290 .bdg_sale.sl_28,
.item_size350 .bdg_sale.sl_28 {
  background-position: -644px -200px;
}

.item_size202 .bdg_sale.sl_29,
.item_size290 .bdg_sale.sl_29,
.item_size350 .bdg_sale.sl_29 {
  background-position: -736px -200px;
}

.item_size202 .bdg_sale.sl_30,
.item_size290 .bdg_sale.sl_30,
.item_size350 .bdg_sale.sl_30 {
  background-position: -828px -200px;
}

.item_size202 .bdg_sale.sl_31,
.item_size290 .bdg_sale.sl_31,
.item_size350 .bdg_sale.sl_31 {
  background-position: 0 -300px;
}

.item_size202 .bdg_sale.sl_32,
.item_size290 .bdg_sale.sl_32,
.item_size350 .bdg_sale.sl_32 {
  background-position: -92px -300px;
}

.item_size202 .bdg_sale.sl_33,
.item_size290 .bdg_sale.sl_33,
.item_size350 .bdg_sale.sl_33 {
  background-position: -184px -300px;
}

.item_size202 .bdg_sale.sl_34,
.item_size290 .bdg_sale.sl_34,
.item_size350 .bdg_sale.sl_34 {
  background-position: -276px -300px;
}

.item_size202 .bdg_sale.sl_35,
.item_size290 .bdg_sale.sl_35,
.item_size350 .bdg_sale.sl_35 {
  background-position: -368px -300px;
}

.item_size202 .bdg_sale.sl_36,
.item_size290 .bdg_sale.sl_36,
.item_size350 .bdg_sale.sl_36 {
  background-position: -460px -300px;
}

.item_size202 .bdg_sale.sl_37,
.item_size290 .bdg_sale.sl_37,
.item_size350 .bdg_sale.sl_37 {
  background-position: -552px -300px;
}

.item_size202 .bdg_sale.sl_38,
.item_size290 .bdg_sale.sl_38,
.item_size350 .bdg_sale.sl_38 {
  background-position: -644px -300px;
}

.item_size202 .bdg_sale.sl_39,
.item_size290 .bdg_sale.sl_39,
.item_size350 .bdg_sale.sl_39 {
  background-position: -736px -300px;
}

.item_size202 .bdg_sale.sl_40,
.item_size290 .bdg_sale.sl_40,
.item_size350 .bdg_sale.sl_40 {
  background-position: -828px -300px;
}

.item_size202 .bdg_sale.sl_41,
.item_size290 .bdg_sale.sl_41,
.item_size350 .bdg_sale.sl_41 {
  background-position: 0 -400px;
}

.item_size202 .bdg_sale.sl_42,
.item_size290 .bdg_sale.sl_42,
.item_size350 .bdg_sale.sl_42 {
  background-position: -92px -400px;
}

.item_size202 .bdg_sale.sl_43,
.item_size290 .bdg_sale.sl_43,
.item_size350 .bdg_sale.sl_43 {
  background-position: -184px -400px;
}

.item_size202 .bdg_sale.sl_44,
.item_size290 .bdg_sale.sl_44,
.item_size350 .bdg_sale.sl_44 {
  background-position: -276px -400px;
}

.item_size202 .bdg_sale.sl_45,
.item_size290 .bdg_sale.sl_45,
.item_size350 .bdg_sale.sl_45 {
  background-position: -368px -400px;
}

.item_size202 .bdg_sale.sl_46,
.item_size290 .bdg_sale.sl_46,
.item_size350 .bdg_sale.sl_46 {
  background-position: -460px -400px;
}

.item_size202 .bdg_sale.sl_47,
.item_size290 .bdg_sale.sl_47,
.item_size350 .bdg_sale.sl_47 {
  background-position: -552px -400px;
}

.item_size202 .bdg_sale.sl_48,
.item_size290 .bdg_sale.sl_48,
.item_size350 .bdg_sale.sl_48 {
  background-position: -644px -400px;
}

.item_size202 .bdg_sale.sl_49,
.item_size290 .bdg_sale.sl_49,
.item_size350 .bdg_sale.sl_49 {
  background-position: -736px -400px;
}

.item_size202 .bdg_sale.sl_50,
.item_size290 .bdg_sale.sl_50,
.item_size350 .bdg_sale.sl_50 {
  background-position: -828px -400px;
}

.item_size202 .bdg_sale.sl_51,
.item_size290 .bdg_sale.sl_51,
.item_size350 .bdg_sale.sl_51 {
  background-position: 0 -500px;
}

.item_size202 .bdg_sale.sl_52,
.item_size290 .bdg_sale.sl_52,
.item_size350 .bdg_sale.sl_52 {
  background-position: -92px -500px;
}

.item_size202 .bdg_sale.sl_53,
.item_size290 .bdg_sale.sl_53,
.item_size350 .bdg_sale.sl_53 {
  background-position: -184px -500px;
}

.item_size202 .bdg_sale.sl_54,
.item_size290 .bdg_sale.sl_54,
.item_size350 .bdg_sale.sl_54 {
  background-position: -276px -500px;
}

.item_size202 .bdg_sale.sl_55,
.item_size290 .bdg_sale.sl_55,
.item_size350 .bdg_sale.sl_55 {
  background-position: -368px -500px;
}

.item_size202 .bdg_sale.sl_56,
.item_size290 .bdg_sale.sl_56,
.item_size350 .bdg_sale.sl_56 {
  background-position: -460px -500px;
}

.item_size202 .bdg_sale.sl_57,
.item_size290 .bdg_sale.sl_57,
.item_size350 .bdg_sale.sl_57 {
  background-position: -552px -500px;
}

.item_size202 .bdg_sale.sl_58,
.item_size290 .bdg_sale.sl_58,
.item_size350 .bdg_sale.sl_58 {
  background-position: -644px -500px;
}

.item_size202 .bdg_sale.sl_59,
.item_size290 .bdg_sale.sl_59,
.item_size350 .bdg_sale.sl_59 {
  background-position: -736px -500px;
}

.item_size202 .bdg_sale.sl_60,
.item_size290 .bdg_sale.sl_60,
.item_size350 .bdg_sale.sl_60 {
  background-position: -828px -500px;
}

.item_size202 .bdg_sale.sl_61,
.item_size290 .bdg_sale.sl_61,
.item_size350 .bdg_sale.sl_61 {
  background-position: 0 -600px;
}

.item_size202 .bdg_sale.sl_62,
.item_size290 .bdg_sale.sl_62,
.item_size350 .bdg_sale.sl_62 {
  background-position: -92px -600px;
}

.item_size202 .bdg_sale.sl_63,
.item_size290 .bdg_sale.sl_63,
.item_size350 .bdg_sale.sl_63 {
  background-position: -184px -600px;
}

.item_size202 .bdg_sale.sl_64,
.item_size290 .bdg_sale.sl_64,
.item_size350 .bdg_sale.sl_64 {
  background-position: -276px -600px;
}

.item_size202 .bdg_sale.sl_65,
.item_size290 .bdg_sale.sl_65,
.item_size350 .bdg_sale.sl_65 {
  background-position: -368px -600px;
}

.item_size202 .bdg_sale.sl_66,
.item_size290 .bdg_sale.sl_66,
.item_size350 .bdg_sale.sl_66 {
  background-position: -460px -600px;
}

.item_size202 .bdg_sale.sl_67,
.item_size290 .bdg_sale.sl_67,
.item_size350 .bdg_sale.sl_67 {
  background-position: -552px -600px;
}

.item_size202 .bdg_sale.sl_68,
.item_size290 .bdg_sale.sl_68,
.item_size350 .bdg_sale.sl_68 {
  background-position: -644px -600px;
}

.item_size202 .bdg_sale.sl_69,
.item_size290 .bdg_sale.sl_69,
.item_size350 .bdg_sale.sl_69 {
  background-position: -736px -600px;
}

.item_size202 .bdg_sale.sl_70,
.item_size290 .bdg_sale.sl_70,
.item_size350 .bdg_sale.sl_70 {
  background-position: -828px -600px;
}

.item_size202 .bdg_sale.sl_71,
.item_size290 .bdg_sale.sl_71,
.item_size350 .bdg_sale.sl_71 {
  background-position: 0 -700px;
}

.item_size202 .bdg_sale.sl_72,
.item_size290 .bdg_sale.sl_72,
.item_size350 .bdg_sale.sl_72 {
  background-position: -92px -700px;
}

.item_size202 .bdg_sale.sl_73,
.item_size290 .bdg_sale.sl_73,
.item_size350 .bdg_sale.sl_73 {
  background-position: -184px -700px;
}

.item_size202 .bdg_sale.sl_74,
.item_size290 .bdg_sale.sl_74,
.item_size350 .bdg_sale.sl_74 {
  background-position: -276px -700px;
}

.item_size202 .bdg_sale.sl_75,
.item_size290 .bdg_sale.sl_75,
.item_size350 .bdg_sale.sl_75 {
  background-position: -368px -700px;
}

.item_size202 .bdg_sale.sl_76,
.item_size290 .bdg_sale.sl_76,
.item_size350 .bdg_sale.sl_76 {
  background-position: -460px -700px;
}

.item_size202 .bdg_sale.sl_77,
.item_size290 .bdg_sale.sl_77,
.item_size350 .bdg_sale.sl_77 {
  background-position: -552px -700px;
}

.item_size202 .bdg_sale.sl_78,
.item_size290 .bdg_sale.sl_78,
.item_size350 .bdg_sale.sl_78 {
  background-position: -644px -700px;
}

.item_size202 .bdg_sale.sl_79,
.item_size290 .bdg_sale.sl_79,
.item_size350 .bdg_sale.sl_79 {
  background-position: -736px -700px;
}

.item_size202 .bdg_sale.sl_80,
.item_size290 .bdg_sale.sl_80,
.item_size350 .bdg_sale.sl_80 {
  background-position: -828px -700px;
}

.item_size202 .bdg_sale.sl_81,
.item_size290 .bdg_sale.sl_81,
.item_size350 .bdg_sale.sl_81 {
  background-position: 0 -800px;
}

.item_size202 .bdg_sale.sl_82,
.item_size290 .bdg_sale.sl_82,
.item_size350 .bdg_sale.sl_82 {
  background-position: -92px -800px;
}

.item_size202 .bdg_sale.sl_83,
.item_size290 .bdg_sale.sl_83,
.item_size350 .bdg_sale.sl_83 {
  background-position: -184px -800px;
}

.item_size202 .bdg_sale.sl_84,
.item_size290 .bdg_sale.sl_84,
.item_size350 .bdg_sale.sl_84 {
  background-position: -276px -800px;
}

.item_size202 .bdg_sale.sl_85,
.item_size290 .bdg_sale.sl_85,
.item_size350 .bdg_sale.sl_85 {
  background-position: -368px -800px;
}

.item_size202 .bdg_sale.sl_86,
.item_size290 .bdg_sale.sl_86,
.item_size350 .bdg_sale.sl_86 {
  background-position: -460px -800px;
}

.item_size202 .bdg_sale.sl_87,
.item_size290 .bdg_sale.sl_87,
.item_size350 .bdg_sale.sl_87 {
  background-position: -552px -800px;
}

.item_size202 .bdg_sale.sl_88,
.item_size290 .bdg_sale.sl_88,
.item_size350 .bdg_sale.sl_88 {
  background-position: -644px -800px;
}

.item_size202 .bdg_sale.sl_89,
.item_size290 .bdg_sale.sl_89,
.item_size350 .bdg_sale.sl_89 {
  background-position: -736px -800px;
}

.item_size202 .bdg_sale.sl_90,
.item_size290 .bdg_sale.sl_90,
.item_size350 .bdg_sale.sl_90 {
  background-position: -828px -800px;
}

.item_size202 .bdg_sale.sl_91,
.item_size290 .bdg_sale.sl_91,
.item_size350 .bdg_sale.sl_91 {
  background-position: 0 -900px;
}

.item_size202 .bdg_sale.sl_92,
.item_size290 .bdg_sale.sl_92,
.item_size350 .bdg_sale.sl_92 {
  background-position: -92px -900px;
}

.item_size202 .bdg_sale.sl_93,
.item_size290 .bdg_sale.sl_93,
.item_size350 .bdg_sale.sl_93 {
  background-position: -184px -900px;
}

.item_size202 .bdg_sale.sl_94,
.item_size290 .bdg_sale.sl_94,
.item_size350 .bdg_sale.sl_94 {
  background-position: -276px -900px;
}

.item_size202 .bdg_sale.sl_95,
.item_size290 .bdg_sale.sl_95,
.item_size350 .bdg_sale.sl_95 {
  background-position: -368px -900px;
}

.item_size202 .bdg_sale.sl_96,
.item_size290 .bdg_sale.sl_96,
.item_size350 .bdg_sale.sl_96 {
  background-position: -460px -900px;
}

.item_size202 .bdg_sale.sl_97,
.item_size290 .bdg_sale.sl_97,
.item_size350 .bdg_sale.sl_97 {
  background-position: -552px -900px;
}

.item_size202 .bdg_sale.sl_98,
.item_size290 .bdg_sale.sl_98,
.item_size350 .bdg_sale.sl_98 {
  background-position: -644px -900px;
}

.item_size202 .bdg_sale.sl_99,
.item_size290 .bdg_sale.sl_99,
.item_size350 .bdg_sale.sl_99 {
  background-position: -736px -900px;
}

.item_size420 .bdg_sale,
.item_size450 .bdg_sale,
.item_size500 .bdg_sale {
  display: block;
  width: 106px;
  height: 105px;
  background: url(../../img/common/spr_detail_sale.png) -990px -1080px no-repeat;
  margin: 0 0 0 -33px;
}

.item_size420 .bdg_sale.sl_01,
.item_size450 .bdg_sale.sl_01,
.item_size500 .bdg_sale.sl_01 {
  background-position: 0 0;
}

.item_size420 .bdg_sale.sl_02,
.item_size450 .bdg_sale.sl_02,
.item_size500 .bdg_sale.sl_02 {
  background-position: -110px 0;
}

.item_size420 .bdg_sale.sl_03,
.item_size450 .bdg_sale.sl_03,
.item_size500 .bdg_sale.sl_03 {
  background-position: -220px 0;
}

.item_size420 .bdg_sale.sl_04,
.item_size450 .bdg_sale.sl_04,
.item_size500 .bdg_sale.sl_04 {
  background-position: -330px 0;
}

.item_size420 .bdg_sale.sl_05,
.item_size450 .bdg_sale.sl_05,
.item_size500 .bdg_sale.sl_05 {
  background-position: -440px 0;
}

.item_size420 .bdg_sale.sl_06,
.item_size450 .bdg_sale.sl_06,
.item_size500 .bdg_sale.sl_06 {
  background-position: -550px 0;
}

.item_size420 .bdg_sale.sl_07,
.item_size450 .bdg_sale.sl_07,
.item_size500 .bdg_sale.sl_07 {
  background-position: -660px 0;
}

.item_size420 .bdg_sale.sl_08,
.item_size450 .bdg_sale.sl_08,
.item_size500 .bdg_sale.sl_08 {
  background-position: -770px 0;
}

.item_size420 .bdg_sale.sl_09,
.item_size450 .bdg_sale.sl_09,
.item_size500 .bdg_sale.sl_09 {
  background-position: -880px 0;
}

.item_size420 .bdg_sale.sl_10,
.item_size450 .bdg_sale.sl_10,
.item_size500 .bdg_sale.sl_10 {
  background-position: -990px 0;
}

.item_size420 .bdg_sale.sl_11,
.item_size450 .bdg_sale.sl_11,
.item_size500 .bdg_sale.sl_11 {
  background-position: 0 -120px;
}

.item_size420 .bdg_sale.sl_12,
.item_size450 .bdg_sale.sl_12,
.item_size500 .bdg_sale.sl_12 {
  background-position: -110px -120px;
}

.item_size420 .bdg_sale.sl_13,
.item_size450 .bdg_sale.sl_13,
.item_size500 .bdg_sale.sl_13 {
  background-position: -220px -120px;
}

.item_size420 .bdg_sale.sl_14,
.item_size450 .bdg_sale.sl_14,
.item_size500 .bdg_sale.sl_14 {
  background-position: -330px -120px;
}

.item_size420 .bdg_sale.sl_15,
.item_size450 .bdg_sale.sl_15,
.item_size500 .bdg_sale.sl_15 {
  background-position: -440px -120px;
}

.item_size420 .bdg_sale.sl_16,
.item_size450 .bdg_sale.sl_16,
.item_size500 .bdg_sale.sl_16 {
  background-position: -550px -120px;
}

.item_size420 .bdg_sale.sl_17,
.item_size450 .bdg_sale.sl_17,
.item_size500 .bdg_sale.sl_17 {
  background-position: -660px -120px;
}

.item_size420 .bdg_sale.sl_18,
.item_size450 .bdg_sale.sl_18,
.item_size500 .bdg_sale.sl_18 {
  background-position: -770px -120px;
}

.item_size420 .bdg_sale.sl_19,
.item_size450 .bdg_sale.sl_19,
.item_size500 .bdg_sale.sl_19 {
  background-position: -880px -120px;
}

.item_size420 .bdg_sale.sl_20,
.item_size450 .bdg_sale.sl_20,
.item_size500 .bdg_sale.sl_20 {
  background-position: -990px -120px;
}

.item_size420 .bdg_sale.sl_21,
.item_size450 .bdg_sale.sl_21,
.item_size500 .bdg_sale.sl_21 {
  background-position: 0 -240px;
}

.item_size420 .bdg_sale.sl_22,
.item_size450 .bdg_sale.sl_22,
.item_size500 .bdg_sale.sl_22 {
  background-position: -110px -240px;
}

.item_size420 .bdg_sale.sl_23,
.item_size450 .bdg_sale.sl_23,
.item_size500 .bdg_sale.sl_23 {
  background-position: -220px -240px;
}

.item_size420 .bdg_sale.sl_24,
.item_size450 .bdg_sale.sl_24,
.item_size500 .bdg_sale.sl_24 {
  background-position: -330px -240px;
}

.item_size420 .bdg_sale.sl_25,
.item_size450 .bdg_sale.sl_25,
.item_size500 .bdg_sale.sl_25 {
  background-position: -440px -240px;
}

.item_size420 .bdg_sale.sl_26,
.item_size450 .bdg_sale.sl_26,
.item_size500 .bdg_sale.sl_26 {
  background-position: -550px -240px;
}

.item_size420 .bdg_sale.sl_27,
.item_size450 .bdg_sale.sl_27,
.item_size500 .bdg_sale.sl_27 {
  background-position: -660px -240px;
}

.item_size420 .bdg_sale.sl_28,
.item_size450 .bdg_sale.sl_28,
.item_size500 .bdg_sale.sl_28 {
  background-position: -770px -240px;
}

.item_size420 .bdg_sale.sl_29,
.item_size450 .bdg_sale.sl_29,
.item_size500 .bdg_sale.sl_29 {
  background-position: -880px -240px;
}

.item_size420 .bdg_sale.sl_30,
.item_size450 .bdg_sale.sl_30,
.item_size500 .bdg_sale.sl_30 {
  background-position: -990px -240px;
}

.item_size420 .bdg_sale.sl_31,
.item_size450 .bdg_sale.sl_31,
.item_size500 .bdg_sale.sl_31 {
  background-position: 0 -360px;
}

.item_size420 .bdg_sale.sl_32,
.item_size450 .bdg_sale.sl_32,
.item_size500 .bdg_sale.sl_32 {
  background-position: -110px -360px;
}

.item_size420 .bdg_sale.sl_33,
.item_size450 .bdg_sale.sl_33,
.item_size500 .bdg_sale.sl_33 {
  background-position: -220px -360px;
}

.item_size420 .bdg_sale.sl_34,
.item_size450 .bdg_sale.sl_34,
.item_size500 .bdg_sale.sl_34 {
  background-position: -330px -360px;
}

.item_size420 .bdg_sale.sl_35,
.item_size450 .bdg_sale.sl_35,
.item_size500 .bdg_sale.sl_35 {
  background-position: -440px -360px;
}

.item_size420 .bdg_sale.sl_36,
.item_size450 .bdg_sale.sl_36,
.item_size500 .bdg_sale.sl_36 {
  background-position: -550px -360px;
}

.item_size420 .bdg_sale.sl_37,
.item_size450 .bdg_sale.sl_37,
.item_size500 .bdg_sale.sl_37 {
  background-position: -660px -360px;
}

.item_size420 .bdg_sale.sl_38,
.item_size450 .bdg_sale.sl_38,
.item_size500 .bdg_sale.sl_38 {
  background-position: -770px -360px;
}

.item_size420 .bdg_sale.sl_39,
.item_size450 .bdg_sale.sl_39,
.item_size500 .bdg_sale.sl_39 {
  background-position: -880px -360px;
}

.item_size420 .bdg_sale.sl_40,
.item_size450 .bdg_sale.sl_40,
.item_size500 .bdg_sale.sl_40 {
  background-position: -990px -360px;
}

.item_size420 .bdg_sale.sl_41,
.item_size450 .bdg_sale.sl_41,
.item_size500 .bdg_sale.sl_41 {
  background-position: 0 -480px;
}

.item_size420 .bdg_sale.sl_42,
.item_size450 .bdg_sale.sl_42,
.item_size500 .bdg_sale.sl_42 {
  background-position: -110px -480px;
}

.item_size420 .bdg_sale.sl_43,
.item_size450 .bdg_sale.sl_43,
.item_size500 .bdg_sale.sl_43 {
  background-position: -220px -480px;
}

.item_size420 .bdg_sale.sl_44,
.item_size450 .bdg_sale.sl_44,
.item_size500 .bdg_sale.sl_44 {
  background-position: -330px -480px;
}

.item_size420 .bdg_sale.sl_45,
.item_size450 .bdg_sale.sl_45,
.item_size500 .bdg_sale.sl_45 {
  background-position: -440px -480px;
}

.item_size420 .bdg_sale.sl_46,
.item_size450 .bdg_sale.sl_46,
.item_size500 .bdg_sale.sl_46 {
  background-position: -550px -480px;
}

.item_size420 .bdg_sale.sl_47,
.item_size450 .bdg_sale.sl_47,
.item_size500 .bdg_sale.sl_47 {
  background-position: -660px -480px;
}

.item_size420 .bdg_sale.sl_48,
.item_size450 .bdg_sale.sl_48,
.item_size500 .bdg_sale.sl_48 {
  background-position: -770px -480px;
}

.item_size420 .bdg_sale.sl_49,
.item_size450 .bdg_sale.sl_49,
.item_size500 .bdg_sale.sl_49 {
  background-position: -880px -480px;
}

.item_size420 .bdg_sale.sl_50,
.item_size450 .bdg_sale.sl_50,
.item_size500 .bdg_sale.sl_50 {
  background-position: -990px -480px;
}

.item_size420 .bdg_sale.sl_51,
.item_size450 .bdg_sale.sl_51,
.item_size500 .bdg_sale.sl_51 {
  background-position: 0 -600px;
}

.item_size420 .bdg_sale.sl_52,
.item_size450 .bdg_sale.sl_52,
.item_size500 .bdg_sale.sl_52 {
  background-position: -110px -600px;
}

.item_size420 .bdg_sale.sl_53,
.item_size450 .bdg_sale.sl_53,
.item_size500 .bdg_sale.sl_53 {
  background-position: -220px -600px;
}

.item_size420 .bdg_sale.sl_54,
.item_size450 .bdg_sale.sl_54,
.item_size500 .bdg_sale.sl_54 {
  background-position: -330px -600px;
}

.item_size420 .bdg_sale.sl_55,
.item_size450 .bdg_sale.sl_55,
.item_size500 .bdg_sale.sl_55 {
  background-position: -440px -600px;
}

.item_size420 .bdg_sale.sl_56,
.item_size450 .bdg_sale.sl_56,
.item_size500 .bdg_sale.sl_56 {
  background-position: -550px -600px;
}

.item_size420 .bdg_sale.sl_57,
.item_size450 .bdg_sale.sl_57,
.item_size500 .bdg_sale.sl_57 {
  background-position: -660px -600px;
}

.item_size420 .bdg_sale.sl_58,
.item_size450 .bdg_sale.sl_58,
.item_size500 .bdg_sale.sl_58 {
  background-position: -770px -600px;
}

.item_size420 .bdg_sale.sl_59,
.item_size450 .bdg_sale.sl_59,
.item_size500 .bdg_sale.sl_59 {
  background-position: -880px -600px;
}

.item_size420 .bdg_sale.sl_60,
.item_size450 .bdg_sale.sl_60,
.item_size500 .bdg_sale.sl_60 {
  background-position: -990px -600px;
}

.item_size420 .bdg_sale.sl_61,
.item_size450 .bdg_sale.sl_61,
.item_size500 .bdg_sale.sl_61 {
  background-position: 0 -720px;
}

.item_size420 .bdg_sale.sl_62,
.item_size450 .bdg_sale.sl_62,
.item_size500 .bdg_sale.sl_62 {
  background-position: -110px -720px;
}

.item_size420 .bdg_sale.sl_63,
.item_size450 .bdg_sale.sl_63,
.item_size500 .bdg_sale.sl_63 {
  background-position: -220px -720px;
}

.item_size420 .bdg_sale.sl_64,
.item_size450 .bdg_sale.sl_64,
.item_size500 .bdg_sale.sl_64 {
  background-position: -330px -720px;
}

.item_size420 .bdg_sale.sl_65,
.item_size450 .bdg_sale.sl_65,
.item_size500 .bdg_sale.sl_65 {
  background-position: -440px -720px;
}

.item_size420 .bdg_sale.sl_66,
.item_size450 .bdg_sale.sl_66,
.item_size500 .bdg_sale.sl_66 {
  background-position: -550px -720px;
}

.item_size420 .bdg_sale.sl_67,
.item_size450 .bdg_sale.sl_67,
.item_size500 .bdg_sale.sl_67 {
  background-position: -660px -720px;
}

.item_size420 .bdg_sale.sl_68,
.item_size450 .bdg_sale.sl_68,
.item_size500 .bdg_sale.sl_68 {
  background-position: -770px -720px;
}

.item_size420 .bdg_sale.sl_69,
.item_size450 .bdg_sale.sl_69,
.item_size500 .bdg_sale.sl_69 {
  background-position: -880px -720px;
}

.item_size420 .bdg_sale.sl_70,
.item_size450 .bdg_sale.sl_70,
.item_size500 .bdg_sale.sl_70 {
  background-position: -990px -720px;
}

.item_size420 .bdg_sale.sl_71,
.item_size450 .bdg_sale.sl_71,
.item_size500 .bdg_sale.sl_71 {
  background-position: 0 -840px;
}

.item_size420 .bdg_sale.sl_72,
.item_size450 .bdg_sale.sl_72,
.item_size500 .bdg_sale.sl_72 {
  background-position: -110px -840px;
}

.item_size420 .bdg_sale.sl_73,
.item_size450 .bdg_sale.sl_73,
.item_size500 .bdg_sale.sl_73 {
  background-position: -220px -840px;
}

.item_size420 .bdg_sale.sl_74,
.item_size450 .bdg_sale.sl_74,
.item_size500 .bdg_sale.sl_74 {
  background-position: -330px -840px;
}

.item_size420 .bdg_sale.sl_75,
.item_size450 .bdg_sale.sl_75,
.item_size500 .bdg_sale.sl_75 {
  background-position: -440px -840px;
}

.item_size420 .bdg_sale.sl_76,
.item_size450 .bdg_sale.sl_76,
.item_size500 .bdg_sale.sl_76 {
  background-position: -550px -840px;
}

.item_size420 .bdg_sale.sl_77,
.item_size450 .bdg_sale.sl_77,
.item_size500 .bdg_sale.sl_77 {
  background-position: -660px -840px;
}

.item_size420 .bdg_sale.sl_78,
.item_size450 .bdg_sale.sl_78,
.item_size500 .bdg_sale.sl_78 {
  background-position: -770px -840px;
}

.item_size420 .bdg_sale.sl_79,
.item_size450 .bdg_sale.sl_79,
.item_size500 .bdg_sale.sl_79 {
  background-position: -880px -840px;
}

.item_size420 .bdg_sale.sl_80,
.item_size450 .bdg_sale.sl_80,
.item_size500 .bdg_sale.sl_80 {
  background-position: -990px -840px;
}

.item_size420 .bdg_sale.sl_81,
.item_size450 .bdg_sale.sl_81,
.item_size500 .bdg_sale.sl_81 {
  background-position: 0 -960px;
}

.item_size420 .bdg_sale.sl_82,
.item_size450 .bdg_sale.sl_82,
.item_size500 .bdg_sale.sl_82 {
  background-position: -110px -960px;
}

.item_size420 .bdg_sale.sl_83,
.item_size450 .bdg_sale.sl_83,
.item_size500 .bdg_sale.sl_83 {
  background-position: -220px -960px;
}

.item_size420 .bdg_sale.sl_84,
.item_size450 .bdg_sale.sl_84,
.item_size500 .bdg_sale.sl_84 {
  background-position: -330px -960px;
}

.item_size420 .bdg_sale.sl_85,
.item_size450 .bdg_sale.sl_85,
.item_size500 .bdg_sale.sl_85 {
  background-position: -440px -960px;
}

.item_size420 .bdg_sale.sl_86,
.item_size450 .bdg_sale.sl_86,
.item_size500 .bdg_sale.sl_86 {
  background-position: -550px -960px;
}

.item_size420 .bdg_sale.sl_87,
.item_size450 .bdg_sale.sl_87,
.item_size500 .bdg_sale.sl_87 {
  background-position: -660px -960px;
}

.item_size420 .bdg_sale.sl_88,
.item_size450 .bdg_sale.sl_88,
.item_size500 .bdg_sale.sl_88 {
  background-position: -770px -960px;
}

.item_size420 .bdg_sale.sl_89,
.item_size450 .bdg_sale.sl_89,
.item_size500 .bdg_sale.sl_89 {
  background-position: -880px -960px;
}

.item_size420 .bdg_sale.sl_90,
.item_size450 .bdg_sale.sl_90,
.item_size500 .bdg_sale.sl_90 {
  background-position: -990px -960px;
}

.item_size420 .bdg_sale.sl_91,
.item_size450 .bdg_sale.sl_91,
.item_size500 .bdg_sale.sl_91 {
  background-position: 0 -1080px;
}

.item_size420 .bdg_sale.sl_92,
.item_size450 .bdg_sale.sl_92,
.item_size500 .bdg_sale.sl_92 {
  background-position: -110px -1080px;
}

.item_size420 .bdg_sale.sl_93,
.item_size450 .bdg_sale.sl_93,
.item_size500 .bdg_sale.sl_93 {
  background-position: -220px -1080px;
}

.item_size420 .bdg_sale.sl_94,
.item_size450 .bdg_sale.sl_94,
.item_size500 .bdg_sale.sl_94 {
  background-position: -330px -1080px;
}

.item_size420 .bdg_sale.sl_95,
.item_size450 .bdg_sale.sl_95,
.item_size500 .bdg_sale.sl_95 {
  background-position: -440px -1080px;
}

.item_size420 .bdg_sale.sl_96,
.item_size450 .bdg_sale.sl_96,
.item_size500 .bdg_sale.sl_96 {
  background-position: -550px -1080px;
}

.item_size420 .bdg_sale.sl_97,
.item_size450 .bdg_sale.sl_97,
.item_size500 .bdg_sale.sl_97 {
  background-position: -660px -1080px;
}

.item_size420 .bdg_sale.sl_98,
.item_size450 .bdg_sale.sl_98,
.item_size500 .bdg_sale.sl_98 {
  background-position: -770px -1080px;
}

.item_size420 .bdg_sale.sl_99,
.item_size450 .bdg_sale.sl_99,
.item_size500 .bdg_sale.sl_99 {
  background-position: -880px -1080px;
}

.dep5_size140 .itembx .price_unit .applied em,
.dep3_size140 .itembx .price_unit .applied em {
  font-size: 18px;
}

.price_date strong {
  float: left;
  font-weight: normal;
  color: #999;
}

.price_date em {
  float: right;
  color: #666;
}

.price_date em em,
.price_date em span {
  float: none;
}

.price_date dt,
.price_date p {
  overflow: hidden;
  background: url(../../img/common/line_dot.gif) repeat-x 0 100%;
}

.price_date dt strong,
.price_date p strong {
  font-weight: bold;
  font-size: 12px;
  color: #272727;
}

.price_date dt {
  padding: 9px 12px 9px;
  margin-bottom: -1px;
}

.price_date dt.sd_sale_price_last {
  background: none;
  padding-bottom: 8px;
}

.price_date ul {
  padding: 6px 12px 4px;
}

.price_date li {
  overflow: hidden;
  margin: 0;
  line-height: 20px;
}

.price_date p {
  padding: 12px 12px 9px;
  background-position: 0 0;
  background-color: #f6f6f6;
  font-weight: bold;
}

.price_date p em {
  color: #222;
}

.price_date.active {
  display: block;
}

.table_itemlist2 .price_unit {
  position: relative;
}

.table_itemlist2 .price_unit .price_date.active {
  display: block;
  z-index: 9999;
}

.table_itemlist2 .ic_section {
  overflow: hidden;
  margin-bottom: -5px;
}

.table_itemlist2 .buy_always {
  display: block;
  width: 93px;
  height: 29px;
  background: url(../../img/common/sp_label.png) 0 -1800px no-repeat;
}

.itembx,
.itembx a {
  color: #666;
  text-decoration: none;
}

.itembx img {
  vertical-align: top;
}

.itembx .check_item {
  display: none;
  position: relative;
  height: 18px;
  margin-bottom: -18px;
  z-index: 9999;
}

.itembx .check_item .lst_check {
  position: absolute;
  left: -18px;
  top: -18px;
}

.itembx .thumbnail {
  position: relative;
  line-height: 0;
}

.itembx .thumbnail a {
  display: inline-block;
  position: relative;
}

.itembx .thumbnail a.soldoutlnk {
  display: block;
  position: static;
}

.itembx .thumbnail .ico_left {
  position: absolute;
  top: -3px;
  left: -8px;
  z-index: 2;
}

.itembx .thumbnail .ico_right {
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 2;
}

.itembx .thumbnail .ico_br {
  position: absolute;
  bottom: 0px;
  right: 0;
}

.itembx_clip {
  padding: 0;
  *zoom: 1;
}

.itembx_clip:after {
  display: block;
  clear: both;
  content: "";
}

.itembx_clip .thumbnail {
  display: inline;
  float: left;
  line-height: 0;
}

.itembx_clip .thumbnail img {
  vertical-align: top;
}

.itembx_clip .thumbnail .ico_right {
  right: -6px;
  top: -6px;
}

.itembx_clip .thumbnail .store_reserve {
  width: 38px;
  height: 38px;
  background: url(../../img/@temp/temp_04s.png);
}

.clip_recomlist li {
  padding: 20px 0;
  border-bottom: 1px solid #e9e9e9;
  vertical-align: top;
}

.mobile_version {
  width: 1020px;
  margin: 10px auto 0;
}

.mobile_version a {
  display: block;
  height: 110px;
  padding-top: 2px;
  background: #fff;
  border: 1px solid #c3c3c3;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  box-shadow: inset 0 -8px 0 0 #f0f0f0;
  font-weight: bold;
  font-size: 36px;
  color: #666;
  line-height: 110px;
  text-align: center;
  text-decoration: none;
}

.thumbnail .soldout,
.thumbnail2 .soldout {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: url(../../img/common/bg_itemlst_soldout.png);
  color: #222;
  line-height: normal;
  text-align: center;
  z-index: 1;
}

.thumbnail .soldout.adultbx,
.thumbnail2 .soldout.adultbx {
  height: 100%;
}

.item_size500.special .soldout {
  background: none;
}

.thumbnail .soldout .tt,
.thumbnail .soldout .tt2,
.thumbnail2 .soldout .tt,
.thumbnail2 .soldout .tt2 {
  display: block;
  color: #fff;
  font-size: 0;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
  line-height: 0;
  text-indent: -9999px;
  font-weight: normal;
}

.thumbnail .soldout .valign,
.thumbnail2 .soldout .valign {
  display: none;
}

.thumbnail .soldout .bx,
.thumbnail2 .soldout .bx {
  display: inline-block;
  width: 100%;
  position: relative;
  vertical-align: bottom;
}

.itembx .soldout .date,
.thumbnail .soldout .date,
.thumbnail2 .soldout .date {
  margin: 0 0 7px;
  color: #fff;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
}

.itembx .soldout .date em,
.thumbnail .soldout .date em,
.thumbnail2 .soldout .date em {
  color: #fffc12;
}

.item_size500.special .soldout .date {
  margin: 0;
  color: #999;
}

.itembx .soldout .tt {
  height: 36px;
  background: url(../../img/common/tx_soldout2_202.png) no-repeat 50% 50%;
}

.itembx .soldout .tt2 {
  height: 36px;
  background: url(../../img/common/tx_soldout_500.png) no-repeat 50% 50%;
}

.item_size70 .soldout .tt2 {
  height: 20px;
  background: url(../../img/common/tx_soldout_70.png) no-repeat 50% 50%;
  padding: 0;
}

.item_size70 .soldout .tt {
  height: 20px;
  background: url(../../img/common/tx_soldout2_70.png) no-repeat 50% 50%;
  padding: 0;
}

.item_size140 .soldout .tt,
.item_size140 .soldout .tt2 {
  height: auto;
  padding: 5px 0 4px;
  background: none;
  font-size: 16px;
  line-height: 18px;
  text-indent: 0;
}

.item_size168 .soldout .tt {
  height: 29px;
  background: url(../../img/common/tx_soldout2_202.png) no-repeat 50% 50%;
}

.item_size168 .soldout .tt2 {
  height: 29px;
  background: url(../../img/common/tx_soldout_202.png) no-repeat 50% 50%;
}

.item_size202 .soldout .tt {
  height: 36px;
  background: url(../../img/common/tx_soldout2_202.png) no-repeat 50% 50%;
}

.item_size202 .soldout .tt2 {
  height: 36px;
  background: url(../../img/common/tx_soldout_202.png) no-repeat 50% 50%;
}

.thumbnail2 .gift_msg .tt {
  display: block;
  padding: 5px 0 4px;
  color: #fff;
  font-size: 16px;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
  line-height: 18px;
}

.thumbnail2 .gift_done {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: url(../../img/common/bg_itemlst_giftdone.png);
  text-align: center;
  z-index: 1;
}

.thumbnail2 .gift_done strong {
  display: inline-block;
  padding: 5px 0 4px;
  color: #fff;
  font-size: 16px;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  line-height: 18px;
  font-weight: 400;
}

.item_size168 .soldout.soldout_l .tt,
.item_size168 .soldout.soldout_l .tt2,
.item_size202 .soldout.soldout_l .tt,
.item_size202 .soldout.soldout_l .tt2 {
  background-position: 11px 50%;
}

.thumdata_area {
  display: inline;
  float: left;
  position: relative;
  width: 314px;
  padding-left: 18px;
}

.thumdata_area.yes_panel {
  width: 274px;
  padding-left: 58px;
}

.thumdata_area.yes_panel .thumdata_dl {
  min-height: 110px;
  padding-bottom: 30px;
}

.thumdata_area .layer_panel {
  position: absolute;
  left: 7px;
  top: 0;
  width: 32px;
  height: 126px;
}

.thumdata_area .layer_panel a {
  display: block;
  position: relative;
  margin: -1px 0 0;
  *margin-top: -5px;
  font-size: 0;
  line-height: 0;
}

.thumdata_area .layer_panel a:first-child {
  margin: 0;
}

.thumdata_area .layer_panel img {
  vertical-align: top;
}

.thumdata_dl {
  position: relative;
  color: #666;
}

.thumdata_dl a {
  color: #666;
  text-decoration: none;
}

.thumdata_dl dt {
  margin-bottom: 8px;
  padding-top: 8px;
}

.thumdata_dl dt .tit {
  display: inline-block;
  vertical-align: middle;
}

.thumdata_dl dt .maker {
  font-weight: bold;
  color: #00aac4;
}

.thumdata_dl .oth_m {
  display: inline-block;
  margin: -7px 0 0 15px;
  font-size: 0;
  line-height: 0;
  vertical-align: middle;
}

.thumdata_dl .oth_m img {
  vertical-align: top;
}

.thumdata_dl .detail_tm .maker {
  color: #000;
}

.thumdata_dl .detail_tm .point {
  color: #ff6865;
}

.thumdata_dl .detail_tm2 {
  margin-top: 5px;
  font-size: 11px;
  line-height: 16px;
}

.thumdata_dl .detail_tm3 {
  margin-top: 18px;
  color: #747474;
}

.thumdata_dl .detail_tm3 a {
  color: #4d9eaa;
  text-decoration: none;
}

.thumdata_dl .datail_date {
  font-size: 11px;
  color: #999;
}

.thumdata_dl .price_tm {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
}

.thumdata_dl .price_tm del {
  display: inline-block;
  margin-right: 10px;
  vertical-align: top;
}

.thumdata_dl .price_tm .price_tt {
  display: inline-block;
  color: #000;
  vertical-align: top;
}

.thumdata_dl .price_tm .price_tt .ssg_price {
  display: inline-block;
  margin: -6px 0;
  font-size: 12px;
}

.thumdata_dl .special_time {
  position: absolute;
  left: 0;
  bottom: 25px;
  background: url(../../img/common/bg_special_time.gif) #ffb401 0 0 no-repeat;
  height: 23px;
  line-height: 24px;
  color: #fff;
  font-weight: bold;
  padding: 0 10px 0 22px;
  overflow: hidden;
}

.clip_red .clip {
  background-position: -33px -31px;
}

.layer_panel {
  background: url(../../img/common/sp_panel2.png) no-repeat;
}

.layer_panel a {
  display: block;
  width: 32px;
  height: 32px;
  margin-right: -1px;
  background: url(../../img/common/sp_panel2.png) no-repeat;
}

.layer_panel a.view {
  background-position: -35px -35px;
}

.layer_panel a.cart {
  background-position: -70px -35px;
}

.layer_panel a.blank {
  background-position: -105px -35px;
}

.layer_panel a.clip {
  background-position: -35px -70px;
}

.layer_panel a.change {
  background-position: -140px -35px;
}

.layer_panel a.enter {
  background-position: -140px -70px;
}

.layer_panel .clip_yellow a.clip {
  background-position: -70px -70px;
}

.layer_panel .clip_red a.clip {
  background-position: -105px -70px;
}

.btn.x_small.help {
  padding-left: 18px;
  color: #666;
  background: url(../../img/common/ico_help.gif) 0 2px no-repeat;
}

.btn.medium.facebook {
  padding-left: 40px;
  background-position: 0 -500px;
  letter-spacing: -0.05em;
}

.btn.medium.facebook span {
  width: 160px;
  padding-right: 0;
}

.btn.xx_large.clip_fan span {
  overflow: hidden;
  width: 64px;
  background-position: 100% -500px;
  text-indent: -999em;
}

.btn.xx_large.clip_wish span {
  overflow: hidden;
  width: 64px;
  background-position: 100% -600px;
  text-indent: -999em;
}

.thumbnail,
.thumbnail2 {
  margin: 0 auto;
}

.thumbnail .sale,
.thumbnail .gift,
.thumbnail .plus,
.thumbnail .reserve,
.thumbnail .traders_reserve,
.thumbnail .ssgdeliv,
.thumbnail .ssgdeliv_tr,
.thumbnail .ssgdeliv_sd,
.thumbnail .ssgpick,
.thumbnail .ssgds,
.thumbnail .special,
.thumbnail2 .sale,
.thumbnail3 .gift,
.thumbnail2 .plus,
.thumbnail2 .reserve,
.thumbnail2 .ssgds,
.thumbnail .coupon_sale,
.thumbnail2 .coupon_sale,
.thumbnail .ico_better,
.thumbnail2 .ico_better,
.thumbnail .holiday_delivery,
.thumbnail .special {
  overflow: hidden;
  display: block;
  width: 52px;
  height: 60px;
  margin-top: -12px;
  background: url(../../img/common/sp_label.png) no-repeat;
  color: #fff;
  line-height: 60px;
  text-align: center;
}

.thumbnail .buy_always,
.thumbnail2 .buy_always {
  display: block;
  width: 93px;
  height: 29px;
  background: url(../../img/common/sp_label.png) 0 -1800px no-repeat;
}

.thumbnail .sale,
.thumbnail2 .sale {
  height: 40px;
  padding-top: 21px;
  font-size: 24px;
  line-height: normal;
  background-position: -150px -150px;
}

.thumbnail .sale span,
.thumbnail2 .sale span {
  display: inline-block;
  *display: inline;
  vertical-align: top;
}

.thumbnail .sale .persent {
  position: relative;
  top: 9px;
  left: 1px;
  width: 11px;
  height: 10px;
  background: url(../../img/common/sp_persent.png) no-repeat;
}

.thumbnail2 .sale .persent {
  position: relative;
  top: 9px;
  left: 1px;
  width: 11px;
  height: 10px;
  background: url(../../img/common/sp_persent.png) no-repeat;
}

.thumbnail .coupon_sale,
.thumbnail2 .coupon_sale,
.thumbnail .special {
  height: 40px;
  padding-top: 21px;
  font-size: 24px;
  line-height: normal;
  background-position: -150px -300px;
}

.thumbnail .coupon_sale span,
.thumbnail2 .coupon_sale span,
.thumbnail .special span {
  display: inline-block;
  vertical-align: top;
  *margin-right: -3px;
}

.thumbnail .coupon_sale .persent {
  position: relative;
  top: 9px;
  left: 1px;
  width: 11px;
  height: 10px;
  background: url(../../img/common/sp_persent.png) no-repeat;
}

.item_size450 .thumbnail .coupon_sale .persent,
.item_size450 .thumbnail .sale .persent {
  position: relative;
  top: 13px;
  left: 1px;
  width: 11px;
  height: 10px;
  background: url(../../img/common/sp_persent.png) no-repeat;
}

.thumbnail2 .coupon_sale .persent,
.thumbnail .special .persent {
  position: relative;
  top: 21px;
  left: 1px;
  width: 11px;
  height: 10px;
  background: url(../../img/common/sp_persent.png) no-repeat;
}

.thumbnail .special,
.thumbnail2 .special {
  height: 46px;
  padding-top: 14px;
  font-style: italic;
  font-size: 24px;
  line-height: normal;
  background-position: 0 -676px;
}

.thumbnail .gift,
.thumbnail2 .gift,
.thumbnail .point,
.thumbnail2 .point,
.thumbnail .coupon_txt,
.thumbnail .card_txt,
.thumbnail2 .coupon_txt {
  background-position: -150px -600px;
  font-size: 14px;
}

.thumbnail .ico_better,
.thumbnail2 .ico_better {
  background-position: -150px -1500px;
}

.thumbnail .plus,
.thumbnail2 .plus {
  background-position: -150px -450px;
  font-size: 24px;
  line-height: 63px;
  letter-spacing: -2px;
  padding-right: 2px;
  width: 50px;
}

.thumbnail .plus .plus1,
.thumbnail2 .plus .plus1 {
  display: inline-block;
  width: 19px;
  height: 22px;
  background: url(../../img/common/sp_label.png) -150px -1650px no-repeat;
  vertical-align: -2px;
  margin-left: 3px;
}

.thumbnail .reserve,
.thumbnail2 .reserve {
  width: 79px;
  height: 48px;
  margin: 0 0 0 -26px;
  background-position: -150px -750px;
}

.thumbnail .reserve.bojung,
.thumbnail2 .reserve.bojung,
.thumbnail .reserve.center,
.thumbnail2 .reserve.center {
  background-position: -150px -900px;
}

.thumbnail .reserve.bojung2,
.thumbnail2 .reserve.bojung2 {
  background-position: -150px -1200px;
}

.thumbnail .reserve.bojung2 {
  width: 62px;
  height: 47px;
  background: url(../../img/common/sp_delivery.png) 0 -100px no-repeat;
  margin: 0 0 0 -9px;
}

.thumbnail .traders_reserve,
.thumbnail2 .traders_reserve {
  width: 79px;
  height: 48px;
  margin: 0 0 0 -26px;
  background-position: -150px -1050px;
}

.thumbnail .reserve.ico_pickup,
.thumbnail2 .reserve.ico_pickup {
  width: 79px;
  height: 56px;
  background-position: -146px -1950px;
}

.thumbnail .traders_reserve.ico_pickup,
.thumbnail2 .traders_reserve.ico_pickup {
  width: 79px;
  height: 56px;
  background-position: -146px -1860px;
}

.thumbnail .ssgdeliv,
.thumbnail2 .ssgdeliv {
  width: 60px;
  height: 63px;
  margin: -5px 0 0 -7px;
  background-position: -150px -2040px;
}

.thumbnail .ssgdeliv_tr,
.thumbnail2 .ssgdeliv_tr {
  width: 60px;
  height: 63px;
  margin: -5px 0 0 -7px;
  background-position: -150px -2140px;
}

.thumbnail .ssgdeliv_sd,
.thumbnail2 .ssgdeliv_sd {
  width: 60px;
  height: 60px;
  margin: -5px 0 0 -7px;
  background-position: -150px -2340px;
}

.thumbnail .ssgpick,
.thumbnail2 .ssgpick {
  width: 60px;
  height: 60px;
  margin: -5px 0 0 -7px;
  background-position: -150px -2240px;
}

.thumbnail .ssgds,
.thumbnail2 .ssgds {
  width: 48px;
  height: 48px;
  margin: 0;
  background-position: 0 -400px;
  background-image: url(../../img/common/label_medium.png);
}

.thumbnail .holiday_delivery,
.thumbnail2 .holiday_delivery,
.thumbnail .holiday_delivery02 {
  display: block;
  width: 50px;
  height: 50px;
  margin: 0;
  background: url(../../img/common/sp_bdg.png) 0 -1350px no-repeat;
}

.thumbnail .soldout.adultbx,
.thumbnail2 .soldout.adultbx {
  background: none !important;
}

.thumbnail .soldout .valign,
.thumbnail2 .soldout .valign {
  display: inline-block;
  width: 1px;
  height: 100%;
  vertical-align: middle;
}

.thumbnail .soldout .tt02,
.thumbnail2 .soldout .tt02 {
  display: block;
  font-size: 21px;
}

.thumbnail .soldout .tx,
.thumbnail2 .soldout .tx {
  display: block;
  font-size: 14px;
}

.thumbnail .soldout.adultbx .tx,
.thumbnail2 .soldout.adultbx .tx {
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  font-size: 0;
  line-height: 0;
  visibility: hidden;
}

.thumbnail .soldout .date,
.thumbnail2 .soldout .date {
  display: block;
  font-size: 12px;
  color: #999;
}

.thumbnail .adultbx,
.thumbnail2 .adultbx {
  background-color: #fff;
}

.thumbnail .adultbx .adult,
.thumbnail2 .adultbx .adult {
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  font-size: 0;
  line-height: 0;
  visibility: hidden;
}

.thumbnail .adultbx2 {
  opacity: 0;
  filter: alpha(opacity=0);
}

.thumbnail .holiday_delivery_em,
.thumbnail .holiday_delivery02_em {
  display: block;
  width: 62px;
  height: 47px;
  background: url(../../img/common/sp_delivery.png) -100px -100px no-repeat !important;
  margin-left: -12px;
}

.thumbnail .sprshp,
.thumbnail2 .sprshp {
  display: block;
  width: 54px;
  height: 55px;
  background: url(../../img/common/sp_sprshp.png) 0 -100px no-repeat;
  margin: 0 0 0 -2px;
}

/* 태그 */
.item_tag {
  float: left;
  margin-top: 15px;
  width: 100%;
  display: table;
}

.item_tag:after {
  content: "";
  display: block;
  clear: both;
}

.item_tag .tag_tit {
  position: relative;
  cursor: pointer;
  display: table-cell;
  width: 35px;
}

.item_tag .tag_tit a {
  display: inline-block;
  background: url(../../img/common/ic_tag.png) 0 1px no-repeat;
  height: 14px;
  width: 35px;
}

.item_tag .tag_tit .tt_tag_layer {
  display: none;
  position: absolute;
  left: -1px;
  top: -60px;
  z-index: 100;
  padding: 8px 10px 7px;
  background-color: #fff;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #222;
  line-height: 16px;
  text-align: left;
  white-space: nowrap;
  -webkit-box-shadow: 4px 5px 17px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 4px 5px 17px 0 rgba(0, 0, 0, 0.3);
  border: 1px solid #eee;
}

.item_tag .tag_tit .tt_tag_layer .bg_arr {
  position: absolute;
  left: 5px;
  top: 100%;
  width: 12px;
  height: 9px;
  background: url(../../img/common/bg_adinfo_n.png) 0 0 no-repeat;
}

.item_tag .tag_lst {
  line-height: 19px;
  display: table-cell;
  vertical-align: top;
}

.item_tag .tag_lst a {
  color: #888;
  margin-right: 7px;
}

.item_tag .tag_lst a:hover {
  color: #000;
}

/* 스티커 */
.ly_sticker {
  position: absolute;
  z-index: 1000;
}

.ty_sticker1 {
  left: 1020px;
  top: 15px;
}

.ty_sticker2 {
  left: 1020px;
  top: 15px;
}

.ty_sticker3 {
  left: 1020px;
  top: 15px;
}

.ty_sticker4 {
  left: 1020px;
  top: 15px;
}

.ty_sticker5 {
  left: 1020px;
  top: 15px;
}

/* 공통SNS */
.cm_sns:after {
  content: "";
  display: block;
  clear: both;
}

.cm_sns li {
  float: left;
  width: 24px;
  height: 24px;
  margin-left: 4px;
}

.cm_sns li a {
  width: 24px;
  height: 24px;
  display: block;
  background-image: url(../../img/common_layout/sp_sns.png);
  background-repeat: no-repeat;
}

.cm_sns li a.facebook {
  background-position: 0 -38px;
}

.cm_sns li a.kakao {
  background-position: -28px -38px;
}

.cm_sns li a.twitter {
  background-position: -56px -38px;
}

.cm_sns li a.pinterest {
  background-position: -84px -38px;
}

.life_magazine .cm_sns {
  float: left;
  margin: 18px 5px 0 0;
} /* 라이프매거진 상세 */
.detail_product .cm_sns {
  position: absolute;
  right: 0;
  top: 7px;
} /* 상품상세 */
.exhibition_box .cm_sns {
  float: left;
  margin: 0 15px 0 0;
} /* 기획전 상세 */
.special .cm_sns {
  position: absolute;
  top: 11px;
  right: 0;
} /* 특가전 */
.benfit_list .cm_sns {
  float: left;
  margin: 2px 5px 0 0;
} /* 이벤트 */
.detail_wrap .left .cm_sns {
  overflow: hidden;
  padding: 10px 0 0 0;
  float: right;
  height: 34px;
} /* 이몰상세 */
.header_obanjang .cm_sns {
  position: absolute;
  top: 318px;
  right: 0;
} /* 이몰오반장 */
.location_section .cm_sns {
  float: left;
  margin: 3px 0 0 10px;
  height: 18px;
} /* 이몰기획전 */
.location_section .cm_sns li {
  width: 18px;
  height: 18px;
  margin-left: 3px;
}

.location_section .cm_sns li a {
  width: 18px;
  height: 18px;
}

.location_section .cm_sns li a.facebook {
  background-position: 0 0;
}

.location_section .cm_sns li a.kakao {
  background-position: -20px 0;
}

.location_section .cm_sns li a.twitter {
  background-position: -40px 0;
}

.location_section .cm_sns li a.pinterest {
  background-position: -60px 0;
}

#container_sm .pr_layout .cm_sns {
  position: absolute;
  right: 28px;
  bottom: 30px;
} /* 신몰상세 */
#container_sm .benfit_list .cm_sns {
  margin: 2px 5px 0 14px;
}

#container_sd .sub_content .detail .cm_sns {
  position: absolute;
  right: 28px;
  bottom: 30px;
}

#container_sd .share_brand .cm_sns {
  position: absolute;
  right: 0;
  top: -44px;
}

/* 공통 클립하기 버튼 */
.clipbx.v2 {
  position: relative;
}

.clipbx.v2 a.clipbtn {
  display: block;
  width: 122px;
  height: 24px;
  background-image: url(../../img/common_layout/sp_clipbtn.png) !important;
  background-repeat: no-repeat;
}

.clipbx.v2 a.clipbtn01 {
  background-position: 0 0;
} /* 카테고리 클립하기 */
.clipbx.v2 a.clipbtn02 {
  background-position: 0 -30px;
} /* 이벤트 클립하기 */
.clipbx.v2 a.clipbtn03 {
  background-position: 0 -60px;
} /* 브랜드 클립하기 */
.clipbx.v2 a.clipbtn04 {
  background-position: 0 -90px;
} /* 기획전 클립하기 */
.clipbx.v2.clip_red a.clipbtn {
  background-position: 0 -120px;
}

.clipbx.v2 .tooltip_box.small.cliping {
  display: none;
  position: absolute;
  left: -280px;
  top: -17px;
  z-index: 2000;
  width: 247px;
  height: 34px;
  padding: 10px;
  border: solid 1px #000;
  background-color: #fff;
  line-height: 18px !important;
  font-size: 12px;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
  color: #666;
  letter-spacing: 0;
  text-align: left;
}

.clipbx.v2 .tooltip_box.small.active {
  display: block;
}

.clipbx.v2 .tooltip_box.small.cliping span {
  color: #fc3d72;
}

.clipbx.v2 .tooltip_box .btn {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 2000;
  width: 13px;
  height: 13px;
  background: url(../../img/common_layout/sp_clipbtn.png) 0 -150px no-repeat;
}

.clipbx.v2 .tooltip_box .icon_arow {
  position: absolute;
  right: -6px;
  top: 40%;
  z-index: 2000;
  width: 6px;
  height: 11px;
  background: url(../../img/common_layout/sp_clipbtn.png) -20px -150px no-repeat;
}

.common_location .clipbx.v2 {
  float: right;
} /* 카테고리 */
.exhibition_box .clipbx.v2 {
  float: left;
} /* 기획전 */
.benfit_list .clipbx.v2 {
  float: left;
  margin-top: 2px;
} /* 이벤트 */
#container_sm .brand_cate_box.brandshop .clipbx.v2 {
  position: absolute;
  right: 0;
  top: 5px;
} /* 신몰 브랜드 */
#container_sd .clipbx.v2 {
  position: absolute;
  top: 54px;
  right: 50%;
  margin-right: -510px;
} /* 신백 브랜드 */
#container_sd .exhibition_box .clipbx.v2 {
  position: relative;
  top: auto;
  right: auto;
  margin-right: 0;
} /* 신백 기획전 */
/* 2입기획 */
.thumbnail .plus.p2i,
.thumbnail2 .plus.p2i {
  background-image: url(../../img/common/sp_p2i.png);
  background-position: -150px 0;
  text-indent: -1000em;
}

.item_size140 .plus.p2i,
.item_size168 .plus.p2i {
  background-position: 0 0;
}

.btm_lst_area .ico_area .plus.p2i {
  background-image: url(../../img/common/sp_p2i.png);
  background-position: -150px 0;
  text-indent: -1000em;
  background-position: -150px 0;
}

.item_size450 .plus.p2i {
  background-position: -300px 0;
}

.item_benefits .plus.p2i {
  background: url(../../img/common/sp_p2i.png) -300px 0 no-repeat !important;
}

.ico_item.normal.p2i {
  width: 64px;
  height: 24px;
  vertical-align: middle;
}

.ico_item.normal.p2i .ir {
  background: url(../../img/common/sp_p2i.png) -450px 0 no-repeat !important;
}

.csm_lst_v .bdg_l p.i_plus.p2i,
.csm_lst_h .bdg_l p.i_plus.p2i {
  background: url(../../img/common/sp_p2i.png) 0 -150px no-repeat !important;
  text-indent: -1000em;
}

#container_sm .pr_bfsv .p2i {
  display: inline-block;
  width: 76px;
  height: 76px;
  overflow: hidden;
  background: url(../../img/common/sp_p2i.png) -150px -150px no-repeat !important;
  vertical-align: top;
}

#container_sm .pr_bnfli .pr_h5 .p2i {
  display: inline-block;
  width: 66px;
  height: 17px;
  overflow: hidden;
  text-indent: -1000em;
  background: url(../../img/common/sp_p2i.png) -450px -150px no-repeat !important;
  vertical-align: top;
}

#container_sm .hb_benefit_b .n_plus.p2i {
  display: inline-block;
  width: 130px;
  height: 130px;
  overflow: hidden;
  text-indent: -1000em;
  background: url(../../img/common/sp_p2i.png) -300px -150px no-repeat !important;
  vertical-align: top;
}

#container_sm .event_ico .event01.p2i {
  background: url(../../img/common/sp_p2i.png) -150px -150px no-repeat !important;
  width: 76px;
  height: 76px;
  padding: 0;
}

#qv .event_ico .event01.p2i {
  background: url(../../img/common/sp_p2i.png) -150px -150px no-repeat !important;
  width: 76px;
  height: 76px;
  padding: 0;
}

.emall .item_thm .ico_plus.p2i,
.s_emall .item_thm .ico_plus.p2i {
  background: url(../../img/common/sp_p2i.png) 0 -300px no-repeat !important;
}

.emall .detail_wrap .bdg_item span.ico_plus1.p2i,
.s_emall .detail_wrap .bdg_item span.ico_plus1.p2i {
  background: url(../../img/common/sp_p2i.png) -450px -300px no-repeat !important;
}

.emall .item_wrap .left_item span.ico_plus.p2i,
.s_emall .item_wrap .left_item span.ico_plus.p2i {
  background: url(../../img/common/sp_p2i.png) -300px -300px no-repeat !important;
}

.benefit .ico_plus1.p2i,
.desc .ico_plus1.p2i {
  background: url(../../img/common/sp_p2i.png) -450px -450px no-repeat !important;
  width: 41px !important;
}

.traders .item_thm .ico_plus.p2i,
.s_traders .item_thm .ico_plus.p2i {
  background: url(../../img/common/sp_p2i.png) -150px -450px no-repeat !important;
  overflow: hidden;
  text-indent: -1000em;
}

.traders .w110 .item_thm .ico_plus.p2i,
.s_traders .w110 .item_thm .ico_plus.p2i {
  background: url(../../img/common/sp_p2i.png) 0 -450px no-repeat !important;
  overflow: hidden;
  text-indent: -1000em;
}

.traders .bdg_item span.ico_plus1.p2i,
.s_traders .bdg_item span.ico_plus1.p2i {
  background: url(../../img/common/sp_p2i.png) 0 -450px no-repeat !important;
  overflow: hidden;
  text-indent: -1000em;
}

.s_traders .item_emall .item.item_h.w110 .item_thm .ico_plus {
  background: url(../../img/common/sp_p2i.png) 0 -300px no-repeat !important;
}

.s_traders .item_emall .item.w253 .item_thm .ico_plus.p2i {
  background: url(../../img/common/sp_p2i.png) 0 -300px no-repeat !important;
  margin: 5px 0 0 0;
}

.boons .item_thm .ico_plus.p2i,
.s_boons .item_thm .ico_plus.p2i {
  background: url(../../img/common/sp_p2i.png) -150px -600px no-repeat !important;
  overflow: hidden;
  text-indent: -1000em;
}

.boons .bdg_item span.ico_plus1.p2i,
.s_boons .bdg_item span.ico_plus1.p2i {
  background: url(../../img/common/sp_p2i.png) 0 -600px no-repeat !important;
  overflow: hidden;
  text-indent: -1000em;
}

.detail_wrap .info_wrap .ico_plus1.p2i {
  background: url(../../img/common/sp_p2i.png) -450px -450px no-repeat !important;
  width: 41px !important;
}

/* 상품상세 카드혜택 */
.cm_card_info {
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
}

.cm_card_info .cm_card_lst {
  /*background:url(../../img/common/ic_ssgpay.png) 100% 100% no-repeat;*/
  padding: 10px 0 10px !important;
  margin-bottom: 5px;
}

.cm_card_info .cm_card_lst li,
.info_wrap .data .link_block li .cm_card_info .cm_card_lst li {
  height: auto;
  width: auto;
  line-height: 18px;
  margin: 0;
  border: 0;
  float: none;
  font-size: 11px;
  color: #999 !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

.cm_card_info .cm_card_tb {
  border-top: 1px solid #dfdfdf;
}

.cm_card_info .cm_card_tb table {
  margin: 0;
  width: 100%;
}

.cm_card_info .cm_card_tb table th {
  width: 40%;
  letter-spacing: -1px;
  font-size: 11px;
  padding: 8px 0 6px;
  border-bottom: 1px solid #ececec;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
  color: #666;
  line-height: 18px !important;
}

.cm_card_info .cm_card_tb table td {
  color: #666;
  vertical-align: middle;
  border-left: 1px solid #ececec;
  border-bottom: 1px solid #ececec;
  padding: 8px 0 6px 9px !important;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
  font-size: 11px;
  line-height: 18px !important;
}

.cm_card_info .cm_card_tb table td.cmonth {
  width: 38%;
}

.cm_card_info .cm_card_tb table td.cprice {
  width: 22%;
}

.cm_card_info .cm_card_tb .ic_ssgpay {
  background: url(../../img/common/ic_ssgpay2.png) 100% 0 no-repeat;
  padding-right: 20px;
  display: inline-block;
  line-height: 17px;
}

.cm_card_info .cm_card_img img {
  vertical-align: top;
}

.info_wrap dd.card .lay_box.ly_cardinfo {
  left: auto;
  right: 4px;
}

.content_ty2 .cm_card_info .cm_card_tb table {
  width: 290px;
  margin-bottom: 15px;
}

.content_ty2 .cm_card_info .cm_card_lst {
  width: 280px;
  padding-top: 0 !important;
}

.content_ty2 .cm_card_info .cm_card_lst li em,
.boons .cm_card_info .cm_card_lst li em {
  display: none;
}

.step_contwrap .cm_card_info {
  padding-top: 8px;
}

.step_contwrap .cm_card_info .cm_card_img {
  width: 254px;
  overflow: hidden;
}

.benefit_layer2 .cm_card_info {
  padding-top: 8px;
}

.benefit_layer2 .cm_card_info .cm_card_img {
  width: 254px;
  overflow: hidden;
}

.benefit_layer2 .cm_card_info .cm_card_tb table {
  margin-bottom: 0 !important;
}

.benefit_layer2 .cm_card_info .cm_card_tb th {
  width: 42%;
}

.benefit_layer2 .cm_card_info .cm_card_tb td {
  background: 0 !important;
  text-align: left !important;
  padding: 8px 0 6px 9px !important;
}

.benefit_layer2 .cm_card_info .cm_card_tb td.cmonth {
  width: 36%;
}

.bn_thankcard {
  margin-bottom: 10px;
  float: left;
  width: 100%;
}

.bn_thankcard a {
  display: block;
  line-height: 0;
}

.bn_holiday {
  float: left;
  width: 100%;
}

#container_sd .bn_thankcard,
#container_sm .bn_thankcard {
  margin-top: 10px;
}

.detail_product.detail_2dan .bn_thankcard {
  padding-top: 10px;
  margin: 10px 0 0;
  border-top: 1px dotted #707070;
}

/* s-club */
.bn_sclub {
  display: none;
  background: url(../../img/common/bg_sclub.png) 0 0 no-repeat;
  width: 300px;
  height: 75px;
  margin: 0 0 13px 122px;
}

.bn_sclub ul {
  padding: 11px 0 0 91px;
}

.bn_sclub li {
  font-size: 11px;
  color: #777;
  line-height: 16px;
  background: url(../../img/common/bu_dot2.png) 0 5px no-repeat;
  padding-left: 6px;
  margin-bottom: 6px;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
}

.bn_sclub li strong {
  color: #0a7ec7;
}

.emall .info_wrap .bn_sclub,
.traders .info_wrap .bn_sclub,
.boons .info_wrap .bn_sclub {
  margin: 0 0 10px 0;
}

#container_sm .bn_sclub {
  margin: 7px 0 0 0;
  width: 285px;
}

#container_sd .bn_sclub {
  margin: 15px 0 0 0;
  width: 285px;
}

#container_sm .bn_sclub li,
#container_sd .bn_sclub li {
  padding-bottom: 0;
}

#container_sm .bn_sclub li strong,
#container_sd .bn_sclub li strong {
  float: none;
}

.ly_ssgpay .dimmed {
  background: #000;
  opacity: 0.2;
  filter: alpha(opacity=20);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 600;
  width: 100%;
  height: 100%;
}

.ly_ssgpay .layer_pop {
  left: 50%;
  width: 540px;
  margin-left: -270px;
  border: 0;
  position: absolute;
  z-index: 700;
  border: 0;
  background: #fff;
}

.ly_ssgpay .ssgpay_top {
  width: 540px;
  height: 126px;
  position: relative;
  background: url(../../img/common/img_ssgpay1.png);
}

.ly_ssgpay .ssgpay_top .btn_close {
  position: absolute;
  top: 20px;
  right: 20px;
  height: 34px;
  width: 34px;
  background: url(../../img/common/b.gif);
}

.ly_ssgpay .ssgpay_btm {
  width: 540px;
  height: 430px;
  position: relative;
  background: url(../../img/common/img_ssgpay2.png);
}

.ly_ssgpay .ssgpay_btm .btn_ssgpay {
  display: none;
  width: 97px;
  height: 35px;
  position: absolute;
  top: 325px;
  left: 38px;
  background: url(../../img/common/btn_ssgpay.png) 0 0 no-repeat;
}

.ly_ssgpay .ssgpay_btm_bn {
  width: 540px;
}

.ly_ssgpay .ssgpay_btm_bn img {
  vertical-align: top;
  width: 540px;
}

.ly_ssgpay .ssgpay_price {
  line-height: 1.5;
}

.ly_ssgpay .ssgpay_price .ssgpay_benefit {
  border-bottom: 1px solid #c9c9c9;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
  color: #666;
  padding: 26px 0 5px;
}

.ly_ssgpay .ssgpay_price .ssgpay_benefit:after {
  content: "";
  display: block;
  clear: both;
}

.ly_ssgpay .ssgpay_price .ssgpay_benefit dt {
  float: left;
  width: 160px;
  background-position: 29px 4px;
  background-repeat: no-repeat;
  height: 44px;
}

.ly_ssgpay .ssgpay_price .ssgpay_benefit dt.stit7 {
  background-image: url(../../img/common/tx_ssgpay3.png);
}

.ly_ssgpay .ssgpay_price .ssgpay_benefit dt.stit8 {
  background-image: url(../../img/common/tx_ssgpay2.png);
}

.ly_ssgpay .ssgpay_price .ssgpay_benefit dt.stit9 {
  background-image: url(../../img/common/tx_ssgpay1.png);
}

.ly_ssgpay .ssgpay_price .ssgpay_benefit dt span {
  visibility: hidden;
  overflow: hidden;
  position: absolute;
  top: -1px;
  left: -1px;
  width: 1px;
  height: 1px;
  font-size: 0;
  line-height: 0;
}

.ly_ssgpay .ssgpay_price .ssgpay_benefit dd {
  float: right;
  width: 360px;
  color: #666;
  text-align: left;
  margin-bottom: 12px;
  padding-right: 20px;
}

.ly_ssgpay .ssgpay_price .ssgpay_benefit dd strong.price {
  font-size: 20px;
  vertical-align: -2px;
  color: #000;
  letter-spacing: -1px;
  margin-right: 2px;
  display: inline-block;
  margin-top: -1px;
}

.ly_ssgpay .ssgpay_price .ssgpay_benefit dd .card_area {
  margin-bottom: 12px;
}

.emall .ly_ssgpay .ssgpay_price .ssgpay_benefit dd strong.price,
.traders .ly_ssgpay .ssgpay_price .ssgpay_benefit dd strong.price,
.boons .ly_ssgpay .ssgpay_price .ssgpay_benefit dd strong.price {
  font-size: 24px;
  margin-top: -3px;
}

.ly_samsungpay .ssgpay_top {
  background: url(../../img/common/img_samsungpay1.png);
}

.ly_samsungpay .ssgpay_price .ssgpay_benefit dt.stit7 {
  background-image: url(../../img/common/tx_samsungpay3.png);
}

.ly_samsungpay .ssgpay_price .ssgpay_benefit dt.stit8 {
  background-image: url(../../img/common/tx_samsungpay2.png);
}

.ly_samsungpay .ssgpay_price .ssgpay_benefit dt.stit9 {
  background-image: url(../../img/common/tx_samsungpay1.png);
}

/* ssgpay */
.info_wrap .bn_ssgpay {
  text-align: right;
  padding: 19px 0 10px 0;
}

.info_wrap .bn_ssgpay a {
  display: inline-block;
  width: 358px;
  height: 35px;
  background: url(../../img/common/bn_ssgpay.png) 0 0 no-repeat;
  vertical-align: top;
}

.emall .info_wrap .bn_ssgpay,
.traders .info_wrap .bn_ssgpay,
.boons .info_wrap .bn_ssgpay {
  padding: 10px 0 16px 0;
  text-align: left;
  background: #fff;
}

#container_sm .bn_ssgpay {
  text-align: center;
  padding: 15px 0 5px 0;
}

#container_sd .bn_ssgpay {
  text-align: center;
  padding: 0 0 5px 0;
}

#container_sm .bn_ssgpay a,
#container_sd .bn_ssgpay a {
  display: inline-block;
  width: 285px;
  height: 35px;
  background: url(../../img/common/bn_ssgpay_sm.png) 0 0 no-repeat;
  vertical-align: top;
}

.bn_ssgpay_v {
  padding: 19px 0 10px 0;
}

.bn_ssgpay_v a {
  display: inline-block;
  overflow: hidden;
  width: 181px;
  height: 35px;
  background: url(../../img/common/bg_ssgpay.png) 0 0 no-repeat;
  vertical-align: top;
  text-align: center;
  line-height: 35px;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
  color: #33353d;
  font-size: 13px;
  text-decoration: none;
  word-break: break-all;
  word-wrap: break-word;
  padding: 0 17px 0 87px;
}

.info_wrap .bn_ssgpay_v {
  padding-left: 122px;
}

.emall .info_wrap .bn_ssgpay_v,
.traders .info_wrap .bn_ssgpay_v,
.boons .info_wrap .bn_ssgpay_v {
  padding: 10px 0 16px 0;
}

#container_sm .bn_ssgpay_v {
  padding: 15px 0 5px 0;
}

#container_sd .bn_ssgpay_v {
  padding: 0 0 5px 0;
}

.btn_ssgpay_cnfrm {
  display: inline-block;
  vertical-align: top;
  width: 79px;
  height: 20px;
  background: url(../../img/common/bg_ssgpay.png) 0 -40px no-repeat;
}

/* 각몰검색추가 - 수정이후삭제예정 */
.srch_ssg_result {
  position: relative;
}

.srch_ssg_result .txt_area {
  background: url(../../img/common/search_bar.jpg) left top no-repeat;
  width: 1020px;
  height: 100px;
  margin-bottom: 50px;
  cursor: pointer;
}

.srch_ssg_result .txt_area .result_txt {
  float: left;
  width: 728px;
  padding: 22px 0 0 70px;
}

.srch_ssg_result .txt_area .result_txt span {
  width: 720px;
  float: left;
  color: #464646;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: -1px;
  line-height: 26px;
}

.srch_ssg_result .txt_area .result_txt span strong {
  font-size: 25px;
}

.srch_ssg_result .txt_area .result_txt span:first-child {
  color: #7a8184;
  font-size: 14px;
}

.srch_ssg_result .txt_area .result_btn {
  float: left;
  position: relative;
  padding-top: 30px;
}

.srch_ssg_result .txt_area .result_btn a {
  float: left;
  background: url(../../img/common/search_btn.gif) left top no-repeat;
  width: 167px;
  height: 40px;
  font-size: 0;
  line-height: 0;
}

.srch_ssg_result .srch_pro {
  position: relative;
}

.srch_ssg_result .hx_cnt {
  position: relative;
  background-repeat: repeat-x;
  background-position: 0 50%;
  font-size: 26px;
  color: #222;
  line-height: normal;
  letter-spacing: -2px;
  text-align: center;
}

.srch_ssg_result .hx_case3 {
  background-image: url(../../img/common/hx_cnt_case3.gif);
}

.srch_ssg_result .hx_cnt .tt {
  display: inline-block;
  padding: 0 20px;
  background: #fff;
}

.srch_ssg_result .hx_cnt a {
  color: #222;
}

.srch_ssg_result .hx_cnt .bn {
  position: absolute;
  margin-top: -9px;
  top: 50%;
  right: 0;
  font-size: 0;
  line-height: 0;
}

.srch_ssg_result_v1 {
  position: relative;
  padding-top: 10px;
}

.srch_ssg_result_v1 .txt_area {
  position: relative;
  width: 1020px;
  border-bottom: 1px solid #747474;
  padding-bottom: 12px;
  margin: 0 auto;
  text-align: center;
}

.srch_ssg_result_v1 .txt_area .result_txt {
  display: inline-block;
}

.srch_ssg_result_v1 .txt_area .result_txt span {
  float: left;
  *float: none;
  display: inline-block;
  color: #999;
  font-size: 24px;
  font-weight: bold;
  line-height: 28px;
  letter-spacing: -1px;
  margin-top: 15px;
}

.srch_ssg_result_v1 .txt_area .result_txt span:first-child {
  color: #222;
}

.srch_ssg_result_v1 .txt_area .result_txt span strong {
  color: #e84348;
  display: inline-block;
}

.srch_ssg_result_v1 .txt_area.ty2 {
  position: relative;
  width: 1020px;
  border-bottom: 0;
  padding-bottom: 2px;
  margin: 0 auto;
  text-align: center;
}

.srch_ssg_result_v1 .txt_area a.result_btn {
  position: relative;
  margin: 0 0 -5px 10px;
  display: inline-block;
  background: url(../../img/common/search_btn_v1.gif) left top no-repeat;
  width: 160px;
  height: 30px;
  font-size: 0;
  line-height: 0;
}

.srch_ssg_result_v1 .srch_pro {
  position: relative;
}

/* 검색결과 교차사이트 상품추천 */
.srch_cross_pd {
  position: relative;
  margin: 54px 0 70px;
}

.srch_cross_pd .txt_area {
  position: relative;
  width: 1020px;
  margin: 0 auto;
  padding-bottom: 12px;
  border-bottom: 1px solid #747474;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
  text-align: center;
}

.srch_cross_pd .txt_area .result_txt {
  display: inline-block;
}

.srch_cross_pd .txt_area .result_txt span {
  display: inline-block;
  font-size: 26px;
  line-height: 30px;
  letter-spacing: -1px;
  color: #222;
}

.srch_cross_pd .txt_area .result_txt span strong {
  font-weight: normal;
}

.srch_cross_pd .txt_area .result_txt .result_btn {
  display: inline-block;
  position: relative;
  top: -1px;
  width: 130px;
  height: 30px;
  margin-left: 12px;
  background: url(../../img/common/search_btn_cross.gif);
  font-size: 0;
  line-height: 0;
  vertical-align: top;
}

.srch_cross_pd .srch_pro {
  position: relative;
}

.body_sm .srch_cross_pd {
  margin-top: -24px;
}

.body_sm .srch_cross_pd .txt_area .result_txt em,
.body_sd .srch_cross_pd .txt_area .result_txt em {
  color: #ff7200;
}

.body_em .srch_cross_pd .txt_area .result_txt em,
.body_traders .srch_cross_pd .txt_area .result_txt em,
.body_boons .srch_cross_pd .txt_area .result_txt em {
  color: #d91800;
}

.ico_bottom {
  position: absolute;
  right: 0;
  bottom: 0;
}

.ico_mov {
  display: block;
  width: 66px;
  height: 24px;
  margin: 0 8px 8px 0;
  background: url(../../img/common/sp_mov.png) no-repeat;
}

.item_size140 .thumbnail .ico_mov {
  margin: 0 4px 4px 0;
}

.help_tooltip {
  z-index: 1010;
  display: inline-block;
  height: 15px;
  padding-right: 16px;
  color: #666;
  text-decoration: none;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: -1px;
  background: url(../../img/common/ico_helptooltip.png) 100% 0 no-repeat;
}

.tooltip_box {
  display: none;
  position: absolute;
  z-index: 1000;
  padding: 8px 10px;
  border: 1px solid #424242;
  background-color: #fff;
}

.tooltip_box.active {
  display: block;
}

.tooltip_box.small {
  width: 182px;
}

.tooltip_box.x_small {
  width: 126px;
}

.tooltip_box.medium {
  min-width: 226px;
}

.tooltip_box.small_02,
.tooltip_box.medium_02,
.tooltip_box.medium_03 {
  border-top: 2px solid #222;
}

.detail_product .tooltip_box.small_02,
.detail_product .tooltip_box.medium_02,
.detail_product .tooltip_box.medium_03 {
  border-top: 2px solid #222;
  position: absolute;
  right: 204px;
  top: 30px;
}

.detail_product .tooltip_box.small {
  position: absolute;
  right: 0;
  top: 19px;
  left: auto;
}

.detail_product.detail_2dan .tooltip_box.small_02,
.detail_product.detail_2dan .tooltip_box.medium_02,
.detail_product.detail_2dan .tooltip_box.medium_03,
.detail_product.detail_2dan .tooltip_box.small_03,
.detail_product.detail_2dan .tooltip_box.small_04 {
  border-top: 2px solid #222;
}

.tooltip_box.small .title {
  display: block;
  margin-bottom: 7px;
  padding-bottom: 5px;
  font-size: 12px;
  color: #272727;
  border-bottom: 1px dotted #666;
  line-height: 16px;
}

.tooltip_box.small .title em {
  font-size: 11px;
  font-weight: normal;
  color: #666;
}

.tooltip_box.medium .title {
  display: block;
  margin-bottom: 7px;
  padding-bottom: 5px;
  font-size: 12px;
  color: #272727;
  border-bottom: 1px dotted #666;
  line-height: 16px;
}

.tooltip_box.medium_03 .title {
  border-bottom: 1px dashed #d8d8d8;
}

.tooltip_box .dashed_line {
  margin-bottom: 5px;
  padding-bottom: 5px;
  border-bottom: 1px dashed #d8d8d8;
}

.tooltip_box .title.dashed_line {
  margin: 7px 0;
  padding-top: 5px;
  border-top: 1px dashed #d8d8d8;
}

.tooltip_box.medium .title.dotted_line {
  margin: 0 0 0;
  padding-top: 7px;
  border-top: 1px dotted #666;
  border-bottom: 0;
}

.tooltip_box.medium .title.dashed_line {
  margin: 0 0 0;
  padding-top: 7px;
  border-top: 1px dashed #d8d8d8;
  border-bottom: 0;
}

.tooltip_box p {
  font-size: 11px;
  line-height: 16px;
  color: #666;
}

.tooltip_box p.desc {
  margin-top: 3px;
  padding-top: 8px;
  border-top: 1px dashed #d8d8d8;
  font-size: 11px;
  line-height: 16px;
  color: #999;
  letter-spacing: -0.1em;
}

.tooltip_box p.desc02 {
  border-top: 1px dotted #666;
  padding: 15px 0 0 7px;
  text-indent: -7px;
}

.tooltip_box p.desc03 {
  margin-top: -10px;
  padding: 15px 0 0 7px;
  text-indent: -7px;
}

.tooltip_box p.strong {
  font-weight: bold;
  font-size: 12px;
  text-align: center;
}

.tooltip_box p.strong .em {
  color: #ef4c44;
}

.tooltip_box.x_small p {
  text-align: center;
}

.tooltip_box.x_small p.txt {
  letter-spacing: 0;
}

.tooltip_box span {
  display: inline;
  line-height: 16px;
}

.tooltip_box ul {
  padding-top: 10px;
}

.tooltip_box .data_list.small {
  padding: 10px;
  border-top: solid 1px #ddd;
  background-color: #f9f9f9;
}

.tooltip_box .data_list.small li {
  line-height: 16px;
  color: #666;
  margin-top: 5px;
}

.tooltip_box .data_list.small li:first-child {
  margin-top: 0;
}

.tooltip_box.small table {
  width: 100%;
  border-collapse: collapse;
  line-height: 16px;
  letter-spacing: -0.05em;
}

.tooltip_box.small table th {
  font-weight: normal;
  color: #666;
  font-size: 11px;
  text-align: left;
}

.tooltip_box.small table td {
  font-size: 11px;
  text-align: right;
}

.tooltip_box p.btn_wrap {
  padding: 5px 0;
  text-align: center;
}

.tooltip_box p.btn_wrap .btn {
  float: none;
}

.tooltip_box .sale_txt {
  display: block;
  margin-top: 5px;
  padding-top: 8px;
  border-top: 1px dashed #d8d8d8;
  color: #666;
  letter-spacing: -1px;
}

.tooltip_box .tooltip_close {
  display: block;
  position: absolute;
  top: 7px;
  right: 15px;
  width: 9px;
  height: 9px;
}

.tooltip_box.medium table {
  width: 100%;
  margin: -7px 0 0;
  border-collapse: collapse;
  line-height: 16px;
  letter-spacing: -0.05em;
}

.tooltip_box.medium table th {
  font-weight: normal;
  color: #666;
  font-size: 11px;
  text-align: left;
  border-bottom: 1px solid #e6e6e6;
}

.tooltip_box.medium table td {
  font-size: 11px;
  padding: 9px 0 5px 10px;
  border-bottom: 1px solid #e6e6e6;
  border-left: 1px solid #e6e6e6;
  letter-spacing: -1px;
}

.tooltip_box.medium table tr.last-child th,
.tooltip_box.medium table tr.last-child td {
  border-bottom: 0;
}

.tooltip_box.medium table td .pay_txt {
  float: right;
  *margin-top: -17px;
}

.tooltip_box.medium_03 table {
  width: 100%;
  margin: 0 0 5px;
  border-collapse: collapse;
  line-height: 16px;
  letter-spacing: -0.05em;
}

.tooltip_box.medium_03 table th {
  font-weight: normal;
  color: #666;
  font-size: 11px;
  text-align: left;
  border: 0;
}

.tooltip_box.medium_03 table td {
  font-size: 11px;
  padding: 3px 0 1px;
  border: 0;
  color: #666;
  letter-spacing: -0.1em;
}

.tooltip_box.small_03 {
  top: -98px;
  left: -26px;
}

.tooltip_box.small_03.cliping {
  width: 226px;
  top: -98px;
  left: -229px;
}

.tooltip_box.small_03.wish {
  width: 226px;
  top: -98px;
  left: 72px;
}

.tooltip_box.small_03.buy {
  top: -108px;
  left: -70px;
}

.mg_list {
  font-size: 11px;
  line-height: 16px;
  color: #666;
}

.mg_list li {
  position: relative;
  margin: 0 !important;
  padding: 0;
  padding-left: 8px;
  zoom: 1;
}

.mg_list li .bul {
  display: inline-block;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 5px;
  width: 2px;
  height: 2px;
  background: #666;
  line-height: 999px;
  vertical-align: top;
}

.detail_2dan .tooltip_box.small_03 {
  top: -97px;
  left: 5px;
}

.detail_2dan .tooltip_box.small_03.cliping {
  width: 226px;
  top: -97px;
  left: 355px;
}

.detail_2dan .tooltip_box.small_03.wish {
  width: 226px;
  top: -35px;
  left: 136px;
}

.detail_2dan .tooltip_box.small_03.buy {
  top: -108px;
  left: -70px;
}

.tooltip_box.small_03 p {
  text-align: center;
}

.tooltip_box.small_03 span.after {
  display: block;
  position: absolute;
  left: 50%;
  bottom: -10px;
  width: 11px;
  height: 10px;
  margin-left: -6px;
  background: url(../../img/product/bg_arrow_03.png) no-repeat 0 0;
  color: #999;
  font-size: 11px;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
}

/* 플로팅배너 */
#floatWrap {
  display: none;
  position: fixed;
  bottom: 5px;
  left: 50%;
  z-index: 500;
  margin-left: 645px;
}

.body_wide #floatWrap {
  margin-left: 775px;
}

#floatTy1 img {
  position: absolute;
  right: 0;
  bottom: 0;
}

#floatTy1 .bn_fix_s {
  position: absolute;
  right: 0;
  bottom: 0;
}

#floatTy1 .bn_fix_b {
  right: -11px;
}

#floatWrap2 {
  display: none;
  position: fixed;
  bottom: 5px;
  left: 50%;
  z-index: 500;
  margin-left: 645px;
}

.body_wide #floatWrap2 {
  margin-left: 775px;
}

#floatTy2 .bn_fix_s {
  position: absolute;
  right: 0;
  bottom: 0;
}

#floatTy2 .bn_fix_s img {
  position: absolute;
  right: 0;
  bottom: 0;
}

#floatTy2 .jump_big {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
}

#floatTy2 .bn_fix_b {
  width: 1020px;
  text-align: right;
  display: block;
  margin: 0 auto;
}

#floatTy2 .bn_fix_b img {
  vertical-align: top;
  width: 1020px;
  height: 185px;
}

#floatWrap3 {
  display: none;
  position: fixed;
  bottom: 5px;
  right: 50%;
  z-index: 500;
  margin-right: 612px;
}

.body_wide #floatWrap3 {
  margin-right: 742px;
}

#floatTy3 {
  position: relative;
}

#floatTy3 img {
  position: absolute;
  left: 0;
  bottom: 0;
}

#floatTy3 .bn_fix_s {
  position: absolute;
  left: 0;
  bottom: 0;
}

#floatTy3 .bn_fix_b img {
  vertical-align: top;
}

#floatWrap4 {
  display: none;
  position: fixed;
  bottom: 5px;
  right: 50%;
  z-index: 500;
  margin-right: 612px;
}

.body_wide #floatWrap4 {
  margin-right: 742px;
}

#floatTy4 {
  position: relative;
}

#floatTy4 img {
  position: absolute;
  left: 0;
  bottom: 0;
}

#floatTy4 .bn_fix_s {
  position: absolute;
  left: 0;
  bottom: 0;
}

@media only screen and (max-width: 1500px) {
  .body_wide #floatWrap,
.body_wide #floatWrap2,
.body_wide #floatWrap3,
.body_wide #floatWrap4 {
    margin-right: 0;
    right: auto;
    left: 0;
    bottom: 0;
  }
  .body_wide #floatTy3 .bn_fix_s img {
    width: 50px !important;
  }
}
/* 개인정보처리방침 */
.agreement_of_utilization {
  padding-top: 0px;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
}

.agreement_of_utilization ol,
.agreement_of_utilization ul {
  list-style: none;
}

.agreement_of_utilization .title {
  margin-top: 50px;
}

.agreement_of_utilization .title h3 {
  font-size: 30px;
  letter-spacing: -1px;
  line-height: 36px;
}

.agreement_of_utilization .title p {
  color: #666;
  font-size: 12px;
  line-height: 18px;
  margin-top: 15px;
}

.agreement_of_utilization .title p:first-child {
  margin-top: 0;
}

.agreement_of_utilization .index {
  position: relative;
  overflow: hidden;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 23px 0 23px;
  background: url(../../img/common/bg_ua_title.gif) no-repeat;
  margin-bottom: 40px;
}

.agreement_of_utilization .index div {
  float: left;
  overflow: hidden;
  width: 345px;
}

.agreement_of_utilization .index div .dt {
  padding-left: 10px;
  background: url(../../img/common/bg_dot.gif) no-repeat 0 8px;
  line-height: 24px;
}

.agreement_of_utilization .index div a {
  text-decoration: none;
  color: #000;
}

.agreement_of_utilization .index div a:hover {
  text-decoration: underline;
  font-weight: bold;
}

.agreement_of_utilization .index div.dl3 {
  width: 330px;
}

.agreement_of_utilization .index .bg_index {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 1020px;
  height: 4px;
  background: url(../../img/common/bg_ua_title.gif) no-repeat;
}

.agreement_of_utilization .index2 div {
  width: 50%;
}

.agreement_of_utilization .section {
  margin-top: 30px;
}

.agreement_of_utilization h4 {
  padding-bottom: 13px;
  font-size: 13px;
  font-weight: bold;
  line-height: 19px;
}

.agreement_of_utilization .ua_box {
  border: 1px solid #efefef;
  border-radius: 4px;
  margin: 20px 15px;
  overflow-x: hidden;
}

.agreement_of_utilization .ua_box p,
.agreement_of_utilization .ua_box ol,
.agreement_of_utilization .ua_box ul {
  font-size: 12px;
  line-height: 18px;
  color: #7d7d7d;
}

.agreement_of_utilization .ua_box .ua_span {
  font-size: 11px;
  line-height: 18px;
  color: #7d7d7d;
}

.agreement_of_utilization .ua_box p strong {
  font-weight: normal;
}

.agreement_of_utilization .ua_box ol a {
  font-size: 11px;
  line-height: 18px;
  color: #7d7d7d;
}

.agreement_of_utilization .ua_box ol ol {
  margin-left: 13px;
}

.agreement_of_utilization .ua_box .ua_table {
  width: calc(100% + 1px);
  text-align: center;
  margin: 5px 0 20px -1px;
}

.agreement_of_utilization .ua_box .ua_table.ua_left td {
  text-align: left;
  padding-left: 10px;
}

.agreement_of_utilization .ua_box .ua_table th {
  height: 40px;
  border-top: 1px solid #666;
  border-bottom: 1px solid #e8e8e8;
  background-color: #f9f9f9;
}

.agreement_of_utilization .ua_box .ua_table td {
  padding: 20px 0;
  border-left: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
}

.agreement_of_utilization .ua_box .ua_table td span.in {
  color: #7d7d7d;
  font-size: 12px;
  line-height: 26px;
  letter-spacing: -1px;
}

.agreement_of_utilization .ua_box .ua_table td span.in strong {
  color: #222;
}

.agreement_of_utilization .ua_box .ua_table td.bdr_left {
  border-left: 1px solid #e8e8e8;
}

.agreement_of_utilization .ua_box .ua_table td a {
  color: #7d7d7d;
}

.ssg-modal-mask {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1000;
  background-color: #fff;
  opacity: 0.8;
  filter: alpha(opacity=80);
}

.agreement_of_utilization .ua_box2 .ua_table th {
  border-left: 1px solid #e8e8e8;
}

.agreement_of_utilization .ua_box2 .ua_table tr:first-child th:first-child {
  border-left: 0 none;
}

.agreement_of_utilization .section.simple_type {
  margin-top: 0;
}

.agreement_of_utilization .simple_type .ua_box {
  border: 0;
  border-radius: 0;
}

/* 개인정보 공통 */
.common_privacy {
  width: 1020px;
  margin: 0 auto;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
}

.common_privacy ol,
.common_privacy ul {
  list-style: none;
}

.common_privacy .title {
  margin-top: 45px;
}

.common_privacy .title h3 {
  font-size: 30px;
  letter-spacing: -1px;
  line-height: 36px;
}

.common_privacy .title p {
  color: #666;
  font-size: 12px;
  line-height: 18px;
  margin-top: 15px;
}

.common_privacy .title p:first-child {
  margin-top: 0;
}

.common_privacy .index {
  position: relative;
  overflow: hidden;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 23px 0 23px;
  background: url(../../img/common/bg_ua_title.gif) no-repeat;
  margin-bottom: 40px;
}

.common_privacy .index div {
  float: left;
  overflow: hidden;
  width: 345px;
}

.common_privacy .index div .dt {
  padding-left: 10px;
  background: url(../../img/common/bg_dot.gif) no-repeat 0 8px;
  line-height: 24px;
}

.common_privacy .index div a {
  text-decoration: none;
  color: #000;
}

.common_privacy .index div a:hover {
  text-decoration: underline;
  font-weight: bold;
}

.common_privacy .index div.dl3 {
  width: 330px;
}

.common_privacy .index .bg_index {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 1020px;
  height: 4px;
  background: url(../../img/common/bg_ua_title.gif) no-repeat;
}

.common_privacy .index2 div {
  width: 50%;
}

.common_privacy .section {
  margin-top: 30px;
}

.common_privacy h4 {
  padding-bottom: 13px;
  font-size: 13px;
  font-weight: bold;
  line-height: 19px;
}

.common_privacy .ua_box {
  border: 1px solid #efefef;
  border-radius: 4px;
  padding: 20px 15px;
}

.common_privacy .ua_box p,
.common_privacy .ua_box ol,
.common_privacy .ua_box ul,
.common_privacy .ua_box dl {
  font-size: 12px;
  line-height: 18px;
  color: #7d7d7d;
}

.common_privacy .ua_box .ua_span {
  display: block;
  margin: -15px 0 15px;
  font-size: 12px;
  line-height: 18px;
  color: #7d7d7d;
}

.common_privacy .ua_box p strong {
  font-weight: normal;
}

.common_privacy .ua_box ol a {
  font-size: 12px;
  line-height: 18px;
  color: #7d7d7d;
}

.common_privacy .ua_box .ua_table {
  width: 100%;
  text-align: center;
  margin: 5px 0 20px;
  text-indent: 0;
}

.common_privacy .ua_box .ua_table.ua_left td {
  text-align: left;
  padding-left: 10px;
}

.common_privacy .ua_box .ua_table th {
  height: 40px;
  border-top: 1px solid #666;
  border-bottom: 1px solid #e8e8e8;
  background-color: #f9f9f9;
}

.common_privacy .ua_box .ua_table td {
  padding: 20px 0;
  border-left: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
}

.common_privacy .ua_box .ua_table td span.in {
  color: #7d7d7d;
  font-size: 12px;
  line-height: 26px;
  letter-spacing: -1px;
}

.common_privacy .ua_box .ua_table td span.in strong {
  color: #222;
}

.common_privacy .ua_box .ua_table td:first-child {
  border-left: none;
}

.common_privacy .ua_box .ua_table td.bdr_left {
  border-left: 1px solid #e8e8e8;
}

.common_privacy .ua_box .ua_table td a {
  color: #7d7d7d;
  text-decoration: none;
}

.common_privacy .ua_box2 .ua_table {
  border-top: 1px solid #666;
}

.common_privacy .ua_box2 .ua_table th {
  border-top: 0 none;
  border-left: 1px solid #e8e8e8;
}

.common_privacy .ua_box2 .ua_table tr:first-child th:first-child {
  border-left: 0 none;
}

.tab_privacy {
  height: 41px;
  width: 1020px;
  margin: 40px auto 0 auto;
}

.tab_privacy ul {
  background-image: url(../../img/common_layout/tab_privacy.gif);
}

.tab_privacy ul:after {
  content: "";
  display: block;
  clear: both;
}

.tab_privacy ul.tab_sgg {
  background-position: 0 0;
}

.tab_privacy ul.tab_sm {
  background-position: 0 -50px;
}

.tab_privacy ul.tab_em {
  background-position: 0 -100px;
}

.tab_privacy ul li {
  float: left;
  height: 41px;
  width: 203px;
}

.tab_privacy ul li:first-child {
  width: 204px;
}

.tab_privacy ul li a {
  display: block;
  width: 100%;
  height: 41px;
}

.privacy_tab .tab_sgg_v {
  background: url(../../img/common/bg_tab.gif) repeat-x 0 100%;
  zoom: 1;
}

.privacy_tab {
  width: 1020px;
  margin: 40px auto 0 auto;
}

.privacy_tab .tab_sgg_v li {
  float: left;
  height: 37px;
  width: 340px;
  margin-left: -1px;
  background: #f8f8f8;
}

.privacy_tab .tab_sgg_v li:first-child {
  margin-left: 0;
  width: 342px;
}

.privacy_tab .tab_sgg_v li a {
  display: block;
  padding: 9px 0 9px;
  border: 1px solid #c4c4c4;
  border-bottom: 0;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #100f0f;
  text-align: center;
  text-decoration: none;
}

.privacy_tab .tab_sgg_v li.active {
  position: relative;
  height: 39px;
  z-index: 2;
}

.privacy_tab .tab_sgg_v li.active a {
  position: relative;
  overflow: hidden;
  padding: 9px 0 11px;
  margin-top: -1px;
  border: 2px solid #777;
  border-bottom: 0;
  background: #fff;
}

.privacy_tab .tab_sgg_v:after {
  content: "";
  display: block;
  clear: both;
}

/* PC/M 공통 개인정보형UI */
.common_term {
  width: 1020px;
  margin: 0 auto;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
}

.common_term a {
  color: #7d7d7d;
  text-decoration: underline;
}

.common_term ul {
  list-style: none;
}

.common_term .title {
  margin-top: 45px;
}

.common_term .title h3 {
  font-size: 30px;
  letter-spacing: -1px;
  line-height: 36px;
}

.common_term .title p {
  color: #666;
  font-size: 12px;
  line-height: 18px;
  margin-top: 15px;
}

.common_term .title p:first-child {
  margin-top: 0;
}

.common_term .index {
  position: relative;
  overflow: hidden;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 23px 0 23px;
  background: url(../../img/common/bg_ua_title.gif) no-repeat;
  margin-bottom: 40px;
}

.common_term .index div {
  float: left;
  overflow: hidden;
  width: 345px;
}

.common_term .index div .dt {
  padding-left: 10px;
  background: url(../../img/common/bg_dot.gif) no-repeat 0 8px;
  line-height: 24px;
}

.common_term .index div a {
  text-decoration: none;
  color: #000;
}

.common_term .index div a:hover {
  text-decoration: underline;
  font-weight: bold;
}

.common_term .index div.dl3 {
  width: 330px;
}

.common_term .index .bg_index {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 1020px;
  height: 4px;
  background: url(../../img/common/bg_ua_title.gif) no-repeat;
}

.common_term .index2 div {
  width: 50%;
}

.common_term .section {
  margin-top: 30px;
}

.common_term h4 {
  padding-bottom: 13px;
  font-size: 13px;
  font-weight: bold;
  line-height: 19px;
}

.common_term h5 {
  padding-bottom: 13px;
  font-size: 13px;
  font-weight: bold;
  line-height: 19px;
}

.common_term .ua_box {
  border: 1px solid #efefef;
  border-radius: 4px;
  padding: 8px 15px;
}

.common_term .ua_box p,
.common_term .ua_box ul {
  font-size: 12px;
  line-height: 18px;
  color: #7d7d7d;
}

.common_term .ua_box ul {
  margin-top: 13px;
}

.common_term .ua_box ul ul ul ul {
  margin-top: 0;
}

.common_term .ua_box p {
  margin: 13px 0;
  text-indent: 0;
}

.common_term .ua_box .default_type {
  padding-left: 14px;
  text-indent: -14px;
}

.common_term .ua_box .circlednum_type {
  padding-left: 16px;
  text-indent: -16px;
}

.common_term .ua_box .hyphen_type {
  padding-left: 11px;
  text-indent: -11px;
}

.common_term .ua_box .korean_type {
  padding-left: 18px;
  text-indent: -18px;
}

.common_term .ua_box .bracket_type {
  padding-left: 10px;
}

.common_term .ua_box li,
.common_term .ua_box .default_type li,
.common_term .ua_box .circlednum_type li {
  margin: 13px 0;
}

.common_term .ua_box .hyphen_type li,
.common_term .ua_box .korean_type li,
.common_term .ua_box .bracket_type li {
  margin: 0;
}

.common_term .ua_box .korean_type .hyphen_type {
  margin-bottom: 13px;
}

.common_term .ua_box .ua_span {
  display: block;
  margin: -15px 0 15px;
  font-size: 11px;
  line-height: 18px;
  color: #7d7d7d;
}

.common_term .ua_box .ua_table {
  width: 100%;
  text-align: center;
  margin: 5px 0 20px;
  text-indent: 0;
}

.common_term .ua_box .ua_table.ua_left td {
  text-align: left;
  padding-left: 10px;
  padding-right: 10px;
}

.common_term .ua_table .important_terms_text {
  text-decoration: underline;
  font-size: 16px;
}

.common_term .ua_box .ua_table th {
  height: 30px;
  border: 1px solid #e8e8e8;
  border-top-color: #666;
  background-color: #f9f9f9;
}

.common_term .ua_box .ua_table td {
  padding: 10px 0;
  border: 1px solid #e8e8e8;
}

/* .common_term .ua_box .ua_table td:first-child{border-left:none}
.common_term .ua_box .ua_table td.bdr_left{border-left:1px solid #e8e8e8} */
.common_term .ua_box2 .ua_table {
  border-top: 1px solid #666;
}

.common_term .ua_box2 .ua_table th {
  border-top: 0 none;
  border-left: 1px solid #e8e8e8;
}

.common_term .ua_box2 .ua_table tr:first-child th:first-child {
  border-left: 0 none;
}

.cm_privacy_tx {
  font-size: 1.25em;
  letter-spacing: -0.05em;
  text-decoration: underline;
}
.cm_privacy_tx .cm_point {
  color: var(--m-colors-primary, #ff5452) !important;
}

/* 개인정보 처리방침, SSG.COM 이용약관, 전자금융거래 이용약관 - 이전약관 보기 셀렉트 */
.common_term_bt_area {
  width: 1020px;
  margin: 30px auto 0;
}

/* 개인정보 처리방침, 이용약관 마크업 표준화 확대 적용 (동의 내용보기, 모바일) 상단 목차 부분 공통화 */
.agreement_of_utilization.common_term {
  width: auto !important;
  padding: 10px !important;
}

.cpp_index {
  overflow: hidden;
  position: relative;
  margin: 10px 0 40px;
  padding: 23px 0 23px;
  background: url(../../img/common/bg_ua_title.gif) repeat-x;
}

.cpp_index .cpp_index_lst {
  display: flex;
  flex-flow: row wrap;
}

.cpp_index .cpp_index_lst > li {
  flex: 0 0 33.33%;
  padding-left: 10px;
  background: url(../../img/common/bg_dot.gif) no-repeat 0 8px;
  line-height: 24px;
  box-sizing: border-box;
}

.cpp_index .cpp_index_lst a {
  text-decoration: none;
  color: #000;
}

.cpp_index .cpp_index_lst a:hover {
  text-decoration: underline;
  font-weight: bold;
}

.cpp_index .cpp_index_lst.dl3 {
  width: 330px;
}

.cpp_index .bg_index {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 4px;
  background: url(../../img/common/bg_ua_title.gif) repeat-x;
}

@media screen and (min-width: 320px) and (max-width: 749px) {
  .cpp_index .cpp_index_lst > li {
    flex: 0 0 100%;
  }
}
@media screen and (min-width: 750px) and (max-width: 1019px) {
  .cpp_index .cpp_index_lst > li {
    flex: 0 0 50%;
  }
}
/* 2024.06 개편 */
.privacy_container {
  margin: 0 auto;
  line-height: 18px;
  max-width: 1020px;
}

.privacy_title {
  font-size: 30px;
  font-weight: 700;
  line-height: normal;
  margin: 40px 0 0;
}

.privacy_list {
  margin: 30px 0 0;
}
.privacy_list li {
  margin-top: 10px;
  color: #888;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
}

.privacy_labeling {
  margin: 80px 0 0;
}
.privacy_labeling_title {
  text-align: center;
  font-weight: bold;
  font-size: 15px;
  margin-bottom: 10px;
}
.privacy_labeling_subtext {
  font-size: 13px;
  color: #888;
  margin: 10px 0 0;
}
.privacy_labeling > ul {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 28px;
  margin: 20px 0 0;
}
.privacy_labeling > ul > li {
  position: relative;
  text-align: center;
  background-color: #fafafa;
}
.privacy_labeling > ul > li:nth-of-type(n + 4) {
  margin-top: 0;
}
.privacy_labeling > ul > li:nth-last-of-type(1) .privacy_labeling_tooltip {
  right: 0;
}
.privacy_labeling > ul > li a {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 20px;
  margin: auto;
  cursor: pointer;
  outline: revert;
}
.privacy_labeling > ul > li a + .privacy_labeling_tooltip {
  position: absolute;
  top: calc(100% + 10px);
  transition: all 0.3s ease;
  color: #333;
  text-align: left;
  opacity: 0;
  visibility: hidden;
  z-index: 2;
}
.privacy_labeling > ul > li a:hover + .privacy_labeling_tooltip, .privacy_labeling > ul > li a:focus + .privacy_labeling_tooltip {
  opacity: 1;
  visibility: visible;
}
.privacy_labeling > ul > li a strong {
  display: block;
  width: 100%;
  margin-top: 20px;
  font-size: 15px;
  font-weight: 700;
}
.privacy_labeling > ul > li a img {
  width: 69.5px;
  height: 80px;
  display: block;
  margin: auto;
}
.privacy_labeling > ul > li .privacy_labeling_tooltip {
  width: 300px;
  padding: 20px;
  box-sizing: border-box;
  border: 1px solid #969696;
  background-color: #fff;
  font-size: 11px;
  font-weight: 400;
  color: #222;
}
.privacy_labeling > ul > li .privacy_labeling_tooltip p + p {
  margin: 10px 0 0;
}
.privacy_labeling > ul > li .privacy_labeling_tooltip .type_list {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 6px 0;
}
.privacy_labeling > ul > li .privacy_labeling_tooltip .type_list li {
  display: inline-flex;
  word-break: keep-all;
  margin-right: 20px;
}
.privacy_labeling > ul > li .privacy_labeling_tooltip .type_list li img {
  width: 16px;
  height: 16px;
  margin-right: 4px;
}
.privacy_labeling > ul > li .privacy_labeling_tooltip .type_list + p {
  margin: 10px 0 0;
}
.privacy_labeling_tip {
  margin: 20px 0 0;
  color: #222;
  text-align: center;
  font-size: 13px;
}

.privacy_labeling_summary {
  background-color: #fafafa;
  overflow: hidden;
  margin: 80px 0 0;
  padding: 16px 40px;
}
.privacy_labeling_summary li {
  float: left;
  width: 50%;
  padding: 16px 0;
  border-bottom: 1px solid #e5e5e5;
  position: relative;
  display: flex;
  align-items: center;
  font-size: 15px;
  box-sizing: border-box;
  color: #222;
  cursor: pointer;
}
.privacy_labeling_summary li:nth-last-child(1), .privacy_labeling_summary li:nth-last-child(2) {
  border: 0;
}
.privacy_labeling_summary li .summary_text {
  display: flex;
  align-items: center;
}
.privacy_labeling_summary li strong {
  margin-right: 10px;
  font-weight: normal;
}
.privacy_labeling_summary li img {
  width: 35px;
  height: 40px;
  margin-right: 6px;
}
.privacy_labeling_summary li:nth-last-child(1) {
  border: 0;
}

.privacy_accordion {
  margin-top: 50px;
  line-height: 18px;
  border-bottom: 1px solid #d9d9d9;
}
.privacy_accordion .is-active .accordion_q {
  border-top: 1px solid #444;
}
.privacy_accordion .is-active .accordion_q:after {
  transform: translateY(-50%) rotate(180deg);
}

.accordion_q {
  position: relative;
  padding: 20px;
  border-top: 1px solid #d9d9d9;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  color: #333;
  gap: 10px;
  display: flex;
}
.accordion_q .accordion_privacy_img img {
  width: 35px;
  height: 40px;
}

.accordion_q:after {
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  right: 20px;
  width: 24px;
  height: 24px;
  transition: all 0.3s ease;
  transform: translateY(-50%);
  background-image: url("data:image/svg+xml;charset=utf8,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.0006 15.9514L4.6249 8.57568L3.77637 9.42421L12.0006 17.6485L20.2249 9.42421L19.3764 8.57568L12.0006 15.9514Z' fill='rgb%280%2C0%2C0%29'%3e%3c/path%3e %3c/svg%3e");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-flex;
  width: 32px;
  height: 32px;
}

.accordion_a {
  display: none;
  padding: 20px;
  background-color: #fafafa;
  color: #333;
  font-size: 12px;
  font-weight: 400;
}
.accordion_a strong {
  display: block;
  font-weight: bold;
}
.accordion_a p {
  margin-bottom: 10px;
}
.accordion_a p:only-child, .accordion_a p:last-child {
  margin: 0;
}

.privacy_tbl {
  margin: 15px 0;
}
.privacy_tbl th,
.privacy_tbl td {
  padding: 10px;
  border: 1px solid #ccc;
}
.privacy_tbl th {
  background-color: #e5e5e5;
}
.privacy_tbl a {
  color: #0044cc;
  word-break: break-all;
}

.privacy_form_group {
  margin: 30px 0 0;
}
.privacy_form_group select {
  padding: 10px 40px 10px 10px;
  border: 1px solid #444;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cmask id='mask0_74_4306' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'%3E%3Crect width='24' height='24' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_74_4306)'%3E%3Cpath d='M12 15L7 10H17L12 15Z' fill='%231C1B1F'/%3E%3C/g%3E%3C/svg%3E");
  background-position: center right 10px;
  background-repeat: no-repeat;
  appearance: none;
  font-size: 12px;
  font-weight: 400;
  color: #333;
  box-sizing: content-box;
}

/**
* mobile
*/
@media (max-width: 536px) {
  .privacy_labeling {
    overflow: visible;
  }
  .privacy_labeling ul {
    grid-template-columns: repeat(2, 1fr);
  }
  .privacy_labeling ul li:nth-of-type(even) a + .privacy_labeling_tooltip {
    right: 0;
  }
  .privacy_labeling ul li .privacy_labeling_tooltip {
    padding: 17px;
  }
  .privacy_labeling_summary {
    margin: 44px -1.75rem 0;
  }
  .privacy_labeling_summary li {
    float: none;
    width: 100%;
  }
  .privacy_labeling_summary li .summary_text {
    flex-wrap: wrap;
  }
  .privacy_labeling_summary li .summary_text strong {
    flex: 1;
    flex-shrink: 0;
    flex-basis: 100%;
    margin: 0;
  }
  .privacy_labeling_summary li .summary_text .summary_privacy_img {
    margin: 10px 0 0;
  }
  .privacy_labeling_summary li:nth-last-child(2) {
    border-bottom: 1px solid #e5e5e5;
  }
  .privacy_accordion ul li .accordion_q {
    flex-wrap: wrap;
  }
  .privacy_accordion ul li .accordion_q button {
    padding-right: 42px;
  }
  .privacy_accordion ul li .accordion_q:after {
    top: 30%;
  }
  .privacy_accordion ul li .accordion_q button {
    width: 100%;
    text-align: left;
  }
}
@media (min-width: 537px) and (max-width: 538px) {
  .privacy_labeling ul {
    gap: 27px;
  }
}
@media (min-width: 536px) and (max-width: 1019px) {
  .privacy_labeling ul {
    flex-wrap: wrap;
    grid-template-columns: repeat(3, 1fr);
  }
  .privacy_labeling ul li:nth-of-type(3n) a + .privacy_labeling_tooltip {
    right: 0;
  }
  .privacy_labeling ul li .privacy_labeling_tooltip {
    padding: 17px;
  }
  .privacy_labeling_summary {
    margin: 44px -1.75rem 0;
  }
  .privacy_labeling_summary li {
    float: none;
    width: 100%;
  }
  .privacy_labeling_summary li:nth-last-child(2) {
    border-bottom: 1px solid #e5e5e5;
  }
}
#gnbBanrDiv {
  z-index: 1001;
  position: relative;
}

.gnb_bnr_ie {
  display: flex;
  align-items: center;
  height: 50px;
  background: #f0f0f0;
}
.gnb_bnr_ie .gnb_bnr_inner {
  position: relative;
  width: 1280px;
  margin: 0 auto;
}
.gnb_bnr_ie .gnb_bnr_desc {
  display: flex;
  align-items: center;
}
.gnb_bnr_ie .gnb_bnr_desc::before {
  content: "";
  display: inline-block;
  vertical-align: top;
  margin-right: 5px;
  width: 20px;
  height: 20px;
  background: url(../../img/common_layout/icon_triangle_error.png) 0 0 no-repeat;
}
.gnb_bnr_ie .gnb_bnr_desc p {
  padding-top: 2px;
  font-size: 13px;
  letter-spacing: -0.3px;
  color: #222;
}
.gnb_bnr_ie .gnb_bnr_desc .gnb_bnr_btnopen {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  font-size: 12px;
  line-height: 13px;
  color: #fff;
  padding: 0 6px 0 14px;
  height: 24px;
  background: #222;
  border-radius: 15px;
}
.gnb_bnr_ie .gnb_bnr_desc .gnb_bnr_btnopen .icon::after {
  filter: invert(86%) sepia(89%) saturate(2%) hue-rotate(271deg) brightness(110%) contrast(97%);
}
.gnb_bnr_ie .gnb_bnr_btntoday {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  font-size: 11px;
  letter-spacing: -0.3px;
  color: #222;
}
.gnb_bnr_ie .gnb_bnr_btntoday .icon {
  margin-left: 10px;
  width: 12px;
  height: 12px;
}

.gnb_bnr_wrap {
  display: none;
  width: 100%;
  position: relative;
  z-index: 600;
  top: 0;
  left: 0;
  right: 0;
  min-width: 1280px;
}

.gnb_bnr_wrap .ie_dimed {
  position: fixed;
  background: url(../../img/common_layout/bn/bg_opacity.png);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 599;
}

.gnb_bnr_s {
  text-align: center;
  position: relative;
  z-index: 600;
}

.gnb_bnr_s a {
  display: block;
  text-align: center;
}

.gnb_bnr_s img {
  margin: 0 auto;
  width: 1280px;
  vertical-align: top;
}

.gnb_bnr_s .gnb_btn_today {
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
}

.gnb_bnr_b {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.gnb_bnr_b .gnb_bnr_cont {
  position: relative;
  width: 1280px;
  margin: 0 auto;
}

.gnb_bnr_b .gnb_bnr_cont img {
  width: 1280px;
  height: 800px;
}

.gnb_bnr_b .gnb_bnr_cont .gnb_bnr_clse {
  position: absolute;
  top: 0;
  right: 0;
  width: 100px;
  height: 100px;
}

/* 탑 배너 영역 추가*/
.tv_promo_wrap {
  width: 100%;
  position: absolute;
  z-index: 600;
  top: 0;
  left: 0;
  right: 0;
  display: none;
  min-width: 1020px;
}

.tv_promo_s {
  height: 100px;
  text-align: center;
}

.tv_promo_s a {
  display: block;
  text-align: center;
}

.tv_promo_s img {
  margin: 0 auto;
  width: 1020px;
  height: 100px;
  vertical-align: top;
  display: block;
}

.tv_promo_cont {
  width: 1020px;
  height: 800px;
  margin: 0 auto;
  position: relative;
}

.tv_promo_mov {
  position: absolute;
  top: 35px;
  left: 186px;
}

.tv_promo_bn {
  position: absolute;
  top: 438px;
  left: -18px;
}

.tv_promo_link {
  position: absolute;
  top: 706px;
  left: 379px;
  width: 263px;
  height: 63px;
  opacity: 0;
  filter: alpha(opacity=0);
  background: #fff;
}

.tv_promo_close {
  position: absolute;
  top: 11px;
  right: 0;
  width: 71px;
  height: 89px;
  background: url(../../img/common_layout/bn/btn_cf.png) 0 0 no-repeat;
}

.gnb_bnr_belt {
  max-height: 32px;
  transform-origin: 50% 0;
  transition: 700ms max-height cubic-bezier(0, 0.01, 0.2, 1);
  overflow: hidden;
}
.gnb_bnr_belt > a {
  width: 100%;
  height: 32px;
  display: block;
  position: relative;
  color: currentColor;
}
.gnb_bnr_belt > a > img {
  min-width: 1280px;
  display: block;
  margin: 0 auto;
}
.gnb_bnr_belt .gnb_bnr_desc {
  max-width: 600px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 20;
  transform: translate(-50%, -50%);
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 500;
  font-size: 12px;
  color: #222;
  line-height: 1.1666666667;
  letter-spacing: -0.3px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.gnb_bnr_belt .gnb_bnr_belt_safety {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  transform: translate(-50%, -50%);
  margin-left: 630px;
}
.gnb_bnr_belt .js_gnb_bnr_belt_close {
  display: block;
  line-height: 0;
}
.gnb_bnr_belt .js_gnb_bnr_belt_close .icon_ssg_close::after {
  content: "";
  background-image: url("data:image/svg+xml;charset=utf8,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M4.3623 18.7882L18.7871 4.36332L19.6357 5.21184L5.21082 19.6367L4.3623 18.7882Z' fill='rgb%280%2C0%2C0%29'%3e%3c/path%3e %3cpath d='M4.3623 5.21156L5.21082 4.36304L19.6357 18.7879L18.7871 19.6364L4.3623 5.21156Z' fill='rgb%280%2C0%2C0%29'%3e%3c/path%3e %3c/svg%3e");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-flex;
  width: 20px;
  height: 20px;
}
.gnb_bnr_belt > .js_gnb_bnr_belt_close {
  position: absolute;
  top: 50%;
  right: 320px;
  z-index: 10000;
  transform: translateY(-50%);
}
.gnb_bnr_belt.color_white .gnb_bnr_desc {
  color: #fff;
}
.gnb_bnr_belt.color_white .js_gnb_bnr_belt_close .icon_ssg_close::after {
  content: "";
  background-image: url("data:image/svg+xml;charset=utf8,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M4.3623 18.7882L18.7871 4.36332L19.6357 5.21184L5.21082 19.6367L4.3623 18.7882Z' fill='rgb%28255%2C255%2C255%29'%3e%3c/path%3e %3cpath d='M4.3623 5.21156L5.21082 4.36304L19.6357 18.7879L18.7871 19.6364L4.3623 5.21156Z' fill='rgb%28255%2C255%2C255%29'%3e%3c/path%3e %3c/svg%3e");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-flex;
  width: 20px;
  height: 20px;
}
.gnb_bnr_belt.is-active {
  max-height: 0;
}
.gnb_bnr_belt.is-active .gnb_bnr_desc {
  transition: 200ms opacity ease;
  opacity: 0;
}
.gnb_bnr_belt.is-active .js_gnb_bnr_belt_close {
  transition: 200ms opacity ease;
  opacity: 0;
}

/* 동영상 상품평 */
.zoomWrap {
  display: none;
  position: absolute;
  z-index: 100;
}

.zoomWrap .zoomTarget {
  position: relative;
  cursor: crosshair;
  background: url("../../img/common/b.gif") 0 0 repeat;
}

.zoomWrap .zoomLens {
  display: none;
  position: absolute;
  cursor: crosshair;
  background: #000;
  opacity: 0.05;
  -moz-opacity: 0.05;
  filter: alpha(opacity=5);
}

.zoomWrap .zoomWindow {
  display: none;
  position: absolute;
  overflow: hidden;
  border: 1px solid #888;
  background: #fff;
}

.zoomWrap .zoomWindow.inner {
  border: 0;
}

/* 상품상세 입고알림신청 */
/* 상품상세 입고알림신청 - 각몰 수정 */
/* 상품 옵션 */
.cm_option_wrap {
  display: inline-block;
  position: relative;
  z-index: 10;
  height: 13px;
  margin: -3px 0 0 4px;
  vertical-align: middle;
  *display: inline;
  *zoom: 1;
}

.cm_option_wrap .cm_option_bt {
  display: inline-block;
  overflow: hidden;
  width: 13px;
  height: 13px;
  background: url(../../img/common/sp_option.png) 0 0 no-repeat;
  line-height: 999px;
  vertical-align: top;
}

.cm_option_wrap.on .cm_option_bt {
  background-position: -20px 0;
}

.cm_option_wrap.on .cm_option {
  display: block;
}

.cm_option {
  display: none;
  position: absolute;
  top: 18px;
  right: -61px;
  width: 348px;
  border: 1px solid #666;
  background: #fff;
  font-size: 12px;
  line-height: 17px;
  text-align: left;
}

.cm_option .sp_opt {
  display: inline-block;
  overflow: hidden;
  background: url(../../img/common/sp_option.png) no-repeat;
  line-height: 999px;
  vertical-align: top;
}

.cm_option .opt_bx {
  padding-top: 4px;
}

.cm_option .opt_item {
  margin: 20px 0;
  padding: 0 11px 0 15px;
}

.cm_option .opt_form {
  font-size: 0;
}

.cm_option .opt_form dt {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  width: 95px;
  margin-right: 8px;
  color: #222;
  font-size: 11px;
  letter-spacing: -1px;
  line-height: 1.3em;
  vertical-align: middle;
  word-break: break-all;
  word-wrap: break-word;
}

.cm_option .opt_form dt.a_top {
  padding-top: 1px;
  vertical-align: top;
}

.cm_option .opt_form dt label {
  vertical-align: middle;
}

.cm_option .opt_form dt label strong {
  font-weight: normal;
}

.cm_option .opt_form dd {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  overflow: hidden;
  position: relative;
  width: 219px;
  min-height: 18px;
  color: #666;
  font-size: 11px;
  letter-spacing: -1px;
  vertical-align: middle;
  word-break: break-all;
  word-wrap: break-word;
}

.cm_option .opt_form dd .tx_point {
  color: #73b1da;
}

.cm_option .opt_form dd .inpbx {
  display: block;
  height: 16px;
  padding: 1px 5px;
  border: 1px solid #b2b2b2;
}

.cm_option .opt_form dd .inpbx input {
  color: #666;
  font-size: 11px;
}

.cm_option .opt_form dd select {
  width: 100%;
  height: 20px;
  border: 1px solid #b2b2b2;
  color: #666;
  font-size: 11px;
}

.cm_option .opt_tip {
  position: relative;
  margin: 6px 0 -4px;
  color: #666;
  font-size: 11px;
  letter-spacing: -1px;
  line-height: 16px;
  word-break: break-all;
  word-wrap: break-word;
}

.cm_option .opt_item_addition {
  background: url(../../img/common/line_dot.gif) repeat-x;
}

.cm_option .opt_item_addition .opt_form {
  padding-top: 20px;
}

.cm_option .opt_item_result {
  position: relative;
  margin: 0;
  padding: 6px 30px 6px 13px;
  background: #f6f6f6 url(../../img/common/line_dot.gif) repeat-x;
  zoom: 1;
}

.cm_option .opt_item_result:after {
  display: block;
  clear: both;
  content: "";
}

.cm_option .opt_item_result .opt_tx {
  float: left;
  position: relative;
  width: 120px;
  padding: 2px 5px 0 21px;
  background: url(../../img/common/sp_option.png) -100px 3px no-repeat;
  color: #333;
  word-break: break-all;
  word-wrap: break-word;
}

.cm_option .opt_item_result .opt_tx em {
  color: #e7340b;
}

.cm_option .opt_item_result .opt_tx.sub {
  background: none;
}

.cm_option .opt_item_result .opt_tx.sub .ic_sub {
  position: absolute;
  top: 7px;
  left: 7px;
  width: 7px;
  height: 7px;
  background-position: 0 -20px;
}

.cm_option .opt_item_result .opt_set {
  float: right;
}

.cm_option .opt_item_result .opt_set:after {
  display: block;
  clear: both;
  content: "";
}

.cm_option .opt_item_result .opt_amount {
  position: relative;
  float: left;
  width: 24px;
  padding: 0 12px 0 2px;
  border: 1px solid #cbcbcb;
  background: #fff;
}

.cm_option .opt_item_result .opt_amount .sp_opt {
  position: absolute;
  right: -1px;
  width: 11px;
  height: 11px;
}

.cm_option .opt_item_result .opt_amount .b_plus {
  top: -1px;
  background-position: -20px -20px;
}

.cm_option .opt_item_result .opt_amount .b_minus {
  bottom: -1px;
  background-position: -40px -20px;
}

.cm_option .opt_item_result .opt_amount .opa_area {
  display: block;
}

.cm_option .opt_item_result .opt_amount .opa_area input {
  width: 100%;
  border: 0;
  background: transparent;
  color: #777;
  font-size: 11px;
  text-align: center;
}

.cm_option .opt_item_result .price {
  float: right;
  width: 100px;
  color: #333;
  font-weight: bold;
  line-height: 22px;
  text-align: right;
}

.cm_option .opt_item_result .price em {
  margin-right: 2px;
}

.cm_option .opt_item_result .bt_opt_del {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 13px;
  height: 13px;
  background-position: -40px 0;
}

.cm_option .opt_bt {
  padding: 10px;
  background: url(../../img/common/line_dot.gif) repeat-x;
  font-size: 0;
  text-align: center;
}

.cm_option .opt_bt .bt {
  display: inline-block;
  overflow: hidden;
  width: 71px;
  height: 26px;
  margin: 0 2px;
  border-radius: 2px;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: -1px;
  text-align: center;
  vertical-align: top;
}

.cm_option .opt_bt .bt.color1 {
  border: 1px solid #6d6d6d;
  background-color: #7f7f7f;
}

.cm_option .opt_bt .bt.color2 {
  border: 1px solid #f14f4f;
  background-color: #f14f4f;
}

/* 이벤트 OSMU */
.evt_osmu {
  overflow: hidden;
  margin: 0 auto;
  padding-top: 15px;
  font-size: 0;
  line-height: 0;
}

.evt_osmu_lst {
  width: 1048px;
  margin: 0 0 0 -14px;
  *zoom: 1;
}

.evt_osmu_unit {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  position: relative;
  float: none;
  width: 262px;
  height: auto;
  border-right: 1px dashed #cfcfcf;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  vertical-align: top;
}

.evt_osmu_unit .eo_ic {
  background-image: url(../../img/common/sp_evt_osmu.png);
  background-repeat: no-repeat;
}

.evt_osmu_unit .eo_check {
  display: none;
  padding: 8px 14px;
}

.evt_osmu_unit .eo_check input {
  width: 14px;
  height: 14px;
  vertical-align: middle;
}

.evt_osmu_unit .eo_link {
  display: block;
  position: relative;
  padding: 0 14px;
  font-size: 13px;
  line-height: 17px;
  text-decoration: none;
  cursor: pointer;
}

.evt_osmu_unit .eo_thmb .thmb {
  display: block;
  overflow: hidden;
  width: 234px;
  height: 167px;
}

.evt_osmu_unit .eo_thmb .thmb img {
  width: 100%;
  vertical-align: top;
}

.evt_osmu_unit .eo_detail {
  padding: 10px 7px 17px;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
}

.evt_osmu_unit .eo_detail .eo_period {
  min-height: 23px;
  display: block;
  font-size: 11px;
  line-height: 23px;
}

.evt_osmu_unit .eo_detail .eo_period em {
  color: #888;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
  letter-spacing: 0.2px;
}

.evt_osmu_unit .eo_detail .eo_period .eo_ic_fin {
  display: inline-block;
  overflow: hidden;
  width: 32px;
  height: 21px;
  margin-right: 7px;
  background-position: 0 0;
  line-height: 999px;
  vertical-align: middle;
}

.evt_osmu_unit .eo_detail .eo_tit {
  height: 92px;
}

.evt_osmu_unit .eo_detail .eo_tit strong {
  display: block;
  overflow: hidden;
  max-height: 44px;
  margin: 5px 0;
  color: #222;
  font-size: 19px;
  font-weight: normal;
  line-height: 22px;
  word-break: break-all;
  word-wrap: break-word;
}

.evt_osmu_unit .eo_detail .eo_tit p {
  overflow: hidden;
  color: #888;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.evt_osmu_unit .eo_detail hr {
  display: block;
  overflow: hidden;
  width: 15px;
  height: 1px;
  margin: 0;
  *margin: -2px 0 -7px;
  padding: 0;
  border: 0;
  background-color: #919191;
  text-align: left;
  clear: both;
}

.evt_osmu_unit .eo_detail .eo_tags {
  padding-bottom: 3px;
}

.evt_osmu_unit .eo_detail .eo_tags .eo_mall {
  height: 17px;
  margin-top: 9px;
}

.evt_osmu_unit .eo_detail .eo_tags .eo_mall em {
  display: inline-block;
  color: #444;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
  font-style: italic;
  white-space: nowrap;
}

.evt_osmu_unit .eo_detail .eo_tags .eo_pub {
  display: none;
  margin-top: 9px;
}

.evt_osmu_unit .eo_detail .eo_tags .eo_pub li {
  color: #888;
  line-height: 18px;
}

.evt_osmu_unit .eo_detail .eo_tags .eo_pub li strong {
  margin-right: 5px;
  font-weight: normal;
}

.evt_osmu_unit .eo_link.pub .eo_detail .eo_tags .eo_pub {
  display: block;
}

.evt_osmu_unit .eo_ic_clip {
  position: absolute;
  right: 15px;
  bottom: 20px;
  z-index: 10;
  overflow: hidden;
  width: 28px;
  height: 27px;
  background-position: 0 -30px;
}

.evt_osmu.selective {
  position: relative;
  margin-top: 15px;
  padding: 0;
  background: url(../../img/common/bg_evt_osmu2.gif) 0 100% repeat-x;
}

.evt_osmu.selective .evt_osmu_lst {
  margin-top: -1px;
}

.evt_osmu.selective .evt_osmu_unit {
  padding-top: 1px;
  background: url(../../img/common/bg_evt_osmu2.gif) 0 0 repeat-x;
}

.evt_osmu.selective .eo_check {
  display: block;
}

.evt_osmu.selective .eo_ic_clip {
  display: block;
}

.evt_osmu.col3 {
  width: 758px;
}

.evt_osmu.col3 .evt_osmu_lst {
  width: 786px;
}

.evt_osmu.col5 .evt_osmu_lst {
  width: 1305px;
  background: url(../../img/common/bg_evt_osmu3.gif) 1px 0 repeat;
}

.evt_osmu.col5 .evt_osmu_unit {
  width: 261px;
}

/* 검색결과 deal_count 숨김 */
.result_relative.hb .deal_count,
.result_relative.obj .deal_count {
  display: none;
}

/* 프로모션,기획전 종료안내 레이어 */
.ly_endpm {
  position: relative;
  z-index: 1000;
}

.ly_endpm .sp_endpm {
  background-image: url(../../img/event/sp_endpm.png);
  background-repeat: no-repeat;
}

.ly_endpm .dimmed_endpm {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 906px;
  background: url(../../img/event/bg_endpm.png) 0 0 repeat-x;
}

.ly_endpm .endpm_wrap {
  position: absolute;
  left: 50%;
  top: 150px;
  width: 1020px;
  margin-left: -510px;
  padding-bottom: 30px;
  background-color: #fff;
}

.ly_endpm .endpm_tit {
  width: 551px;
  height: 116px;
  margin: -29px auto 0;
}

.ly_endpm .btn_endpm_back {
  position: absolute;
  left: 33px;
  top: 26px;
  display: inline-block;
  width: 33px;
  height: 33px;
  background-position: -640px 0;
}

.ly_endpm .btn_endpm_close {
  position: absolute;
  right: 32px;
  top: 27px;
  display: inline-block;
  width: 31px;
  height: 31px;
  background-position: -680px 0;
}

.ly_endpm .evt_osmu {
  padding-top: 0;
  text-align: center;
}

.ly_endpm .evt_osmu_lst {
  width: 1020px;
  margin: 0 auto;
  background: none;
}

.ly_endpm .evt_osmu_unit {
  width: 239px;
}

.ly_endpm .evt_osmu_unit .eo_link {
  padding: 0 3px;
}

.ly_endpm .evt_osmu_unit .eo_detail {
  padding-bottom: 5px;
}

.ly_endpm .endpm_cont_tit {
  width: 953px;
  height: 20px;
  margin: 30px auto 26px;
}

.ly_endpm .endpm_cont_tit.tit_event {
  background-position: 0 -140px;
}

.ly_endpm .endpm_cont_tit.tit_benefit {
  background-position: 0 -170px;
}

.ly_endpm .anoter_btn_wrap {
  text-align: center;
}

.ly_endpm .anoter_btn_wrap a {
  display: inline-block;
  width: 237px;
  height: 55px;
  margin-left: 5px;
}

.ly_endpm .anoter_btn_wrap a:first-child {
  margin-left: 0;
}

.ly_endpm .anoter_btn_wrap .btn_another01 {
  background-position: -723px 0;
}

.ly_endpm .anoter_btn_wrap .btn_another02 {
  background-position: -723px -57px;
}

.ly_endpm.ly_endexb .endpm_wrap {
  top: 95px;
  width: 640px;
  margin-left: -320px;
}

.ly_endpm.ly_endexb .endpm_wrap.v2 {
  top: 95px;
  width: 780px;
  margin-left: -390px;
}
.ly_endpm.ly_endexb .endpm_wrap.v2 .sp_endpm {
  background-image: url(../../img/event/sp_endexb_v1.png);
}
.ly_endpm.ly_endexb .endpm_wrap.v2 .endpm_tit {
  width: 600px;
  height: 210px;
  margin: -30px auto 0;
  padding: 0;
  background-color: transparent;
}

.ly_endpm.ly_endexb .sp_endpm {
  background-image: url(../../img/event/sp_endexb.png);
}

.ly_endpm.ly_endexb .endpm_tit {
  width: 482px;
  height: 169px;
  margin: -30px auto 0;
  padding: 0;
  background-color: transparent;
}

.ly_endpm .endpm_tit_txt {
  width: 482px;
  margin: 30px auto 0;
  text-align: center;
  font-size: 35px;
  color: #222;
  line-height: 45px;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
}

.ly_endpm.ly_endexb .btn_endpm_back {
  left: 26px;
  top: 22px;
}

.ly_endpm.ly_endexb .btn_endpm_close {
  right: 22px;
  top: 23px;
}

.ly_endpm.ly_endexb .endpm_cont_tit.tit_benefit {
  width: 178px;
  height: 18px;
  margin: 17px auto 23px;
  background-position: 100% -116px;
}

/* 이벤트 이미지 게시판  */
.inpbx {
  display: inline-block;
  height: 22px;
  padding: 5px 10px 0;
  background: #fff;
}
.inpbx input {
  width: 100%;
  height: 16px;
  border: 0;
  vertical-align: top;
  line-height: 16px;
}

.img_board_wrap {
  width: 1020px;
  margin: 0 auto;
}

.img_board_wrap .h_evt_reply {
  height: 22px;
  margin: 59px 0 0;
  background: url(../../img/common/tit_evt_reply.png) no-repeat;
}

.reply_register_wrap {
  position: relative;
  margin-top: 10px;
  padding: 10px 135px 10px 0;
  border: 1px solid #e5e5e5;
  background: #fafafa;
}

.reply_register_wrap .inp_wrap {
  margin-top: 5px;
}

.reply_register_wrap .inp_wrap:first-child {
  margin-top: 0;
}

.reply_register_wrap label {
  float: left;
  width: 26px;
  height: 13px;
  margin: 19px 11px 0 17px;
}

.reply_register_wrap .lb_txt {
  background: url(../../img/common/txt_board_title.gif) no-repeat;
}

.reply_register_wrap .lb_photo {
  background: url(../../img/common/txt_board_photo.gif) no-repeat;
}

.reply_register_wrap .inp {
  overflow: hidden;
  display: block;
  height: 48px;
  padding: 0 19px;
  border: 1px solid #e5e5e5;
  background: #fff;
}

.reply_register_wrap .inp input {
  width: 100%;
  height: 23px;
  margin-top: 14px;
  border: 0;
  background: #fff;
  color: #959595;
  line-height: 23px\9 ;
}

.reply_register_wrap .inp input::-ms-value {
  border: 0;
  background: #fff;
  color: #959595;
}

.reply_register_wrap .inp_txt {
  position: relative;
  padding-right: 92px;
}

.reply_register_wrap .inp_txt input {
  height: 42px;
  margin-top: 3px;
  border: 0;
  font-size: 16px;
  color: #3d3d3d;
  line-height: 42px;
  letter-spacing: -1px;
}

.reply_register_wrap .inp_txt .tx_desc {
  position: absolute;
  right: 0;
  top: 17px;
  width: 88px;
  height: 14px;
  border-left: 1px solid #e5e5e5;
  color: #888;
  text-align: center;
  line-height: 14px;
}

.reply_register_wrap .btn_register_reply {
  position: absolute;
  right: -1px;
  top: -1px;
}

.img_board_wrap .lst_noti {
  margin-top: 9px;
  text-align: left;
}

.img_board_wrap .lst_noti li {
  margin-top: 4px;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #737272;
  letter-spacing: -1px;
  line-height: 14px;
}

.img_board_top {
  position: relative;
  margin-top: 57px;
}

.img_board_top .btn_my_writing {
  position: absolute;
  top: 0;
  right: 0;
  width: 126px;
  height: 30px;
  background: url(../../img/common/btn_my_writing.png) no-repeat;
}

.img_board_top .sort_lst_wrap {
  zoom: 1;
}

.img_board_top .sort_lst_wrap:after {
  display: block;
  clear: both;
  content: "";
}

.img_board_top .sort_lst_wrap li {
  float: left;
  width: 74px;
  height: 30px;
  margin-left: -1px;
  background: url(../../img/common/sp_board_sort.png) no-repeat;
}

.img_board_top .sort_lst_wrap li a {
  display: block;
  height: 100%;
}

.img_board_top .sort_lst_wrap .lst {
  margin-left: 0;
}

.img_board_top .sort_lst_wrap .lst2 {
  background-position: -73px 0;
}

.img_board_top .sort_lst_wrap .lst3 {
  width: 75px;
  background-position: -146px 0;
}

.img_board_top .sort_lst_wrap .lst.active {
  background-position: 0 -32px;
}

.img_board_top .sort_lst_wrap .lst2.active {
  background-position: -73px -32px;
}

.img_board_top .sort_lst_wrap .lst3.active {
  background-position: -146px -32px;
}

.img_board_list {
  overflow: hidden;
  margin: 10px 0 20px;
  padding: 5px 0 10px;
  border-top: 4px solid #444;
  border-bottom: 1px solid #ccc;
}

.img_board_list ul {
  margin-left: -20px;
  zoom: 1;
}

.img_board_list ul:after {
  display: block;
  clear: both;
  content: "";
}

.img_board_list li {
  float: left;
  width: 240px;
  margin: 10px 0 0 20px;
  padding-bottom: 20px;
  height: 224px;
}

.img_board_list li .tit {
  overflow: hidden;
  display: block;
  margin-top: 8px;
  font-size: 14px;
  color: #222;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.img_board_list .writing_info {
  margin: 7px 0 0;
  padding: 0;
}

.img_board_list .writing_info li {
  width: auto;
  height: 11px;
  margin: 0;
  padding: 0 10px;
  border-left: 1px solid #d6d6d6;
  font-size: 11px;
  color: #777;
  line-height: 11px;
}

.img_board_list .writing_info li:first-child {
  border-left: 0;
}

.img_board_list .writing_info li.tx_userid {
  padding-left: 16px;
  background: url(../../img/common/ico_userid.gif) no-repeat;
}

.img_board_list li .pic {
  position: relative;
  width: 240px;
  height: 180px;
}

.img_board_list li .pic a {
  display: block;
  background: #000;
  text-align: center;
}

.img_board_list li .pic a img {
  width: auto;
  max-width: 240px;
  vertical-align: top;
}

.img_board_list li .pic .btn_rcmd {
  overflow: hidden;
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 35px;
  height: 32px;
  padding-left: 40px;
  background: url(../../img/common/sp_board.png) no-repeat;
  font-size: 15px;
  font-weight: bold;
  color: #dedede;
  text-decoration: none;
  z-index: 10;
}

.img_board_list li .pic .btn_rcmd em {
  display: block;
  padding-right: 21px;
  background: url(../../img/common/sp_board.png) no-repeat 100% -68px;
  line-height: 33px;
  text-align: right;
}

.img_board_list li .pic .btn_rcmd_on {
  background-position: 0 -34px;
}

.img_board_list li .pic .btn_rcmd_on em {
  background-position: -105px -68px;
}

.img_board_list li .pic .bg_rcmd_succ {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: url(../../img/common/bg_rcmd_succ.png) no-repeat;
  z-index: 10;
}

.img_board_list li .pic .bg_rcmd_fail {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: url(../../img/common/bg_rcmd_fail.png) no-repeat;
  z-index: 10;
}

.img_board_list li .pic .bg_rcmd_succ.on,
.img_board_list li .pic .bg_rcmd_fail.on {
  display: block;
}

/* 공식스토어 */
.com_store {
  width: 1020px;
  margin: 30px auto;
}

.com_store.store_wrap:after {
  display: block;
  clear: both;
  content: "";
}

.com_store .store_list {
  margin: 0 -4px 0 -17px;
}

.com_store .store_list a.sel_clip {
  background-image: none !important;
}

.com_store .store_list li {
  display: inline-block;
  width: 240px;
  margin: 20px 0 10px 17px;
  height: auto;
  vertical-align: top;
}

.com_store .store_list li .store_img {
  position: relative;
  display: block;
  height: 145px;
  background-color: #f3f3f5;
}

/* 363218 오픈 후 삭제 */
.com_store .store_list li .store_img .bdg {
  position: absolute;
  top: 0;
  left: 0;
  padding: 3px 15px 1px 15px;
  background-color: #f14f4f;
  color: #fff;
}

/* //363218 오픈 후 삭제 */
.com_store .store_list li .store_img .bdg_open {
  position: absolute;
  top: 0;
  left: 0;
  background: url(../../img/common/ic_open.png) no-repeat 0 0/64px auto;
  width: 64px;
  height: 25px;
}

.com_store .store_list li .store_img .cm_clip {
  overflow: hidden;
  background: url(../../img/common/sp_ccp.png) -590px -85px no-repeat;
  width: 31px;
  height: 31px;
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.com_store .store_list li .store_img .cm_clip.on {
  background-position: -624px -85px;
}

.com_store .store_list li .store_img .cm_clip_item a.sel_clip {
  width: 31px;
  height: 31px;
}

.com_store .store_list .tt_adinfo_n {
  left: 0;
  bottom: 0;
}

.com_store .store_list .tt_adinfo_n .tt_adinfo_layer {
  top: -45px;
  left: 10px;
}

@media screen and (-webkit-min-device-pixel-ratio: 2) {
  .com_store .store_list li .store_img .cm_clip {
    background: url(../../img/common/clip_off.svg) 0 0 no-repeat !important;
    width: 30px;
    height: 30px;
  }
  .com_store .store_list li .store_img .cm_clip.on {
    background: url(../../img/common/clip_on.svg) 0 0 no-repeat !important;
  }
  .com_store .store_list li .store_img .bdg_open {
    background: url(../../img/common/ic_open_2x.png) no-repeat 0 0;
    background-size: 64px auto;
  }
}
.store_wrap .store_big .store_list li .store_img .cm_clip {
  width: 60px;
  height: 60px;
  background-position: -587px -148px;
}

.store_wrap .store_big .store_list li .store_img .cm_clip a {
  width: 60px;
  height: 60px;
}

.store_wrap .store_big .store_list li .store_img .cm_clip.on {
  background-position: -658px -148px;
}

.store_wrap {
  position: relative;
}

.store_wrap h2.tit {
  width: 1020px;
  overflow: hidden;
  padding: 30px 0 15px;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
  font-size: 50px;
  line-height: 52px;
  color: #231f20;
  text-align: center;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
  font-weight: normal;
}

.store_wrap h2.tit span {
  display: block;
  font-size: 18px;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
}

.store_wrap h3.sub_tit {
  position: relative;
  background: url(../../img/common/hx_cnt_case1.gif) 0 50% repeat-x;
  margin-top: 30px;
  font-size: 26px;
  color: #222;
  line-height: normal;
  letter-spacing: -2px;
  text-align: center;
}

.store_wrap h3.sub_tit .tt {
  display: inline-block;
  padding: 0 20px;
  background: #fff;
}

.store_wrap .store_big .store_list li {
  float: left;
  width: 500px;
  height: 375px;
}

.store_wrap .store_big .store_list li .store_img {
  position: relative;
  display: block;
  height: 300px;
  background-color: #f3f3f5;
}

.store_wrap .store_big .store_list li .store_text .brand {
  text-align: left;
}

.store_wrap .com_store_tab {
  position: relative;
  width: 100%;
  margin-top: 26px;
  display: table;
  margin-left: -1px;
}

.store_wrap .com_store_tab li {
  display: table-cell;
  min-width: 145px;
  border: 1px solid #c4c4c4;
  border-left: 0;
  border-bottom: 2px solid #777;
}

.store_wrap .com_store_tab li:first-child {
  border-left: 1px solid #c4c4c4;
}

.store_wrap .com_store_tab li a {
  color: #747474;
  text-align: center;
  border-bottom: 0;
  display: block;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
  text-decoration: none;
  margin: 0 -1px;
  height: 40px;
  line-height: 40px;
}

.store_wrap .com_store_tab li.active {
  position: relative;
  border: 1px solid #777;
  border-bottom: 0;
  z-index: 50;
}

.store_wrap .com_store_tab li.active a {
  overflow: hidden;
  position: relative;
  border: 1px solid #777;
  border-bottom: 0;
  background: #fff;
  color: #222;
  font-weight: bold;
  letter-spacing: -1px;
  z-index: 50;
  margin: 0;
}

.store_wrap .com_store_tab li a:active,
.store_wrap .com_store_tab li a:focus {
  background: none;
  border-right: 1px solid transparent;
  border-left: 1px solid #c4c4c4;
}

.store_wrap .com_store_tab li.active a:active,
.store_wrap .com_store_tab li.active a:focus {
  background: #fff;
  z-index: 20;
  border-right: 1px solid #777;
  border-left: 1px solid #777;
}

.store_wrap .sub_list {
  float: left;
  margin-bottom: 40px;
}

.store_wrap .list_no {
  text-align: center;
  margin: 50px 0 70px 0;
  display: inline-block;
  width: 1020px;
  color: #8b8b8b;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
}

.store_wrap .list_no span.txt1 {
  display: block;
  font-size: 26px;
}

.store_wrap .list_no span.txt2 {
  display: block;
  font-size: 12px;
  padding-top: 15px;
}

.body_sm .com_store.store_wrap h2.tit {
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
}

.body_sm .com_store.store_wrap h3.sub_tit {
  background: url(https://sui.ssgcdn.com/ui/sm/img/display/tit_bg.gif) 0 0 repeat-x;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
}

.body_sm .service.store .pr_path {
  margin: 15px 0 0;
  font-size: 13px;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
}

.body_sm .service.store .paginate {
  margin-bottom: 30px;
}

.body_sd .com_store.store_wrap h2 {
  border: none;
}

.body_sd .com_store.store_wrap h3.sub_tit {
  background: none;
  border: 1px solid #dbdbdb;
  font-size: 24px;
  color: #151515;
  line-height: 48px;
  text-align: center;
  font-weight: normal;
}

.body_sd .store_wrap .com_store_tab.sd_tab {
  margin-bottom: 0 !important;
  border: none !important;
}

.body_sd .store_wrap .com_store_tab li a {
  width: auto;
  font-size: 12px;
}

/* 공식스토어 1280 대응 */
.body_wide_ctn .com_store {
  width: 1280px;
}

.body_wide_ctn .store_wrap h2.tit {
  width: 1280px;
  font-size: 30px;
  padding: 13px 0 0 0;
  line-height: 32px;
}

.body_wide_ctn .store_wrap .store_big .store_list li {
  width: 634px;
  height: 457px;
}

.body_wide_ctn .store_wrap .store_big .store_list li .store_img {
  height: 382px;
}

.body_wide_ctn .com_store .store_list li {
  width: 305px;
}

.body_wide_ctn .com_store .store_list li .store_img {
  height: 183px;
}

.body_wide_ctn .store_wrap .list_no {
  width: 1280px;
}

/* 전문관 클립, 내보내기 */
.special_wrap_wide .special_menu {
  margin-left: 525px;
}

.special_menu {
  position: absolute;
  bottom: 19px;
  left: 50%;
  z-index: 40;
  width: 90px;
  height: 29px;
  margin-left: 420px;
  padding-top: 7px;
  background: url(../../img/special/sp_special_menu.png) 0 0 no-repeat;
  text-align: center;
}

.special_menu .sp_menu {
  display: inline-block;
  overflow: hidden;
  background: url(../../img/special/sp_special_menu.png) no-repeat;
  line-height: 999px;
  vertical-align: top;
}

.special_menu .cm_clip_special {
  display: inline-block;
  height: 17px;
  margin-top: 2px;
  vertical-align: middle;
}

.special_menu .cm_clip_special a {
  cursor: pointer;
}

.special_menu .cm_clip_special a.sp_ccp {
  background-image: url(../../img/special/sp_special_menu.png) !important;
}

.special_menu .cm_clip_special a.sel_clip {
  width: 21px;
  height: 17px;
  margin-top: 0;
  background-position: 0 -40px;
}

.special_menu .cm_clip_special.on a.sel_clip {
  background-position: 0 -60px;
}

.special_menu .special_sns {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}

.special_menu .special_bt_sns {
  display: inline-block;
  margin-left: 9px;
  vertical-align: middle;
  cursor: pointer;
}

.special_menu .special_bt_sns span {
  width: 18px;
  height: 17px;
  background-position: -30px -40px;
}

.special_menu .on .special_bt_sns span {
  background-position: -30px -60px;
}

.special_menu .special_ly_sns {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  width: 260px;
  margin-top: 9px;
  padding: 21px 0 27px;
  border: 1px solid #dedede;
  background: #fff;
}

.special_menu .special_ly_sns h3 {
  margin-bottom: 7px;
  font-size: 14px;
  font-weight: normal;
  font-family: "Century Gothic";
}

.special_menu .special_ly_sns .bt_close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 13px 19px;
  background: transparent;
}

.special_menu .special_ly_sns .bt_close span {
  width: 10px;
  height: 11px;
  background-position: -80px -40px;
}

.special_menu .special_ly_sns .cm_sns {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  vertical-align: top;
}

.special_menu .special_ly_sns .cm_sns li {
  width: 29px;
  height: 29px;
  margin: 0 2px;
}

.special_menu .special_ly_sns .cm_sns li a {
  display: block;
  width: 29px;
  height: 29px;
  background: url(../../img/lifem/sp_sns.png) no-repeat;
}

.special_menu .special_ly_sns .cm_sns li a.kakao {
  background-position: -33px 0;
}

.special_menu .special_ly_sns .cm_sns li a.facebook {
  background-position: 0 0;
}

.special_menu .special_ly_sns .cm_sns li a.twitter {
  background-position: -66px 0;
}

.special_menu .special_ly_sns .cm_sns li a.pinterest {
  background-position: -99px 0;
}

.special_menu .special_like {
  display: inline-block;
  vertical-align: middle;
}
.special_menu .special_like .cmlike {
  vertical-align: middle;
}

/* 레티나 대응 */
@media screen and (-webkit-min-device-pixel-ratio: 2) {
  .special_menu,
.special_menu .sp_menu,
.special_menu .cm_clip_special a.sp_ccp {
    background-image: url(../../img/special/sp_special_menu@2x.png) !important;
    background-size: 90px 90px;
  }
  .special_menu .special_ly_sns .cm_sns li a {
    background-image: url(../../img/lifem/sp_sns@2x.png);
    background-size: 130px 30px;
  }
}
/* myssg layer */
/*퀵뷰개편시 삭제*/
/*퀵뷰개편시 삭제*/
/* quickview layer */
.ly_cmqv.bly {
  display: none;
  position: relative;
  width: 1280px;
  border: 0;
  box-shadow: none;
  outline: none;
}

.ly_cmqv.bly .ly_cmqv_in {
  padding-top: 0;
}

.ly_cmqv.bly .ly_cmqv_cont {
  position: relative;
  width: 1278px;
  border: 1px solid #222;
  box-shadow: 2px 2px 1px 0 rgba(0, 0, 0, 0.1);
  outline: none;
  background: #fff;
}

.ly_cmqv.bly .ly_cmqv_ifr {
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  height: 750px;
  vertical-align: top;
}

.ly_cmqv.bly .cmqv_btn_close {
  display: inline-block;
  overflow: hidden;
  position: absolute;
  top: -49px;
  right: -1px;
  z-index: 10;
  width: 49px;
  height: 49px;
  background: url(../../img/product/v2/sp_cmqv.png) no-repeat 0 -83px;
  font-size: 0;
  line-height: 0;
  vertical-align: top;
}

.ly_cmqv.bly.ty920 {
  width: auto;
  min-width: 922px;
}

.ly_cmqv.bly.ty920 .ly_cmqv_cont {
  width: 937px;
}

.ly_cmqv.bly.ty400 {
  width: auto;
  min-width: 402px;
}

.ly_cmqv.bly.ty400 .ly_cmqv_cont {
  width: 417px;
}

/* 합병공고 */
.mergenotice {
  padding-bottom: 40px;
  background-color: #f1f1f1;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
  font-size: 13px;
  color: #222;
  /* 띠배너 */
}
.mergenotice .cm_linebn {
  text-align: center;
}
.mergenotice .cm_linebn img {
  vertical-align: top;
}
.mergenotice .mergenotice_row {
  margin-top: 20px;
}
.mergenotice .mergenotice_row:first-child {
  margin: 0;
}
.mergenotice .mergenotice_contwrap {
  display: none;
  padding: 1px 0;
  background-color: #fff;
}
.mergenotice .mergenotice_row.on .mergenotice_contwrap {
  display: block;
}
.mergenotice .mergenotice_cont {
  width: 1030px;
  margin: 0 auto;
}
.mergenotice .mergenotice_tit {
  padding: 30px 0 15px;
  font-size: 20px;
}
.mergenotice .mergenotice_txt p {
  margin: 0 0 20px;
  line-height: 20px;
  letter-spacing: -0.2px;
}
.mergenotice .mergenotice_close {
  padding: 0 0 40px;
  text-align: center;
}

/* 공통헤더 공지 레이어 */
.sp_nl {
  display: inline-block;
  overflow: hidden;
  width: auto;
  height: 10px;
  background: url(../../img/common/notice/sp_notice.png) no-repeat;
  line-height: 999px;
  vertical-align: top;
}

.notice_lpop {
  position: absolute;
  right: 0;
  top: 27px;
  z-index: 100;
  border: 2px solid #33353e;
}

.notice_lpop img {
  vertical-align: top;
}

.nl_cont {
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}

.nl_foot {
  height: 40px;
  line-height: 18px;
  background: #33353e;
  zoom: 1;
}

.nl_foot:after {
  display: block;
  clear: both;
  content: "";
}

.nlf_l {
  float: left;
  margin-left: 17px;
  padding-top: 14px;
  font-size: 11px;
  line-height: 12px;
  letter-spacing: -1px;
  color: #a39b97;
}

.nlf_l input {
  width: 13px;
  height: 13px;
  margin-right: 4px;
  vertical-align: top;
}

.nlf_l label {
  display: inline-block;
  margin: 1px 0 -1px;
  vertical-align: top;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
}

.nlf_r {
  float: right;
  margin-right: 6px;
  font-size: 0;
  line-height: 0;
}

.nl_close {
  display: inline-block;
  padding: 14px 14px 13px;
  background: transparent;
  line-height: 13px;
  vertical-align: top;
}

.nl_close .sp_nl {
  width: 13px;
  height: 13px;
  background-position: 0 0;
}

.ly_main_wrap {
  width: 1020px;
  margin: 0 auto;
  position: relative;
  z-index: 55;
}

.body_wide_ctn .ly_main_wrap {
  width: 1280px;
}

.nl_main {
  width: 350px;
  height: 440px;
  border: 2px solid #666;
}

/* 대체상품 레이어 */
.ly_chg_w {
  position: relative;
  width: 1018px;
  outline: none;
  border: 1px solid #000;
  border-top-width: 2px;
  display: none;
}

.ly_chg_cont {
  text-align: left;
  padding: 0;
  width: 800px;
  margin: 0 auto;
  padding-bottom: 40px;
}

.ly_chg_cont .table_itemlist td {
  background: none;
}

.ly_chg_cont .ly_chg_tit {
  height: 46px;
  background: url(../../img/common/sp_change.png) 0 0 no-repeat;
  margin-top: 40px;
  border-bottom: 1px solid #e2e2e1;
}

.ly_chg_cont .ly_chg_tit.notranslate {
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 500;
  font-size: 22px;
  background: none;
  line-height: 38px;
}

.ly_chg_cont .ly_chg_close {
  position: absolute;
  top: 17px;
  right: 17px;
  height: 21px;
  width: 21px;
  background: url(../../img/common/sp_change.png) -400px -100px no-repeat;
}

.ly_chg_cont .ly_chg_prev {
  position: absolute;
  top: 50%;
  left: 39px;
  height: 50px;
  width: 40px;
  background: url(../../img/common/sp_change.png) -200px -100px no-repeat;
  margin-top: -25px;
}

.ly_chg_cont .ly_chg_next {
  position: absolute;
  top: 50%;
  right: 39px;
  height: 50px;
  width: 40px;
  background: url(../../img/common/sp_change.png) -300px -100px no-repeat;
  margin-top: -25px;
}

.ly_chg_cont .ly_chg_btn {
  border-top: 1px dashed #d8d8d8;
  clear: both;
  margin: 0 26px 0 27px;
  padding-top: 20px;
  line-height: 0;
}

.ly_chg_cont .ly_chg_cart {
  height: 40px;
  width: 150px;
  background: url(../../img/common/sp_change.png) 0 -100px no-repeat;
}

.ly_chg_goods {
  min-height: 450px;
}

.ly_chg_goods.loading {
  background: url(../../img/common/loading.gif) 50% 50% no-repeat;
}

.ly_chg_goods .table_itemlist,
.ly_chg_goods .lst_item {
  display: none;
}

.ly_chg_goods .table_itemlist.on {
  display: block;
}

.ly_chg_goods .lst_item.on {
  display: table;
}

.ly_chg_goods .cunit_lst_v {
  display: none;
}

.ly_chg_goods .cunit_lst_v.on {
  display: block;
}

.body_wide_ctn .ly_chg_goods .cunit_thmb_lst {
  width: 800px;
  padding: 0;
}
.body_wide_ctn .ly_chg_goods .cunit_thmb_lst > li {
  width: 232px;
  margin-right: 52px;
}
.body_wide_ctn .ly_chg_goods .cunit_thmb_lst > li:nth-child(3n+3) {
  margin-right: 0;
}

.ly_chg_goods .cmitem_grid,
.ly_chg_goods .mnemitem_grid {
  display: none;
}
.ly_chg_goods .cmitem_grid.on,
.ly_chg_goods .mnemitem_grid.on {
  display: block;
}
.ly_chg_goods .cmitem_grid .cmitem_grid_lst,
.ly_chg_goods .cmitem_grid .mnemitem_grid_lst,
.ly_chg_goods .mnemitem_grid .cmitem_grid_lst,
.ly_chg_goods .mnemitem_grid .mnemitem_grid_lst {
  width: 800px;
  gap: 0 52px;
}
.ly_chg_goods .cmitem_grid .cmitem_grid_lst > li,
.ly_chg_goods .cmitem_grid .mnemitem_grid_lst > li,
.ly_chg_goods .mnemitem_grid .cmitem_grid_lst > li,
.ly_chg_goods .mnemitem_grid .mnemitem_grid_lst > li {
  width: 232px;
}

.body_em .ly_chg_cont .lst_item,
.body_traders .ly_chg_cont .lst_item {
  border: 0;
  margin-top: 20px;
}

.body_em .ly_chg_cont .lst_item td,
.body_traders .ly_chg_cont .lst_item td {
  border: 0;
  padding: 0;
}

.body_em .ly_chg_cont .ly_chg_btn,
.body_traders .ly_chg_cont .ly_chg_btn {
  border-top: 0;
  padding-top: 3px;
  margin: 0 0 0 31px;
}

.body_em .ly_chg_cont .ly_chg_close,
.body_traders .ly_chg_cont .ly_chg_close {
  position: absolute;
  top: 17px;
  right: 17px;
  height: 25px;
  width: 25px;
  background: url(../../img/common/sp_change_em.png) -400px -100px no-repeat;
}

.body_em .ly_chg_cont .ly_chg_prev,
.body_traders .ly_chg_cont .ly_chg_prev {
  position: absolute;
  top: 50%;
  left: 39px;
  height: 50px;
  width: 40px;
  background: url(../../img/common/sp_change_em.png) -200px -100px no-repeat;
  margin-top: -25px;
}

.body_em .ly_chg_cont .ly_chg_next,
.body_traders .ly_chg_cont .ly_chg_next {
  position: absolute;
  top: 50%;
  right: 39px;
  height: 50px;
  width: 40px;
  background: url(../../img/common/sp_change_em.png) -300px -100px no-repeat;
  margin-top: -25px;
}

.body_em .ly_chg_cont .ly_chg_cart {
  background: url(../../img/common/sp_change_em.png) 0 -100px no-repeat;
}

.body_traders .ly_chg_cont .ly_chg_cart {
  background: url(../../img/common/sp_change_em.png) 0 -150px no-repeat;
}

/* 공통 몰아이콘(#364373) */
.cm_mall_ic {
  display: inline-block;
  font-size: 0;
  vertical-align: top;
}

.cm_mall_ic > i {
  display: inline-block;
  overflow: hidden;
  background-image: url(../../img/common/sp_cm_mall_ic_20220722.png);
  background-repeat: no-repeat;
  font-size: 12px;
  vertical-align: top;
}

.cm_mall_ic > i.ssg {
  background-position-y: 0;
}

.cm_mall_ic > i.sm {
  background-position-y: -50px;
}

.cm_mall_ic > i.sd {
  background-position-y: -100px;
}

.cm_mall_ic > i.em {
  background-position-y: -150px;
}

.cm_mall_ic > i.tr {
  background-position-y: -200px;
}

.cm_mall_ic > i.mnmorning {
  background-position-y: -250px;
}

.cm_mall_ic > i.mnstarbucks {
  background-position-y: -300px;
}

.cm_mall_ic > i.tv {
  background-position-y: -350px;
}

.cm_mall_ic > i.si {
  background-position-y: -400px;
}

.cm_mall_ic > i.outlet {
  background-position-y: -450px;
}

.cm_mall_ic > i.mncasamia {
  background-position-y: -500px;
}

.cm_mall_ic > i.sf {
  background-position-y: -550px;
}

.cm_mall_ic > i.hwd {
  background-position-y: -600px;
}

.cm_mall_ic > i.mndeptfood {
  background-position-y: -650px;
}

.cm_mall_ic > i.mnchicor {
  background-position-y: -700px;
}

.cm_mall_ic > i.mntrip {
  background-position-y: -750px;
}

.cm_mall_ic > i.mndutyfree {
  background-position-y: -800px;
}

.cm_mall_ic > i.mnssgfood {
  background-position-y: -850px;
}

/* 공통 몰아이콘 - 원형 small */
.cm_mall_ic.ty_circle_s {
  line-height: 20px;
}

.cm_mall_ic.ty_circle_s > i {
  width: 20px;
  height: 20px;
  background-position-x: 0;
}

.cm_mall_ic.ty_circle_s > i + i {
  margin-left: 2px;
}

/* 공통 몰아이콘 - 원형 medium */
.cm_mall_ic.ty_circle_m {
  line-height: 30px;
}

.cm_mall_ic.ty_circle_m > i {
  width: 30px;
  height: 30px;
  background-position-x: -50px;
}

.cm_mall_ic.ty_circle_m > i + i {
  margin-left: 2px;
}

/* 공통 몰아이콘 - 원형 large */
.cm_mall_ic.ty_circle_l {
  line-height: 40px;
}

.cm_mall_ic.ty_circle_l > i {
  width: 40px;
  height: 40px;
  background-position-x: -100px;
}

.cm_mall_ic.ty_circle_l > i + i {
  margin-left: 2px;
}

/* 공통 몰아이콘 - 원형 xlarge */
.cm_mall_ic.ty_circle_xl {
  line-height: 40px;
}

.cm_mall_ic.ty_circle_xl > i {
  height: 40px;
  background-position-x: -150px;
}

.cm_mall_ic.ty_circle_xl > i + i {
  margin-left: 2px;
}

.cm_mall_ic.ty_circle_xl > i.ssg {
  width: 97px;
}

.cm_mall_ic.ty_circle_xl > i.sm {
  width: 86px;
}

.cm_mall_ic.ty_circle_xl > i.sd {
  width: 102px;
}

.cm_mall_ic.ty_circle_xl > i.em {
  width: 90px;
}

.cm_mall_ic.ty_circle_xl > i.tr {
  width: 100px;
}

.cm_mall_ic.ty_circle_xl > i.mnmorning {
  width: 84px;
}

.cm_mall_ic.ty_circle_xl > i.mnstarbucks {
  width: 129px;
}

.cm_mall_ic.ty_circle_xl > i.tv {
  width: 108px;
}

.cm_mall_ic.ty_circle_xl > i.si {
  width: 113px;
}

.cm_mall_ic.ty_circle_xl > i.outlet {
  width: 133px;
}

.cm_mall_ic.ty_circle_xl > i.mncasamia {
  width: 97px;
}

.cm_mall_ic.ty_circle_xl > i.sf {
  width: 96px;
}

.cm_mall_ic.ty_circle_xl > i.hwd {
  width: 84px;
}

.cm_mall_ic.ty_circle_xl > i.mndeptfood {
  width: 116px;
}

.cm_mall_ic.ty_circle_xl > i.mnchicor {
  width: 93px;
}

.cm_mall_ic.ty_circle_xl > i.mntrip {
  width: 71px;
}

/* 공통 몰아이콘 - 사각형 small */
.cm_mall_ic.ty_rect_s {
  line-height: 17px;
}

.cm_mall_ic.ty_rect_s > i {
  height: 17px;
  margin-right: 5px;
  background-position-x: -300px;
}

.cm_mall_ic.ty_rect_s > i.sm {
  width: 49px;
}

.cm_mall_ic.ty_rect_s > i.sd {
  width: 68px;
}

.cm_mall_ic.ty_rect_s > i.em {
  width: 49px;
}

.cm_mall_ic.ty_rect_s > i.tr {
  width: 59px;
}

.cm_mall_ic.ty_rect_s > i.mnmorning {
  width: 50px;
}

.cm_mall_ic.ty_rect_s > i.mnstarbucks {
  width: 50px;
}

.cm_mall_ic.ty_rect_s > i.tv {
  width: 72px;
}

.cm_mall_ic.ty_rect_s > i.si {
  width: 70px;
}

.cm_mall_ic.ty_rect_s > i.outlet {
  width: 81px;
}

.cm_mall_ic.ty_rect_s > i.mncasamia {
  width: 52px;
}

.cm_mall_ic.ty_rect_s > i.hwd {
  width: 42px;
}

.cm_mall_ic.ty_rect_s > i.mndeptfood {
  width: 68px;
}

.cm_mall_ic.ty_rect_s > i.mnchicor {
  width: 44px;
}

.cm_mall_ic.ty_rect_s > i.mntrip {
  width: 31px;
}

.cm_mall_ic.ty_rect_s > i.mndutyfree {
  width: 40px;
}

.cm_mall_ic.ty_rect_s > i.mnssgfood {
  width: 71px;
}

/* 공통 몰아이콘 - 사각형 medium */
.cm_mall_ic.ty_rect_m {
  line-height: 21px;
}

.cm_mall_ic.ty_rect_m > i {
  height: 21px;
  margin-right: 5px;
  background-position-x: -400px;
}

.cm_mall_ic.ty_rect_m > i.ssg {
  width: 65px;
}

.cm_mall_ic.ty_rect_m > i.sm {
  width: 56px;
}

.cm_mall_ic.ty_rect_m > i.sd {
  width: 76px;
}

.cm_mall_ic.ty_rect_m > i.em {
  width: 56px;
}

.cm_mall_ic.ty_rect_m > i.tr {
  width: 67px;
}

.cm_mall_ic.ty_rect_m > i.mnmorning {
  width: 55px;
}

.cm_mall_ic.ty_rect_m > i.mnstarbucks {
  width: 55px;
}

.cm_mall_ic.ty_rect_m > i.tv {
  width: 80px;
}

.cm_mall_ic.ty_rect_m > i.si {
  width: 76px;
}

.cm_mall_ic.ty_rect_m > i.outlet {
  width: 89px;
}

.cm_mall_ic.ty_rect_m > i.mncasamia {
  width: 60px;
}

.cm_mall_ic.ty_rect_m > i.hwd {
  width: 47px;
}

.cm_mall_ic.ty_rect_m > i.mndeptfood {
  width: 76px;
}

.cm_mall_ic.ty_rect_m > i.mnchicor {
  width: 55px;
}

.cm_mall_ic.ty_rect_m > i.mntrip {
  width: 37px;
}

.cm_mall_ic.ty_rect_m > i.mndutyfree {
  width: 46px;
}

.cm_mall_ic.ty_rect_m > i.mnssgfood {
  width: 89px;
}

/* 공통 몰아이콘 - 사각형 large */
.cm_mall_ic.ty_rect_l {
  line-height: 25px;
}

.cm_mall_ic.ty_rect_l > i {
  height: 25px;
  margin-right: 5px;
  background-position-x: -500px;
}

.cm_mall_ic.ty_rect_l > i.sm {
  width: 60px;
}

.cm_mall_ic.ty_rect_l > i.sd {
  width: 85px;
}

.cm_mall_ic.ty_rect_l > i.em {
  width: 60px;
}

.cm_mall_ic.ty_rect_l > i.tr {
  width: 73px;
}

.cm_mall_ic.ty_rect_l > i.mnmorning {
  width: 60px;
}

.cm_mall_ic.ty_rect_l > i.mnstarbucks {
  width: 60px;
}

.cm_mall_ic.ty_rect_l > i.tv {
  width: 91px;
}

.cm_mall_ic.ty_rect_l > i.si {
  width: 87px;
}

.cm_mall_ic.ty_rect_l > i.outlet {
  width: 101px;
}

.cm_mall_ic.ty_rect_l > i.mncasamia {
  width: 63px;
}

.cm_mall_ic.ty_rect_l > i.hwd {
  width: 51px;
}

.cm_mall_ic.ty_rect_l > i.mndeptfood {
  width: 85px;
}

.cm_mall_ic.ty_rect_l > i.mnchicor {
  width: 60px;
}

.cm_mall_ic.ty_rect_l > i.mntrip {
  width: 41px;
}

.cm_mall_ic.ty_rect_l > i.mndutyfree {
  width: 48px;
}

.cm_mall_ic.ty_rect_l > i.mnssgfood {
  width: 104px;
}

.cmmyssg_help_layer .cm_mall_ic a {
  font-size: 12px;
}

@media screen and (-webkit-min-device-pixel-ratio: 2) {
  .cm_mall_ic > i {
    background-image: url(../../img/common/sp_cm_mall_ic_20220722@2x.png);
    background-size: 610px auto;
  }
}
/* 딱일 */
.cm_bdg {
  position: absolute;
  top: -15px;
  left: -8px;
  width: 53px;
  z-index: 2;
}

.cm_bdg_r {
  position: absolute;
  top: 0;
  right: 3px;
  width: 50px;
  z-index: 2;
}

.box .cm_bdg_r {
  padding-top: 16px;
}

.cm_bdg_r span {
  display: inline-block;
  width: 50px;
  height: 50px;
  background-image: url(../../img/common/sp_bdg.png);
  background-repeat: no-repeat;
}

.cm_bdg span {
  display: inline-block;
  width: 53px;
  height: 59px;
  margin-bottom: -9px;
  background-image: url(../../img/common/sp_bdg.png);
  background-repeat: no-repeat;
  font-size: 25px;
  color: #fff;
  text-align: center;
  letter-spacing: -1px;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
  line-height: 53px;
  overflow: hidden;
  vertical-align: top;
}

.cm_bdg em.per {
  display: inline-block;
  background: url(../../img/common/sp_bdg.png) 0 -1200px no-repeat;
  width: 9px;
  height: 10px;
  text-indent: -1000em;
  overflow: hidden;
  margin-left: 2px;
}

.cm_bdg span.di_sale {
  background-position: 0 0;
  padding-top: 3px;
  height: 56px;
}

.cm_bdg span.di_coupon {
  background-position: 0 -150px;
  line-height: 50px;
}

.cm_bdg span.di_enuri {
  background-position: 0 -300px;
  line-height: 50px;
}

.cm_bdg span.di_plus {
  background-position: 0 -600px;
  line-height: 58px;
  font-size: 18px;
}

.cm_bdg span.di_plus em {
  color: #f9f051;
  font-size: 20px;
  vertical-align: top;
  margin-left: 1px;
}

.cm_bdg span.di_plus2 {
  background-position: 0 -450px;
}

.cm_bdg span.di_gift {
  background-position: 0 -750px;
}

.cm_bdg span.di_better {
  background-position: 0 -900px;
}

.cm_bdg span.di_sale2 {
  background-position: 0 -1050px;
}

.cm_bdg span.di_holiday,
.cm_bdg_r span.di_holiday {
  background-position: 0 -1350px;
  width: 50px;
  height: 50px;
}

.cm_bdg span.di_onefree {
  background-position: 0 -1500px;
  line-height: 58px;
  font-size: 18px;
}

.cm_bdg span.di_onefree em {
  font-size: 14px;
  margin: 0 3px;
}

.cm_bdg_r span.sprshp {
  width: 54px;
  height: 55px;
  background: url(../../img/common/sp_sprshp.png) 0 -100px no-repeat;
  margin: 8px 0 0 -11px;
}

.cm_bdg_r span.ssgdeliv_sd {
  display: block;
  width: 60px;
  height: 63px;
  background: url(../../img/common/sp_label.png) -150px -2340px no-repeat;
  margin: 0 0 0 -11px;
}

.table_itemlist2 .cm_bdg {
  top: -10px;
}

.cm_bdg.bdg_m {
  width: 74px;
  line-height: 1;
}

.cm_bdg.bdg_m span {
  margin-bottom: -10px;
  width: 74px;
  height: 82px;
  font-size: 34px;
  line-height: 72px;
}

.cm_bdg_r.bdg_m span {
  display: inline-block;
  width: 70px;
  height: 70px;
  background-image: url(../../img/common/sp_bdg.png);
  background-repeat: no-repeat;
}

.cm_bdg.bdg_m em.per {
  display: inline-block;
  background: url(../../img/common/sp_bdg.png) -100px -1200px no-repeat;
  width: 12px;
  height: 14px;
  text-indent: -1000em;
  overflow: hidden;
  margin-left: 2px;
}

.cm_bdg.bdg_m span.di_sale {
  background-position: -100px 0;
  padding-top: 3px;
  height: 79px;
}

.cm_bdg.bdg_m span.di_coupon {
  background-position: -100px -150px;
}

.cm_bdg.bdg_m span.di_enuri {
  background-position: -100px -300px;
}

.cm_bdg.bdg_m span.di_plus {
  background-position: -100px -600px;
  line-height: 80px;
  font-size: 24px;
}

.cm_bdg.bdg_m span.di_plus em {
  color: #f9f051;
  font-size: 26px;
  vertical-align: top;
  margin-left: 1px;
}

.cm_bdg.bdg_m span.di_plus2 {
  background-position: -100px -450px;
}

.cm_bdg.bdg_m span.di_gift {
  background-position: -100px -750px;
}

.cm_bdg.bdg_m span.di_better {
  background-position: -100px -900px;
}

.cm_bdg.bdg_m span.di_sale2 {
  background-position: -100px -1050px;
}

.cm_bdg.bdg_m span.di_holiday,
.cm_bdg_r.bdg_m span.di_holiday {
  background-position: -98px -1350px;
  width: 70px;
  height: 70px;
}

.cm_bdg.bdg_m span.di_onefree {
  background-position: -100px -1500px;
  line-height: 80px;
  font-size: 24px;
}

.cm_bdg.bdg_m span.di_onefree em {
  font-size: 20px;
  margin: 0 5px;
}

.cm_bdg.bdg_m span.sprshp {
  width: 75px;
  height: 76px;
  background: url(../../img/common/sp_sprshp.png) 0 0 no-repeat;
}

.cm_bdg.bdg_b {
  width: 114px;
}

.cm_bdg.bdg_b span {
  margin-bottom: -20px;
  width: 114px;
  height: 126px;
  font-size: 54px;
  line-height: 112px;
  font-weight: bold;
}

.cm_bdg_r.bdg_m span {
  display: inline-block;
  width: 107px;
  height: 107px;
  background-image: url(../../img/common/sp_bdg.png);
  background-repeat: no-repeat;
}

.cm_bdg_r.bdg_m span.sprshp {
  width: 75px;
  height: 76px;
  background: url(../../img/common/sp_sprshp.png) 0 0 no-repeat;
  margin: -2px 0 0 -26px;
}

.cm_bdg_r.bdg_m span.ssgdeliv_sd {
  width: 90px;
  height: 94px;
  background: url(../../img/common/sp_label.png) -300px -2340px no-repeat;
  margin: -2px 0 0 -26px;
}

.cm_bdg.bdg_b em.per {
  display: inline-block;
  background: url(../../img/common/sp_bdg.png) -200px -1200px no-repeat;
  width: 18px;
  height: 26px;
  text-indent: -1000em;
  overflow: hidden;
  margin-left: 2px;
}

.cm_bdg.bdg_b span.di_sale {
  background-position: -200px 0;
  padding-top: 10px;
  height: 116px;
}

.cm_bdg.bdg_b span.di_coupon {
  background-position: -200px -150px;
}

.cm_bdg.bdg_b span.di_enuri {
  background-position: -200px -300px;
}

.cm_bdg.bdg_b span.di_plus {
  background-position: -200px -600px;
  line-height: 132px;
  font-size: 38px;
}

.cm_bdg.bdg_b span.di_plus em {
  color: #f9f051;
  font-size: 44px;
  vertical-align: middle;
  margin-left: 1px;
  margin-top: -11px;
  display: inline-block;
}

.cm_bdg.bdg_b span.di_plus2 {
  background-position: -200px -450px;
}

.cm_bdg.bdg_b span.di_gift {
  background-position: -200px -750px;
}

.cm_bdg.bdg_b span.di_better {
  background-position: -200px -900px;
}

.cm_bdg.bdg_b span.di_sale2 {
  background-position: -200px -1050px;
}

.cm_bdg.bdg_b span.di_holiday,
.cm_bdg_r.bdg_b span.di_holiday {
  background-position: -200px -1350px;
  width: 107px;
  height: 107px;
}

.cm_bdg.bdg_b span.di_onefree {
  background-position: -200px -1500px;
  line-height: 132px;
  font-size: 38px;
}

.cm_bdg.bdg_b span.di_onefree em {
  font-size: 30px;
  margin: 0 5px;
}

.cm_best {
  text-align: center;
  padding: 11px 8px 3px;
  height: 28px;
}

.cm_best span {
  font-size: 20px;
  color: #181818;
  border-bottom: 3px solid #000;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 500;
  line-height: 28px;
  padding: 0 9px 0 7px;
  letter-spacing: -1px;
  background-color: #fff;
}

.cm_delivery {
  position: absolute;
  top: 0;
  right: 0;
}

.cm_delivery span.sprshp {
  display: inline-block;
  width: 75px;
  height: 76px;
  background: url(../../img/common/sp_sprshp.png) 0 0 no-repeat;
}

.cm_delivery span.ssgdeliv_sd {
  display: inline-block;
  width: 90px;
  height: 90px;
  background: url(../../img/common/sp_label.png) -300px -2340px no-repeat;
}

/* 몰 TAB */
.cmall_tabarea {
  position: relative;
  height: 43px;
  margin: 40px 0 20px;
  border-bottom: 2px solid #222;
  font-size: 14px;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
  z-index: 99;
}

.cmall_tab {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.cmall_tab li {
  display: table-cell;
  color: #bbb;
  text-align: center;
  vertical-align: middle;
  border-top: 1px solid #dcdcdc;
  border-left: 1px solid #dcdcdc;
  background: #fafafa;
  height: 42px;
}

.cmall_tab li:last-child {
  border-right: 1px solid #dcdcdc;
}

.cmall_tab li a,
.cmall_tab li span,
.cmall_tab li span.notranslate {
  display: inline-block;
  padding: 0;
  line-height: 15px;
}

.cmall_tab li a {
  color: #222;
  text-decoration: none;
}

.cmall_tab li:first-child {
  border-left: 1px solid #dcdcdc;
}

.cmall_tab li:first-child a {
  margin-left: 0;
}

.cmall_tab li.active {
  background-color: #fff;
  border-right: 2px solid #222;
  border-color: #222;
  border-width: 2px;
  position: relative;
  z-index: 10;
  font-weight: bold;
  height: 41px;
}

.cmall_tab li.active:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  right: 0;
  height: 2px;
  background-color: #fff;
}

.cmall_tab li:first-child.active {
  right: 0;
}

.cmall_tab li:last-child.active {
  right: 0;
}

/* 카테고리 몰 TAB */
.category_section .cmall_tabarea {
  margin: 10px 0 0;
}

/* 상품평, 이벤트 몰 TAB */
.content_myssg .cmall_tabarea,
.content_primary .cmall_tabarea,
.content_csc .cmall_tabarea {
  height: auto;
  margin: 21px 0 0;
  border-bottom-color: #777;
  font-size: 12px;
}

.content_myssg .cmall_tab li,
.content_primary .cmall_tab li,
.content_csc .cmall_tab li {
  color: #747474;
}

.content_myssg .cmall_tab li a,
.content_myssg .cmall_tab li span,
.content_primary .cmall_tab li a,
.content_primary .cmall_tab li span {
  min-width: 86px;
  border-color: #c4c4c4;
  background: transparent;
}

.content_csc .cmall_tab li a,
.content_csc .cmall_tab li span {
  min-width: 77px;
  border-color: #c4c4c4;
  background: #fff;
}

.content_myssg .cmall_tab li.active a,
.content_primary .cmall_tab li.active a,
.content_csc .cmall_tab li.active a {
  border-color: #777;
  letter-spacing: -1px;
}

/* 기획전 배너 - 컨텐츠(unit) */
.cmplan_unit {
  position: relative;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
  word-break: break-all;
}

.cmplan_unit .badge_ad {
  position: absolute;
  top: 5px;
  right: 5px;
}

.cmplan_unit .cmplan_link {
  display: block;
  text-decoration: none;
}

.cmplan_unit .cmplan_imgbx {
  font-size: 0;
}

.cmplan_unit .cmplan_img {
  width: 100%;
  font-size: 12px;
  vertical-align: top;
}

.cmplan_unit .cmplan_img_half {
  width: 50%;
  font-size: 12px;
  vertical-align: top;
}

.cmplan_unit .cmplan_detailbx {
  padding: 15px 20px 50px;
}

.cmplan_unit .cmplan_tagarea {
  padding-bottom: 8px;
}
.cmplan_unit .cmplan_tagarea .cmplan_tag {
  display: inline-block;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  color: var(--m-colors-primary, #ff5452);
}

.cmplan_unit .cmplan_service {
  display: block;
  padding: 4px 0 8px;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  color: var(--m-colors-primary, #ff5452);
}

.cmplan_unit .cmplan_tit {
  display: block;
  font-size: 18px;
  line-height: 25px;
  color: #222;
  letter-spacing: -0.5px;
}

.cmplan_unit .cmplan_tit2 {
  overflow: hidden;
  max-height: 66px;
  font-size: 13px;
  line-height: 22px;
  color: #777;
  letter-spacing: -0.3px;
}

/* 기획전 배너 - 컨텐츠(unit) 몰구분 있는경우 */
.cmplan_unit .cmplan_badge_area {
  margin: -22px 0 0 18px;
}

.cmplan_unit .cmplan_badge_area:after {
  display: block;
  clear: both;
  content: "";
}

.cmplan_unit .cmplan_badge_area .cm_mall_ic > i {
  border: 2px solid #fff;
  border-radius: 50%;
  background-color: #fff;
}

.cmplan_unit .cm_mall_ic {
  float: left;
  position: relative;
  z-index: 10;
  margin-right: -8px;
}

.cmplan_unit .cmplan_badge {
  float: left;
  position: relative;
  z-index: 10;
  width: 44px;
  height: 44px;
  margin-right: -8px;
  background: url(../../img/common/sp_cmplan_201907.png) no-repeat;
}

.cmplan_unit .cmplan_badge.ssg {
  background-position: 0 0;
}

.cmplan_unit .cmplan_badge.sm {
  background-position: 0 -50px;
}

.cmplan_unit .cmplan_badge.sd {
  background-position: 0 -100px;
}

.cmplan_unit .cmplan_badge.em {
  background-position: 0 -150px;
}

.cmplan_unit .cmplan_badge.tr {
  background-position: 0 -200px;
}

.cmplan_unit .cmplan_badge.bt {
  background-position: 0 -250px;
}

.cmplan_unit .cmplan_badge.tv {
  background-position: 0 -300px;
}

.cmplan_unit .cmplan_badge.si {
  background-position: 0 -350px;
}

.cmplan_unit .cmplan_badge.sf {
  background-position: 0 -400px;
}

.cmplan_unit .cmplan_badge.hwd {
  background-position: 0 -450px;
}

.cmplan_unit .cmplan_badge.mgz {
  background-position: 0 -500px;
}

.cmplan_unit .cmplan_badge.outlet {
  background-position: 0 -550px;
}

.cmplan_unit .cmplan_badge.mnmorning {
  background-position: 0 -600px;
}

.cmplan_unit .cmplan_badge.mndeptfood {
  background-position: 0 -650px;
}

.cmplan_unit .cmplan_badge_benefit {
  float: left;
  position: relative;
  height: 40px;
  margin-right: -8px;
  padding: 0 15px;
  border-radius: 22px;
  border: 2px solid #fff;
  background: #fff;
  font-size: 25px;
  font-weight: bold;
  line-height: 40px;
  color: #222;
  letter-spacing: -1px;
}

.cmplan_unit .cmplan_badge_benefit:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 20px;
  border: 1px solid #222;
  content: "";
}

.cmplan_unit .cmplan_badge_subtx {
  display: inline-block;
  margin: 3px 0 0 2px;
  font-weight: bold;
  font-size: 15px;
  letter-spacing: 0;
  vertical-align: top;
}

.cmplan_unit .cmplan_badge_area + .cmplan_detailbx {
  padding-top: 8px;
  padding-bottom: 35px;
}

/* 기획전 배너 - 레이아웃(grid) */
.cmplan_grid {
  width: 1280px;
  margin: 0 auto;
}

.cmplan_grid .cmplan_gridlist {
  font-size: 0;
}

.cmplan_grid .cmplan_griditem {
  display: inline-block;
  font-size: 12px;
  vertical-align: top;
}

.cmplan_grid .cmplan_gridlist_w400 {
  margin-left: -40px;
}

.cmplan_grid .cmplan_gridlist_w400 .cmplan_griditem {
  width: 400px;
  margin-left: 40px;
}

.cmplan_grid .cmplan_gridlist_w300 {
  margin-left: -26px;
}

.cmplan_grid .cmplan_gridlist_w300 .cmplan_griditem {
  width: 300px;
  margin-left: 26px;
}

.cmplan_grid .cmplan_gridlist_w300 .cmplan_imgbx + .cmplan_detailbx {
  padding-top: 20px;
  padding-bottom: 55px;
}

.cmplan_grid .cmplan_gridlist_w300 .cmplan_badge_area + .cmplan_detailbx {
  padding-bottom: 45px;
}

.cmplan_grid .cmplan_gridlist_w370 .cmplan_griditem {
  width: 370px;
}

.cmplan_grid_mix:after {
  display: block;
  clear: both;
  content: "";
}

.cmplan_grid_mix .cmplan_gridlist {
  float: left;
}

.cmplan_grid_mix .cmplan_gridlist_w400 {
  width: 880px;
}

.cmplan_grid_mix .cmplan_gridlist_w370 {
  margin-left: 70px;
}

.cmplan_grid_mix .cmplan_gridlist_w370 .cmplan_griditem {
  display: block;
}

/* 기획전 배너 - 광고상품 */
.cmplan_unit_ad .cmplan_imgbx {
  position: relative;
}

.cmplan_unit_ad .tt_adinfo_n {
  right: 0;
  bottom: 0;
  left: auto;
}

.cmplan_unit_ad .tt_adinfo_n .tt_adinfo_layer {
  top: -42px;
  right: 9px;
  left: auto;
  border-color: #ccc;
  -webkit-box-shadow: 3px 5px 5px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 3px 5px 5px 0 rgba(0, 0, 0, 0.3);
}

.cmplan_unit_ad .tt_adinfo_n .tt_adinfo_layer .bg_adinfo {
  right: 12px;
  left: auto;
  width: 8px;
  height: 6px;
  background: url(../../img/common/bg_adinfo_n2.png) no-repeat;
}

.cmplan_gridlist_w400 .cmplan_unit_ad .tt_adinfo_n {
  right: 0;
  top: 216px;
  bottom: auto;
  left: auto;
}

/* 기획전 배너 - 고해상도 대응 */
@media screen and (-webkit-min-device-pixel-ratio: 2) {
  .cmplan_unit .cmplan_badge {
    background-image: url(../../img/common/sp_cmplan_201907@2x.png);
    background-size: 44px auto;
  }
}
/* 상품없는 경우 */
.cm_empty {
  min-height: 250px;
  padding: 85px 0;
  margin-top: 40px;
  box-sizing: border-box;
}
.cm_empty:before {
  display: block;
  margin: 0 auto 15px;
  width: 22px;
  height: 22px;
  background-image: url(../../img/common/ico_empty.png);
  background-position: top center;
  content: "";
}
.cm_empty .cm_empty_tx {
  margin-top: 7px;
  color: #424242;
  font-size: 15px;
  line-height: normal;
  text-align: center;
}
@media screen and (-webkit-min-device-pixel-ratio: 2) {
  .cm_empty:before {
    background-image: url(../../img/common/ico_empty@2x.png);
    background-size: cover;
  }
}

.cm_empty2 {
  padding: 60px 0;
  margin-top: 14px;
  box-sizing: border-box;
  text-align: center;
}
.cm_empty2::before {
  display: block;
  margin: 0 auto;
  background-image: url("data:image/svg+xml;charset=utf8,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.59961 3.59998H20.3996V20.4H3.59961V3.59998ZM4.79953 19.2003H19.1995V4.80031H4.79953V19.2003Z' fill='rgb%28150%2C150%2C150%29'%3e%3c/path%3e %3cpath d='M7.19961 7.67998H16.7996V8.87998H7.19961V7.67998Z' fill='rgb%28150%2C150%2C150%29'%3e%3c/path%3e %3cpath d='M7.19961 11.28H16.7996V12.48H7.19961V11.28Z' fill='rgb%28150%2C150%2C150%29'%3e%3c/path%3e %3cpath d='M7.19952 14.8803H11.9995V16.0803H7.19952V14.8803Z' fill='rgb%28150%2C150%2C150%29'%3e%3c/path%3e %3c/svg%3e");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-flex;
  width: 32px;
  height: 32px;
  content: "";
}
.cm_empty2 .cm_empty_tx {
  margin-top: 4px;
  color: var(--m-colors-gray900, #222222);
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
}
.cm_empty2 .cm_empty_subtx {
  margin-top: 4px;
  color: var(--m-colors-gray600, #777777);
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}
.cm_empty2 + .cmmain_more2 {
  margin-top: 0;
}

/* 매직픽업툴팁 */
.tt_magicpick {
  position: relative;
  z-index: 10;
  display: inline-block;
  vertical-align: top;
  height: 21px;
}

.tt_magicpick .magicpick_tip_layer {
  display: none;
  visibility: hidden;
  position: absolute;
  word-break: break-all;
  word-wrap: break-word;
  border: 1px solid #bb744b;
  color: #fff;
  background-color: #ce9266;
  background-color: rgba(203, 140, 94, 0.95);
  font-size: 12px;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
  line-height: 21px;
  padding: 14px 20px;
  max-width: 160px;
  white-space: nowrap;
}

.tt_magicpick .magicpick_tip_layer em {
  white-space: nowrap;
}

.tt_magicpick .btn_magicpick {
  width: 57px;
  height: 21px;
  background: url(../../img/common/sp_magicpick.png) 0 0 no-repeat;
}

.tt_magicpick .btn_magicpick.on {
  background-position: 0 -50px;
}

.table_itemlist2 .tt_magicpick {
  display: inline-block;
  vertical-align: top;
  height: 21px;
}

.tl_magicpick {
  display: none;
  position: absolute;
  z-index: 1000;
}

.tl_magicpick:after {
  display: none;
  content: "";
  position: absolute;
  top: -6px;
  left: 24px;
  width: 10px;
  height: 7px;
  background: url(../../img/common/sp_magicpick.png) 0 -100px no-repeat;
  z-index: 1001;
}

.tl_magicpick.on {
  display: block;
}

.tl_magicpick.on:after {
  display: block;
}

.tl_magicpick .tl_magicpick_lst {
  position: absolute;
  word-break: break-all;
  word-wrap: break-word;
  border: 1px solid #bb744b;
  color: #fff;
  background-color: #ce9266;
  background-color: rgba(203, 140, 94, 0.95);
  font-size: 12px;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
  line-height: 21px;
  padding: 14px 20px;
  white-space: nowrap;
}

.tl_magicpick .tl_magicpick_lst em {
  white-space: nowrap;
}

/* 해바/오반장 광고아이콘 툴팁 */
.ssg-tooltip-wrap.tt_adinfo {
  position: relative;
  z-index: 100;
  width: 51px;
  height: 22px;
}

.ssg-tooltip-wrap.tt_adinfo .btn_tt_adinfo {
  display: inline-block;
  vertical-align: top;
  width: 51px;
  height: 22px;
  background: url(../../img/common/ico_adinfo.png) 0 0 no-repeat;
  line-height: 100px;
  overflow: hidden;
}

.ssg-tooltip-wrap.tt_adinfo .ssg-tooltip-layer {
  display: none;
  position: absolute;
  right: 0;
  top: 29px;
  padding: 7px 10px;
  border: 1px solid #d8d8d8;
  background-color: #fff;
  text-align: left;
  line-height: 16px;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #757575;
  white-space: nowrap;
}

.ssg-tooltip-wrap.tt_adinfo.on .ssg-tooltip-layer {
  display: block;
}

.ssg-tooltip-wrap.tt_adinfo .ssg-tooltip-layer .bg_adinfo {
  position: absolute;
  right: 20px;
  top: -8px;
  width: 13px;
  height: 8px;
  background: url(../../img/common/bg_adinfo.png) 0 0 no-repeat;
}

.ssg-tooltip-wrap.tt_adinfo.v2 .ssg-tooltip-layer {
  right: 0;
  top: -59px;
}

.ssg-tooltip-wrap.tt_adinfo.v2 .ssg-tooltip-layer .bg_adinfo {
  top: auto;
  bottom: -8px;
  background-position: 0 -8px;
}

/* 광고아이콘 툴팁 */
.tt_adinfo_n {
  position: absolute;
  left: 5px;
  bottom: 5px;
  z-index: 50;
  width: 60px;
  height: 24px;
}
.tt_adinfo_n.ty_mainbn {
  position: absolute;
  top: 0;
  right: 0;
  left: initial;
  width: 45px;
  height: 20px;
}
.tt_adinfo_n.ty_mainbn .btn_tt_adinfo {
  width: 45px;
  height: 20px;
  background: url(../../img/common/ico_adinfo_n_v2.png) 0 0 no-repeat;
}
.tt_adinfo_n.ty_ctunitbn {
  position: absolute;
  top: initial;
  bottom: 0;
  right: 0;
  left: initial !important;
  width: 45px;
  height: 20px;
}
.tt_adinfo_n.ty_ctunitbn .btn_tt_adinfo {
  width: 45px;
  height: 20px;
  background: url(../../img/common/ico_adinfo_n_v2.png) 0 0 no-repeat;
}

.tt_adinfo_n .btn_tt_adinfo {
  display: inline-block;
  vertical-align: top;
  width: 60px;
  height: 24px;
  background: url(../../img/common/ico_adinfo_n.png) 0 0 no-repeat;
  line-height: 100px;
  overflow: hidden;
}

:lang(en) .tt_adinfo_n .btn_tt_adinfo,
:lang(zh) .tt_adinfo_n .btn_tt_adinfo {
  background-position: 0 -24px;
}

.tt_adinfo_n .tt_adinfo_layer {
  display: none;
  position: absolute;
  left: 0;
  top: -56px;
  z-index: 100;
  padding: 8px 10px 7px;
  background-color: #fff;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #222;
  line-height: 16px;
  text-align: left;
  white-space: nowrap;
  -webkit-box-shadow: 4px 5px 17px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 4px 5px 17px 0 rgba(0, 0, 0, 0.3);
  border: 1px solid #eee;
}

.tt_adinfo_n.on .tt_adinfo_layer {
  display: block;
}

.tt_adinfo_n .tt_adinfo_layer .bg_adinfo {
  position: absolute;
  left: 15px;
  top: 100%;
  width: 12px;
  height: 9px;
  background: url(../../img/common/bg_adinfo_n.png) 0 0 no-repeat;
}

.tt_adinfo_n.tt_disabled {
  z-index: 0;
}

.tt_adinfo_n.tt_disabled.on .tt_adinfo_layer {
  display: none;
}

/* dropdown */
.sort_box {
  width: 100%;
  position: relative;
  float: left;
  margin-top: 15px;
  z-index: 999;
}

.tmpl_drop_wrap.com {
  float: left;
  margin-right: 10px;
}

.tmpl_drop_wrap.com .tmpl_drop_opt {
  position: relative;
  height: 30px;
}

.tmpl_drop_wrap.com .tmpl_drop_opt.on {
  z-index: 100;
}

.tmpl_drop_wrap.com .tmpl_drop_select {
  display: block;
  overflow: hidden;
  position: relative;
  height: 27px;
  padding: 1px 30px 0 10px;
  border: 1px solid #e1e1e1;
  background: #fff;
  font-size: 12px;
  line-height: 27px;
  color: #666;
  cursor: pointer;
  text-decoration: none;
}

.tmpl_drop_wrap.com .tmpl_drop_select .txt {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  zoom: 1;
}

.tmpl_drop_wrap.com .tmpl_drop_select .sel_arrow {
  position: absolute;
  top: 0;
  right: 0;
  width: 29px;
  height: 28px;
  background: url(../../img/display/search/bg_select_arr.gif) 0 0 no-repeat;
}

.tmpl_drop_wrap.com .tmpl_drop_opt.on .tmpl_drop_select .sel_arrow {
  background-position: 0 -30px;
}

.tmpl_drop_wrap.com .tmpl_drop_scroll {
  display: none;
  overflow-y: auto;
  position: absolute;
  right: 0;
  left: 0;
  top: 100%;
  max-height: 130px;
  margin-top: -1px;
  border: 1px solid #e1e1e1;
  background: #fff;
}

.tmpl_drop_wrap.com .tmpl_drop_opt.on .tmpl_drop_scroll {
  display: block;
}

.tmpl_drop_wrap.com .tmpl_drop_list li a {
  display: block;
  padding: 5px 10px;
  line-height: 16px;
  color: #666;
  text-decoration: none;
}

.tmpl_drop_wrap.com .tmpl_drop_list li a em {
  display: block;
  font-weight: bold;
}

.tmpl_drop_wrap.com .tmpl_drop_list li a:hover {
  background: #f7f7f7;
}

.tmpl_drop_wrap.com .tmpl_drop_list li.selected .txt,
.tmpl_drop_wrap.com .tmpl_drop_list li.selected em {
  font-weight: bold;
}

.tmpl_drop_wrap.com .tmpl_drop_list li.disabled .txt,
.tmpl_drop_wrap.com .tmpl_drop_list li.disabled em {
  color: #ccc;
}

/* for you */
.cmforyou_sec {
  width: 1280px;
  margin-right: auto;
  margin-left: auto;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
}

.cmforyou_sec .cmforyou_item {
  height: 444px;
  background: url(../../img/common/loading.gif) 50% 50% no-repeat;
}

.cmforyou_sec .cmforyou_item_login {
  height: 477px;
  margin-top: -10px;
}

.cmforyou_sec .cmforyou_guide {
  position: absolute;
  bottom: 0;
  width: 180px;
}

.cmforyou_sec .cmforyou_guide:before {
  display: inline-block;
  width: 40px;
  height: 40px;
  background: url(../../img/common/sp_foryou_v2.png) no-repeat;
  vertical-align: top;
  content: "";
}

.cmforyou_sec .cmforyou_best_type:before {
  background-position: 0 0;
}

.cmforyou_sec .cmforyou_favorite_type:before {
  background-position: -50px 0;
}

.cmforyou_sec .cmforyou_cart_type:before {
  background-position: -100px 0;
}

.cmforyou_sec .cmforyou_clip_type:before {
  background-position: -150px 0;
}

.cmforyou_sec .cmforyou_question_type:before {
  background-position: 0 -50px;
}

.cmforyou_sec .cmforyou_look_type:before {
  background-position: -50px -50px;
}

.cmforyou_sec .cmforyou_notice_type:before {
  background-position: -100px -50px;
}

.cmforyou_sec .cmforyou_guide_tx {
  height: 40px;
  margin-top: 10px;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #222;
  letter-spacing: -0.3px;
}

.cmforyou_sec .cmforyou_guide_tx a {
  color: #222;
}

.cmforyou_sec .cmforyou_guide_tx .point {
  color: var(--m-colors-primary, #ff5452);
}

.cmforyou_sec .cmforyou_del {
  position: absolute;
  top: 0;
  right: 0;
}

.cmforyou_sec .cmforyou_del_btn {
  width: 30px;
  height: 30px;
  background: url(../../img/common/sp_foryou_v2.png) no-repeat -100px -290px;
}

.cmforyou_sec .cmforyou_last:before {
  display: block;
  width: 180px;
  height: 180px;
  background: url(../../img/common/sp_foryou_v2.png) no-repeat 0 -100px;
  content: "";
}

.cmforyou_sec .cmforyou_last_tx1 {
  display: block;
  margin-top: 10px;
  font-size: 13px;
  line-height: 20px;
  color: #969696;
  letter-spacing: -0.4px;
  text-align: center;
}

.cmforyou_sec .cmforyou_last_tx2 {
  margin-top: 10px;
  font-size: 14px;
  line-height: 22px;
  color: #222;
  letter-spacing: -0.5px;
}

.cmforyou_sec .cmforyou_last_point {
  color: var(--m-colors-primary, #ff5452);
}

.cmforyou_sec .cmforyou_controls {
  width: 80px;
  margin: 30px auto 0;
}

.cmforyou_sec .cmforyou_controls:after {
  display: block;
  clear: both;
  content: "";
}

.cmforyou_sec .cmforyou_controls_prev,
.cmforyou_sec .cmforyou_controls_next {
  float: left;
  width: 40px;
  height: 40px;
  background: url(../../img/common/sp_foryou_v2.png) no-repeat;
}

.cmforyou_sec .cmforyou_controls_prev {
  background-position: 0 -290px;
}

.cmforyou_sec .cmforyou_controls_prev:hover {
  background-position: 0 -390px;
}

.cmforyou_sec .cmforyou_controls_prev.disabled {
  background-position: 0px -340px;
}

.cmforyou_sec .cmforyou_controls_next {
  background-position: -50px -290px;
}

.cmforyou_sec .cmforyou_controls_next:hover {
  background-position: -50px -390px;
}

.cmforyou_sec .cmforyou_controls_next.disabled {
  background-position: -50px -340px;
}

/* 공통상품유닛 분기 */
.cmforyou_sec .cunit_thmb_lst {
  position: relative;
  background: #fff;
}

.cmforyou_sec .cunit_thmb_lst:before {
  position: absolute;
  bottom: 69px;
  left: 0;
  width: 100%;
  height: 1px;
  background: #e5e5e5;
  content: "";
}

.cmforyou_sec .cunit_thmb_lst > li {
  position: relative;
  height: 334px;
  padding-top: 0;
  padding-bottom: 110px;
}

.cmforyou_sec .cmforyou_item_login .cunit_thmb_lst > li {
  padding-top: 33px;
}

/* 고해상도 대응 */
@media screen and (-webkit-min-device-pixel-ratio: 2) {
  .cmforyou_sec .cmforyou_guide:before,
.cmforyou_sec .cmforyou_last:before,
.cmforyou_sec .cmforyou_controls_prev,
.cmforyou_sec .cmforyou_controls_next,
.cmforyou_sec .cmforyou_del_btn {
    background-image: url(../../img/common/sp_foryou_v2@2x.png);
    background-size: 200px auto;
  }
}
/* 공통 몰메인 팝업 */
.cmmain_notipop {
  position: relative;
  z-index: 310;
  width: 1020px;
  margin: 0 auto;
}

.cmmain_notipop .cmmain_notipop_inr {
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  font-size: 0;
  white-space: nowrap;
}

.cmmain_notipop .cmnotipop {
  display: inline-block;
  margin: 10px 0 0 10px;
  font-size: 12px;
  vertical-align: top;
}

.cmmain_notipop .nl_main {
  display: inline-block;
  position: relative;
  right: auto;
  top: auto;
  z-index: auto;
  margin: 10px 0 0 10px;
  font-size: 12px;
  vertical-align: top;
}

.cmnotipop {
  width: 365px;
  height: 396px;
  border: 2px solid #222;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
  white-space: normal;
  word-break: break-all;
  background: #fff;
}

.cmnotipop .cmnotipop_cont {
  position: relative;
  background: #fff;
  overflow-y: auto;
  max-height: 357px;
  height: 357px;
}
.cmnotipop .cmnotipop_cont > a:first-child {
  position: relative;
  z-index: 1;
}

.cmnotipop .cmnotipop_img img {
  width: 100%;
  height: 238px;
  vertical-align: top;
}

.cmnotipop .cmnotipop_detail {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 50px 40px 0;
  color: #222;
}

.cmnotipop .cmnotipop_tit {
  display: block;
  margin-bottom: 23px;
  font-weight: bold;
  font-size: 24px;
  line-height: normal;
}

.cmnotipop .cmnotipop_tx {
  margin-top: 25px;
  font-size: 16px;
  line-height: 1.25;
  color: #666;
  letter-spacing: 0.2px;
}

.cmnotipop .cmnotipop_subtx {
  margin-top: 10px;
  font-size: 16px;
  line-height: 1.25;
  color: #888;
}

.cmnotipop .cmnotipop_subtx_inr {
  display: inline-block;
  text-align: left;
  vertical-align: top;
}

.cmnotipop .cmnotipop_link_inr {
  margin-top: 10px;
  padding-bottom: 20px;
}
.cmnotipop .cmnotipop_link_inr.rgt {
  text-align: right;
}

.cmnotipop .cmnotipop_link {
  display: inline-block;
  font-size: 14px;
  text-decoration: underline;
  color: #000;
}

.cmnotipop .cmnotipop_banner img {
  vertical-align: top;
  width: 100%;
}

.cmnotipop .cmnotipop_foot {
  height: 40px;
  background: #222;
}

.cmnotipop .cmnotipop_foot:after {
  display: block;
  clear: both;
  content: "";
}

.cmnotipop .cmnotipop_l {
  float: left;
  margin: 11px 0 0 12px;
}

.cmnotipop .cmnotipop_r {
  float: right;
  margin-right: 5px;
}

.cmnotipop .cmnotipop_chk {
  width: 18px;
  height: 18px;
  margin-right: 10px;
  vertical-align: top;
}

.cmnotipop .cmnotipop_lbl {
  font-size: 13px;
  line-height: 19px;
  color: #d1d1d1;
  vertical-align: top;
}

.cmnotipop .cmnotipop_close {
  padding: 10px;
  background: transparent;
  line-height: normal;
}

.cmnotipop .cmnotipop_close:before {
  display: block;
  width: 20px;
  height: 20px;
  background: url(../../img/common/notice/ico_cmnotipop_close.png) 0 0;
  content: "";
}

.cmnotipop.v2 {
  height: 440px;
  width: 350px;
  border: 2px solid #666;
}
.cmnotipop.v2 .cmnotipop_cont {
  height: 400px;
  max-height: 400px;
}
.cmnotipop.v2 .cmnotipop_img img {
  height: auto;
}
.cmnotipop.v2 .cmnotipop_foot {
  padding-top: 2px;
  margin-left: -2px;
  height: 40px;
  width: 354px;
  line-height: 18px;
  background: #33353e;
  zoom: 1;
}
.cmnotipop.v2 .cmnotipop_foot:after {
  display: block;
  clear: both;
  content: "";
}
.cmnotipop.v2 .cmnotipop_l {
  float: left;
  margin: 0 0 0 17px;
  padding-top: 14px;
}
.cmnotipop.v2 .cmnotipop_l .cmnotipop_chk {
  width: 13px;
  height: 13px;
  margin-right: 4px;
  vertical-align: top;
}
.cmnotipop.v2 .cmnotipop_l .cmnotipop_lbl {
  display: inline-block;
  margin: 1px 0 -1px;
  vertical-align: top;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
  font-size: 11px;
  line-height: 12px;
  letter-spacing: -1px;
  color: #a39b97;
}
.cmnotipop.v2 .cmnotipop_r {
  float: right;
  margin-right: 6px;
  font-size: 0;
  line-height: 0;
}
.cmnotipop.v2 .cmnotipop_close {
  display: inline-block;
  padding: 14px 14px 13px;
  background: transparent;
  line-height: 13px;
  vertical-align: top;
}
.cmnotipop.v2 .cmnotipop_close:before {
  display: inline-block;
  overflow: hidden;
  width: auto;
  height: 10px;
  background: url(../../img/common/notice/sp_notice.png) no-repeat;
  line-height: 999px;
  vertical-align: top;
  width: 13px;
  height: 13px;
  background-position: 0 0;
}

/* 공통 몰메인 팝업 - 몰별 분기 */
.body_wide_ctn .cmmain_notipop {
  width: 1280px;
}

.body_ssg .cmmain_notipop {
  top: 20px;
  z-index: 210;
  width: 1240px;
}

.body_sm .top_banner + .cmmain_notipop {
  top: -100px;
}

.body_em .top_bn_tti + .cmmain_notipop {
  top: -100px;
}

.body_boots .cmmain_notipop {
  z-index: 190;
}

.body_siv .cmmain_notipop {
  z-index: 190;
}

.body_sf .cmmain_notipop {
  z-index: 300;
}

.body_howdy .cmmain_notipop {
  top: 145px;
  z-index: 3100;
  width: 1280px;
}

/* 고해상도 대응 */
@media screen and (-webkit-min-device-pixel-ratio: 2) {
  .cmnotipop .cmnotipop_close:before {
    background-position: -15px 0;
    background-size: 35px auto;
  }
}
/* 온라인 포인트카드 발급 팝업 */
.cmmain_notipop .ptcd_main {
  display: inline-block;
  position: relative;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
  margin: 10px 0 0 10px;
  font-size: 12px;
  vertical-align: top;
  width: 650px;
  height: 569px;
  border: 1px solid #eee;
  background: #fff;
}

.cmmain_notipop .ptcd_main .pop_header {
  position: relative;
  text-align: center;
  height: 54px;
}

.cmmain_notipop .ptcd_main .pop_header h1 {
  margin: 0;
  padding: 14px 0 13px;
  font-weight: bold;
  font-size: 22px;
  height: 26px;
  line-height: 28px;
  color: #222;
  background-color: #fff;
  border-bottom: 2px solid #1e1e1e;
  letter-spacing: -1px;
}

.cmmain_notipop .ptcd_main .pop_content .ptcd_intro {
  height: 211px;
  background: url(../../img/common/img_ptcd_barcode.png) 8px 13px no-repeat;
}

.cmmain_notipop .ptcd_main .pop_content .ptcd_intro .ptcd_intro_txt {
  padding: 93px 0 0 315px;
}

.cmmain_notipop .ptcd_main .pop_content .ptcd_intro .ptcd_intro_txt h2 {
  font-size: 16px;
  letter-spacing: -0.4px;
  font-weight: bold;
  line-height: 23px;
}

.cmmain_notipop .ptcd_main .pop_content .ptcd_intro .ptcd_intro_txt p {
  font-size: 13px;
  color: #9b9b9b;
  letter-spacing: -0.4px;
  line-height: 19px;
  margin-top: 7px;
}

.cmmain_notipop .ptcd_main .pop_content .ptcd_agr_lst {
  border-top: 1px solid #222;
  border-bottom: 1px solid #222;
}

.cmmain_notipop .ptcd_main .pop_content .ptcd_agr_lst li {
  position: relative;
  border-bottom: 1px solid #eee;
  height: 42px;
  line-height: 42px;
  padding-left: 11px;
}

.cmmain_notipop .ptcd_main .pop_content .ptcd_agr_lst li:last-child {
  border-bottom: none;
}

.cmmain_notipop .ptcd_main .custom_chk {
  position: relative;
  min-width: 18px;
  display: inline-block;
  min-height: 18px;
  word-wrap: normal;
  word-break: normal;
  vertical-align: top;
  line-height: 42px;
}

.cmmain_notipop .ptcd_main .custom_chk input[type=checkbox] {
  position: absolute;
  top: 11px;
  width: 18px;
  height: 18px;
  outline: 0 none;
  opacity: 0;
  filter: alpha(opacity=0);
}

.cmmain_notipop .ptcd_main .custom_chk:after {
  position: absolute;
  top: 11px;
  left: 0;
  width: 18px;
  height: 18px;
  background: url(../../img/common/omni_sp_chk.png) 0 0 no-repeat;
  content: "";
}

.cmmain_notipop .ptcd_main .custom_chk label {
  display: inline-block;
  position: relative;
  z-index: 10;
  padding-left: 22px;
  font-size: 14px;
  color: #222;
  cursor: pointer;
}

.cmmain_notipop .ptcd_main .custom_chk input:focus + label {
  outline: 1px dotted #808080;
  outline: auto -webkit-focus-ring-color;
}

.cmmain_notipop .ptcd_main .custom_chk.on:after {
  background-position: 0 -30px;
}

.cmmain_notipop .ptcd_main .pop_content .ptcd_agr_lst li .agr_btn_dtl {
  position: absolute;
  top: 1px;
  right: 0;
  font-size: 12px;
  color: #888;
  line-height: 42px;
  letter-spacing: -1px;
}

.cmmain_notipop .ptcd_main .pop_content .ptcd_agr_lst li .agr_btn_dtl:hover {
  text-decoration: underline;
}

.cmmain_notipop .ptcd_main .pop_content .ptcd_agr_lst li .agr_btn_dtl .ico_arr {
  display: inline-block;
  width: 6px;
  height: 10px;
  margin: -1px 0 0 3px;
  background: url(../../img/common/omni_ico_btn.png) no-repeat 0 0;
  vertical-align: middle;
}

.cmmain_notipop .ptcd_main .btn_cnf {
  width: 100%;
  height: 50px;
  background: var(--m-colors-primary, #ff5452);
  color: #fff;
  text-align: center;
  line-height: 50px;
  font-weight: bold;
  font-size: 15px;
  margin-top: 30px;
}

.cmmain_notipop .ptcd_main .pop_footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 34px;
  background: #eee;
  text-align: center;
  z-index: 99;
}

.cmmain_notipop .ptcd_main .pop_footer .custom_chk {
  line-height: 35px;
}

.cmmain_notipop .ptcd_main .pop_footer .custom_chk input[type=checkbox] {
  top: 8px;
}

.cmmain_notipop .ptcd_main .pop_footer .custom_chk:after {
  top: 8px;
}

.cmmain_notipop .ptcd_main .pop_footer .custom_chk label {
  font-size: 13px;
  color: #777;
  padding-left: 26px;
}

.cmmain_notipop .ptcd_main .button_close {
  top: -54px !important;
  width: 53px !important;
  height: 53px !important;
}

.cmmain_notipop .ptcd_main .button_close .ir {
  width: 53px !important;
  height: 53px !important;
}

/* 배송지 등록 안내 팝업 */
.cmmain_notipop .regi_ad .cmnotipop_cont {
  padding: 25px;
  box-sizing: border-box;
}
.cmmain_notipop .regi_ad .cmnotipop_img {
  padding: 32px 0 31px;
  text-align: center;
}
.cmmain_notipop .regi_ad .cmnotipop_img img {
  width: auto;
}
.cmmain_notipop .regi_ad .cmnotipop_tit {
  margin-bottom: 0;
  font-size: 28px;
  letter-spacing: -0.035em;
  text-align: center;
  color: #222;
}
.cmmain_notipop .regi_ad .cmnotipop_tx {
  margin-top: 3px;
  text-align: center;
}
.cmmain_notipop .regi_ad .cmnotipop_subtit {
  font-size: 13px;
  letter-spacing: -0.02em;
}
.cmmain_notipop .regi_ad .tx_emart {
  color: #ffa516;
}
.cmmain_notipop .regi_ad .tx_earlymorning {
  color: #8097af;
}
.cmmain_notipop .regi_ad .tx_traders {
  color: #99cc00;
}
.cmmain_notipop .regi_ad .cmnotipop_dsc {
  margin: 20px 0 35px;
  font-size: 16px;
  color: #222;
  line-height: 1.38;
  letter-spacing: -0.05em;
}
.cmmain_notipop .regi_ad .btn_cnf {
  display: block;
  width: 100%;
  height: 52px;
  background: var(--m-colors-primary, #ff5452);
  font-size: 16px;
  color: #fff;
  text-align: center;
  line-height: 52px;
  letter-spacing: -0.3px;
}
.cmmain_notipop .regi_ad .cmnotipop_foot {
  padding-top: 0;
}

/* 배송지 등록 안내 팝업 */
.cmmain_notipop .ty_affiliate .cmnotipop_cont {
  padding: 40px 25px 38px;
  box-sizing: border-box;
}
.cmmain_notipop .ty_affiliate .cmnotipop_img {
  width: 90px;
  height: 70px;
  margin: 0 auto 32px;
}
.cmmain_notipop .ty_affiliate .cmnotipop_img img {
  width: 100%;
}
.cmmain_notipop .ty_affiliate .cmnotipop_tit {
  margin-bottom: 0;
  font-size: 22px;
  text-align: center;
  color: #222;
}
.cmmain_notipop .ty_affiliate .cmnotipop_tx {
  margin: 9px 0 36px;
  font-size: 14px;
  text-align: center;
}
.cmmain_notipop .ty_affiliate .btn_cnf {
  display: block;
  width: 100%;
  height: 52px;
  background: #ff3e33;
  font-size: 16px;
  color: #fff;
  text-align: center;
  line-height: 52px;
  letter-spacing: -0.3px;
}
.cmmain_notipop .ty_affiliate .cmnotipop_foot {
  padding-top: 0;
}

/* 명절 대량 주문 안내 팝업 */
.cmmain_notipop .ty_holiday {
  width: 350px;
  height: 250px;
  box-sizing: border-box;
}
.cmmain_notipop .ty_holiday .cmnotipop_cont {
  height: auto;
  max-height: 100%;
  padding: 40px 25px 38px;
  box-sizing: border-box;
}
.cmmain_notipop .ty_holiday .cmnotipop_tit {
  margin-bottom: 0;
  font-size: 22px;
  text-align: center;
  color: #222;
  letter-spacing: 0.5px;
}
.cmmain_notipop .ty_holiday .cmnotipop_tx {
  margin: 11px 0 0;
  font-size: 14px;
  text-align: center;
  color: #666;
  letter-spacing: -0.5px;
}
.cmmain_notipop .ty_holiday .cmnotipop_dsc:not(:first-child) {
  margin-top: 12px;
}
.cmmain_notipop .ty_holiday .cmnotipop_dsc.tx_point {
  color: var(--m-colors-primary, #ff5452);
}
.cmmain_notipop .ty_holiday .cmnotipop_foot {
  background-color: #000;
}

/* 비밀번호 변경 캠페인 팝업 */
.cm_lypop {
  display: none;
  position: relative;
  background-color: #fff;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
  line-height: normal;
  box-sizing: border-box;
}
.cm_lypop_btnclose {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 20px;
  height: 20px;
}
.cm_lypop_btnclose:before, .cm_lypop_btnclose:after {
  content: "";
  position: absolute;
  top: 0;
  left: 10px;
  height: 21px;
  width: 1px;
  background-color: #222;
}
.cm_lypop_btnclose:before {
  transform: rotate(45deg);
}
.cm_lypop_btnclose:after {
  transform: rotate(-45deg);
}

.cm_lypop_pwcampaign {
  border: solid 2px #222;
}
.cm_lypop_pwcampaign .cm_lypop_content {
  padding: 52px 85px 70px;
}
.cm_lypop_pwcampaign .password_campaign_intro {
  text-align: center;
}
.cm_lypop_pwcampaign .password_campaign_intro h2 {
  font-size: 30px;
  font-weight: normal;
}
.cm_lypop_pwcampaign .password_campaign_intro p {
  margin-top: 15px;
  font-size: 15px;
  line-height: 1.33;
}
.cm_lypop_pwcampaign .password_campaign_intro em {
  color: var(--m-colors-primary, #ff5452);
}
.cm_lypop_pwcampaign .password_campaign_tip {
  margin-top: 40px;
  padding: 30px;
  border-radius: 13px;
  border: solid 1px #eee;
  text-align: center;
}
.cm_lypop_pwcampaign .password_campaign_tip strong {
  font-size: 20px;
  font-weight: normal;
  letter-spacing: -0.46px;
}
.cm_lypop_pwcampaign .password_campaign_list {
  margin-top: 30px;
}
.cm_lypop_pwcampaign .password_campaign_list li {
  display: inline-block;
  margin: 15px 35px;
  font-size: 15px;
  line-height: 1.33;
  letter-spacing: -0.3px;
  color: #777;
  text-align: center;
}
.cm_lypop_pwcampaign .password_campaign_list li i {
  display: block;
  margin-bottom: 20px;
}
.cm_lypop_pwcampaign .password_campaign_list li i:before {
  content: "";
  display: inline-block;
  width: 100px;
  height: 100px;
  background-repeat: no-repeat;
  background-size: 100%;
}
.cm_lypop_pwcampaign .password_campaign_list li i.ico_othernumber:before {
  background-image: url("../../img/common/ico_othernumber.svg");
}
.cm_lypop_pwcampaign .password_campaign_list li i.ico_combination:before {
  background-image: url("../../img/common/ico_combination.svg");
}
.cm_lypop_pwcampaign .password_campaign_list li i.ico_memo:before {
  background-image: url("../../img/common/ico_memo.svg");
}
.cm_lypop_pwcampaign .password_campaign_btnarea {
  margin: 50px auto 0;
  text-align: center;
}
.cm_lypop_pwcampaign .btn_change_after,
.cm_lypop_pwcampaign .btn_change_password {
  display: inline-block;
  width: 263px;
  height: 52px;
  line-height: 50px;
  border: 1px solid #222;
  font-size: 16px;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
  color: #222;
  vertical-align: top;
  letter-spacing: -0.3px;
  box-sizing: border-box;
}
.cm_lypop_pwcampaign .btn_change_after:not(:first-child),
.cm_lypop_pwcampaign .btn_change_password:not(:first-child) {
  margin-left: 15px;
}
.cm_lypop_pwcampaign .btn_change_password {
  background: #222;
  color: #fff;
}

/* 넷퍼넬 팝업 */
.cm_lypop_netfunnel {
  width: 360px;
}
.cm_lypop_netfunnel .cm_lypop_content {
  padding: 50px 30px;
  text-align: center;
}
.cm_lypop_netfunnel .netfunnel_tit {
  display: block;
  font-size: 24px;
  color: #222;
  line-height: 32px;
  letter-spacing: -0.3px;
  font-weight: bold;
}
.cm_lypop_netfunnel .netfunnel_tx {
  margin-top: 15px;
}
.cm_lypop_netfunnel .netfunnel_dsc {
  font-size: 13px;
  letter-spacing: -0.3px;
}
.cm_lypop_netfunnel .netfunnel_img {
  width: 100%;
  margin: 8px auto 0;
  line-height: 1em;
}
.cm_lypop_netfunnel .netfunnel_img img {
  width: 100%;
  vertical-align: top;
}
.cm_lypop_netfunnel .netfunnel_subtx {
  margin: 30px 0 15px;
}
.cm_lypop_netfunnel .netfunnel_subdsc {
  font-size: 13px;
  color: var(--m-colors-primary, #ff5452);
  letter-spacing: -0.3px;
}
.cm_lypop_netfunnel .netfunnel_subdsc_time {
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.3px;
  color: var(--m-colors-primary, #ff5452);
  font-weight: bold;
}

/* 로그인 보안 설정 팝업 */
.cmmain_notipop .logsecurity .cmnotipop_cont {
  padding: 25px;
  box-sizing: border-box;
  text-align: center;
}
.cmmain_notipop .logsecurity .cmnotipop_img {
  padding: 38px 0 34px;
  text-align: center;
}
.cmmain_notipop .logsecurity .cmnotipop_img img {
  width: auto;
}
.cmmain_notipop .logsecurity .cmnotipop_tit {
  display: block;
  margin-bottom: 10px;
  font-size: 25px;
  line-height: 22px;
  color: #222;
  letter-spacing: -0.5px;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
}
.cmmain_notipop .logsecurity .cmnotipop_tx {
  margin-top: 3px;
}
.cmmain_notipop .logsecurity .cmnotipop_dsc {
  font-size: 16px;
  color: #222;
  letter-spacing: -0.25px;
  line-height: 22px;
}
.cmmain_notipop .logsecurity .cmnotipop_lst {
  display: inline-block;
  margin: 10px auto 38px;
}
.cmmain_notipop .logsecurity .cmnotipop_lst li {
  position: relative;
  padding-left: 18px;
  margin-top: 10px;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.2px;
  text-align: left;
}
.cmmain_notipop .logsecurity .cmnotipop_lst li:before {
  position: absolute;
  display: block;
  left: 1px;
  top: 2px;
  width: 9px;
  height: 5px;
  border-left: 2px solid #000;
  border-bottom: 2px solid #000;
  transform: rotate(-50deg);
  content: "";
}
.cmmain_notipop .logsecurity .btn_cnf {
  display: block;
  width: 100%;
  height: 52px;
  background: var(--m-colors-primary, #ff5452);
  font-size: 16px;
  color: #fff;
  text-align: center;
  line-height: 52px;
  letter-spacing: -0.3px;
}
.cmmain_notipop .logsecurity .cmnotipop_foot {
  padding-top: 0;
}

/* 고해상도 대응 */
@media screen and (-webkit-min-device-pixel-ratio: 2) {
  .cmmain_notipop .ptcd_main .pop_content .ptcd_intro {
    background: url(../../img/common/img_ptcd_barcode@2x.png) 8px 13px no-repeat;
    background-size: 282px auto;
  }
}
/* 해외 ip접근시 다국어 변환 안내 */
.ssgmain_oversea_pop {
  display: none;
  position: absolute;
  width: 650px;
  height: 410px;
  border: 1px solid #222;
  background: #fff;
  font-size: 0;
  line-height: normal;
  text-align: center;
}

.ssgmain_oversea_pop:after {
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
  content: "";
}

.ssgmain_oversea_pop .ssgmain_ospop_en {
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
}

.ssgmain_oversea_pop .ssgmain_ospop_kr {
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
}

.ssgmain_oversea_pop .ssgmain_ospop_cont {
  display: inline-block;
  font-size: 12px;
  vertical-align: middle;
}

.ssgmain_oversea_pop .ssgmain_ospop_msg {
  font-size: 25px;
  line-height: 30px;
  color: #222;
  letter-spacing: -0.5px;
}

.ssgmain_oversea_pop .ssgmain_ospop_btnarea {
  margin-top: 50px;
}

.ssgmain_oversea_pop .ssgmain_ospop_btn {
  display: block;
  width: 240px;
  height: 50px;
  margin: 10px auto 0;
  padding-bottom: 2px;
  background: #222;
  color: #fff;
  box-sizing: border-box;
}

.ssgmain_oversea_pop button.ssgmain_ospop_btn {
  border: 1px solid #222;
  background: #fff;
  color: #222;
}

.ssgmain_oversea_pop .ssgmain_ospop_btn:after {
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
  content: "";
}

.ssgmain_oversea_pop .ssgmain_ospop_btn .ssgmain_ospop_en {
  font-size: 15px;
  vertical-align: middle;
}

.ssgmain_oversea_pop .ssgmain_ospop_btn .ssgmain_ospop_cn {
  font-size: 13px;
  vertical-align: middle;
}

.ssgmain_oversea_pop .ssgmain_ospop_btn .ssgmain_ospop_kr {
  font-size: 14px;
  vertical-align: -2px;
}

.ssgmain_oversea_pop .ssgmain_ospop_close {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 29px;
  height: 29px;
  background: url(../../img/common/btn_cmpop_close.png) no-repeat;
  font-size: 12px;
}

/* 고해상도 대응 */
@media screen and (-webkit-min-device-pixel-ratio: 2) {
  .ssgmain_oversea_pop .ssgmain_ospop_close {
    background-image: url(../../img/common/btn_cmpop_close@2x.png);
    background-size: 29px auto;
  }
}
/* 신규몰 홍보 구좌 */
.cmgrand_open_sec .cmgrand_open_imgbx {
  position: relative;
  height: 392px;
}

.cmgrand_open_sec .cmgrand_open_img {
  width: 100%;
  vertical-align: top;
}

.cmgrand_open_sec .cmgrand_open_listbx {
  margin: -26px 0 14px;
  text-align: center;
}

.cmgrand_open_sec .cmgrand_open_list {
  position: relative;
  display: inline-block;
  padding: 0 14px;
  border: 2px solid #fff;
  border-radius: 27px;
  background: #fff;
  box-sizing: border-box;
  font-size: 0;
}

.cmgrand_open_sec .cmgrand_open_list li {
  display: inline-block;
  position: relative;
  vertical-align: top;
  text-align: center;
}

.cmgrand_open_sec .cmgrand_open_list li a {
  overflow: hidden;
  position: relative;
  z-index: 1;
  display: block;
  width: 90px;
  height: 52px;
  padding: 0 14px;
  font-size: 16px;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
  color: #222;
  word-break: break-word;
  word-wrap: break-word;
  letter-spacing: -0.3px;
  line-height: 52px;
}

.cmgrand_open_sec .cmgrand_open_list li:hover a {
  color: #fff;
  text-decoration: none;
}

.cmgrand_open_sec .cmgrand_open_list li:hover:after {
  position: absolute;
  top: 0;
  left: -14px;
  bottom: 0;
  right: -14px;
  border-radius: 26px;
  box-sizing: border-box;
  border: 1px solid #222;
  background: #222;
  content: "";
}

/* 몰별 키컬러값 */
.cmgrand_open_sec.sm .cmgrand_open_list li:hover:after {
  border: 1px solid #f12e24;
  background: #f12e24;
}

.cmgrand_open_sec.sd .cmgrand_open_list li:hover:after {
  border: 1px solid #535455;
  background: #535455;
}

.cmgrand_open_sec.em .cmgrand_open_list li:hover:after {
  border: 1px solid #ffd040;
  background: #ffd040;
}

.cmgrand_open_sec.traders .cmgrand_open_list li:hover:after {
  border: 1px solid #a6dd27;
  background: #a6dd27;
}

.cmgrand_open_sec.boots .cmgrand_open_list li:hover:after {
  border: 1px solid #1b448e;
  background: #1b448e;
}

.cmgrand_open_sec.tv .cmgrand_open_list li:hover:after {
  border: 1px solid #e2231a;
  background: #e2231a;
}

.cmgrand_open_sec.siv .cmgrand_open_list li:hover:after {
  border: 1px solid #222;
  background: #222;
}

.cmgrand_open_sec.outlet .cmgrand_open_list li:hover:after {
  border: 1px solid #d9117c;
  background: #d9117c;
}

.cmgrand_open_sec.sf .cmgrand_open_list li:hover:after {
  border: 1px solid #b12536;
  background: #b12536;
}

.cmgrand_open_sec.howdy .cmgrand_open_list li:hover:after {
  border: 1px solid #000;
  background: #000;
}

.cmgrand_open_sec.mnmorning .cmgrand_open_list li:hover:after {
  border: 1px solid #a3b7cd;
  background: #a3b7cd;
}

.cmgrand_open_sec {
  /* 고해상도 대응 */
}
.cmgrand_open_sec.v2 .cmgrand_open_imgbx, .cmgrand_open_sec.v3 .cmgrand_open_imgbx {
  height: 180px;
}
.cmgrand_open_sec.v2 .cmgrand_open_imgbx .cmgrand_open_imglink, .cmgrand_open_sec.v3 .cmgrand_open_imgbx .cmgrand_open_imglink {
  overflow: hidden;
  display: block;
  width: inherit;
  height: inherit;
}
.cmgrand_open_sec.v2 .cunit_thmb_lst, .cmgrand_open_sec.v3 .cunit_thmb_lst {
  height: 608px;
}
.cmgrand_open_sec.v3 .cmgrand_open_linkbx {
  bottom: 31px;
  margin-bottom: -31px;
}
.cmgrand_open_sec.v3 .cmgrand_open_linkbx .cmgrand_open_link {
  padding: 0 10px;
  bottom: 28px;
  border: 0;
}
.cmgrand_open_sec.v3 .cmgrand_open_linkbx .cmgrand_open_link li {
  margin: 0 4px;
  padding: 7px 11px 7px 15px;
  background-color: #fff;
  border-radius: 15.5px;
  border: solid 1px rgba(34, 34, 34, 0.1);
}
.cmgrand_open_sec.v3 .cmgrand_open_linkbx .cmgrand_open_link li.active:after {
  bottom: -29px;
}
.cmgrand_open_sec.v3 .cmgrand_open_linkbx .cmgrand_open_link li a:before {
  content: none;
}
.cmgrand_open_sec.v3 .cmgrand_open_linkbx .cmgrand_open_link li a:after {
  display: inline-block;
  vertical-align: middle;
  width: 4px;
  height: 4px;
  border-top: 1px solid #222;
  border-right: 1px solid #222;
  margin: 2px;
  transform: rotate(45deg);
  content: "";
}
.cmgrand_open_sec .ico_grand_open {
  position: absolute;
  left: -18px;
  top: 8px;
  width: 135px;
  height: 46px;
}
.cmgrand_open_sec .cmgrand_open_btn {
  margin: 0 auto;
  text-align: center;
  font-size: 0;
}
.cmgrand_open_sec .cmgrand_open_btn .cmgrand_open_btnprev,
.cmgrand_open_sec .cmgrand_open_btn .cmgrand_open_btnnext {
  width: 40px;
  height: 40px;
  background: url(../../img/common/sp_main_slide.png) no-repeat;
}
.cmgrand_open_sec .cmgrand_open_btn .cmgrand_open_btnprev {
  background-position: 0 0;
}
.cmgrand_open_sec .cmgrand_open_btn .cmgrand_open_btnprev:hover {
  background-position: 0 -100px;
}
.cmgrand_open_sec .cmgrand_open_btn .cmgrand_open_btnprev.disabled {
  background-position: 0 -50px;
}
.cmgrand_open_sec .cmgrand_open_btn .cmgrand_open_btnnext {
  background-position: -50px 0;
}
.cmgrand_open_sec .cmgrand_open_btn .cmgrand_open_btnnext:hover {
  background-position: -50px -100px;
}
.cmgrand_open_sec .cmgrand_open_btn .cmgrand_open_btnnext.disabled {
  background-position: -50px -50px;
}
.cmgrand_open_sec .cmgrand_open_quick {
  position: absolute;
  right: 17px;
  bottom: 15px;
  width: 113px;
  height: 32px;
  border: 1px solid #fff;
  background: #222;
  text-align: center;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
  z-index: 10;
}
.cmgrand_open_sec .cmgrand_open_quick span {
  line-height: 33px;
  font-size: 12px;
  color: #fff;
}
.cmgrand_open_sec .cmgrand_open_quick span:after {
  display: inline-block;
  margin-left: 5px;
  background: url(../../img/main/ico_arrow.png) no-repeat;
  background-size: 17px;
  vertical-align: middle;
  width: 17px;
  height: 9px;
  content: "";
}
.cmgrand_open_sec .cmgrand_open_linkbx {
  position: relative;
  text-align: center;
  bottom: 30px;
  margin-bottom: -30px;
  line-height: 1.5em;
  font-size: 0.75em;
}
.cmgrand_open_sec .cmgrand_open_linkbx .cmgrand_open_link {
  display: inline-block;
  position: relative;
  padding: 0 10px;
  background: none;
  border-top: 1px solid rgba(34, 34, 34, 0.1);
  border-bottom: 1px solid rgba(34, 34, 34, 0.1);
  bottom: 30px;
  box-sizing: border-box;
  font-size: 0;
}
.cmgrand_open_sec .cmgrand_open_linkbx .cmgrand_open_link li {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  text-align: center;
  padding: 4px 10px;
}
.cmgrand_open_sec .cmgrand_open_linkbx .cmgrand_open_link li.active {
  font-weight: bold;
}
.cmgrand_open_sec .cmgrand_open_linkbx .cmgrand_open_link li.active:after {
  position: absolute;
  left: 50%;
  bottom: -29px;
  border-bottom: 9px solid currentColor;
  border-left: 8px solid transparent !important;
  border-right: 8px solid transparent !important;
  transform: translateX(-50%);
  content: "";
}
.cmgrand_open_sec .cmgrand_open_linkbx .cmgrand_open_link li a {
  display: block;
  overflow: hidden;
  position: relative;
  color: #222;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: -0.26px;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
  word-break: break-word;
  word-wrap: break-word;
  z-index: 1;
}
.cmgrand_open_sec .cmgrand_open_linkbx .cmgrand_open_link li a:before {
  content: "#";
}
.cmgrand_open_sec .cmgrand_open_linkbx .cmgrand_open_link li a:hover {
  text-decoration: none;
}
.cmgrand_open_sec .cmgrand_open_tipbx {
  position: relative;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
  background-color: currentColor;
}
.cmgrand_open_sec .cmgrand_open_tipbx .cmgrand_open_tip {
  display: block;
  padding: 12px 0;
  font-size: 14px;
  text-align: center;
  color: #fff;
}
@media screen and (-webkit-min-device-pixel-ratio: 2) {
  .cmgrand_open_sec .cmgrand_open_quick span:after {
    background-image: url(../../img/main/ico_arrow@2x.png);
  }
}

/* 디자인공통 더보기 버튼 */
.cmtheme_loading {
  height: 60px;
  margin-top: 50px;
  background: url(../../img/common/loading.gif) no-repeat 50% 50%;
}

.cmtheme_more {
  margin-top: 50px;
  text-align: center;
}

.cmtheme_more .cmtheme_btnmore {
  width: 500px;
  height: 50px;
  border: 1px solid #222;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
  font-size: 15px;
  padding-top: 1px;
  line-height: 49px;
  color: #222;
  letter-spacing: -0.3px;
  box-sizing: border-box;
}

.cmtheme_more .cmtheme_btnmore:after {
  display: inline-block;
  width: 10px;
  height: 6px;
  margin: 21px -17px 0 7px;
  background: url(../../img/common/ico_arr.png) no-repeat;
  vertical-align: top;
  content: "";
}

/* 고해상도 대응 */
@media screen and (-webkit-min-device-pixel-ratio: 2) {
  .cmtheme_more .cmtheme_btnmore:after {
    background-size: 10px auto;
    background-position: 0 -10px;
  }
}
/* 로딩 overlay */
.cmloading_overlay {
  display: none;
  z-index: 3011;
  position: absolute;
  background: url(../../img/common/bg_opct60.png);
  background: rgba(255, 255, 255, 0.6);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.cmloading_overlay .cmloading_overlay_ico {
  height: 100%;
  background: url(../../img/common/loading.gif) no-repeat center 400px;
}