/* 검색박스 */
.common_header .main_title {
  position: absolute;
  top: 22px;
  left: 0;
  z-index: 30;
}
.common_header .searching_box {
  position: absolute;
  top: 0;
  left: 456px;
  width: 350px;
  font-size: 0;
  background: #fff;
}
.common_header.rank .main_title .searching_box {
  left: 392px;
  width: 340px;
  top: 4px;
}
.common_header .searching_box div {
  float: left;
  width: 249px;
  height: 36px;
  border: 2px solid #3a3a3a;
  border-left: none;
}
.common_header.rank .main_title .searching_box div {
  width: 239px;
}
.common_header.rank .main_title .searching_box .searching_select {
  width: 97px;
}
.common_header .searching_box input {
  float: left;
}
.common_header .searching_box .inp_txt {
  margin-top: 1px;
  padding-left: 8px;
  height: 34px;
  border: 0;
  font-size: 13px;
  letter-spacing: -1px;
  line-height: 34px\9;
  font-family: $fontR;
  font-weight: 400;
}
.common_header .searching_box .inp_txt::-ms-clear {
  display: none;
}
.common_header .searching_box .search_hash {
  float: left;
  width: 18px;
  height: 100%;
  background: url(#{$imgPath}/common_layout/btn_hash.png) no-repeat 100% 50%;
}
.common_header .searching_box .search_btn {
  float: left;
  width: 30px;
  height: 100%;
  background: url(#{$imgPath}/common_layout/btn_search.png) no-repeat 50% 50%;
}
.common_header .searching_box.search_inp_tx .search_btn {
  background: url(#{$imgPath}/common_layout/btn_search_white.png) no-repeat 50% 50% #3a3a3a;
}
.common_header .searching_box .searching_select {
  width: 97px;
  height: 36px;
  background: url(#{$imgPath}/common_layout/bg_search_new.png) no-repeat 100% 50%;
  border: 2px solid #3a3a3a;
  border-right: none;
  font-size: 12px;
}
.common_header .searching_box .searching_select .ss_default {
  display: block;
  padding-left: 10px;
  color: #2b2b2b;
  line-height: 36px;
  text-decoration: none;
}
.common_header .searching_box .searching_select .ss_option {
  display: none;
}
.common_header .searching_box .searching_select .ss_option.active {
  display: block;
  position: absolute;
  z-index: 10;
  top: 100%;
  left: 1px;
  width: 96px;
  margin-top: -1px;
  padding-top: 7px;
  padding-bottom: 3px;
  border: 1px solid #3a3a3a;
  background: #fff;
}
.common_header .searching_box .searching_select .ss_option.active li a {
  display: block;
  padding-left: 9px;
  line-height: 23px;
  color: #2b2b2b;
  text-decoration: none;
}
.common_header .searching_box .searching_select .ss_option.active li a:hover {
  font-weight: bold;
}
.common_header .searching_box.hash_tag div {
  width: 346px;
  border-left: 2px solid #3a3a3a;
}
.common_header.rank .searching_box.hash_tag div {
  width: 326px;
  border-left: 2px solid #3a3a3a;
}
.common_header .searching_box.hash_tag .search_btn {
  float: right;
}
.common_header .searching_box.hash_tag .inp_txt {
  font-size: 12px;
  letter-spacing: 0;
}
.common_header .searching_box.hash_tag .inp_txt:-ms-input-placeholder {
  color: #888;
  letter-spacing: -0.05em;
}
.common_header .searching_box.hash_tag .inp_txt::-webkit-input-placeholder {
  color: #888;
  letter-spacing: -0.05em;
}
.common_header .searching_box.hash_tag .inp_txt:focus:-moz-placeholder {
  color: transparent;
}
.common_header .searching_box.hash_tag .inp_txt:focus::-moz-placeholder {
  color: transparent;
}
.common_header .searching_box.hash_tag .inp_txt:focus::-webkit-input-placeholder {
  color: transparent;
}
.common_header .searching_box.hash_tag .search_hash {
  width: 22px;
}
.common_header.other_site .main_title {
  top: 17px;
}
.common_header.other_site .searching_box {
  left: 431px;
}
.common_header.other_site.rank .main_title .searching_box {
  left: 347px;
}
.common_header.other_site .searching_box div {
  height: 30px;
}
.common_header.other_site .searching_box .inp_txt {
  height: 28px;
  line-height: 28px\9;
}
.common_header.other_site .searching_box .searching_select .ss_default {
  line-height: 30px;
}
.common_header.other_site .searching_box.hash_tag .search_hash {
  width: 22px;
}
/* 검색박스(이몰) */
.emall_header_wide .emall_sch .ip_search .search_hash {
  float: left;
  width: 20px;
  height: 100%;
  background: url(#{$imgPath}/common_layout/btn_hash.png) no-repeat 50% 50%;
}
.emall_header_wide .emall_sch .btn_search {
  float: right;
  width: 35px;
  height: 31px;
  background: url(#{$imgPath}/common_layout/btn_search_white.png) 50% 50% no-repeat #3a3a3a;
}
.emall_header_wide .emall_sch.hash_tag .btn_search {
  background-image: url(#{$imgPath}/common_layout/btn_search_gray.png);
}
.emall_header_wide .emall_sch.hash_tag.search_inp_tx .btn_search {
  background-image: url(#{$imgPath}/common_layout/btn_search_white.png);
}
/* 검색박스(신몰) */
#header_sm .search .search_hash {
  width: 20px;
  height: 100%;
  background: url(#{$imgPath}/common_layout/btn_hash.png) no-repeat 50% 50%;
}
#header_sm .search .search_btn {
  width: 38px;
  height: 36px;
  border: 2px solid #f12e24;
  border-left: 0;
  border-radius: 0 50% 50% 0;
  background: url(#{$imgPath}/common_layout/btn_search.png) 5px 50% no-repeat;
}
/* 검색박스(레티나 대응) */
@media screen and (-webkit-min-device-pixel-ratio: 2) {
  /* 검색박스(레티나 대응 - ssg) */
  .common_header .searching_box .search_hash {
    background-image: url(#{$imgPath}/common_layout/btn_hash@2x.png);
    background-size: 13px 16px;
  }
  .common_header .searching_box .search_btn {
    background-image: url(#{$imgPath}/common_layout/btn_search@2x.png);
    background-size: 16px 16px;
  }
  .common_header .searching_box.search_inp_tx .search_btn {
    background-image: url(#{$imgPath}/common_layout/btn_search_white@2x.png);
    background-size: 16px 16px;
  }
  /* 검색박스(레티나 대응 - 이몰) */
  .emall_header_wide .emall_sch .ip_search .search_hash {
    background: url(#{$imgPath}/common_layout/btn_hash@2x.png) no-repeat 50% 50%;
    background-size: 13px 16px;
  }
  .emall_header_wide .emall_sch .btn_search {
    background-image: url(#{$imgPath}/common_layout/btn_search_white@2x.png);
    background-size: 16px 16px;
  }
  .emall_header_wide .emall_sch.hash_tag .btn_search {
    background-image: url(#{$imgPath}/common_layout/btn_search_gray@2x.png);
    background-size: 16px 16px;
  }
  .emall_header_wide .emall_sch.hash_tag.search_inp_tx .btn_search {
    background-image: url(#{$imgPath}/common_layout/btn_search_white@2x.png);
    background-size: 16px 16px;
  }
  /* 검색박스(레티나 대응 - 신몰) */
  #header_sm .search .search_hash {
    background-image: url(#{$imgPath}/common_layout/btn_hash@2x.png);
    background-size: 13px 16px;
  }
  #header_sm .search .search_btn {
    background-image: url(#{$imgPath}/common_layout/btn_search@2x.png);
    background-size: 16px 16px;
  }
}
/* 자동완성 */
.com_search_word {
  position: absolute;
  top: 32px;
  left: 430px;
  width: 346px;
  font-family: $fontR;
  font-weight: 400;
  font-size: 13px;
  color: #222;
}
.auto_word {
  display: none;
  position: absolute;
  top: 1px;
  left: -29px;
  right: 0;
  width: 440px;
  border: 1px solid #d1d1d1;
  background: #fff;
  box-sizing: border-box;
  z-index: 1;
}
.other_site .auto_word {
  top: 0;
  left: -49px;
}
.auto_word .word_shortcut {
  margin: 0 14px;
  border-bottom: 1px solid #d8d8d8;
}
.auto_word .word_shortcut a {
  display: block;
  position: relative;
  overflow: hidden;
  margin: 0 -14px;
  padding: 12px 24px 10px 14px;
  color: #222;
  font-weight: bold;
  letter-spacing: -0.5px;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.auto_word .word_shortcut a:after {
  position: absolute;
  top: 50%;
  right: 10px;
  margin-top: -7px;
  color: #4c4c4c;
  font-family: $fontR;
  font-weight: 400;
  font-size: 15px;
  line-height: 15px;
  content: '\003e';
}
.auto_word .word_list {
  position: relative;
  min-height: 276px;
  padding: 10px 0;
}
.auto_word .word_list li {
  line-height: 25px;
}
.auto_word .word_list li > a {
  display: block;
  overflow: hidden;
  position: relative;
  padding: 0 170px 0 14px;
  color: #222;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.auto_word .word_list li > a font {
  font-size: 13px !important;
}
.auto_word .word_list li > a.match:after {
  margin-left: 3px;
  color: #969696;
  font-weight: bold;
  font-size: 15px;
  vertical-align: middle;
  content: '\003e';
}
.auto_word .word_list li > a .word_date,
.auto_word .tag_list li > a .word_date {
  position: absolute;
  top: 0;
  right: 19px;
  color: #969696;
  line-height: inherit;
}
.auto_word.ad_showing .word_list li > a,
.auto_word.ad_showing .tag_list li > a {
  padding-right: 240px;
}
.auto_word.ad_showing .word_list li > a .word_date,
.auto_word.ad_showing .tag_list li > a .word_date {
  right: 200px;
}
.auto_word .word_list li strong {
  font-weight: normal;
  color: #fc4248;
}
.auto_word .word_list li .ad {
  display: none;
  position: absolute;
  top: 1px;
  bottom: 0;
  right: 0;
  width: 180px;
  background: #fff;
  z-index: 1;
}
.auto_word .word_list li .ad:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  width: 1px;
  background: #e5e5e5;
  content: '';
}
.auto_word .word_list li .ad .link {
  display: block;
  color: #222;
  text-decoration: none;
}
.auto_word .word_list li .ad_thmb {
  position: relative;
  margin: 34px 20px 0;
  *zoom: 1;
}
.auto_word .word_list li .ad_thmb img {
  width: 100%;
  vertical-align: top;
}
.auto_word .word_list li .ad_thmb .tt_adinfo_n {
  left: 0;
  bottom: 0;
  width: 45px;
  height: 20px;
}
.auto_word .word_list li .ad_thmb .tt_adinfo_n .btn_tt_adinfo {
  width: 45px;
  height: 20px;
  background: #fbfcfc url(#{$imgPath}/common/ico_adinfo_s.png) no-repeat -3px 1px;
}
.auto_word .word_list li .ad_detail {
  margin: 0 20px;
}
.auto_word .word_list li .ad_detail .link {
  padding: 5px 0;
  font-size: 12px;
  line-height: 17px;
  text-align: center;
}
.auto_word .word_list li .ad_detail .tx {
  display: block;
  display: -webkit-box;
  overflow: hidden;
  max-height: 34px;
  word-break: break-all;
  word-wrap: break-word;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  *zoom: 1;
}
.auto_word .word_list li .ad_detail .price {
  display: block;
  margin-top: 5px;
}
.auto_word .word_list li .ad_detail .price em {
  font-weight: bold;
  font-size: 13px;
  vertical-align: -1px;
}
.auto_word .word_list li.on,
.auto_word .word_list li:hover {
  background: #f4f5f8;
}
.auto_word .word_list li.on .ad,
.auto_word .word_list li:hover .ad {
  display: block;
}
.auto_word .word_plan {
  margin: 0 14px;
  padding: 15px 0;
  border-top: 1px solid #d8d8d8;
}
.auto_word .word_plan li {
  position: relative;
  margin-top: 6px;
}
.auto_word .word_plan li:first-child {
  margin-top: 0;
}
.auto_word .word_plan li a {
  display: block;
  line-height: 22px;
  color: #222;
  text-decoration: none;
  cursor: pointer;
  zoom: 1;
}
.auto_word .word_plan li a:after {
  display: block;
  clear: both;
  content: '';
}
.auto_word .word_plan li .plan_tit {
  position: absolute;
  top: 50%;
  left: 0;
  width: 49px;
  height: 20px;
  margin-top: -11px;
  border: 1px solid #222;
  color: #222;
  font-size: 11px;
  line-height: 22px;
  text-align: center;
}
.auto_word .word_plan li .plan_txt {
  display: block;
  overflow: hidden;
  padding-left: 61px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.auto_word .tag_list {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px 0 15px;
  border-top: 1px solid #e5e5e5;
  background: #fff;
}
.auto_word .tag_list:before {
  position: absolute;
  top: -11px;
  left: 0;
  width: 100%;
  height: 10px;
  background: #fff;
  content: '';
}
.auto_word .tag_list li {
  line-height: 25px;
}
.auto_word .tag_list li a {
  display: block;
  overflow: hidden;
  position: relative;
  padding: 0 14px;
  color: #222;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
}
.auto_word .tag_list li strong {
  font-weight: normal;
  color: #fc4248;
}
.auto_word .tag_list li.on a,
.auto_word .tag_list li a:hover {
  background: #f4f5f8;
}
.key_word {
  display: none;
  position: absolute;
  top: 1px;
  left: 0;
  right: 0;
  min-height: 206px;
  border: 1px solid #d1d1d1;
  background: #fff;
  z-index: 1;
  zoom: 1;
}
.common_header.other_site .key_word {
  top: 0;
}
.key_word:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  z-index: 10;
  width: 1px;
  background: #d1d1d1;
  content: '';
}
.key_word:after {
  display: block;
  clear: both;
  content: '';
}
.key_word .recent_list {
  float: left;
  width: 50%;
  z-index: 20;
}
.key_word .often_list {
  float: left;
  width: 50%;
  z-index: 10;
}
.key_word .nodata {
  position: absolute;
  top: 34px;
  bottom: 0;
  width: 50%;
  text-align: center;
}
.key_word .recent_list .nodata {
  left: 0;
}
.key_word .often_list .nodata {
  left: 50%;
}
.key_word .nodata p {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  margin-top: -18px;
  font-size: 13px;
  line-height: 18px;
  vertical-align: middle;
}
.key_word .tit_area {
  position: relative;
  height: 35px;
  border-bottom: 1px solid #e5e5e5;
  line-height: 35px;
  text-align: center;
  z-index: 10;
}
.key_word .tit_area strong {
  display: inline-block;
  overflow: hidden;
  max-width: 90%;
  color: #000;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
}
.key_word .result_area {
  position: relative;
  padding: 0 0 31px;
}
.key_word .result_area ul {
  overflow: hidden;
  padding: 8px 0 18px;
  height: 264px;
}
.key_word .result_area li {
  position: relative;
  padding: 0 29px 0 10px;
  line-height: 25px;
}
.key_word .often_list .result_area li {
  padding-right: 45px;
}
.key_word .result_area li a {
  display: block;
  overflow: hidden;
  padding-left: 5px;
  color: #000;
  letter-spacing: -0.03em;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}
.key_word .result_area li.on,
.key_word .result_area li:hover {
  background: #f4f5f8;
}
.key_word .result_area li.on a,
.key_word .result_area li:hover a {
  color: #fc4248;
}
.key_word .result_area .num {
  position: absolute;
  top: 50%;
  right: 15px;
  width: 30px;
  height: 26px;
  margin-top: -13px;
  font-size: 12px;
  color: #9e9e9e;
  text-align: right;
  white-space: nowrap;
}
.key_word .result_area .btn_del {
  overflow: hidden;
  position: absolute;
  top: 50%;
  right: 10px;
  width: 19px;
  height: 19px;
  margin-top: -10px;
  background: url(#{$imgPath}/display/search/ico_del.gif) 50% 50% no-repeat;
  line-height: 999px;
}
.key_word .btn_clear {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  width: 100%;
  height: 30px;
  border-top: 1px solid #f5f5f5;
  background: #fbfbfb;
  color: #888;
  font-family: $fontR;
  font-weight: 400;
  font-size: 11px;
  line-height: 31px;
}
.key_word .btn_clear span {
  position: relative;
}
.key_word .ssg-tooltip-wrap {
  display: inline-block;
  *display: inline;
  zoom: 1;
  position: relative;
  height: 17px;
  line-height: 17px;
  text-align: left;
  vertical-align: middle;
}
.key_word .ssg-tooltip {
  display: inline-block;
  overflow: hidden;
  width: 17px;
  height: 17px;
  background: url(#{$imgPath}/display/search/ico_qmark.png) no-repeat;
  line-height: 999px;
  vertical-align: top;
}
.key_word .ssg-tooltip-layer {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 185px;
  padding: 18px 20px 15px 15px;
  border: 1px solid #3a3a3a;
  background: #fff;
  line-height: 20px;
  word-break: break-all;
  word-wrap: break-word;
}
.key_word .recomm_list {
  position: absolute;
  top: -1px;
  right: 100%;
  width: 344px;
  border: 1px solid #d1d1d1;
  background: #fff;
  z-index: 30;
}
.key_word .item_area {
  position: relative;
  height: 307px;
  padding: 4px 10px 10px;
}
.key_word .cmitem_list {
  margin-left: -26px;
  zoom: 1;
}
.key_word .cmitem_list:after {
  display: block;
  clear: both;
  content: '';
}
.key_word .cmitem_bx {
  float: left;
  width: 90px;
  height: 139px;
  margin: 13px 0 0 26px;
}
.key_word .cmitem_link {
  display: block;
  padding: 5px 0 8px;
  color: #222;
  text-decoration: none;
  cursor: pointer;
  zoom: 1;
}
.key_word .cmitem_thmb {
  display: block;
  width: 70px;
  margin: 0 auto;
}
.key_word .cmitem_thmb img {
  vertical-align: top;
}
.key_word .cmitem_tx {
  display: block;
  margin-top: 8px;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.03em;
  text-align: center;
}
.key_word .cmitem_tx .tx {
  display: block;
  overflow: hidden;
  max-height: 30px;
}
.key_word .cmitem_tx .price {
  display: block;
  margin-top: 3px;
}
/* 자동완성(SSG) */
.common_header.other_site .com_search_word {
  top: 28px;
  left: 402px;
}
.common_header .com_search_word {
  top: 38px;
  left: 456px;
  width: 350px;
}
.common_header.rank .com_search_word {
  top: 43px;
  left: 456px;
  width: 350px;
}
.common_header.other_site .com_search_word {
  top: 32px;
  left: 431px;
}
.common_header.rank.other_site .com_search_word {
  top: 38px;
  left: 431px;
}
/* 자동완성(신몰) */
#header_sm .com_search_word {
  top: 59px;
  left: 263px;
  width: 294px;
}
#header_sm .auto_word {
  top: 2px;
}
#header_sm .auto_word:before {
  width: 100%;
}
#header_sm .key_word .recomm_list {
  width: 317px;
}
#header_sm .key_word .cmitem_list {
  margin-left: -13px;
}
#header_sm .key_word .cmitem_bx {
  margin-left: 13px;
}
/* 자동완성(신백) */
#header_sd .com_search_word {
  top: 59px;
  left: 205px;
  width: 350px;
  z-index: 50;
}
#header_sd .auto_word {
  top: 1px;
  left: 0;
}
#header_sd .auto_word:before {
  display: none;
}
#header_sd .key_word {
  top: 1px;
  border-top: 1px solid #d1d1d1;
}
#header_sd .key_word .cmitem_list {
  margin-left: -27px;
}
#header_sd .key_word .cmitem_bx {
  margin-left: 27px;
}
/* 자동완성(이/트) */
.emall_header_wide .com_search_word {
  top: 58px;
  left: 415px;
  width: 350px;
}
.emall_header_wide .auto_word {
  width: inherit;
  left: 0;
}
.emall_header_wide .key_word .item_area {
  height: 278px;
  padding-top: 8px;
  padding-bottom: 35px;
}
.emall_header_wide .key_word .cmitem_bx {
  margin-top: 0;
}
.traders_header_wide .com_search_word {
  top: 59px;
  left: 456px;
  width: 350px;
}
.traders_header_wide .auto_word {
  border-top: 1px solid #d1d1d1;
  left: 0;
}
.traders_header_wide .auto_word {
  width: inherit;
}
.traders_header_wide .auto_word:before {
  display: none;
}
.traders_header_wide .key_word {
  border-top: 1px solid #d1d1d1;
}
.traders_header_wide .key_word .item_area {
  height: 278px;
  padding-top: 8px;
  padding-bottom: 35px;
}
.traders_header_wide .key_word .cmitem_list {
  margin-left: -25px;
}
.traders_header_wide .key_word .cmitem_bx {
  margin: 0 0 0 25px;
}
/* 자동완성(tv) */
.tv_header .com_search_word {
  top: 59px;
  left: 345px;
  width: 332px;
}
.tv_header .auto_word {
  width: inherit;
  left: 0;
}
.tv_header .auto_word:before {
  display: none;
}
.tv_header .key_word .cmitem_bx {
  margin-top: 0;
}
/* 자동완성(Boots) */
.boots_header .com_search_word {
  top: 59px;
  left: 50%;
  width: 376px;
  margin-left: -362px;
  z-index: 20;
}
.boots_header .auto_word {
  top: 1px;
  left: 0;
  width: inherit;
}
.boots_header .auto_word:before {
  display: none;
}
.boots_header .auto_word .word_list li strong,
.boots_header .auto_word .tag_list li strong {
  color: #00498e;
}
.boots_header .key_word {
  top: 1px;
}
.boots_header .key_word .result_area li.on a,
.boots_header .key_word .result_area li:hover a {
  color: #00498e;
}
.boots_header .key_word .cmitem_list {
  margin-left: -27px;
}
.boots_header .key_word .cmitem_bx {
  margin-left: 27px;
}
/* 자동완성(siv) */
.siv_header .com_search_word {
  top: 53px;
  left: 335px;
  width: 350px;
}
.siv_header .auto_word {
  top: 1px;
  width: inherit;
  left: 0;
}
.siv_header .auto_word:before {
  display: none;
}
.siv_header .key_word {
  top: 1px;
}
.siv_header .key_word .recomm_list {
  width: 317px;
}
.siv_header .key_word .cmitem_list {
  margin-left: -13px;
}
.siv_header .key_word .cmitem_bx {
  margin-left: 13px;
}
/* 자동완성(starfield) */
.sf_header .com_search_word {
  top: 71px;
  right: 30px;
  left: auto;
  z-index: 20;
  width: 339px;
  margin-right: 0;
}
.sf_header .auto_word {
  top: 1px;
  width: inherit;
  left: 0;
}
.sf_header .auto_word:before {
  display: none;
}
.sf_header .key_word {
  top: 1px;
}
.sf_header .key_word .ssg-tooltip-layer {
  right: 0;
  left: auto;
}
/* 자동완성(outlet) */
.oul_header .com_search_word {
  top: 51px;
  right: 0;
  left: auto;
  width: 260px;
  margin-right: 0;
  z-index: 300;
}
.oul_header .auto_word {
  top: 0;
  width: inherit;
  border: 1px solid #d1d1d1;
  left: 0;
}
.oul_header .auto_word:before {
  display: none;
}
.oul_header .auto_word .word_list li > a {
  padding-right: 100px;
}
.oul_header .key_word {
  top: 0;
  border: 1px solid #d1d1d1;
}
.oul_header .key_word .ssg-tooltip-layer {
  right: 0;
  left: auto;
}
/* 자동완성(mnmorning) */
.mnmorning_header .com_search_word {
  top: 73px;
  right: 0;
  left: auto;
  z-index: 20;
  width: 350px;
  margin-right: 0;
}
.mnmorning_header .auto_word {
  top: 1px;
  width: inherit;
  left: 0;
}
.mnmorning_header .auto_word:before {
  display: none;
}
.mnmorning_header .key_word {
  top: 1px;
}
.mnmorning_header .key_word .ssg-tooltip-layer {
  right: 0;
  left: auto;
}
/* 자동완성(mnseller) */
.mnseller_header .com_search_word {
  top: 71px;
  left: 50%;
  width: 390px;
  z-index: 20;
  transform: translateX(-50%);
}
.mnseller_header .auto_word {
  top: 0;
  width: inherit;
  left: 0;
  border-top: 0;
}
.mnseller_header .auto_word:before {
  display: none;
}
.mnseller_header .key_word {
  top: 0;
  border-top: 0;
}
.mnseller_header .key_word .ssg-tooltip-layer {
  right: 0;
  left: auto;
}
/* 자동완성(mndeptfood) */
.mndeptfood_header .com_search_word {
  top: 67px;
  right: 0;
  left: auto;
  z-index: 20;
  width: 350px;
  margin-right: 0;
}
.mndeptfood_header .auto_word {
  top: 0;
  width: inherit;
  left: 0;
}
.mndeptfood_header .auto_word:before {
  display: none;
}
.mndeptfood_header .key_word {
  top: 0;
}
.mndeptfood_header .key_word .ssg-tooltip-layer {
  right: 0;
  left: auto;
}
/* 자동완성(mncasamia) */
.mncasamia_header .com_search_word {
  top: 74px;
  right: 0;
  left: auto;
  z-index: 20;
  width: 350px;
  margin-right: 0;
}
.mncasamia_header .auto_word {
  top: 0;
  width: inherit;
  left: 0;
}
.mncasamia_header .auto_word:before {
  display: none;
}
.mncasamia_header .key_word {
  top: 0;
}
.mncasamia_header .key_word .ssg-tooltip-layer {
  right: 0;
  left: auto;
}
/* 자동완성(mnchicor) */
.mnchicor_header .com_search_word {
  top: 67px;
  left: 50%;
  z-index: 20;
  width: 360px;
  margin-right: 0;
  margin-left: -180px;
}
.mnchicor_header .auto_word {
  top: 0;
  width: inherit;
  left: 0;
}
.mnchicor_header .auto_word:before {
  display: none;
}
.mnchicor_header .key_word {
  top: 0;
}
.mnchicor_header .key_word .ssg-tooltip-layer {
  right: 0;
  left: auto;
}
/* 자동완성(mntrip) */
.mntrip_header .com_search_word {
  top: 80px;
  left: 207px;
  width: 220px;
  margin-right: 0;
  z-index: 300;
}
.mntrip_header .auto_word {
  top: 0;
  width: inherit;
  border: 1px solid #d1d1d1;
  left: 0;
}
.mntrip_header .auto_word:before {
  display: none;
}
.mntrip_header .auto_word .word_list li > a {
  padding-right: 100px;
}
.mntrip_header .key_word {
  top: 0;
  left: -5px;
  width: 270px;
  border: 1px solid #d1d1d1;
}
.mntrip_header .key_word .ssg-tooltip-layer {
  right: 0;
  left: auto;
}
/* 자동완성(mnstarbucks) */
.mnstarbucks_header .com_search_word {
  top: 74px;
  right: 0;
  left: auto;
  z-index: 20;
  width: 320px;
  margin-right: 0;
}
.mnstarbucks_header .auto_word {
  top: 0;
  width: inherit;
  left: 0;
}
.mnstarbucks_header .auto_word:before {
  display: none;
}
.mnstarbucks_header .key_word {
  top: 0;
}
.mnstarbucks_header .key_word .ssg-tooltip-layer {
  right: 0;
  left: auto;
}
/* 해시태그 */
.com_search_hash {
  position: absolute;
  top: 32px;
  left: 160px;
  width: 680px;
  font-family: $fontR;
  font-weight: 400;
  font-size: 13px;
  color: #222;
  background-color: #fff;
  border: 1px solid #d1d1d1;
}
.com_search_hash .key_hash {
  position: relative;
}
.com_search_hash .key_hash button.btn_close {
  position: absolute;
  right: 0;
  top: 0;
  width: 45px;
  height: 45px;
  background: url(#{$imgPath}/common_layout/hash_close.png) no-repeat;
  z-index: 99;
}
.com_search_hash a {
  font-size: 13px;
  text-decoration: none;
  color: #3e3e3e;
}
.com_search_hash .real_time {
  position: relative;
  padding: 30px 0 27px 0;
  float: left;
  border-right: 1px solid #ececec;
  width: 225px;
  min-height: 357px;
}
.com_search_hash .real_time .tit {
  padding-bottom: 10px;
  border-bottom: 1px solid #e5e5e5;
  font-size: 14px;
  color: #000;
  margin: 0 25px;
}
.com_search_hash .real_time .real_lst {
  padding-top: 11px;
}
.com_search_hash .real_time .real_lst li {
  display: block;
  padding: 7px 25px 0 25px;
}
.com_search_hash .real_time .real_lst li span.arr_num {
  display: inline-block;
  width: 18px;
  color: #888;
  font-family: $fontR;
  font-weight: 400;
}
.com_search_hash .real_time .real_lst li span.text {
  display: inline-block;
  overflow: hidden;
  max-width: 88%;
  color: #666;
  vertical-align: middle;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.com_search_hash .real_time .real_lst p.date {
  text-align: right;
  padding-top: 8px;
  border-top: 1px solid #e5e5e5;
  font-size: 12px;
  color: #888;
  margin: 18px 25px 0 25px;
}
.com_search_hash .real_time .real_lst li:hover {
  background-color: #f4f5f8;
  padding-bottom: 5px;
  margin-bottom: -5px;
}
.com_search_hash .real_time .real_lst li:hover span.text {
  color: #222;
  font-weight: bold;
}
.com_search_hash .recomm_hash {
  position: relative;
  padding: 30px 25px 25px 25px;
  float: left;
  width: 404px;
  min-height: 357px;
}
.com_search_hash .recomm_hash .tit {
  font-size: 16px;
  padding-bottom: 10px;
}
.com_search_hash .recomm_hash .tit strong {
  position: relative;
}
.com_search_hash .recomm_hash .tit strong:after {
  position: absolute;
  right: -8px;
  top: 0;
  border-radius: 50%;
  width: 6px;
  height: 6px;
  background-color: get-color('primary');
  content: '';
}
.com_search_hash .recomm_hash .tit em {
  color: get-color('primary');
}
.com_search_hash .recomm_hash .recomm_lst {
  margin-bottom: 27px;
  max-height: 135px;
  overflow: hidden;
  font-size: 0;
}
.com_search_hash .recomm_hash .recomm_lst:last-child {
  margin-bottom: 0;
}
.com_search_hash .recomm_hash .recomm_lst li {
  display: inline-block;
  vertical-align: top;
  *display: inline;
}
.com_search_hash .recomm_hash .recomm_lst li a {
  display: inline-block;
  margin: 0 5px 5px 0;
  border: 1px solid #ececec;
  border-radius: 30px;
  padding: 6px 10px 3px 13px;
  line-height: 18px;
}
.com_search_hash .recomm_hash .recomm_lst li a:hover {
  color: get-color('primary');
  border: 1px solid get-color('primary');
  text-decoration: none;
}
.com_search_hash .com_search_txarea {
  clear: both;
  height: 34px;
  padding: 0 25px;
  background: #f4f5f8;
  line-height: 34px;
}
.com_search_hash .com_search_tx {
  font-size: 12px;
  font-family: $fontR;
  font-weight: 400;
  color: #888;
  letter-spacing: -1px;
}
.com_search_hash .com_search_tx em {
  color: #666;
}
.common_header.other_site .com_search_hash {
  top: 28px;
}
.common_header .com_search_hash {
  top: 38px;
}
.common_header.rank .com_search_hash {
  top: 44px;
  left: 186px;
}
.common_header.rank.other_site .com_search_hash {
  top: 38px;
  left: 141px;
}
.common_header.other_site .com_search_hash {
  top: 32px;
}
/* 해시태그(신몰) */
#header_sm .com_search_hash {
  top: 61px;
  z-index: 40;
}
/* 해시태그(이몰) */
.emall_header_wide .emall_sch.hash_tag .ip_search .inp_txt {
  padding: 0 2px;
  font-size: 12px;
  font-family: $fontR;
  font-weight: 400;
}
.emall_header_wide .com_search_hash {
  top: 58px;
  left: 249px;
  z-index: 1;
}
.emall_header .com_search_hash a {
  text-decoration: none;
}
