/* cmfooter 1280 */
#footer.common_footer {
  background-color: #fff;
}
.common_footer {
  position: static;
  width: auto;
  margin: 0 auto;
  padding-top: 30px;
}
.common_footer .service_support {
  width: auto;
  height: 51px;
  margin-bottom: 0;
  border-top: 1px solid #e4e4e4;
  border-bottom: 1px solid #e4e4e4;
}
.common_footer .service_support .f_inner {
  position: relative;
  width: 1280px;
  margin: 0 auto;
  zoom: 1;
}
.common_footer .service_support .f_inner:after {
  display: block;
  clear: both;
  content: '';
}

.common_footer .support_txt {
  display: inline;
  float: left;
  padding-top: 19px;
}
.common_footer .support_txt:after {
  content: '';
  display: block;
  clear: both;
}
.common_footer .support_txt li {
  float: left;
  margin-left: 19px;
  padding-left: 20px;
  background: url(#{$imgPath}/footer/bu_bar.png) no-repeat 0 2px;
  &:first-child {
    padding-left: 0;
    margin-left: 0;
  }
}
.common_footer .support_txt li a {
  display: block;
  height: 15px;
  text-decoration: none;
  color: #777;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
}
.common_footer .support_txt li a:hover {
  text-decoration: underline;
}
.common_footer .support_txt li a strong {
  color: get-color('primary');
  font-weight: bold;
}
.common_footer .support_txt li:first-child {
  background: none;
}
.common_footer .support_site {
  float: right;
  .family_link {
    float: left;
    margin-left: -1px;
  }
}
.common_footer .family_link {
  position: relative;
  z-index: 300;
  float: right;
  right: 0;
  top: 10px;
  width: 182px;
  height: 30px;
}
.common_footer .family_link h2 {
  right: 0;
  top: 13px;
  width: 182px;
  height: 30px;
}
.common_footer .family_link h2 a {
  position: relative;
  width: 182px !important;
  height: 30px !important;
  padding: 0 10px 0 15px;
  *padding: 0;
  line-height: 29px;
  text-align: left;
  color: #444;
  font-size: 12px;
  font-weight: normal;
  text-decoration: none;
  border: solid 1px #cecece;
  box-sizing: border-box;
}
.common_footer .family_link h2 a .sel_arrow {
  position: absolute;
  top: 50%;
  right: 10px;
  width: 15px;
  height: 9px;
  margin-top: -5px;
  background: url(#{$imgPath}/footer/bg_sel_arrow_close.png);
}
.common_footer .family_link h2 a.active .sel_arrow {
  background: url(#{$imgPath}/footer/bg_sel_arrow_open.png);
}
.common_footer .family_link h2 a.active {
  background-position: -300px -50px;
}
.common_footer .family_link dl {
  display: none;
  position: absolute;
  bottom: 29px;
  left: 0;
  width: 156px;
  border: 1px solid #b2b2b2;
  padding: 3px 12px 13px 12px;
  background-color: #fff;
}
.common_footer .family_link dl.active {
  display: block;
}
.common_footer .family_link dl dt {
  position: relative;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 1px;
  color: #969696;
  padding: 8px 0 2px 0;
  display: block;
}
.common_footer .family_link dl dt span.txt {
  position: relative;
  padding-right: 5px;
  background-color: #fff;
  z-index: 99;
}
.common_footer .family_link dl dt:after {
  position: absolute;
  top: 16px;
  left: 10px;
  height: 1px;
  background-color: #cecece;
  width: 143px;
  content: '';
  z-index: 9;
}
.common_footer .family_link dl dt.no_tit:after {
  display: none;
}
.common_footer .family_link dl dd a {
  font-size: 12px;
  color: #222;
  text-decoration: none;
  padding: 2px 0;
  display: block;
  line-height: 15px;
}
.common_footer .family_link dl dd a:hover {
  text-decoration: underline;
  color: get-color('primary');
}

.common_footer .sp_cmfooter,
.common_footer .cmfooter_corp_wrap .cmfooter_corp_cs .cmfooter_cs_tel:before {
  display: inline-block;
  background: url(#{$imgPath}/footer/sp_cmfooter.png) no-repeat 0 0;
  vertical-align: top;
}
.common_footer .cmfooter_corp_wrap {
  min-height: 338px;
  padding: 20px 0 70px;
  font-size: 12px;
  line-height: 23px;
  letter-spacing: -0.5px;
  color: #777;
}
.common_footer .cmfooter_corp_wrap .cmfooter_corp_row {
  margin: 0 0 24px;
}
.common_footer .cmfooter_corp_wrap .cmfooter_corp_in {
  position: relative;
  width: 1280px;
  margin: 0 auto;
  zoom: 1;
}
.common_footer .cmfooter_corp_wrap .cmfooter_corp_in:after {
  display: block;
  clear: both;
  content: '';
}
.common_footer .cmfooter_corp_wrap .cmfooter_corp_info {
  float: left;
}
.common_footer .cmfooter_corp_wrap .cmfooter_corp_mall {
  float: left;
  margin-left: 70px;
  zoom: 1;
}
:lang(en) .common_footer .cmfooter_corp_wrap .cmfooter_corp_mall,
:lang(zh) .common_footer .cmfooter_corp_wrap .cmfooter_corp_mall {
  display: block;
  float: none;
  margin-left: 0;
  margin-bottom: 20px;
}
.common_footer .cmfooter_corp_wrap .cmfooter_corp_mall:after {
  display: block;
  clear: both;
  content: '';
}
.common_footer .cmfooter_corp_wrap .cmfooter_corp_mall:first-child {
  margin-left: 0;
}
.common_footer .cmfooter_corp_wrap .cmfooter_corp_mall dt {
  font-weight: bold;
  color: #222;
}
.common_footer .cmfooter_corp_wrap .cmfooter_corp_txarea .cmfooter_corp_txwrap {
  margin: 11px 0 0;
}
.common_footer .cmfooter_corp_wrap .cmfooter_corp_txarea .cmfooter_corp_txwrap:first-child {
  margin-top: 0;
}
.common_footer .cmfooter_corp_wrap .cmfooter_corp_txarea .cmfooter_corp_tx {
  display: inline-block;
  margin-left: 7px;
}
.common_footer .cmfooter_corp_wrap .cmfooter_corp_txarea .cmfooter_corp_tx:first-child {
  margin-left: 0;
}
.common_footer .cmfooter_corp_wrap .cmfooter_corp_btnarea {
  margin-top: 4px;
}
.common_footer .cmfooter_corp_wrap .cmfooter_corp_btn {
  display: inline-block;
  margin: 0 0 0 3px;
  padding: 1px 8px 1px 6px;
  border: 1px solid #dcdcdc;
  border-radius: 2px;
  line-height: 19px;
  font-size: 12px;
  color: #555;
  text-decoration: none;
  vertical-align: top;
}
.common_footer .cmfooter_corp_wrap .cmfooter_corp_btn:after {
  display: inline-block;
  margin: 6px 0 0 6px;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-left: 4px solid get-color('primary');
  vertical-align: top;
  content: '';
}
.common_footer .cmfooter_corp_wrap .cmfooter_corp_btn:first-child {
  margin-left: 0;
}
.common_footer .cmfooter_corp_wrap .cmfooter_corp_cs {
  margin: 2px 0 8px;
  line-height: 21px;
  zoom: 1;
}
.common_footer .cmfooter_corp_wrap .cmfooter_corp_cs:after {
  display: block;
  clear: both;
  content: '';
}
.common_footer .cmfooter_corp_wrap .cmfooter_corp_cs .cmfooter_cs_tel {
  display: inline-block;
  margin-right: 6px;
  font-size: 24px;
  letter-spacing: 0;
  color: #222;
  vertical-align: top;
}
.common_footer .cmfooter_corp_wrap .cmfooter_corp_cs .cmfooter_cs_tel:before {
  width: 22px;
  height: 21px;
  background-position: 0 0;
  content: '';
}
.common_footer .cmfooter_corp_wrap .cmfooter_corp_cs strong.cmfooter_corp_tx {
  color: #222;
  font-weight: normal;
  line-height: 23px;
  padding-left: 2px;
}
.common_footer .cmfooter_corp_wrap .cmfooter_corp_rgt {
  position: absolute;
  top: 3px;
  right: 0;
  text-align: right;
}
.common_footer .sp_cmfooter_sns {
  display: inline-block;
  background: url(#{$imgPath}/footer/sp_cmfooter_sns.png) no-repeat 0 0;
  vertical-align: top;
}
.common_footer .cmfooter_corp_wrap .cmfooter_sns {
  margin-bottom: 30px;
}
.common_footer .cmfooter_corp_wrap .cmfooter_sns strong {
  display: inline-block;
  position: relative;
  margin: 11px 14px 0 28px;
  font-weight: 500;
  font-weight: normal;
  vertical-align: top;
}
.common_footer .cmfooter_corp_wrap .cmfooter_sns a {
  width: 45px;
  height: 45px;
  margin-left: 5px;
}
.common_footer .cmfooter_corp_wrap .cmfooter_sns .cmfooter_sns_fb {
  background-position: 0 0;
}
.common_footer .cmfooter_corp_wrap .cmfooter_sns .cmfooter_sns_ig {
  background-position: 0 -50px;
}
.common_footer .cmfooter_corp_wrap .cmfooter_sns .cmfooter_sns_kakao {
  background-position: 0 -100px;
}
.common_footer .cmfooter_corp_wrap .cmfooter_sns .cmfooter_sns_blog {
  background-position: 0 -150px;
}
.common_footer .cmfooter_corp_wrap .cmfooter_app {
  text-align: right;
}
.common_footer .cmfooter_corp_wrap .cmfooter_app_lft {
  display: inline-block;
  margin: 0 14px 0 0;
  text-align: left;
  vertical-align: top;
}
.common_footer .cmfooter_corp_wrap .cmfooter_app_tit {
  display: block;
  font-weight: bold;
}
.common_footer .cmfooter_corp_wrap .cmfooter_app_ssg .cmfooter_app_qrcode {
  width: 70px;
  height: 70px;
  background-position: -200px -50px;
}
.common_footer .cmfooter_corp_wrap .cmfooter_app_sm .cmfooter_app_qrcode {
  width: 70px;
  height: 70px;
  background-position: -200px -150px;
}
.common_footer .cmfooter_corp_wrap .cmfooter_app_em .cmfooter_app_qrcode {
  width: 70px;
  height: 70px;
  background-position: -200px -250px;
}
.common_footer .cmfooter_corp_wrap .cmfooter_app_traders .cmfooter_app_qrcode {
  width: 71px;
  height: 74px;
  background-position: -200px -350px;
}
.common_footer .cmfooter_corp_wrap .cmfooter_corp_row2 {
  margin: 0 0 34px;
}
.common_footer .cmfooter_corp_wrap .cmfooter_corp_hosting .cmfooter_corp_tx {
  display: inline-block;
  margin-left: 45px;
  letter-spacing: 0;
  color: #222;
}
.common_footer .cmfooter_corp_wrap .cmfooter_corp_hosting .cmfooter_corp_tx:first-child {
  margin-left: 0;
}
.common_footer .cmfooter_corp_wrap .cmfooter_corp_notice {
  font-size: 10px;
  line-height: 1.6;
  letter-spacing: -0.42px;
  color: #777;
}
.common_footer .cmfooter_corp_wrap .cmfooter_corp_arr_link {
  color: #222;
  margin: 0 5px;
  &:after {
    content: '';
    display: inline-block;
    width: 4px;
    height: 4px;
    margin: 6px 0 0 4px;
    border: solid #222;
    border-width: 1px 1px 0 0;
    transform: rotate(45deg);
    vertical-align: top;
  }
}
.common_footer .cmfooter_corp_wrap .cmfooter_mark {
  position: relative;
}
.common_footer .cmfooter_corp_wrap .cmfooter_mark ul li {
  display: inline-block;
  margin-right: 18px;
  padding: 6px 0;
  font-size: 10px;
  line-height: 13px;
  color: #777;
  vertical-align: top;
}
.common_footer .cmfooter_corp_wrap .cmfooter_mark ul li.cmfooter_mark_sgi {
  padding: 0;
}
.common_footer .cmfooter_corp_wrap .cmfooter_mark ul li.cmfooter_mark_sgi2 {
  padding: 6px 0;
  line-height: 15px;
}
.common_footer_sm .cmfooter_corp_wrap .cmfooter_mark ul li.cmfooter_mark_sgi {
  display: block;
  margin-top: 5px;
}
.common_footer .cmfooter_corp_wrap .cmfooter_mark ul li a {
  color: #777;
  text-decoration: none;
}
.common_footer .cmfooter_corp_wrap .cmfooter_mark ul li .cmfooter_corp_btn {
  color: #555;
  vertical-align: text-bottom;
  &:not(:first-child) {
    margin-left: 5px;
  }
}
.common_footer .cmfooter_corp_wrap .cmfooter_mark_ico {
  width: 30px;
  height: 30px;
}
.common_footer .cmfooter_corp_wrap .cmfooter_mark_risk .cmfooter_mark_ico {
  width: 97px;
  height: 30px;
  background-position: 0 -50px;
}
.common_footer .cmfooter_corp_wrap .cmfooter_mark_kisa .cmfooter_mark_ico {
  width: 30px;
  height: 30px;
  background-position: 0 -100px;
}
.common_footer .cmfooter_corp_wrap .cmfooter_mark_kolsa .cmfooter_mark_ico {
  width: 48px;
  height: 30px;
  background-position: 0 -150px;
}
.common_footer .cmfooter_corp_wrap .cmfooter_mark_ea .cmfooter_mark_ico {
  width: 25px;
  height: 30px;
  background-position: 0 -200px;
}
.common_footer .cmfooter_corp_wrap .cmfooter_mark_wa .cmfooter_mark_ico {
  width: 26px;
  height: 30px;
  background-position: 0 -250px;
}
.common_footer .cmfooter_corp_wrap .cmfooter_mark_aa .cmfooter_mark_ico {
  width: 26px;
  height: 30px;
  background-position: 0 -300px;
}
.common_footer .cmfooter_corp_wrap .cmfooter_mark_norton .cmfooter_mark_ico {
  width: 60px;
  height: 30px;
  background-position: 0 -350px;
}
.common_footer .cmfooter_corp_wrap .cmfooter_mark_ico + .cmfooter_mark_txt {
  margin-left: 4px;
}
.common_footer .cmfooter_corp_wrap .cmfooter_mark_txt {
  display: inline-block;
  padding: 2px 0;
}
.common_footer .cmfooter_corp_wrap .cmfooter_mark_tit {
  font-weight: bold;
  font-size: 11px;
  color: #222;
}
.common_footer .cmfooter_corp_wrap .cmfooter_corp_copy {
  display: block;
  width: 164px;
  height: 41px;
  margin: 0 auto;
  background-position: -200px 0;
}
@media screen and (-webkit-min-device-pixel-ratio: 2) {
  .common_footer .sp_cmfooter,
  .common_footer .cmfooter_corp_wrap .cmfooter_corp_cs .cmfooter_cs_tel:before {
    background-image: url(#{$imgPath}/footer/sp_cmfooter@2x.png);
    background-size: 640px auto;
  }
  .common_footer .sp_cmfooter_sns {
    background-image: url(#{$imgPath}/footer/sp_cmfooter_sns@2x.png);
    background-size: 45px auto;
  }
}
