/* 기획전 배너 - 컨텐츠(unit) */
.cmplan_unit {
  position: relative;
  font-family: $fontR;
  font-weight: 400;
  word-break: break-all;
}
.cmplan_unit .badge_ad {
  position: absolute;
  top: 5px;
  right: 5px;
}
.cmplan_unit .cmplan_link {
  display: block;
  text-decoration: none;
}
.cmplan_unit .cmplan_imgbx {
  font-size: 0;
}
.cmplan_unit .cmplan_img {
  width: 100%;
  font-size: 12px;
  vertical-align: top;
}
.cmplan_unit .cmplan_img_half {
  width: 50%;
  font-size: 12px;
  vertical-align: top;
}
.cmplan_unit .cmplan_detailbx {
  padding: 15px 20px 50px;
}
.cmplan_unit .cmplan_tagarea {
  padding-bottom: 8px;
  .cmplan_tag {
    display: inline-block;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    color: get-color('primary');
  }
}
.cmplan_unit .cmplan_service {
  display: block;
  padding: 4px 0 8px;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  color: get-color('primary');
}
.cmplan_unit .cmplan_tit {
  display: block;
  font-size: 18px;
  line-height: 25px;
  color: #222;
  letter-spacing: -0.5px;
}
.cmplan_unit .cmplan_tit2 {
  overflow: hidden;
  max-height: 66px;
  font-size: 13px;
  line-height: 22px;
  color: #777;
  letter-spacing: -0.3px;
}
/* 기획전 배너 - 컨텐츠(unit) 몰구분 있는경우 */
.cmplan_unit .cmplan_badge_area {
  margin: -22px 0 0 18px;
}
.cmplan_unit .cmplan_badge_area:after {
  display: block;
  clear: both;
  content: '';
}
.cmplan_unit .cmplan_badge_area .cm_mall_ic > i {
  border: 2px solid #fff;
  border-radius: 50%;
  background-color: #fff;
}
.cmplan_unit .cm_mall_ic {
  float: left;
  position: relative;
  z-index: 10;
  margin-right: -8px;
}
.cmplan_unit .cmplan_badge {
  float: left;
  position: relative;
  z-index: 10;
  width: 44px;
  height: 44px;
  margin-right: -8px;
  background: url(#{$imgPath}/common/sp_cmplan_201907.png) no-repeat;
}
.cmplan_unit .cmplan_badge.ssg {
  background-position: 0 0;
}
.cmplan_unit .cmplan_badge.sm {
  background-position: 0 -50px;
}
.cmplan_unit .cmplan_badge.sd {
  background-position: 0 -100px;
}
.cmplan_unit .cmplan_badge.em {
  background-position: 0 -150px;
}
.cmplan_unit .cmplan_badge.tr {
  background-position: 0 -200px;
}
.cmplan_unit .cmplan_badge.bt {
  background-position: 0 -250px;
}
.cmplan_unit .cmplan_badge.tv {
  background-position: 0 -300px;
}
.cmplan_unit .cmplan_badge.si {
  background-position: 0 -350px;
}
.cmplan_unit .cmplan_badge.sf {
  background-position: 0 -400px;
}
.cmplan_unit .cmplan_badge.hwd {
  background-position: 0 -450px;
}
.cmplan_unit .cmplan_badge.mgz {
  background-position: 0 -500px;
}
.cmplan_unit .cmplan_badge.outlet {
  background-position: 0 -550px;
}
.cmplan_unit .cmplan_badge.mnmorning {
  background-position: 0 -600px;
}
.cmplan_unit .cmplan_badge.mndeptfood {
  background-position: 0 -650px;
}
.cmplan_unit .cmplan_badge_benefit {
  float: left;
  position: relative;
  height: 40px;
  margin-right: -8px;
  padding: 0 15px;
  border-radius: 22px;
  border: 2px solid #fff;
  background: #fff;
  font-size: 25px;
  font-weight: bold;
  line-height: 40px;
  color: #222;
  letter-spacing: -1px;
}
.cmplan_unit .cmplan_badge_benefit:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 20px;
  border: 1px solid #222;
  content: '';
}
.cmplan_unit .cmplan_badge_subtx {
  display: inline-block;
  margin: 3px 0 0 2px;
  font-weight: bold;
  font-size: 15px;
  letter-spacing: 0;
  vertical-align: top;
}
.cmplan_unit .cmplan_badge_area + .cmplan_detailbx {
  padding-top: 8px;
  padding-bottom: 35px;
}
/* 기획전 배너 - 레이아웃(grid) */
.cmplan_grid {
  width: 1280px;
  margin: 0 auto;
}
.cmplan_grid .cmplan_gridlist {
  font-size: 0;
}
.cmplan_grid .cmplan_griditem {
  display: inline-block;
  font-size: 12px;
  vertical-align: top;
}
.cmplan_grid .cmplan_gridlist_w400 {
  margin-left: -40px;
}
.cmplan_grid .cmplan_gridlist_w400 .cmplan_griditem {
  width: 400px;
  margin-left: 40px;
}
.cmplan_grid .cmplan_gridlist_w300 {
  margin-left: -26px;
}
.cmplan_grid .cmplan_gridlist_w300 .cmplan_griditem {
  width: 300px;
  margin-left: 26px;
}
.cmplan_grid .cmplan_gridlist_w300 .cmplan_imgbx + .cmplan_detailbx {
  padding-top: 20px;
  padding-bottom: 55px;
}
.cmplan_grid .cmplan_gridlist_w300 .cmplan_badge_area + .cmplan_detailbx {
  padding-bottom: 45px;
}
.cmplan_grid .cmplan_gridlist_w370 .cmplan_griditem {
  width: 370px;
}
.cmplan_grid_mix:after {
  display: block;
  clear: both;
  content: '';
}
.cmplan_grid_mix .cmplan_gridlist {
  float: left;
}
.cmplan_grid_mix .cmplan_gridlist_w400 {
  width: 880px;
}
.cmplan_grid_mix .cmplan_gridlist_w370 {
  margin-left: 70px;
}
.cmplan_grid_mix .cmplan_gridlist_w370 .cmplan_griditem {
  display: block;
}
/* 기획전 배너 - 광고상품 */
.cmplan_unit_ad .cmplan_imgbx {
  position: relative;
}
.cmplan_unit_ad .tt_adinfo_n {
  right: 0;
  bottom: 0;
  left: auto;
}
.cmplan_unit_ad .tt_adinfo_n .tt_adinfo_layer {
  top: -42px;
  right: 9px;
  left: auto;
  border-color: #ccc;
  -webkit-box-shadow: 3px 5px 5px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 3px 5px 5px 0 rgba(0, 0, 0, 0.3);
}
.cmplan_unit_ad .tt_adinfo_n .tt_adinfo_layer .bg_adinfo {
  right: 12px;
  left: auto;
  width: 8px;
  height: 6px;
  background: url(#{$imgPath}/common/bg_adinfo_n2.png) no-repeat;
}
.cmplan_gridlist_w400 .cmplan_unit_ad .tt_adinfo_n {
  right: 0;
  top: 216px;
  bottom: auto;
  left: auto;
}
/* 기획전 배너 - 고해상도 대응 */
@media screen and (-webkit-min-device-pixel-ratio: 2) {
  .cmplan_unit .cmplan_badge {
    background-image: url(#{$imgPath}/common/sp_cmplan_201907@2x.png);
    background-size: 44px auto;
  }
}
