/* 이벤트 OSMU */
.evt_osmu {
  overflow: hidden;
  margin: 0 auto;
  padding-top: 15px;
  font-size: 0;
  line-height: 0;
}
.evt_osmu_lst {
  width: 1048px;
  margin: 0 0 0 -14px;
  // background: url(#{$imgPath}/common/bg_evt_osmu.gif) repeat;
  *zoom: 1;
}
.evt_osmu_unit {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  position: relative;
  float: none;
  width: 262px;
  height: auto;
  border-right: 1px dashed #cfcfcf;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  vertical-align: top;
}
.evt_osmu_unit .eo_ic {
  background-image: url(#{$imgPath}/common/sp_evt_osmu.png);
  background-repeat: no-repeat;
}
.evt_osmu_unit .eo_check {
  display: none;
  padding: 8px 14px;
}
.evt_osmu_unit .eo_check input {
  width: 14px;
  height: 14px;
  vertical-align: middle;
}
.evt_osmu_unit .eo_link {
  display: block;
  position: relative;
  padding: 0 14px;
  font-size: 13px;
  line-height: 17px;
  text-decoration: none;
  cursor: pointer;
}
.evt_osmu_unit .eo_thmb .thmb {
  display: block;
  overflow: hidden;
  width: 234px;
  height: 167px;
}
.evt_osmu_unit .eo_thmb .thmb img {
  width: 100%;
  vertical-align: top;
}
.evt_osmu_unit .eo_detail {
  padding: 10px 7px 17px;
  font-family: $fontR;
  font-weight: 400;
}
.evt_osmu_unit .eo_detail .eo_period {
  min-height: 23px;
  display: block;
  font-size: 11px;
  line-height: 23px;
}
.evt_osmu_unit .eo_detail .eo_period em {
  color: #888;
  font-family: $fontR;
  font-weight: 400;
  letter-spacing: 0.2px;
}
.evt_osmu_unit .eo_detail .eo_period .eo_ic_fin {
  display: inline-block;
  overflow: hidden;
  width: 32px;
  height: 21px;
  margin-right: 7px;
  background-position: 0 0;
  line-height: 999px;
  vertical-align: middle;
}
.evt_osmu_unit .eo_detail .eo_tit {
  height: 92px;
}
.evt_osmu_unit .eo_detail .eo_tit strong {
  display: block;
  overflow: hidden;
  max-height: 44px;
  margin: 5px 0;
  color: #222;
  font-size: 19px;
  font-weight: normal;
  line-height: 22px;
  word-break: break-all;
  word-wrap: break-word;
}
.evt_osmu_unit .eo_detail .eo_tit p {
  overflow: hidden;
  color: #888;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.evt_osmu_unit .eo_detail hr {
  display: block;
  overflow: hidden;
  width: 15px;
  height: 1px;
  margin: 0;
  *margin: -2px 0 -7px;
  padding: 0;
  border: 0;
  background-color: #919191;
  text-align: left;
  clear: both;
}
.evt_osmu_unit .eo_detail .eo_tags {
  padding-bottom: 3px;
}
.evt_osmu_unit .eo_detail .eo_tags .eo_mall {
  height: 17px;
  margin-top: 9px;
}
.evt_osmu_unit .eo_detail .eo_tags .eo_mall em {
  display: inline-block;
  color: #444;
  font-family: $fontR;
  font-weight: 400;
  font-style: italic;
  white-space: nowrap;
}
.evt_osmu_unit .eo_detail .eo_tags .eo_pub {
  display: none;
  margin-top: 9px;
}
.evt_osmu_unit .eo_detail .eo_tags .eo_pub li {
  color: #888;
  line-height: 18px;
}
.evt_osmu_unit .eo_detail .eo_tags .eo_pub li strong {
  margin-right: 5px;
  font-weight: normal;
}
.evt_osmu_unit .eo_link.pub .eo_detail .eo_tags .eo_pub {
  display: block;
}
.evt_osmu_unit .eo_ic_clip {
  position: absolute;
  right: 15px;
  bottom: 20px;
  z-index: 10;
  overflow: hidden;
  width: 28px;
  height: 27px;
  background-position: 0 -30px;
}
.evt_osmu.selective {
  position: relative;
  margin-top: 15px;
  padding: 0;
  background: url(#{$imgPath}/common/bg_evt_osmu2.gif) 0 100% repeat-x;
}
.evt_osmu.selective .evt_osmu_lst {
  margin-top: -1px;
}
.evt_osmu.selective .evt_osmu_unit {
  padding-top: 1px;
  background: url(#{$imgPath}/common/bg_evt_osmu2.gif) 0 0 repeat-x;
}
.evt_osmu.selective .eo_check {
  display: block;
}
.evt_osmu.selective .eo_ic_clip {
  display: block;
}
.evt_osmu.col3 {
  width: 758px;
}
.evt_osmu.col3 .evt_osmu_lst {
  width: 786px;
}
.evt_osmu.col5 .evt_osmu_lst {
  width: 1305px;
  background: url(#{$imgPath}/common/bg_evt_osmu3.gif) 1px 0 repeat;
}
.evt_osmu.col5 .evt_osmu_unit {
  width: 261px;
}

/* 검색결과 deal_count 숨김 */
.result_relative.hb .deal_count,
.result_relative.obj .deal_count {
  display: none;
}

/* 프로모션,기획전 종료안내 레이어 */
.ly_endpm {
  position: relative;
  z-index: 1000;
}
.ly_endpm .sp_endpm {
  background-image: url(#{$imgPath}/event/sp_endpm.png);
  background-repeat: no-repeat;
}
.ly_endpm .dimmed_endpm {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 906px;
  background: url(#{$imgPath}/event/bg_endpm.png) 0 0 repeat-x;
}
.ly_endpm .endpm_wrap {
  position: absolute;
  left: 50%;
  top: 150px;
  width: 1020px;
  margin-left: -510px;
  padding-bottom: 30px;
  background-color: #fff;
}
.ly_endpm .endpm_tit {
  width: 551px;
  height: 116px;
  margin: -29px auto 0;
}
.ly_endpm .btn_endpm_back {
  position: absolute;
  left: 33px;
  top: 26px;
  display: inline-block;
  width: 33px;
  height: 33px;
  background-position: -640px 0;
}
.ly_endpm .btn_endpm_close {
  position: absolute;
  right: 32px;
  top: 27px;
  display: inline-block;
  width: 31px;
  height: 31px;
  background-position: -680px 0;
}
.ly_endpm .evt_osmu {
  padding-top: 0;
  text-align: center;
}
.ly_endpm .evt_osmu_lst {
  width: 1020px;
  margin: 0 auto;
  background: none;
}
.ly_endpm .evt_osmu_unit {
  width: 239px;
}
.ly_endpm .evt_osmu_unit .eo_link {
  padding: 0 3px;
}
.ly_endpm .evt_osmu_unit .eo_detail {
  padding-bottom: 5px;
}
.ly_endpm .endpm_cont_tit {
  width: 953px;
  height: 20px;
  margin: 30px auto 26px;
}
.ly_endpm .endpm_cont_tit.tit_event {
  background-position: 0 -140px;
}
.ly_endpm .endpm_cont_tit.tit_benefit {
  background-position: 0 -170px;
}
.ly_endpm .anoter_btn_wrap {
  text-align: center;
}
.ly_endpm .anoter_btn_wrap a {
  display: inline-block;
  width: 237px;
  height: 55px;
  margin-left: 5px;
}
.ly_endpm .anoter_btn_wrap a:first-child {
  margin-left: 0;
}
.ly_endpm .anoter_btn_wrap .btn_another01 {
  background-position: -723px 0;
}
.ly_endpm .anoter_btn_wrap .btn_another02 {
  background-position: -723px -57px;
}
.ly_endpm.ly_endexb .endpm_wrap {
  top: 95px;
  width: 640px;
  margin-left: -320px;
}
.ly_endpm.ly_endexb .endpm_wrap.v2 {
  top: 95px;
  width: 780px;
  margin-left: -390px;
  .sp_endpm {
    background-image: url(#{$imgPath}/event/sp_endexb_v1.png);
  }
  .endpm_tit {
    width: 600px;
    height: 210px;
    margin: -30px auto 0;
    padding: 0;
    background-color: transparent;
  }
}
.ly_endpm.ly_endexb .sp_endpm {
  background-image: url(#{$imgPath}/event/sp_endexb.png);
}
.ly_endpm.ly_endexb {
  .endpm_tit {
    width: 482px;
    height: 169px;
    margin: -30px auto 0;
    padding: 0;
    background-color: transparent;
  }
}

.ly_endpm .endpm_tit_txt {
  width: 482px;
  margin: 30px auto 0;
  text-align: center;
  font-size: 35px;
  color: #222;
  line-height: 45px;
  font-family: $fontR;
  font-weight: 400;
}
.ly_endpm.ly_endexb .btn_endpm_back {
  left: 26px;
  top: 22px;
}
.ly_endpm.ly_endexb .btn_endpm_close {
  right: 22px;
  top: 23px;
}
.ly_endpm.ly_endexb .endpm_cont_tit.tit_benefit {
  width: 178px;
  height: 18px;
  margin: 17px auto 23px;
  background-position: 100% -116px;
}
