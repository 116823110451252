/* for you */
.cmforyou_sec {
  width: 1280px;
  margin-right: auto;
  margin-left: auto;
  font-family: $fontR;
  font-weight: 400;
}
.cmforyou_sec .cmforyou_item {
  height: 444px;
  background: url(#{$imgPath}/common/loading.gif) 50% 50% no-repeat;
}
.cmforyou_sec .cmforyou_item_login {
  height: 477px;
  margin-top: -10px;
}
.cmforyou_sec .cmforyou_guide {
  position: absolute;
  bottom: 0;
  width: 180px;
}
.cmforyou_sec .cmforyou_guide:before {
  display: inline-block;
  width: 40px;
  height: 40px;
  background: url(#{$imgPath}/common/sp_foryou_v2.png) no-repeat;
  vertical-align: top;
  content: '';
}
.cmforyou_sec .cmforyou_best_type:before {
  background-position: 0 0;
}
.cmforyou_sec .cmforyou_favorite_type:before {
  background-position: -50px 0;
}
.cmforyou_sec .cmforyou_cart_type:before {
  background-position: -100px 0;
}
.cmforyou_sec .cmforyou_clip_type:before {
  background-position: -150px 0;
}
.cmforyou_sec .cmforyou_question_type:before {
  background-position: 0 -50px;
}
.cmforyou_sec .cmforyou_look_type:before {
  background-position: -50px -50px;
}
.cmforyou_sec .cmforyou_notice_type:before {
  background-position: -100px -50px;
}
.cmforyou_sec .cmforyou_guide_tx {
  height: 40px;
  margin-top: 10px;
  font-family: $fontM;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #222;
  letter-spacing: -0.3px;
}
.cmforyou_sec .cmforyou_guide_tx a {
  color: #222;
}
.cmforyou_sec .cmforyou_guide_tx .point {
  color: get-color('primary');
}
.cmforyou_sec .cmforyou_del {
  position: absolute;
  top: 0;
  right: 0;
}
.cmforyou_sec .cmforyou_del_btn {
  width: 30px;
  height: 30px;
  background: url(#{$imgPath}/common/sp_foryou_v2.png) no-repeat -100px -290px;
}
.cmforyou_sec .cmforyou_last:before {
  display: block;
  width: 180px;
  height: 180px;
  background: url(#{$imgPath}/common/sp_foryou_v2.png) no-repeat 0 -100px;
  content: '';
}
.cmforyou_sec .cmforyou_last_tx1 {
  display: block;
  margin-top: 10px;
  font-size: 13px;
  line-height: 20px;
  color: #969696;
  letter-spacing: -0.4px;
  text-align: center;
}
.cmforyou_sec .cmforyou_last_tx2 {
  margin-top: 10px;
  font-size: 14px;
  line-height: 22px;
  color: #222;
  letter-spacing: -0.5px;
}
.cmforyou_sec .cmforyou_last_point {
  color: get-color('primary');
}
.cmforyou_sec .cmforyou_controls {
  width: 80px;
  margin: 30px auto 0;
}
.cmforyou_sec .cmforyou_controls:after {
  display: block;
  clear: both;
  content: '';
}
.cmforyou_sec .cmforyou_controls_prev,
.cmforyou_sec .cmforyou_controls_next {
  float: left;
  width: 40px;
  height: 40px;
  background: url(#{$imgPath}/common/sp_foryou_v2.png) no-repeat;
}
.cmforyou_sec .cmforyou_controls_prev {
  background-position: 0 -290px;
}
.cmforyou_sec .cmforyou_controls_prev:hover {
  background-position: 0 -390px;
}
.cmforyou_sec .cmforyou_controls_prev.disabled {
  background-position: 0px -340px;
}
.cmforyou_sec .cmforyou_controls_next {
  background-position: -50px -290px;
}
.cmforyou_sec .cmforyou_controls_next:hover {
  background-position: -50px -390px;
}
.cmforyou_sec .cmforyou_controls_next.disabled {
  background-position: -50px -340px;
}
/* 공통상품유닛 분기 */
.cmforyou_sec .cunit_thmb_lst {
  position: relative;
  background: #fff;
}
.cmforyou_sec .cunit_thmb_lst:before {
  position: absolute;
  bottom: 69px;
  left: 0;
  width: 100%;
  height: 1px;
  background: #e5e5e5;
  content: '';
}
.cmforyou_sec .cunit_thmb_lst > li {
  position: relative;
  height: 334px;
  padding-top: 0;
  padding-bottom: 110px;
}
.cmforyou_sec .cmforyou_item_login .cunit_thmb_lst > li {
  padding-top: 33px;
}
/* 고해상도 대응 */
@media screen and (-webkit-min-device-pixel-ratio: 2) {
  .cmforyou_sec .cmforyou_guide:before,
  .cmforyou_sec .cmforyou_last:before,
  .cmforyou_sec .cmforyou_controls_prev,
  .cmforyou_sec .cmforyou_controls_next,
  .cmforyou_sec .cmforyou_del_btn {
    background-image: url(#{$imgPath}/common/sp_foryou_v2@2x.png);
    background-size: 200px auto;
  }
}
