/* 매직픽업툴팁 */
.tt_magicpick {
  position: relative;
  z-index: 10;
  display: inline-block;
  vertical-align: top;
  height: 21px;
}
.tt_magicpick .magicpick_tip_layer {
  display: none;
  visibility: hidden;
  position: absolute;
  word-break: break-all;
  word-wrap: break-word;
  border: 1px solid #bb744b;
  color: #fff;
  background-color: #ce9266;
  background-color: rgba(203, 140, 94, 0.95);
  font-size: 12px;
  font-family: $fontR;
  font-weight: 400;
  line-height: 21px;
  padding: 14px 20px;
  max-width: 160px;
  white-space: nowrap;
}
.tt_magicpick .magicpick_tip_layer em {
  white-space: nowrap;
}
.tt_magicpick .btn_magicpick {
  width: 57px;
  height: 21px;
  background: url(#{$imgPath}/common/sp_magicpick.png) 0 0 no-repeat;
}
.tt_magicpick .btn_magicpick.on {
  background-position: 0 -50px;
}
.table_itemlist2 .tt_magicpick {
  display: inline-block;
  vertical-align: top;
  height: 21px;
}
.tl_magicpick {
  display: none;
  position: absolute;
  z-index: 1000;
}
.tl_magicpick:after {
  display: none;
  content: '';
  position: absolute;
  top: -6px;
  left: 24px;
  width: 10px;
  height: 7px;
  background: url(#{$imgPath}/common/sp_magicpick.png) 0 -100px no-repeat;
  z-index: 1001;
}
.tl_magicpick.on {
  display: block;
}
.tl_magicpick.on:after {
  display: block;
}
.tl_magicpick .tl_magicpick_lst {
  position: absolute;
  word-break: break-all;
  word-wrap: break-word;
  border: 1px solid #bb744b;
  color: #fff;
  background-color: #ce9266;
  background-color: rgba(203, 140, 94, 0.95);
  font-size: 12px;
  font-family: $fontR;
  font-weight: 400;
  line-height: 21px;
  padding: 14px 20px;
  white-space: nowrap;
}
.tl_magicpick .tl_magicpick_lst em {
  white-space: nowrap;
}
