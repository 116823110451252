/* dropdown */
.sort_box {
  width: 100%;
  position: relative;
  float: left;
  margin-top: 15px;
  z-index: 999;
}
.tmpl_drop_wrap.com {
  float: left;
  margin-right: 10px;
}
.tmpl_drop_wrap.com .tmpl_drop_opt {
  position: relative;
  height: 30px;
}
.tmpl_drop_wrap.com .tmpl_drop_opt.on {
  z-index: 100;
}
.tmpl_drop_wrap.com .tmpl_drop_select {
  display: block;
  overflow: hidden;
  position: relative;
  height: 27px;
  padding: 1px 30px 0 10px;
  border: 1px solid #e1e1e1;
  background: #fff;
  font-size: 12px;
  line-height: 27px;
  color: #666;
  cursor: pointer;
  text-decoration: none;
}
.tmpl_drop_wrap.com .tmpl_drop_select .txt {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  zoom: 1;
}
.tmpl_drop_wrap.com .tmpl_drop_select .sel_arrow {
  position: absolute;
  top: 0;
  right: 0;
  width: 29px;
  height: 28px;
  background: url(#{$imgPath}/display/search/bg_select_arr.gif) 0 0 no-repeat;
}
.tmpl_drop_wrap.com .tmpl_drop_opt.on .tmpl_drop_select .sel_arrow {
  background-position: 0 -30px;
}
.tmpl_drop_wrap.com .tmpl_drop_scroll {
  display: none;
  overflow-y: auto;
  position: absolute;
  right: 0;
  left: 0;
  top: 100%;
  max-height: 130px;
  margin-top: -1px;
  border: 1px solid #e1e1e1;
  background: #fff;
}
.tmpl_drop_wrap.com .tmpl_drop_opt.on .tmpl_drop_scroll {
  display: block;
}
.tmpl_drop_wrap.com .tmpl_drop_list li a {
  display: block;
  padding: 5px 10px;
  line-height: 16px;
  color: #666;
  text-decoration: none;
}
.tmpl_drop_wrap.com .tmpl_drop_list li a em {
  display: block;
  font-weight: bold;
}
.tmpl_drop_wrap.com .tmpl_drop_list li a:hover {
  background: #f7f7f7;
}
.tmpl_drop_wrap.com .tmpl_drop_list li.selected .txt,
.tmpl_drop_wrap.com .tmpl_drop_list li.selected em {
  font-weight: bold;
}
.tmpl_drop_wrap.com .tmpl_drop_list li.disabled .txt,
.tmpl_drop_wrap.com .tmpl_drop_list li.disabled em {
  color: #ccc;
}
