.thumdata_area {
  display: inline;
  float: left;
  position: relative;
  width: 314px;
  padding-left: 18px;
}
.thumdata_area.yes_panel {
  width: 274px;
  padding-left: 58px;
}
.thumdata_area.yes_panel .thumdata_dl {
  min-height: 110px;
  padding-bottom: 30px;
}
.thumdata_area .layer_panel {
  position: absolute;
  left: 7px;
  top: 0;
  width: 32px;
  height: 126px;
}
.thumdata_area .layer_panel a {
  display: block;
  position: relative;
  margin: -1px 0 0;
  *margin-top: -5px;
  font-size: 0;
  line-height: 0;
}
.thumdata_area .layer_panel a:first-child {
  margin: 0;
}
.thumdata_area .layer_panel img {
  vertical-align: top;
}
.thumdata_dl {
  position: relative;
  color: #666;
}
.thumdata_dl a {
  color: #666;
  text-decoration: none;
}
.thumdata_dl dt {
  margin-bottom: 8px;
  padding-top: 8px;
}
.thumdata_dl dt .tit {
  display: inline-block;
  vertical-align: middle;
}
.thumdata_dl dt .maker {
  font-weight: bold;
  color: #00aac4;
}
.thumdata_dl .oth_m {
  display: inline-block;
  margin: -7px 0 0 15px;
  font-size: 0;
  line-height: 0;
  vertical-align: middle;
}
.thumdata_dl .oth_m img {
  vertical-align: top;
}
.thumdata_dl .detail_tm .maker {
  color: #000;
}
.thumdata_dl .detail_tm .point {
  color: #ff6865;
}
.thumdata_dl .detail_tm2 {
  margin-top: 5px;
  font-size: 11px;
  line-height: 16px;
}
.thumdata_dl .detail_tm3 {
  margin-top: 18px;
  color: #747474;
}
.thumdata_dl .detail_tm3 a {
  color: #4d9eaa;
  text-decoration: none;
}
.thumdata_dl .datail_date {
  font-size: 11px;
  color: #999;
}
.thumdata_dl .price_tm {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
}
.thumdata_dl .price_tm del {
  display: inline-block;
  margin-right: 10px;
  vertical-align: top;
}
.thumdata_dl .price_tm .price_tt {
  display: inline-block;
  color: #000;
  vertical-align: top;
}
.thumdata_dl .price_tm .price_tt .ssg_price {
  display: inline-block;
  margin: -6px 0;
  font-size: 12px;
}
.thumdata_dl .special_time {
  position: absolute;
  left: 0;
  bottom: 25px;
  background: url(#{$imgPath}/common/bg_special_time.gif) #ffb401 0 0 no-repeat;
  height: 23px;
  line-height: 24px;
  color: #fff;
  font-weight: bold;
  padding: 0 10px 0 22px;
  overflow: hidden;
}
.clip_red .clip {
  background-position: -33px -31px;
}
.layer_panel {
  background: url(#{$imgPath}/common/sp_panel2.png) no-repeat;
}
.layer_panel a {
  display: block;
  width: 32px;
  height: 32px;
  margin-right: -1px;
  background: url(#{$imgPath}/common/sp_panel2.png) no-repeat;
}
.layer_panel a.view {
  background-position: -35px -35px;
}
.layer_panel a.cart {
  background-position: -70px -35px;
}
.layer_panel a.blank {
  background-position: -105px -35px;
}
.layer_panel a.clip {
  background-position: -35px -70px;
}
.layer_panel a.change {
  background-position: -140px -35px;
}
.layer_panel a.enter {
  background-position: -140px -70px;
}
.layer_panel .clip_yellow a.clip {
  background-position: -70px -70px;
}
.layer_panel .clip_red a.clip {
  background-position: -105px -70px;
}
.btn.x_small.help {
  padding-left: 18px;
  color: #666;
  background: url(#{$imgPath}/common/ico_help.gif) 0 2px no-repeat;
}
.btn.medium.facebook {
  padding-left: 40px;
  background-position: 0 -500px;
  letter-spacing: -0.05em;
}
.btn.medium.facebook span {
  width: 160px;
  padding-right: 0;
}
.btn.xx_large.clip_fan span {
  overflow: hidden;
  width: 64px;
  background-position: 100% -500px;
  text-indent: -999em;
}
.btn.xx_large.clip_wish span {
  overflow: hidden;
  width: 64px;
  background-position: 100% -600px;
  text-indent: -999em;
}
.thumbnail,
.thumbnail2 {
  margin: 0 auto;
}
.thumbnail .sale,
.thumbnail .gift,
.thumbnail .plus,
.thumbnail .reserve,
.thumbnail .traders_reserve,
.thumbnail .ssgdeliv,
.thumbnail .ssgdeliv_tr,
.thumbnail .ssgdeliv_sd,
.thumbnail .ssgpick,
.thumbnail .ssgds,
.thumbnail .special,
.thumbnail2 .sale,
.thumbnail3 .gift,
.thumbnail2 .plus,
.thumbnail2 .reserve,
.thumbnail2 .ssgds,
.thumbnail .coupon_sale,
.thumbnail2 .coupon_sale,
.thumbnail .ico_better,
.thumbnail2 .ico_better,
.thumbnail .holiday_delivery,
.thumbnail .special {
  overflow: hidden;
  display: block;
  width: 52px;
  height: 60px;
  margin-top: -12px;
  background: url(#{$imgPath}/common/sp_label.png) no-repeat;
  color: #fff;
  line-height: 60px;
  text-align: center;
}
.thumbnail .buy_always,
.thumbnail2 .buy_always {
  display: block;
  width: 93px;
  height: 29px;
  background: url(#{$imgPath}/common/sp_label.png) 0 -1800px no-repeat;
}
.thumbnail .sale,
.thumbnail2 .sale {
  height: 40px;
  padding-top: 21px;
  font-size: 24px;
  line-height: normal;
  background-position: -150px -150px;
}
.thumbnail .sale span,
.thumbnail2 .sale span {
  display: inline-block;
  *display: inline;
  vertical-align: top;
}
.thumbnail .sale .persent {
  position: relative;
  top: 9px;
  left: 1px;
  width: 11px;
  height: 10px;
  background: url(#{$imgPath}/common/sp_persent.png) no-repeat;
}
.thumbnail2 .sale .persent {
  position: relative;
  top: 9px;
  left: 1px;
  width: 11px;
  height: 10px;
  background: url(#{$imgPath}/common/sp_persent.png) no-repeat;
}
.thumbnail .coupon_sale,
.thumbnail2 .coupon_sale,
.thumbnail .special {
  height: 40px;
  padding-top: 21px;
  font-size: 24px;
  line-height: normal;
  background-position: -150px -300px;
}
.thumbnail .coupon_sale span,
.thumbnail2 .coupon_sale span,
.thumbnail .special span {
  display: inline-block;
  vertical-align: top;
  *margin-right: -3px;
}
.thumbnail .coupon_sale .persent {
  position: relative;
  top: 9px;
  left: 1px;
  width: 11px;
  height: 10px;
  background: url(#{$imgPath}/common/sp_persent.png) no-repeat;
}
.item_size450 .thumbnail .coupon_sale .persent,
.item_size450 .thumbnail .sale .persent {
  position: relative;
  top: 13px;
  left: 1px;
  width: 11px;
  height: 10px;
  background: url(#{$imgPath}/common/sp_persent.png) no-repeat;
}
.thumbnail2 .coupon_sale .persent,
.thumbnail .special .persent {
  position: relative;
  top: 21px;
  left: 1px;
  width: 11px;
  height: 10px;
  background: url(#{$imgPath}/common/sp_persent.png) no-repeat;
}
.thumbnail .special,
.thumbnail2 .special {
  height: 46px;
  padding-top: 14px;
  font-style: italic;
  font-size: 24px;
  line-height: normal;
  background-position: 0 -676px;
}
.thumbnail .gift,
.thumbnail2 .gift,
.thumbnail .point,
.thumbnail2 .point,
.thumbnail .coupon_txt,
.thumbnail .card_txt,
.thumbnail2 .coupon_txt {
  background-position: -150px -600px;
  font-size: 14px;
}
.thumbnail .ico_better,
.thumbnail2 .ico_better {
  background-position: -150px -1500px;
}
.thumbnail .plus,
.thumbnail2 .plus {
  background-position: -150px -450px;
  font-size: 24px;
  line-height: 63px;
  letter-spacing: -2px;
  padding-right: 2px;
  width: 50px;
}
.thumbnail .plus .plus1,
.thumbnail2 .plus .plus1 {
  display: inline-block;
  width: 19px;
  height: 22px;
  background: url(#{$imgPath}/common/sp_label.png) -150px -1650px no-repeat;
  vertical-align: -2px;
  margin-left: 3px;
}
.thumbnail .reserve,
.thumbnail2 .reserve {
  width: 79px;
  height: 48px;
  margin: 0 0 0 -26px;
  background-position: -150px -750px;
}
.thumbnail .reserve.bojung,
.thumbnail2 .reserve.bojung,
.thumbnail .reserve.center,
.thumbnail2 .reserve.center {
  background-position: -150px -900px;
}
.thumbnail .reserve.bojung2,
.thumbnail2 .reserve.bojung2 {
  background-position: -150px -1200px;
}
.thumbnail .reserve.bojung2 {
  width: 62px;
  height: 47px;
  background: url(#{$imgPath}/common/sp_delivery.png) 0 -100px no-repeat;
  margin: 0 0 0 -9px;
}
.thumbnail .traders_reserve,
.thumbnail2 .traders_reserve {
  width: 79px;
  height: 48px;
  margin: 0 0 0 -26px;
  background-position: -150px -1050px;
}
.thumbnail .reserve.ico_pickup,
.thumbnail2 .reserve.ico_pickup {
  width: 79px;
  height: 56px;
  background-position: -146px -1950px;
}
.thumbnail .traders_reserve.ico_pickup,
.thumbnail2 .traders_reserve.ico_pickup {
  width: 79px;
  height: 56px;
  background-position: -146px -1860px;
}
.thumbnail .ssgdeliv,
.thumbnail2 .ssgdeliv {
  width: 60px;
  height: 63px;
  margin: -5px 0 0 -7px;
  background-position: -150px -2040px;
}
.thumbnail .ssgdeliv_tr,
.thumbnail2 .ssgdeliv_tr {
  width: 60px;
  height: 63px;
  margin: -5px 0 0 -7px;
  background-position: -150px -2140px;
}
.thumbnail .ssgdeliv_sd,
.thumbnail2 .ssgdeliv_sd {
  width: 60px;
  height: 60px;
  margin: -5px 0 0 -7px;
  background-position: -150px -2340px;
}
.thumbnail .ssgpick,
.thumbnail2 .ssgpick {
  width: 60px;
  height: 60px;
  margin: -5px 0 0 -7px;
  background-position: -150px -2240px;
}
.thumbnail .ssgds,
.thumbnail2 .ssgds {
  width: 48px;
  height: 48px;
  margin: 0;
  background-position: 0 -400px;
  background-image: url(#{$imgPath}/common/label_medium.png);
}
.thumbnail .holiday_delivery,
.thumbnail2 .holiday_delivery,
.thumbnail .holiday_delivery02 {
  display: block;
  width: 50px;
  height: 50px;
  margin: 0;
  background: url(#{$imgPath}/common/sp_bdg.png) 0 -1350px no-repeat;
}
.thumbnail .soldout.adultbx,
.thumbnail2 .soldout.adultbx {
  background: none !important;
}
.thumbnail .soldout .valign,
.thumbnail2 .soldout .valign {
  display: inline-block;
  width: 1px;
  height: 100%;
  vertical-align: middle;
}
.thumbnail .soldout .tt02,
.thumbnail2 .soldout .tt02 {
  display: block;
  font-size: 21px;
}
.thumbnail .soldout .tx,
.thumbnail2 .soldout .tx {
  display: block;
  font-size: 14px;
}
.thumbnail .soldout.adultbx .tx,
.thumbnail2 .soldout.adultbx .tx {
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  font-size: 0;
  line-height: 0;
  visibility: hidden;
}
.thumbnail .soldout .date,
.thumbnail2 .soldout .date {
  display: block;
  font-size: 12px;
  color: #999;
}
.thumbnail .adultbx,
.thumbnail2 .adultbx {
  background-color: #fff;
}
.thumbnail .adultbx .adult,
.thumbnail2 .adultbx .adult {
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  font-size: 0;
  line-height: 0;
  visibility: hidden;
}
.thumbnail .adultbx2 {
  opacity: 0;
  filter: alpha(opacity=0);
}
.thumbnail .holiday_delivery_em,
.thumbnail .holiday_delivery02_em {
  display: block;
  width: 62px;
  height: 47px;
  background: url(#{$imgPath}/common/sp_delivery.png) -100px -100px no-repeat !important;
  margin-left: -12px;
}
.thumbnail .sprshp,
.thumbnail2 .sprshp {
  display: block;
  width: 54px;
  height: 55px;
  background: url(#{$imgPath}/common/sp_sprshp.png) 0 -100px no-repeat;
  margin: 0 0 0 -2px;
}
