/* 스티커 */
.ly_sticker {
  position: absolute;
  z-index: 1000;
}
.ty_sticker1 {
  left: 1020px;
  top: 15px;
}
.ty_sticker2 {
  left: 1020px;
  top: 15px;
}
.ty_sticker3 {
  left: 1020px;
  top: 15px;
}
.ty_sticker4 {
  left: 1020px;
  top: 15px;
}
.ty_sticker5 {
  left: 1020px;
  top: 15px;
}
