@use '@ssgui/icons/dist/ssgui-icons' as *;

/* 상품없는 경우 */
.cm_empty {
  min-height: 250px;
  padding: 85px 0;
  margin-top: 40px;
  box-sizing: border-box;
  &:before {
    display: block;
    margin: 0 auto 15px;
    width: 22px;
    height: 22px;
    background-image: url(#{$imgPath}/common/ico_empty.png);
    background-position: top center;
    content: '';
  }

  .cm_empty_tx {
    margin-top: 7px;
    color: #424242;
    font-size: 15px;
    line-height: normal;
    text-align: center;
  }
  @media screen and (-webkit-min-device-pixel-ratio: 2) {
    &:before {
      background-image: url(#{$imgPath}/common/ico_empty@2x.png);
      background-size: cover;
    }
  }
}

.cm_empty2 {
  padding: 60px 0;
  margin-top: 14px;
  box-sizing: border-box;
  text-align: center;
  &::before {
    display: block;
    margin: 0 auto;
    @include ssgui-svg-icon($name: $IconSsgReservation, $size: 'xl', $color: map-get($colors, 'gray400'));
    content: '';
  }

  .cm_empty_tx {
    margin-top: 4px;
    color: get-color('gray900');
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
  }

  .cm_empty_subtx {
    margin-top: 4px;
    color: get-color('gray600');
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
  }

  & + .cmmain_more2 { 
    margin-top:0
  }
}
