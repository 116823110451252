/* 해바/오반장 광고아이콘 툴팁 */
.ssg-tooltip-wrap.tt_adinfo {
  position: relative;
  z-index: 100;
  width: 51px;
  height: 22px;
}
.ssg-tooltip-wrap.tt_adinfo .btn_tt_adinfo {
  display: inline-block;
  vertical-align: top;
  width: 51px;
  height: 22px;
  background: url(#{$imgPath}/common/ico_adinfo.png) 0 0 no-repeat;
  line-height: 100px;
  overflow: hidden;
}
.ssg-tooltip-wrap.tt_adinfo .ssg-tooltip-layer {
  display: none;
  position: absolute;
  right: 0;
  top: 29px;
  padding: 7px 10px;
  border: 1px solid #d8d8d8;
  background-color: #fff;
  text-align: left;
  line-height: 16px;
  font-family: $fontR;
  font-weight: 400;
  font-size: 12px;
  color: #757575;
  white-space: nowrap;
}
.ssg-tooltip-wrap.tt_adinfo.on .ssg-tooltip-layer {
  display: block;
}
.ssg-tooltip-wrap.tt_adinfo .ssg-tooltip-layer .bg_adinfo {
  position: absolute;
  right: 20px;
  top: -8px;
  width: 13px;
  height: 8px;
  background: url(#{$imgPath}/common/bg_adinfo.png) 0 0 no-repeat;
}
.ssg-tooltip-wrap.tt_adinfo.v2 .ssg-tooltip-layer {
  right: 0;
  top: -59px;
}
.ssg-tooltip-wrap.tt_adinfo.v2 .ssg-tooltip-layer .bg_adinfo {
  top: auto;
  bottom: -8px;
  background-position: 0 -8px;
}

/* 광고아이콘 툴팁 */
.tt_adinfo_n {
  position: absolute;
  left: 5px;
  bottom: 5px;
  z-index: 50;
  width: 60px;
  height: 24px;

  &.ty_mainbn {
    position: absolute;
    top: 0;
    right: 0;
    left: initial;
    width: 45px;
    height: 20px;
    .btn_tt_adinfo {
      width: 45px;
      height: 20px;
      background: url(#{$imgPath}/common/ico_adinfo_n_v2.png) 0 0 no-repeat;
    }
  }

  &.ty_ctunitbn {
    position: absolute;
    top: initial;
    bottom: 0;
    right: 0;
    left: initial !important;
    width: 45px;
    height: 20px;
    .btn_tt_adinfo {
      width: 45px;
      height: 20px;
      background: url(#{$imgPath}/common/ico_adinfo_n_v2.png) 0 0 no-repeat;
    }
  }
}
.tt_adinfo_n .btn_tt_adinfo {
  display: inline-block;
  vertical-align: top;
  width: 60px;
  height: 24px;
  background: url(#{$imgPath}/common/ico_adinfo_n.png) 0 0 no-repeat;
  line-height: 100px;
  overflow: hidden;
}
:lang(en) .tt_adinfo_n .btn_tt_adinfo,
:lang(zh) .tt_adinfo_n .btn_tt_adinfo {
  background-position: 0 -24px;
}
.tt_adinfo_n .tt_adinfo_layer {
  display: none;
  position: absolute;
  left: 0;
  top: -56px;
  z-index: 100;
  padding: 8px 10px 7px;
  background-color: #fff;
  font-family: $fontR;
  font-weight: 400;
  font-size: 12px;
  color: #222;
  line-height: 16px;
  text-align: left;
  white-space: nowrap;
  -webkit-box-shadow: 4px 5px 17px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 4px 5px 17px 0 rgba(0, 0, 0, 0.3);
  border: 1px solid #eee;
}
.tt_adinfo_n.on .tt_adinfo_layer {
  display: block;
}
.tt_adinfo_n .tt_adinfo_layer .bg_adinfo {
  position: absolute;
  left: 15px;
  top: 100%;
  width: 12px;
  height: 9px;
  background: url(#{$imgPath}/common/bg_adinfo_n.png) 0 0 no-repeat;
}
.tt_adinfo_n.tt_disabled {
  z-index: 0;
}
.tt_adinfo_n.tt_disabled.on .tt_adinfo_layer {
  display: none;
}
