.item_size140 .thumbnail {
  width: 140px;
  height: 140px;
}
.item_size140 .thumbnail2 {
  position: relative;
  line-height: 0;
  height: 140px;
}
.item_size140 .sale,
.item_size140 .gift,
.item_size140 .plus,
.item_size140 .reserve,
.item_size140 .traders_reserve,
.item_size140 .ssgdeliv,
.item_size140 .ssgdeliv_tr,
.item_size140 .ssgdeliv_sd,
.item_size140 .ssgpick,
.item_size140 .coupon_sale,
.item_size140 .ico_better,
.item_size140 .holiday_delivery {
  width: 42px;
  height: 48px;
  margin-top: -8px;
  background-image: url(#{$imgPath}/common/sp_label.png);
  line-height: 48px;
}
.item_size140 .sale {
  height: 25px;
  padding-top: 18px;
  padding-bottom: 5px;
  font-size: 20px;
  line-height: normal;
  background-position: 0 -150px;
}
.item_size140 .sale .persent {
  top: 5px;
}
.item_size140 .coupon_sale {
  height: 25px;
  padding-top: 18px;
  padding-bottom: 5px;
  font-size: 20px;
  line-height: normal;
  background-position: 0 -300px;
}
.item_size140 .coupon_sale .persent {
  top: 5px;
}
.item_size140 .gift {
  background-position: 0 -600px;
  font-size: 12px;
  color: #fff;
  text-align: center;
}
.item_size140 .plus {
  background-position: 0 -450px;
  font-size: 19px;
  line-height: 50px;
}
.item_size140 .plus .plus1 {
  display: inline-block;
  width: 15px;
  height: 17px;
  background: url(#{$imgPath}/common/sp_label.png) 0 -1650px no-repeat;
  vertical-align: -1px;
  margin-left: 3px;
}
.item_size140 .ico_better {
  background-position: 0 -1500px;
}
.item_size140 .reserve {
  width: 62px;
  height: 38px;
  margin: 0 0 0 -24px;
  background-position: 0 -750px;
}
.item_size140 .reserve.bojung,
.item_size140 .reserve.center {
  background-position: 0 -900px;
}
.item_size140 .reserve.bojung2 {
  width: 54px;
  height: 41px;
  background: url(#{$imgPath}/common/sp_delivery.png) 0 -200px no-repeat;
  margin: 0 0 0 -16px;
}
.item_size140 .traders_reserve {
  display: block;
  width: 62px;
  height: 38px;
  margin: 0 0 0 -24px;
  background-position: 0 -1050px;
}
.item_size140 .holiday_delivery,
.item_size140 .holiday_delivery02 {
  display: block;
  width: 50px;
  height: 50px;
  margin: 0 0 0 -12px;
  background: url(#{$imgPath}/common/sp_bdg.png) 0 -1350px no-repeat;
}
.item_size140 .reserve.ico_pickup {
  width: 65px;
  height: 45px;
  background-position: 0 -1950px;
  margin-right: -3px;
}
.item_size140 .traders_reserve.ico_pickup {
  width: 65px;
  height: 45px;
  background-position: 0 -1860px;
  margin-right: -3px;
}
.item_size140 .ssgdeliv {
  display: block;
  width: 48px;
  height: 50px;
  margin: 0 0 0 -10px;
  background-position: 0 -2040px;
}
.item_size140 .ssgdeliv_tr {
  display: block;
  width: 48px;
  height: 50px;
  margin: 0 0 0 -10px;
  background-position: 0 -2140px;
}
.item_size140 .ssgdeliv_sd {
  display: block;
  width: 48px;
  height: 50px;
  margin: 0 0 0 -10px;
  background-position: 0 -2340px;
}
.item_size140 .ssgpick {
  display: block;
  width: 48px;
  height: 48px;
  margin: 0 0 0 -10px;
  background-position: 0 -2240px;
}
.item_size140 .soldout {
  background-image: url(#{$imgPath}/common/bg_soldout_140x140.png);
}
.item_size140 .soldout .date {
  color: #999;
}
.item_size140 .soldout.adultbx {
  background-image: url(#{$imgPath}/common/bg_adult_140x140.png);
}
.item_size140 .holiday_delivery_em,
.item_size140 .holiday_delivery02_em {
  display: block;
  width: 62px;
  height: 47px;
  background: url(#{$imgPath}/common/sp_delivery.png) -100px -100px no-repeat !important;
  margin-left: -24px;
}
.item_size140 .sprshp {
  width: 54px;
  height: 55px;
  margin: -2px 0 0 -16px;
}
.table_itemlist td .list_content .item_size140 .item_info .summary {
  min-height: 34px;
  height: auto;
}
.item_size140 .item_ord .item_promotion span {
  width: 50%;
}
.item_size168 .thumbnail {
  width: 168px;
  height: 168px;
}
.item_size168 .thumbnail2 {
  position: relative;
  line-height: 0;
  height: 168px;
}
.item_size168 .sale,
.item_size168 .gift,
.item_size168 .plus,
.item_size168 .reserve,
.item_size168 .traders_reserve,
.item_size168 .ssgdeliv,
.item_size168 .ssgdeliv_tr,
.item_size168 .coupon_sale,
.item_size168 .ico_better {
  width: 42px;
  height: 48px;
  margin-top: -8px;
  background-image: url(#{$imgPath}/common/sp_label.png);
  line-height: 48px;
}
.item_size168 .sale {
  height: 25px;
  padding-top: 18px;
  padding-bottom: 5px;
  font-size: 20px;
  line-height: normal;
  background-position: 0 -150px;
}
.item_size168 .sale .persent {
  top: 5px;
}
.item_size168 .coupon_sale {
  height: 25px;
  padding-top: 18px;
  padding-bottom: 5px;
  font-size: 20px;
  line-height: normal;
  background-position: 0 -300px;
}
.item_size168 .coupon_sale .persent {
  top: 6px;
}
.item_size168 .gift {
  background-position: 0 -600px;
  font-size: 12px;
  color: #fff;
  text-align: center;
}
.item_size168 .plus {
  background-position: 0 -450px;
  font-size: 19px;
  line-height: 52px;
}
.item_size168 .plus .plus1 {
  display: inline-block;
  width: 15px;
  height: 17px;
  background: url(#{$imgPath}/common/sp_label.png) 0 -1650px no-repeat;
  vertical-align: -1px;
  margin-left: 3px;
}
.item_size168 .ico_better {
  background-position: 0 -1500px;
}
.item_size168 .reserve {
  width: 62px;
  height: 38px;
  margin: 0 0 0 -24px;
  background-position: 0 -750px;
}
.item_size168 .reserve.bojung,
.item_size168 .reserve.center {
  width: 62px;
  height: 38px;
  margin: 0 0 0 -24px;
  background-position: 0 -900px;
}
.item_size168 .reserve.bojung2 {
  width: 54px;
  height: 41px;
  background: url(#{$imgPath}/common/sp_delivery.png) 0 -200px no-repeat;
  margin: 0 0 0 -16px;
}
.item_size168 .traders_reserve {
  width: 62px;
  height: 38px;
  margin: 0 0 0 -24px;
  background-position: 0 -1050px;
}
.item_size168 .reserve.ico_pickup {
  width: 65px;
  height: 45px;
  background-position: 0 -1950px;
}
.item_size168 .traders_reserve.ico_pickup {
  width: 65px;
  height: 45px;
  background-position: 0 -1860px;
}
.item_size168 .holiday_delivery {
  width: 50px;
  height: 50px;
  margin: 0 0 0 -9px;
  background-position: 0 -1350px;
}
.item_size168 .holiday_delivery_em {
  margin-left: -21px;
}
.item_size168 .ssgdeliv {
  width: 48px;
  height: 50px;
  margin: 0 0 0 -7px;
  background-position: 0 -2040px;
}
.item_size168 .ssgdeliv_tr {
  width: 48px;
  height: 50px;
  margin: 0 0 0 -7px;
  background-position: 0 -2140px;
}
.item_size168 .soldout {
  background-image: url(#{$imgPath}/common/bg_soldout_168x168.png);
}
.item_size168 .soldout .date {
  margin-top: 34px;
  color: #999;
}
.item_size168 .soldout.adultbx {
  background-image: url(#{$imgPath}/common/bg_adult_168x168.png);
}
.table_itemlist td .list_content .item_size168 .item_info .summary {
  min-height: 34px;
  height: auto;
}
.item_size168 .item_ord .item_promotion span {
  width: 50%;
}
.item_size168 .sprshp {
  margin: 0 0 0 -14px;
}
.item_size202 .thumbnail {
  width: 202px;
  height: 202px;
}
.item_size202 .item_info {
  padding: 0 2px 0 1px;
}
.item_size202 .item_ord {
  padding: 0 0 5px;
  width: 100%;
}
.itembx.item_size202 .item_promotion span {
  width: 50%;
  overflow: hidden;
}
.item_size290 .soldout .date {
  margin-top: 63px;
}
.item_size290 .soldout .tx,
.item_size350 .soldout .tx {
  font-size: 18px;
}
.item_size290 .adultbx .adult,
.item_size350 .adultbx .adult {
  width: 63px;
  height: 63px;
  background-position: -97px 0;
}
.itembx.item_size290 .item_promotion span,
.itembx.item_size350 .item_promotion span {
  width: 33%;
  overflow: hidden;
}
.item_size290 .thumbnail {
  width: 290px;
  height: 290px;
}
.item_size290 .soldout {
  background-image: url(#{$imgPath}/common/bg_soldout_290x290.png);
}
.item_size290 .soldout.adultbx {
  background-image: url(#{$imgPath}/common/bg_adult_290x290.png);
}
.item_size350 .thumbnail {
  width: 350px;
  height: 350px;
}
.item_size350 .summary {
  height: 34px;
}
.item_size350 .soldout {
  background-image: url(#{$imgPath}/common/bg_soldout_350x350.png);
}
.item_size350 .soldout.adultbx {
  background-image: url(#{$imgPath}/common/bg_adult_350x350.png);
}
.item_size350 .soldout .date {
  margin-top: 68px;
  font-size: 12px;
  font-family: $fontR;
  font-weight: 400;
  color: #999;
}
.item_size420 .thumbnail {
  width: 420px;
  height: 420px;
}
.item_size420 .thumbnail .soldout {
  background: url(#{$imgPath}/common/bg_soldout_420x420.png);
}
.item_size420 .thumbnail .soldout .date {
  margin-top: 67px;
  font-size: 16px;
}
.item_size420 .thumbnail .soldout.adultbx {
  background: url(#{$imgPath}/common/bg_adult_420x420.png);
}
.itembx.item_size420 .item_promotion span {
  width: 33%;
}
.item_size420 .sale,
.item_size420 .gift,
.item_size420 .plus,
.item_size420 .s_price {
  width: 73px;
  height: 83px;
  margin-top: -17px;
  background-image: url(#{$imgPath}/common/label_01_xxlarge.png);
  line-height: 165px;
}
.item_size420 .department {
  display: block;
  width: 67px;
  height: 67px;
  background-image: url(#{$imgPath}/common/label_02_xxlarge.png);
  line-height: 83px;
  background-position: 0 -76px;
}
.item_size420 .holiday_delivery02 {
  display: block;
  width: 67px;
  height: 67px;
  background-image: url(#{$imgPath}/common/sp_label.png);
  line-height: 83px;
  background-position: -300px -1350px;
}
.item_size420 .reserve {
  width: 108px;
  height: 67px;
  background-image: url(#{$imgPath}/common/sp_label.png);
  background-position: -300px -750px;
  margin-left: -41px;
}
.item_size420 .reserve.bojung,
.item_size420 .reserve.center {
  background: url(#{$imgPath}/common/sp_label.png) -300px -900px no-repeat !important;
}
.item_size420 .reserve.bojung2 {
  background: url(#{$imgPath}/common/sp_label.png) -300px -1200px no-repeat !important;
}
.item_size450.special .thumbnail {
  width: 450px;
  height: 450px;
}
.item_size450.special .sale,
.item_size450.special .gift,
.item_size450.special .plus,
.item_size450.special .s_price {
  width: 73px;
  height: 83px;
  margin-top: -17px;
  background-image: url(#{$imgPath}/common/sp_label.png);
  line-height: 165px;
}
.item_size450.special .reserve,
.item_size450.special .department,
.item_size450.special .holiday_delivery02 {
  width: 67px;
  height: 66px;
  background-image: url(#{$imgPath}/common/sp_label.png);
  line-height: 83px;
}
.item_size450.special .sale {
  height: 52px;
  padding-top: 31px;
  font-size: 33px;
  line-height: normal;
  background-position: -300px -150px;
}
.item_size450.special .sale .persent {
  width: 15px;
  height: 14px;
  background-position: 0 -13px;
}
.item_size450.special .gift {
  background-position: -300px -600px;
  font-size: 20px;
}
.item_size450.special .plus {
  background-position: -300px -450px;
  font-size: 33px;
}
.item_size450.special .plus .plus1 {
  display: inline-block;
  width: 25px;
  height: 30px;
  background: url(#{$imgPath}/common/sp_label.png) -300px -1650px no-repeat;
  vertical-align: -2px;
  margin-left: 5px;
}
.item_size450.special .s_price {
  background-position: 0 -706px;
  font-size: 33px;
}
.item_size450.special .soldout {
  background-image: url(#{$imgPath}/common/bg_soldout_450x450.png);
}
.item_size450.special .soldout .bx {
  width: 320px;
  margin: 0 auto;
}
.item_size450.special .soldout .tt {
  font-size: 32px;
}
.item_size450.special .soldout .tx {
  font-size: 28px;
}
.item_size450 .thumbnail {
  width: 450px;
  height: 450px;
}
.itembx.item_size450 .item_promotion span {
  width: 33%;
}
.item_size450 .sale,
.item_size450 .gift,
.item_size450 .plus,
.item_size450 .reserve,
.item_size450 .traders_reserve,
.item_size450 .ssgdeliv,
.item_size450 .ssgdeliv_tr,
.item_size450 .ssgpick,
.item_size450 .ico_better,
.item_size450 .coupon_sale,
.item_size450 .holiday_delivery {
  width: 73px;
  height: 84px;
  margin-top: -17px;
  background-image: url(#{$imgPath}/common/sp_label.png);
  line-height: 83px;
}
.item_size450 .sale {
  height: 47px;
  padding-top: 32px;
  font-size: 33px;
  line-height: normal;
  padding-bottom: 6px;
  background-position: -300px -150px;
}
.item_size450 .sale .persent {
  width: 15px;
  height: 14px;
  background-position: 0 -13px;
}
.item_size450 .coupon_sale {
  height: 49px;
  padding-top: 30px;
  font-size: 33px;
  line-height: normal;
  background-position: -300px -300px;
  padding-bottom: 6px;
}
.item_size450 .coupon_sale .persent {
  width: 15px;
  height: 14px;
  background-position: 0 -13px;
}
.item_size450 .gift {
  background-position: -300px -600px;
  font-size: 20px;
}
.item_size450 .plus {
  background-position: -300px -450px;
  font-size: 33px;
  line-height: 86px;
}
.item_size450 .plus .plus1 {
  display: inline-block;
  width: 25px;
  height: 30px;
  background: url(#{$imgPath}/common/sp_label.png) -300px -1650px no-repeat;
  vertical-align: -2px;
  margin-left: 5px;
}
.item_size450 .ico_better {
  background-position: -300px -1500px;
}
.item_size450 .reserve {
  width: 108px;
  height: 66px;
  margin: 0 0 0 -35px;
  background-position: -300px -750px;
}
.item_size450 .reserve.bojung,
.item_size450 .reserve.center {
  background-position: -300px -900px;
}
.item_size450 .reserve.bojung2 {
  width: 90px;
  height: 68px;
  background: url(#{$imgPath}/common/sp_delivery.png) 0 0 no-repeat;
  margin: 0 0 0 -17px;
}
.item_size450 .traders_reserve {
  width: 108px;
  height: 66px;
  margin: 0 0 0 -35px;
  background-position: -300px -1050px;
}
.item_size450 .reserve.ico_pickup {
  width: 108px;
  height: 76px;
  background-position: -296px -1950px;
}
.item_size450 .traders_reserve.ico_pickup {
  width: 108px;
  height: 76px;
  background-position: -296px -1860px;
}
.item_size450 .holiday_delivery {
  width: 70px;
  height: 70px;
  margin: 0 0 0 -3px;
  background: url(#{$imgPath}/common/sp_bdg.png) -100px -1350px no-repeat;
}
.item_size450 .ssgdeliv {
  width: 90px;
  height: 94px;
  margin: 0 0 0 -17px;
  background-position: -300px -2040px;
}
.item_size450 .ssgdeliv_tr {
  width: 90px;
  height: 94px;
  margin: 0 0 0 -17px;
  background-position: -300px -2140px;
}
.item_size450 .ssgdeliv_sd {
  width: 90px;
  height: 94px;
  margin: 0 0 0 -17px;
  background-position: -300px -2340px;
}
.item_size450 .ssgpick {
  width: 90px;
  height: 90px;
  margin: 0 0 0 -17px;
  background-position: -300px -2240px;
}
.item_size450 .soldout {
  background-image: url(#{$imgPath}/common/bg_soldout_450x450.png);
}
.item_size450 .soldout.adultbx {
  background-image: url(#{$imgPath}/common/bg_adult_450x450.png);
}
.item_size450 .soldout .tx {
  font-size: 26px;
}
.item_size450 .adultbx .adult {
  width: 94px;
  height: 94px;
  background-position: 0 0;
}
.item_size450 .item_info,
.item_size450 .item_ord {
  padding: 0 20px;
}
.item_size450 .holiday_delivery_em {
  width: 90px;
  height: 68px;
  background: url(#{$imgPath}/common/sp_delivery.png) -100px 0 no-repeat !important;
  margin-left: -17px;
}
.item_size450 .sprshp {
  width: 75px;
  height: 76px;
  background: url(#{$imgPath}/common/sp_sprshp.png) 0 0 no-repeat;
  margin: 0 0 0 -2px;
}
.item_size500.special .ico_right .thumbnail {
  width: 500px;
  height: 500px;
}
.item_size500.special .ico_right .holiday_delivery,
.item_size500.special .ico_right .reserve,
.item_size500.special .ico_right .traders_reserve,
.item_size500.special .ico_right .ssgdeliv,
.item_size500.special .ico_right .ssgdeliv_tr {
  width: 73px;
  height: 83px;
  margin-top: -17px;
  background-image: url(#{$imgPath}/common/sp_label.png);
  line-height: 165px;
  background-position: 0 0;
}
.item_size500.special .ico_right .holiday_delivery02 {
  display: block;
  width: 70px;
  height: 70px;
  margin: 0 0 0 -3px;
  background: url(#{$imgPath}/common/sp_bdg.png) -100px -1350px no-repeat;
}
.item_size500.special .ico_right .holiday_delivery02_em {
  display: block;
  width: 90px;
  height: 68px;
  background: url(#{$imgPath}/common/sp_delivery.png) -100px 0 no-repeat !important;
  margin-left: -17px;
}
.item_size500.special .ico_right .holiday_delivery {
  display: block;
  width: 70px;
  height: 70px;
  margin: 0 0 0 -3px;
  background: url(#{$imgPath}/common/sp_bdg.png) -100px -1350px no-repeat;
}
.item_size500.special .ico_right .reserve {
  width: 108px;
  height: 66px;
  margin-top: 0;
  margin-left: -41px;
  background-position: -300px -750px;
}
.item_size500.special .ico_right .reserve.bojung,
.item_size500.special .ico_right .reserve.center {
  background-position: -300px -900px;
}
.item_size500.special .ico_right .reserve.bojung2 {
  width: 90px;
  height: 68px;
  background: url(#{$imgPath}/common/sp_delivery.png) 0 0 no-repeat;
}
.item_size500.special .ico_right .traders_reserve {
  width: 108px;
  height: 66px;
  margin-top: 0;
  margin-left: -41px;
  background-position: -300px -1050px;
}
.item_size500.special .ico_right .ssgdeliv {
  width: 90px;
  height: 94px;
  margin: 0 0 0 -23px;
  background-position: -300px -2040px;
}
.item_size500.special .ico_right .ssgdeliv_tr {
  width: 90px;
  height: 94px;
  margin: 0 0 0 -23px;
  background-position: -300px -2140px;
}
.item_size500.special .ico_right .ssgdeliv_sd {
  width: 90px;
  height: 94px;
  margin: 0 0 0 -23px;
  background-position: -300px -2340px;
}
.search_section .item_size500.special .soldout {
  background-image: url(#{$imgPath}/common/bg_soldout_420x420.png);
}
.item_size500 .thumbnail {
  width: 450px;
  height: 450px;
}
.item_size500 .thumbnail .soldout .tx {
  content: '';
  display: block;
  width: 0;
  height: 0;
  font-size: 0;
  line-height: 0;
  visibility: hidden;
}
.itembx.item_size500 .item_promotion span {
  width: 33%;
}
.item_size500 .ico_left .sale,
.item_size500 .ico_left .gift,
.item_size500 .ico_left .plus,
.item_size500 .reserve,
.item_size500 .traders_reserve,
.item_size500 .ssgdeliv,
.item_size500 .ssgdeliv_tr,
.item_size500 .ico_left .coupon_sale,
.item_size500 .ico_left .ico_better,
.item_size500 .ico_left .holiday_delivery {
  width: 73px;
  height: 83px;
  margin-top: -17px;
  background-image: url(#{$imgPath}/common/sp_label.png);
  line-height: 83px;
}
.item_size500 .ico_left .sale {
  height: 52px;
  padding-top: 31px;
  font-size: 33px;
  line-height: normal;
  background-position: -300px -150px;
}
.item_size500 .ico_left .sale .persent {
  width: 15px;
  height: 14px;
  background-position: 0 -13px;
}
.item_size500 .ico_left .coupon_sale {
  height: 52px;
  padding-top: 31px;
  font-size: 33px;
  line-height: normal;
  background-position: -300px -300px;
}
.item_size500 .ico_left .coupon_sale .persent {
  width: 15px;
  height: 14px;
  background-position: 0 -13px;
}
.item_size500 .ico_left .gift {
  background-position: -300px -600px;
  font-size: 20px;
}
.item_size500 .ico_left .plus {
  background-position: -300px -450px;
  font-size: 33px;
}
.item_size500 .ico_left .plus .plus1 {
  display: inline-block;
  width: 25px;
  height: 30px;
  background: url(#{$imgPath}/common/sp_label.png) -300px -1650px no-repeat;
  vertical-align: -2px;
  margin-left: 5px;
}
.item_size500 .ico_better {
  background-position: -300px -1500px;
}
.item_size500 .reserve {
  width: 66px;
  height: 66px;
  margin: 0;
  background-position: -300px -750px;
}
.item_size500 .reserve.bojung,
.item_size500 .reserve.center {
  background-position: -300px -900px;
}
.item_size500 .reserve.bojung2 {
  background-position: -300px -1200px;
}
.item_size500 .traders_reserve {
  width: 66px;
  height: 66px;
  margin: 0;
  background-position: -300px -1050px;
}
.item_size500 .reserve.ico_pickup {
  width: 108px;
  height: 76px;
  background-position: -296px -1950px;
}
.item_size500 .traders_reserve.ico_pickup {
  width: 108px;
  height: 76px;
  background-position: -296px -1860px;
}
.item_size500 .ico_left .holiday_delivery {
  width: 66px;
  height: 66px;
  margin: 0;
  background-position: -300px -1350px;
}
.item_size500 .ssgdeliv {
  width: 90px;
  height: 94px;
  margin: 0;
  background-position: -300px -2040px;
}
.item_size500 .ssgdeliv_tr {
  width: 90px;
  height: 94px;
  margin: 0;
  background-position: -300px -2140px;
}
.item_size500 .soldout {
  background-image: url(#{$imgPath}/common/bg_soldout_450x450.png);
}
.item_size500 .soldout .date {
  position: absolute;
  left: 179px;
  top: 276px;
  font-size: 16px;
  margin-top: 0;
  color: #999;
  font-family: $fontR;
  font-weight: 400;
}
.item_size500 .adultbx .adult {
  width: 94px;
  height: 94px;
  background-position: 0 0;
}
.item_size500 .sprshp {
  width: 75px;
  height: 76px;
  background: url(#{$imgPath}/common/sp_sprshp.png) 0 0 no-repeat;
  margin-left: -8px;
}
/* 백화점 세일 딱지 */
.item_size168 .sd_sale,
.item_size140 .sd_sale {
  display: block;
  width: 52px;
  height: 52px;
  background: url(#{$imgPath}/common/sp_sale.png) 100% -200px no-repeat;
  margin: 3px 0 0 -17px;
}
.item_size202 .sd_sale,
.item_size290 .sd_sale,
.item_size350 .sd_sale {
  display: block;
  width: 72px;
  height: 72px;
  background: url(#{$imgPath}/common/sp_sale.png) 100% -100px no-repeat;
  margin: 0 0 0 -17px;
}
.item_size420 .sd_sale,
.item_size450 .sd_sale,
.item_size500 .sd_sale {
  display: block;
  width: 90px;
  height: 90px;
  background: url(#{$imgPath}/common/sp_sale.png) 100% 0 no-repeat;
  margin: 0 0 0 -17px;
}
.item_size140 .bdg_sale,
.item_size168 .bdg_sale {
  display: block;
  width: 70px;
  height: 71px;
  background: url(#{$imgPath}/common/spr_sale2.png) -639px -720px no-repeat;
  margin: 4px 0 0 -32px;
}
.item_size140 .bdg_sale.sl_01,
.item_size168 .bdg_sale.sl_01 {
  background-position: 0 0;
}
.item_size140 .bdg_sale.sl_02,
.item_size168 .bdg_sale.sl_02 {
  background-position: -71px 0;
}
.item_size140 .bdg_sale.sl_03,
.item_size168 .bdg_sale.sl_03 {
  background-position: -142px 0;
}
.item_size140 .bdg_sale.sl_04,
.item_size168 .bdg_sale.sl_04 {
  background-position: -213px 0;
}
.item_size140 .bdg_sale.sl_05,
.item_size168 .bdg_sale.sl_05 {
  background-position: -284px 0;
}
.item_size140 .bdg_sale.sl_06,
.item_size168 .bdg_sale.sl_06 {
  background-position: -355px 0;
}
.item_size140 .bdg_sale.sl_07,
.item_size168 .bdg_sale.sl_07 {
  background-position: -426px 0;
}
.item_size140 .bdg_sale.sl_08,
.item_size168 .bdg_sale.sl_08 {
  background-position: -497px 0;
}
.item_size140 .bdg_sale.sl_09,
.item_size168 .bdg_sale.sl_09 {
  background-position: -568px 0;
}
.item_size140 .bdg_sale.sl_10,
.item_size168 .bdg_sale.sl_10 {
  background-position: -639px 0;
}
.item_size140 .bdg_sale.sl_11,
.item_size168 .bdg_sale.sl_11 {
  background-position: 0 -80px;
}
.item_size140 .bdg_sale.sl_12,
.item_size168 .bdg_sale.sl_12 {
  background-position: -71px -80px;
}
.item_size140 .bdg_sale.sl_13,
.item_size168 .bdg_sale.sl_13 {
  background-position: -142px -80px;
}
.item_size140 .bdg_sale.sl_14,
.item_size168 .bdg_sale.sl_14 {
  background-position: -213px -80px;
}
.item_size140 .bdg_sale.sl_15,
.item_size168 .bdg_sale.sl_15 {
  background-position: -284px -80px;
}
.item_size140 .bdg_sale.sl_16,
.item_size168 .bdg_sale.sl_16 {
  background-position: -355px -80px;
}
.item_size140 .bdg_sale.sl_17,
.item_size168 .bdg_sale.sl_17 {
  background-position: -426px -80px;
}
.item_size140 .bdg_sale.sl_18,
.item_size168 .bdg_sale.sl_18 {
  background-position: -497px -80px;
}
.item_size140 .bdg_sale.sl_19,
.item_size168 .bdg_sale.sl_19 {
  background-position: -568px -80px;
}
.item_size140 .bdg_sale.sl_20,
.item_size168 .bdg_sale.sl_20 {
  background-position: -639px -80px;
}
.item_size140 .bdg_sale.sl_21,
.item_size168 .bdg_sale.sl_21 {
  background-position: 0 -160px;
}
.item_size140 .bdg_sale.sl_22,
.item_size168 .bdg_sale.sl_22 {
  background-position: -71px -160px;
}
.item_size140 .bdg_sale.sl_23,
.item_size168 .bdg_sale.sl_23 {
  background-position: -142px -160px;
}
.item_size140 .bdg_sale.sl_24,
.item_size168 .bdg_sale.sl_24 {
  background-position: -213px -160px;
}
.item_size140 .bdg_sale.sl_25,
.item_size168 .bdg_sale.sl_25 {
  background-position: -284px -160px;
}
.item_size140 .bdg_sale.sl_26,
.item_size168 .bdg_sale.sl_26 {
  background-position: -355px -160px;
}
.item_size140 .bdg_sale.sl_27,
.item_size168 .bdg_sale.sl_27 {
  background-position: -426px -160px;
}
.item_size140 .bdg_sale.sl_28,
.item_size168 .bdg_sale.sl_28 {
  background-position: -497px -160px;
}
.item_size140 .bdg_sale.sl_29,
.item_size168 .bdg_sale.sl_29 {
  background-position: -568px -160px;
}
.item_size140 .bdg_sale.sl_30,
.item_size168 .bdg_sale.sl_30 {
  background-position: -639px -160px;
}
.item_size140 .bdg_sale.sl_31,
.item_size168 .bdg_sale.sl_31 {
  background-position: 0 -240px;
}
.item_size140 .bdg_sale.sl_32,
.item_size168 .bdg_sale.sl_32 {
  background-position: -71px -240px;
}
.item_size140 .bdg_sale.sl_33,
.item_size168 .bdg_sale.sl_33 {
  background-position: -142px -240px;
}
.item_size140 .bdg_sale.sl_34,
.item_size168 .bdg_sale.sl_34 {
  background-position: -213px -240px;
}
.item_size140 .bdg_sale.sl_35,
.item_size168 .bdg_sale.sl_35 {
  background-position: -284px -240px;
}
.item_size140 .bdg_sale.sl_36,
.item_size168 .bdg_sale.sl_36 {
  background-position: -355px -240px;
}
.item_size140 .bdg_sale.sl_37,
.item_size168 .bdg_sale.sl_37 {
  background-position: -426px -240px;
}
.item_size140 .bdg_sale.sl_38,
.item_size168 .bdg_sale.sl_38 {
  background-position: -497px -240px;
}
.item_size140 .bdg_sale.sl_39,
.item_size168 .bdg_sale.sl_39 {
  background-position: -568px -240px;
}
.item_size140 .bdg_sale.sl_40,
.item_size168 .bdg_sale.sl_40 {
  background-position: -639px -240px;
}
.item_size140 .bdg_sale.sl_41,
.item_size168 .bdg_sale.sl_41 {
  background-position: 0 -320px;
}
.item_size140 .bdg_sale.sl_42,
.item_size168 .bdg_sale.sl_42 {
  background-position: -71px -320px;
}
.item_size140 .bdg_sale.sl_43,
.item_size168 .bdg_sale.sl_43 {
  background-position: -142px -320px;
}
.item_size140 .bdg_sale.sl_44,
.item_size168 .bdg_sale.sl_44 {
  background-position: -213px -320px;
}
.item_size140 .bdg_sale.sl_45,
.item_size168 .bdg_sale.sl_45 {
  background-position: -284px -320px;
}
.item_size140 .bdg_sale.sl_46,
.item_size168 .bdg_sale.sl_46 {
  background-position: -355px -320px;
}
.item_size140 .bdg_sale.sl_47,
.item_size168 .bdg_sale.sl_47 {
  background-position: -426px -320px;
}
.item_size140 .bdg_sale.sl_48,
.item_size168 .bdg_sale.sl_48 {
  background-position: -497px -320px;
}
.item_size140 .bdg_sale.sl_49,
.item_size168 .bdg_sale.sl_49 {
  background-position: -568px -320px;
}
.item_size140 .bdg_sale.sl_50,
.item_size168 .bdg_sale.sl_50 {
  background-position: -639px -320px;
}
.item_size140 .bdg_sale.sl_51,
.item_size168 .bdg_sale.sl_51 {
  background-position: 0 -400px;
}
.item_size140 .bdg_sale.sl_52,
.item_size168 .bdg_sale.sl_52 {
  background-position: -71px -400px;
}
.item_size140 .bdg_sale.sl_53,
.item_size168 .bdg_sale.sl_53 {
  background-position: -142px -400px;
}
.item_size140 .bdg_sale.sl_54,
.item_size168 .bdg_sale.sl_54 {
  background-position: -213px -400px;
}
.item_size140 .bdg_sale.sl_55,
.item_size168 .bdg_sale.sl_55 {
  background-position: -284px -400px;
}
.item_size140 .bdg_sale.sl_56,
.item_size168 .bdg_sale.sl_56 {
  background-position: -355px -400px;
}
.item_size140 .bdg_sale.sl_57,
.item_size168 .bdg_sale.sl_57 {
  background-position: -426px -400px;
}
.item_size140 .bdg_sale.sl_58,
.item_size168 .bdg_sale.sl_58 {
  background-position: -497px -400px;
}
.item_size140 .bdg_sale.sl_59,
.item_size168 .bdg_sale.sl_59 {
  background-position: -568px -400px;
}
.item_size140 .bdg_sale.sl_60,
.item_size168 .bdg_sale.sl_60 {
  background-position: -639px -400px;
}
.item_size140 .bdg_sale.sl_61,
.item_size168 .bdg_sale.sl_61 {
  background-position: 0 -480px;
}
.item_size140 .bdg_sale.sl_62,
.item_size168 .bdg_sale.sl_62 {
  background-position: -71px -480px;
}
.item_size140 .bdg_sale.sl_63,
.item_size168 .bdg_sale.sl_63 {
  background-position: -142px -480px;
}
.item_size140 .bdg_sale.sl_64,
.item_size168 .bdg_sale.sl_64 {
  background-position: -213px -480px;
}
.item_size140 .bdg_sale.sl_65,
.item_size168 .bdg_sale.sl_65 {
  background-position: -284px -480px;
}
.item_size140 .bdg_sale.sl_66,
.item_size168 .bdg_sale.sl_66 {
  background-position: -355px -480px;
}
.item_size140 .bdg_sale.sl_67,
.item_size168 .bdg_sale.sl_67 {
  background-position: -426px -480px;
}
.item_size140 .bdg_sale.sl_68,
.item_size168 .bdg_sale.sl_68 {
  background-position: -497px -480px;
}
.item_size140 .bdg_sale.sl_69,
.item_size168 .bdg_sale.sl_69 {
  background-position: -568px -480px;
}
.item_size140 .bdg_sale.sl_70,
.item_size168 .bdg_sale.sl_70 {
  background-position: -639px -480px;
}
.item_size140 .bdg_sale.sl_71,
.item_size168 .bdg_sale.sl_71 {
  background-position: 0 -560px;
}
.item_size140 .bdg_sale.sl_72,
.item_size168 .bdg_sale.sl_72 {
  background-position: -71px -560px;
}
.item_size140 .bdg_sale.sl_73,
.item_size168 .bdg_sale.sl_73 {
  background-position: -142px -560px;
}
.item_size140 .bdg_sale.sl_74,
.item_size168 .bdg_sale.sl_74 {
  background-position: -213px -560px;
}
.item_size140 .bdg_sale.sl_75,
.item_size168 .bdg_sale.sl_75 {
  background-position: -284px -560px;
}
.item_size140 .bdg_sale.sl_76,
.item_size168 .bdg_sale.sl_76 {
  background-position: -355px -560px;
}
.item_size140 .bdg_sale.sl_77,
.item_size168 .bdg_sale.sl_77 {
  background-position: -426px -560px;
}
.item_size140 .bdg_sale.sl_78,
.item_size168 .bdg_sale.sl_78 {
  background-position: -497px -560px;
}
.item_size140 .bdg_sale.sl_79,
.item_size168 .bdg_sale.sl_79 {
  background-position: -568px -560px;
}
.item_size140 .bdg_sale.sl_80,
.item_size168 .bdg_sale.sl_80 {
  background-position: -639px -560px;
}
.item_size140 .bdg_sale.sl_81,
.item_size168 .bdg_sale.sl_81 {
  background-position: 0 -640px;
}
.item_size140 .bdg_sale.sl_82,
.item_size168 .bdg_sale.sl_82 {
  background-position: -71px -640px;
}
.item_size140 .bdg_sale.sl_83,
.item_size168 .bdg_sale.sl_83 {
  background-position: -142px -640px;
}
.item_size140 .bdg_sale.sl_84,
.item_size168 .bdg_sale.sl_84 {
  background-position: -213px -640px;
}
.item_size140 .bdg_sale.sl_85,
.item_size168 .bdg_sale.sl_85 {
  background-position: -284px -640px;
}
.item_size140 .bdg_sale.sl_86,
.item_size168 .bdg_sale.sl_86 {
  background-position: -355px -640px;
}
.item_size140 .bdg_sale.sl_87,
.item_size168 .bdg_sale.sl_87 {
  background-position: -426px -640px;
}
.item_size140 .bdg_sale.sl_88,
.item_size168 .bdg_sale.sl_88 {
  background-position: -497px -640px;
}
.item_size140 .bdg_sale.sl_89,
.item_size168 .bdg_sale.sl_89 {
  background-position: -568px -640px;
}
.item_size140 .bdg_sale.sl_90,
.item_size168 .bdg_sale.sl_90 {
  background-position: -639px -640px;
}
.item_size140 .bdg_sale.sl_91,
.item_size168 .bdg_sale.sl_91 {
  background-position: 0 -720px;
}
.item_size140 .bdg_sale.sl_92,
.item_size168 .bdg_sale.sl_92 {
  background-position: -71px -720px;
}
.item_size140 .bdg_sale.sl_93,
.item_size168 .bdg_sale.sl_93 {
  background-position: -142px -720px;
}
.item_size140 .bdg_sale.sl_94,
.item_size168 .bdg_sale.sl_94 {
  background-position: -213px -720px;
}
.item_size140 .bdg_sale.sl_95,
.item_size168 .bdg_sale.sl_95 {
  background-position: -284px -720px;
}
.item_size140 .bdg_sale.sl_96,
.item_size168 .bdg_sale.sl_96 {
  background-position: -355px -720px;
}
.item_size140 .bdg_sale.sl_97,
.item_size168 .bdg_sale.sl_97 {
  background-position: -426px -720px;
}
.item_size140 .bdg_sale.sl_98,
.item_size168 .bdg_sale.sl_98 {
  background-position: -497px -720px;
}
.item_size140 .bdg_sale.sl_99,
.item_size168 .bdg_sale.sl_99 {
  background-position: -568px -720px;
}
.item_size202 .bdg_sale,
.item_size290 .bdg_sale,
.item_size350 .bdg_sale {
  display: block;
  width: 88px;
  height: 88px;
  background: url(#{$imgPath}/common/spr_sale.png) -828px -900px no-repeat;
  margin: 0 0 0 -36px;
}
.item_size202 .bdg_sale.sl_01,
.item_size290 .bdg_sale.sl_01,
.item_size350 .bdg_sale.sl_01 {
  background-position: 0 0;
}
.item_size202 .bdg_sale.sl_02,
.item_size290 .bdg_sale.sl_02,
.item_size350 .bdg_sale.sl_02 {
  background-position: -92px 0;
}
.item_size202 .bdg_sale.sl_03,
.item_size290 .bdg_sale.sl_03,
.item_size350 .bdg_sale.sl_03 {
  background-position: -184px 0;
}
.item_size202 .bdg_sale.sl_04,
.item_size290 .bdg_sale.sl_04,
.item_size350 .bdg_sale.sl_04 {
  background-position: -276px 0;
}
.item_size202 .bdg_sale.sl_05,
.item_size290 .bdg_sale.sl_05,
.item_size350 .bdg_sale.sl_05 {
  background-position: -368px 0;
}
.item_size202 .bdg_sale.sl_06,
.item_size290 .bdg_sale.sl_06,
.item_size350 .bdg_sale.sl_06 {
  background-position: -460px 0;
}
.item_size202 .bdg_sale.sl_07,
.item_size290 .bdg_sale.sl_07,
.item_size350 .bdg_sale.sl_07 {
  background-position: -552px 0;
}
.item_size202 .bdg_sale.sl_08,
.item_size290 .bdg_sale.sl_08,
.item_size350 .bdg_sale.sl_08 {
  background-position: -644px 0;
}
.item_size202 .bdg_sale.sl_09,
.item_size290 .bdg_sale.sl_09,
.item_size350 .bdg_sale.sl_09 {
  background-position: -736px 0;
}
.item_size202 .bdg_sale.sl_10,
.item_size290 .bdg_sale.sl_10,
.item_size350 .bdg_sale.sl_10 {
  background-position: -828px 0;
}
.item_size202 .bdg_sale.sl_11,
.item_size290 .bdg_sale.sl_11,
.item_size350 .bdg_sale.sl_11 {
  background-position: 0 -100px;
}
.item_size202 .bdg_sale.sl_12,
.item_size290 .bdg_sale.sl_12,
.item_size350 .bdg_sale.sl_12 {
  background-position: -92px -100px;
}
.item_size202 .bdg_sale.sl_13,
.item_size290 .bdg_sale.sl_13,
.item_size350 .bdg_sale.sl_13 {
  background-position: -184px -100px;
}
.item_size202 .bdg_sale.sl_14,
.item_size290 .bdg_sale.sl_14,
.item_size350 .bdg_sale.sl_14 {
  background-position: -276px -100px;
}
.item_size202 .bdg_sale.sl_15,
.item_size290 .bdg_sale.sl_15,
.item_size350 .bdg_sale.sl_15 {
  background-position: -368px -100px;
}
.item_size202 .bdg_sale.sl_16,
.item_size290 .bdg_sale.sl_16,
.item_size350 .bdg_sale.sl_16 {
  background-position: -460px -100px;
}
.item_size202 .bdg_sale.sl_17,
.item_size290 .bdg_sale.sl_17,
.item_size350 .bdg_sale.sl_17 {
  background-position: -552px -100px;
}
.item_size202 .bdg_sale.sl_18,
.item_size290 .bdg_sale.sl_18,
.item_size350 .bdg_sale.sl_18 {
  background-position: -644px -100px;
}
.item_size202 .bdg_sale.sl_19,
.item_size290 .bdg_sale.sl_19,
.item_size350 .bdg_sale.sl_19 {
  background-position: -736px -100px;
}
.item_size202 .bdg_sale.sl_20,
.item_size290 .bdg_sale.sl_20,
.item_size350 .bdg_sale.sl_20 {
  background-position: -828px -100px;
}
.item_size202 .bdg_sale.sl_21,
.item_size290 .bdg_sale.sl_21,
.item_size350 .bdg_sale.sl_21 {
  background-position: 0 -200px;
}
.item_size202 .bdg_sale.sl_22,
.item_size290 .bdg_sale.sl_22,
.item_size350 .bdg_sale.sl_22 {
  background-position: -92px -200px;
}
.item_size202 .bdg_sale.sl_23,
.item_size290 .bdg_sale.sl_23,
.item_size350 .bdg_sale.sl_23 {
  background-position: -184px -200px;
}
.item_size202 .bdg_sale.sl_24,
.item_size290 .bdg_sale.sl_24,
.item_size350 .bdg_sale.sl_24 {
  background-position: -276px -200px;
}
.item_size202 .bdg_sale.sl_25,
.item_size290 .bdg_sale.sl_25,
.item_size350 .bdg_sale.sl_25 {
  background-position: -368px -200px;
}
.item_size202 .bdg_sale.sl_26,
.item_size290 .bdg_sale.sl_26,
.item_size350 .bdg_sale.sl_26 {
  background-position: -460px -200px;
}
.item_size202 .bdg_sale.sl_27,
.item_size290 .bdg_sale.sl_27,
.item_size350 .bdg_sale.sl_27 {
  background-position: -552px -200px;
}
.item_size202 .bdg_sale.sl_28,
.item_size290 .bdg_sale.sl_28,
.item_size350 .bdg_sale.sl_28 {
  background-position: -644px -200px;
}
.item_size202 .bdg_sale.sl_29,
.item_size290 .bdg_sale.sl_29,
.item_size350 .bdg_sale.sl_29 {
  background-position: -736px -200px;
}
.item_size202 .bdg_sale.sl_30,
.item_size290 .bdg_sale.sl_30,
.item_size350 .bdg_sale.sl_30 {
  background-position: -828px -200px;
}
.item_size202 .bdg_sale.sl_31,
.item_size290 .bdg_sale.sl_31,
.item_size350 .bdg_sale.sl_31 {
  background-position: 0 -300px;
}
.item_size202 .bdg_sale.sl_32,
.item_size290 .bdg_sale.sl_32,
.item_size350 .bdg_sale.sl_32 {
  background-position: -92px -300px;
}
.item_size202 .bdg_sale.sl_33,
.item_size290 .bdg_sale.sl_33,
.item_size350 .bdg_sale.sl_33 {
  background-position: -184px -300px;
}
.item_size202 .bdg_sale.sl_34,
.item_size290 .bdg_sale.sl_34,
.item_size350 .bdg_sale.sl_34 {
  background-position: -276px -300px;
}
.item_size202 .bdg_sale.sl_35,
.item_size290 .bdg_sale.sl_35,
.item_size350 .bdg_sale.sl_35 {
  background-position: -368px -300px;
}
.item_size202 .bdg_sale.sl_36,
.item_size290 .bdg_sale.sl_36,
.item_size350 .bdg_sale.sl_36 {
  background-position: -460px -300px;
}
.item_size202 .bdg_sale.sl_37,
.item_size290 .bdg_sale.sl_37,
.item_size350 .bdg_sale.sl_37 {
  background-position: -552px -300px;
}
.item_size202 .bdg_sale.sl_38,
.item_size290 .bdg_sale.sl_38,
.item_size350 .bdg_sale.sl_38 {
  background-position: -644px -300px;
}
.item_size202 .bdg_sale.sl_39,
.item_size290 .bdg_sale.sl_39,
.item_size350 .bdg_sale.sl_39 {
  background-position: -736px -300px;
}
.item_size202 .bdg_sale.sl_40,
.item_size290 .bdg_sale.sl_40,
.item_size350 .bdg_sale.sl_40 {
  background-position: -828px -300px;
}
.item_size202 .bdg_sale.sl_41,
.item_size290 .bdg_sale.sl_41,
.item_size350 .bdg_sale.sl_41 {
  background-position: 0 -400px;
}
.item_size202 .bdg_sale.sl_42,
.item_size290 .bdg_sale.sl_42,
.item_size350 .bdg_sale.sl_42 {
  background-position: -92px -400px;
}
.item_size202 .bdg_sale.sl_43,
.item_size290 .bdg_sale.sl_43,
.item_size350 .bdg_sale.sl_43 {
  background-position: -184px -400px;
}
.item_size202 .bdg_sale.sl_44,
.item_size290 .bdg_sale.sl_44,
.item_size350 .bdg_sale.sl_44 {
  background-position: -276px -400px;
}
.item_size202 .bdg_sale.sl_45,
.item_size290 .bdg_sale.sl_45,
.item_size350 .bdg_sale.sl_45 {
  background-position: -368px -400px;
}
.item_size202 .bdg_sale.sl_46,
.item_size290 .bdg_sale.sl_46,
.item_size350 .bdg_sale.sl_46 {
  background-position: -460px -400px;
}
.item_size202 .bdg_sale.sl_47,
.item_size290 .bdg_sale.sl_47,
.item_size350 .bdg_sale.sl_47 {
  background-position: -552px -400px;
}
.item_size202 .bdg_sale.sl_48,
.item_size290 .bdg_sale.sl_48,
.item_size350 .bdg_sale.sl_48 {
  background-position: -644px -400px;
}
.item_size202 .bdg_sale.sl_49,
.item_size290 .bdg_sale.sl_49,
.item_size350 .bdg_sale.sl_49 {
  background-position: -736px -400px;
}
.item_size202 .bdg_sale.sl_50,
.item_size290 .bdg_sale.sl_50,
.item_size350 .bdg_sale.sl_50 {
  background-position: -828px -400px;
}
.item_size202 .bdg_sale.sl_51,
.item_size290 .bdg_sale.sl_51,
.item_size350 .bdg_sale.sl_51 {
  background-position: 0 -500px;
}
.item_size202 .bdg_sale.sl_52,
.item_size290 .bdg_sale.sl_52,
.item_size350 .bdg_sale.sl_52 {
  background-position: -92px -500px;
}
.item_size202 .bdg_sale.sl_53,
.item_size290 .bdg_sale.sl_53,
.item_size350 .bdg_sale.sl_53 {
  background-position: -184px -500px;
}
.item_size202 .bdg_sale.sl_54,
.item_size290 .bdg_sale.sl_54,
.item_size350 .bdg_sale.sl_54 {
  background-position: -276px -500px;
}
.item_size202 .bdg_sale.sl_55,
.item_size290 .bdg_sale.sl_55,
.item_size350 .bdg_sale.sl_55 {
  background-position: -368px -500px;
}
.item_size202 .bdg_sale.sl_56,
.item_size290 .bdg_sale.sl_56,
.item_size350 .bdg_sale.sl_56 {
  background-position: -460px -500px;
}
.item_size202 .bdg_sale.sl_57,
.item_size290 .bdg_sale.sl_57,
.item_size350 .bdg_sale.sl_57 {
  background-position: -552px -500px;
}
.item_size202 .bdg_sale.sl_58,
.item_size290 .bdg_sale.sl_58,
.item_size350 .bdg_sale.sl_58 {
  background-position: -644px -500px;
}
.item_size202 .bdg_sale.sl_59,
.item_size290 .bdg_sale.sl_59,
.item_size350 .bdg_sale.sl_59 {
  background-position: -736px -500px;
}
.item_size202 .bdg_sale.sl_60,
.item_size290 .bdg_sale.sl_60,
.item_size350 .bdg_sale.sl_60 {
  background-position: -828px -500px;
}
.item_size202 .bdg_sale.sl_61,
.item_size290 .bdg_sale.sl_61,
.item_size350 .bdg_sale.sl_61 {
  background-position: 0 -600px;
}
.item_size202 .bdg_sale.sl_62,
.item_size290 .bdg_sale.sl_62,
.item_size350 .bdg_sale.sl_62 {
  background-position: -92px -600px;
}
.item_size202 .bdg_sale.sl_63,
.item_size290 .bdg_sale.sl_63,
.item_size350 .bdg_sale.sl_63 {
  background-position: -184px -600px;
}
.item_size202 .bdg_sale.sl_64,
.item_size290 .bdg_sale.sl_64,
.item_size350 .bdg_sale.sl_64 {
  background-position: -276px -600px;
}
.item_size202 .bdg_sale.sl_65,
.item_size290 .bdg_sale.sl_65,
.item_size350 .bdg_sale.sl_65 {
  background-position: -368px -600px;
}
.item_size202 .bdg_sale.sl_66,
.item_size290 .bdg_sale.sl_66,
.item_size350 .bdg_sale.sl_66 {
  background-position: -460px -600px;
}
.item_size202 .bdg_sale.sl_67,
.item_size290 .bdg_sale.sl_67,
.item_size350 .bdg_sale.sl_67 {
  background-position: -552px -600px;
}
.item_size202 .bdg_sale.sl_68,
.item_size290 .bdg_sale.sl_68,
.item_size350 .bdg_sale.sl_68 {
  background-position: -644px -600px;
}
.item_size202 .bdg_sale.sl_69,
.item_size290 .bdg_sale.sl_69,
.item_size350 .bdg_sale.sl_69 {
  background-position: -736px -600px;
}
.item_size202 .bdg_sale.sl_70,
.item_size290 .bdg_sale.sl_70,
.item_size350 .bdg_sale.sl_70 {
  background-position: -828px -600px;
}
.item_size202 .bdg_sale.sl_71,
.item_size290 .bdg_sale.sl_71,
.item_size350 .bdg_sale.sl_71 {
  background-position: 0 -700px;
}
.item_size202 .bdg_sale.sl_72,
.item_size290 .bdg_sale.sl_72,
.item_size350 .bdg_sale.sl_72 {
  background-position: -92px -700px;
}
.item_size202 .bdg_sale.sl_73,
.item_size290 .bdg_sale.sl_73,
.item_size350 .bdg_sale.sl_73 {
  background-position: -184px -700px;
}
.item_size202 .bdg_sale.sl_74,
.item_size290 .bdg_sale.sl_74,
.item_size350 .bdg_sale.sl_74 {
  background-position: -276px -700px;
}
.item_size202 .bdg_sale.sl_75,
.item_size290 .bdg_sale.sl_75,
.item_size350 .bdg_sale.sl_75 {
  background-position: -368px -700px;
}
.item_size202 .bdg_sale.sl_76,
.item_size290 .bdg_sale.sl_76,
.item_size350 .bdg_sale.sl_76 {
  background-position: -460px -700px;
}
.item_size202 .bdg_sale.sl_77,
.item_size290 .bdg_sale.sl_77,
.item_size350 .bdg_sale.sl_77 {
  background-position: -552px -700px;
}
.item_size202 .bdg_sale.sl_78,
.item_size290 .bdg_sale.sl_78,
.item_size350 .bdg_sale.sl_78 {
  background-position: -644px -700px;
}
.item_size202 .bdg_sale.sl_79,
.item_size290 .bdg_sale.sl_79,
.item_size350 .bdg_sale.sl_79 {
  background-position: -736px -700px;
}
.item_size202 .bdg_sale.sl_80,
.item_size290 .bdg_sale.sl_80,
.item_size350 .bdg_sale.sl_80 {
  background-position: -828px -700px;
}
.item_size202 .bdg_sale.sl_81,
.item_size290 .bdg_sale.sl_81,
.item_size350 .bdg_sale.sl_81 {
  background-position: 0 -800px;
}
.item_size202 .bdg_sale.sl_82,
.item_size290 .bdg_sale.sl_82,
.item_size350 .bdg_sale.sl_82 {
  background-position: -92px -800px;
}
.item_size202 .bdg_sale.sl_83,
.item_size290 .bdg_sale.sl_83,
.item_size350 .bdg_sale.sl_83 {
  background-position: -184px -800px;
}
.item_size202 .bdg_sale.sl_84,
.item_size290 .bdg_sale.sl_84,
.item_size350 .bdg_sale.sl_84 {
  background-position: -276px -800px;
}
.item_size202 .bdg_sale.sl_85,
.item_size290 .bdg_sale.sl_85,
.item_size350 .bdg_sale.sl_85 {
  background-position: -368px -800px;
}
.item_size202 .bdg_sale.sl_86,
.item_size290 .bdg_sale.sl_86,
.item_size350 .bdg_sale.sl_86 {
  background-position: -460px -800px;
}
.item_size202 .bdg_sale.sl_87,
.item_size290 .bdg_sale.sl_87,
.item_size350 .bdg_sale.sl_87 {
  background-position: -552px -800px;
}
.item_size202 .bdg_sale.sl_88,
.item_size290 .bdg_sale.sl_88,
.item_size350 .bdg_sale.sl_88 {
  background-position: -644px -800px;
}
.item_size202 .bdg_sale.sl_89,
.item_size290 .bdg_sale.sl_89,
.item_size350 .bdg_sale.sl_89 {
  background-position: -736px -800px;
}
.item_size202 .bdg_sale.sl_90,
.item_size290 .bdg_sale.sl_90,
.item_size350 .bdg_sale.sl_90 {
  background-position: -828px -800px;
}
.item_size202 .bdg_sale.sl_91,
.item_size290 .bdg_sale.sl_91,
.item_size350 .bdg_sale.sl_91 {
  background-position: 0 -900px;
}
.item_size202 .bdg_sale.sl_92,
.item_size290 .bdg_sale.sl_92,
.item_size350 .bdg_sale.sl_92 {
  background-position: -92px -900px;
}
.item_size202 .bdg_sale.sl_93,
.item_size290 .bdg_sale.sl_93,
.item_size350 .bdg_sale.sl_93 {
  background-position: -184px -900px;
}
.item_size202 .bdg_sale.sl_94,
.item_size290 .bdg_sale.sl_94,
.item_size350 .bdg_sale.sl_94 {
  background-position: -276px -900px;
}
.item_size202 .bdg_sale.sl_95,
.item_size290 .bdg_sale.sl_95,
.item_size350 .bdg_sale.sl_95 {
  background-position: -368px -900px;
}
.item_size202 .bdg_sale.sl_96,
.item_size290 .bdg_sale.sl_96,
.item_size350 .bdg_sale.sl_96 {
  background-position: -460px -900px;
}
.item_size202 .bdg_sale.sl_97,
.item_size290 .bdg_sale.sl_97,
.item_size350 .bdg_sale.sl_97 {
  background-position: -552px -900px;
}
.item_size202 .bdg_sale.sl_98,
.item_size290 .bdg_sale.sl_98,
.item_size350 .bdg_sale.sl_98 {
  background-position: -644px -900px;
}
.item_size202 .bdg_sale.sl_99,
.item_size290 .bdg_sale.sl_99,
.item_size350 .bdg_sale.sl_99 {
  background-position: -736px -900px;
}
.item_size420 .bdg_sale,
.item_size450 .bdg_sale,
.item_size500 .bdg_sale {
  display: block;
  width: 106px;
  height: 105px;
  background: url(#{$imgPath}/common/spr_detail_sale.png) -990px -1080px no-repeat;
  margin: 0 0 0 -33px;
}
.item_size420 .bdg_sale.sl_01,
.item_size450 .bdg_sale.sl_01,
.item_size500 .bdg_sale.sl_01 {
  background-position: 0 0;
}
.item_size420 .bdg_sale.sl_02,
.item_size450 .bdg_sale.sl_02,
.item_size500 .bdg_sale.sl_02 {
  background-position: -110px 0;
}
.item_size420 .bdg_sale.sl_03,
.item_size450 .bdg_sale.sl_03,
.item_size500 .bdg_sale.sl_03 {
  background-position: -220px 0;
}
.item_size420 .bdg_sale.sl_04,
.item_size450 .bdg_sale.sl_04,
.item_size500 .bdg_sale.sl_04 {
  background-position: -330px 0;
}
.item_size420 .bdg_sale.sl_05,
.item_size450 .bdg_sale.sl_05,
.item_size500 .bdg_sale.sl_05 {
  background-position: -440px 0;
}
.item_size420 .bdg_sale.sl_06,
.item_size450 .bdg_sale.sl_06,
.item_size500 .bdg_sale.sl_06 {
  background-position: -550px 0;
}
.item_size420 .bdg_sale.sl_07,
.item_size450 .bdg_sale.sl_07,
.item_size500 .bdg_sale.sl_07 {
  background-position: -660px 0;
}
.item_size420 .bdg_sale.sl_08,
.item_size450 .bdg_sale.sl_08,
.item_size500 .bdg_sale.sl_08 {
  background-position: -770px 0;
}
.item_size420 .bdg_sale.sl_09,
.item_size450 .bdg_sale.sl_09,
.item_size500 .bdg_sale.sl_09 {
  background-position: -880px 0;
}
.item_size420 .bdg_sale.sl_10,
.item_size450 .bdg_sale.sl_10,
.item_size500 .bdg_sale.sl_10 {
  background-position: -990px 0;
}
.item_size420 .bdg_sale.sl_11,
.item_size450 .bdg_sale.sl_11,
.item_size500 .bdg_sale.sl_11 {
  background-position: 0 -120px;
}
.item_size420 .bdg_sale.sl_12,
.item_size450 .bdg_sale.sl_12,
.item_size500 .bdg_sale.sl_12 {
  background-position: -110px -120px;
}
.item_size420 .bdg_sale.sl_13,
.item_size450 .bdg_sale.sl_13,
.item_size500 .bdg_sale.sl_13 {
  background-position: -220px -120px;
}
.item_size420 .bdg_sale.sl_14,
.item_size450 .bdg_sale.sl_14,
.item_size500 .bdg_sale.sl_14 {
  background-position: -330px -120px;
}
.item_size420 .bdg_sale.sl_15,
.item_size450 .bdg_sale.sl_15,
.item_size500 .bdg_sale.sl_15 {
  background-position: -440px -120px;
}
.item_size420 .bdg_sale.sl_16,
.item_size450 .bdg_sale.sl_16,
.item_size500 .bdg_sale.sl_16 {
  background-position: -550px -120px;
}
.item_size420 .bdg_sale.sl_17,
.item_size450 .bdg_sale.sl_17,
.item_size500 .bdg_sale.sl_17 {
  background-position: -660px -120px;
}
.item_size420 .bdg_sale.sl_18,
.item_size450 .bdg_sale.sl_18,
.item_size500 .bdg_sale.sl_18 {
  background-position: -770px -120px;
}
.item_size420 .bdg_sale.sl_19,
.item_size450 .bdg_sale.sl_19,
.item_size500 .bdg_sale.sl_19 {
  background-position: -880px -120px;
}
.item_size420 .bdg_sale.sl_20,
.item_size450 .bdg_sale.sl_20,
.item_size500 .bdg_sale.sl_20 {
  background-position: -990px -120px;
}
.item_size420 .bdg_sale.sl_21,
.item_size450 .bdg_sale.sl_21,
.item_size500 .bdg_sale.sl_21 {
  background-position: 0 -240px;
}
.item_size420 .bdg_sale.sl_22,
.item_size450 .bdg_sale.sl_22,
.item_size500 .bdg_sale.sl_22 {
  background-position: -110px -240px;
}
.item_size420 .bdg_sale.sl_23,
.item_size450 .bdg_sale.sl_23,
.item_size500 .bdg_sale.sl_23 {
  background-position: -220px -240px;
}
.item_size420 .bdg_sale.sl_24,
.item_size450 .bdg_sale.sl_24,
.item_size500 .bdg_sale.sl_24 {
  background-position: -330px -240px;
}
.item_size420 .bdg_sale.sl_25,
.item_size450 .bdg_sale.sl_25,
.item_size500 .bdg_sale.sl_25 {
  background-position: -440px -240px;
}
.item_size420 .bdg_sale.sl_26,
.item_size450 .bdg_sale.sl_26,
.item_size500 .bdg_sale.sl_26 {
  background-position: -550px -240px;
}
.item_size420 .bdg_sale.sl_27,
.item_size450 .bdg_sale.sl_27,
.item_size500 .bdg_sale.sl_27 {
  background-position: -660px -240px;
}
.item_size420 .bdg_sale.sl_28,
.item_size450 .bdg_sale.sl_28,
.item_size500 .bdg_sale.sl_28 {
  background-position: -770px -240px;
}
.item_size420 .bdg_sale.sl_29,
.item_size450 .bdg_sale.sl_29,
.item_size500 .bdg_sale.sl_29 {
  background-position: -880px -240px;
}
.item_size420 .bdg_sale.sl_30,
.item_size450 .bdg_sale.sl_30,
.item_size500 .bdg_sale.sl_30 {
  background-position: -990px -240px;
}
.item_size420 .bdg_sale.sl_31,
.item_size450 .bdg_sale.sl_31,
.item_size500 .bdg_sale.sl_31 {
  background-position: 0 -360px;
}
.item_size420 .bdg_sale.sl_32,
.item_size450 .bdg_sale.sl_32,
.item_size500 .bdg_sale.sl_32 {
  background-position: -110px -360px;
}
.item_size420 .bdg_sale.sl_33,
.item_size450 .bdg_sale.sl_33,
.item_size500 .bdg_sale.sl_33 {
  background-position: -220px -360px;
}
.item_size420 .bdg_sale.sl_34,
.item_size450 .bdg_sale.sl_34,
.item_size500 .bdg_sale.sl_34 {
  background-position: -330px -360px;
}
.item_size420 .bdg_sale.sl_35,
.item_size450 .bdg_sale.sl_35,
.item_size500 .bdg_sale.sl_35 {
  background-position: -440px -360px;
}
.item_size420 .bdg_sale.sl_36,
.item_size450 .bdg_sale.sl_36,
.item_size500 .bdg_sale.sl_36 {
  background-position: -550px -360px;
}
.item_size420 .bdg_sale.sl_37,
.item_size450 .bdg_sale.sl_37,
.item_size500 .bdg_sale.sl_37 {
  background-position: -660px -360px;
}
.item_size420 .bdg_sale.sl_38,
.item_size450 .bdg_sale.sl_38,
.item_size500 .bdg_sale.sl_38 {
  background-position: -770px -360px;
}
.item_size420 .bdg_sale.sl_39,
.item_size450 .bdg_sale.sl_39,
.item_size500 .bdg_sale.sl_39 {
  background-position: -880px -360px;
}
.item_size420 .bdg_sale.sl_40,
.item_size450 .bdg_sale.sl_40,
.item_size500 .bdg_sale.sl_40 {
  background-position: -990px -360px;
}
.item_size420 .bdg_sale.sl_41,
.item_size450 .bdg_sale.sl_41,
.item_size500 .bdg_sale.sl_41 {
  background-position: 0 -480px;
}
.item_size420 .bdg_sale.sl_42,
.item_size450 .bdg_sale.sl_42,
.item_size500 .bdg_sale.sl_42 {
  background-position: -110px -480px;
}
.item_size420 .bdg_sale.sl_43,
.item_size450 .bdg_sale.sl_43,
.item_size500 .bdg_sale.sl_43 {
  background-position: -220px -480px;
}
.item_size420 .bdg_sale.sl_44,
.item_size450 .bdg_sale.sl_44,
.item_size500 .bdg_sale.sl_44 {
  background-position: -330px -480px;
}
.item_size420 .bdg_sale.sl_45,
.item_size450 .bdg_sale.sl_45,
.item_size500 .bdg_sale.sl_45 {
  background-position: -440px -480px;
}
.item_size420 .bdg_sale.sl_46,
.item_size450 .bdg_sale.sl_46,
.item_size500 .bdg_sale.sl_46 {
  background-position: -550px -480px;
}
.item_size420 .bdg_sale.sl_47,
.item_size450 .bdg_sale.sl_47,
.item_size500 .bdg_sale.sl_47 {
  background-position: -660px -480px;
}
.item_size420 .bdg_sale.sl_48,
.item_size450 .bdg_sale.sl_48,
.item_size500 .bdg_sale.sl_48 {
  background-position: -770px -480px;
}
.item_size420 .bdg_sale.sl_49,
.item_size450 .bdg_sale.sl_49,
.item_size500 .bdg_sale.sl_49 {
  background-position: -880px -480px;
}
.item_size420 .bdg_sale.sl_50,
.item_size450 .bdg_sale.sl_50,
.item_size500 .bdg_sale.sl_50 {
  background-position: -990px -480px;
}
.item_size420 .bdg_sale.sl_51,
.item_size450 .bdg_sale.sl_51,
.item_size500 .bdg_sale.sl_51 {
  background-position: 0 -600px;
}
.item_size420 .bdg_sale.sl_52,
.item_size450 .bdg_sale.sl_52,
.item_size500 .bdg_sale.sl_52 {
  background-position: -110px -600px;
}
.item_size420 .bdg_sale.sl_53,
.item_size450 .bdg_sale.sl_53,
.item_size500 .bdg_sale.sl_53 {
  background-position: -220px -600px;
}
.item_size420 .bdg_sale.sl_54,
.item_size450 .bdg_sale.sl_54,
.item_size500 .bdg_sale.sl_54 {
  background-position: -330px -600px;
}
.item_size420 .bdg_sale.sl_55,
.item_size450 .bdg_sale.sl_55,
.item_size500 .bdg_sale.sl_55 {
  background-position: -440px -600px;
}
.item_size420 .bdg_sale.sl_56,
.item_size450 .bdg_sale.sl_56,
.item_size500 .bdg_sale.sl_56 {
  background-position: -550px -600px;
}
.item_size420 .bdg_sale.sl_57,
.item_size450 .bdg_sale.sl_57,
.item_size500 .bdg_sale.sl_57 {
  background-position: -660px -600px;
}
.item_size420 .bdg_sale.sl_58,
.item_size450 .bdg_sale.sl_58,
.item_size500 .bdg_sale.sl_58 {
  background-position: -770px -600px;
}
.item_size420 .bdg_sale.sl_59,
.item_size450 .bdg_sale.sl_59,
.item_size500 .bdg_sale.sl_59 {
  background-position: -880px -600px;
}
.item_size420 .bdg_sale.sl_60,
.item_size450 .bdg_sale.sl_60,
.item_size500 .bdg_sale.sl_60 {
  background-position: -990px -600px;
}
.item_size420 .bdg_sale.sl_61,
.item_size450 .bdg_sale.sl_61,
.item_size500 .bdg_sale.sl_61 {
  background-position: 0 -720px;
}
.item_size420 .bdg_sale.sl_62,
.item_size450 .bdg_sale.sl_62,
.item_size500 .bdg_sale.sl_62 {
  background-position: -110px -720px;
}
.item_size420 .bdg_sale.sl_63,
.item_size450 .bdg_sale.sl_63,
.item_size500 .bdg_sale.sl_63 {
  background-position: -220px -720px;
}
.item_size420 .bdg_sale.sl_64,
.item_size450 .bdg_sale.sl_64,
.item_size500 .bdg_sale.sl_64 {
  background-position: -330px -720px;
}
.item_size420 .bdg_sale.sl_65,
.item_size450 .bdg_sale.sl_65,
.item_size500 .bdg_sale.sl_65 {
  background-position: -440px -720px;
}
.item_size420 .bdg_sale.sl_66,
.item_size450 .bdg_sale.sl_66,
.item_size500 .bdg_sale.sl_66 {
  background-position: -550px -720px;
}
.item_size420 .bdg_sale.sl_67,
.item_size450 .bdg_sale.sl_67,
.item_size500 .bdg_sale.sl_67 {
  background-position: -660px -720px;
}
.item_size420 .bdg_sale.sl_68,
.item_size450 .bdg_sale.sl_68,
.item_size500 .bdg_sale.sl_68 {
  background-position: -770px -720px;
}
.item_size420 .bdg_sale.sl_69,
.item_size450 .bdg_sale.sl_69,
.item_size500 .bdg_sale.sl_69 {
  background-position: -880px -720px;
}
.item_size420 .bdg_sale.sl_70,
.item_size450 .bdg_sale.sl_70,
.item_size500 .bdg_sale.sl_70 {
  background-position: -990px -720px;
}
.item_size420 .bdg_sale.sl_71,
.item_size450 .bdg_sale.sl_71,
.item_size500 .bdg_sale.sl_71 {
  background-position: 0 -840px;
}
.item_size420 .bdg_sale.sl_72,
.item_size450 .bdg_sale.sl_72,
.item_size500 .bdg_sale.sl_72 {
  background-position: -110px -840px;
}
.item_size420 .bdg_sale.sl_73,
.item_size450 .bdg_sale.sl_73,
.item_size500 .bdg_sale.sl_73 {
  background-position: -220px -840px;
}
.item_size420 .bdg_sale.sl_74,
.item_size450 .bdg_sale.sl_74,
.item_size500 .bdg_sale.sl_74 {
  background-position: -330px -840px;
}
.item_size420 .bdg_sale.sl_75,
.item_size450 .bdg_sale.sl_75,
.item_size500 .bdg_sale.sl_75 {
  background-position: -440px -840px;
}
.item_size420 .bdg_sale.sl_76,
.item_size450 .bdg_sale.sl_76,
.item_size500 .bdg_sale.sl_76 {
  background-position: -550px -840px;
}
.item_size420 .bdg_sale.sl_77,
.item_size450 .bdg_sale.sl_77,
.item_size500 .bdg_sale.sl_77 {
  background-position: -660px -840px;
}
.item_size420 .bdg_sale.sl_78,
.item_size450 .bdg_sale.sl_78,
.item_size500 .bdg_sale.sl_78 {
  background-position: -770px -840px;
}
.item_size420 .bdg_sale.sl_79,
.item_size450 .bdg_sale.sl_79,
.item_size500 .bdg_sale.sl_79 {
  background-position: -880px -840px;
}
.item_size420 .bdg_sale.sl_80,
.item_size450 .bdg_sale.sl_80,
.item_size500 .bdg_sale.sl_80 {
  background-position: -990px -840px;
}
.item_size420 .bdg_sale.sl_81,
.item_size450 .bdg_sale.sl_81,
.item_size500 .bdg_sale.sl_81 {
  background-position: 0 -960px;
}
.item_size420 .bdg_sale.sl_82,
.item_size450 .bdg_sale.sl_82,
.item_size500 .bdg_sale.sl_82 {
  background-position: -110px -960px;
}
.item_size420 .bdg_sale.sl_83,
.item_size450 .bdg_sale.sl_83,
.item_size500 .bdg_sale.sl_83 {
  background-position: -220px -960px;
}
.item_size420 .bdg_sale.sl_84,
.item_size450 .bdg_sale.sl_84,
.item_size500 .bdg_sale.sl_84 {
  background-position: -330px -960px;
}
.item_size420 .bdg_sale.sl_85,
.item_size450 .bdg_sale.sl_85,
.item_size500 .bdg_sale.sl_85 {
  background-position: -440px -960px;
}
.item_size420 .bdg_sale.sl_86,
.item_size450 .bdg_sale.sl_86,
.item_size500 .bdg_sale.sl_86 {
  background-position: -550px -960px;
}
.item_size420 .bdg_sale.sl_87,
.item_size450 .bdg_sale.sl_87,
.item_size500 .bdg_sale.sl_87 {
  background-position: -660px -960px;
}
.item_size420 .bdg_sale.sl_88,
.item_size450 .bdg_sale.sl_88,
.item_size500 .bdg_sale.sl_88 {
  background-position: -770px -960px;
}
.item_size420 .bdg_sale.sl_89,
.item_size450 .bdg_sale.sl_89,
.item_size500 .bdg_sale.sl_89 {
  background-position: -880px -960px;
}
.item_size420 .bdg_sale.sl_90,
.item_size450 .bdg_sale.sl_90,
.item_size500 .bdg_sale.sl_90 {
  background-position: -990px -960px;
}
.item_size420 .bdg_sale.sl_91,
.item_size450 .bdg_sale.sl_91,
.item_size500 .bdg_sale.sl_91 {
  background-position: 0 -1080px;
}
.item_size420 .bdg_sale.sl_92,
.item_size450 .bdg_sale.sl_92,
.item_size500 .bdg_sale.sl_92 {
  background-position: -110px -1080px;
}
.item_size420 .bdg_sale.sl_93,
.item_size450 .bdg_sale.sl_93,
.item_size500 .bdg_sale.sl_93 {
  background-position: -220px -1080px;
}
.item_size420 .bdg_sale.sl_94,
.item_size450 .bdg_sale.sl_94,
.item_size500 .bdg_sale.sl_94 {
  background-position: -330px -1080px;
}
.item_size420 .bdg_sale.sl_95,
.item_size450 .bdg_sale.sl_95,
.item_size500 .bdg_sale.sl_95 {
  background-position: -440px -1080px;
}
.item_size420 .bdg_sale.sl_96,
.item_size450 .bdg_sale.sl_96,
.item_size500 .bdg_sale.sl_96 {
  background-position: -550px -1080px;
}
.item_size420 .bdg_sale.sl_97,
.item_size450 .bdg_sale.sl_97,
.item_size500 .bdg_sale.sl_97 {
  background-position: -660px -1080px;
}
.item_size420 .bdg_sale.sl_98,
.item_size450 .bdg_sale.sl_98,
.item_size500 .bdg_sale.sl_98 {
  background-position: -770px -1080px;
}
.item_size420 .bdg_sale.sl_99,
.item_size450 .bdg_sale.sl_99,
.item_size500 .bdg_sale.sl_99 {
  background-position: -880px -1080px;
}
.dep5_size140 .itembx .price_unit .applied em,
.dep3_size140 .itembx .price_unit .applied em {
  font-size: 18px;
}
.price_date strong {
  float: left;
  font-weight: normal;
  color: #999;
}
.price_date em {
  float: right;
  color: #666;
}
.price_date em em,
.price_date em span {
  float: none;
}
.price_date dt,
.price_date p {
  overflow: hidden;
  background: url(#{$imgPath}/common/line_dot.gif) repeat-x 0 100%;
}
.price_date dt strong,
.price_date p strong {
  font-weight: bold;
  font-size: 12px;
  color: #272727;
}
.price_date dt {
  padding: 9px 12px 9px;
  margin-bottom: -1px;
}
.price_date dt.sd_sale_price_last {
  background: none;
  padding-bottom: 8px;
}
.price_date ul {
  padding: 6px 12px 4px;
}
.price_date li {
  overflow: hidden;
  margin: 0;
  line-height: 20px;
}
.price_date p {
  padding: 12px 12px 9px;
  background-position: 0 0;
  background-color: #f6f6f6;
  font-weight: bold;
}
.price_date p em {
  color: #222;
}
.price_date.active {
  display: block;
}
.table_itemlist2 .price_unit {
  position: relative;
}
.table_itemlist2 .price_unit .price_date.active {
  display: block;
  z-index: 9999;
}
.table_itemlist2 .ic_section {
  overflow: hidden;
  margin-bottom: -5px;
}
.table_itemlist2 .buy_always {
  display: block;
  width: 93px;
  height: 29px;
  background: url(#{$imgPath}/common/sp_label.png) 0 -1800px no-repeat;
}
.itembx,
.itembx a {
  color: #666;
  text-decoration: none;
}
.itembx img {
  vertical-align: top;
}
.itembx .check_item {
  display: none;
  position: relative;
  height: 18px;
  margin-bottom: -18px;
  z-index: 9999;
}
.itembx .check_item .lst_check {
  position: absolute;
  left: -18px;
  top: -18px;
}
.itembx .thumbnail {
  position: relative;
  line-height: 0;
}
.itembx .thumbnail a {
  display: inline-block;
  position: relative;
}
.itembx .thumbnail a.soldoutlnk {
  display: block;
  position: static;
}
.itembx .thumbnail .ico_left {
  position: absolute;
  top: -3px;
  left: -8px;
  z-index: 2;
}
.itembx .thumbnail .ico_right {
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 2;
}
.itembx .thumbnail .ico_br {
  position: absolute;
  bottom: 0px;
  right: 0;
}
.itembx_clip {
  padding: 0;
  *zoom: 1;
}
.itembx_clip:after {
  display: block;
  clear: both;
  content: '';
}
.itembx_clip .thumbnail {
  display: inline;
  float: left;
  line-height: 0;
}
.itembx_clip .thumbnail img {
  vertical-align: top;
}
.itembx_clip .thumbnail .ico_right {
  right: -6px;
  top: -6px;
}
.itembx_clip .thumbnail .store_reserve {
  width: 38px;
  height: 38px;
  background: url(#{$imgPath}/@temp/temp_04s.png);
}
.clip_recomlist li {
  padding: 20px 0;
  border-bottom: 1px solid #e9e9e9;
  vertical-align: top;
}

.mobile_version {
  width: 1020px;
  margin: 10px auto 0;
}
.mobile_version a {
  display: block;
  height: 110px;
  padding-top: 2px;
  background: #fff;
  border: 1px solid #c3c3c3;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  box-shadow: inset 0 -8px 0 0 #f0f0f0;
  font-weight: bold;
  font-size: 36px;
  color: #666;
  line-height: 110px;
  text-align: center;
  text-decoration: none;
}

.thumbnail .soldout,
.thumbnail2 .soldout {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: url(#{$imgPath}/common/bg_itemlst_soldout.png);
  color: #222;
  line-height: normal;
  text-align: center;
  z-index: 1;
}
.thumbnail .soldout.adultbx,
.thumbnail2 .soldout.adultbx {
  height: 100%;
}
.item_size500.special .soldout {
  background: none;
}
.thumbnail .soldout .tt,
.thumbnail .soldout .tt2,
.thumbnail2 .soldout .tt,
.thumbnail2 .soldout .tt2 {
  display: block;
  color: #fff;
  font-size: 0;
  font-family: $fontR;
  font-weight: 400;
  line-height: 0;
  text-indent: -9999px;
  font-weight: normal;
}
.thumbnail .soldout .valign,
.thumbnail2 .soldout .valign {
  display: none;
}
.thumbnail .soldout .bx,
.thumbnail2 .soldout .bx {
  display: inline-block;
  width: 100%;
  position: relative;
  vertical-align: bottom;
}
.itembx .soldout .date,
.thumbnail .soldout .date,
.thumbnail2 .soldout .date {
  margin: 0 0 7px;
  color: #fff;
  font-family: $fontR;
  font-weight: 400;
}
.itembx .soldout .date em,
.thumbnail .soldout .date em,
.thumbnail2 .soldout .date em {
  color: #fffc12;
}
.item_size500.special .soldout .date {
  margin: 0;
  color: #999;
}
.itembx .soldout .tt {
  height: 36px;
  background: url(#{$imgPath}/common/tx_soldout2_202.png) no-repeat 50% 50%;
}
.itembx .soldout .tt2 {
  height: 36px;
  background: url(#{$imgPath}/common/tx_soldout_500.png) no-repeat 50% 50%;
}
.item_size70 .soldout .tt2 {
  height: 20px;
  background: url(#{$imgPath}/common/tx_soldout_70.png) no-repeat 50% 50%;
  padding: 0;
}
.item_size70 .soldout .tt {
  height: 20px;
  background: url(#{$imgPath}/common/tx_soldout2_70.png) no-repeat 50% 50%;
  padding: 0;
}
.item_size140 .soldout .tt,
.item_size140 .soldout .tt2 {
  height: auto;
  padding: 5px 0 4px;
  background: none;
  font-size: 16px;
  line-height: 18px;
  text-indent: 0;
}
.item_size168 .soldout .tt {
  height: 29px;
  background: url(#{$imgPath}/common/tx_soldout2_202.png) no-repeat 50% 50%;
}
.item_size168 .soldout .tt2 {
  height: 29px;
  background: url(#{$imgPath}/common/tx_soldout_202.png) no-repeat 50% 50%;
}
.item_size202 .soldout .tt {
  height: 36px;
  background: url(#{$imgPath}/common/tx_soldout2_202.png) no-repeat 50% 50%;
}
.item_size202 .soldout .tt2 {
  height: 36px;
  background: url(#{$imgPath}/common/tx_soldout_202.png) no-repeat 50% 50%;
}
.thumbnail2 .gift_msg .tt {
  display: block;
  padding: 5px 0 4px;
  color: #fff;
  font-size: 16px;
  font-family: $fontR;
  font-weight: 400;
  line-height: 18px;
}
.thumbnail2 .gift_done {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: url(#{$imgPath}/common/bg_itemlst_giftdone.png);
  text-align: center;
  z-index: 1;
}
.thumbnail2 .gift_done strong {
  display: inline-block;
  padding: 5px 0 4px;
  color: #fff;
  font-size: 16px;
  font-family: $fontR;
  line-height: 18px;
  font-weight: 400;
}
.item_size168 .soldout.soldout_l .tt,
.item_size168 .soldout.soldout_l .tt2,
.item_size202 .soldout.soldout_l .tt,
.item_size202 .soldout.soldout_l .tt2 {
  background-position: 11px 50%;
}
