@use '@ssgui/icons/dist/ssgui-icons' as *;
/* 개인정보처리방침 */
.agreement_of_utilization {
  padding-top: 0px;
  font-family: $fontR;
  font-weight: 400;
}
.agreement_of_utilization ol,
.agreement_of_utilization ul {
  list-style: none;
}
.agreement_of_utilization .title {
  margin-top: 50px;
}
.agreement_of_utilization .title h3 {
  font-size: 30px;
  letter-spacing: -1px;
  line-height: 36px;
}
.agreement_of_utilization .title p {
  color: #666;
  font-size: 12px;
  line-height: 18px;
  margin-top: 15px;
}
.agreement_of_utilization .title p:first-child {
  margin-top: 0;
}
.agreement_of_utilization .index {
  position: relative;
  overflow: hidden;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 23px 0 23px;
  background: url(#{$imgPath}/common/bg_ua_title.gif) no-repeat;
  margin-bottom: 40px;
}
.agreement_of_utilization .index div {
  float: left;
  overflow: hidden;
  width: 345px;
}
.agreement_of_utilization .index div .dt {
  padding-left: 10px;
  background: url(#{$imgPath}/common/bg_dot.gif) no-repeat 0 8px;
  line-height: 24px;
}
.agreement_of_utilization .index div a {
  text-decoration: none;
  color: #000;
}
.agreement_of_utilization .index div a:hover {
  text-decoration: underline;
  font-weight: bold;
}
.agreement_of_utilization .index div.dl3 {
  width: 330px;
}
.agreement_of_utilization .index .bg_index {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 1020px;
  height: 4px;
  background: url(#{$imgPath}/common/bg_ua_title.gif) no-repeat;
}
.agreement_of_utilization .index2 div {
  width: 50%;
}
.agreement_of_utilization .section {
  margin-top: 30px;
}
.agreement_of_utilization h4 {
  padding-bottom: 13px;
  font-size: 13px;
  font-weight: bold;
  line-height: 19px;
}
.agreement_of_utilization .ua_box {
  border: 1px solid #efefef;
  border-radius: 4px;
  margin: 20px 15px;
  overflow-x: hidden;
}
.agreement_of_utilization .ua_box p,
.agreement_of_utilization .ua_box ol,
.agreement_of_utilization .ua_box ul {
  font-size: 12px;
  line-height: 18px;
  color: #7d7d7d;
}
.agreement_of_utilization .ua_box .ua_span {
  font-size: 11px;
  line-height: 18px;
  color: #7d7d7d;
}
.agreement_of_utilization .ua_box p strong {
  font-weight: normal;
}
.agreement_of_utilization .ua_box ol a {
  font-size: 11px;
  line-height: 18px;
  color: #7d7d7d;
}
.agreement_of_utilization .ua_box ol ol {
  margin-left: 13px;
}
.agreement_of_utilization .ua_box .ua_table {
  width: calc(100% + 1px);
  text-align: center;
  margin: 5px 0 20px -1px;
}
.agreement_of_utilization .ua_box .ua_table.ua_left td {
  text-align: left;
  padding-left: 10px;
}
.agreement_of_utilization .ua_box .ua_table th {
  height: 40px;
  border-top: 1px solid #666;
  border-bottom: 1px solid #e8e8e8;
  background-color: #f9f9f9;
}
.agreement_of_utilization .ua_box .ua_table td {
  padding: 20px 0;
  border-left: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
}
.agreement_of_utilization .ua_box .ua_table td span.in {
  color: #7d7d7d;
  font-size: 12px;
  line-height: 26px;
  letter-spacing: -1px;
}
.agreement_of_utilization .ua_box .ua_table td span.in strong {
  color: #222;
}
.agreement_of_utilization .ua_box .ua_table td.bdr_left {
  border-left: 1px solid #e8e8e8;
}
.agreement_of_utilization .ua_box .ua_table td a {
  color: #7d7d7d;
}
.ssg-modal-mask {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1000;
  background-color: #fff;
  opacity: 0.8;
  filter: alpha(opacity=80);
}
.agreement_of_utilization .ua_box2 .ua_table th {
  border-left: 1px solid #e8e8e8;
}
.agreement_of_utilization .ua_box2 .ua_table tr:first-child th:first-child {
  border-left: 0 none;
}
.agreement_of_utilization .section.simple_type {
  margin-top: 0;
}
.agreement_of_utilization .simple_type .ua_box {
  border: 0;
  border-radius: 0;
}

/* 개인정보 공통 */
.common_privacy {
  width: 1020px;
  margin: 0 auto;
  font-family: $fontR;
  font-weight: 400;
}
.common_privacy ol,
.common_privacy ul {
  list-style: none;
}
.common_privacy .title {
  margin-top: 45px;
}
.common_privacy .title h3 {
  font-size: 30px;
  letter-spacing: -1px;
  line-height: 36px;
}
.common_privacy .title p {
  color: #666;
  font-size: 12px;
  line-height: 18px;
  margin-top: 15px;
}
.common_privacy .title p:first-child {
  margin-top: 0;
}
.common_privacy .index {
  position: relative;
  overflow: hidden;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 23px 0 23px;
  background: url(#{$imgPath}/common/bg_ua_title.gif) no-repeat;
  margin-bottom: 40px;
}
.common_privacy .index div {
  float: left;
  overflow: hidden;
  width: 345px;
}
.common_privacy .index div .dt {
  padding-left: 10px;
  background: url(#{$imgPath}/common/bg_dot.gif) no-repeat 0 8px;
  line-height: 24px;
}
.common_privacy .index div a {
  text-decoration: none;
  color: #000;
}
.common_privacy .index div a:hover {
  text-decoration: underline;
  font-weight: bold;
}
.common_privacy .index div.dl3 {
  width: 330px;
}
.common_privacy .index .bg_index {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 1020px;
  height: 4px;
  background: url(#{$imgPath}/common/bg_ua_title.gif) no-repeat;
}
.common_privacy .index2 div {
  width: 50%;
}
.common_privacy .section {
  margin-top: 30px;
}
.common_privacy h4 {
  padding-bottom: 13px;
  font-size: 13px;
  font-weight: bold;
  line-height: 19px;
}
.common_privacy .ua_box {
  border: 1px solid #efefef;
  border-radius: 4px;
  padding: 20px 15px;
}
.common_privacy .ua_box p,
.common_privacy .ua_box ol,
.common_privacy .ua_box ul,
.common_privacy .ua_box dl {
  font-size: 12px;
  line-height: 18px;
  color: #7d7d7d;
}
.common_privacy .ua_box .ua_span {
  display: block;
  margin: -15px 0 15px;
  font-size: 12px;
  line-height: 18px;
  color: #7d7d7d;
}
.common_privacy .ua_box p strong {
  font-weight: normal;
}
.common_privacy .ua_box ol a {
  font-size: 12px;
  line-height: 18px;
  color: #7d7d7d;
}
.common_privacy .ua_box .ua_table {
  width: 100%;
  text-align: center;
  margin: 5px 0 20px;
  text-indent: 0;
}
.common_privacy .ua_box .ua_table.ua_left td {
  text-align: left;
  padding-left: 10px;
}
.common_privacy .ua_box .ua_table th {
  height: 40px;
  border-top: 1px solid #666;
  border-bottom: 1px solid #e8e8e8;
  background-color: #f9f9f9;
}
.common_privacy .ua_box .ua_table td {
  padding: 20px 0;
  border-left: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
}
.common_privacy .ua_box .ua_table td span.in {
  color: #7d7d7d;
  font-size: 12px;
  line-height: 26px;
  letter-spacing: -1px;
}
.common_privacy .ua_box .ua_table td span.in strong {
  color: #222;
}
.common_privacy .ua_box .ua_table td:first-child {
  border-left: none;
}
.common_privacy .ua_box .ua_table td.bdr_left {
  border-left: 1px solid #e8e8e8;
}
.common_privacy .ua_box .ua_table td a {
  color: #7d7d7d;
  text-decoration: none;
}
.common_privacy .ua_box2 .ua_table {
  border-top: 1px solid #666;
}
.common_privacy .ua_box2 .ua_table th {
  border-top: 0 none;
  border-left: 1px solid #e8e8e8;
}
.common_privacy .ua_box2 .ua_table tr:first-child th:first-child {
  border-left: 0 none;
}
.tab_privacy {
  height: 41px;
  width: 1020px;
  margin: 40px auto 0 auto;
}
.tab_privacy ul {
  background-image: url(#{$imgPath}/common_layout/tab_privacy.gif);
}
.tab_privacy ul:after {
  content: '';
  display: block;
  clear: both;
}
.tab_privacy ul.tab_sgg {
  background-position: 0 0;
}
.tab_privacy ul.tab_sm {
  background-position: 0 -50px;
}
.tab_privacy ul.tab_em {
  background-position: 0 -100px;
}
.tab_privacy ul li {
  float: left;
  height: 41px;
  width: 203px;
}
.tab_privacy ul li:first-child {
  width: 204px;
}
.tab_privacy ul li a {
  display: block;
  width: 100%;
  height: 41px;
}
.privacy_tab .tab_sgg_v {
  background: url(#{$imgPath}/common/bg_tab.gif) repeat-x 0 100%;
  zoom: 1;
}
.privacy_tab {
  width: 1020px;
  margin: 40px auto 0 auto;
}
.privacy_tab .tab_sgg_v li {
  float: left;
  height: 37px;
  width: 340px;
  margin-left: -1px;
  background: #f8f8f8;
}
.privacy_tab .tab_sgg_v li:first-child {
  margin-left: 0;
  width: 342px;
}
.privacy_tab .tab_sgg_v li a {
  display: block;
  padding: 9px 0 9px;
  border: 1px solid #c4c4c4;
  border-bottom: 0;
  font-family: $fontR;
  font-weight: 400;
  font-size: 16px;
  color: #100f0f;
  text-align: center;
  text-decoration: none;
}
.privacy_tab .tab_sgg_v li.active {
  position: relative;
  height: 39px;
  z-index: 2;
}
.privacy_tab .tab_sgg_v li.active a {
  position: relative;
  overflow: hidden;
  padding: 9px 0 11px;
  margin-top: -1px;
  border: 2px solid #777;
  border-bottom: 0;
  background: #fff;
}
.privacy_tab .tab_sgg_v:after {
  content: '';
  display: block;
  clear: both;
}

/* PC/M 공통 개인정보형UI */
.common_term {
  width: 1020px;
  margin: 0 auto;
  font-family: $fontR;
  font-weight: 400;
}
.common_term a {
  color: #7d7d7d;
  text-decoration: underline;
}
.common_term ul {
  list-style: none;
}
.common_term .title {
  margin-top: 45px;
}
.common_term .title h3 {
  font-size: 30px;
  letter-spacing: -1px;
  line-height: 36px;
}
.common_term .title p {
  color: #666;
  font-size: 12px;
  line-height: 18px;
  margin-top: 15px;
}
.common_term .title p:first-child {
  margin-top: 0;
}
.common_term .index {
  position: relative;
  overflow: hidden;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 23px 0 23px;
  background: url(#{$imgPath}/common/bg_ua_title.gif) no-repeat;
  margin-bottom: 40px;
}
.common_term .index div {
  float: left;
  overflow: hidden;
  width: 345px;
}
.common_term .index div .dt {
  padding-left: 10px;
  background: url(#{$imgPath}/common/bg_dot.gif) no-repeat 0 8px;
  line-height: 24px;
}
.common_term .index div a {
  text-decoration: none;
  color: #000;
}
.common_term .index div a:hover {
  text-decoration: underline;
  font-weight: bold;
}
.common_term .index div.dl3 {
  width: 330px;
}
.common_term .index .bg_index {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 1020px;
  height: 4px;
  background: url(#{$imgPath}/common/bg_ua_title.gif) no-repeat;
}
.common_term .index2 div {
  width: 50%;
}
.common_term .section {
  margin-top: 30px;
}
.common_term h4 {
  padding-bottom: 13px;
  font-size: 13px;
  font-weight: bold;
  line-height: 19px;
}
.common_term h5 {
  padding-bottom: 13px;
  font-size: 13px;
  font-weight: bold;
  line-height: 19px;
}
.common_term .ua_box {
  border: 1px solid #efefef;
  border-radius: 4px;
  padding: 8px 15px;
}
.common_term .ua_box p,
.common_term .ua_box ul {
  font-size: 12px;
  line-height: 18px;
  color: #7d7d7d;
}
.common_term .ua_box ul {
  margin-top: 13px;
}
.common_term .ua_box ul ul ul ul {
  margin-top: 0;
}
.common_term .ua_box p {
  margin: 13px 0;
  text-indent: 0;
}
.common_term .ua_box .default_type {
  padding-left: 14px;
  text-indent: -14px;
}
.common_term .ua_box .circlednum_type {
  padding-left: 16px;
  text-indent: -16px;
}
.common_term .ua_box .hyphen_type {
  padding-left: 11px;
  text-indent: -11px;
}
.common_term .ua_box .korean_type {
  padding-left: 18px;
  text-indent: -18px;
}
.common_term .ua_box .bracket_type {
  padding-left: 10px;
}
.common_term .ua_box li,
.common_term .ua_box .default_type li,
.common_term .ua_box .circlednum_type li {
  margin: 13px 0;
}
.common_term .ua_box .hyphen_type li,
.common_term .ua_box .korean_type li,
.common_term .ua_box .bracket_type li {
  margin: 0;
}
.common_term .ua_box .korean_type .hyphen_type {
  margin-bottom: 13px;
}
.common_term .ua_box .ua_span {
  display: block;
  margin: -15px 0 15px;
  font-size: 11px;
  line-height: 18px;
  color: #7d7d7d;
}
.common_term .ua_box .ua_table {
  width: 100%;
  text-align: center;
  margin: 5px 0 20px;
  text-indent: 0;
}
.common_term .ua_box .ua_table.ua_left td {
  text-align: left;
  padding-left: 10px;
  padding-right: 10px;
}
.common_term .ua_table .important_terms_text {
  text-decoration: underline;
  font-size: 16px;
}
.common_term .ua_box .ua_table th {
  height: 30px;
  border: 1px solid #e8e8e8;
  border-top-color: #666;
  background-color: #f9f9f9;
}
.common_term .ua_box .ua_table td {
  padding: 10px 0;
  border: 1px solid #e8e8e8;
}
/* .common_term .ua_box .ua_table td:first-child{border-left:none}
.common_term .ua_box .ua_table td.bdr_left{border-left:1px solid #e8e8e8} */
.common_term .ua_box2 .ua_table {
  border-top: 1px solid #666;
}
.common_term .ua_box2 .ua_table th {
  border-top: 0 none;
  border-left: 1px solid #e8e8e8;
}
.common_term .ua_box2 .ua_table tr:first-child th:first-child {
  border-left: 0 none;
}

.cm_privacy_tx {
  font-size: 1.25em;
  letter-spacing: -0.05em;
  text-decoration: underline;
  .cm_point {
    color: get-color('primary') !important;
  }
}

/* 개인정보 처리방침, SSG.COM 이용약관, 전자금융거래 이용약관 - 이전약관 보기 셀렉트 */
.common_term_bt_area {
  width: 1020px;
  margin: 30px auto 0;
}

/* 개인정보 처리방침, 이용약관 마크업 표준화 확대 적용 (동의 내용보기, 모바일) 상단 목차 부분 공통화 */
.agreement_of_utilization.common_term {
  width: auto !important;
  padding: 10px !important;
}

.cpp_index {
  overflow: hidden;
  position: relative;
  margin: 10px 0 40px;
  padding: 23px 0 23px;
  background: url(#{$imgPath}/common/bg_ua_title.gif) repeat-x;
}

.cpp_index .cpp_index_lst {
  display: flex;
  flex-flow: row wrap;
}

.cpp_index .cpp_index_lst > li {
  flex: 0 0 33.33%;
  padding-left: 10px;
  background: url(#{$imgPath}/common/bg_dot.gif) no-repeat 0 8px;
  line-height: 24px;
  box-sizing: border-box;
}

.cpp_index .cpp_index_lst a {
  text-decoration: none;
  color: #000;
}
.cpp_index .cpp_index_lst a:hover {
  text-decoration: underline;
  font-weight: bold;
}
.cpp_index .cpp_index_lst.dl3 {
  width: 330px;
}
.cpp_index .bg_index {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 4px;
  background: url(#{$imgPath}/common/bg_ua_title.gif) repeat-x;
}

@media screen and (min-width: 320px) and (max-width: 749px) {
  .cpp_index .cpp_index_lst > li {
    flex: 0 0 100%;
  }
}

@media screen and (min-width: 750px) and (max-width: 1019px) {
  .cpp_index .cpp_index_lst > li {
    flex: 0 0 50%;
  }
}

/* 2024.06 개편 */
.privacy_container {
  margin: 0 auto;
  line-height: 18px;
  max-width: 1020px;
}
.privacy_title {
  font-size: 30px;
  font-weight: 700;
  line-height: normal;
  margin: 40px 0 0;
}
.privacy_list {
  margin: 30px 0 0;
  li {
    margin-top: 10px;
    color: #888;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
  }
}

.privacy_labeling {
  margin: 80px 0 0;
  &_title {
    text-align: center;
    font-weight: bold;
    font-size: 15px;
    margin-bottom: 10px;
  }
  &_subtext {
    font-size: 13px;
    color: #888;
    margin: 10px 0 0;
  }
  > ul {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 28px;
    margin: 20px 0 0;
    > li {
      position: relative;
      text-align: center;
      &:nth-of-type(n + 4) {
        margin-top: 0;
      }
      &:nth-last-of-type(1) {
        .privacy_labeling_tooltip {
          right: 0;
        }
      }
      a {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        padding: 20px;
        margin: auto;
        cursor: pointer;
        outline: revert;
        & + .privacy_labeling_tooltip {
          position: absolute;
          top: calc(100% + 10px);
          transition: all 0.3s ease;
          color: #333;
          text-align: left;
          opacity: 0;
          visibility: hidden;
          z-index: 2;
        }
        &:hover + .privacy_labeling_tooltip,
        &:focus + .privacy_labeling_tooltip {
          opacity: 1;
          visibility: visible;
        }
        strong {
          display: block;
          width: 100%;
          margin-top: 20px;
          font-size: 15px;
          font-weight: 700;
        }

        img {
          width: 69.5px;
          height: 80px;
          display: block;
          margin: auto;
        }
      }
      .privacy_labeling_tooltip {
        width: 300px;
        padding: 20px;
        box-sizing: border-box;
        border: 1px solid #969696;
        background-color: #fff;
        font-size: 11px;
        font-weight: 400;
        color: #222;
        p {
          & + p {
            margin: 10px 0 0;
          }
        }
        .type_list {
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          gap: 6px 0;
          li {
            display: inline-flex;
            word-break: keep-all;
            margin-right: 20px;
            img {
              width: 16px;
              height: 16px;
              margin-right: 4px;
            }
          }
          & + p {
            margin: 10px 0 0;
          }
        }
      }
    }
  }
  &_tip {
    margin: 20px 0 0;
    color: #222;
    text-align: center;
    font-size: 13px;
  }
}
.privacy_labeling_summary {
  overflow: hidden;
  margin: 80px 0 0;
  padding: 16px 40px;
  li {
    float: left;
    width: 50%;
    padding: 16px 0;
    border-bottom: 1px solid #e5e5e5;
    position: relative;
    display: flex;
    align-items: center;
    font-size: 15px;
    box-sizing: border-box;
    color: #222;
    cursor: pointer;
    &:nth-last-child(1),
    &:nth-last-child(2) {
      border: 0;
    }

    .summary_text {
      display: flex;
      align-items: center;
    }
    strong {
      margin-right: 10px;
      font-weight: normal;
    }
    img {
      width: 35px;
      height: 40px;
      margin-right: 6px;
    }
    &:nth-last-child(1) {
      border: 0;
    }
  }
}

.privacy_accordion {
  margin-top: 50px;
  line-height: 18px;
  border-bottom: 1px solid #d9d9d9;
  .is-active {
    .accordion_q {
      border-top: 1px solid #444;
      &:after {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }
}
.accordion_q {
  position: relative;
  padding: 20px;
  border-top: 1px solid #d9d9d9;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  color: #333;
  gap: 10px;
  display: flex;
  .accordion_privacy_img img {
    width: 35px;
    height: 40px;
  }
}
.accordion_q:after {
  display: block;
  content: '';
  position: absolute;
  top: 50%;
  right: 20px;
  width: 24px;
  height: 24px;
  transition: all 0.3s ease;
  transform: translateY(-50%);
  @include ssgui-svg-icon($name: $IconSsgChevronDownMedium, $size: 'xl');
}
.accordion_a {
  display: none;
  padding: 20px;
  color: #333;
  font-size: 12px;
  font-weight: 400;
  strong {
    display: block;
    font-weight: bold;
  }
  p {
    margin-bottom: 10px;
    &:only-child,
    &:last-child {
      margin: 0;
    }
  }
}

.privacy_tbl {
  width: 100%;
  margin: 15px 0;
  th,
  td {
    padding: 10px;
    border: 1px solid #ccc;
  }
  th {
    background-color: #e5e5e5;
  }
  a {
    color: #0044cc;
    word-break: break-all;
  }
}
.privacy_form_group {
  margin: 30px 0 0;
  select {
    padding: 10px 40px 10px 10px;
    border: 1px solid #444;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cmask id='mask0_74_4306' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'%3E%3Crect width='24' height='24' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_74_4306)'%3E%3Cpath d='M12 15L7 10H17L12 15Z' fill='%231C1B1F'/%3E%3C/g%3E%3C/svg%3E");
    background-position: center right 10px;
    background-repeat: no-repeat;
    appearance: none;
    font-size: 12px;
    font-weight: 400;
    color: #333;
    box-sizing: content-box;
  }
}

/**
* mobile
*/
@media (max-width: 536px) {
  .privacy_labeling {
    overflow: visible;
    ul {
      grid-template-columns: repeat(2, 1fr);
      li {
        &:nth-of-type(even) {
          a {
            & + .privacy_labeling_tooltip {
              right: 0;
            }
          }
        }
        .privacy_labeling_tooltip {
          padding: 17px;
        }
      }
    }
  }
  .privacy_labeling_summary {
    margin: 44px -1.75rem 0;
    li {
      float: none;
      width: 100%;
      .summary_text {
        flex-wrap: wrap;
        strong {
          flex: 1;
          flex-shrink: 0;
          flex-basis: 100%;
          margin: 0;
        }
        .summary_privacy_img {
          margin: 10px 0 0;
        }
      }
      &:nth-last-child(2) {
        border-bottom: 1px solid #e5e5e5;
      }
    }
  }

  .privacy_accordion {
    ul {
      li {
        .accordion_q {
          flex-wrap: wrap;
          button {
            padding-right: 42px;
          }
          &:after {
            top: 30%;
          }
          button {
            width: 100%;
            text-align: left;
          }
        }
      }
    }
  }
}
@media (min-width: 537px) and (max-width: 538px) {
  .privacy_labeling {
    ul {
      gap: 27px;
    }
  }
}
@media (min-width: 536px) and (max-width: 1019px) {
  .privacy_labeling {
    ul {
      flex-wrap: wrap;
      grid-template-columns: repeat(3, 1fr);
      li {
        &:nth-of-type(3n) {
          a {
            & + .privacy_labeling_tooltip {
              right: 0;
            }
          }
        }
        .privacy_labeling_tooltip {
          padding: 17px;
        }
      }
    }
  }
  .privacy_labeling_summary {
    margin: 44px -1.75rem 0;
    li {
      float: none;
      width: 100%;
      &:nth-last-child(2) {
        border-bottom: 1px solid #e5e5e5;
      }
    }
  }
}
