/* 공통SNS */
.cm_sns:after {
  content: '';
  display: block;
  clear: both;
}
.cm_sns li {
  float: left;
  width: 24px;
  height: 24px;
  margin-left: 4px;
}
.cm_sns li a {
  width: 24px;
  height: 24px;
  display: block;
  background-image: url(#{$imgPath}/common_layout/sp_sns.png);
  background-repeat: no-repeat;
}
.cm_sns li a.facebook {
  background-position: 0 -38px;
}
.cm_sns li a.kakao {
  background-position: -28px -38px;
}
.cm_sns li a.twitter {
  background-position: -56px -38px;
}
.cm_sns li a.pinterest {
  background-position: -84px -38px;
}

.life_magazine .cm_sns {
  float: left;
  margin: 18px 5px 0 0;
} /* 라이프매거진 상세 */
.detail_product .cm_sns {
  position: absolute;
  right: 0;
  top: 7px;
} /* 상품상세 */
.exhibition_box .cm_sns {
  float: left;
  margin: 0 15px 0 0;
} /* 기획전 상세 */
.special .cm_sns {
  position: absolute;
  top: 11px;
  right: 0;
} /* 특가전 */
.benfit_list .cm_sns {
  float: left;
  margin: 2px 5px 0 0;
} /* 이벤트 */

.detail_wrap .left .cm_sns {
  overflow: hidden;
  padding: 10px 0 0 0;
  float: right;
  height: 34px;
} /* 이몰상세 */
.header_obanjang .cm_sns {
  position: absolute;
  top: 318px;
  right: 0;
} /* 이몰오반장 */
.location_section .cm_sns {
  float: left;
  margin: 3px 0 0 10px;
  height: 18px;
} /* 이몰기획전 */
.location_section .cm_sns li {
  width: 18px;
  height: 18px;
  margin-left: 3px;
}
.location_section .cm_sns li a {
  width: 18px;
  height: 18px;
}
.location_section .cm_sns li a.facebook {
  background-position: 0 0;
}
.location_section .cm_sns li a.kakao {
  background-position: -20px 0;
}
.location_section .cm_sns li a.twitter {
  background-position: -40px 0;
}
.location_section .cm_sns li a.pinterest {
  background-position: -60px 0;
}

#container_sm .pr_layout .cm_sns {
  position: absolute;
  right: 28px;
  bottom: 30px;
} /* 신몰상세 */
#container_sm .benfit_list .cm_sns {
  margin: 2px 5px 0 14px;
}

#container_sd .sub_content .detail .cm_sns {
  position: absolute;
  right: 28px;
  bottom: 30px;
}
#container_sd .share_brand .cm_sns {
  position: absolute;
  right: 0;
  top: -44px;
}
