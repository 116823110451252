/* 공통 몰아이콘(#364373) */
.cm_mall_ic {
  display: inline-block;
  font-size: 0;
  vertical-align: top;
}
.cm_mall_ic > i {
  display: inline-block;
  overflow: hidden;
  background-image: url(#{$imgPath}/common/sp_cm_mall_ic_20220722.png);
  background-repeat: no-repeat;
  font-size: 12px;
  vertical-align: top;
}
.cm_mall_ic > i.ssg {
  background-position-y: 0;
}
.cm_mall_ic > i.sm {
  background-position-y: -50px;
}
.cm_mall_ic > i.sd {
  background-position-y: -100px;
}
.cm_mall_ic > i.em {
  background-position-y: -150px;
}
.cm_mall_ic > i.tr {
  background-position-y: -200px;
}
.cm_mall_ic > i.mnmorning {
  background-position-y: -250px;
}
.cm_mall_ic > i.mnstarbucks {
  background-position-y: -300px;
}
.cm_mall_ic > i.tv {
  background-position-y: -350px;
}
.cm_mall_ic > i.si {
  background-position-y: -400px;
}
.cm_mall_ic > i.outlet {
  background-position-y: -450px;
}
.cm_mall_ic > i.mncasamia {
  background-position-y: -500px;
}
.cm_mall_ic > i.sf {
  background-position-y: -550px;
}
.cm_mall_ic > i.hwd {
  background-position-y: -600px;
}
.cm_mall_ic > i.mndeptfood {
  background-position-y: -650px;
}
.cm_mall_ic > i.mnchicor {
  background-position-y: -700px;
}
.cm_mall_ic > i.mntrip {
  background-position-y: -750px;
}
.cm_mall_ic > i.mndutyfree {
  background-position-y: -800px;
}
.cm_mall_ic > i.mnssgfood {
  background-position-y: -850px;
}
/* 공통 몰아이콘 - 원형 small */
.cm_mall_ic.ty_circle_s {
  line-height: 20px;
}
.cm_mall_ic.ty_circle_s > i {
  width: 20px;
  height: 20px;
  background-position-x: 0;
}
.cm_mall_ic.ty_circle_s > i + i {
  margin-left: 2px;
}
/* 공통 몰아이콘 - 원형 medium */
.cm_mall_ic.ty_circle_m {
  line-height: 30px;
}
.cm_mall_ic.ty_circle_m > i {
  width: 30px;
  height: 30px;
  background-position-x: -50px;
}
.cm_mall_ic.ty_circle_m > i + i {
  margin-left: 2px;
}
/* 공통 몰아이콘 - 원형 large */
.cm_mall_ic.ty_circle_l {
  line-height: 40px;
}
.cm_mall_ic.ty_circle_l > i {
  width: 40px;
  height: 40px;
  background-position-x: -100px;
}
.cm_mall_ic.ty_circle_l > i + i {
  margin-left: 2px;
}
/* 공통 몰아이콘 - 원형 xlarge */
.cm_mall_ic.ty_circle_xl {
  line-height: 40px;
}
.cm_mall_ic.ty_circle_xl > i {
  height: 40px;
  background-position-x: -150px;
}
.cm_mall_ic.ty_circle_xl > i + i {
  margin-left: 2px;
}
.cm_mall_ic.ty_circle_xl > i.ssg {
  width: 97px;
}
.cm_mall_ic.ty_circle_xl > i.sm {
  width: 86px;
}
.cm_mall_ic.ty_circle_xl > i.sd {
  width: 102px;
}
.cm_mall_ic.ty_circle_xl > i.em {
  width: 90px;
}
.cm_mall_ic.ty_circle_xl > i.tr {
  width: 100px;
}
.cm_mall_ic.ty_circle_xl > i.mnmorning {
  width: 84px;
}
.cm_mall_ic.ty_circle_xl > i.mnstarbucks {
  width: 129px;
}
.cm_mall_ic.ty_circle_xl > i.tv {
  width: 108px;
}
.cm_mall_ic.ty_circle_xl > i.si {
  width: 113px;
}
.cm_mall_ic.ty_circle_xl > i.outlet {
  width: 133px;
}
.cm_mall_ic.ty_circle_xl > i.mncasamia {
  width: 97px;
}
.cm_mall_ic.ty_circle_xl > i.sf {
  width: 96px;
}
.cm_mall_ic.ty_circle_xl > i.hwd {
  width: 84px;
}
.cm_mall_ic.ty_circle_xl > i.mndeptfood {
  width: 116px;
}
.cm_mall_ic.ty_circle_xl > i.mnchicor {
  width: 93px;
}
.cm_mall_ic.ty_circle_xl > i.mntrip {
  width: 71px;
}
/* 공통 몰아이콘 - 사각형 small */
.cm_mall_ic.ty_rect_s {
  line-height: 17px;
}
.cm_mall_ic.ty_rect_s > i {
  height: 17px;
  margin-right: 5px;
  background-position-x: -300px;
}
.cm_mall_ic.ty_rect_s > i.ssg {
}
.cm_mall_ic.ty_rect_s > i.sm {
  width: 49px;
}
.cm_mall_ic.ty_rect_s > i.sd {
  width: 68px;
}
.cm_mall_ic.ty_rect_s > i.em {
  width: 49px;
}
.cm_mall_ic.ty_rect_s > i.tr {
  width: 59px;
}
.cm_mall_ic.ty_rect_s > i.mnmorning {
  width: 50px;
}
.cm_mall_ic.ty_rect_s > i.mnstarbucks {
  width: 50px;
}
.cm_mall_ic.ty_rect_s > i.tv {
  width: 72px;
}
.cm_mall_ic.ty_rect_s > i.si {
  width: 70px;
}
.cm_mall_ic.ty_rect_s > i.outlet {
  width: 81px;
}
.cm_mall_ic.ty_rect_s > i.mncasamia {
  width: 52px;
}
.cm_mall_ic.ty_rect_s > i.sf {
}
.cm_mall_ic.ty_rect_s > i.hwd {
  width: 42px;
}
.cm_mall_ic.ty_rect_s > i.mndeptfood {
  width: 68px;
}
.cm_mall_ic.ty_rect_s > i.mnchicor {
  width: 44px;
}
.cm_mall_ic.ty_rect_s > i.mntrip {
  width: 31px;
}
.cm_mall_ic.ty_rect_s > i.mndutyfree {
  width: 40px;
}
.cm_mall_ic.ty_rect_s > i.mnssgfood {
  width: 71px;
}
/* 공통 몰아이콘 - 사각형 medium */
.cm_mall_ic.ty_rect_m {
  line-height: 21px;
}
.cm_mall_ic.ty_rect_m > i {
  height: 21px;
  margin-right: 5px;
  background-position-x: -400px;
}
.cm_mall_ic.ty_rect_m > i.ssg {
  width: 65px;
}
.cm_mall_ic.ty_rect_m > i.sm {
  width: 56px;
}
.cm_mall_ic.ty_rect_m > i.sd {
  width: 76px;
}
.cm_mall_ic.ty_rect_m > i.em {
  width: 56px;
}
.cm_mall_ic.ty_rect_m > i.tr {
  width: 67px;
}
.cm_mall_ic.ty_rect_m > i.mnmorning {
  width: 55px;
}
.cm_mall_ic.ty_rect_m > i.mnstarbucks {
  width: 55px;
}
.cm_mall_ic.ty_rect_m > i.tv {
  width: 80px;
}
.cm_mall_ic.ty_rect_m > i.si {
  width: 76px;
}
.cm_mall_ic.ty_rect_m > i.outlet {
  width: 89px;
}
.cm_mall_ic.ty_rect_m > i.mncasamia {
  width: 60px;
}
.cm_mall_ic.ty_rect_m > i.sf {
}
.cm_mall_ic.ty_rect_m > i.hwd {
  width: 47px;
}
.cm_mall_ic.ty_rect_m > i.mndeptfood {
  width: 76px;
}
.cm_mall_ic.ty_rect_m > i.mnchicor {
  width: 55px;
}
.cm_mall_ic.ty_rect_m > i.mntrip {
  width: 37px;
}
.cm_mall_ic.ty_rect_m > i.mndutyfree {
  width: 46px;
}
.cm_mall_ic.ty_rect_m > i.mnssgfood {
  width: 89px;
}
/* 공통 몰아이콘 - 사각형 large */
.cm_mall_ic.ty_rect_l {
  line-height: 25px;
}
.cm_mall_ic.ty_rect_l > i {
  height: 25px;
  margin-right: 5px;
  background-position-x: -500px;
}
.cm_mall_ic.ty_rect_l > i.ssg {
}
.cm_mall_ic.ty_rect_l > i.sm {
  width: 60px;
}
.cm_mall_ic.ty_rect_l > i.sd {
  width: 85px;
}
.cm_mall_ic.ty_rect_l > i.em {
  width: 60px;
}
.cm_mall_ic.ty_rect_l > i.tr {
  width: 73px;
}
.cm_mall_ic.ty_rect_l > i.mnmorning {
  width: 60px;
}
.cm_mall_ic.ty_rect_l > i.mnstarbucks {
  width: 60px;
}
.cm_mall_ic.ty_rect_l > i.tv {
  width: 91px;
}
.cm_mall_ic.ty_rect_l > i.si {
  width: 87px;
}
.cm_mall_ic.ty_rect_l > i.outlet {
  width: 101px;
}
.cm_mall_ic.ty_rect_l > i.mncasamia {
  width: 63px;
}
.cm_mall_ic.ty_rect_l > i.sf {
}
.cm_mall_ic.ty_rect_l > i.hwd {
  width: 51px;
}
.cm_mall_ic.ty_rect_l > i.mndeptfood {
  width: 85px;
}
.cm_mall_ic.ty_rect_l > i.mnchicor {
  width: 60px;
}
.cm_mall_ic.ty_rect_l > i.mntrip {
  width: 41px;
}
.cm_mall_ic.ty_rect_l > i.mndutyfree {
  width: 48px;
}
.cm_mall_ic.ty_rect_l > i.mnssgfood {
  width: 104px;
}
// my ssg 툴팁 링크 있는 요소
.cmmyssg_help_layer {
  .cm_mall_ic {
    a {
      // @extend .cm_mall_ic;
      // @extend .cm_mall_ic.ty_rect_m;
      font-size: 12px;
    }
  }
}
@media screen and (-webkit-min-device-pixel-ratio: 2) {
  .cm_mall_ic > i {
    background-image: url(#{$imgPath}/common/sp_cm_mall_ic_20220722@2x.png);
    background-size: 610px auto;
  }
}
