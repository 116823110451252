/* 공통헤더 */
.common_header {
  position: relative;
  z-index: 400;
  font-family: $fontR;
  font-weight: 400;
  line-height: 1.5em;
}
.common_header:after {
  display: block;
  clear: both;
  content: '';
}
.common_header .common_header_inner {
  position: relative;
  z-index: 300;
  width: 1280px;
  margin: 0 auto;
  height: 59px;
  padding-top: 13px;
}
.common_header img {
  vertical-align: middle;
}
.common_header .sp_rban {
  display: inline-block;
  overflow: hidden;
  width: auto;
  height: 10px;
  background: url(#{$imgPath}/common_layout/sp_rban.png) no-repeat;
  line-height: 999px;
  vertical-align: top;
}
.common_header .sp_cmh,
.common_header .util_menu .cmh_ico_myssg:after,
.common_header .util_myssg li.util_myssg_02 a:after {
  display: inline-block;
  overflow: hidden;
  background: url(#{$imgPath}/common_layout/sp_cmh.png) no-repeat;
  vertical-align: top;
}
:lang(en) .common_header .sp_cmh,
.common_header .util_menu .cmh_ico_myssg:after,
.common_header .util_myssg li.util_myssg_02 a:after {
  background-image: url(#{$imgPath}/common_layout/sp_cmh_en.png);
}
:lang(zh) .common_header .sp_cmh,
.common_header .util_menu .cmh_ico_myssg:after,
.common_header .util_myssg li.util_myssg_02 a:after {
  background-image: url(#{$imgPath}/common_layout/sp_cmh_zh.png);
}
.common_header.other_site .common_header_inner {
  height: 42px;
}
@media screen and (-webkit-min-device-pixel-ratio: 2) {
  .common_header .sp_cmh,
  .common_header .util_menu .cmh_ico_myssg:after,
  .common_header .util_myssg li.util_myssg_02 a:after {
    background-image: url(#{$imgPath}/common_layout/sp_cmh_2x.png);
    background-size: 150px auto;
  }
  :lang(en) .common_header .sp_cmh,
  .common_header .util_menu .cmh_ico_myssg:after,
  .common_header .util_myssg li.util_myssg_02 a:after {
    background-image: url(#{$imgPath}/common_layout/sp_cmh_en_2x.png);
  }
  :lang(zh) .common_header .sp_cmh,
  .common_header .util_menu .cmh_ico_myssg:after,
  .common_header .util_myssg li.util_myssg_02 a:after {
    background-image: url(#{$imgPath}/common_layout/sp_cmh_zh_2x.png);
  }
}
/* 공통헤더 좌측 날개배너 */
.left_wing_bx .bn_blist_benefit {
  overflow: hidden;
  position: relative;
  width: 77px;
  padding: 3px 0;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  background: #fff;
}
.left_wing_bx .card_bnr_area .bn_blist {
  overflow: hidden;
  height: 109px;
}
.left_wing_bx .bn_blist li a {
  display: inline-block;
  vertical-align: top;
}
.left_wing_bx .bn_blist li img {
  vertical-align: top;
}
.left_wing_bx .bn_blist_benefit .bx-controls-direction {
  padding: 3px 0;
  line-height: 0;
  text-align: center;
}
.left_wing_bx .bn_blist_benefit .bx-controls-direction a {
  display: inline-block;
  overflow: hidden;
  background: url(#{$imgPath}/sprites/sp_leftwing.png) 0 0 no-repeat;
  line-height: 999px;
  vertical-align: top;
  width: 18px;
  height: 18px;
  margin: 0 1px;
}
.left_wing_bx .bn_blist_benefit .bx-controls-direction .bx-next {
  background-position: -20px 0;
}
/* 공통헤더 유틸 */
.common_header .header_util {
  position: absolute;
  top: 10px;
  left: 0;
  z-index: 20;
  width: 100%;
}
.common_header .header_util:after {
  display: block;
  clear: both;
  content: '';
}
.common_header .util_left {
  display: none;
  float: left;
}
.common_header .util_left li {
  float: left;
  margin-left: 10px;
  padding-left: 11px;
  background: url(#{$imgPath}/common_layout/bg_vertical_util.gif) 0 4px no-repeat;
}
.common_header .util_left li:first-child {
  margin-left: 0;
  padding-left: 0;
  background: none;
}
.common_header .util_left li a {
  font-weight: bold;
  font-size: 11px;
  line-height: 19px;
  color: #666;
  letter-spacing: -0.5px;
  text-decoration: none;
}

.common_header .util_left li.util_lang {
  min-width: 97px;
}
.common_header .util_left li.util_lang.on {
  position: relative;
  z-index: 2;
  margin-right: -18px;
}
:lang(en) .common_header .util_left li.util_lang.on {
  margin-right: -13px;
}
:lang(zh) .common_header .util_left li.util_lang.on {
  margin-right: -4px;
}
.common_header .util_left li.util_lang .util_lang_btnsel {
  display: block;
  padding: 0 0 0 10px;
  background-color: #fff;
  font-family: $fontM;
  font-weight: 500;
  font-size: 11px;
  line-height: 33px;
  color: #666;
}
.common_header .util_left li.util_lang.on .util_lang_btnsel:hover {
  background-color: #222;
  color: #fff;
}
.common_header .util_left li.util_lang .util_lang_sel {
  display: block;
  margin: -10px 0 0 -10px;
  border: 1px solid #fff;
  border-bottom: 0;
}
.common_header .util_left li.util_lang.on .util_lang_sel {
  width: 123px;
  border-color: #d1d1d1;
}
.common_header .util_left li.util_lang .util_lang_sel .util_lang_btnsel {
  padding-top: 1px;
}
.common_header .util_left li.util_lang .util_lang_sel .util_lang_btnsel:after {
  display: inline-block;
  margin: 13px 0 0 4px;
  border-top: 4px solid #222;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  vertical-align: top;
  content: '';
}
.common_header .util_left li.util_lang.on .util_lang_sel .util_lang_btnsel:after {
  display: none;
}
.common_header .util_left li.util_lang .util_lang_layer {
  display: none;
  position: absolute;
  top: 100%;
  left: -10px;
  width: 123px;
  border: 1px solid #d1d1d1;
  border-top: 0;
  background: #fff;
}
.common_header .util_left li.util_lang.on .util_lang_layer {
  display: block;
}
.common_header .util_left li.util_lang_cur {
  display: none;
  font-family: $fontM;
  font-weight: 500;
  font-size: 11px;
  color: #666;
}
.util_lang .util_lang_ico {
  display: inline-block;
  width: 25px;
  height: 25px;
  margin: 4px 4px 0 0;
  background: url(#{$imgPath}/common_layout/sp_util_lang.png) no-repeat 0 0;
  vertical-align: top;
}
:lang(ko) .util_lang .util_lang_btnsel1 .util_lang_ico {
  background-position: 0 0;
}
:lang(ko) .util_lang .util_lang_btnsel2 .util_lang_ico {
  background-position: 0 -50px;
}
:lang(ko) .util_lang .util_lang_btnsel3 .util_lang_ico {
  background-position: 0 -100px;
}
:lang(auto) .util_lang .util_lang_btnsel1 .util_lang_ico {
  background-position: 0 0;
}
:lang(auto) .util_lang .util_lang_btnsel2 .util_lang_ico {
  background-position: 0 -50px;
}
:lang(auto) .util_lang .util_lang_btnsel3 .util_lang_ico {
  background-position: 0 -100px;
}
:lang(en) .util_lang .util_lang_btnsel1 .util_lang_ico {
  background-position: 0 -50px;
}
:lang(en) .util_lang .util_lang_btnsel2 .util_lang_ico {
  background-position: 0 0;
}
:lang(en) .util_lang .util_lang_btnsel3 .util_lang_ico {
  background-position: 0 -100px;
}
:lang(zh) .util_lang .util_lang_btnsel1 .util_lang_ico {
  background-position: 0 -100px;
}
:lang(zh) .util_lang .util_lang_btnsel2 .util_lang_ico {
  background-position: 0 0;
}
:lang(zh) .util_lang .util_lang_btnsel3 .util_lang_ico {
  background-position: 0 -50px;
}
.util_lang .util_lang_tx em {
  display: none;
}
:lang(ko) .util_lang .util_lang_btnsel1 .util_lang_tx_ko {
  display: inline;
}
:lang(ko) .util_lang .util_lang_btnsel2 .util_lang_tx_en {
  display: inline;
}
:lang(ko) .util_lang .util_lang_btnsel3 .util_lang_tx_zh {
  display: inline;
}
:lang(auto) .util_lang .util_lang_btnsel1 .util_lang_tx_ko {
  display: inline;
}
:lang(auto) .util_lang .util_lang_btnsel2 .util_lang_tx_en {
  display: inline;
}
:lang(auto) .util_lang .util_lang_btnsel3 .util_lang_tx_zh {
  display: inline;
}
:lang(en) .util_lang .util_lang_btnsel1 .util_lang_tx_en {
  display: inline;
}
:lang(en) .util_lang .util_lang_btnsel2 .util_lang_tx_ko {
  display: inline;
}
:lang(en) .util_lang .util_lang_btnsel3 .util_lang_tx_zh {
  display: inline;
}
:lang(zh) .util_lang .util_lang_btnsel1 .util_lang_tx_zh {
  display: inline;
}
:lang(zh) .util_lang .util_lang_btnsel2 .util_lang_tx_ko {
  display: inline;
}
:lang(zh) .util_lang .util_lang_btnsel3 .util_lang_tx_en {
  display: inline;
}
:lang(en) .auto_word,
:lang(zh) .auto_word {
  border-top: 1px solid #d1d1d1;
  top: 2px;
}
@media screen and (-webkit-min-device-pixel-ratio: 2) {
  .util_lang .util_lang_ico {
    background-image: url(#{$imgPath}/common_layout/sp_util_lang@2x.png);
    background-size: 25px auto;
  }
}

.common_header .util_left li.util_go_global a {
  position: relative;
  display: inline-block;
  vertical-align: top;
  padding-right: 37px;
  font-family: $fontM;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
}
.common_header .util_left li.util_go_global .go_global_ico {
  position: absolute;
  right: 0;
  top: -2px;
}
/* util_go_global 배포 후, util_intl_guide 제거 예정(s) */
.common_header .util_left li.util_intl_guide {
  margin-top: -3px;
}
.common_header .util_left li.util_intl_guide img {
  width: 85px;
  height: 21px;
  vertical-align: top;
}
/* util_go_global 배포 후, util_intl_guide 제거 예정(e) */
/* #394955 [PC GNB] 바로가기 ON 버튼 비노출 처리 */
.common_header .util_left li.util_go_s {
  display: none;
}
/* //#394955 [PC GNB] 바로가기 ON 버튼 비노출 처리 */
.common_header .util_left li.util_go_s .ico {
  display: inline-block;
  margin-left: 2px;
  vertical-align: top;
}
.common_header .util_left li.util_go_s .util_s_bx {
  display: none;
  position: absolute;
  top: 19px;
  left: 0;
  padding-top: 3px;
  cursor: pointer;
}
.common_header .util_left li.util_go_s .util_s_bx img {
  vertical-align: top;
}
.common_header .util_left li.util_go_s.hover .util_s_bx {
  display: block;
}
.common_header .util_right {
  float: right;
}
.common_header .util_right li {
  float: left;
  margin-right: 8px;
  padding-right: 9px;
  background: url(#{$imgPath}/common_layout/bg_vertical_util.gif) 100% 4px no-repeat;
}
.common_header .util_right li:last-child {
  margin-right: 0;
  padding-right: 0;
  background: none;
}
.common_header .util_right li a {
  font-weight: bold;
  font-size: 11px;
  line-height: 19px;
  color: #666;
  letter-spacing: -0.5px;
  text-decoration: none;
}
.common_header .util_menu {
  position: absolute;
  top: 22px;
  left: 824px;
  z-index: 21;
}
.common_header.rank .util_menu {
  position: absolute;
  top: 26px;
  left: 875px;
  z-index: 41;
}
.common_header .util_menu > li {
  float: left;
  padding-left: 1px;
  background: url(#{$imgPath}/common_layout/bg_vertical_util2.gif) 0 50% no-repeat;
}
.common_header .util_menu > li:first-child {
  padding-left: 0;
  background: none;
}
.common_header .util_menu > li > a,
.common_header .util_menu > li > button {
  display: block;
  position: relative;
  padding: 2px 13px 0 12px;
}
.common_header .util_menu .cmh_ico_cart {
  width: 31px;
  height: 38px;
  background-position: 0 0;
}
.common_header .util_menu .cmh_ico_order {
  width: 46px;
  height: 38px;
  background-position: -33px 0;
}
.common_header .util_menu .cmh_ico_myssg {
  width: 33px;
  height: 38px;
  background-position: -81px 0;
}
.common_header .util_menu .num_nc {
  position: absolute;
  top: -4px;
  left: 50%;
  min-width: 12px;
  margin-left: 3px;
  padding: 0 3px;
  background: get-color('primary');
  border-radius: 9px;
  font-size: 11px;
  font-family: $fontR;
  font-weight: 400;
  line-height: 18px;
  color: #fff;
  text-align: center;
}
.common_header .util_cart {
  position: relative;
  word-break: break-all;
  letter-spacing: -0.5px;
}
.common_header .util_cart .sr_off {
  display: block;
}
.common_header .util_cart .sr_on {
  display: none;
}
.common_header .util_cart button.util_cart_btn {
  display: none;
  background: transparent;
}
.common_header .util_cart .util_cart_layer {
  display: none;
  position: absolute;
  top: 46px;
  left: -13px;
  min-width: 318px;
  border: 1px solid #d1d1d1;
  background: #fff;
}
.common_header .util_cart .util_cart_layer:before {
  position: absolute;
  top: -1px;
  left: 0;
  width: 78px;
  height: 1px;
  background: #fff;
  content: '';
}
.common_header .util_cart .util_cart_layer.ty_empty {
  min-width: 198px;
}
.common_header .util_cart .util_cart_link {
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 78px;
  height: 55px;
  background: url(#{$imgPath}/common/b.gif);
}
.common_header .util_cart .util_cart_tit {
  display: block;
  padding: 14px 19px 0;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: #222;
}
.common_header .util_cart .util_cart_loading {
  height: 40px;
  margin: 15px 20px;
  background: url(#{$imgPath}/common/loading.gif) no-repeat 50% 0;
}
.common_header .util_cart .util_cart_more {
  display: block;
  padding: 13px 0;
  font-size: 13px;
  line-height: 15px;
  color: get-color('primary');
  text-align: center;
  text-decoration: none;
}
.common_header .util_cart .util_cart_more:hover {
  text-decoration: underline;
}
.common_header .util_cart .util_cart_more:after {
  display: inline-block;
  width: 9px;
  height: 15px;
  margin: -1px 0 0 6px;
  background: url(#{$imgPath}/common_layout/bu_arr2.png);
  vertical-align: top;
  content: '';
}
@media screen and (-webkit-min-device-pixel-ratio: 2) {
  .common_header .util_cart .util_cart_more:after {
    background-image: url(#{$imgPath}/common_layout/bu_arr2_2x.png);
    background-size: 9px auto;
  }
}
.common_header .util_cart .util_cart_total {
  padding-bottom: 8px;
  background: #fafafa;
  color: #222;
  line-height: 18px;
}
.common_header .util_cart .util_cart_total:before {
  display: block;
  height: 4px;
  margin-bottom: 10px;
  border-top: 1px solid #d7d7d7;
  background: #efefef;
  content: '';
}
.common_header .util_cart .util_cart_total:after {
  display: block;
  clear: both;
  content: '';
}
.common_header .util_cart .util_cart_totaltit {
  float: left;
  clear: both;
  padding: 3px 0 3px 19px;
}
.common_header .util_cart .util_cart_totaldsc {
  float: right;
  clear: right;
  padding: 3px 19px 3px 0;
  font-weight: bold;
  text-align: right;
}
.common_header .util_cart .util_cart_price .ssg_price {
  display: inline-block;
  margin-top: -2px;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0;
  vertical-align: top;
}
.common_header .util_cart .util_cart_pointx {
  color: get-color('primary');
}
.common_header .util_cart .util_cart_pointx2 {
  color: #969696;
  letter-spacing: -0.3px;
}
.common_header .util_cart .util_cart_empty {
  display: block;
  padding: 18px 20px 19px;
  color: #888;
}
.common_header .util_cartlst {
  padding: 0 19px;
}
.common_header .util_cartlst .util_cartlst_item {
  padding: 10px 0;
  border-bottom: 1px solid #efefef;
}
.common_header .util_cartlst .util_cartlst_item:after {
  display: block;
  clear: both;
  content: '';
}
.common_header .util_cartlst .util_cartlst_thmb {
  float: left;
  position: relative;
  width: 50px;
  height: 50px;
}
.common_header .util_cartlst .util_cartlst_thmb a:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.02);
  z-index: 3;
}
.common_header .util_cartlst .util_cartlst_img {
  width: 100%;
  height: 100%;
}
.common_header .util_cartlst .util_cartlst_soldout {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #222;
  background-color: rgba(34, 34, 34, 0.95);
  font-weight: bold;
  font-size: 11px;
  line-height: 18px;
  color: #fff;
  letter-spacing: -0.4px;
  text-align: center;
}
.common_header .util_cartlst .util_cartlst_infoarea {
  margin-left: 60px;
  font-size: 0;
  white-space: nowrap;
}
.common_header .util_cartlst .util_cartlst_name {
  display: block;
  overflow: hidden;
  max-width: 220px;
  padding-top: 2px;
  font-size: 12px;
  line-height: 23px;
  color: #888;
  letter-spacing: -0.3px;
  text-overflow: ellipsis;
  text-decoration: none;
}
.common_header .util_cartlst .util_cartlst_name:hover {
  text-decoration: underline;
}
/*  #364373 [공통] 몰아이콘 마크업 통합 관리 배포후 삭제*/
.common_header .util_cartlst .util_cartlst_mall {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 3px 0 2px;
  background-position-x: 0;
  background-image: url(#{$imgPath}/common_layout/sp_mall_cate_ico_v2.png);
  background-repeat: no-repeat;
  font-size: 12px;
  vertical-align: top;
}
.common_header .util_cartlst .util_cartlst_mall.ssg {
  background-position-y: 0;
}
.common_header .util_cartlst .util_cartlst_mall.sm {
  background-position-y: -20px;
}
.common_header .util_cartlst .util_cartlst_mall.sd {
  background-position-y: -40px;
}
.common_header .util_cartlst .util_cartlst_mall.em {
  background-position-y: -60px;
}
.common_header .util_cartlst .util_cartlst_mall.tr {
  background-position-y: -80px;
}
.common_header .util_cartlst .util_cartlst_mall.bt {
  background-position-y: -100px;
}
.common_header .util_cartlst .util_cartlst_mall.tv {
  background-position-y: -120px;
}
.common_header .util_cartlst .util_cartlst_mall.si {
  background-position-y: -140px;
}
.common_header .util_cartlst .util_cartlst_mall.hwd {
  background-position-y: -160px;
}
.common_header .util_cartlst .util_cartlst_mall.outlet {
  background-position-y: -180px;
}
.common_header .util_cartlst .util_cartlst_mall.mnmorning {
  background-position-y: -200px;
}
@media screen and (-webkit-min-device-pixel-ratio: 2) {
  .common_header .util_cartlst .util_cartlst_mall {
    background-position-x: -20px;
    background-size: 40px 300px;
  }
}
.common_header .util_cartlst .util_cartlst_mall {
  margin: 0 3px 0 2px;
}
.common_header .util_cartlst .util_cartlst_mall + .util_cartlst_info {
  margin-left: 0;
  padding-left: 0;
}
.common_header .util_cartlst .util_cartlst_mall + .util_cartlst_info:before {
  display: none;
}
/*  #364373 [공통] 몰아이콘 마크업 통합 관리 배포후 삭제*/
.common_header .util_cartlst .util_cartlst_icon {
  display: inline-block;
  vertical-align: top;
  width: 20px;
  height: 20px;
  margin: 0 3px 0 2px;
}
.common_header .util_cartlst .util_cartlst_icon + .util_cartlst_info {
  margin-left: 0;
  padding-left: 0;
}
.common_header .util_cartlst .util_cartlst_icon + .util_cartlst_info:before {
  display: none;
}
.common_header .util_cartlst .util_cartlst_info {
  display: inline-block;
  position: relative;
  margin-left: 10px;
  padding: 2px 0 0 11px;
  font-size: 13px;
  line-height: 18px;
  color: #888;
  vertical-align: top;
}
.common_header .util_cartlst .util_cartlst_info:before {
  position: absolute;
  top: 4px;
  left: 0;
  width: 1px;
  height: 13px;
  background: #c7c7c7 url(#{$imgPath}/common_layout/bg_dotline_trans_white.gif) repeat-y;
  content: '';
}
.common_header .util_cartlst .util_cartlst_price {
  color: #222;
}
.common_header .util_cartlst .util_cartlst_price .ssg_price {
  display: inline-block;
  margin-top: -2px;
  font-family: $fontR;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0;
  vertical-align: top;
}
.common_header .util_cart.active_preview a.util_cart_btn {
  display: none;
}
.common_header .util_cart.active_preview button.util_cart_btn {
  display: block;
}

.common_header .util_cart .util_cart_btnview {
  display: none;
}
.common_header .util_cart.active_preview .util_cart_btnpreview {
  display: none;
}
.common_header .util_cart.active_preview .util_cart_btnview {
  display: block;
}

.common_header .util_cartlst .util_cartlst_item.ty_bundle .util_cartlst_name {
  line-height: 18px;
}
.common_header .util_cartlst .util_cartlst_item.ty_bundle .util_cartlst_name:before {
  content: '·';
}
.common_header .util_cartlst .util_cartlst_item.ty_bundle dt {
  margin-bottom: 6px;
}
.common_header .util_cartlst .util_cartlst_item.ty_bundle .util_cartlst_thmb {
  height: auto;
  width: 46px;
  padding: 0 0 15px 4px;
}
.common_header .util_cartlst .util_cartlst_item.ty_bundle .util_cartlst_thmb a:before {
  display: none;
}
.common_header .util_cartlst .util_cartlst_item.ty_bundle .util_cartlst_thmb img.util_cartlst_img {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  border: 1px solid #eee;
  margin-bottom: -15px;
}

.common_header .util_cart.on {
  z-index: 10;
}
.common_header .util_cart.on:before {
  position: absolute;
  top: -9px;
  left: -13px;
  width: 78px;
  height: 54px;
  border: 1px solid #d1d1d1;
  border-bottom-color: #fff;
  background: #fff;
  content: '';
}
.common_header .util_cart.on .sr_off {
  display: none;
}
.common_header .util_cart.on .sr_on {
  display: block;
}
.common_header .util_cart.on .util_cart_layer {
  display: block;
}
.common_header .util_myssg {
  position: relative;
  z-index: 1;
}
.common_header .util_myssg ul {
  display: none;
  position: absolute;
  top: -9px;
  left: 0;
  width: 98px;
  height: 151px;
  border: 1px solid #d1d1d1;
  background: #fff;
}
:lang(en) .common_header .util_myssg ul {
  width: 130px;
  height: 157px;
}
:lang(zh) .common_header .util_myssg ul {
  width: 110px;
  height: 157px;
}
.common_header .util_myssg.on {
  z-index: 11;
}
.common_header .util_myssg.on ul {
  display: block;
}
.common_header .util_myssg li {
  margin: 0 -1px;
}
.common_header .util_myssg li:first-child {
  margin-top: -1px;
}
.common_header .util_myssg li a {
  display: block;
  position: relative;
  padding: 0 13px;
  font-size: 12px;
  line-height: 30px;
  color: #222;
  letter-spacing: -0.5px;
  text-decoration: none;
}
.common_header .util_myssg li a:hover {
  background: #222;
  color: #fff;
}
.common_header .util_myssg li.util_myssg_01:after {
  display: block;
  margin: -1px 1px 0;
  border-top: 1px solid #d7d7d7;
  height: 4px;
  background: #efefef;
  content: '';
}
.common_header .util_myssg li.util_myssg_01 a {
  padding: 11px 13px 10px;
}
.common_header .util_myssg li.util_myssg_01 a .cmh_ico_myssg:after {
  overflow: hidden;
  position: absolute;
  top: 50%;
  right: 9px;
  width: 9px;
  height: 15px;
  margin: -8px 0 0 14px;
  background-position: -14px -40px;
  content: '';
}
.common_header .util_myssg li.util_myssg_01 a:hover .cmh_ico_myssg {
  background-position: -116px 0;
}
.common_header .util_myssg li.util_myssg_01 a:hover .cmh_ico_myssg:after {
  background-position: -25px -40px;
}
.common_header .util_myssg li.util_myssg_02 a:after {
  width: 15px;
  height: 13px;
  margin: 9px 0 0 4px;
  background-position: -36px -40px;
  content: '';
}
.common_header .util_myssg li .util_myssg_like {
  display: block;
  a {
    .cmlike_ico {
      margin: 6px 3px 0 -3px;
    }
    &:hover {
      .cmlike_ico {
        .cmlike_primary_xs {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M0 0h16v16H0z'/%3E%3Cpath fill='%23fff' stroke-width='0' stroke='%23fff' fill-rule='nonzero' d='M10.8 2.5a3.5 3.5 0 00-2.73 1.58L8 4.2l-.07-.12A3.5 3.5 0 001.5 6a4.08 4.08 0 001.14 2.75L8 14.1l5.35-5.36a4.08 4.08 0 001.15-2.74A3.5 3.5 0 0011 2.5h-.2zM7.5 5.83V6h1a2.5 2.5 0 015 0l-.01.2a3.07 3.07 0 01-.85 1.85L8 12.7 3.35 8.05a3.06 3.06 0 01-.85-2.06 2.5 2.5 0 015-.15z'/%3E%3C/g%3E%3C/svg%3E");
        }
      }
    }
    &:after {
      display: none;
    }
  }
}
.common_header .direct_link {
  position: absolute;
  top: 42px;
  right: 0;
  z-index: 11;
}
.common_header .direct_link li {
  float: left;
  margin-left: 8px;
  padding-left: 9px;
  background: url(#{$imgPath}/common_layout/bg_vertical_util.gif) no-repeat 0 5px;
}
.common_header .direct_link li:first-child {
  margin-left: 0;
  padding-left: 0;
  background: none;
}
.common_header .direct_link li img {
  vertical-align: top;
}
.common_header.other_site .util_menu {
  top: 13px;
  left: 799px;
}
.common_header.rank.other_site .util_menu {
  top: 17px;
  left: 825px;
}
.common_header.other_site .direct_link {
  top: 38px;
}
/* 공통헤더 로고 */
.common_header .logo_section {
  position: absolute;
  top: 0;
  left: 186px;
  width: 270px;
  height: 80px;
  text-align: right;
  overflow: hidden;
}
.common_header.rank .logo_section {
  left: 130px;
  top: 0;
}
.common_header .logo_section a {
  display: inline-block;
  color: #000;
  text-decoration: none;
  width: 270px;
  height: 83px;
  font-size: 21px;
  cursor: pointer;
  text-align: right;
}
.common_header .logo_section span {
  display: block;
  font-family: $fontM;
  font-weight: 500;
}
.common_header .logo_section .name_x {
  font-size: 26px;
  display: inline-block;
  width: 200px;
  text-align: center;
  line-height: 26px;
  padding-top: 16px;
}
.common_header .logo_section .name_x .name {
  display: none;
}
.common_header .logo_section .name_o {
  font-size: 17px;
  display: inline-block;
  width: 200px;
  text-align: center;
  font-weight: bold;
  line-height: 24px;
  padding-top: 20px;
}
.common_header .logo_section .name_o .msg {
  font-size: 18px;
}
.common_header .logo_section .name_o .msg br {
  display: none;
}
.common_header .logo_section .name_o .name {
  font-size: 21px;
}
.common_header .logo_section .bg_birthday {
  position: absolute;
  top: 0;
  right: 0;
  height: 83px;
  width: 270px;
  background-image: url(#{$imgPath}/vvip/ty_birthday.png);
  background-position: 0 0;
  background-repeat: no-repeat;
  animation: logo_bg2 3s steps(49) infinite;
  -webkit-animation: bg_birthday 3s steps(49) infinite;
  -ms-animation: bg_birthday 3s steps(49) infinite;
}
.common_header .logo_section.ie .bg_birthday {
  background: url(#{$imgPath}/vvip/ty_birthday.gif) 0 0 no-repeat;
  animation: none;
  -webkit-animation: none;
  z-index: -1;
}
.common_header .logo_section h1.logo img {
  margin-left: -4px;
  vertical-align: top;
}
.common_header.other_site .logo_section {
  top: 24px;
  left: 296px;
  width: auto;
  height: auto;
}
.common_header.rank.other_site .logo_section {
  top: 28px;
  left: 210px;
}
.common_header.other_site .logo_section a {
  width: auto;
  height: auto;
}
.common_header.other_site .logo_section h1.logo img {
  margin-left: 0;
}

@keyframes bg_birthday {
  from {
    background-position: 0 0;
    width: 270px;
    height: 83px;
  }
  to {
    background-position: 0 -4067px;
    width: 270px;
    height: 83px;
  }
}
@-webkit-keyframes bg_birthday {
  from {
    background-position: 0 0;
    width: 270px;
    height: 83px;
  }
  to {
    background-position: 0 -4067px;
    width: 270px;
    height: 83px;
  }
}
@-ms-keyframes bg_birthday {
  from {
    background-position: 0 0;
    width: 270px;
    height: 83px;
  }
  to {
    background-position: 0 -4067px;
    width: 270px;
    height: 83px;
  }
}

/* 공통헤더 급상승검색어 */
.cmjump_rank {
  position: absolute;
  top: 26px;
  left: 720px;
  z-index: 31;
}
:lang(en) .cmjump_rank,
:lang(zh) .cmjump_rank {
  visibility: hidden;
  left: -100%;
}
.cmjump_rank .cmjump_totalrank {
  overflow: hidden;
  width: 145px;
  height: 36px;
  border: 2px solid #3a3a3a;
  border-left: 0 none;
  background-color: #fff;
}
.cmjump_rank .cmjump_totalrank:before {
  position: absolute;
  top: 2px;
  left: 0;
  width: 1px;
  height: 36px;
  background: #e8e8e8;
  content: '';
}
.cmjump_rank .cmjump_totalrank_tit {
  display: block;
  padding-left: 10px;
  font-family: $fontR;
  font-weight: 400;
  font-size: 13px;
  line-height: 14px;
  color: #222;
}
.cmjump_rank .cmjump_keyword_info {
  display: block;
  padding: 5px 0 8px 8px;
  font-family: $fontR;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: -0.5px;
  color: #b1b1b1;
}
.cmjump_rank .cmjump_totalrank .cmjump_totalrank_cont {
  overflow: hidden;
}
.cmjump_rank .cmjump_rank_ico {
  display: inline-block;
  background: url(#{$imgPath}/common_layout/sp_cmjump_rank_ico.png) no-repeat;
  vertical-align: top;
}
.cmjump_rank .cmjump_rank_ico {
  width: 11px;
  height: 10px;
  margin-top: 7px;
}
.cmjump_rank .cmjump_rank_up {
  color: get-color('primary');
}
.cmjump_rank .cmjump_rank_up .cmjump_rank_ico {
  width: 11px;
  height: 10px;
  background-position: 0 0;
}
.cmjump_rank .cmjump_rank_down {
  color: #343aa3;
}
.cmjump_rank .cmjump_rank_down .cmjump_rank_ico {
  background-position: 0 -50px;
}
.cmjump_rank .cmjump_rank_same .cmjump_rank_ico {
  background-position: 0 -100px;
  margin: 12px 10px 0 0;
}
.cmjump_rank .cmjump_rank_new {
  color: #000;
}
.cmjump_rank .cmjump_rank_new .cmjump_rank_ico {
  width: 23px;
  vertical-align: -2px;
  background-position: 0 -150px;
}
.cmjump_rank .cmjump_rank_link {
  display: block;
  overflow: hidden;
  position: relative;
  padding: 6px 45px 6px 8px;
  font-family: $fontR;
  font-weight: 400;
  font-size: 13px;
  line-height: 25px;
  color: #222;
  text-decoration: none;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.cmjump_rank .cmjump_rank_num {
  font-size: 14px;
  letter-spacing: -0.5px;
  vertical-align: top;
  color: #222;
  font-family: $fontR;
  font-weight: 400;
}
.cmjump_rank .cmjump_rank_state {
  position: absolute;
  top: 6px;
  right: 10px;
  font-family: $fontM;
  font-weight: 500;
  font-size: 13px;
  line-height: 25px;
  letter-spacing: -0.5px;
  text-align: right;
}
.cmjump_rank .cmjump_lyr {
  display: none;
  position: absolute;
  top: -5px;
  left: -82px;
  z-index: 11;
  width: 230px;
  height: 370px;
  margin-top: 5px;
  border: solid 1px #d1d1d1;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.03);
  background-color: #fff;
}
.cmjump_rank .cmjump_lyr.on,
.cmjump_rank .cmjump_lyr_cont .cmjump_lyr_panel.on {
  display: block;
}
.cmjump_rank .cmjump_lyr_cont {
  padding: 15px 0 15px 15px;
}
.cmjump_rank .cmjump_lyr_cont .cmjump_rank_lst {
  padding-top: 5px;
}
.cmjump_rank .cmjump_lyr_cont .cmjump_rank_link {
  padding: 0 48px 0 0;
}
.cmjump_rank .cmjump_lyr_cont .cmjump_rank_item {
  display: block;
  width: 200px;
}
.cmjump_rank .cmjump_lyr_cont .cmjump_rank_num {
  font-size: 12px;
  letter-spacing: -0.3px;
  vertical-align: top;
  color: #888;
  padding-right: 5px;
}
.cmjump_rank .cmjump_lyr_cont .cmjump_rank_same .cmjump_rank_ico {
  margin-right: 0;
}
.cmjump_rank .cmjump_lyr_cont .cmjump_lyr_tab li {
  display: inline-block;
  width: 100px;
  height: 30px;
  margin-right: -3px;
}
.cmjump_rank .cmjump_lyr_cont .cmjump_lyr_tab li button {
  float: left;
  font-family: $fontR;
  font-weight: 400;
  color: #969696;
  width: 100px;
  height: 30px;
  line-height: 14px;
  text-align: center;
  vertical-align: middle;
  font-size: 12px;
  border: 1px solid #d8d8d8;
  background-color: #f4f4f4;
}
.cmjump_rank .cmjump_lyr_cont .cmjump_lyr_tab li.on button {
  color: #222;
  background-color: #fff;
}
.cmjump_rank .cmjump_lyr_cont .cmjump_rank_state {
  top: 0;
}
.cmjump_rank .cmjump_lyr_cont .cmjump_lyr_panel {
  display: none;
}
.cmjump_rank .cmjump_lyr_cont .cmjump_rank_item {
  display: block;
  width: 200px;
}
.cmjump_rank .cmjump_lyr_cont .cmjump_lyr_panel.on .cmjump_rank_item.bold .cmjump_rank_tx {
  font-weight: bold;
}
.other_site .cmjump_rank {
  top: 21px;
  left: 675px;
}
.other_site .cmjump_rank .cmjump_totalrank_tit {
  margin-top: 8px;
}
.other_site .cmjump_rank .cmjump_totalrank:before {
  position: absolute;
  top: 2px;
  left: 0;
  width: 1px;
  height: 30px;
  background: #e8e8e8;
  content: '';
}
.other_site .cmjump_rank .cmjump_totalrank {
  height: 30px;
}
.other_site .cmjump_rank .cmjump_totalrank .cmjump_rank_link {
  padding: 3px 45px 0 8px;
}
.other_site .cmjump_rank .cmjump_totalrank .cmjump_rank_state {
  top: 3px;
}
@media screen and (-webkit-min-device-pixel-ratio: 2) {
  .cmjump_rank .cmjump_rank_ico {
    background: url(#{$imgPath}/common_layout/sp_cmjump_rank_ico_retina.png) no-repeat;
    background-size: 320px 240px;
  }
  .cmjump_rank .cmjump_rank_up .cmjump_rank_ico {
    background-position: 0 0;
  }
  .cmjump_rank .cmjump_rank_down .cmjump_rank_ico {
    background-position: 0 -27px;
  }
  .cmjump_rank .cmjump_rank_same .cmjump_rank_ico {
    background-position: 0 -52px;
  }
  .cmjump_rank .cmjump_rank_new .cmjump_rank_ico {
    background-position: 0 -77px;
  }
  .cmjump_rank .cmjump_lyr .cmjump_lyr_close:after {
    background-image: url(#{$imgPath}/common_layout/sp_cm_jump_ico_set_retina.png);
    background-position: -72px 0px;
    width: 20px;
    height: 20px;
    background-size: 92px 65px;
  }
}

/* 몰탭 + 검색창 개편 (s)*/
.cmicon {
  display: inline-block;
  vertical-align: middle;
  font-size: 0;
  line-height: normal;
  .icon,
  .logo {
    display: inline-block\9;
  }
}
// 검색창
.key_word_renew,
.auto_word.renew {
  border: solid 1px rgba(0, 0, 0, 0.07);
  border-top: 0;
}
.key_word_renew {
  overflow: hidden;
  padding-bottom: 15px;
  text-align: left;
  box-sizing: border-box;
  background: #fff;
  font-size: 13px;
  .tit_area {
    padding-top: 15px;
    color: #000;
    margin: 0 15px;
    strong {
      font-weight: bold;
    }
  }
  .result_area {
    padding-top: 5px;
    li {
      position: relative;
      padding: 0 15px;
      line-height: 16px;
      margin-right: 1px;
      &.on,
      &:hover {
        background: #fafafa;
      }
      a {
        display: block;
        overflow: hidden;
        padding: 5px 36px 5px 0;
        color: #777;
        letter-spacing: -0.03em;
        text-decoration: none;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
      }
    }
    .btn_del {
      overflow: hidden;
      position: absolute;
      top: 50%;
      right: 15px;
      width: 19px;
      height: 19px;
      margin-top: -10px;
      line-height: 19px;
      background: none;
      .icon_close {
        opacity: 0.4;
      }
    }
    .result_clear {
      padding-top: 10px;
      margin: 5px 15px 0;
      border-top: 1px solid #f0f0f0;
      text-align: right;
      .btn_clear {
        font-size: 13px;
        color: #969696;
        background: none;
      }
    }
  }
  .nodata {
    padding: 40px 0 45px;
    text-align: center;
    color: #777;
  }
}
.auto_word.renew {
  position: relative;
  width: 100%;
  padding-top: 3px;
  top: 0;
  left: 0;
  text-align: left;
  .word_shortcut {
    position: relative;
    margin: 0 15px;
    padding: 12px 15px 0;
    font-size: 13px;
    color: #000;
    background: #fff;
    white-space: nowrap;
    text-overflow: ellipsis;
    border: 0;
    a {
      padding: 12px 35px 12px 15px;
      border-radius: 4px;
      border: 1px solid #e5e5e5;
      &:after {
        content: none;
      }
      .icon_market {
        margin-right: 5px;
      }
    }
    &:after {
      right: 15px;
    }
    .word_shortcut_arrow {
      position: absolute;
      right: 15px;
      top: 50%;
      margin-top: -8px;
      width: 16px;
      height: 16px;
    }
  }
  .word_list {
    padding: 12px 0;
    min-height: auto;
    li {
      line-height: 26px;
      > a {
        padding: 0 72px 0 15px;
        color: #777;
        &.match:after {
          content: none;
        }
        .word_date {
          font-size: 13px;
        }
      }
      &:hover {
        background: #fafafa;
      }
      .ad_thmb {
        margin-top: 15px;
      }
      .ad_detail {
        .link {
          text-align: left;
          padding-top: 10px;
        }
        .tx {
          color: #777;
        }
      }
      .ad {
        top: 1px;
        bottom: 2px;
        border-bottom-right-radius: 20px;
        .link {
          border-radius: 4px;
          overflow: hidden;
        }
        &:before {
          background: #f0f0f0;
          margin: 15px 0;
        }
      }
    }
    .word_lnk {
      display: inline-block;
      width: 16px;
      height: 16px;
      line-height: 16px;
      opacity: 0.6;
      margin: -3px 0 0 -5px;
    }
  }
  &.ad_showing {
    .word_list li > a {
      padding-right: 240px;
    }
  }
}
@mixin gradientBorder() {
  padding: 1px;
  border-radius: 4px;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1 1' preserveAspectRatio='none'%3E%3Cdefs%3E%3ClinearGradient id='fh8cypk2ya' x1='5.138%25' x2='99.194%25' y1='50.219%25' y2='49.719%25'%3E%3Cstop offset='0%25' stop-color='%23FECC1F'/%3E%3Cstop offset='33.288%25' stop-color='%23FE3A6B'/%3E%3Cstop offset='55.717%25' stop-color='%23A33FFA'/%3E%3Cstop offset='77.639%25' stop-color='%233798FB'/%3E%3Cstop offset='100%25' stop-color='%2326D866'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect fill='url(%23fh8cypk2ya)' x='0' y='0' width='1' height='1'/%3E%3C/svg%3E%0A");
  background: get-color('ssg_brand');
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  // -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}
.common_header.renew.v2 {
  position: relative;
  z-index: 400;
  &:after {
    display: block;
    clear: both;
    content: '';
  }
  .common_header_inner {
    position: relative;
    z-index: 300;
    width: 1280px;
    margin: 0 auto;
    padding-top: 13px;
    height: 89px;
  }
  &.sub_site {
    width: 100%;
    box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.05);
  }
  &.other_site {
    width: 100%;
    box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.05);
    .common_header_inner {
      height: 47px;
    }
    .gnb_renew_logo {
      top: 22px;
      margin: 0 15px 0 0;
    }
    .gnb_renew_mall_gate {
      .btn_label {
        padding-top: 1px;
        width: 18px;
        height: 17px;
      }
    }
    .gnb_renew_search {
      margin: 0 0 0 140px;
      text-align: left;
    }
    .gnb_renew_util {
      top: 22px;
      right: 178px;
      &:before {
        position: absolute;
        right: -16px;
        top: -3px;
        width: 1px;
        height: 25px;
        background: #f0f0f0;
        content: '';
      }
    }
    .gnb_renew_menu {
      top: 16px;
    }
    .gnb_searching_box .inp_txt {
      height: 26px;
    }
    .gnb_renew_mall_layer {
      top: -10px;
    }
    //몰 급상승 검색어
    .cmjump_rank.renew {
      top: 6px;
      .cmjump_lyr {
        top: 2px;
        left: -1px;
        .cmjump_totalrank .cmjump_rank_state {
          top: 7px;
        }
      }
    }
  }
  .auto_word.renew {
    position: static;
  }
  .key_word_renew,
  .auto_word.renew {
    padding-top: 45px;
    border-radius: 4px;
    border: 0;
    @media \0screen {
      border: 1px solid #000;
    }
    &:before {
      position: absolute;
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      top: -1px;
      left: -1px;
      right: 0;
      bottom: 0;
      z-index: -1;
      box-sizing: border-box;
      @include gradientBorder();
      content: '';
    }
  }
  .gnb_search_wrap {
    &.ty_mall {
      .key_word_renew {
        padding-top: 28px;
      }
      .auto_word.renew {
        padding-top: 28px;
        .icon_shortcut_lnk {
          right: 12px;
        }
      }
    }
  }
}
.cmjump_rank.renew {
  display: inline-block;
  top: 38px;
  left: auto;
  margin-left: 20px;
  text-align: left;
  .cmjump_rank_ico {
    width: 16px;
    height: 16px;
    margin-top: -2px;
    vertical-align: middle;
    background-image: none;
  }
  .cmjump_totalrank {
    width: 155px;
    padding: 6px 0;
    border: none;
    background-color: transparent;
    &:before {
      content: none;
    }
  }
  .cmjump_totalrank_cont {
    .cmjump_rank_num {
      font-size: 15px;
      font-weight: bold;
      margin-right: 4px;
      color: #000;
    }
    .cmjump_rank_link {
      text-overflow: clip;
      padding: 6px 0;
      font-weight: bold;
      color: #000;
      .cmjump_rank_tx {
        display: inline-block;
        white-space: nowrap;
        max-width: 125px;
        text-overflow: ellipsis;
        overflow: hidden;
        vertical-align: top;
        max-width: 125px;
      }
    }
    .cmjump_rank_state {
      position: relative;
      right: 2px;
      top: 0;
      padding-right: 2px;
      &.cmjump_rank_new,
      &.cmjump_rank_same {
        padding-left: 3px;
      }
    }
  }
  .cmjump_lyr {
    width: 247px;
    height: 462px;
    top: -8px;
    left: 0px;
    border-radius: 8px;
    box-shadow: 6px 6px 8px 0 rgba(0, 0, 0, 0.1);
    border-color: rgba(0, 0, 0, 0.07);
  }
  .cmjump_lyr_cont {
    padding: 20px;
    .cmjump_lyr_tab {
      position: relative;
      border-radius: 4px;
      border: solid 1px rgba(0, 0, 0, 0.07);
      box-sizing: border-box;
      height: 35px;
      &:before {
        position: absolute;
        left: 50%;
        top: 11px;
        height: 13px;
        width: 1px;
        background-color: #f0f0f0;
        z-index: -1;
        content: '';
      }
      li {
        width: 50%;
        height: 35px;
        font-size: 0;
        button {
          width: 100%;
          height: 35px;
          border: 0;
          font-size: 13px;
          color: #777;
          background-color: transparent;
        }
        &.on {
          button {
            color: #222;
            font-weight: bold;
          }
        }
      }
    }
    .cmjump_rank_lst {
      padding-top: 15px;
    }
    .cmjump_rank_state {
      top: -3px;
      right: 0;
      text-align: left;
      width: 40px;
    }
    .cmjump_rank_item {
      width: 100%;
    }
  }
  .cmjump_totalrank_tit {
    padding: 0 0 15px 0;
    font-weight: bold;
  }
  .cmjump_keyword_info {
    padding: 15px 0 0 0;
    border-top: 1px solid #f0f0f0;
    color: #969696;
    font-size: 13px;
    text-align: right;
  }
  .cmjump_lyr_scont {
    .cmjump_rank_item {
      padding-bottom: 15px;
    }
    .cmjump_rank_link {
      line-height: 16px;
      color: #777;
      padding: 0 60px 0 0;
    }
    .cmjump_rank_num {
      font-size: 13px;
      color: #000;
      font-weight: bold;
    }
  }
  .cmjump_rank_down {
    color: #22009c;
  }
  .cmjump_rank_up {
    color: get-color('primary');
  }
}
.gnb_renew_logo {
  position: absolute;
  top: 46px;
  .gnb_logo_wrap {
    position: relative;
    display: inline-block;
    > h1 {
      display: inline-block;
      vertical-align: top;
    }
    .gnb_logo_ssg {
      .gnb_logo_lnk {
        display: block;
        vertical-align: middle;
      }
    }
    &.is_open {
      .gnb_renew_mall_gate {
        .btn_label {
          &:before {
            transform: rotate(180deg);
          }
          &.ty_on {
            display: block;
          }
          &.ty_off {
            display: none;
          }
        }
      }
      .gnb_renew_mall_layer {
        visibility: visible;
        animation: gnb-mall-layer 0.25s ease-out forwards;
      }
    }
    &.is_close {
      .gnb_renew_mall_layer {
        visibility: hidden;
        animation: gnb-mall-layer-close 0.2s ease-out forwards;
      }
    }
  }
  .gnb_renew_mall_gate {
    display: inline-block;
    box-sizing: border-box;
    text-align: center;
    background: #fff;
    .btn_label {
      display: inline-block;
      border-radius: 50%;
      border: solid 1px rgba(34, 34, 34, 0.1);
      padding-top: 5px;
      width: 26px;
      height: 21px;
      &.ty_on {
        display: none;
      }
      .cmicon {
        vertical-align: top;
      }
    }
  }
  // 몰 이동 레이어
  .gnb_renew_mall_layer {
    position: absolute;
    overflow: hidden;
    top: -13px;
    left: -6px;
    z-index: 2100;
    width: 324px;
    padding: 20px 12px 12px;
    border-radius: 20px;
    border: solid 1px rgba(0, 0, 0, 0.03);
    box-sizing: border-box;
    box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    visibility: hidden;
    .gnb_mall_ssg {
      position: relative;
      padding-bottom: 12px;
      margin: 0 6px;
      border-bottom: 1px solid #f0f0f0;
      .gnb_mall_ssglnk {
        position: relative;
        display: inline-block;
        line-height: 28px;
        height: 28px;
        margin: 0 16px 0 30px;
        &:before {
          content: '';
          position: absolute;
          top: 50%;
          left: -30px;
          width: 28px;
          height: 28px;
          border-radius: 50%;
          overflow: hidden;
          transform: translateY(-50%);
          background: url(#{$imgPath}/common/gnb_badge_ssg.jpg) center no-repeat;
          background-size: cover;
        }
      }
      .gnb_renew_mall_gate {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        margin-top: -6px;
      }
    }
    .gnb_mall_lst {
      &:after {
        clear: both;
        display: block;
        content: '';
      }
      > li {
        float: left;
        width: 50%;
        padding-top: 7px;
      }
    }
    .gnb_mall_lnk {
      display: inline-block;
      padding: 5px 6px;
      border-radius: 16px;
      text-decoration: none;
      white-space: nowrap;
      &.on {
        padding-right: 12px;
        .gnb_mall_name {
          font-weight: bold;
        }
        .gnb_mall_bedge {
          display: none;
        }
      }
    }
    .gnb_mall_name {
      overflow: hidden;
      display: inline-block;
      vertical-align: top;
      max-width: 108px;
      height: 20px;
      line-height: 20px;
      box-sizing: border-box;
      padding: 1px 0 0 3px;
      color: #222;
      font-size: 13px;
      letter-spacing: -0.43px;
    }
    .gnb_mall_thumb {
      overflow: hidden;
      display: inline-block;
      vertical-align: top;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      img {
        width: 100%;
        vertical-align: middle;
      }
    }
    .gnb_mall_bedge {
      display: inline-block;
      vertical-align: top;
      height: 15px;
      line-height: 15px;
      padding: 0 3px 0 3px;
      margin: 3px 0 0 2px;
      border: solid 1px transparent;
      border-radius: 3px;
      box-sizing: border-box;
      font-family: $fontM;
      font-weight: 500;
      font-size: 9px;
      text-align: center;
      &.ty_ad {
        color: #969696;
        border-color: #e5e5e5;
      }
      /* 백화점 개편 : 오픈 딱지 임시 수정(s) */
      // &.ty_open {
      // 	max-width: 34px;
      // 	color: #fff;
      // 	border-color: get-color('primary');
      // 	background-color: get-color('primary');
      // }
      &.ty_open {
        overflow: hidden;
        color: transparent;
        max-width: 34px;
        color: #fff;
        border-color: get-color('primary');
        background-color: get-color('primary');
        &:before {
          content: 'NEW';
          display: block;
          color: #fff;
        }
      }
      &.ty_icon_txt {
        max-width: 34px;
        color: #fff;
        border-color: get-color('primary');
        background-color: get-color('primary');
      }
    }
  }
}
.gnb_renew_search {
  margin: 23px auto 0;
  text-align: center;
}
.gnb_search_wrap {
  position: relative;
  display: inline-block;
  text-align: left;
  width: 500px;
  height: 48px;
  @media \0screen {
    border: 1px solid #000;
  }
  &:before {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    @include gradientBorder();
    background-size: 300%, 300%;
    background-size: contain\9;
    animation: animatedgradient 5s ease alternate infinite;
    content: '';
  }
  &.ty_mall {
    width: 218px;
    height: 30px;
    top: 2px;
    .gnb_search_btn {
      width: 20px;
      height: 20px;
      right: 3px;
      top: 3px;
    }
    .gnb_searching_inp .inp_txt {
      padding-left: 11px;
      width: calc(100% - 31px);
      &::placeholder {
        font-size: 12px;
      }
    }
  }
  .key_word_renew .tit_area {
    border-top: 1px solid #f0f0f0;
  }
}
.gnb_searching_box {
  position: relative;
  overflow: hidden;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  margin-top: 1px;
  margin-left: 1px;
  border-radius: 4px;
  font-size: 0;
  background: #fff;
  z-index: 300;
  .inp_txt {
    padding-left: 16px;
    margin-top: 1px;
    width: calc(100% - 16px);
    height: 45px;
    min-height: 26px;
    border: 0;
    font-size: 13px;
    letter-spacing: -1px;
    line-height: 45px\9;
    font-family: $fontR;
    font-weight: 400;
    &::-ms-clear {
      display: none;
    }
    &:focus {
      outline: none;
    }
  }
  .gnb_searching_inp {
    .inp_txt {
      padding-left: 16px;
      &::placeholder {
        color: #777;
        font-size: 14px;
        opacity: 1; /* 파이어폭스에서 뿌옇게 나오는 현상을 방지하기 위한 css */
      }
      &:-webkit-input-placeholder {
        color: #777;
        font-size: 14px;
      }
      /* IE */
      &:-ms-input-placeholder {
        color: #777;
        font-size: 14px;
      }
      /* Firefox */
      &:-mos-input-placeholder {
        color: #777;
        font-size: 14px;
      }
    }
  }
  .gnb_search_btn {
    position: absolute;
    width: 28px;
    height: 28px;
    right: 8px;
    top: 8px;
    background: #fff;
  }
}
.gnb_search_word {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  background: #fff;
  box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.1);
  z-index: 200;
}
.gnb_renew_util {
  position: absolute;
  right: 0;
  z-index: 20;
  width: auto;
  top: 12px;
  display: flex;
  align-items: center;
  &:after {
    display: block;
    clear: both;
    content: '';
  }
  .gnb_util {
    float: right;
    &:after {
      display: block;
      clear: both;
      content: '';
    }
    li {
      float: left;
      margin-right: 3px;
      &:not(:first-child):before {
        display: inline-block;
        margin-right: 3px;
        color: #969696;
        content: '·';
      }
      a {
        line-height: 19px;
        letter-spacing: -0.5px;
        text-decoration: none;
        font-size: 12px;
        color: #777;
      }
      &.gnb_tx_user {
        margin-right: 16px;
        + li:before {
          content: none;
        }
        a {
          font-weight: bold;
        }
      }
    }
  }
  .gnb_mmbrs {
    &.ty_smileclub {
      .gnb_mmbrs_txt {
        color: #966e46;
      }
    }
    .gnb_mmbrs_link {
      display: flex;
      align-items: center;
      &::after {
        content: '';
        display: block;
        width: 1px;
        height: 10px;
        margin: 0 8px;
        background-color: #e5e5e5;
      }
    }
    .gnb_mmbrs_new {
      font-family: 'Helvetica', 'Arial', sans-serif;
      font-weight: 700;
      font-size: 11px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1px 1px 0 1px;
      width: 12px;
      height: 13px;
      line-height: 13px;
      border-radius: 16px;
      color: #fff;
      background: linear-gradient(
        57.38deg,
        #fecc1f 6.24%,
        #fe3a6b 40.38%,
        #a33ffa 57.32%,
        #3798fb 76.49%,
        #26d866 92.2%
      );
    }
    .gnb_mmbrs_txt {
      padding: 0 4px;
      font-weight: bold;
      font-size: 12px;
      height: 20px;
      line-height: 22px;
      color: #222;
    }
  }
}
.gnb_renew_menu {
  position: absolute;
  top: 52px;
  right: 0;
  .gnb_menu {
    z-index: 41;
    &:after {
      display: block;
      clear: both;
      content: '';
    }
    > li {
      float: left;
      position: relative;
      margin-left: 12px;
    }
    .gnb_util_num {
      position: absolute;
      top: -4px;
      left: 50%;
      min-width: 12px;
      margin-left: 3px;
      padding: 0 3px;
      background: get-color('primary');
      border-radius: 9px;
      font-size: 11px;
      line-height: 19px;
      height: 18px;
      color: #fff;
      text-align: center;
    }
  }
}
@keyframes animatedgradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-webkit-keyframes gnb-mall-layer {
  0% {
    opacity: 1;
    height: 0;
  }
  100% {
    visibility: visible;
    height: inherit;
  }
}
@keyframes gnb-mall-layer {
  0% {
    opacity: 1;
    height: 0;
  }
  100% {
    visibility: visible;
    height: inherit;
  }
}
@-webkit-keyframes gnb-mall-layer-close {
  0% {
    opacity: 1;
    height: inherit;
    visibility: visible;
  }
  80% {
    opacity: 1;
  }
  100% {
    visibility: hidden;
    height: 0;
    opacity: 0;
  }
}
@keyframes gnb-mall-layer-close {
  0% {
    opacity: 1;
    height: inherit;
    visibility: visible;
  }
  80% {
    opacity: 1;
  }
  100% {
    visibility: hidden;
    height: 0;
    opacity: 0;
  }
}
//몰이동 레이어
.body_wide {
  .gnb_mall_lnk.on {
    background-color: #f5f5f5;
  }
}
.body_sm {
  .gnb_mall_lnk.on {
    background-color: #f12e24;
    .gnb_mall_name {
      color: #fff;
    }
  }
}
.body_sd {
  .gnb_mall_lnk.on {
    background-color: #6a6b6d;
    .gnb_mall_name {
      color: #fff;
    }
  }
}
.body_em {
  .gnb_mall_lnk.on {
    background-color: #ffd040;
  }
}
.body_traders {
  .gnb_mall_lnk.on {
    background-color: #a6dd27;
  }
}
.body_morning {
  .gnb_mall_lnk.on {
    background-color: #8097af;
  }
}
.body_mnstarbucks {
  .gnb_mall_lnk.on {
    background-color: #037042;
    .gnb_mall_name {
      color: #fff;
    }
  }
}
.body_mncasamia {
  .gnb_mall_lnk.on {
    background-color: #f5f5f5;
  }
}
.body_mnchicor {
  .gnb_mall_lnk.on {
    background-color: #000;
    .gnb_mall_name {
      color: #fff;
    }
  }
}
.body_howdy {
  .gnb_mall_lnk.on {
    background-color: #000;
    .gnb_mall_name {
      color: #fff;
    }
  }
}
.body_tv {
  .gnb_mall_lnk.on {
    .cm_mall_ic > i {
      background-image: none;
      &::after {
        background-image: url('data:image/svg+xml;utf-8,<svg width="26" height="16" viewBox="0 0 26 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M25.9999 14.9004H15.3276V0.5H17.9056V12.6199H25.9999V14.9004Z" fill="white"/><path d="M4.60391 6.34812C3.47165 6.01242 2.94529 5.37807 2.94529 4.54229C2.94529 3.4611 4.06819 2.6068 5.89525 2.6068C7.69892 2.6068 9.00196 3.55834 9.47217 5.04468L11.6946 4.46126C10.8992 1.78029 8.57853 0.5 5.76659 0.5C2.37915 0.5 0.472553 2.50494 0.472553 4.59554C0.472553 6.36665 1.35684 7.53581 2.7558 8.14701C4.16177 8.76053 5.84846 8.85777 7.2942 9.21662C8.6838 9.56158 9.48153 10.1612 9.48153 11.1753C9.48153 12.4417 8.19955 13.3376 6.32336 13.3376C4.50332 13.3376 2.90551 12.319 2.31131 10.5548L0 11.1822C0.624616 13.9951 3.20028 15.5 6.314 15.5C9.8956 15.5 11.9449 13.4325 11.9449 11.0965C11.9449 9.20273 10.8197 7.91781 9.10956 7.32513C7.47901 6.76022 5.89057 6.73013 4.60391 6.34812Z" fill="white"/></svg>');
        background-position: center;
        background-size: 16px;
      }
    }
    background: linear-gradient(90deg, #ff329b 0%, #fc5653 59.9%, #ff9100 100%);
    .gnb_mall_name {
      color: #fff;
    }
  }
}
.body_siv {
  .gnb_mall_lnk.on {
    background-color: #f5f5f5;
  }
}
.body_outlet {
  .gnb_mall_lnk.on {
    background-color: #d9117c;
    .gnb_mall_name {
      color: #fff;
    }
  }
}
.body_mntrip {
  .gnb_mall_lnk.on {
    background-color: #328ca8;
    .gnb_mall_name {
      color: #fff;
    }
  }
}
.body_sf {
  .gnb_mall_lnk.on {
    background-color: #b12536;
    .gnb_mall_name {
      color: #fff;
    }
  }
}
/* 몰탭 + 검색창 개편 (e) */
