/* 1280px container */
.body_wide .left_wing_bx {
  top: 260px;
  margin-left: -739px;
}
.body_wide.body_ssg .left_wing_bx {
  top: 200px;
}
.body_wide .leftbnr_wing {
  top: 260px;
  margin-left: -739px;
}
.body_wide .sky_banner_left {
  top: 154px;
  left: 50%;
  margin-left: -739px;
}
.body_wide .main .sky_banner_left {
  top: 154px;
}
.body_wide .main .sky_banner_left.top_banner_visible {
  top: 154px;
}

/* 좌측 날개배너 */
.left_wing_bx {
  position: absolute;
  left: 50%;
  top: 190px;
  margin-left: -609px;
}
.left_wing_bx .promotion_bnr_area {
  position: relative;
  margin-top: 6px;
}
.left_wing_bx .promotion_bnr_area .bn_blist {
  overflow: hidden;
}
.left_wing_bx .promotion_bnr_area .bn_blist li:first-child {
  display: block;
}
.left_wing_bx .promotion_bnr_area .bn_blist li {
  display: none;
}
.left_wing_bx .promotion_bnr_area .bx-wrapper .bn_blist li {
  display: block;
}
.left_wing_bx .bn_cf_left {
  position: relative;
  margin-top: 10px;
}
.left_wing_bx .bn_blist_benefit,
.left_wing_bx .bn_cf_left {
  position: relative;
  top: 0;
  left: 0;
}

.cmsky_card {
  position: absolute;
  top: 260px;
  left: 50%;
  z-index: 400;
  width: 87px;
  margin-left: -739px;
}
.body_ssg .cmsky_card {
  top: 200px;
}
.cmsky_card .cmsky_card_btnview {
  display: none;
}
.cmsky_card .cmsky_card_bnlst {
  margin-top: 6px;
  a {
    display: block;
    width: 77px;
    height: 109px;
    margin-bottom: 6px;

    img {
      width: 100%;
      height: 100%;
      vertical-align: top;
    }
  }
}
.body_wide .cmsky_card .left_wing_bx {
  position: relative;
  top: 0;
  left: 0;
  margin-left: 0;
}
.body_wide.body_ssg .cmsky_card .left_wing_bx {
  top: 0;
}
.body_wide .cmsky_card .leftbnr_wing {
  position: relative;
  top: 0;
  left: 0;
  margin-left: 0;
}
.body_wide .cmsky_card .sky_banner_left {
  position: relative;
  top: 0;
  left: 0;
  margin-left: 0;
}
.body_wide .main .cmsky_card .sky_banner_left {
  top: 0;
}
.body_wide .main .cmsky_card .sky_banner_left.top_banner_visible {
  top: 0;
}

@media only screen and (max-width: 1500px) {
  .cmsky_card {
    left: -87px;
    margin-left: 0;
  }
  .cmsky_card.on {
    left: 0;
  }
  .cmsky_card .cmsky_card_sec {
    position: relative;
    width: 84px;
    padding: 4px 0 4px 2px;
    border: 1px solid #222;
    border-left: 0;
    border-radius: 0 0 9px 0;
    background: #fff;
  }
  .cmsky_card .cmsky_card_btnview {
    display: block;
    position: absolute;
    top: -1px;
    left: 100%;
    width: 50px;
    border: 1px solid #222;
    border-left: 0;
    border-radius: 0 9px 9px 0;
    background: #fff;
    text-align: left;
  }
  .cmsky_card .cmsky_card_btnview:after {
    display: inline-block;
    overflow: hidden;
    position: absolute;
    right: 13px;
    bottom: 11px;
    width: 17px;
    height: 17px;
    background: url(#{$imgPath}/common_layout/sp_cmsky_card_btnview.png) no-repeat;
    vertical-align: top;
    content: '';
  }
  .cmsky_card.on .cmsky_card_btnview:after {
    right: 10px;
    background-position: 0 -50px;
  }
  .cmsky_card .cmsky_card_tx {
    display: inline-block;
    padding: 13px 0 40px 11px;
    font-family: $fontR;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #666;
    vertical-align: top;
  }
  .cmsky_card.on .cmsky_card_tx {
    padding-left: 14px;
  }
  .cmsky_card .cmsky_card_cont {
    visibility: hidden;
  }
  .cmsky_card.on .cmsky_card_cont {
    visibility: visible;
  }
  .cmsky_card.off {
    visibility: hidden;
    z-index: -1;
  }
  .body_wide #wrap .sky_scraper.off {
    visibility: hidden;
    z-index: -1;
  }
  :lang(en) .cmsky_card .cmsky_card_tx,
  :lang(zh) .cmsky_card .cmsky_card_tx {
    padding-left: 8px;
  }
  :lang(en) .cmsky_card.on .cmsky_card_tx,
  :lang(zh) .cmsky_card.on .cmsky_card_tx {
    padding-left: 8px;
  }
}

/* 고해상도 대응 */
@media screen and (-webkit-min-device-pixel-ratio: 2) {
  .cmsky_card .cmsky_card_btnview:after {
    background-image: url(#{$imgPath}/common_layout/sp_cmsky_card_btnview@2x.png);
    background-size: 17px auto;
  }
}

/* 우측 날개배너 */
#skyScraperWrap {
  z-index: 11;
}
.sky_scraper {
  position: absolute;
  top: 290px;
  right: auto;
  left: 50%;
  z-index: 500;
  width: 101px;
  margin-left: 544px;
  background: url(#{$imgPath}/common_layout/bg_common_sky.png) no-repeat -240px -100px;
  font-family: $fontR;
  font-weight: 400;
  line-height: 1.5em;
}
.body_wide .sky_scraper {
  position: absolute;
  top: 288px;
  right: auto;
  left: 50%;
  z-index: 500;
  width: 101px;
  margin-left: 674px;
  background: url(#{$imgPath}/common_layout/bg_common_sky.png) no-repeat -240px -100px;
  font-family: $fontR;
  font-weight: 400;
  line-height: 1.5em;
}

#wrap .sky_scraper {
  top: 190px;
}

.body_wide #wrap .sky_scraper {
  top: 288px;
}
.body_wide.body_ssg #wrap .sky_scraper {
  top: 228px;
}
:lang(en) .body_wide #wrap .sky_scraper,
:lang(zh) .body_wide #wrap .sky_scraper {
  margin-top: 40px;
}
:lang(en) .body_wide #wrap .sky_scraper.active,
:lang(zh) .body_wide #wrap .sky_scraper.active {
  margin-top: 10px;
}

.sky_scraper .btn_sky_mini {
  position: absolute;
  top: -28px;
  right: 0;
  width: 25px;
  height: 25px;
  background: url(#{$imgPath}/common_layout/bg_common_sky.png) no-repeat 0 -575px;
}

.sky_scraper.active {
  width: 395px;
  height: 596px;
  margin-top: -28px;
  margin-left: 244px;
  padding: 0 2px 2px 5px;
  background-position: -350px 0;
  background-color: transparent;
}
.body_wide .sky_scraper.active {
  width: 395px;
  height: 596px;
  margin-top: -28px;
  margin-left: 374px;
  padding: 0 2px 2px 5px;
  background-position: -350px 0;
  background-color: transparent;
}

.sky_scraper.active .btn_sky_mini {
  top: -26px;
  right: -4px;
}
.sky_scraper img {
  vertical-align: middle;
}
.sky_scraper.active .sky_direction,
.sky_scraper.active .sky_top,
.sky_scraper.active .sky_clip,
.sky_scraper.active .sky_delivery,
.sky_scraper .sky_haeder select,
.sky_scraper .history_list li.type_product .txt {
  display: none;
}
.sky_scraper.active .sky_haeder select,
.sky_scraper.active .history_list li.commodity .txt,
.sky_scraper.active .history_list li.brand .txt {
  display: block;
}

.sky_scraper .sky_haeder {
  min-width: 101px;
  height: 18px;
  padding: 12px 0 2px;
  text-align: left;
  *zoom: 1;
}
.sky_scraper .sky_haeder h3 {
  padding: 3px 8px 0 8px;
  font-weight: normal;
}
.sky_scraper .sky_haeder h3:after {
  display: block;
  clear: both;
  content: '';
}
.sky_scraper .sky_haeder h3 .sky_tit {
  float: left;
  font-size: 15px;
  font-weight: normal;
  letter-spacing: 0.5px;
  color: #222;
  font-family: $fontR;
  font-weight: 400;
}
.sky_scraper .sky_haeder h3 strong {
  float: right;
  margin-top: -3px;
  font-family: $fontR;
  font-size: 19px;
  font-weight: 400;
  line-height: 23px;
  color: #e74848;
  vertical-align: top;
}
.sky_scraper .sky_content {
  position: relative;
  height: 186px;
  margin-top: 23px;
  background: url(#{$imgPath}/common_layout/bg_history.gif) repeat-y 12px 0;
}
.sky_scraper .history_list {
  overflow: hidden;
  position: relative;
  z-index: 1000;
  height: 186px;
  margin: 0 0 0 3px;
}

.sky_scraper .history_list li {
  overflow: hidden;
  margin-top: 15px;
  width: 97px;
}
.sky_scraper .history_list li .icon_mall.cm_mall_ic,
.sky_scraper.active .history_list li .icon_mall.cm_mall_ic {
  float: left;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  background: none;
  font-size: 0;
  line-height: 0;
}
.sky_scraper .history_cont {
  float: left;
  width: 72px;
  position: relative;
}
.sky_scraper .history_cont .btn_history_del {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
  background: url(#{$imgPath}/common_layout/btn_history_del.png) 0 0 no-repeat;
}
.sky_scraper .hishover .history_cont .btn_history_del {
  display: block;
}
.sky_scraper .sky_btn_del {
  display: none;
  text-align: right;
  border-top: 1px solid #7d7d7d;
  padding: 9px 15px 0 0;
}
.sky_scraper .sky_btn_del .btn_history_alldel {
  width: 72px;
  height: 25px;
  background: url(#{$imgPath}/common_layout/btn_history_alldel.png) 0 0 no-repeat;
}
:lang(en) .sky_scraper .sky_btn_del .btn_history_alldel {
  background-image: url(#{$imgPath}/common_layout/btn_history_alldel_en.png);
}
:lang(zh) .sky_scraper .sky_btn_del .btn_history_alldel {
  background-image: url(#{$imgPath}/common_layout/btn_history_alldel_zh.png);
}
.history_cont a {
  color: #666;
  text-decoration: none;
}
.history_cont .txt {
  line-height: 18px;
  max-height: 36px;
  overflow: hidden;
}
.history_cont .txt a {
  color: #222;
}
.history_cont .txt .blind_txt {
  display: none;
}
.history_cont .txt .blind_txt a {
  color: #848484;
  text-decoration: none;
}
.history_cont a:hover {
  text-decoration: underline;
}
.history_cont .sky_thumbnail {
  position: relative;
  float: left;
  margin-right: 9px;
}
.history_cont .sky_thumbnail a,
.active .history_cont .sky_thumbnail a {
  display: block;
  position: relative;
  width: 48px;
  height: 48px;
  background: url(#{$imgPath}/common_layout/bg_common_sky.png) no-repeat;
  border: 1px solid #ececec;
  cursor: pointer;
}
.history_cont .sky_thumbnail a img {
  width: 48px;
  height: 48px;
}
.history_cont .sky_thumbnail.medium a img {
  width: 68px;
  height: 48px;
}
.history_cont .sky_thumbnail.adult_only a {
  background-position: -100px -100px;
}
.history_cont .sky_thumbnail.sold_out a {
  background-position: 0 -100px;
}
.history_cont .sky_thumbnail.medium a {
  width: 68px;
  height: 48px;
  border: 1px solid #ececec;
}
.history_cont .sky_thumbnail a .img_hover,
.history_cont .sky_thumbnail a .img_hover02 {
  display: block;
  position: absolute;
  left: -1px;
  top: -1px;
  right: -1px;
  bottom: -1px;
}
.history_cont .sky_thumbnail a:hover .img_hover {
  background: url(#{$imgPath}/common_layout/bg_common_sky.png) no-repeat 0 -400px;
}
.history_cont .sky_thumbnail a:hover .img_hover02 {
  background: url(#{$imgPath}/common_layout/bg_common_sky.png) no-repeat 0 -500px;
}
.history_cont .sky_thumbnail .soldout {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: url(#{$imgPath}/common/bg_itemlst_soldout.png);
  color: #222;
  line-height: normal;
  text-align: center;
}
.history_cont .sky_thumbnail .soldout .bx {
  display: inline-block;
  position: relative;
  width: 100%;
  vertical-align: middle;
}
.history_cont .sky_thumbnail .soldout .tt {
  display: block;
  width: 100%;
  height: 16px;
  background: url(#{$imgPath}/common/bg_soldout_txt_50.png) no-repeat 50% 50%;
  font-size: 0;
  line-height: 0;
  overflow: hidden;
}
.history_cont .sky_thumbnail .soldout .valign {
  display: none;
}
.history_cont .event_end {
  color: #e74848;
  font-size: 12px;
}
.sky_scraper .history_list li.history_txt {
  background: #fff;
  margin: 0;
}
.sky_scraper .history_list li.history_txt p.history_txt {
  height: 113px;
  width: 100px;
  margin: 28px 0 0 0;
  padding-top: 45px;
  text-align: center;
  font-size: 11px;
  line-height: 14px;
  color: #747474;
}
.sky_scraper .history_list li.history_txt p.history_txt span {
  color: #00aac4;
}
.sky_scraper .sky_direction {
  position: relative;
  position: absolute;
  top: 0;
  height: 261px;
}

.sky_scraper .sky_direction button {
  position: absolute;
  width: 101px;
  height: 20px;
  margin-top: 0;
  background: url(#{$imgPath}/common_layout/bg_common_sky.png) no-repeat;
  cursor: pointer;
  z-index: 9999;
}
.sky_scraper .sky_direction .prev {
  left: 0;
  top: 35px;
  background-position: -100px -200px;
}
.sky_scraper .sky_direction .next {
  left: 0;
  bottom: 0;
  background-position: -100px -225px;
}
.sky_scraper .sky_clip {
  border: 1px solid #ff625e;
  position: absolute;
  bottom: -86px;
  width: 99px;
  height: 58px;
}
.sky_scraper .sky_clip a {
  background-image: url(#{$imgPath}/common_layout/bg_common_sky.png);
  display: block;
}
.sky_scraper .sky_clip a.sky_myclip {
  width: 99px;
  height: 25px;
  background-position: -1px -621px;
  position: absolute;
  top: 0;
  left: 0;
}
.sky_scraper .sky_clip a.sky_myclip:hover {
  background-position: -131px -621px;
}
.sky_scraper .sky_clip a.sky_clip_red {
  width: 50px;
  height: 33px;
  background-position: -1px -646px;
  position: absolute;
  top: 25px;
  left: 0;
}
.sky_scraper .sky_clip a.sky_clip_red:hover {
  background-position: -131px -646px;
}
.sky_scraper .sky_clip a.sky_clip_yellow {
  width: 50px;
  height: 33px;
  background-position: -51px -646px;
  position: absolute;
  top: 25px;
  left: 50px;
}
.sky_scraper .sky_clip a.sky_clip_yellow:hover {
  background-position: -181px -646px;
}
.sky_scraper .sky_delivery a {
  display: none;
  position: absolute;
  bottom: -114px;
  width: 101px;
  height: 24px;
  background-image: url(#{$imgPath}/common_layout/bg_common_sky.png);
  background-position: 0 -684px;
  background-repeat: no-repeat;
}
.sky_scraper .sky_delivery a:hover {
  background-position: -130px -684px;
}
.sky_scraper .sky_top a {
  position: absolute;
  bottom: -118px;
  width: 101px;
  height: 28px;
  background: url(#{$imgPath}/common_layout/bg_common_sky.png) no-repeat 0 -712px;
}
.sky_scraper .sky_btn_area {
  position: absolute;
  left: -21px;
  top: 13px;
  font-size: 0;
  line-height: 0;
  z-index: 100000;
}
.sky_scraper .sky_btn_area .btn {
  width: 22px;
  height: 22px;
  background: url(#{$imgPath}/common_layout/bg_common_sky.png) -150px 0;
  font-size: 0;
  line-height: 0;
  cursor: pointer;
}

.sky_scraper .sky_btm {
  padding-top: 20px;
  font-family: $fontR;
  font-weight: 400;
}
.sky_scraper .sky_btm .sky_bx {
  padding: 1px 0;
  background-color: #f7f7f7;
  text-align: center;
}
.sky_scraper .sky_btm .ico_reserve.bojung2 {
  display: block;
  margin: 0 auto 8px;
  background-image: url(#{$imgPath}/common/sp_label.png);
  background-repeat: no-repeat;
}
.sky_scraper .sky_btm .ico_reserve.bojung2 {
  width: 62px;
  height: 47px;
  background: url(#{$imgPath}/common/sp_delivery.png) 0 -100px no-repeat;
}
.sky_scraper .sky_btm .sky_tx {
  margin: 15px 0;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -1px;
  color: #232323;
}
.sky_scraper .sky_btm .sky_tx a {
  font-weight: bold;
  color: #232323;
  text-decoration: underline;
}
.sky_scraper .sky_btm .sky_tx a:hover {
  text-decoration: underline;
}
.sky_scraper .sky_btm .sky_tx strong {
  display: block;
  color: #232323;
}
.sky_scraper .sky_btm .sky_deliv_btnarea {
  margin: 13px 0;
  text-align: center;
}
.sky_scraper .sky_btm .sky_deliv_btn {
  display: inline-block;
  overflow: hidden;
  width: 87px;
  height: 20px;
  margin: 3px 0 0 0;
  border: solid 1px #dbdbdb;
  border-radius: 3px;
  background-color: #fff;
  font-size: 11px;
  line-height: 22px;
  letter-spacing: -1px;
  color: #777;
  text-decoration: none;
  vertical-align: top;
}
.sky_scraper .sky_btm .sky_deliv_btn:first-child {
  margin-top: 0;
}
.sky_scraper .sky_btm .sky_deliv_btnarea.ty_image {
  position: relative;
  margin: 13px 0 0;
  padding-bottom: 13px;
  padding-top: 62%;
}
.sky_scraper .sky_btm .sky_deliv_btnarea.ty_image .sky_deliv_img {
  position: absolute;
  bottom: -1px;
  right: 0;
}
.sky_scraper .sky_btm .sky_deliv_btnarea.ty_image .sky_deliv_img img {
  vertical-align: top;
}
.sky_scraper .sky_btm .sky_deliv_btnarea.ty_image .sky_deliv_btn {
  position: relative;
  color: #232323;
  font-weight: bold;
}
.sky_scraper .sky_btm .sky_deliv_tit {
  margin: 7px 8px -3px;
  background: url(#{$imgPath}/common_layout/bg_sky_delivtit.png) repeat-x 0 50%;
  font-size: 12px;
  font-weight: bold;
  color: #232323;
}
.sky_scraper .sky_btm .sky_deliv_tit span {
  display: inline-block;
  padding: 0 5px;
  background: #f7f7f7;
}
.sky_scraper .sky_btm .sky_deliv_lst {
  margin: 0 7px 13px;
}
.sky_scraper .sky_btm .sky_deliv_item {
  position: relative;
  margin: 10px 0 0;
  font-size: 11px;
  line-height: 15px;
  letter-spacing: -1px;
  color: #232323;
}
.sky_scraper .sky_btm .sky_deliv_item:first-child {
  margin-top: 13px;
}
.sky_scraper .sky_btm .sky_deliv_item .icon_mall {
  display: inline-block;
  float: none;
  width: 17px;
  height: 17px;
  margin: -3px 1px 0 -1px;
  background: url(#{$imgPath}/common_layout/bg_common_sky.png) no-repeat 0 -190px;
  font-size: 0;
  line-height: 0;
  vertical-align: top;
}
.sky_scraper .sky_btm .sky_deliv_item .icon_mall.ssgmall {
  background-position: 0 -219px;
}
.sky_scraper .sky_btm .sky_deliv_item .icon_mall.depart {
  background-position: 0 -248px;
}
.sky_scraper .sky_btm .sky_deliv_item .icon_mall.emart {
  background-position: 0 -277px;
}
.sky_scraper .sky_btm .sky_deliv_item .icon_mall.traders {
  background-position: 0 -306px;
}
.sky_scraper .sky_btm .sky_deliv_item .icon_mall.boons {
  background-position: 0 -335px;
}
.sky_scraper .sky_btm .sky_deliv_item .icon_mall.howdy {
  background-position: 0 -364px;
}
.sky_scraper .sky_btm .sky_deliv_item .icon_mall.boots {
  background-position: -38px -161px;
}
.sky_scraper .sky_btm .sky_deliv_item .icon_mall.tv {
  background-position: 0 -161px;
}
.sky_scraper .sky_btm .sky_deliv_item .icon_mall.si {
  background-position: -38px -364px;
}
.sky_scraper .sky_btm .sky_deliv_item .sky_deliv_place {
  display: inline-block;
}
.sky_scraper .sky_btm .sky_deliv_itemlft {
  padding: 0 0 0 20px;
  text-align: left;
}
.sky_scraper .sky_btm .sky_deliv_itemlft .icon_mall {
  position: absolute;
  top: 0;
  left: 0;
}
.sky_scraper .sky_btm .sky_deliv_msg a {
  color: #232323;
  text-decoration: none;
}
.sky_scraper .sky_btm .sky_deliv_msg .ico_arr {
  display: inline-block;
  width: 5px;
  height: 7px;
  margin: 2px 0 0 3px;
  background: url(#{$imgPath}/common_layout/bg_common_sky.png) -100px -350px no-repeat;
  vertical-align: top;
}
.sky_scraper .sky_btm .sky_btn_myclip {
  display: block;
  width: 101px;
  height: 26px;
  background: url(#{$imgPath}/common_layout/bg_common_sky.png) -130px -750px no-repeat;
}
.sky_scraper .sky_btm .sky_btn_like {
  display: block;
  width: 99px;
  border: 1px solid #222;
  .cmlike_ico {
    margin-right: 2px;
  }
  .cmlike_btn {
    color: #222;
    background-color: #fff;
    height: 24px;
    line-height: 23px;
    &:after {
      content: '';
      display: inline-block;
      width: 6px;
      height: 6px;
      border-bottom: 1px solid #222;
      border-right: 1px solid #222;
      margin-top: -1px;
      transform: perspective(1px) rotate(-45deg);
    }
  }
}
.sky_scraper .sky_btm .sky_btn_top {
  display: block;
  width: 101px;
  height: 26px;
  margin-top: 4px;
  background: url(#{$imgPath}/common_layout/bg_common_sky.png) -130px -712px no-repeat;
}
:lang(en) .sky_scraper .sky_btm .sky_btn_myclip {
  background-image: url(#{$imgPath}/common_layout/bg_common_sky_en.png);
}
:lang(zh) .sky_scraper .sky_btm .sky_btn_myclip {
  background-image: url(#{$imgPath}/common_layout/bg_common_sky_zh.png);
}

/* sky_scraper_v2 */
.sky_scraper.sky_scraper_v2.sky_mini .sky_btm .sky_bx {
  display: block;
  width: 100px;
  position: absolute;
  top: -1000px;
  left: -2000px;
  visibility: hidden;
}
.sky_scraper.sky_scraper_v2 .sky_content {
  position: relative;
  height: 166px;
  margin-top: 23px;
  background: url(#{$imgPath}/common_layout/bg_history.gif) repeat-y 12px 0;
}
.sky_scraper.sky_scraper_v2 .sky_direction {
  position: relative;
  position: absolute;
  top: 0;
  height: 241px;
}

.sky_scraper.sky_scraper_v2 .sky_btm .deiv_bdg {
  position: relative;
  top: auto;
  right: auto;
  width: 100%;
}

.sky_scraper.sky_scraper_v2 .sky_btm .deiv_bdg > span {
  display: block;
  width: 45px;
  height: 45px;
  margin: 0 auto 4px;
}
.sky_scraper.sky_scraper_v2 .sky_btm .ico_reserve.bojung2 {
  display: block;
  margin: 0 auto 4px;
  width: 62px;
  height: 47px;
  background: url(#{$imgPath}/common/sp_delivery.png) 0 -100px no-repeat;
}
.sky_scraper.sky_scraper_v2 .sky_btm .sky_deliv_lst {
  margin: 0;
  overflow: hidden;
  max-height: 98px;
}
.sky_scraper.sky_scraper_v2 .sky_btm .sky_deliv_item {
  width: 101px;
  margin: 0;
  padding-top: 10px;
}
.sky_scraper.sky_scraper_v2 .sky_btm .sky_deliv_msg {
  line-height: 12px;
  letter-spacing: -1.5px;
}
.sky_scraper.sky_scraper_v2 .sky_btm .sky_deliv_msg a {
  color: #232323;
  text-decoration: none;
}
.sky_scraper.sky_scraper_v2 .sky_btm .sky_deliv_btn {
  display: inline-block;
  overflow: hidden;
  width: 87px;
  height: 20px;
  margin: 0;
  border: solid 1px #dbdbdb;
  border-radius: 0;
  background-color: #fff;
  font-size: 11px;
  line-height: 22px;
  letter-spacing: -1px;
  color: #222;
  text-decoration: none;
  vertical-align: top;
}
.sky_scraper.sky_scraper_v2 .sky_btm .sky_deliv_btn:first-child {
  margin-top: 0;
  margin-bottom: -1px;
}
.sky_scraper .bx-wrapper {
  height: 122px;
}
.sky_scraper .bx-controls {
  position: relative;
  width: 47px;
  height: 16px;
  margin: 5px auto 0;
}
.sky_scraper .bx-controls a {
  position: absolute;
  background-image: url(#{$imgPath}/common_layout/sp_deiv_page.png);
  background-repeat: no-repeat;
  height: 16px;
  top: 0;
  overflow: hidden;
  text-indent: -1000px;
}
.sky_scraper .bx-controls a.bx-prev {
  left: 0;
  background-position: 0 -22px;
  width: 16px;
}
.sky_scraper .bx-controls a.bx-prev:hover {
  background-position: 0 0;
}
.sky_scraper .bx-controls a.bx-next {
  right: 0;
  background-position: -32px -22px;
  width: 16px;
}
.sky_scraper .bx-controls a.bx-next:hover {
  background-position: -32px 0;
}
.sky_scraper .bx-controls-auto a {
  display: block;
}
.sky_scraper .bx-controls-auto a.active {
  display: none;
}
.sky_scraper .bx-controls-auto a.bx-start {
  left: 16px;
  width: 17px;
  background-position: -16px 0;
}
.sky_scraper .bx-controls-auto a.bx-stop {
  left: 16px;
  width: 17px;
  background-position: -16px -22px;
}
.sky_scraper.sky_scraper_v2 .sky_btm .sky_deliv_btnarea {
  margin: 8px 0 13px;
  text-align: center;
}

@media screen and (-webkit-min-device-pixel-ratio: 2) {
  .sky_scraper .bx-controls a {
    background-image: url(#{$imgPath}/common_layout/sp_deiv_page@2x.png);
    background-size: 47px auto;
  }
}

@media screen and (max-width: 1340px) {
  .body_wide {
    overflow: overlay;
  }
  .body_wide::-webkit-scrollbar {
    width: 5px;
    height: 5px; /* 가로스크롤 없애려면 값을 0으로 지정 */
  }

  // .body_wide::-webkit-scrollbar:hover {
  // 	width: 20px;
  // 	height: 20px;
  // 	background: rgba(255, 255, 255, 0.8);
  // }

  .body_wide::-webkit-scrollbar-thumb {
    background: rgba(50, 50, 50, 0.3);
    border-radius: 100px;
  }
  .body_wide::-webkit-scrollbar-thumb:hover {
    background: rgba(50, 50, 50, 0.5);
  }

  .body_wide::-webkit-scrollbar-track {
    display: none;
  }
}

/* sky_ssgtalk */
.sky_scraper .sky_ssgtalk {
  margin: 20px 2px 0 1px;
  padding: 0 0 7px;
  border-radius: 5px;
  background: #fff;
  font-family: $fontR;
  font-weight: 400;
}
.sky_scraper.active .sky_ssgtalk {
  display: none;
}
.sky_scraper.sky_mini .sky_ssgtalk {
  margin: 16px auto 0;
  padding: 3px 0 3px;
}
.sky_scraper .sky_ssgtalk .sky_ssgtalk_tittxt,
.sky_scraper .cmfloating_btnarea .cmfloating_ico,
.sky_scraper .cmfloating_btnarea .cmfloating_msg:before,
.sky_scraper .cmfloating_btnarea .cmfloating_tip:before {
  display: inline-block;
  background: url(#{$imgPath}/common_layout/sp_cmfloating_ssgtalk_v2.png) no-repeat;
  vertical-align: top;
}
.sky_scraper .sky_ssgtalk .sky_ssgtalk_tit {
  display: block;
  position: relative;
}
.sky_scraper .sky_ssgtalk .sky_ssgtalk_tittxt {
  width: 42px;
  height: 23px;
  margin-left: 15px;
  background-position: 0 0;
}
.sky_scraper .sky_ssgtalk .sky_ssgtalk_tittxt.v2 {
  width: 40px;
  height: 23px;
  margin-left: 15px;
  background-position: 0 -74px;
}
.sky_scraper .sky_ssgtalk .sky_ssgtalk_titico {
  display: inline-block;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 58px;
  width: 32px;
  height: 18px;
  background: url(#{$imgPath}/common_layout/sky_ssgtalk_titico.gif) no-repeat;
  vertical-align: top;
}
.sky_scraper .sky_ssgtalk .on .sky_ssgtalk_tittxt {
  background-position: -50px 0;
}
.sky_scraper .sky_ssgtalk .on .sky_ssgtalk_tittxt.v2 {
  background-position: -51px -74px;
}
.sky_scraper .sky_ssgtalk .push .sky_ssgtalk_tittxt {
  margin-left: 5px;
}
.sky_scraper .sky_ssgtalk .push .sky_ssgtalk_titico {
  left: 47px;
  width: 46px;
  height: 26px;
  background: url(#{$imgPath}/common_layout/sky_ssgtalk_titico_on.gif) no-repeat;
}
.sky_scraper.sky_mini .sky_ssgtalk .sky_ssgtalk_tit {
  text-align: center;
}
.sky_scraper.sky_mini .sky_ssgtalk .sky_ssgtalk_tittxt {
  width: 38px;
  height: 21px;
  margin-left: 0;
  background-position: 0 -250px;
}
.sky_scraper.sky_mini .sky_ssgtalk .sky_ssgtalk_tittxt.v2 {
  width: 36px;
  height: 21px;
  margin-left: 0;
  background-position: 0 -103px;
}
.sky_scraper.sky_mini .sky_ssgtalk .on .sky_ssgtalk_tittxt {
  background-position: -50px -250px;
}
.sky_scraper.sky_mini .sky_ssgtalk .on .sky_ssgtalk_tittxt.v2 {
  background-position: -51px -103px;
}
.sky_scraper.sky_mini .sky_ssgtalk .sky_ssgtalk_titico {
  display: none;
}
.sky_scraper .cmfloating_btnarea {
  display: block;
  position: relative;
  height: 38px;
  margin: 3px 0;
}
.sky_scraper .cmfloating_btnarea .cmfloating_btn {
  display: block;
  position: absolute;
  right: 0;
  width: 96px;
  height: 36px;
  border: solid 1px #d1d1d1;
  border-radius: 19px;
  background-color: #fff;
}
.sky_scraper .cmfloating_btnseller {
  height: 32px;
  padding-top: 12px;
}
.sky_scraper .cmfloating_btnseller:after {
  position: absolute;
  top: 0;
  right: 5px;
  left: 5px;
  height: 1px;
  background: #e5e5e5;
  content: '';
}
.sky_scraper .cmfloating_btnseller.on:after {
  background: #7989c7;
}
.sky_scraper .cmfloating_btnseller .cmfloating_btn {
  display: block;
  position: absolute;
  right: 0;
  width: 98px;
  height: 24px;
  padding: 4px 0 4px;
  border: 0;
  border-radius: 0;
  background: none;
}
.sky_scraper.sky_mini .cmfloating_btnarea {
  height: 57px;
}
.sky_scraper.sky_mini .cmfloating_btnarea .cmfloating_btn {
  right: 6px;
  width: 36px;
}
.sky_scraper.sky_mini .cmfloating_btnseller {
  height: 29px;
  padding-top: 0;
}
.sky_scraper.sky_mini .cmfloating_btnseller .cmfloating_btn {
  width: 38px;
}
.sky_scraper.sky_mini .cmfloating_btnseller:after {
  display: none;
}
.sky_scraper .cmfloating_btnarea.on .cmfloating_btn {
  border-color: #6677c0;
}
.sky_scraper .cmfloating_btnarea .cmfloating_ico {
  position: absolute;
  top: 9px;
  left: 9px;
  width: 18px;
  height: 18px;
}
.sky_scraper .cmfloating_btnarea.cmfloating_btncsbot .cmfloating_ico {
  background-position: 1px -50px;
}
.sky_scraper .cmfloating_btnarea.cmfloating_btncsbot.on .cmfloating_ico {
  background-position: -49px -50px;
}
.sky_scraper .cmfloating_btnarea .cmfloating_txt {
  position: absolute;
  top: 10px;
  right: 0;
  width: 73px;
  margin-right: 0;
  font-size: 12px;
  letter-spacing: -0.3px;
  color: #6c7080;
  text-align: center;
  white-space: nowrap;
}
.sky_scraper.sky_mini .cmfloating_btnarea .cmfloating_txt {
  top: 100%;
  right: -6px;
  width: 49px;
  font-size: 11px;
}
.sky_scraper .cmfloating_btnarea.on .cmfloating_txt {
  color: #6677c0;
}
.sky_scraper .cmfloating_btnarea .cmfloating_push {
  display: none;
  position: absolute;
  top: -5px;
  right: -2px;
  margin-left: -5px;
  padding: 0 0.45em;
  border-radius: 10px;
  background: get-color('primary');
  font-size: 11px;
  color: #fff;
  text-align: center;
}
.sky_scraper .cmfloating_btnarea.push .cmfloating_push {
  display: block;
}
.sky_scraper.sky_mini .cmfloating_btnarea .cmfloating_push {
  top: -1px;
  right: -6px;
}
.sky_scraper.sky_mini .cmfloating_btnseller .cmfloating_push {
  top: -11px;
}
.sky_scraper .cmfloating_btnarea .cmfloating_thmb_img {
  position: absolute;
  top: 0;
  left: 0;
  width: 36px;
  height: 36px;
  border-radius: 19px;
}
.sky_scraper .cmfloating_btnarea .cmfloating_thmb_img img {
  width: 100%;
  height: 100%;
  border-radius: 19px;
}
.sky_scraper .cmfloating_btnarea .cmfloating_msg {
  visibility: hidden;
  position: absolute;
  top: -14px;
  right: 100%;
  z-index: -1;
  width: 156px;
  height: 38px;
  margin-right: -11px;
  border: solid 1px #6677c0;
  border-radius: 19px 15px 0 19px;
  box-shadow: 3px 5px 5px 0 rgba(0, 0, 0, 0.15);
  background: #fff;
  font-size: 12px;
  letter-spacing: -0.3px;
  color: #6677c0;
  white-space: nowrap;
  word-wrap: break-word;
  word-break: break-all;
  opacity: 0;
  -webkit-transition: visibility 0.25s ease-out, z-index 0.25s ease-out, margin-right 0.25s ease-out,
    opacity 0.25s ease-out;
  transition: visibility 0.25s ease-out, z-index 0.25s ease-out, margin-right 0.25s ease-out,
    opacity 0.25s ease-out;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.sky_scraper .cmfloating_btnarea.push.on .cmfloating_msg {
  visibility: visible;
  z-index: 1;
  margin-right: 0;
  opacity: 1;
}
.sky_scraper .cmfloating_btnarea .cmfloating_msg:before {
  position: absolute;
  right: -9px;
  bottom: -1px;
  width: 9px;
  height: 8px;
  background-position: 0 -200px;
  content: '';
}
.sky_scraper .cmfloating_btnarea .cmfloating_msg:after {
  display: inline-block;
  overflow: hidden;
  width: 1px;
  height: 100%;
  margin-right: -1px;
  vertical-align: middle;
  content: '';
}
.sky_scraper .cmfloating_btnarea .cmfloating_msg .cmfloating_thmb_img {
  width: 38px;
  height: 38px;
}
.sky_scraper .cmfloating_btnarea .cmfloating_msg_txt {
  display: inline-block;
  overflow: hidden;
  padding: 0 10px 0 20px;
  line-height: 15px;
  white-space: normal;
  vertical-align: middle;
}
.sky_scraper .cmfloating_btnarea .cmfloating_msg .cmfloating_thmb_img + .cmfloating_msg_txt {
  padding-left: 45px;
}
.sky_scraper .cmfloating_btnseller .cmfloating_msg {
  top: -8px;
}
.sky_scraper.sky_mini .cmfloating_btnseller .cmfloating_msg {
  top: -23px;
}
.sky_scraper .cmfloating_btnarea .cmfloating_tip {
  visibility: hidden;
  position: absolute;
  top: -14px;
  right: 100%;
  z-index: -1;
  width: 156px;
  height: 38px;
  margin-right: -11px;
  border: solid 1px #6677c0;
  border-radius: 19px 15px 0 19px;
  box-shadow: 3px 5px 5px 0 rgba(0, 0, 0, 0.15);
  background: #6677c0;
  font-size: 11px;
  letter-spacing: -0.3px;
  color: #fff;
  white-space: nowrap;
  word-wrap: break-word;
  word-break: break-all;
  opacity: 0;
  -webkit-transition: visibility 0.25s ease-out, z-index 0.25s ease-out, margin-right 0.25s ease-out,
    opacity 0.25s ease-out;
  transition: visibility 0.25s ease-out, z-index 0.25s ease-out, margin-right 0.25s ease-out,
    opacity 0.25s ease-out;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.sky_scraper .cmfloating_btnarea.on .cmfloating_tip {
  visibility: visible;
  z-index: 1;
  margin-right: -1px;
  opacity: 1;
}
.sky_scraper .cmfloating_btnarea .cmfloating_tip:before {
  position: absolute;
  right: -9px;
  bottom: -1px;
  width: 9px;
  height: 8px;
  background-position: -50px -200px;
  content: '';
}
.sky_scraper .cmfloating_btnarea .cmfloating_tip:after {
  display: inline-block;
  overflow: hidden;
  width: 1px;
  height: 100%;
  margin-right: -1px;
  vertical-align: middle;
  content: '';
}
.sky_scraper .cmfloating_btnarea .cmfloating_tip .cmfloating_thmb_img {
  width: 38px;
  height: 38px;
}
.sky_scraper .cmfloating_btnarea .cmfloating_tip_txt {
  display: inline-block;
  overflow: hidden;
  padding: 0 10px 0 20px;
  line-height: 15px;
  white-space: normal;
  vertical-align: middle;
}
.sky_scraper .cmfloating_btnarea .cmfloating_tip .cmfloating_thmb_img + .cmfloating_tip_txt {
  padding-left: 45px;
}

@media screen and (-webkit-min-device-pixel-ratio: 2) {
  .sky_scraper .sky_ssgtalk .sky_ssgtalk_tittxt,
  .sky_scraper .cmfloating_btnarea .cmfloating_ico,
  .sky_scraper .cmfloating_btnarea .cmfloating_msg:before,
  .sky_scraper .cmfloating_btnarea .cmfloating_tip:before {
    background-image: url(#{$imgPath}/common_layout/sp_cmfloating_ssgtalk_v2@2x.png);
    background-size: 116px auto;
  }
  .sky_scraper .sky_ssgtalk .sky_ssgtalk_titico {
    background-image: url(#{$imgPath}/common_layout/sky_ssgtalk_titico@2x.gif);
    background-size: 32px auto;
  }
  .sky_scraper .sky_ssgtalk .push .sky_ssgtalk_titico {
    background-image: url(#{$imgPath}/common_layout/sky_ssgtalk_titico_on@2x.gif);
    background-size: 46px auto;
  }
  .sky_scraper .history_list.v2 li .icon_mall,
  .sky_scraper.active .history_list.v2 li .icon_mall {
    background-image: url(#{$imgPath}/common_layout/sp_ico_dotmall@2x.png);
    background-size: 150px auto;
  }
}

.sky_scraper.sky_afterlogin .sky_delivery a {
  display: block;
}
.sky_scraper.sky_afterlogin .sky_top a {
  bottom: -146px;
}
.sky_scraper.sky_afterlogin .sky_content {
  height: 186px;
}
.sky_scraper.sky_afterlogin .history_list {
  height: 186px;
}
.sky_scraper.sky_afterlogin .sky_direction {
  height: 261px;
}

.sky_scraper.sky_mini {
  width: 49px;
  right: 0;
  left: auto;
  margin-left: 0;
  background: none;
}
.sky_scraper.sky_mini .sky_haeder,
.sky_scraper.sky_mini .sky_content,
.sky_scraper.sky_mini .sky_direction,
.sky_scraper.sky_mini .sky_clip,
.sky_scraper.sky_mini .sky_delivery {
  display: none;
}
.sky_scraper.sky_mini .sky_btn_area {
  display: none;
}
.sky_scraper.sky_mini .btn_sky_mini {
  width: 49px;
  height: 43px;
  background: url(#{$imgPath}/common_layout/bg_common_sky.png) no-repeat -139px -500px;
  right: 0;
  top: -28px;
}
:lang(en) .sky_scraper.sky_mini .btn_sky_mini,
:lang(zh) .sky_scraper.sky_mini .btn_sky_mini {
  background-position: -80px -500px;
}
.sky_scraper.sky_mini .sky_top a {
  width: 49px;
  height: 26px;
  background-position: -146px -52px;
  bottom: -44px;
  right: 0;
}
.sky_scraper.sky_mini .sky_btm {
  padding-top: 14px;
}
.sky_scraper.sky_mini .sky_btm .sky_bx,
.sky_scraper.sky_mini .sky_btm .sky_btn_myclip,
.sky_scraper.sky_mini .sky_btm .sky_btn_like {
  display: none;
}
.sky_scraper.sky_mini .sky_btm .sky_btn_top {
  width: 49px;
  height: 26px;
  background-position: -200px -52px;
}
.sky_scraper.low_width {
  right: 0;
  left: auto;
  margin-left: 0;
}
.sky_scraper.active .sky_btm,
.sky_scraper.active .btn_sky_mini {
  display: none;
}
.sky_scraper.active .sky_content {
  position: static;
  background: 0 none;
  margin-top: 0;
}
.sky_scraper.active .sky_btn_area {
  position: absolute;
  left: -7px;
  top: 13px;
}
.sky_scraper.active .sky_btn_area .btn {
  width: 22px;
  height: 22px;
  background: url(#{$imgPath}/common_layout/bg_common_sky.png) no-repeat -100px -300px;
  cursor: pointer;
}
.sky_scraper.active .sky_haeder {
  overflow: hidden;
  width: auto;
  height: 47px;
  padding: 3px 0 0;
}
.sky_scraper.active .sky_haeder h3 {
  float: left;
  padding: 1px 0 0 20px;
  font-size: 13px;
  line-height: 40px;
  *padding-top: 0;
}
.sky_scraper.active .sky_haeder h3 strong {
  float: left;
  margin: 0 0 0 4px;
  font-family: $fontR;
  font-size: 19px;
  font-weight: 400;
  line-height: 40px;
  color: #e74848;
  vertical-align: top;
}
.sky_scraper.active .sky_haeder h3 img {
  padding-right: 4px;
  padding-top: 3px;
}
.sky_scraper.active .sky_haeder select {
  float: right;
  width: 113px;
  padding: 4px;
  height: 26px\9;
  *height: auto;
  margin: 8px 15px 0 0;
  *margin: 12px 15px 0 0;
  vertical-align: -3px;
  font-size: 12px;
  color: #222;
}
:lang(en) .sky_scraper.active .sky_haeder select,
:lang(zh) .sky_scraper.active .sky_haeder select {
  width: auto;
}

.sky_scraper.active .history_list {
  position: relative;
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: auto;
  height: 503px;
  margin: -1px 0 0 10px;
  background: url(#{$imgPath}/common_layout/bg_history.gif) repeat-y 9px 0;
}
.sky_scraper.active .history_list li {
  overflow: hidden;
  margin-top: 8px;
  width: 364px;
}
.sky_scraper.active .history_list li:first-child {
  margin-top: 15px;
}
.sky_scraper.active .history_list li.category {
  margin-left: 0;
}
.sky_scraper.active .history_list li .blind_txt {
  display: inline;
  color: #848484;
}
.sky_scraper.active .history_list li .icon_mall {
  margin-right: 7px;
}
.sky_scraper.active .history_list li .history_cont .txt {
  max-height: 54px;
}
.sky_scraper.active .history_list li .history_cont {
  overflow: hidden;
  float: left;
  width: 330px;
  padding: 2px 0 10px;
  border-bottom: 1px dotted #909090;
}
.sky_scraper.active .history_list li .history_cont .sky_thumbnail.adult_only a {
  background-position: -100px -100px;
}
.sky_scraper.active .history_list li .history_cont .sky_thumbnail.sold_out a {
  background-position: 0 -100px;
}
.sky_scraper.active .history_list li .history_cont .txt {
  float: left;
  width: 330px;
}
.sky_scraper.active .history_list li .history_cont .txt.blind_txt {
  width: 270px;
}
.sky_scraper.active .history_list li .history_cont .txt.blind_txt a {
  color: #848484;
  text-decoration: none;
  word-break: break-all;
  word-wrap: break-word;
}
.sky_scraper.active .history_list li .history_cont .txt a:hover {
  text-decoration: underline;
}
.sky_scraper.active .history_list li.type_category .txt {
  color: #848484;
}
.sky_scraper.active .history_list li.type_brand .history_cont .txt .brand_txt {
  color: #222;
  padding-right: 3px;
}
.sky_scraper.active .history_list li.type_brand .history_cont .txt .brand_txt .brand {
  color: #848484;
  padding-right: 3px;
}
.sky_scraper.active .history_list li.type_event .txt a .em,
.sky_scraper.active .history_list li.type_exhibition .txt a .em {
  color: #848484;
}
.sky_scraper.active .history_list li.type_search .txt a {
  color: #222;
}
.sky_scraper.active .history_list li.type_brand .history_cont .txt a,
.sky_scraper.active .history_list li.type_search .txt {
  color: #848484;
}
.sky_scraper.active .history_list li.type_product .txt a {
  max-height: 36px;
  overflow: hidden;
  display: block;
  color: #222;
}
.sky_scraper.active .history_list li.type_product .txt a .brand {
  color: #848484;
}
.sky_scraper.active .history_list li.type_product.adult .txt a {
  display: block;
  overflow: hidden;
  color: #222;
  line-height: 18px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.sky_scraper.active .history_list li.type_product .txt del {
  display: inline-block;
  margin-right: 8px;
  color: #aaaaaa;
}
.sky_scraper.active .history_list li.type_product .txt .applied {
  font-weight: bold;
  color: #e74848;
}
.sky_scraper.active .history_list li.type_product:hover .btn_history_del {
  display: block;
}
.sky_scraper.active .history_list li.type_brand .history_cont .txt {
  float: left;
  width: 250px;
  color: #848484;
}
.sky_scraper.active .history_list li.type_event .txt .date,
.sky_scraper.active .history_list li.type_exhibition .txt .date {
  color: #848484;
  display: block;
}
.sky_scraper.active .history_list li.type_event .txt a,
.sky_scraper.active .history_list li.type_exhibition .txt a {
  display: block;
  overflow: hidden;
  color: #222;
  line-height: 18px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.sky_scraper.active .history_list li.history_txt {
  background: #fff;
  margin: 0;
}
.sky_scraper.active .history_list li.history_txt p.history_txt {
  height: 265px;
  width: 383px;
  margin: 28px 0 0 3px;
  padding-top: 210px;
  text-align: center;
  font-size: 20px;
  font-family: $fontR;
  font-weight: 400;
  line-height: 26px;
  color: #666;
}
.sky_scraper.active .history_list li.history_txt p.history_txt span {
  color: #00aac4;
}
.sky_scraper.active .sky_btn_del {
  display: block;
}

// 스카이스크래퍼 여행
.sky_scraper.active .history_list li.cmsky_ty_trip {
  .txt {
    a {
      max-height: 54px;
    }
    .cmsky_trip_cities {
      display: block;
      max-width: 260px;
      white-space: nowrap;
      overflow: hidden;

      i {
        display: inline-block;
        font-size: 0;
        width: 16px;
        height: 12px;
        margin-top: 2px;
        background: url(#{$imgPath}/common_layout/bg_common_sky.png) no-repeat 0 0;
        background-position: -100px -372px;
        vertical-align: top;
      }
    }
    .cmsky_trip_date {
      color: #338ca8;
      letter-spacing: -0.3px;
    }
    .cmsky_trip_stops {
      display: block;
      color: #848484;
    }
    .cmsky_trip_hotels {
      display: block;
      max-width: 260px;
      white-space: nowrap;
      overflow: hidden;
    }
    .cmsky_trip_countries {
      display: block;
      color: #848484;
    }
  }
}

#sr_ly {
  position: fixed;
  z-index: 1000;
  left: 50%;
  right: auto;
  margin-left: 395px;
  font-family: $fontR;
  font-weight: 400;
}
.body_wide #sr_ly {
  position: fixed;
  z-index: 1000;
  left: 50%;
  right: auto;
  margin-left: 526px;
}
#sr_ly.low_width {
  left: auto;
  right: 20px;
  margin-left: 0;
}
#sr_ly .history_cont {
  position: relative;
  width: 158px;
  height: 44px;
  padding: 9px 62px 9px 11px;
  border: 1px solid #000;
  background: #fff;
}
:lang(en) #sr_ly .history_cont,
:lang(zh) #sr_ly .history_cont {
  margin-top: -40px;
}
#sr_ly .history_cont:after {
  content: '';
  display: block;
  clear: both;
}
#sr_ly .history_cont .btn_history_del {
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  background: url(#{$imgPath}/common_layout/btn_history_del.png) 0 0 no-repeat;
  right: -21px;
  top: -1px;
}
#sr_ly .history_cont .sky_thumbnail {
  position: absolute;
  right: 6px;
  top: 6px;
  float: none;
  margin: 0;
}
#sr_ly .history_cont .sky_thumbnail a {
  display: inline-block;
  border: 1px solid #ececec;
  vertical-align: top;
}
#sr_ly .history_cont .sky_thumbnail a img {
  vertical-align: top;
}
#sr_ly .history_cont .sky_thumbnail .img_hover {
  display: none;
}
#sr_ly .history_cont .txt {
  overflow: visible;
  max-height: 100%;
  height: 62px;
  color: #555;
}
#sr_ly .history_cont .txt a {
  display: block;
  overflow: hidden;
  max-height: 26px;
  margin-bottom: 2px;
  font-size: 11px;
  line-height: 13px;
  color: #777;
}
#sr_ly .history_cont.adult .txt a {
  max-height: 13px;
}
#sr_ly .history_cont .txt .adult_notice {
  display: block;
  font-size: 11px;
  line-height: 13px;
  color: #777;
}
#sr_ly .history_cont .txt del {
  display: inline-block;
  color: #aaa;
  vertical-align: top;
  display: none;
}
#sr_ly .history_cont .txt .applied {
  display: inline-block;
  vertical-align: top;
  line-height: 22px;
}
#sr_ly .history_cont .txt .applied .ssg_price {
  display: inline-block;
  margin: 0;
  font-size: 15px;
  font-family: $fontR;
  font-weight: 400;
  line-height: 22px;
  color: #f14f4f;
  vertical-align: top;
}
#sr_ly .history_cont .txt .applied .ssg_tx {
  display: inline-block;
  vertical-align: top;
}
#sr_ly .history_cont .txt .applied em {
  display: inline-block;
  font-size: 15px;
  font-family: $fontR;
  font-weight: 400;
  line-height: 22px;
  color: #f14f4f;
  vertical-align: -1px;
  margin-right: 1px;
}
#sr_ly .history_cont.cmsky_ty_trip {
  padding: 10px 61px 10px 11px;
  width: 160px;
  .txt {
    a {
      max-height: 46px;
    }
    .cmsky_trip_cities {
      display: block;
      height: 13px;
      max-width: 160px;
      overflow: hidden;
      i {
        display: inline-block;
        font-size: 0;
        width: 16px;
        height: 12px;
        background: url(#{$imgPath}/common_layout/bg_common_sky.png) no-repeat 0 0;
        background-position: -100px -372px;
        vertical-align: top;
      }
    }
    .cmsky_trip_date {
      display: block;
      margin-top: 2px;
      font-family: $fontR;
      font-weight: 400;
      color: #338ca8;
      font-size: 12px;
      letter-spacing: -0.3px;
    }
    .cmsky_trip_stops {
      display: block;
      color: #848484;
      margin-top: 3px;
    }
    .cmsky_trip_hotels {
      display: block;
      height: 13px;
      max-width: 160px;
      overflow: hidden;
    }
    .cmsky_trip_countries {
      display: block;
      margin-top: 3px;
      color: #848484;
    }
  }
}
